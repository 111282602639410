import { CommissionLeadershipSchemeModel } from '@shared/data-access/models';
import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { map, Observable } from 'rxjs';
import { ICommissionLeadershipScheme } from '@shared/data-access/interfaces';
import { PaginationAdapter } from '@red/data-access';
import { CommLeadershipSchemeCreateDto, CommLeadershipSchemeUpdateDto } from '@shared/data-access/dto';

@Injectable({
  providedIn: 'root',
})
export class CommissionLeadershipSchemeApiService {
  static LEADER_SCHEME = 'hrm/commission-schemes/leadership';

  constructor(private apiService: ApiService) {}

  create(data: CommLeadershipSchemeCreateDto): Observable<CommissionLeadershipSchemeModel> {
    return this.apiService
      .post(`${CommissionLeadershipSchemeApiService.LEADER_SCHEME}`, data)
      .pipe(map((res: ICommissionLeadershipScheme) => CommissionLeadershipSchemeModel.fromJson(res) as CommissionLeadershipSchemeModel));
  }

  get(itemId: number): Observable<CommissionLeadershipSchemeModel> {
    return this.apiService
      .get(CommissionLeadershipSchemeApiService.LEADER_SCHEME + '/' + itemId)
      .pipe(map(res => CommissionLeadershipSchemeModel.fromJson(res) as CommissionLeadershipSchemeModel));
  }

  update(id: number, data: CommLeadershipSchemeUpdateDto): Observable<any> {
    return this.apiService.patch(CommissionLeadershipSchemeApiService.LEADER_SCHEME + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<CommissionLeadershipSchemeModel>> {
    return this.apiService
      .get(CommissionLeadershipSchemeApiService.LEADER_SCHEME, query, option)
      .pipe(map(data => new PaginationAdapter(CommissionLeadershipSchemeModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(CommissionLeadershipSchemeApiService.LEADER_SCHEME + '/' + id, {}, option);
  }
}
