import { ICommissionSplittingGroup } from '../interfaces';
import { BaseModel } from '@red/data-access';
import {  Expose } from 'class-transformer';


export class CommissionSplittingGroupModel extends BaseModel implements ICommissionSplittingGroup {
  @Expose()
  name!: string;

  @Expose()
  percentage!: number;

  @Expose()
  value?: number;
}
