export interface IStransactionType {
  id: number;
  code: string;
  name: string;
  status: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  description: string;
  categoryRole: string;
}
