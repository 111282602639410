import { Transform, TransformFnParams } from 'class-transformer';
import { isInteger, isNaN, isNil } from 'lodash-es';
import * as moment from 'moment';

function isValidDate(val: string | number | Date): boolean {
  const selected = val !== '' && isInteger(Number(val)) ? Number(val) : val;
  const instance = new Date(selected);
  return instance instanceof Date && !isNaN(instance.getTime()) && !isNil(val);
}
function toDate(val: string | number | Date): Date {
  const selected = val !== '' && isInteger(Number(val)) ? Number(val) : val;
  return new Date(selected);
}
function getTimeZoneOffset(offsetMinutes: number) {
  const hours = Math.floor(Math.abs(offsetMinutes) / 60);
  const minutes = Math.abs(offsetMinutes) % 60;
  const sign = offsetMinutes > 0 ? '-' : '+';
  const formattedOffset = sign + hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
  return formattedOffset;
}
export function IsoString(config?: { keepDateValue: boolean; endOfDay?: boolean }) {
  return Transform((params: TransformFnParams) => {
    if (!params.value) {
      return undefined;
    }
    const selected = params.value;
    // if (typeof params?.value?.toDate === 'function') {
    //   console.log('params.value function', params.value);
    //   selected = params.value.toDate();
    // }
    // console.log('selected', selected, params?.value);
    if (selected instanceof Date) {
      let value = config?.keepDateValue ? new Date(Date.UTC(selected.getFullYear(), selected.getMonth(), selected.getDate())) : selected;

      if (config?.endOfDay) {
        value = new Date(Date.UTC(selected.getFullYear(), selected.getMonth(), selected.getDate(), 23, 59, 59));
      }

      return value.toISOString();
    }
    return params.value;
  });
}
export function Timestamp() {
  return Transform((params: TransformFnParams) => {
    if (!params.value) {
      return undefined;
    }
    const selected = params.value;
    if (selected instanceof Date) {
      return selected.getTime();
    }
    return params.value;
  });
}
export function StartOfDate() {
  return Transform((params: TransformFnParams) => {
    const selected = params.value;
    if (selected instanceof Date) {
      selected.setHours(0, 0, 0);
      return selected;
    }
    if (isValidDate(selected)) {
      const cloned = new Date(selected);
      cloned.setHours(0, 0, 0);
      return cloned;
    }
    return undefined;
  });
}
export function EndOfDate() {
  return Transform((params: TransformFnParams) => {
    const selected = params.value;
    if (selected instanceof Date) {
      selected.setHours(23, 59, 59);
      return selected;
    }
    // console.log('EndOfDate-->', selected, isValidDate(selected));
    if (isValidDate(selected)) {
      const cloned = toDate(selected);
      cloned.setHours(23, 59, 59);
      return cloned;
    }
    return undefined;
  });
}

export function FormattedDateString() {
  return Transform((params: TransformFnParams) => {
    if (!params.value) {
      return undefined;
    }
    if (typeof params.value === 'string' || typeof params.value === 'number' || params.value instanceof Date) {
      const date = toDate(params.value);
      if (!isValidDate(date)) {
        return params.value;
      }
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      const timeZoneOffset = getTimeZoneOffset(date.getTimezoneOffset());
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timeZoneOffset}`;
      return formattedDate;
    }
    return params.value;
  });
}


export function FormattedStringToDateOfBirth() {
  return Transform((params: TransformFnParams) => {
    if (!params.value) return undefined;

    if (typeof params.value === 'string' || typeof params.value === 'number' || params.value instanceof Date) {

      if (params.value.toString().length > 8) return params.value

      const date = moment(params.value, "DDMMYYYY").toDate();

      if (!isValidDate(date)) return params.value;

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      const timeZoneOffset = '+06:00';  // Hardcoded as per original code, change if needed
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timeZoneOffset}`;

      return formattedDate;
    }
    return params.value
  });

}

export function FormattedDateOfBirthToString() {
  return Transform((params: TransformFnParams) => {
    if (!params.value) {
      return '';
    }
    if (typeof params.value === 'string') {
      const dateOfBirth = params.value
      const [datePart] = dateOfBirth.split('T');
      const [year, month, day] = datePart.split('-');
      const formattedDateOfBirth = `${day}${month}${year}`;
      return formattedDateOfBirth.replace(/\D/g, '')
    }
    return params.value;
  });
}






