import { ISchemeType } from './scheme-type.interface';
import { ISplitMatrix } from './split-matrix.interface';

export interface ICommissionScheme {
  id: number;
  schemeTypeId: number;
  schemeType: ISchemeType;
  designation: string;
  code: string;
  status: string;
  autoApprove: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  sequence: number;
}

export interface ICommSchemeGeneric {
  id: number;
  designation: string;
  splitMatrices?: ISplitMatrix[];
}

export interface IRequiredCommSchemeDto {
  commissionSchemeId: number;
  isActive: boolean;
}

export interface IRequiredTeamMemberDto {
  commissionSchemeId: number;
  headcount: number;
  isActive: boolean;
}

export interface IGrossCommissionEarnedDto {
  isActive: boolean;
  commissionReceivedFrom: number;
  commissionReceivedTo: number;
  fixedEvaluationPeriod: boolean;
  accumulatedPeriod: number;
  resetAccumulatedPeriod: number;
  excludeOwnTransaction: boolean;
}
