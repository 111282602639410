import { BaseModel, Default } from "@red/data-access";
import { Expose, Type } from "class-transformer";
export class DeveloperAddressCreateDto extends BaseModel {

  @Expose()
  @Type(()=>Number)
  developerId!:number;

  @Expose()
  address!:string;

  @Expose()
  @Type(()=>Number)
  countryId!: number;

  @Expose()
  postCode!: string;

  @Expose()
  addressType!: string;


  @Expose()
  @Type(()=>Boolean)
  isDefault!: boolean;

}

export class DeveloperAddressUpdateDto extends BaseModel {

  @Expose()
  address!:string;

  @Expose()
  @Type(()=>Number)
  countryId!: number;

  @Expose()
  postCode!: string;

  @Expose()
  addressType!: string;

  @Expose()
  @Type(()=>Boolean)
  isDefault!: boolean;


}

