import { ISplitMatrix } from './split-matrix.interface';
import { IPersonalParticular } from './personal-particular.interface';
import {
  EBlankFormIcType,
  EBlankFormReferralType,
  EBlankFormReferralSourceType,
  EBlankFormInternalAgentType,
  EBLankFormReferralFormula,
  EBlankFormOtherFeeType,
} from '../enums';
import { KeysOfBlankFormExtendBlankFormIcs } from '../types';
import { IAppointmentType } from './appointment-type.interface';
import { ISplitPart } from './split-part.interface';

export interface IBlankFormIc extends ISplitMatrix {
  id: number;
  blankFormId: number;
  appointmentTypeId: number;
  appTypeCode: string;
  appointmentType: IAppointmentType;
  salespersonId: number;
  salesperson: IPersonalParticular;
  note: any;
  data: any;
  parties: ISplitPart[];
  splitMatrixId: number;
  tiers: ISplitPart[];
  createdAt: string;
  updatedAt: string;
  isDefault: boolean;
  companyAmount: number;
}

export interface IBlankFormIcDescription {
  id: EBlankFormIcType;
  label: string;
  blankFormKey: KeysOfBlankFormExtendBlankFormIcs;
}

export interface IBlankFormReferralTypeDescription {
  value: EBlankFormReferralType;
  viewValue: string;
  sourceTypes: EBlankFormReferralSourceType[];
}

export interface IBlankFormInternalAgentTypeDescription {
  value: EBlankFormInternalAgentType;
  viewValue: string;
}

export interface IBlankFormReferralFormulaDescription {
  value: EBLankFormReferralFormula;
  viewValue: string;
  sourceTypes: EBlankFormReferralSourceType[];
}

export interface IBlankFormOtherTypeDescription {
  value: EBlankFormOtherFeeType;
  viewValue: string;
}
