import { Expose } from 'class-transformer';
import { TemplateType } from '../enums';
import { ITemplates as ITemplate } from '../interfaces';
import { BaseFixedModel } from './base.model';

export class TemplateModel extends BaseFixedModel implements ITemplate {
  @Expose()
  html!: string;

  @Expose()
  css?: string;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  type!: TemplateType;
}
