import { BaseModel, PhoneNumber } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EBuyerType, EGender, EBuyerPropertyType, ECitizen, ENumberOf, EPurpose } from '../enums';
import { IBlankFormBuyerDto } from '../interfaces';
import { AddressBuyerDto } from './address.dto';

export class BlankFormBuyerDto extends BaseModel implements IBlankFormBuyerDto {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  type!: EBuyerType;

  @Expose()
  gender!: EGender;

  @Expose()
  maritalId!: number;

  @Expose()
  propertyType!: EBuyerPropertyType;

  @Expose()
  categoryId!: number;

  @Expose()
  postal!: string;

  @Expose()
  nricNo!: string;

  @Expose()
  yearOfBirth!: number;

  @Expose()
  @Type(() => AddressBuyerDto)
  address!: AddressBuyerDto;

  @Expose()
  email!: string;

  @Expose()
  @Type(() => PhoneNumber)
  mobile!: PhoneNumber;

  @Expose()
  citizen!: ECitizen;

  @Expose()
  nationalityId!: number;

  @Expose()
  districtId!: number;

  @Expose()
  occupation!: string;

  @Expose()
  ownership!: ENumberOf;

  @Expose()
  isHdbOwnership!: boolean;

  @Expose()
  purposeOfPurchase!: EPurpose;
}
