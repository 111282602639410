import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { JournalCreateDto } from '@shared/data-access/dto';
import { IJournalEntries } from '@shared/data-access/interfaces';
import { JournalEntriesModel } from '@shared/data-access/models';
import { plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JournalEntriesApiService {
  static ROOT_POINT = 'finance/journals';

  constructor(private apiService: ApiService) {}

  create(data: JournalCreateDto): Observable<JournalEntriesModel> {
    return this.apiService
      .post(`${JournalEntriesApiService.ROOT_POINT}`, data)
      .pipe(map((res: IJournalEntries) => JournalEntriesModel.fromJson(res) as JournalEntriesModel));
  }

  get(itemId: number): Observable<JournalEntriesModel> {
    return this.apiService.get(JournalEntriesApiService.ROOT_POINT + '/' + itemId).pipe(map(res => JournalEntriesModel.fromJson(res) as JournalEntriesModel));
  }

  update(id: number, data: JournalCreateDto): Observable<JournalEntriesModel> {
    return this.apiService.patch(JournalEntriesApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<JournalEntriesModel>> {
    return this.apiService.get(JournalEntriesApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(JournalEntriesModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(JournalEntriesApiService.ROOT_POINT + '/' + id, data, option);
  }

  getPath(id: number): Observable<JournalEntriesModel[]> {
    return this.apiService.get(JournalEntriesApiService.ROOT_POINT + '/' + id + '/paths').pipe(
      map((res: JournalEntriesModel[]) => {
        return plainToInstance(JournalEntriesModel, res);
      })
    );
  }
}
