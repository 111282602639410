import { BaseModel } from '@red/data-access';
import {  Expose } from 'class-transformer';
import { ICountryPhoneNumber } from '../interfaces';


export class CountryPhoneNumberModel extends BaseModel implements ICountryPhoneNumber{
  @Expose()
  id!: string;

  @Expose()
  iso!: string;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  flagImagePos!: string;

}
