import { ICredential } from '../interfaces';
import { BaseModel } from '@red/data-access';
import {  Expose } from 'class-transformer';

export class CredentialModel extends BaseModel implements ICredential {
  @Expose()
  id!: number;

  @Expose()
  username!: string;

}
