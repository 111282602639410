import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { JournalEntryCreateDto } from '@shared/data-access/dto';
import { IJournalEntries } from '@shared/data-access/interfaces';
import { JournalEntryModel, JournalEntryPostingModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JournalEntryApiService {
  static ROOT_POINT = 'finance/journals';

  constructor(private apiService: ApiService) {}

  create(data: JournalEntryCreateDto): Observable<JournalEntryModel> {
    return this.apiService
      .post(`${JournalEntryApiService.ROOT_POINT}`, data)
      .pipe(map((res: IJournalEntries) => JournalEntryModel.fromJson(res) as JournalEntryModel));
  }

  get(id: number, query = {}): Observable<JournalEntryModel> {
    return this.apiService.get(JournalEntryApiService.ROOT_POINT + '/' + id, query).pipe(map(res => JournalEntryModel.fromJson(res) as JournalEntryModel));
  }

  getPostings(journalId?: number, query: any = {}, option?: IApiOption): Observable<PaginationAdapter<JournalEntryPostingModel>> {
    return this.apiService
      .get(JournalEntryApiService.ROOT_POINT + '/' + journalId + '/postings', query, option)
      .pipe(map(data => new PaginationAdapter(JournalEntryPostingModel, data)));
  }

  update(id: number, data: JournalEntryCreateDto): Observable<any> {
    return this.apiService.patch(JournalEntryApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<JournalEntryModel>> {
    return this.apiService.get(JournalEntryApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(JournalEntryModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(JournalEntryApiService.ROOT_POINT + '/' + id, data, option);
  }
}
