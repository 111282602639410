import { Expose, Transform, Type } from 'class-transformer';
import { ICheque } from '../interfaces';
import { BankAccountModel } from './bank-account.model';
import { PaymentModel } from './payment.model';
import { BaseFixedModel } from './base.model';

export class ChequeModel extends BaseFixedModel implements ICheque {
  @Expose()
  bankAccountId!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  @Type(() => BankAccountModel)
  bankAccount!: BankAccountModel;

  @Expose()
  paymentId!: number;

  @Expose()
  @Type(() => PaymentModel)
  payment!: PaymentModel;

  @Expose()
  chequeNumber!: string;

  @Expose()
  chequeDate!: Date;

  @Expose()
  amount!: number;

  @Expose()
  code!: string;

  @Expose()
  isCancel!: boolean;

  @Expose()
  paymentNumber!: string;

  @Expose()
  @Transform(({ obj }) => {
    return !obj.isCancel && !obj.paymentId;
  })
  canCancel!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return obj.isCancel && !obj.paymentId;
  })
  canReset!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return !obj.paymentId;
  })
  canDelete!: boolean;
}
