import { UnitModel } from './unit.model';
import { UpdatedByModel } from './updated-by.model';
import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ICommissionMatrix, IStatusDescription } from '../interfaces';
import { CommisisonMatrixVerifyStatus, CommissionMatrixStatus } from '../types/commission-matrix-status.type';
import { CategoryModel } from './category.model';
import { BonusDistributionModel } from './bonus-distribution.model';
import { OverridingDistributionModel } from './overriding-distribution.model';
import { ECommMatrixVerifyStatus, EProjectType } from '../enums';
import { COMMISSION_MATRIX_VERIFY_STATUS } from '../data';

export class CommissionMatrixModel extends BaseModel implements ICommissionMatrix {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  agentTier!: number;

  @Expose()
  @Type(() => Number)
  companyTier!: number;

  @Expose()
  @Type(() => Number)
  developerTier!: number;

  @Expose()
  @Type(() => Number)
  commissionTypeId!: number;

  @Expose()
  @Type(() => CategoryModel)
  commissionType!: CategoryModel;

  @Expose()
  rejectReason?: string | null;

  @Expose()
  remarks!: string;

  @Expose()
  closingAgent!: { agent: boolean; hr: boolean; value: number };

  @Expose()
  teamLeader!: { agent: boolean; hr: boolean; value: number };

  @Expose()
  otherFund!: { agent: boolean; hr: boolean; value: number };

  @Expose()
  @Transform(({ value }) => {
    if (!value) return null;
    return {
      agent: value.agent,
      disBonusCode: value.disBonusCode,
      hr: value.hr,
      disBonusId: value.disBonusId,
      disBonus: BonusDistributionModel.fromJson(value.disBonus),
      value: value.value,
    };
  })

  icBonus!: {
    agent: boolean;
    hr: boolean;
    disBonusId: number;
    disBonusCode: string;
    disBonus: BonusDistributionModel;
    value: number;
  };

  @Expose()
  @Transform(({ value }) => {
    if (!value) return null;
    return {
      agent: value.agent,
      hr: value.hr,
      disOverridingCode: value.disOverridingCode,
      disOverridingId: value.disOverridingId,
      disOverriding: OverridingDistributionModel.fromJson(value.disOverriding),
      value: value.value,
    };
  })
  icOverriding!: {
    agent: boolean;
    hr: boolean;
    disOverridingId: number;
    disOverridingCode: string;
    disOverriding: OverridingDistributionModel;
    value: number;
  };

  @Expose()
  status!: CommisisonMatrixVerifyStatus;
  // status!: CommissionMatrixStatus;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  isSpecial!: boolean;

  @Expose()
  isLocked!: boolean;

  // @Expose()
  // unit!: UnitModel;

  @Expose()
  unitNo!: string;

  // @Expose()
  // unitId!: number;

  @Expose()
  type!: `${EProjectType}`;

  get displayStatus() {
    return COMMISSION_MATRIX_VERIFY_STATUS[this.status];
  }

  get canSubmit() {
    return COMMISSION_MATRIX_VERIFY_STATUS[this.status].canSubmit ?? false;
  }

  get canEdit(): boolean {
    return COMMISSION_MATRIX_VERIFY_STATUS[this.status].canEdit ?? false;
  }

  get canAgentEdit(): boolean {
    return COMMISSION_MATRIX_VERIFY_STATUS[this.status].canAgentEdit ?? false;
  }

  get canDelete(): boolean {
    return (COMMISSION_MATRIX_VERIFY_STATUS[this.status].canDelete && !this.isLocked) ?? false;
  }

  get canAgentDelete(): boolean {
    return (COMMISSION_MATRIX_VERIFY_STATUS[this.status].canAgentDelete && !this.isLocked) ?? false;
  }

  get canSave(): boolean {
    return COMMISSION_MATRIX_VERIFY_STATUS[this.status].canSave ?? false;
  }
}
