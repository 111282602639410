import { NumberOfRecord } from '../types';
import { ENumberOf } from '../enums';

export const NUMBER_OF_OPTIONS: NumberOfRecord = {
  [ENumberOf.No0]: {
    id: ENumberOf.No0,
    value: ENumberOf.No0,
    viewValue: '0',
  },
  [ENumberOf.No1]: {
    id: ENumberOf.No1,
    value: ENumberOf.No1,
    viewValue: '1',
  },
  [ENumberOf.No2]: {
    id: ENumberOf.No2,
    value: ENumberOf.No2,
    viewValue: '2',
  },
  [ENumberOf.No3]: {
    id: ENumberOf.No3,
    value: ENumberOf.No3,
    viewValue: '3',
  },
  [ENumberOf.No4]: {
    id: ENumberOf.No4,
    value: ENumberOf.No4,
    viewValue: '4',
  },
  [ENumberOf.No5]: {
    id: ENumberOf.No5,
    value: ENumberOf.No5,
    viewValue: '5',
  },
  [ENumberOf.MoreThan5]: {
    id: ENumberOf.MoreThan5,
    value: ENumberOf.MoreThan5,
    viewValue: '>5',
  },
};
