import { BaseModel, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class EmploymentHistoryDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  companyName!: string;

  @Expose()
  designation?: string;

  @Expose()
  areaWork?: string;

  @Expose()
  address?: string;

  @Expose()
  reasonLeft?: string;

  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  periodFrom?: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  periodTo?: string;
}
