import {BaseModel} from "@red/data-access";
import {IDialect} from "@shared/data-access/interfaces";
import {Expose} from "class-transformer";

export class DialectModel extends BaseModel implements IDialect {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string | null;

  @Expose()
  createdBy!: string | null;

  @Expose()
  updatedBy!: string | null;

  @Expose()
  deletedBy!: string | null;
}
