import { BaseModel, StartOfDate, FormattedDateString } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class AdditionalInformationDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  typeId?: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate?: string;

  @Expose()
  remarks?: string;
}
