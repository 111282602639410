import { Injectable } from "@angular/core";
import { ApiService, IApiOption } from "@red/api";
import { PaginationAdapter } from "@red/data-access";
import { DeveloperAddressUpdateDto, DeveloperAddressCreateDto } from "@shared/data-access/dto";
import { IAddress } from "@shared/data-access/interfaces";
import { AddressModel } from "@shared/data-access/models";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DeveloperAddressApiService {
  static ROOT_POINT = 'transaction/developer-addresses';

  constructor(private apiService: ApiService) { }

  create(data: DeveloperAddressCreateDto): Observable<AddressModel> {
    return this.apiService
        .post(`${DeveloperAddressApiService.ROOT_POINT}`, data)
        .pipe(
            map(
                (res: IAddress) => AddressModel.fromJson(res) as AddressModel
            )
        );
}
  get(itemId: number): Observable<AddressModel> {
    return this.apiService
        .get(DeveloperAddressApiService.ROOT_POINT + '/' + itemId)
        .pipe(map(res => AddressModel.fromJson(res) as AddressModel));
  }
  getAddressDefaultByDeveloperId(developerId:number,option?:IApiOption):Observable<AddressModel | null>{
    const query = {
      developerId,
      isDefault:true
    }
    return this.apiService
    .get(DeveloperAddressApiService.ROOT_POINT, query, option)
    .pipe(
      map(data => new PaginationAdapter(AddressModel,data)),
      map(data => {
        if(data.results.length > 0){
          return data.results[0]
        }
        return null
      }));
  }
  update(id: number, data: DeveloperAddressUpdateDto): Observable<any> {
      return this.apiService.patch(DeveloperAddressApiService.ROOT_POINT + '/' + id, data,{excludeFields:['']});
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<AddressModel>> {
      return this.apiService
          .get(DeveloperAddressApiService.ROOT_POINT, query, option)
          .pipe(map(data => new PaginationAdapter(AddressModel,data)));
  }


  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(
      DeveloperAddressApiService.ROOT_POINT, {ids}, option
    );
}

}

