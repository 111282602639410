export enum EReceiptMode {
  ChequeReceipt = 'ChequeReceipt',
  BankDirectDebit = 'BankDirectDebit',
  NETS = 'NETS',
  CashReceipt = 'CashReceipt',
}

export enum EReceiptType {
  OneTimeReceipt = 'OneTimeReceipt',
  CustomerReceipt = 'CustomerReceipt',
  SupplierRefund = 'SupplierRefund',
}

export enum EReceiptAction {
  ADD_TAX_INVOICE = 'TAX_INVOICE',
  ADD_CUSTOMER_DEBIT_NOTE = 'CUSTOMER_DEBIT_NOTE',
  ADD_CUSTOMER_CREDIT_NOTE = 'CUSTOMER_CREDIT_NOTE',
  ADD_SUPPLIER_CREDIT_NOTE = 'SUPPLIER_CREDIT_NOTE',
  ADD_SUPPLIER_ADVANCE = 'SUPPLIER_ADVANCE',
}

export enum EReceiptMemoType {
  CustomerInvoice = 'CustomerInvoice',
  CustomerDebitNote = 'CustomerDebitNote',
  SupplierCreditNote = 'SupplierCreditNote',
}

export enum EReceiptRecordType {
  IV = 'IV', // Invoice
  DN = 'DN', // Debit Note
  SAL = 'SAL', // Taxable Sales Receipt
  VCN = 'VCN', // Supplier (Vendor) Credit Note
  SCN = 'SCN', // Supplier (Vendor) Credit Note
  AD = 'AD', // Supplier Advance
  VI = 'VI', // Supplier (Vendor) Invoice
  CN = 'CN', // Customer Credit Note
}

export enum EReceiptStatus {
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  reversed = 'reversed',
}
