import { BaseModel } from '@red/data-access';
import {  Expose } from 'class-transformer';
import { IAppointmentTypeConfig } from '../interfaces';


export class AppointmentTypeConfigModel extends BaseModel implements IAppointmentTypeConfig {
  @Expose()
  appointmentTypeConfig!: string;

  @Expose()
  name!:string;

  @Expose()
  code!:string;

  @Expose()
  isActive!: boolean;

}
