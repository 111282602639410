import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { MediaStorageService, RED_MEDIA_STORAGE } from '@red/components/media-storage';
import { map, Observable } from 'rxjs';
import { HuttonStorageApiService } from '@shared/features/media-storage';

@Injectable({
  providedIn: 'root',
})
export class EcddStorageApiService extends HuttonStorageApiService {
  TYPE = 'hrm';
}
