import { BaseModel, Default, FormattedDateString, PhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
export class DeveloperCreateDto extends BaseModel {
  @Expose({ groups: ['full', 'general'] })
  name!: string;

  @Expose({ groups: ['full', 'general'] })
  code!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  categoryId!: number;

  @Expose({ groups: ['full', 'general'] })
  groupName!: string;

  @Expose({ groups: ['full', 'general'] })
  role!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  commenceDate!: string;

  @Expose({ groups: ['full', 'general'] })
  email!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose({ groups: ['full', 'general'] })
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose({ groups: ['full', 'business'] })
  businessRegistration!: string;

  @Expose({ groups: ['full', 'business'] })
  @Type(() => Number)
  creditTerm!: number;

  @Expose({ groups: ['full', 'business'] })
  @Type(() => Number)
  creditLimit!: number;

  @Expose({ groups: ['full', 'business'] })
  gstRegistration!: string;

  @Expose({ groups: ['full', 'remark'] })
  remarks!: string;

  @Expose({ groups: ['full', 'account'] })
  @Type(() => Number)
  accountReceivableId!: number;

  @Expose({ groups: ['full', 'account'] })
  @Type(() => Number)
  accountPayableId!: number;
}

export class DeveloperDataUpdateDto extends BaseModel {
  @Expose({ groups: ['full', 'general'] })
  name!: string;

  @Expose({ groups: ['full', 'general'] })
  code!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  categoryId!: number;

  @Expose({ groups: ['full', 'general'] })
  groupName!: string;

  @Expose({ groups: ['full', 'general'] })
  role!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  commenceDate!: string;

  @Expose({ groups: ['full', 'general'] })
  email!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose({ groups: ['full', 'general', 'isActive'] })
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose({ groups: ['full', 'business'] })
  businessRegistration!: string;

  @Expose({ groups: ['full', 'business'] })
  @Type(() => Number)
  creditTerm!: number;

  @Expose({ groups: ['full', 'business'] })
  @Type(() => Number)
  creditLimit!: number;

  @Expose({ groups: ['full', 'business'] })
  gstRegistration!: string;

  @Expose({ groups: ['full', 'remark'] })
  remarks!: string;

  @Expose({ groups: ['full', 'accountCode'] })
  @Type(() => Number)
  accountReceivableId!: number;

  @Expose({ groups: ['full', 'accountCode'] })
  @Type(() => Number)
  accountPayableId!: number;
}

export class DeveloperUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'general' | 'business' | 'remark' | 'accountCode' | 'isActive' | 'full';

  @Expose()
  @Transform(params => {
    console.log('params -->', params.obj);
    return DeveloperDataUpdateDto.fromJson(params.value, { groups: [params.obj.group] });
  })
  data!: DeveloperDataUpdateDto;
}
