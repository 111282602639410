export enum EAgentSession {
    Internal = 'Internal',
    ClosingAgent = 'ClosingAgent',
    TeamLeader = 'TeamLeader',
    BusinessDirector = 'businessDirector',
    HeadHI = 'headHI',
    Pool = 'Pool',
    Bonus = 'Bonus',
    Overriding = 'Overriding',
    InternalRefferal = 'InternalRefferal',
    PDOverride = 'PDOverride',
    PDReferral = 'PDReferral',
    PDManagement = 'PDManagement',
    OTHManagement = 'OTHManagement'
}