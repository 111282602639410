import { BaseModel, StartOfDate, FormattedDateString, EndOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class EducationalRecordDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  qualificationId?: number;

  @Expose()
  @Type(() => Number)
  disciplineId?: number;

  @Expose()
  @Type(() => Number)
  countryId?: number;

  @Expose()
  school?: string;

  @Expose()
  achievement?: string;

  @Expose()
  @Type(() => Boolean)
  highestQualification?: boolean;

  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate?: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate?: string;
}
