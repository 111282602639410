import { ECommMatrixVerifyStatus } from '../enums';

export interface IProjectCommMatrixStatusHistory {
  action: `${ECommMatrixVerifyStatus}`;
  businessUnitId?: number | null;
  createdAt: string;
  createdBy: string;
  data: { reason: string };
  deletedAt: string | null;
  deletedBy: string | null;
  id: number;
  sourceId: number;
  type: string;
  updatedAt: string;
  updatedBy: string | null;
}
