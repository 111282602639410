import { CommissionSalespersonSchemeModel } from '@shared/data-access/models';
import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { map, Observable } from 'rxjs';
import { ICommissionSalespersonScheme } from '@shared/data-access/interfaces';
import { PaginationAdapter } from '@red/data-access';
import { CommSalespersonSchemeCreateDto, CommSalespersonSchemeUpdateDto } from '@shared/data-access/dto';

@Injectable({
  providedIn: 'root',
})
export class CommissionSalespersonSchemeApiService {
  static SALESPERSON_SCHEME = 'hrm/commission-schemes/salesperson';

  constructor(private apiService: ApiService) {}

  create(data: CommSalespersonSchemeCreateDto): Observable<CommissionSalespersonSchemeModel> {
    return this.apiService
      .post(`${CommissionSalespersonSchemeApiService.SALESPERSON_SCHEME}`, data)
      .pipe(map((res: ICommissionSalespersonScheme) => CommissionSalespersonSchemeModel.fromJson(res) as CommissionSalespersonSchemeModel));
  }

  get(itemId: number): Observable<CommissionSalespersonSchemeModel> {
    return this.apiService
      .get(CommissionSalespersonSchemeApiService.SALESPERSON_SCHEME + '/' + itemId)
      .pipe(map(res => CommissionSalespersonSchemeModel.fromJson(res) as CommissionSalespersonSchemeModel));
  }

  update(id: number, data: CommSalespersonSchemeUpdateDto): Observable<any> {
    return this.apiService.patch(CommissionSalespersonSchemeApiService.SALESPERSON_SCHEME + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<CommissionSalespersonSchemeModel>> {
    return this.apiService
      .get(CommissionSalespersonSchemeApiService.SALESPERSON_SCHEME, query, option)
      .pipe(map(data => new PaginationAdapter(CommissionSalespersonSchemeModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(CommissionSalespersonSchemeApiService.SALESPERSON_SCHEME + '/' + id, {}, option);
  }
}
