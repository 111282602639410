import { BaseModel } from '@red/data-access';
import {  Expose, Type } from 'class-transformer';
import {  IPoolDistribution } from '../interfaces';
import { PoolChartModel } from './pool-chart.model';


export class PoolDistributionModel extends BaseModel implements IPoolDistribution {

  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  poolType!: string;

  @Expose()
  @Type(()=> PoolChartModel)
  poolCharts!: PoolChartModel[];

  @Expose()
  isActive!: boolean;

  @Expose()
  status!: string;


}
