import { UpdatedByModel } from '../models';

export interface ILedgerAccountChangeRequest {
  current: string;
  request: string;
}

export interface ILedgerAccount {
  id: number;
  code: string;
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  createdBy?: string;
  updatedBy?: UpdatedByModel;
  deletedBy?: string;
  businessUnitId?: number;
  description: string;
  type?: string;
  refCode?: string;
  parent?: Partial<ILedgerAccount>;
  parentId?: string;
  verifyStatus: string | null;
  rejectReason?: string;
  changeRequest?: Record<string, ILedgerAccountChangeRequest>;
}
