<mat-table class="metabase-table" [dataSource]="dataSource">
  <ng-container *ngFor="let col of columns; let isLastCol = last; let isFirstCol = first" [matColumnDef]="col.columnDef" [sticky]="isFirstCol">
    <mat-header-cell *matHeaderCellDef [redMetabaseTableColSetting]="col">
      <div class="truncate" [matTooltip]="col.header">{{ col.header }}</div>
    </mat-header-cell>
    <mat-cell *matCellDef="let item; let i = index" [redMetabaseTableColSetting]="col">
      <div [matTooltip]="col.cell(item)" [matTooltipDisabled]="isFirstCol" matTooltipPosition="above" class="truncate">
        <div *ngIf="col.template(item, i) as templateSetting; else rawData">
          <div [ngSwitch]="templateSetting.templateRefName">
            <div *ngSwitchCase="'link'">
              <red-metabase-link [metabaseLink]="templateSetting.attrs?.url">
                <ng-container [ngTemplateOutlet]="rawData"></ng-container>
              </red-metabase-link>
            </div>
            <div *ngSwitchDefault>
              <ng-container [ngTemplateOutlet]="rawData"></ng-container>
            </div>
          </div>
        </div>
      </div>
      <ng-template #rawData>
        {{ col.cell(item, i) }}
      </ng-template>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayColums; sticky: true"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayColums"> </mat-row>
</mat-table>
