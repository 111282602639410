import { MetabaseColSetting } from '../models';
import { MetabaseNumberSeparator, MetadataColDataType } from '../models/metabase.type';
import { MetabaseConversionTransformFn } from './data-converter';
const numberFormatRegex = /(\d)(?=(\d{3})+(?!\d))/g;
export const numberDataConverter: MetabaseConversionTransformFn = (value: string, colSetting: MetabaseColSetting) => {
  // if (!colSetting || !colSetting.numberSeparators) {
  //   return value.toString().replace(numberFormatRegex, ',');
  // }
  function converter(num: string, separator: string) {
    return num.toString().replace(/^[+-]?\d+/, function (int) {
      return int.replace(numberFormatRegex, `$1${separator}`);
    });
  }
  switch (colSetting?.numberSeparators) {
    case MetabaseNumberSeparator.COMMA_DOT:
      return converter(String(value), ',');
    case MetabaseNumberSeparator.DOT_COMMA:
      return converter(String(value).replace('.', ','), '.');
    case MetabaseNumberSeparator.SPACE_DOT:
      return converter(String(value), ' ');
    default:
      return converter(String(value), ',');
  }
};
