import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { InvoiceWriteoffCreateDto, InvoiceWriteoffPostingReqStateDto, InvoiceWriteoffUpdateDto } from '@shared/data-access/dto';
import { IInvoiceWriteoff } from '@shared/data-access/interfaces';
import { InvoiceWriteoffModel, InvoiceWriteoffDataModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceWriteoffApiService {
  static ROOT_POINT = 'finance/write-offs';
  static DELETE_POINT = 'finance/write-offs/:id';
  static RECORDS_AND_POSTING_DETAIL = 'finance/write-offs/:id/data';

  constructor(private apiService: ApiService) {}

  create(data: InvoiceWriteoffCreateDto): Observable<InvoiceWriteoffModel> {
    return this.apiService
      .post(`${InvoiceWriteoffApiService.ROOT_POINT}`, data)
      .pipe(map((res: IInvoiceWriteoff) => InvoiceWriteoffModel.fromJson(res) as InvoiceWriteoffModel));
  }

  get(itemId: number, query = {}): Observable<InvoiceWriteoffModel> {
    return this.apiService
      .get(InvoiceWriteoffApiService.ROOT_POINT + '/' + itemId, query)
      .pipe(map(res => InvoiceWriteoffModel.fromJson(res) as InvoiceWriteoffModel));
  }

  getPreviewData(data: InvoiceWriteoffPostingReqStateDto): Observable<InvoiceWriteoffDataModel> {
    return this.apiService
      .post(`${InvoiceWriteoffApiService.ROOT_POINT}/preview/data`, { ...data })
      .pipe(map(res => InvoiceWriteoffDataModel.fromJson(res) as InvoiceWriteoffDataModel));
  }

  getRecordsAndPostings(id: number, query = {}): Observable<InvoiceWriteoffDataModel> {
    return this.apiService
      .get(InvoiceWriteoffApiService.RECORDS_AND_POSTING_DETAIL, { id, ...query })
      .pipe(map(res => InvoiceWriteoffDataModel.fromJson(res) as InvoiceWriteoffDataModel));
  }

  update(id: number, data: InvoiceWriteoffCreateDto): Observable<any> {
    return this.apiService.patch(InvoiceWriteoffApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<InvoiceWriteoffModel>> {
    return this.apiService.get(InvoiceWriteoffApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(InvoiceWriteoffModel, data)));
  }

  delete(id: number, query = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(InvoiceWriteoffApiService.DELETE_POINT, { id, ...query }, option);
  }
}
