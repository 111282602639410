export const PROJECT_TENURES = [
  {
    id: 'freeHold',
    name: 'Freehold',
  },
  {
    id: 'leaseHold',
    name: 'Leasehold',
  },
] as const;
