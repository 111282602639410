export interface IImportJournalEntry {
  journalNumber: string;
  journalDate: string;
  title: string;
  accountCode: string;
  description: string;
  debit: string;
  credit: string;
  profitCentre: string;
  batchCode: string;
  batchDescription: string;
}

export interface IImportJournalEntryColumn {
  id: keyof IImportJournalEntry;
  label: string;
  index: number;
  required?: boolean;
  maxLength?: number;
}
