import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
export class MetabaseCardQueryParameterDto extends BaseModel {
  @Expose()
  id!: string;

  @Expose()
  target!: [string, [string, string]];

  @Expose()
  type!: string;

  @Expose()
  @Transform(({ obj, value }) => {
    return typeof value !== 'undefined' ? value : obj['default'];
  })
  value!: unknown;
}

export class MetabaseCardQueryDto extends BaseModel {
  @Expose()
  @Default(false)
  @Type(() => Boolean)
  collection_preview!: boolean;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  ignore_cache!: boolean;

  @Expose()
  @Type(() => MetabaseCardQueryParameterDto)
  parameters!: MetabaseCardQueryParameterDto[];
}
