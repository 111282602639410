import { ISplitPart } from './split-part.interface';

export interface ISplitMatrix {
  id: number;
  name: string;
  isActive: boolean;
  tiers: ISplitPart[];
  parties: ISplitPart[];
}

export interface ISplitMatrixDto {
  name: string;
  isActive: boolean;
  tiers: ISplitPart[];
  parties: ISplitPart[];
}
