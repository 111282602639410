import { BaseModel, Default, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class GetGstRateDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDate!: string;
}

export class GstRateCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate!: string;

  @Expose()
  @Type(() => Number)
  @Default(1.0)
  gstRate!: number;

  @Expose()
  gstCode!: string;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;
}
