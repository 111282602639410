import { Observable, of } from 'rxjs';
import { Injectable, InjectionToken } from '@angular/core';
import { IPermission } from './interfaces/permission.interface';
export const PERMISSION_LOADER = new InjectionToken<PermissionLoader>('PERMISSION_LOADER');

@Injectable()
export class PermissionLoader {
  get(): Observable<IPermission[]> {
    return of([]);
  }
}
