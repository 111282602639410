import { BaseModel, Default } from '@red/data-access';
import { AbstractListFilters } from '@shared/core';
import { Expose, Transform, Type } from 'class-transformer';

export class ResaleAdjustmentCommissionTierDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  @Default(0)
  @Type(() => Number)
  percentage!: number;

  @Expose()
  @Type(() => Number)
  level!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  value!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;
}

export class ResaleAdjustmentInternalCoBrokeDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  agentId!: number;

  @Expose()
  @Type(() => ResaleAdjustmentCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        ResaleAdjustmentCommissionTierDto.fromJson(
          {
            name: 'Commission',
          },
          options
        ),
        ResaleAdjustmentCommissionTierDto.fromJson(
          {
            name: 'Tier 1',
            level: 2,
          },
          options
        ),
        ResaleAdjustmentCommissionTierDto.fromJson(
          {
            name: 'Tier 2',
            level: 3,
          },
          options
        ),
      ];
    }
    return value.map((item: any) => ResaleAdjustmentCommissionTierDto.fromJson(item, options));
  })
  tiers!: ResaleAdjustmentCommissionTierDto[];

  @Expose()
  @Type(() => ResaleAdjustmentCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        ResaleAdjustmentCommissionTierDto.fromJson(
          {
            name: 'Recruiter',
            level: 4,
          },
          options
        ),
      ];
    }
    return value.map((item: any) => ResaleAdjustmentCommissionTierDto.fromJson(item, options));
  })
  parties!: ResaleAdjustmentCommissionTierDto[];

  @Expose()
  isDefault!: boolean;
}

export class ResaleAdjustmentGrossCommDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  subTotalAmount!: number;

  @Expose()
  taxType!: 'number' | 'percentage';

  @Expose()
  @Type(() => Number)
  taxValue!: number;
}

export class ResaleAdjustmentExternalCoBrokeDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  contactId!: number;

  @Expose()
  @Type(() => ResaleAdjustmentGrossCommDto)
  grossComm!: ResaleAdjustmentGrossCommDto;

  @Expose()
  isDefault!: boolean;
}

export class ResaleAdjustmentRelatedAgentDto extends BaseModel {
  @Expose()
  @Default([])
  @Type(() => ResaleAdjustmentInternalCoBrokeDto)
  mains!: ResaleAdjustmentInternalCoBrokeDto[];

  @Expose()
  @Default([])
  @Type(() => ResaleAdjustmentInternalCoBrokeDto)
  internals!: ResaleAdjustmentInternalCoBrokeDto[];

  @Expose()
  @Default([])
  @Type(() => ResaleAdjustmentExternalCoBrokeDto)
  externals!: ResaleAdjustmentExternalCoBrokeDto[];
}

export class ResaleAdjustmentDataDto extends BaseModel {
  @Expose()
  resaleId!: number;

  @Expose()
  remarks!: string;

  @Expose()
  @Type(() => ResaleAdjustmentGrossCommDto)
  grossComm!: ResaleAdjustmentGrossCommDto;

  @Expose()
  @Type(() => ResaleAdjustmentRelatedAgentDto)
  relatedAgent!: ResaleAdjustmentRelatedAgentDto;
}

export class ResaleAdjustmentUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'full' | 'remark';

  @Expose()
  @Type(() => ResaleAdjustmentDataDto)
  data!: ResaleAdjustmentDataDto;
}


export class ResaleAdjustmentFiltersDto extends AbstractListFilters {
  @Expose()
  searchType!: string;

  @Expose()
  code!: string;

  @Expose()
  resaleCode!: string;

  @Expose()
  status!: string;

}