<div class="flex flex-col flex-auto">
  <div class="flex flex-row justify-between items-center px-4 py-2 border-b">
    <div class="font-bold text-h6">Select</div>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container #container></ng-container>
  <!-- <cms-chart-of-account-selection #selectionComponent></cms-chart-of-account-selection> -->
  <div class="flex flex-row justify-end items-center px-4 py-2 border-t">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button mat-flat-button color="primary" type="button" cdkFocusInitial (click)="save()">Save</button>
  </div>
</div>
