import { Injectable } from '@angular/core';
import { MatDrawerToggleResult } from '@angular/material/sidenav';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export interface DetailComponentForCanDeactivate {
  closeDrawer(): Promise<MatDrawerToggleResult>;
}

@Injectable()
export class DetailControllerCanDeactivate<T extends DetailComponentForCanDeactivate> implements CanDeactivate<T> {
  canDeactivate(
    component: T,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // const routeList = this.getRouteList(nextState.root);
    const currentId = currentRoute.paramMap.get('id');
    if (!currentId) {
      throw new Error(`can't find id param is route`);
    }
    const routeList = currentState.url.split('/' + currentId)[0];
    if (nextState.url.includes('logout')) {
      return true;
    }
    // if(!routeList){
    //   return throwError(() => 'Please set isRouteListRoot is true in Route of List')
    // }
    // Get the next route
    let nextRoute: ActivatedRouteSnapshot | null = nextState.root;
    while (nextRoute && !nextRoute.paramMap.has('id')) {
      nextRoute = nextRoute.firstChild;
    }
    // If the next state doesn't contain '/items'
    // it means we are navigating away from the
    // items app
    if (routeList && !nextState.url.includes(routeList)) {
      // Let it navigate
      return true;
    }
    // If we are navigating to create new one or edit current one...
    if (nextState.url.includes('/create') || nextState.url.includes('/edit')) {
      // Let it navigate
      return true;
    }
    // If we are navigating to another item...
    if (nextRoute && nextRoute.paramMap.get('id')) {
      // Just navigate
      return true;
    }
    // Otherwise...
    else {
      // Close the drawer first, and then navigate
      return component.closeDrawer().then(() => true);
    }
  }

  getRouteList(root: ActivatedRouteSnapshot): string | null {
    let route = root;
    let routeList: string | null = null;
    while (route.firstChild) {
      route = route.firstChild;
    }
    const paths = route.pathFromRoot;
    paths.forEach(path => {
      // Check if there is a 'layout' data
      if (path.routeConfig && path.routeConfig.data && path.routeConfig.data['isRouteListRoot']) {
        // Set the tabIndex
        routeList = path.routeConfig?.path || null;
      }
    });
    return routeList;
  }
}
