import { BaseModel, Default, EndOfDate, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EReceiptMemoType } from '../enums';
import { ReceiptMode, ReceiptType } from '../types';
export class ReceiptDealValueDto extends BaseModel {
  @Expose()
  @Default(0)
  @Type(() => Number)
  subAmount!: number;
}

export class ReceiptRateGroupDealValueDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  tax!: number;
}

export class ReceiptRecordMetadataDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  taxPercent!: number;

  @Expose()
  @Default(false)
  isEdit!: boolean;
}

export class ReceiptRecordDto extends BaseModel {
  @Expose()
  id?: number;

  @Expose()
  receiptId?: number;

  @Expose()
  type!: string;

  @Expose()
  accountId!: number;

  @Expose()
  profitCenterId!: number;

  @Expose()
  receiptMemoId!: number; // Invoice id

  @Expose()
  @Transform(({ value }) => value || null)
  receiptMemoType!: `${EReceiptMemoType}`;

  @Expose()
  description!: string;

  @Expose()
  @Default({ subAmount: 0 })
  @Type(() => ReceiptDealValueDto)
  debit!: ReceiptDealValueDto;

  @Expose()
  @Default({ subAmount: 0 })
  @Type(() => ReceiptDealValueDto)
  credit!: ReceiptDealValueDto;

  @Expose()
  @Default(false)
  isAdvance!: boolean;

  @Expose()
  @Default(false)
  isTaxable!: boolean;

  @Expose()
  @Type(() => Number)
  taxAccountId?: number;

  @Expose()
  taxDescription!: string;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Default('gstExclusive')
  gstType!: string;

  @Expose()
  @Default('percentage')
  taxUnit!: string;

  @Expose()
  @Type(() => Number)
  taxValue!: number;

  @Expose()
  @Type(() => Number)
  taxAmount?: number;

  @Expose()
  @Type(() => ReceiptRecordMetadataDto)
  metadata!: ReceiptRecordMetadataDto;

  @Expose()
  @Type(() => Number)
  sequence!: number;
}

export class ReceiptPostingDto extends BaseModel {
  @Expose()
  accountId!: number;

  @Expose()
  profitCenterId!: number;

  @Expose()
  description!: string;

  @Expose()
  @Default(0)
  @Type(() => Number)
  debit!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  credit!: number;

  @Expose()
  @Default(1)
  sequence!: number;

  @Expose()
  amount!: number;
}

export class ReceiptCreateDto extends BaseModel {
  @Expose()
  type!: ReceiptType;

  @Expose()
  mode!: ReceiptMode;

  @Expose()
  payerName!: string;

  @Expose()
  contactId!: number;

  @Expose()
  accountId!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  receiptDate!: string;

  @Expose()
  currency!: string;

  @Expose()
  reference!: string;

  @Expose()
  templateId!: number;

  @Expose()
  @Type(() => ReceiptRecordDto)
  details!: ReceiptRecordDto[];

  @Expose()
  @Type(() => ReceiptPostingDto)
  postings!: ReceiptPostingDto[];
}

export class ReceiptUpdateDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  type!: ReceiptType;

  @Expose()
  mode!: ReceiptMode;

  @Expose()
  accountId!: number;

  @Expose()
  contactId!: number;

  @Expose()
  payerName!: string;

  @Expose()
  currency!: string;

  @Expose()
  reference!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  receiptDate!: string;

  @Expose()
  templateId!: number;

  @Expose()
  @Type(() => ReceiptRecordDto)
  details!: ReceiptRecordDto[];

  @Expose()
  @Type(() => ReceiptPostingDto)
  postings!: ReceiptPostingDto[];
}

export class ReceiptDealValueReqDto extends BaseModel {
  @Expose()
  @Default(0)
  @Type(() => Number)
  subAmount!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  subTotalAmount!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  totalAmount!: number;

  @Expose()
  @Type(() => ReceiptRateGroupDealValueDto)
  rateGroup!: ReceiptRateGroupDealValueDto;
}

export class ReceiptRecordPostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => Number)
  profitCenterId!: number;

  @Expose()
  postingDescription!: string;

  @Expose()
  @Default({ amount: 0, rateGroup: {}, subAmount: 0, subTotalAmount: 0, totalAmount: 0 })
  @Type(() => ReceiptDealValueReqDto)
  debit!: ReceiptDealValueReqDto;

  @Expose()
  @Default({ amount: 0, rateGroup: {}, subAmount: 0, subTotalAmount: 0, totalAmount: 0 })
  @Type(() => ReceiptDealValueReqDto)
  credit!: ReceiptDealValueReqDto;

  @Expose()
  isTaxable!: boolean;

  @Expose()
  @Type(() => Number)
  taxAmount!: number;

  @Expose()
  @Type(() => Number)
  taxAccountId!: number;

  @Expose()
  postingTaxDescription!: string;
}

export class ReceiptPostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number | null;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => ReceiptRecordPostingReqDto)
  details!: ReceiptRecordPostingReqDto[];
}

export class ReceiptDateFilterDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;
}

export class PreviewReceiptTemplateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  templateId!: number;

  @Expose()
  templateCode!: string;

  @Expose()
  type!: string;

  @Expose()
  templateType!: string;

  @Expose()
  @Type(() => Number)
  invoiceId!: number;

  @Expose()
  @Type(() => Number)
  sourceId!: number;
}
