export enum EResaleStatus {
  draft = 'draft',
  submitted = 'submitted',
  rework = 'rework',
  withdrawn = 'withdrawn',
  confirmed = 'confirmed',
  sendBack = 'sendBack',
  verified = 'verified',
  approved = 'approved',
  approvedPC = 'approvedPC',
  receivedPartial = 'receivedPartial',
  partial = 'partial',
  cancelled = 'cancelled',
  aborted = 'aborted',
  writeOff = 'writeOff',
  rejected = 'rejected',
  completed = 'completed',
  cancelledPartial = 'cancelledPartial',
  received = 'received',
}

export enum EResaleType {
  resale = 'resale',
  lease = 'lease',
  developerSale = 'developerSale',
  assignment = 'assignment',
  enBloc = 'enBloc',
  leaseRental = 'leaseRental',
}

export enum EResalePropertyType {
  ResidentialCondominiumApartment = 'ResidentialCondominiumApartment',
  ResidentialExecutiveCondominium = 'ResidentialExecutiveCondominium',
  ResidentialHDB = 'ResidentialHDB',
  LandedTerraceHouse = 'LandedTerraceHouse',
  LandedSemiDetachedHouse = 'LandedSemiDetachedHouse',
  LandedDetachedHouse = 'LandedDetachedHouse',
  LandedStrataHouse = 'LandedStrataHouse',
  CommercialOffice = 'CommercialOffice',
  CommercialShopHouse = 'CommercialShopHouse',
  CommercialRetail = 'CommercialRetail',
  IndustrialWarehouse = 'IndustrialWarehouse',
  IndustrialSingleUserFactory = 'IndustrialSingleUserFactory',
  IndustrialMultipleUserFactory = 'IndustrialMultipleUserFactory',
  // Retail = 'Retail',
  IndustrialBusinessPark = 'IndustrialBusinessPark',

  Landed = 'LANDED',
  Commercial = 'COMMERCIAL',
  EcAptCondoClusterHse = 'EC/APT/CONDO/CLUSTER HSE',
  Industrial = 'INDUSTRIAL',
}

export enum EResalePropertyTypeSearch {
  ResidentialCondominiumApartment = 'ResidentialCondominiumApartment',
  ResidentialExecutiveCondominium = 'ResidentialExecutiveCondominium',
  ResidentialHDB = 'ResidentialHDB',
  LandedTerraceHouse = 'LandedTerraceHouse',
  LandedSemiDetachedHouse = 'LandedSemiDetachedHouse',
  LandedDetachedHouse = 'LandedDetachedHouse',
  LandedStrataHouse = 'LandedStrataHouse',
  CommercialOffice = 'CommercialOffice',
  CommercialShopHouse = 'CommercialShopHouse',
  CommercialRetail = 'CommercialRetail',
  IndustrialWarehouse = 'IndustrialWarehouse',
  IndustrialSingleUserFactory = 'IndustrialSingleUserFactory',
  IndustrialMultipleUserFactory = 'IndustrialMultipleUserFactory',
  Landed = 'Landed',
  Commercial = 'Commercial',
  EcAptCondoClusterHse = 'EC/APT/CONDO/CLUSTER HSE',
  Industrial = 'Industrial',
  IndustrialBusinessPark = 'IndustrialBusinessPark',
}

export enum EFurnished {
  full = 'full',
  partial = 'partial',
  unfurnished = 'unfurnished',
}

export enum EPaymentAdvice {
  tenant = 'tenant',
  landlord = 'landlord',
  cobroke = 'cobroke',
  buyer = 'buyer',
  seller = 'seller',
  external = 'external',
  poa = 'poa',
  solicitor = 'solicitor',
}

export enum EClientType {
  tenant = 'tenant',
  landlord = 'landlord',
  buyer = 'buyer',
  seller = 'seller',
  cobroke = 'cobroke',
  poa = 'poa',
  solicitor = 'solicitor',
}

export enum EGstType {
  gstInclusive = 'gstInclusive',
  gstExclusive = 'gstExclusive',
  gstNo = 'gstNo',
}

export enum ECommType {
  percentage = 'percentage',
  value = 'value',
}

export enum EResaleAgentType {
  cobroke = 'cobroke',
  referral = 'referral',
}

export enum EBillingTo {
  seller = 'seller',
  buyer = 'buyer',
  external = 'external',
  poa = 'poa',
  solicitor = 'solicitor',
}

export enum EResaleDocumentType {
  optionToPurchase = 'optionToPurchase',
  ceaForm = 'ceaForm',
  coBrokeForm = 'coBrokeForm',
  paymentReceipt = 'paymentReceipt',
  others = 'others',
  tenancyAgreementOrRentalRenewal = 'tenancyAgreementOrRentalRenewal',
  pc01 = 'pc01',
}

export enum EResaleDocumentTypeOfHip {
  optionToPurchase = 'OptionToPurchase',
  ceaForm = 'CEAForms',
  coBrokeForm = 'CoBrokeForms',
  paymentReceipt = 'CommissionPayment',
  others = 'SupportingDocuments',
  tenancyAgreementOrRentalRenewal = 'TenancyAgreement',
  pc01 = 'PC0120Documents',
}

export enum EResalePaymentMethod {
  // byPost = 'byPost',
  dropCheque = 'dropCheque',
  bankTransfer = 'bankTransfer',
}

export enum EResaleUserRole {
  agent = 'agent',
  internal = 'internal',
}

export enum EResaleSubmitLateReason {
  pendingDocuments = 'Pending for document(s) / payment',
  submitUponExerciseOfLease = 'Submit upon exercise / completion / commencement of lease',
  busy = 'Busy / Overlook',
  others = 'Others',
}

export enum EResaleDialogType {
  dropChequeNote = 'dropChequeNote',
  bankTransferNote = 'bankTransferNote',
  dropChequeError = 'dropChequeError',
  bankTransferError = 'bankTransferError',
}

export enum EResaleEmailTo {
  agent = 'agent',
  ecb = 'ecb',
  client = 'client',
  solicitor = 'solicitor',
  hardcopy = 'hardcopy',
}

export enum EResaleEmailStatus {
  draft = 'draft',
  submitted = 'submitted',
  sent = 'sent',
  failed = 'failed',
}

export enum EResaleCommissionTaxType {
  percentage = 'percentage',
  number = 'number',
  absolute = 'absolute',
}

export enum EResaleNoOfRoom {
  'Studio' = 'Studio',
  '1B' = '1B',
  '1B+' = '1B+',
  '2B' = '2B',
  '2B+' = '2B+',
  '3B' = '3B',
  '3B+' = '3B+',
  '4B' = '4B',
  '4B+' = '4B+',
  '5B' = '5B',
  '>5B' = '>5B',
}
export enum EResaleNoOfRoomHdb {
  '1 Room' = '1 Room',
  '2 Room' = '2 Room',
  '3 Room' = '3 Room',
  '4 Room' = '4 Room',
  '5 Room' = '5 Room',
  'Multi-Gen' = 'Multi-Gen',
  'Jumbo' = 'Jumbo',
  'Executive' = 'Executive',
  'Maisonette' = 'Maisonette',
  'Terrace House' = 'Terrace House',
  'Others' = 'Others',
}
