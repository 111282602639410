import { IUpdatedBy } from './updated-by.interface';
import { IUserGroupAccessible } from './user-group.interface';

export interface IUserRole {
  id: number;
  name: string;
  code: string;
  description: string;
  // status: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: IUpdatedBy;
  accessible: IUserGroupAccessible[];
}
