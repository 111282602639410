import { IHmsProfle } from './../interfaces';
import { BaseModel } from '@red/data-access';
import {  Expose } from 'class-transformer';

export class HmsProfileModel extends BaseModel implements IHmsProfle{
  @Expose()
  id!: number;

  @Expose()
  type!: string;

  @Expose()
  username!: string;

  @Expose()
  email!: string;

  @Expose()
  name!: string;

  @Expose()
  avatar!: string;

  @Expose()
  address!: string;

}
