import { Directive, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject, takeUntil, tap, zip } from 'rxjs';
import { MetabaseCardParameter, MetabaseDataSetQueryTemplateTag } from '../models/metabase.model';

@Directive()
export abstract class MetabaseParameterFieldBase implements OnDestroy {
  protected _unsubscribeAll: Subject<void> = new Subject<void>();
  constructor() {
    zip(this.dataChange$, this.templateTagChange$).subscribe(([card, parameter]) => {
      this.init();
    });
  }
  public get data(): Partial<MetabaseCardParameter> {
    return this._data;
  }
  public set data(value: Partial<MetabaseCardParameter>) {
    this._data = value;
    if (value) {
      this.dataChange$.next(value);
    }
  }
  protected _data!: Partial<MetabaseCardParameter>;
  public get templateTag(): MetabaseDataSetQueryTemplateTag | undefined {
    return this._templateTag;
  }
  public set templateTag(value: MetabaseDataSetQueryTemplateTag | undefined) {
    this._templateTag = value;
    if (value) {
      this.templateTagChange$.next(value);
    }
  }
  protected _templateTag?: MetabaseDataSetQueryTemplateTag;

  dataChange$ = new Subject<Partial<MetabaseCardParameter>>();
  templateTagChange$ = new Subject<MetabaseDataSetQueryTemplateTag>();
  dataUpcast = new FormControl<unknown>('', []);
  dataDowncast = new FormControl<unknown>('', []);
  abstract get controlType(): string;

  onValueChange(val: unknown): void {
    this.dataDowncast?.setValue(this.toDataDowncastValue(this.data, val));
  }
  abstract toDataDowncastValue(scheme: Partial<MetabaseCardParameter>, val: unknown): unknown;
  abstract toDataUpcastValue(scheme: Partial<MetabaseCardParameter>, val: unknown): unknown;

  init(): void {
    const dataDowncast = this.toDataUpcastValue(this.data, this.data.value);
    this.dataUpcast.setValue(dataDowncast);
    // this.dataDowncast = new FormControl('', []);
    this.dataUpcast.valueChanges
      .pipe(
        debounceTime(300),
        tap(val => {
          this.onValueChange(val);
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
