import { BaseModel, StripComma } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ImportJournalEntryItemDto extends BaseModel {
  @Expose()
  @Type(() => String)
  journalNumber?: string;

  @Expose()
  @Type(() => String)
  journalDate?: string;

  @Expose()
  @Type(() => String)
  title?: string;

  @Expose()
  @Type(() => String)
  accountCode?: string;

  @Expose()
  @Type(() => String)
  description?: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  debit?: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  credit?: string;

  @Expose()
  @Type(() => String)
  profitCentre?: string;

  @Expose()
  @Type(() => String)
  batchCode?: string;

  @Expose()
  @Type(() => String)
  batchDescription?: string;
}

export class ImportJournalEntryDto extends BaseModel {
  @Expose()
  @Type(() => ImportJournalEntryItemDto)
  items?: ImportJournalEntryItemDto[];
}
