import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { TAX_INVOICE_PAID_STATUSES, TAX_INVOICE_STATUSES } from '../data';
import {
  EBalanceDocumentCode,
  EBalanceDocumentPostingType,
  ECustomerOrSupplier,
  EGstCategoryCustomer,
  EInvoiceCreateMode,
  EInvoicePaidStatus,
  ETaxInvoiceStatus,
} from '../enums/tax-invoice-enhanced.enum';
import { IStatusDescription } from '../interfaces';
import { ITaxInvoice, ITaxInvoicePosting, ITaxInvoiceRecord, ITaxInvoiceSummary } from '../interfaces/tax-invoice-enhanced.interface';
import { ContactAddressModel } from './contact-address.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { ContactPersonModel } from './contact-person.model';
import { CurrencyRateModel } from './currency-rate.model';
import { GstCategoryLookupModel } from './gst-category.model';
import { LedgerAccountModel } from './ledger-account.model';
import { PersonalParticularModel } from './personal-particular.model';
import { ProfitCentresModel } from './profit-centres.model';
import { TemplateModel } from './template.model';
import { UpdatedByModel } from './updated-by.model';

export class TaxInvoiceRecordModel extends BaseModel implements ITaxInvoiceRecord {
  @Expose()
  id!: number;

  @Expose()
  itemCode?: string;

  @Expose()
  description!: string;

  @Expose()
  amount!: number;

  @Expose()
  quantity!: number;

  @Expose()
  unitPrice!: number;

  @Expose()
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Transform(({ obj }) => {
    switch (obj.gstCategory) {
      case EGstCategoryCustomer.ZR:
        return 'N';
      case EGstCategoryCustomer.SR:
        return 'Y';
      case EGstCategoryCustomer.ES33:
        return 'E';
      default:
        return obj.gstCategory;
    }
  })
  gstCategoryDisplay!: string;

  @Expose()
  @Type(() => GstCategoryLookupModel)
  gstCategoryLookup?: GstCategoryLookupModel;

  @Expose()
  @Transform(({ obj }) => obj.gstPercent ?? Number(obj.gstCategoryLookup?.gst_rate))
  @Type(() => Number)
  gstPercent?: number;

  @Expose()
  @Transform(({ obj }) => obj.gstCharged ?? Number(obj.gstCategoryLookup?.gst_charged))
  @Type(() => Number)
  gstCharged?: number;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  gstValue?: number;

  // @Expose()
  // gstPercent?: number;

  @Expose()
  bankReferralFee?: boolean;

  @Expose()
  uom?: string;

  @Expose()
  remarks?: string;

  @Expose()
  remarkAddress?: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter!: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  // @Expose()
  // taxInvoice!: TaxInvoiceModel;

  @Expose()
  taxInvoiceId!: number;

  @Expose()
  agentId?: number;

  @Expose()
  nric?: string;

  @Expose()
  @Type(() => PersonalParticularModel)
  agent?: PersonalParticularModel;

  @Expose()
  @Transform(({ obj, value }) => {
    if (value) return value

    // Support Migration
    if (obj.nric) return obj.nric

    return `${obj.agent?.nricName} ${obj.agent?.ceaRegNo ? '(' + obj.agent?.ceaRegNo + ')' : ''}` ?? ''
  })
  agentName?: string;

  @Expose()
  referralDescription?: string;
}

export class TaxInvoicePostingModel extends BaseModel implements ITaxInvoicePosting {
  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  description?: string;

  @Expose()
  debit!: number;

  @Expose()
  credit!: number;

  @Expose()
  amount!: number;

  @Expose()
  currency?: string;

  @Expose()
  taxInvoiceId!: number;

  @Expose()
  taxInvoiceDetailId?: number;

  // @Expose()
  // taxInvoiceDetail?: ITaxInvoiceDetailEnhanced;

  @Expose()
  type?: EBalanceDocumentPostingType;
}

export class TaxInvoicePostingAcccountModel extends BaseModel {
  @Expose()
  accountName!: string;

  @Expose()
  accountCode!: string;

  @Expose()
  accountId!: string;

  @Expose()
  @Transform(({ obj }) => {
    const normalized = `${obj.accountCode || ''} ${obj.accountName || ''}`;
    return normalized.trim();
  })
  normalizedAccountName!: string;
}

export class TaxInvoiceModel extends BaseModel implements ITaxInvoice {
  // For CN only
  @Expose()
  @Type(() => TaxInvoicePostingAcccountModel)
  posting?: TaxInvoicePostingAcccountModel

  // General
  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  @Type(() => ContactAddressModel)
  billingAddress!: ContactAddressModel;

  @Expose()
  contactId?: number;

  @Expose()
  contactAddressId!: number;

  @Expose()
  invoiceNumber?: string;

  @Expose()
  invoiceDate!: string;

  @Expose()
  invoiceDueDate?: Date;

  @Expose()
  @Type(() => ContactPersonModel)
  contactPerson?: ContactPersonModel;

  @Expose()
  contactPersonId?: number;

  @Expose()
  customerName?: string;

  @Expose()
  inPaymentBilling?: boolean;

  @Expose()
  inClosedPeriod?: boolean;

  // Summary
  @Expose()
  gstEdited!: boolean;

  @Expose()
  amount!: number;

  @Expose()
  discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gst?: number;

  // Other Details
  @Expose()
  currencyId!: number;

  @Expose()
  currencyCode?: string;

  @Expose()
  @Type(() => CurrencyRateModel)
  currency!: CurrencyRateModel;

  @Expose()
  billingAddressCustom?: string;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  reference?: string;

  @Expose()
  creditTerm?: number;

  @Expose()
  paymentTerm?: string;

  @Expose()
  salePersonId?: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson?: PersonalParticularModel;

  @Expose()
  remarks?: string;

  @Expose()
  templateId?: number;

  @Expose()
  @Type(() => TemplateModel)
  template?: TemplateModel;

  // General
  @Expose()
  id!: number;

  @Expose()
  businessUnitId?: number;

  @Expose()
  status?: ETaxInvoiceStatus;

  @Expose()
  @Transform(({ obj }) => {
    return TAX_INVOICE_STATUSES[obj.status as ETaxInvoiceStatus];
  })
  displayStatus!: IStatusDescription;

  @Expose()
  createdMode?: EInvoiceCreateMode;

  @Expose()
  invoiceType?: ECustomerOrSupplier.CUSTOMER;

  @Expose()
  balanceDocumentCodeType?: EBalanceDocumentCode;

  @Expose()
  @Transform(({ value }) => {
    if (!value) return undefined;
    if (typeof value === 'string' && !isNaN(+value)) return undefined;

    return TAX_INVOICE_PAID_STATUSES[value as EInvoicePaidStatus]?.name;
  })
  paidStatus?: string;

  @Expose()
  balanceDue?: number;

  // Base
  @Expose()
  createdAt?: string;

  @Expose()
  updatedAt?: string;

  @Expose()
  createdBy?: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  paidAmount?: number;

  @Expose()
  @Transform(({ obj }) => {
    // const isStatusInvalid = [ETaxInvoiceStatus.PaymentInProgress, ETaxInvoiceStatus.Withhold, ETaxInvoiceStatus.FullyPaid].includes(obj.status);
    const isStatusInvalid = [ETaxInvoiceStatus.Cancelled, ETaxInvoiceStatus.FullyPaid].includes(obj.status);
    // const isPaidStatusInvalid = [EInvoicePaidStatus.FULLY_PAID, EInvoicePaidStatus.CREDITED].includes(obj.paidStatus);
    const isPaidStatusInvalid = [EInvoicePaidStatus.CANCELLED].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.balanceDue === 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid;
  })
  canWriteOff!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    // const isStatusInvalid = [ETaxInvoiceStatus.PaymentInProgress, ETaxInvoiceStatus.Withhold, ETaxInvoiceStatus.FullyPaid].includes(obj.status);
    const isStatusInvalid = [ETaxInvoiceStatus.FullyPaid, ETaxInvoiceStatus.Cancelled].includes(obj.status);
    // const isPaidStatusInvalid = [EInvoicePaidStatus.FULLY_PAID, EInvoicePaidStatus.CREDITED].includes(obj.paidStatus);
    const isPaidStatusInvalid = [EInvoicePaidStatus.CANCELLED].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.balanceDue === 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inPaymentBilling;
  })
  canMakeReceipt!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = [ETaxInvoiceStatus.FullyPaid, ETaxInvoiceStatus.PartPaid, ETaxInvoiceStatus.Cancelled].includes(obj.status);
    // const isPaidStatusInvalid = [EInvoicePaidStatus.FULLY_PAID, EInvoicePaidStatus.CREDITED].includes(obj.paidStatus);
    const isPaidStatusInvalid = [EInvoicePaidStatus.CANCELLED].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod && !obj.inPaymentBilling;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = [ETaxInvoiceStatus.FullyPaid, ETaxInvoiceStatus.PartPaid].includes(obj.status);
    // const isPaidStatusInvalid = [EInvoicePaidStatus.FULLY_PAID, EInvoicePaidStatus.CREDITED].includes(obj.paidStatus);
    const isPaidStatusInvalid = false;
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod && !obj.inPaymentBilling;
  })
  canDelete!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = [ETaxInvoiceStatus.FullyPaid, ETaxInvoiceStatus.PartPaid, ETaxInvoiceStatus.Cancelled].includes(obj.status);
    const isPaidStatusInvalid = [EInvoicePaidStatus.CANCELLED].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod && !obj.inPaymentBilling;
  })
  canCancel!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = [ETaxInvoiceStatus.FullyPaid, ETaxInvoiceStatus.PartPaid, ETaxInvoiceStatus.Cancelled].includes(obj.status);
    // const isPaidStatusInvalid = [EInvoicePaidStatus.FULLY_PAID, EInvoicePaidStatus.CREDITED].includes(obj.paidStatus);
    const isPaidStatusInvalid = [EInvoicePaidStatus.CANCELLED].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod && !obj.inPaymentBilling;
  })
  isLock!: boolean;

  @Expose()
  projectId?: number;

  // @Expose()
  // invoiceType?: ECustomerOrSupplier;

  // @Expose()
  // printFormat?: string;

  // @Expose()
  // ecbInvoiceDate?: string;

  // @Expose()
  // paidStatus?: string;

  // @Expose()
  // balanceDue?: number;

  // @Expose()
  // parentInvoice?: TaxInvoiceModel;

  // @Expose()
  // parentInvoiceId?: number;

  // @Expose()
  // batchComment?: string;

  // @Expose()
  // batchName?: string;

  // @Expose()
  // batchCode?: string;

  // @Expose()
  // attention?: string;

  // @Expose()
  // supplierInvoiceType?: ESupplierInvoice;

  // @Expose()
  // adjustment?: boolean;

  // @Expose()
  // fileAttachedName?: string;

  // @Expose()
  // fileAttachedViewName?: string;

  // @Expose()
  // balanceDocumentCodeType?: EBalanceDocumentCode;
}

export class TaxInvoiceSummaryModel extends BaseModel implements ITaxInvoiceSummary {
  @Expose()
  amount!: number;

  @Expose()
  discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gst?: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gstEdited!: boolean;
}
