import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { BLANK_FORM_REFERRAL_FORMULA_OPTIONS, BLANK_FORM_REFERRAL_TYPE_OPTIONS } from '../data';
import { EAgentBlankFormSession, EBLankFormReferralFormula, EBlankFormReferralSourceType, EBlankFormReferralType } from '../enums';
import { IBlankFormReferral } from '../interfaces';
import { PersonalParticularModel } from './personal-particular.model';
import { ReferralExternalModel } from './referral-external.model';
import { SplitPartModel } from './split-part.model';

export class BlankFormReferralModel extends BaseModel implements IBlankFormReferral {
  @Expose()
  id!: number;

  @Expose()
  blankFormReferralType!: EBlankFormReferralType;

  @Expose()
  type!: EBlankFormReferralSourceType;

  @Expose()
  formula!: EBLankFormReferralFormula;

  @Expose()
  @Default({
    agent: true,
    hr: true,
  })
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  agentId!: number;

  @Expose()
  @Type(() => ReferralExternalModel)
  agent!: ReferralExternalModel;

  @Expose()
  agentType!: EAgentBlankFormSession;

  @Expose()
  blankFormId!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  receivedValue!: number;

  @Expose()
  defaultValue!: number;

  @Expose()
  updatedAt!: string;

  @Expose()
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  note: any;

  @Expose()
  data: any;

  @Expose()
  name!: string;

  @Expose()
  get blankFormReferralTypeDescription() {
    return BLANK_FORM_REFERRAL_TYPE_OPTIONS[this.blankFormReferralType];
  }

  @Expose()
  get formulaDisplay() {
    return BLANK_FORM_REFERRAL_FORMULA_OPTIONS[this.formula]?.viewValue;
  }

  @Expose()
  attrs!: any;

  @Expose()
  grouping!: string;

  @Expose()
  appTypeCode!: string;

}
