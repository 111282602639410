import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { AgentProjectTransactionModel, ProjectTransactionAgentModel, UnitModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgentProjectTransactionApiService {
  static ROOT_POINT = 'transaction/portal/transactions/agent';
  static UNIT_FOR_SALE_POINT = 'transaction/units/portal/for-sale';
  static STATUS_HISTORIES_POINT = 'transaction/status-histories/portal';
  static DASHBOARD_POINT = 'transaction/dashboard/portal/transactions';

  constructor(private apiService: ApiService) { }

  get(itemId: number): Observable<AgentProjectTransactionModel> {
    return this.apiService
      .get(AgentProjectTransactionApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => AgentProjectTransactionModel.fromJson(res) as AgentProjectTransactionModel));
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionAgentModel>> {
    return this.apiService
      .get(AgentProjectTransactionApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectTransactionAgentModel, data)));
  }

  searchDashboard(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionAgentModel>> {
    return this.apiService
      .get(AgentProjectTransactionApiService.DASHBOARD_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionAgentModel, data)));
  }

  searchUnits(type: 'forSale', query = {}, option?: IApiOption): Observable<PaginationAdapter<UnitModel>> {
    const endPoint = AgentProjectTransactionApiService.UNIT_FOR_SALE_POINT;
    return this.apiService.get(endPoint, query, option).pipe(map(data => new PaginationAdapter(UnitModel, data)));
  }

  searchStatusHistories(query = {}, option?: IApiOption): Observable<PaginationAdapter<any>> {
    return this.apiService
      .get(AgentProjectTransactionApiService.STATUS_HISTORIES_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionAgentModel, data)));
  }
}
