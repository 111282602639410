import { IPersonalParticular, IProfitSplitPart, ISplitPart } from '.';
import { ECommType, EResaleCommissionTaxType } from '../enums';
import { IPhoneNumber } from '@red/data-access';

export interface IResaleCoBroke {
  id: number;
  agentId: number;
  agent: IPersonalParticular;
  brokerName: string;
  brokerIdNo: string;
  brokerPhone: IPhoneNumber;
  brokerEmail: string;
  receivedPercent: number;
  receivedValue: number;
  absorbType: { agent: boolean; hr: boolean };
  attrs: {
    sequence: number;
    commission?: {
      type: EResaleCommissionTaxType;
      value: number;
    };
    [key: string]: any;
  };
  commType: ECommType;
  tiers: ISplitPart[];
  parties: ISplitPart[];
  profitTiers: IProfitSplitPart[];
  isDefault?: boolean;
  advises?: IResaleCoBrokeAdvises[];
  grouping?: string;
}

export interface IResaleCoBrokeAdvises {
  agentId: number;
  batchCode: string;
  commSchemeCode: string;
  grouping: string;
  tiers: ISplitPart[];
  parties: ISplitPart[];
  receivedPercent: number;
  receivedValue: number;
  splitMatrixId: number;
  totalAmount: number;
  transactionCode: string;
  transactionType: string;
  type: string;
}
