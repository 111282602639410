import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationItemRouterLinkService {
  private linkUpdator = new ReplaySubject<(link: string) => string>(1);
  updateLink(fn: (link: string) => string): void {
    this.linkUpdator.next(fn);
  }
  onLinkUpdate(): Observable<(link: string) => string> {
    return this.linkUpdator.asObservable();
  }
}
