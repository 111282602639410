import { EBusinessDirector } from '../enums';
import { IAppointmentType } from './appointment-type.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { ITimestamp } from './timestamp.interface';

export interface IBusinessDirector extends ITimestamp {
  id: number;
  projectId: number;
  salespersonId: number;
  salesperson: IPersonalParticular;
  type: EBusinessDirector;
  appointmentId: number;
  appointmentType: IAppointmentType;
  startDate: string;
  endDate: string | null;
  isPersonInCharge: boolean;
  appTypes: { appType: IAppointmentType }[];
}
