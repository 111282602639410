import { BaseModel } from '@red/data-access';
import {Expose, Type} from 'class-transformer';
import { IDepartment } from '../interfaces/department.interface';
import {PersonalParticularModel} from "@shared/data-access/models/personal-particular.model";
import {IPersonalParticular} from "@shared/data-access/interfaces";

export class DepartmentModel extends BaseModel implements IDepartment {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  // @Expose()
  // status!: string;

  @Expose()
  level!: number;

  @Expose()
  isActive!: boolean;

  @Expose()
  salesperson!: IPersonalParticular;

  @Expose()
  salespersonId!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string | null;

  @Expose()
  createdBy!: string | null;

  @Expose()
  updatedBy!: string | null;

  @Expose()
  deletedBy!: string | null;
}
