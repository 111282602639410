import { BaseModel, Default, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EFinanceCalculatingOrigin } from '../enums';
import { EBalanceDocumentPostingType, ECustomerOrSupplier } from '../enums/tax-invoice-enhanced.enum';

export class TaxInvoiceRecordDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  itemCode?: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount?: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Default(false)
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  accountId?: number;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  @Type(() => String)
  @Transform(({ value }) => value.replace(/[ \t\r\n\f]+$/g, ''))
  remarks?: string;

  @Expose()
  remarkAddress?: string;

  @Expose()
  bankReferralFee?: boolean;

  @Expose()
  description?: string;

  @Expose()
  uom?: string;

  @Expose()
  @Type(() => Number)
  agentId?: number;

  @Expose()
  referralDescription?: string;
}

export class TaxInvoicePostingDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  debit?: number;

  @Expose()
  @Type(() => Number)
  credit?: number;

  @Expose()
  type?: EBalanceDocumentPostingType;
}

export class TaxInvoiceDto extends BaseModel {
  //  ====== General ======
  @Expose()
  @Default(ECustomerOrSupplier.CUSTOMER)
  invoiceType!: ECustomerOrSupplier;

  @Expose()
  @Type(() => Number)
  contactId?: number;

  @Expose()
  @Type(() => Number)
  contactPersonId?: number;

  @Expose()
  @Type(() => Number)
  contactAddressId?: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDate!: Date;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDueDate!: Date;

  // @Expose()
  // @Default('SGD')
  // @Transform(({ value }) => 'SGD')
  // currency!: string;

  @Expose()
  @Type(() => Number)
  currencyId!: number;

  //  ====== Other detail ======
  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  // @Type(() => Number)
  // @Default(0)
  // paymentTerm?: number;
  paymentTerm?: string;

  // @Expose()
  // printFormat?: string;

  @Expose()
  @Type(() => Number)
  salePersonId?: number;

  @Expose()
  billingAddressCustom?: string;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  reference?: string;

  @Expose()
  @Type(() => Number)
  templateId?: number;

  @Expose()
  invoiceNumber?: string;

  //  ====== Calculate data =====
  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  gstPercent?: number;

  // @Expose()
  // @Type(() => Number)
  // taxValue?: number;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  gstEdited?: boolean;

  @Expose()
  @Type(() => TaxInvoiceRecordDto)
  details!: TaxInvoiceRecordDto[];

  @Expose()
  @Type(() => TaxInvoicePostingDto)
  postings!: TaxInvoicePostingDto[];
}

export class TaxInvoicePostingReqItemDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  amount?: number;
}

export class TaxInvoicePostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Default(ECustomerOrSupplier.CUSTOMER)
  type!: ECustomerOrSupplier;

  @Expose()
  @Type(() => Number)
  contactId!: number | null;

  @Expose()
  @Type(() => TaxInvoicePostingReqItemDto)
  items!: TaxInvoicePostingReqItemDto[];
}

export class TaxInvoiceCalculateSummaryReqDto extends BaseModel {
  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  gstValue!: number;

  // @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  // @Type(() => Number)
  // amount!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => TaxInvoiceRecordDto)
  details!: TaxInvoiceRecordDto[];
}

// Pdf Preview
export class TaxInvoicePreviewInvoicePdfDetailDto extends BaseModel {
  @Expose()
  id?: number;

  @Expose()
  description!: string;

  @Expose()
  quantity!: number;

  @Expose()
  unitPrice!: number;

  @Expose()
  itemCode?: string;

  @Expose()
  gstValue?: number;

  @Expose()
  discount?: number;

  @Expose()
  amount!: number;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  remarks!: string;

  @Expose()
  agentName!: string;
}

export class TaxInvoicePreviewInvoicePdfDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  invoiceId?: number;

  @Expose()
  // @Default(21)
  @Type(() => Number)
  templateId?: number;

  @Expose()
  customerName?: string;

  @Expose()
  billingAddress?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDate?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDueDate?: string;

  @Expose()
  invoiceNumber?: string;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  reference?: string;

  @Expose()
  gstEdited?: boolean;

  // @Expose()
  // @Type(() => Number)
  // gstValue?: number;

  @Expose()
  @Type(() => Number)
  taxValue?: number;

  @Expose()
  @Type(() => Number)
  @Default(8)
  gstPercent?: number;

  @Expose()
  currency?: string;

  @Expose()
  contactPerson?: string;

  @Expose()
  contactCode?: string;

  @Expose()
  @Type(() => TaxInvoicePreviewInvoicePdfDetailDto)
  details?: TaxInvoicePreviewInvoicePdfDetailDto[];
}
