import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { TransactionTypeCreateDto, TransactionTypeUpdateDto } from '@shared/data-access/dto';
import { IStransactionType } from '@shared/data-access/interfaces';
import { TransactionTypeModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionTypeApiService {
  static ROOT_POINT = 'collection/categories/transaction-type';

  constructor(private apiService: ApiService) { }

  create(data: TransactionTypeCreateDto): Observable<TransactionTypeModel> {
    return this.apiService.post(`${TransactionTypeApiService.ROOT_POINT}`, data).pipe(map((res: IStransactionType) => TransactionTypeModel.fromJson(res) as TransactionTypeModel));
  }

  get(itemId: number): Observable<TransactionTypeModel> {
    return this.apiService.get(TransactionTypeApiService.ROOT_POINT + '/' + itemId).pipe(map(res => TransactionTypeModel.fromJson(res) as TransactionTypeModel));
  }

  update(id: number, data: TransactionTypeUpdateDto): Observable<any> {
    return this.apiService.patch(TransactionTypeApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<TransactionTypeModel>> {
    return this.apiService.get(TransactionTypeApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(TransactionTypeModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(TransactionTypeApiService.ROOT_POINT, { ids }, option);
  }
}
