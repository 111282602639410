import { BaseModel, Default } from '@red/data-access';
import { Expose } from 'class-transformer';

export class BankAccountCreateDto extends BaseModel {
  @Expose()
  accountId!: number;

  @Expose()
  bankId!: number;

  @Expose()
  name!: string;

  @Expose()
  bankAccountNumber!: string;

  @Expose()
  currency!: string;

  @Expose()
  @Default(false)
  requireBankReturnCollectionFile!: boolean;

  @Expose()
  @Default(false)
  requireBankReturnPaymentFile!: boolean;

  @Expose()
  directDebitCollectionFileId!: number;

  @Expose()
  directDebitPaymentFileId!: number;

  @Expose()
  remarks!: string;
}

export class BankAccountUpdateDto extends BaseModel {
  @Expose()
  accountId!: number;

  @Expose()
  bankId!: number;

  @Expose()
  name!: string;

  @Expose()
  bankAccountNumber!: string;

  @Expose()
  currency!: string;

  @Expose()
  @Default(false)
  requireBankReturnCollectionFile!: boolean;

  @Expose()
  @Default(false)
  requireBankReturnPaymentFile!: boolean;

  @Expose()
  directDebitCollectionFileId!: number;

  @Expose()
  directDebitPaymentFileId!: number;

  @Expose()
  remarks!: string;
}
