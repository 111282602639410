import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { LedgerAccountDto } from '@shared/data-access/dto';
import { AccountSettingType } from '@shared/data-access/enums';
import { ILedgerAccount } from '@shared/data-access/interfaces';
import { AccountType, LedgerAccountModel, LedgerPathModel } from '@shared/data-access/models';
import { plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LedgerAccountApiService {
  static ROOT_POINT = 'finance/ledger-accounts';
  static USED_ACCOUNT_POINT = 'finance/ledger-accounts/used/:type';

  constructor(private apiService: ApiService) {}

  create(data: LedgerAccountDto): Observable<LedgerAccountModel> {
    return this.apiService
      .post(`${LedgerAccountApiService.ROOT_POINT}`, data)
      .pipe(map((res: ILedgerAccount) => LedgerAccountModel.fromJson(res) as LedgerAccountModel));
  }

  get(itemId: number, query = {}): Observable<LedgerAccountModel> {
    return this.apiService.get(LedgerAccountApiService.ROOT_POINT + '/' + itemId, query).pipe(map(res => LedgerAccountModel.fromJson(res) as LedgerAccountModel));
  }

  getAvailableTypes(): Observable<AccountType[]> {
    return this.apiService.get(LedgerAccountApiService.ROOT_POINT + '/types').pipe(
      map(res => {
        let array = new Array<AccountType>();
        array = res.map((value: string) => ({ type: value } as AccountType));
        return plainToInstance(AccountType, array);
      })
    );
  }

  getPath(id: number): Observable<LedgerPathModel[]> {
    return this.apiService.get(LedgerAccountApiService.ROOT_POINT + '/' + id + '/paths').pipe(
      map((res: LedgerPathModel[]) => {
        return plainToInstance(LedgerPathModel, res);
      })
    );
  }

  update(id: number, data: any): Observable<any> {
    return this.apiService.patch(LedgerAccountApiService.ROOT_POINT + '/' + id, data);
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<LedgerAccountModel>> {
    return this.apiService.get(LedgerAccountApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(LedgerAccountModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(LedgerAccountApiService.ROOT_POINT + '/' + id, data, option);
  }

  approveAccount(id: number, data = {}): Observable<any> {
    return this.apiService.patch(LedgerAccountApiService.ROOT_POINT + '/' + id + '/approve', data);
  }

  rejectAccount(id: number, data = {}): Observable<any> {
    return this.apiService.patch(LedgerAccountApiService.ROOT_POINT + '/' + id + '/reject', data);
  }

  getAccountByAccountCode(role: string, code: string, apiOption?: IApiOption): Observable<LedgerAccountModel> {
    return this.apiService.get(LedgerAccountApiService.ROOT_POINT + '/' + role + '/' + code, {}, apiOption).pipe(
      map(res => {
        return LedgerAccountModel.fromJson(res) as LedgerAccountModel;
      })
    );
  }

  getLinkedAccount(type: AccountSettingType, query = {}, option?: IApiOption): Observable<PaginationAdapter<LedgerAccountModel>> {
    return this.apiService
      .get(LedgerAccountApiService.USED_ACCOUNT_POINT, { type: type, ...query }, option)
      .pipe(map(data => new PaginationAdapter(LedgerAccountModel, data)));
  }
}
