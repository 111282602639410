import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { AssetRegisterCreateDto, AssetRegisterComputeDepDto } from '@shared/data-access/dto';
import { IAssetRegister } from '@shared/data-access/interfaces';
import { AssetRegisterModel, AssetRegisterDepreciationModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetRegisterApiService {
  static ROOT_POINT = 'finance/assets';

  constructor(private apiService: ApiService) {}

  create(data: AssetRegisterCreateDto): Observable<AssetRegisterModel> {
    return this.apiService
      .post(`${AssetRegisterApiService.ROOT_POINT}`, data)
      .pipe(map((res: IAssetRegister) => AssetRegisterModel.fromJson(res) as AssetRegisterModel));
  }

  get(itemId: number, query = {}): Observable<AssetRegisterModel> {
    return this.apiService.get(AssetRegisterApiService.ROOT_POINT + '/' + itemId).pipe(map(res => AssetRegisterModel.fromJson(res) as AssetRegisterModel));
  }

  update(id: number, data: AssetRegisterCreateDto): Observable<any> {
    return this.apiService.patch(AssetRegisterApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<AssetRegisterModel>> {
    return this.apiService.get(AssetRegisterApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(AssetRegisterModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(AssetRegisterApiService.ROOT_POINT + '/' + id, data, option);
  }

  computeDepreciation(data: AssetRegisterComputeDepDto, option?: IApiOption): Observable<Partial<AssetRegisterDepreciationModel>> {
    return this.apiService.post(AssetRegisterApiService.ROOT_POINT + '/compute-dep', data, option);
  }
}
