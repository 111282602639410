import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

// Metadata
export class EcddActionMetadataDto extends BaseModel {
  @Expose()
  reason?: number;

  @Expose()
  approvedFile?: string;
}

export class EcddRejectedMetadataDto extends BaseModel {
  @Expose()
  reason?: number;

  @Expose()
  rejectedFile?: string;
}

// Data
export class EcddActionDataDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => EcddActionMetadataDto)
  metadata?: EcddActionMetadataDto;
}

export class EcddRejectedDataDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => EcddRejectedMetadataDto)
  metadata?: EcddRejectedMetadataDto;
}

// Action - Reject
export class EcddActionDto extends BaseModel {
  @Expose()
  @Type(() => EcddActionDataDto)
  ecdds?: EcddActionDataDto[];
}

export class EcddRejectedDto extends BaseModel {
  @Expose()
  @Type(() => EcddRejectedDataDto)
  ecdds?: EcddRejectedDataDto[];
}
