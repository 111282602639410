import { Expose, Type } from 'class-transformer';
import { ISupplierBankAccount } from '../interfaces';
import { BankModel } from './bank.model';
import { BaseFixedModel } from './base.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';

export class SupplierBankAccountModel extends BaseFixedModel implements ISupplierBankAccount {
  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  @Type(() => Number)
  contactId?: number;

  @Expose()
  @Type(() => Number)
  bankId!: number;

  @Expose()
  accountNumber!: string;

  @Expose()
  accountName!: string;

  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => BankModel)
  bank?: BankModel;
}
