import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ExceljsParameterFieldBase } from '../exceljs-parameter-field-base.directive';
import { ExceljsParameter, IExceljsParameterControlType } from '../../models';
import { isMoment } from 'moment';
import { format } from 'date-fns';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'red-exceljs-parameter-date-range',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, MatFormFieldModule, MatInputModule],
  templateUrl: './exceljs-parameter-date-range.component.html',
  styleUrls: ['./exceljs-parameter-date-range.component.scss'],
})
export class ExceljsParameterDateRangeComponent extends ExceljsParameterFieldBase {
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  toDataDowncastValue(scheme: Partial<ExceljsParameter>, val: unknown): { start: any; end: any } {
    const { start, end } = val as { start: any; end: any };
    const startValue = this.convertValue(start);
    const endValue = this.convertValue(end)
    const data = {
      start: startValue,
      end: endValue,
    };
    console.log('downcast --> ', data)
    return data
  }
  convertValue(val: any): string {
    if (isMoment(val)) {
      return val.format('YYYY-MM-DD');
    }
    if (val instanceof Date) {
      return format(val, 'yyyy-MM-dd');
    }
    return val ?? null;
  }
  toDataUpcastValue(scheme: Partial<ExceljsParameter>, val: any): { start: any; end: any } {
    // console.log('toDataUpcastValue', val);
    return { start: val?.start ?? null, end: val?.end ?? null };
  }

  get controlType(): IExceljsParameterControlType {
    return 'date/range';
  }

  override init(): void {
    const dataDowncast = this.toDataUpcastValue(this.data, this.data.value);

    this.range.setValue(dataDowncast);
    this.dataChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      const dataDowncast = this.toDataUpcastValue(this.data, this.data.value);

      this.range.setValue(dataDowncast);
    });
  }
  updateValue(): void {
    console.log('updateValue', this.range.getRawValue());
    if (this.range.dirty) {
      this.onValueChange(this.range.getRawValue());
    }
  }
}
