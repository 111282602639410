import { HttpErrorResponse } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { AuthProviderBase } from './auth.service';
import { ICookieReader } from './cookie.service';

// Create the injection token for the custom settings
export const LOGIN_URL = new InjectionToken('SharedAuthLoginUrl');
export const LOGOUT_URL = new InjectionToken('SharedAuthLogoutUrl');
export const FORCED_LOGOUT_FN = new InjectionToken<(error: HttpErrorResponse) => boolean>('FORCED_LOGOUT_FN');
export const AUTH_PROVIDER = new InjectionToken<AuthProviderBase>('AUTH_PROVIDER');
export const AUTH_COOKIE_READER = new InjectionToken<ICookieReader>('AUTH_COOKIE_READER');
