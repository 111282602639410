import { IProjectTransactionRelatedCobroke } from './project-transaction-related-cobroke.interface';
import { IProjectTransactionOverriding } from './project-transaction-overriding.interface';
import { IProjectTransactionBonus } from './project-transaction-bonus.interface';
import { IProjectTransactionRelatedAgent } from './project-transaction-related-agent.interface';
import { IProjectTransactionCobrokeReferral } from './project-transaction-cobroke-referral.interface';

export interface IProjectTransactionCompanyInfoSummary {
  companyTierProfit: number;
  transactionOverridings: IProjectTransactionOverriding[];
  transactionPdManagements: any[];
  transactionPdReferrals: any[];
  transactionPds: any[];
  transactionPools: any[];
  transactionReferrals: IProjectTransactionCobrokeReferral[];
}
