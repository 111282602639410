import { Injectable } from '@angular/core';
import { RedocOAuthStorage } from '@redocco/oauth2-redoc-connect';

@Injectable()
export class MemoryStorage implements RedocOAuthStorage {
  private data = new Map<string, string>();

  getItem(key: string): string {
    return this.data.get(key) as string;
  }

  removeItem(key: string): void {
    this.data.delete(key);
  }

  setItem(key: string, data: string): void {
    this.data.set(key, data);
  }
}
