import { BaseModel, Default, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { BLANK_FORM_STATUSES, PROJECT_PROPERTY_TYPES_OPTIONS } from '../data';
import { EAgentRole, EBlankFormReferralSourceType, EBlankFormStatus, EBlankFormTypeDetail, EManagementCommissionPdRole, EProjectType } from '../enums';
import { IBlankForm, IBlankFormInternalCoBroke, IBlankFormRelatedAgent, IPersonalParticular, IStatusDescription } from '../interfaces';
import { KeysOfBlankFormHaveAgent, OptionDescription } from '../types';
import { BlankFormBdModel } from './blank-form-bd.model';
import { BlankFormBuyerInfoModel, BlankFormBuyerModel } from './blank-form-buyer.model';
import { BlankFormIcModel } from './blank-form-ic.model';
import { BlankFormOtherFeeModel } from './blank-form-other-fee.model';
import { BlankFormPdModel } from './blank-form-pd.model';
import { BlankFormReferralModel } from './blank-form-referral.model';
import { BlankFormModel } from './blank-form.model';
import { BlankFormRelatedAgentModel } from './blank-form-related-agent.model';
import { BlankFormInternalCoBrokeModel } from './blank-form-internal-co-broke.model';

export class AgentBlankFormModel extends BaseModel {

  @Expose()
  blankForm!: BlankFormModel;

  @Expose()
  @Default([])
  @Type(() => BlankFormInternalCoBrokeModel)
  mains!: BlankFormInternalCoBrokeModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormInternalCoBrokeModel)
  leaders!: BlankFormInternalCoBrokeModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormInternalCoBrokeModel)
  internals!: BlankFormInternalCoBrokeModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  bonuses!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  overridings!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  pools!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormBdModel)
  bds!: BlankFormBdModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormPdModel)
  pds!: BlankFormPdModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormPdModel)
  pdOverrides!: BlankFormPdModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormPdModel)
  pdReferrals!: BlankFormPdModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormPdModel)
  pdManagements!: BlankFormPdModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormReferralModel)
  referrals!: BlankFormReferralModel[];

  @Expose()
  userAgentId!: number;

}
