import { STORAGE_TYPE } from '@red/data-access';

export interface IAttachment {
  id: number;
  fileName: string;
  fileSize: number;
  fileType: string;
  hostProvider: STORAGE_TYPE;
  createdAt: Date;
  updatedAt: Date;
}
