export interface IEmploymentHistory {
  id: number;
  companyName: string;
  designation: string;
  areaWork: number;
  address: string;
  reasonLeft: string;
  remarks: string;
  periodFrom: string;
  periodTo: string;
  createdAt: string;
  updatedAt: string;
}
