import { BaseModel, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class AssetRegisterDepStringDto extends BaseModel {
  @Expose()
  @Type(() => String)
  firstDepValue!: string;

  @Expose()
  @Type(() => String)
  seqDepValue!: string;

  @Expose()
  @Type(() => String)
  finalDepValue!: string;

  @Expose()
  @Type(() => String)
  totalDepValue!: string;
}

export class AssetRegisterDepNumberDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  firstDepValue!: number;

  @Expose()
  @Type(() => Number)
  seqDepValue!: number;

  @Expose()
  @Type(() => Number)
  finalDepValue!: number;

  @Expose()
  @Type(() => Number)
  totalDepValue!: number;
}

export class AssetRegisterComputeDepDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Number)
  yearPeriod!: number;

  @Expose()
  @Type(() => Number)
  monthPeriod!: number;

  @Expose()
  @Type(() => Number)
  initialAmount!: number;

  @Expose()
  @Type(() => Number)
  residualAmount!: number;
}

export class AssetDepreciationDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Number)
  yearPeriod!: number;

  @Expose()
  @Type(() => Number)
  monthPeriod!: number;

  @Expose()
  @Type(() => Number)
  initialAmount!: number;

  @Expose()
  @Type(() => Number)
  residualAmount!: number;

  @Expose()
  @Type(() => Number)
  firstDepValue!: number;

  @Expose()
  @Type(() => Number)
  seqDepValue!: number;

  @Expose()
  @Type(() => Number)
  finalDepValue!: number;
}

export class AssetRegisterCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  serialNumber?: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  location?: string;

  @Expose()
  specification?: string;

  @Expose()
  @Type(() => Number)
  invoiceId!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  purchaseDate!: string;

  @Expose()
  @Type(() => Number)
  purchaseAmount!: number;

  @Expose()
  @Type(() => Number)
  purchaseQuantity!: number;

  @Expose()
  remarks?: string;

  @Expose()
  items?: string[];

  @Expose()
  @Type(() => AssetDepreciationDto)
  depreciation!: AssetDepreciationDto | null;

  @Expose()
  @Type(() => Number)
  inChargePersonId?: number;
}
