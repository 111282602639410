import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { ICommissionScheme, ICommSchemeGeneric } from '../interfaces';
import { SchemeTypeModel } from './scheme-type.model';
import { SplitMatrixModel } from './split-matrix.model';

export class CommissionSchemeModel extends BaseModel implements ICommissionScheme {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => Number)
  schemeTypeId!: number;

  @Expose()
  @Type(() => SchemeTypeModel)
  schemeType!: SchemeTypeModel;

  @Expose()
  designation!: string;

  @Expose()
  code!: string;

  @Expose()
  status!: string;

  @Expose()
  @Type(() => Boolean)
  autoApprove!: boolean;

  @Expose()
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => Number)
  sequence!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;
}

export class CommSchemeGenericModel extends BaseModel implements ICommSchemeGeneric {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  designation!: string;

  @Expose()
  @Type(() => SplitMatrixModel)
  splitMatrices?: SplitMatrixModel[];

  //#region getter
  get designationRate(): number[] {
    if (!this.splitMatrices || !this.splitMatrices.length) return [90, 10];

    const comm = this.splitMatrices[0].tiers.find(v => v.level === 1) ?? this.splitMatrices[0].tiers[0];
    const commPercent = comm?.percentage ?? 0;

    return commPercent > 0 ? [+commPercent.toFixed(4), +(100 - commPercent).toFixed(4)] : [90, 10];
  }

  get designationRateName(): string {
    return [this.designation, `(${this.designationRate.join('/')})`].join(' ');
  }
  //#endregion
}
