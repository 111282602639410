import { BaseModel, Default, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ProjectTeamMemberCreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  teamId!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => String)
  appTypeCode!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  @Default('')
  endDate!: string;

  @Expose()
  isLeader!: boolean;
}

export class ProjectTeamMemberUpdateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => String)
  appTypeCode!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  @Default('')
  endDate!: string;
}
