import { ILedgerAccountPath } from './../interfaces/ledger-account-path.interface';
import { BaseModel } from "@red/data-access";
import { Expose } from 'class-transformer';

export class LedgerPathModel extends BaseModel implements ILedgerAccountPath {

  @Expose()
  id!: number;

  @Expose()
  name!: string;

}
