import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'span[redClearEnter]',
  standalone: true,
})
export class ClearEnterDirective implements AfterContentInit {
  constructor(private el: ElementRef) {}
  ngAfterContentInit() {
    this.el.nativeElement.innerHTML = this.el.nativeElement.innerHTML.replace(/(\\r\\n)|([\r\n])/g, '<br/>');
  }
}
