import { PhoneNumber } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { PaginationDto } from './pagination.dto';

export class ContactPersonDto extends PaginationDto {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  name!: string;

  @Expose()
  designation!: string;

  @Expose()
  email!: string;

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  mobile!: PhoneNumber;

  @Expose()
  fax!: string;

  @Expose()
  default!: boolean;
}
