export interface ITransactedTypeValueItem {
  percent: number;
  absorbType?: { agent: boolean; hr: boolean };
  value?: number;
}
export interface ITransactedTypeValue {
  cashAdvance: ITransactedTypeValueItem;
  discount: ITransactedTypeValueItem;
  powerDiscount: ITransactedTypeValueItem;
  incentive: ITransactedTypeValueItem;
  buyerRebate: ITransactedTypeValueItem;
}
