import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { UnitCreateDto, UnitUpdateDto } from '@shared/data-access/dto';
import { IUnit } from '@shared/data-access/interfaces';
import { UnitModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnitApiService {
  static UNIT = 'transaction/units/portal';
  static UNIT_FOR_SALE = 'transaction/units/portal/for-sale';
  static UNIT_FOR_BLANK_FORM = 'transaction/units/portal/for-blank-form';

  constructor(private apiService: ApiService) {}

  create(data: UnitCreateDto): Observable<UnitModel> {
    return this.apiService.post(`${UnitApiService.UNIT}`, data).pipe(map((res: IUnit) => UnitModel.fromJson(res) as UnitModel));
  }

  get(itemId: number): Observable<UnitModel> {
    return this.apiService.get(UnitApiService.UNIT + '/' + itemId).pipe(map(res => UnitModel.fromJson(res) as UnitModel));
  }

  update(id: number, data: UnitUpdateDto): Observable<any> {
    return this.apiService.patch(UnitApiService.UNIT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<UnitModel>> {
    return this.apiService.get(UnitApiService.UNIT, query, option).pipe(map(data => new PaginationAdapter(UnitModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(UnitApiService.UNIT, { ids }, option);
  }

  updateStatus(id: number, status: string): Observable<any> {
    return this.apiService.patch(UnitApiService.UNIT + '/' + id, { status });
  }

  searchForSales(query = {}, option?: IApiOption): Observable<PaginationAdapter<UnitModel>> {
    return this.apiService.get(UnitApiService.UNIT_FOR_SALE, query, option).pipe(map(data => new PaginationAdapter(UnitModel, data)));
  }

  searchForBlankForm(query = {}, option?: IApiOption): Observable<PaginationAdapter<UnitModel>> {
    return this.apiService.get(UnitApiService.UNIT_FOR_BLANK_FORM, query, option).pipe(map(data => new PaginationAdapter(UnitModel, data)));
  }
}
