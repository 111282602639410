import { BaseModel } from '@red/data-access';
import { Expose, Transform } from 'class-transformer';
import { IGstRate } from '../interfaces';

export class GstRateModel extends BaseModel implements IGstRate {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  gstRate!: number;

  @Expose()
  gstCode!: string;

  @Expose()
  effectiveDate!: string;

  @Expose()
  @Transform(({ obj }) => (obj.effectiveDate ? new Date(obj.effectiveDate).getTime() : null))
  effectiveTimestamp!: any;

  @Expose()
  isActive!: boolean;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string | null;

  @Expose()
  createdBy!: string | null;

  @Expose()
  updatedBy!: string | null;

  @Expose()
  deletedBy!: string | null;
}
