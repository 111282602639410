import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { KeyAppointmentListCreateDto, KeyAppointmentListUpdateDto } from '@shared/data-access/dto';
import { IKeyAppointmentList } from '@shared/data-access/interfaces';
import { KeyAppointmentListModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyAppointmentListApiService {
  static ROOT_POINT = 'key-appointment-lists';

  constructor(private apiService: ApiService) {}

  create(data: KeyAppointmentListCreateDto): Observable<KeyAppointmentListModel> {
    return this.apiService
      .post(`${KeyAppointmentListApiService.ROOT_POINT}`, data)
      .pipe(map((res: IKeyAppointmentList) => KeyAppointmentListModel.fromJson(res) as KeyAppointmentListModel));
  }
  get(itemId: number): Observable<KeyAppointmentListModel> {
    return this.apiService
      .get(KeyAppointmentListApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => KeyAppointmentListModel.fromJson(res) as KeyAppointmentListModel));
  }
  update(id: number, data: KeyAppointmentListUpdateDto): Observable<any> {
    return this.apiService.patch(KeyAppointmentListApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<KeyAppointmentListModel>> {
    return this.apiService.get(KeyAppointmentListApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(KeyAppointmentListModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(KeyAppointmentListApiService.ROOT_POINT, { ids }, option);
  }
}
