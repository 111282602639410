import { BaseModel, Default } from "@red/data-access";
import { AbstractListFilters } from "@shared/core";
import { Expose, Type } from "class-transformer";

export class TransactionTypeCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  categoryRole!: string;

  @Expose()
  @Default("")
  description!: string;

  // @Expose()
  // status!: string;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;
}

export class TransactionTypeUpdateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  categoryRole!: string;

  @Expose()
  @Default("")
  description!: string;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;
}


// export enum CategoryRole {
//   commissionMatrixType = 'commissionMatrixType',
//   transactionType = 'transactionType'
// }
export class TransactionTypeFiltersDto extends AbstractListFilters {
  @Expose()
  categoryRoles!: string;

  @Expose()
  isActive!: boolean;
}