import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportCardRoutePipe } from './report-card-route.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ReportCardRoutePipe],
  exports: [ReportCardRoutePipe],
})
export class ReportManagerModule {}
