export const PROJECT_PAYMENT_BY_DEVELOPER = [
  {
    id: 'gstInclusive',
    name: 'Inclusive GST',
  },
  {
    id: 'gstExclusive',
    name: 'Exclusive GST',
  },
];
