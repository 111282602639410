import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { InvoicePostingType } from '../enums';

export class InvoicePostingDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  description?: string;

  @Expose()
  @Type(() => Number)
  accountId?: number;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  type!: InvoicePostingType;
}
