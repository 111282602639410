<!-- Placeholder to adjust vertical offset of the mat-option elements -->
<input matInput class="mat-select-search-input mat-select-search-hidden" />

<!-- Note: the  mat-datepicker-content mat-tab-header are needed to inherit the material theme colors, see PR #22 -->
<div
  #innerSelectSearch
  class="mat-select-search-inner mat-typography mat-datepicker-content mat-tab-header"
  [ngClass]="{ 'mat-select-search-inner-multiple': matSelect.multiple, 'mat-select-search-inner-toggle-all': _isToggleAllCheckboxVisible() }"
>
  <mat-checkbox
    *ngIf="_isToggleAllCheckboxVisible()"
    [color]="matFormField.color"
    class="mat-select-search-toggle-all-checkbox"
    [checked]="toggleAllCheckboxChecked"
    [indeterminate]="toggleAllCheckboxIndeterminate"
    [matTooltip]="toggleAllCheckboxTooltipMessage"
    matTooltipClass="ngx-mat-select-search-toggle-all-tooltip"
    [matTooltipPosition]="toogleAllCheckboxTooltipPosition"
    (change)="_emitSelectAllBooleanToParent($event.checked)"
  ></mat-checkbox>

  <input
    class="mat-select-search-input mat-input-element"
    autocomplete="off"
    [type]="type"
    [formControl]="_formControl"
    #searchSelectInput
    (keydown)="_handleKeydown($event)"
    (keyup)="_handleKeyup($event)"
    (blur)="onBlur()"
    [placeholder]="placeholderLabel"
    [attr.aria-label]="ariaLabel"
    [enableUppercase]="uppercase"
    uppercase
  />
  <mat-spinner *ngIf="searching" class="mat-select-search-spinner" diameter="16"></mat-spinner>

  <button mat-button *ngIf="!hideClearSearchButton && value && !searching" mat-icon-button aria-label="Clear" (click)="_reset(true)" class="mat-select-search-clear">
    <ng-content *ngIf="clearIcon; else defaultIcon" select="[ngxMatSelectSearchClear]"></ng-content>
    <ng-template #defaultIcon>
      <mat-icon>close</mat-icon>
    </ng-template>
  </button>

  <ng-content select=".mat-select-search-custom-header-content"></ng-content>
</div>

<div *ngIf="_showNoEntriesFound$ | async" class="mat-select-search-no-entries-found">
  {{ noEntriesFoundLabel }}
</div>
