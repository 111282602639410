import { EChartOfACcountDisplayStatus } from '../enums';
import { StatusRecord } from '../types/status.type';

export const CHART_OF_ACCOUNT_DISPLAY_STATUSES: StatusRecord<EChartOfACcountDisplayStatus> = {
  [EChartOfACcountDisplayStatus.active]: {
    id: EChartOfACcountDisplayStatus.active,
    class: 'status-box-success',
    name: 'Active',
    canEdit: true,
    canDelete: true,
  },
  [EChartOfACcountDisplayStatus.inactive]: {
    id: EChartOfACcountDisplayStatus.inactive,
    class: 'status-box-default',
    name: 'Inactive',
    canEdit: true,
    canDelete: true,
  },
  [EChartOfACcountDisplayStatus.approved]: {
    id: EChartOfACcountDisplayStatus.approved,
    class: 'status-box-success',
    name: 'Approved',
  },
  [EChartOfACcountDisplayStatus.pending]: {
    id: EChartOfACcountDisplayStatus.pending,
    class: 'status-box-warning',
    name: 'Pending Approval',
    canEdit: false,
    canDelete: true,
  },
  [EChartOfACcountDisplayStatus.rejected]: {
    id: EChartOfACcountDisplayStatus.rejected,
    class: 'status-box-danger',
    name: 'Rejected',
    canEdit: true,
    canDelete: true,
  },
};
