import { BaseModel, Default, IsoString } from '@red/data-access';
import { Helper } from '@red/utils';
import { Expose, Transform, Type } from 'class-transformer';
import {
  FURNISHED_OPTIONS,
  RESALE_NO_OF_ROOMS_OPTIONS_1,
  RESALE_NO_OF_ROOM_OPTIONS,
  RESALE_NO_OF_ROOM_OPTIONS_HDB,
  RESALE_PAYMENT_METHOD_OPTIONS,
  RESALE_PROPERTY_TYPE_OPTIONS,
  RESALE_STATUSES,
  RESALE_TYPE_OPTIONS,
} from '../data/resale.data';
import {
  ECommType,
  EFurnished,
  EGstType,
  EResaleEmailStatus,
  EResaleNoOfRoom,
  EResaleNoOfRoomHdb,
  EResalePaymentMethod,
  EResalePropertyType,
  EResaleStatus,
  EResaleSubmitLateReason,
  EResaleType,
  EResaleUserRole,
} from '../enums/resale.enum';
import { IGrossCommissionEarned, IResaleCoBroke, IResaleDocumentHip, IResaleExternalCoBroke } from '../interfaces';
import { IResaleMetadata, IResaleTransaction } from '../interfaces/resale-transaction.interface';
import { OptionDescription, ResaleDateKey, ResaleNoOfRoomTypeRecord } from '../types';
import { AddressModel } from './address.model';
import { CompanyModel } from './company.model';
import { CountryModel } from './country.model';
import { ResaleBillingModel } from './resale-billing.model';
import { ResaleDocumentModel } from './resale-document.model';
import { ResaleRelatedAgentModel } from './resale-related-agent.model';
import { UpdatedByModel } from './updated-by.model';

export class ResaleTransactionModel extends BaseModel implements IResaleTransaction {
  @Expose()
  id!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: number;

  @Expose()
  submittedAt!: string;

  @Expose()
  submittedBy!: number;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  reviewedAt!: string;

  @Expose()
  reviewedBy!: UpdatedByModel;

  @Expose()
  agreementDate!: string;

  @Expose()
  isPC!: boolean;

  @Expose()
  agreeToTerms!: boolean;

  @Expose()
  @Default(ResaleRelatedAgentModel.createEmpty())
  @Type(() => ResaleRelatedAgentModel)
  // @Transform(
  //   ({ obj }) => {
  //     let resale = obj as IResaleTransaction;
  //     if (obj instanceof ResaleTransactionModel) {
  //       resale = ResaleTransactionModel.toJson(obj) as IResaleTransaction;
  //     }
  //     const externals = (resale.relatedAgent?.externals ?? []) as IResaleExternalCoBroke[];
  //     const externalsCalculated = resale.userAgentId ? externals : externals.map(item => ResaleTransactionModel.calculateGrossCommExternal(item, resale));
  //     return ResaleRelatedAgentModel.fromJson({ ...resale.relatedAgent, externals: externalsCalculated });
  //   },
  //   { toClassOnly: true, groups: ['draft'] }
  // )
  relatedAgent!: ResaleRelatedAgentModel;

  @Expose()
  rejectReason?: string;

  @Expose()
  @Default(ResaleBillingModel.createEmpty())
  @Type(() => ResaleBillingModel)
  billing!: ResaleBillingModel;

  @Expose()
  code!: string;

  @Expose()
  commencementDate!: string;

  @Expose()
  @Type(() => CompanyModel)
  company?: CompanyModel;

  @Expose()
  companyId!: number;

  @Expose()
  completionDate!: string;

  @Expose()
  @Type(() => CountryModel)
  country?: CountryModel;

  @Expose()
  countryId!: number;

  @Expose()
  expireDate!: string;

  @Expose()
  furnished?: EFurnished;

  @Expose()
  invoice?: any;

  @Expose()
  invoiceId!: number;

  @Expose()
  invoiceCode!: number;

  @Expose()
  name!: string;

  @Expose()
  optionDate!: string;

  @Expose()
  propertyType!: EResalePropertyType;

  @Expose()
  remarksInternal!: string;

  @Expose()
  remarks!: string;

  @Expose()
  resaleType!: EResaleType;

  @Expose()
  @Transform(({ obj }) => {
    return RESALE_TYPE_OPTIONS[obj?.resaleType as EResaleType]?.viewValue ?? null;
  })
  resaleTypeDisplay!: string | null;

  @Expose()
  @Default(EResaleStatus.draft)
  status!: EResaleStatus;

  @Expose()
  submissionDate!: string;

  @Expose()
  reSubmissionDate!: string;

  @Expose()
  @Default(false)
  isInternal!: boolean;

  @Expose()
  @Default([])
  @Type(() => ResaleDocumentModel)
  documents!: ResaleDocumentModel[];

  @Expose()
  @Default([])
  additionals!: IResaleDocumentHip[];

  @Expose()
  invoiceAt?: string;

  @Expose()
  userAgentId?: number;

  @Expose()
  salespersonId?: number;

  @Expose()
  paymentMethod?: EResalePaymentMethod;

  @Expose()
  role?: EResaleUserRole;

  @Expose()
  @Type(() => AddressModel)
  propertyAddress?: AddressModel;

  @Expose()
  @Transform(({ obj }) => {
    const { mains, internals, externals } = obj.relatedAgent ?? {
      mains: [],
      internals: [],
      externals: [],
    };
    const cobrokeItems = [
      ...(mains ?? []).map((i: IResaleCoBroke) => ({ ...i, type: 'mains' })),
      ...(internals ?? []).map((i: IResaleCoBroke) => ({
        ...i,
        type: 'internals',
      })),
      ...(externals ?? []).map((i: IResaleExternalCoBroke) => ({ ...i, type: 'externals' })),
    ];
    return cobrokeItems;
  })
  cobrokeItems!: ((IResaleCoBroke | IResaleExternalCoBroke) & { type: string })[];

  @Expose()
  metadata?: IResaleMetadata;

  // metadata?: {
  //   agent?: string | null;
  //   admin?: string | null;
  //   ecbGreaterPercentReason: string;
  //   submitLateReason: {
  //     type: EResaleSubmitLateReason;
  //     reason: string;
  //   };
  //   isPC?: boolean;
  //   skipEmail?: boolean;
  //   emailStatus: EResaleEmailStatus;
  //   isAbleSubmitted?: boolean;
  // };

  get isShowPC() {
    return [EResaleType.lease, EResaleType.leaseRental, EResaleType.assignment].includes(this.resaleType) &&
      [
        EResalePropertyType.LandedTerraceHouse,
        EResalePropertyType.LandedSemiDetachedHouse,
        EResalePropertyType.LandedDetachedHouse,
        EResalePropertyType.LandedStrataHouse,
        EResalePropertyType.ResidentialCondominiumApartment,
        EResalePropertyType.ResidentialExecutiveCondominium,
        EResalePropertyType.ResidentialHDB,
      ].includes(this.propertyType);
  }

  @Expose()
  payment?: {
    total: number;
    paymentCodes: string[];
  };

  @Expose()
  system?: 'hms' | 'agent';

  @Expose()
  area?: {
    areaSqft?: number;
    areaSqm?: number;
    landSqft?: number;
    landSqm?: number;
  };

  @Expose()
  @Transform(({ obj }) => {
    if (!obj?.metadata?.isWithPayment) {
      return 'NO'
    } else {
      switch (obj?.paymentMethod) {
        case 'dropCheque':
          return 'YES (DC)';
        case 'bankTransfer':
          return 'YES (FT)';
        default:
          return 'YES';
      }
    }
  })
  paymentMethodDisplay!: string;

  get paymentMethodDescription() {
    return this.paymentMethod ? RESALE_PAYMENT_METHOD_OPTIONS[this.paymentMethod] : null;
  }

  get closingAgentHasUser() {
    return this.relatedAgent.mains.some(item => item.agentId === this.userAgentId);
  }

  get statusDescription() {
    return RESALE_STATUSES[this.status];
  }

  get resaleTypeDescription() {
    return this.resaleType ? RESALE_TYPE_OPTIONS[this.resaleType] : null;
  }

  get propertyTypeDescription() {
    return this.propertyType ? RESALE_PROPERTY_TYPE_OPTIONS[this.propertyType] : null;
  }

  get furnishedDescriptions() {
    return this.furnished ? FURNISHED_OPTIONS[this.furnished] : null;
  }

  get totalExternalsReceivedPercent() {
    return this.relatedAgent.externals.reduce((total, item) => Helper.round(total + (item?.receivedPercent ?? 0), 4), 0);
  }

  get canSubmit() {
    return RESALE_STATUSES[this.status]?.canSubmit ?? false;
  }

  get canEdit() {
    return [EResaleStatus.draft, EResaleStatus.submitted, EResaleStatus.confirmed, EResaleStatus.rework, EResaleStatus.sendBack].includes(this.status);
  }

  get canDelete() {
    return [EResaleStatus.draft].includes(this.status);
  }

  get canWithdraw() {
    return [EResaleStatus.submitted, EResaleStatus.confirmed, EResaleStatus.verified].includes(this.status);
  }

  get canUpdatePC() {
    return [EResaleStatus.draft, EResaleStatus.submitted, EResaleStatus.confirmed, EResaleStatus.verified, EResaleStatus.rework, EResaleStatus.sendBack].includes(this.status);
  }

  get userCanSubmit() {
    return [EResaleStatus.draft].includes(this.status) && this.role === EResaleUserRole.agent;
  }

  get userCanEdit() {
    return this.canEdit && this.role === EResaleUserRole.agent;
  }

  get canAgentDelete() {
    return [EResaleStatus.draft].includes(this.status) && this.role === EResaleUserRole.agent;
  }

  get userCanClone() {
    return [EResaleType.leaseRental, EResaleType.lease].includes(this.resaleType) && this.role === EResaleUserRole.agent;
  }

  get canPrintInvoice() {
    return (
      this.invoiceId &&
      [
        EResaleStatus.approved,
        EResaleStatus.approvedPC,
        EResaleStatus.received,
        EResaleStatus.receivedPartial,
        EResaleStatus.partial,
        EResaleStatus.cancelled,
        EResaleStatus.aborted,
        EResaleStatus.writeOff,
        EResaleStatus.rejected,
        EResaleStatus.completed,
        EResaleStatus.cancelledPartial
      ].includes(this.status)
    );
  }

  get canAgentEdit() {
    return this.role === EResaleUserRole.agent && [EResaleStatus.draft].includes(this.status);
  }

  // get canAgentUpload() {
  //   return (
  //     this.system === 'agent' &&
  //     this.role === EResaleUserRole.agent &&
  //     [
  //       EResaleStatus.submitted,
  //       EResaleStatus.rework,
  //       EResaleStatus.sendBack,
  //       EResaleStatus.confirmed,
  //       EResaleStatus.verified,
  //       EResaleStatus.approved,
  //       EResaleStatus.approvedPC,
  //       EResaleStatus.receivedPartial,
  //     ].includes(this.status)
  //   );
  // }

  get canAgentUpload() {
    return (
      this.role === EResaleUserRole.agent && RESALE_STATUSES[this.status]?.canAgentUploadDocs
    );
  }

  get canAgentWithdraw() {
    return this.role === EResaleUserRole.agent && [EResaleStatus.submitted].includes(this.status);
  }

  get showSkipEmail() {
    return this.metadata?.skipEmail ? this.metadata?.emailStatus !== EResaleEmailStatus.sent : this.canSkipEmail;
  }

  get canSkipEmail() {
    return [EResaleStatus.draft, EResaleStatus.submitted, EResaleStatus.confirmed, EResaleStatus.verified, EResaleStatus.rework, EResaleStatus.sendBack].includes(
      this.status
    );
  }

  get isShowReason() {
    return [EResaleStatus.sendBack, EResaleStatus.rework, EResaleStatus.rejected].includes(this.status);
  }

  canShowFieldDependOnResaleType(key: ResaleDateKey) {
    return RESALE_TYPE_OPTIONS[this.resaleType]?.correspondingField.includes(key) ?? false;
  }

  static calculateGrossCommExternal(item: IResaleExternalCoBroke, resale: IResaleTransaction): IResaleExternalCoBroke {
    const { subTotalAmount: billingValue } = resale.billing.grossComm;
    const { commType, gstType, receivedPercent, receivedValue, grossComm } = item;
    const { taxValue, totalAmount, subTotalAmount } = grossComm;
    let grossCommCalculated: IGrossCommissionEarned;
    let subTotalCalculated = subTotalAmount;
    let totalAmountCalculated = totalAmount;
    let receivedPercentCalculated = receivedPercent;
    switch (commType) {
      case ECommType.percentage:
        if (gstType === EGstType.gstInclusive) {
          totalAmountCalculated = Helper.round((+(receivedPercent ?? 0) / 100) * +(billingValue ?? 0), 2);
          subTotalCalculated = Helper.round(+(totalAmountCalculated ?? 0) - +(taxValue ?? 0), 2);
          grossCommCalculated = {
            ...grossComm,
            subTotalAmount: subTotalCalculated,
            totalAmount: totalAmountCalculated,
          };
        } else {
          subTotalCalculated = Helper.round((+(receivedPercent ?? 0) / 100) * +(billingValue ?? 0), 2);
          totalAmountCalculated = Helper.round(+(subTotalCalculated ?? 0) + +(taxValue ?? 0), 2);
          grossCommCalculated = {
            ...grossComm,
            subTotalAmount: subTotalCalculated,
            totalAmount: totalAmountCalculated,
          };
        }
        break;
      case ECommType.value:
        if (gstType === EGstType.gstInclusive) {
          grossCommCalculated = {
            ...grossComm,
            subTotalAmount: Helper.round(+(receivedValue ?? 0) - +(taxValue ?? 0), 2),
            totalAmount: receivedValue,
          };
        } else {
          grossCommCalculated = {
            ...grossComm,
            subTotalAmount: receivedValue,
            totalAmount: Helper.round(+(receivedValue ?? 0) + +(taxValue ?? 0), 2),
          };
        }
        receivedPercentCalculated = Helper.round((+(grossCommCalculated.totalAmount ?? 0) / +(billingValue ?? 0)) * 100, 4);
        break;
    }
    return { ...item, grossComm: grossCommCalculated, receivedPercent: receivedPercentCalculated };
  }

  get outstandingValue(): number {
    const billingValue = this.billing.grossComm.subTotalAmount || 0;
    const { mains, internals, externals } = this.relatedAgent;
    const receivedValueAgents = [...mains.map(v => v.receivedValue), ...internals.map(v => v.receivedValue), ...externals.map(v => v.receivedValue)];
    const totalValueOfAgent = receivedValueAgents.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return billingValue - totalValueOfAgent;
  }

  get totalReceivedValueAgent(): number {
    const { mains, internals } = this.relatedAgent;
    const receivedValueAgents = [...mains.map(v => v.receivedValue), ...internals.map(v => v.receivedValue)];
    return receivedValueAgents.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  @Expose()
  migrationId!: string;

  get noOfRoomInfo(): string | null {
    if (this.propertyType === EResalePropertyType.ResidentialExecutiveCondominium || this.propertyType === EResalePropertyType.ResidentialCondominiumApartment) {
      return this.propertyAddress?.noOfRooms ? RESALE_NO_OF_ROOM_OPTIONS[this.propertyAddress?.noOfRooms as EResaleNoOfRoom]?.viewValue : null;
    } else if (this.propertyType === EResalePropertyType.ResidentialHDB) {
      return this.propertyAddress?.noOfRooms ? RESALE_NO_OF_ROOM_OPTIONS_HDB[this.propertyAddress?.noOfRooms as EResaleNoOfRoomHdb]?.viewValue : null;
    } else {
      return this.propertyAddress?.noOfRooms as string;
    }
  }
}
