import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IProjectTransactionReferral } from '../interfaces';
import { PersonalParticularModel } from './personal-particular.model';
import { ProjectTransactionModel } from './project-transaction.model';
import { ReferralExternalModel } from './referral-external.model';
import { SplitPartModel } from './split-part.model';

export class ProjectTransactionReferralModel extends BaseModel implements IProjectTransactionReferral {
  @Expose()
  id!: number;

  @Expose()
  type!: 'internal' | 'external';

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  @Transform(({ obj, value }) => {
    if (!value || !value?.id) {
      return obj?.tiers?.[0]?.salesperson;
    }
    return value;
  })
  salesperson!: PersonalParticularModel;

  @Expose()
  agentId!: number;

  @Expose()
  @Type(() => ReferralExternalModel)
  agent!: ReferralExternalModel;

  @Expose()
  createdAt!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  updatedAt!: string;

  @Expose()
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  note: any;

  @Expose()
  name!: string;

  @Expose()
  transactionId!: number;

  @Expose()
  @Type(() => ProjectTransactionModel)
  transaction!: ProjectTransactionModel;

  @Expose()
  isSplit!: boolean;

  @Expose()
  formula!: string;

  @Expose()
  fee!: number;

  @Expose()
  receivedValue!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  data!: any;

  @Expose()
  @Default({
    agent: true,
    hr: true,
  })
  absorbType!: { agent: boolean; hr: boolean };
}
