import { ISchemeType } from './scheme-type.interface';
import { ITimestamp } from './timestamp.interface';

export interface IProfitSharingItem {
  commId: number;
  sequence: number;
  profit: number;
}

export interface IProfitSharingItemDetail {
  comm?: {
    code: string;
    commissionType: string;
    designation: string;
    id: number;
    status: string;
  };
}

export interface IProfitSharing extends ITimestamp {
  branchOutAmount: number;
  code: string;
  group1: IProfitSharingItemDetail[];
  group2: IProfitSharingItemDetail[];
  id: number;
  isActive: boolean;
  maximumGroup1: number;
  maximumGroup2: number;
  name?: string;
  schemeType: ISchemeType;
  schemeTypeId: number;
}

export interface IProfitSharingDetail extends Omit<IProfitSharing, 'group1' | 'group2'> {
  group1: IProfitSharingItemDetail[];
  group2: IProfitSharingItemDetail[];
}
