import { EmploymentHistoryModel } from '@shared/data-access/models';
import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { map, Observable } from 'rxjs';
import { IEmploymentHistory } from '@shared/data-access/interfaces';
import { EmploymentHistoryDto } from '@shared/data-access/dto';

@Injectable({
  providedIn: 'root',
})
export class EmploymentHistoryApiService {
  static HISTORY = 'hrm/histories';

  constructor(private apiService: ApiService) {}

  create(salespersonId: number, data: EmploymentHistoryDto): Observable<EmploymentHistoryModel> {
    return this.apiService
      .post(`${EmploymentHistoryApiService.HISTORY}/${salespersonId}`, data)
      .pipe(map((res: IEmploymentHistory) => EmploymentHistoryModel.fromJson(res) as EmploymentHistoryModel));
  }

  update(id: number, data: EmploymentHistoryDto): Observable<any> {
    return this.apiService.patch(EmploymentHistoryApiService.HISTORY + '/' + id, data, { excludeFields: [''] });
  }

  getAll(salespersonId: number, query = {}, option?: IApiOption): Observable<EmploymentHistoryModel[]> {
    // return of(fakeData.map(item =>  AdditionalInformationModel.fromJson(item)));
    return this.apiService
      .get(`${EmploymentHistoryApiService.HISTORY}/${salespersonId}`, query, option)
      .pipe(map((data: IEmploymentHistory[]) => data.map(item => EmploymentHistoryModel.fromJson(item))));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(EmploymentHistoryApiService.HISTORY + '/' + id, {}, option);
  }
}
