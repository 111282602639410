import { AccountSettingType } from '../enums';

export const ACCOUNT_SETUP_USAGE = {
  [AccountSettingType.CONTROL]: {
    OUTPUT_TAX: 'OUTPUT_TAX',
    INPUT_TAX: 'INPUT_TAX',
    BANK_REFERAL_FEE: 'BANK_REFERAL_FEE'
  },
  [AccountSettingType.DEFAULT]: {
    PAYABLE: 'PAYABLE',
    RECEIVABLE: 'RECEIVABLE',
    BANK_PAYMENT: 'BANK_PAYMENT',
    CASH_PAYMENT: 'CASH_PAYMENT',
    CHEQUE_DEPOSIT: 'CHEQUE_DEPOSIT',
  },
};

// export const ACCOUNT_TYPE_TOP_LEVEL = ['Cost of Sales', 'Current Asset', 'Trade Payables', 'Other Payables', 'ECB (Control)', 'Equity', 'Current Liabilities'];
