import { IRedIcon, IRedIconObject } from './icon.interface';
import { Inject, Injectable } from "@angular/core";
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";
import { RED_ICON } from "./icon.token";
@Injectable()
export class RedIconService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(RED_ICON) private svgIcons: IRedIcon[]
  ) {
  svgIcons.forEach(item => {
    const data: IRedIconObject = {
      key:'',
      path:''
    }
    if(typeof item === 'string'){
      data.key = item;
      data.path = `assets/icons/${item}.svg`;
      this.registerIcon(data)
    }else{
      if(item.isSvg){
        this.registerIcon(item)
      }
    }
  })

  }

  private registerIcon(icon:IRedIconObject):void{
    if(icon.key && icon.path){
      this.iconRegistry.addSvgIcon(
        icon.key,
          this.sanitizer.bypassSecurityTrustResourceUrl(icon.path)
        );
    }

  }
}
