import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { DebitNoteDto, DebitNotePostingReqDto, DebitNotePreviewInvoicePdfDto } from '@shared/data-access/dto';
import { IDebitNote, IDebitNotePayment, IDebitNotePosting } from '@shared/data-access/interfaces';
import { DebitNoteModel, DebitNotePostingModel, DebitNoteRecordModel, ReceiptMemoModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DebitNoteEnhancedApiService {
  static ROOT_POINT = 'finance/debit-notes';
  static CANCEL_POINT = 'finance/debit-notes/:id/cancel';
  static PAYMENTS_POINT = 'finance/debit-notes/:id/payments';
  static PREVIEW_POSTING_POINT = 'finance/debit-notes/postings/preview';
  static FOR_RECEIPT_POINT = 'finance/tax-invoices/for-receipts';

  constructor(private _store: Store, private _router: Router, private _apiService: ApiService) {}

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<DebitNoteModel>> {
    return this._apiService.get(DebitNoteEnhancedApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(DebitNoteModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this._apiService.delete(DebitNoteEnhancedApiService.ROOT_POINT + '/' + id, data, option);
  }

  cancel(id: number, data = {}): Observable<any> {
    return this._apiService.patch(DebitNoteEnhancedApiService.CANCEL_POINT, { id, ...data });
  }

  create(data: DebitNoteDto): Observable<DebitNoteModel> {
    return this._apiService.post(`${DebitNoteEnhancedApiService.ROOT_POINT}`, data).pipe(map((res: IDebitNote) => DebitNoteModel.fromJson(res) as DebitNoteModel));
  }

  get(id: number, query = {}): Observable<DebitNoteModel> {
    return this._apiService.get(DebitNoteEnhancedApiService.ROOT_POINT + '/' + id, query).pipe(map(res => DebitNoteModel.fromJson(res)));
  }

  update(id: number, data: DebitNoteDto): Observable<DebitNoteModel> {
    return this._apiService.patch(DebitNoteEnhancedApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  getDetails(id: number, query = {}): Observable<PaginationAdapter<DebitNoteRecordModel>> {
    return this._apiService
      .get(DebitNoteEnhancedApiService.ROOT_POINT + '/' + id + '/details', query)
      .pipe(map(res => new PaginationAdapter(DebitNoteRecordModel, res)));
  }

  getPostings(creditNoteId: number, query = {}): Observable<PaginationAdapter<DebitNotePostingModel>> {
    return this._apiService
      .get(DebitNoteEnhancedApiService.ROOT_POINT + '/' + creditNoteId + '/postings', query)
      .pipe(map(res => new PaginationAdapter(DebitNotePostingModel, res)));
  }

  getPayments(id: number, query = {}): Observable<IDebitNotePayment[]> {
    return this._apiService.get(DebitNoteEnhancedApiService.PAYMENTS_POINT, { id, ...query }).pipe(map(res => res as IDebitNotePayment[]));
  }

  previewPosting(data: DebitNotePostingReqDto): Observable<DebitNotePostingModel[]> {
    return this._apiService
      .post(DebitNoteEnhancedApiService.PREVIEW_POSTING_POINT, data)
      .pipe(map(res => res.map((item: IDebitNotePosting) => DebitNotePostingModel.fromJson(item))));
  }

  getDebitNoteForReceipt(query = {}): Observable<PaginationAdapter<ReceiptMemoModel>> {
    return this._apiService
      .get(DebitNoteEnhancedApiService.FOR_RECEIPT_POINT, { ...query, itemType: 'DN' })
      .pipe(map(res => new PaginationAdapter(ReceiptMemoModel, res)));
  }

  // updatePosting(creditNoteId: number, data: DebitNoteEnhancedPostingDto[]) {
  //   return this._apiService.patch(DebitNoteEnhancedApiService.ROOT_POINT + '/' + creditNoteId + '/postings', { items: data }, { excludeFields: [''] });
  // }

  // updateDetail(creditNoteId: number, data: DebitNoteEnhancedDetailDto[]): Observable<DebitNoteEnhancedDetailsModel[]> {
  //   return this._apiService.patch(DebitNoteEnhancedApiService.ROOT_POINT + '/' + creditNoteId + '/details', { items: data }, { excludeFields: [''] });
  // }

  viewPdf(data: DebitNotePreviewInvoicePdfDto): Observable<any> {
    return this._apiService.post(DebitNoteEnhancedApiService.ROOT_POINT + '/template/preview', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }
}
