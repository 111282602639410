import { Expose, Type } from 'class-transformer';
import { IContactCategory } from '../interfaces';
import { BaseFixedModel } from './base.model';

export class ContactCategoryModel extends BaseFixedModel implements IContactCategory {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  description?: string;

  @Expose()
  categoryRole?: string;

  @Expose()
  @Type(() => Number)
  categoryId?: number;
}
