import { Pipe, PipeTransform } from '@angular/core';
import { isPossiblePhoneNumber, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { IPhoneNumber } from '../../data-access/interfaces';
import { formatPhoneNumber } from './util';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(val?: IPhoneNumber): string {
    if (!val || !val.phoneNumber || !isPhoneNumberValid(val)) return '';

    const numberInstance = parsePhoneNumberFromString(val.phoneNumber, { defaultCallingCode: val.countryCode }) as PhoneNumber;
    return formatPhoneNumber(numberInstance);
  }
}

export function isPhoneNumberValid(val?: IPhoneNumber): boolean {
  try {
    if (val && val.phoneNumber && val.countryCode && isPossiblePhoneNumber(val.phoneNumber, { defaultCallingCode: val.countryCode })) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}
