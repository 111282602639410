import { IPhoneNumber } from '@red/data-access';
import { EAgentSession, EBusinessDirector } from '../enums';
import { IAppointmentType } from './appointment-type.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { ISplitPart } from './split-part.interface';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';

export interface IProjectTransactionBds {
  appointmentType: IAppointmentType;
  appointmentTypeId: number;
  appTypeCode: string;
  grouping: string;
  agentType: EAgentSession;
  id: number | null;
  data?: any;
  parties: ISplitPart[];
  receivedDirectPercent: number | null;
  receivedPercent: number;
  receivedValue?: number;
  salesperson: IPersonalParticular;
  salespersonId: number | null;
  splitMatrixId: number;
  tiers: ISplitPart[];
  totalAmount?: number;
  transactionId: number | null;
  type: `${EBusinessDirector}`;
  brokerName?: string;
  brokerPhone?: IPhoneNumber;
  contactId?: number;
  contact?: IContactCustomerAndSupplier;
}
