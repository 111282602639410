import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ExceljsWorkBook } from '@shared/components/exceljs-viewer';
import { DirectDebitPaymentCreateDto, DirectDebitPaymentUpdateDto } from '@shared/data-access/dto';
import { IDirectDebitPayment } from '@shared/data-access/interfaces';
import { DirectDebitPaymentModel } from '@shared/data-access/models';
import { DirectDebitPaymentReportType } from '@shared/data-access/types';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectDebitPaymentApiService {
  static ROOT_POINT = 'finance/payment-directs';
  static POSTED_POINT = 'finance/payment-directs/:id/posted';
  static POST_PAYMENT_POINT = 'finance/payment-directs/:id/posted';
  static REPORT_POINT = 'finance/payment-directs/:id/reports/:type';
  static CONFIRM_EMAIL_SENT_POINT = 'finance/payment-directs/:id/sent';
  static DOWNLOAD_REPORT_POINT = 'finance/payment-directs/:id/reports/:type/download';
  static BANK_SUBMISSION_FILE_POINT = 'finance/payment-directs/:id/bank-file/download';

  constructor(private apiService: ApiService) { }

  create(data: DirectDebitPaymentCreateDto): Observable<DirectDebitPaymentModel> {
    return this.apiService
      .post(`${DirectDebitPaymentApiService.ROOT_POINT}`, data)
      .pipe(map((res: IDirectDebitPayment) => DirectDebitPaymentModel.fromJson(res) as DirectDebitPaymentModel));
  }

  get(itemId: number, query = {}): Observable<DirectDebitPaymentModel> {
    return this.apiService
      .get(DirectDebitPaymentApiService.ROOT_POINT + '/' + itemId, query)
      .pipe(map(res => DirectDebitPaymentModel.fromJson(res) as DirectDebitPaymentModel));
  }

  update(id: number, data: DirectDebitPaymentUpdateDto): Observable<any> {
    return this.apiService.patch(DirectDebitPaymentApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<DirectDebitPaymentModel>> {
    return this.apiService.get(DirectDebitPaymentApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(DirectDebitPaymentModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(DirectDebitPaymentApiService.ROOT_POINT + '/' + id, data, option);
  }

  confirmEmailSent(id: number, query = {}): Observable<any> {
    return this.apiService.post(DirectDebitPaymentApiService.CONFIRM_EMAIL_SENT_POINT, { id, ...query });
  }

  getReport(id: number, type: string, query = {}): Observable<ExceljsWorkBook> {
    return this.apiService.post(DirectDebitPaymentApiService.REPORT_POINT, { id, type, ...query }).pipe(map(res => ExceljsWorkBook.fromJson(res) as ExceljsWorkBook));
  }

  downloadReport(id: number, type: DirectDebitPaymentReportType, query = {}): Observable<any> {
    return this.apiService.post(
      DirectDebitPaymentApiService.DOWNLOAD_REPORT_POINT,
      { id, type, ...query },
      {
        pretreatmentResponse: false,
        requestOptions: {
          responseType: 'arraybuffer',
        },
      }
    );
  }

  downloadBankSubmissionFile(id: number, query = {}): Observable<any> {
    return this.apiService
      .post(
        DirectDebitPaymentApiService.BANK_SUBMISSION_FILE_POINT,
        { id, ...query },
        {
          pretreatmentResponse: false,
          requestOptions: {
            responseType: 'arraybuffer',
            observe: 'response',
          },
        }
      )
      .pipe(
        map(res => {
          const contentDisposition = res.headers.get('content-disposition');
          const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').trim();
          const body = res.body;
          return { fileName, body };
        })
      );
  }

  postPayment(id: number, file: File, query: Record<string, any> = {}): Observable<any> {
    const formData = new FormData();
    formData.append('bankFile', file);
    console.log('formData', formData, file, query);
    query['businessUnitId'] && formData.append('businessUnitId', query['businessUnitId']);

    //return of({});

    return this.apiService.post(DirectDebitPaymentApiService.ROOT_POINT + '/' + id + '/posted', formData).pipe(
      map(res => {
        return res;
      })
    );
  }
}
