import { BaseModel, Default } from '@red/data-access';
import { IImportSupplierInvoice, IImportSupplierInvoiceColumn } from '../interfaces/import-supplier-invoice.interface';
import { Expose, Transform, Type } from 'class-transformer';

export class ImportSupplierInvoiceModel extends BaseModel implements IImportSupplierInvoice {
  @Expose()
  rowIndex!: number;

  @Expose()
  @Default(false)
  isHover?: boolean;

  @Expose()
  @Type(() => String)
  @Default('')
  supplierCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  supplierName!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  invoiceNo!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  invoiceDate!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  creditTerm!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  gstPercent!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  gstAmount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemNo!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemDescription!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemAmount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  creditAccount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  debitAccount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  profitCentre!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  currencySign!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  currencyRate!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  reference!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  remarks!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  glEntry!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  offsetInvoice!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  isCreditNote!: string;

  get isEmpty(): boolean {
    const checkingProperties: (keyof IImportSupplierInvoice)[] = [
      'supplierCode',
      'supplierName',
      'invoiceNo',
      'invoiceDate',
      'creditTerm',
      'gstPercent',
      'gstAmount',
      'itemNo',
      'itemCode',
      'itemDescription',
      'itemAmount',
      'creditAccount',
      'debitAccount',
      'profitCentre',
      'currencySign',
      'currencyRate',
      'reference',
      'remarks',
      'glEntry',
      'offsetInvoice',
      'isCreditNote',
    ];

    return checkingProperties.every(c => {
      const value = this[c];
      return !value || value.trim() === '';
    });
  }
}

export class ImportSupplierInvoiceColumnModel extends BaseModel implements IImportSupplierInvoiceColumn {
  @Expose()
  id!: keyof IImportSupplierInvoice;

  @Expose()
  label!: string;

  @Expose()
  index!: number;

  @Expose()
  required?: boolean;

  @Expose()
  maxLength?: number;

  @Expose()
  @Transform(({ obj }) => {
    return `${obj.label} [${obj.maxLength ?? 'NA'}]${obj.required ? '*' : ''}`;
  })
  title!: string;
}
