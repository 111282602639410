import { IImportContactColumn } from '../interfaces';

export const IMPORT_CONTACT_DATA: IImportContactColumn[] = [
  {
    id: 'contactCode',
    label: 'Contact Code',
    index: 0,
    required: false,
    // required: true,
    maxLength: 20,
  },
  {
    id: 'contactName',
    label: 'Contact Name',
    index: 1,
    required: true,
    maxLength: 300,
  },
  {
    id: 'type',
    label: 'Customer / Supplier (C/S/B)',
    index: 2,
    required: true,
    maxLength: 1,
  },
  {
    id: 'accountReceivable',
    label: 'Account Receivable',
    index: 3,
    required: true,
    maxLength: 20,
  },
  {
    id: 'accountPayable',
    label: 'Account Payable',
    index: 4,
    required: true,
    maxLength: 20,
  },
  {
    id: 'contactCategory',
    label: 'Contact Category',
    index: 5,
    required: false,
    maxLength: 300,
  },
  {
    id: 'commenceDate',
    label: 'Commencement Date (dd/mm/yyyy)',
    index: 6,
    required: false,
  },
  {
    id: 'email',
    label: 'Email',
    index: 7,
    required: false,
    maxLength: 300,
  },
  {
    id: 'phonePrefix',
    label: 'Phone Prefix',
    index: 8,
    required: false,
    maxLength: 3,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    index: 9,
    required: false,
    maxLength: 300,
  },
  {
    id: 'faxPrefix',
    label: 'Fax Prefix',
    index: 10,
    required: false,
    maxLength: 3,
  },
  {
    id: 'faxNumber',
    label: 'Fax Number',
    index: 11,
    required: false,
    maxLength: 300,
  },
  {
    id: 'businessReg',
    label: 'Business Reg',
    index: 12,
    required: false,
    maxLength: 300,
  },
  {
    id: 'gstReg',
    label: 'GST Reg',
    index: 13,
    required: false,
    maxLength: 300,
  },
  {
    id: 'creditTerm',
    label: 'Credit Term',
    index: 14,
    required: false,
    maxLength: 3,
  },
  {
    id: 'creditLimit',
    label: 'Credit Limit',
    index: 15,
    required: false,
    maxLength: 10,
  },
  {
    id: 'remarks',
    label: 'Remarks',
    index: 16,
    required: false,
    maxLength: 300,
  },
  {
    id: 'address',
    label: 'Address',
    index: 17,
    required: true,
    maxLength: 300,
  },
  {
    id: 'country',
    label: 'Country',
    index: 18,
    required: true,
    maxLength: 300,
  },
  {
    id: 'postCode',
    label: 'Postcode',
    index: 19,
    required: false,
    maxLength: 300,
  },
  {
    id: 'personName',
    label: 'Contact Person Name',
    index: 20,
    required: false,
    maxLength: 300,
  },
  {
    id: 'personDesignation',
    label: 'Contact Person Designation',
    index: 21,
    required: false,
    maxLength: 300,
  },
  {
    id: 'personEmail',
    label: 'Contact Person Email',
    index: 22,
    required: false,
    maxLength: 300,
  },
  {
    id: 'personPhonePrefix',
    label: 'Contact Person Phone Prefix',
    index: 23,
    required: false,
    maxLength: 3,
  },
  {
    id: 'personPhoneNumber',
    label: 'Contact Person Phone Number',
    index: 24,
    required: false,
    maxLength: 300,
  },
  {
    id: 'bankCode',
    label: 'Bank Code',
    index: 25,
    required: false,
    // maxLength: 300,
  },
  {
    id: 'bankAccountName',
    label: 'Bank Account Name',
    index: 26,
    required: false,
    // maxLength: 300,
  },
  {
    id: 'bankAccountNumber',
    label: 'Bank Account Number',
    index: 27,
    required: false,
    // maxLength: 300,
  },
  {
    id: 'status',
    label: 'Active (Y/N)',
    index: 28,
    required: false,
    maxLength: 1,
  },
  // {
  //   id: 'status',
  //   label: 'Active (Y/N)',
  //   index: 25,
  //   required: false,
  //   maxLength: 1,
  // },
];
