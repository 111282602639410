import { ITransactedTypeValue, ITransactedTypeValueItem } from './../interfaces/transacted-type-value.interface';
import { BaseModel, Default } from '@red/data-access';
import { Expose } from 'class-transformer';
export class TransactedTypeValueModel extends BaseModel implements ITransactedTypeValue {
  @Expose()
  @Default({})
  cashAdvance!: ITransactedTypeValueItem;

  @Expose()
  @Default({})
  discount!: ITransactedTypeValueItem;

  @Expose()
  @Default({})
  powerDiscount!: ITransactedTypeValueItem;

  @Expose()
  @Default({})
  incentive!: ITransactedTypeValueItem;

  @Expose()
  @Default({})
  buyerRebate!: ITransactedTypeValueItem;

}
