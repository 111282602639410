import { IPersonalParticular } from './personal-particular.interface';
import { EPersonalVerifyKey, EPersonalVerifyStatus, EPersonalVerifyType } from '../enums';
import { ITimestamp } from './timestamp.interface';

export interface IPersonalVerify extends ITimestamp {
  id: number;
  status: EPersonalVerifyStatus;
  type: EPersonalVerifyType;
  name: EPersonalVerifyKey;
  oldValue: IPersonalVerifyChangeValue;
  newValue: IPersonalVerifyChangeValue;
  salespersonId: number;
  salesperson: Partial<IPersonalParticular>;
}

export interface IPersonalVerifyChangeValue {
  value: unknown;
  data: unknown;
}
