import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IReceipt } from './receipt.interface';

export interface IParentInvoicePayable {
  id: number;
  code: string;
  total: number;
  receipts?: IReceipt[];
}

export interface IInvoicePayable {
  id: number;
  invoiceNumber: string;
  invoiceDate?: string;
  invoiceDueDate?: Date;
  code?: string;
  contactId: number;
  contact: IContactCustomerAndSupplier;
  total: number;
  paidAmount: number;
  balanceDue: number;
  currency: string;
  createdAt: string;
  updatedAt: string;
  parentInvoice?: IParentInvoicePayable;
}
