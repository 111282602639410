<mat-form-field class="w-full" appearance="fill">
  <mat-label>{{ data.name }}</mat-label>
  <mat-icon *ngIf="dataUpcast?.value" matSuffix class="icon-size-5 text-theme-dark" (click)="clear()">clear</mat-icon>
  <ng-container *ngIf="!isNumber; else inputNumber">
    <input #searchInput type="text" matInput [formControl]="dataUpcast" [matAutocomplete]="auto" [matAutocompleteDisabled]="options.length === 0" />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of options" [value]="option.label">
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>
  <ng-template #inputNumber>
    <input #searchInput type="text" mask="separator.4" matInput [formControl]="dataUpcast" />
  </ng-template>
</mat-form-field>
