export interface IOneMapResult {
  address: string;
  blockNumber: string;
  buildingName: string;
  postalCode: string;
  streetName: string;
  searchVal: string;
}

export interface IOneMapResponse {
  found: number;
  pageNum: number;
  totalNumPages: number;
  results: IOneMapResult[];
}
