import { UnitNoOfRoomTypeRecord } from '../types';
import { EUnitNoOfRoom } from '../enums';

export const UNIT_NO_OF_ROOM_OPTIONS: UnitNoOfRoomTypeRecord = {
  [EUnitNoOfRoom['1B']]: {
    id: EUnitNoOfRoom['1B'],
    value: EUnitNoOfRoom['1B'],
    viewValue: '1B',
  },
  [EUnitNoOfRoom['1B+']]: {
    id: EUnitNoOfRoom['1B+'],
    value: EUnitNoOfRoom['1B+'],
    viewValue: '1B+',
  },
  [EUnitNoOfRoom['2B']]: {
    id: EUnitNoOfRoom['2B'],
    value: EUnitNoOfRoom['2B'],
    viewValue: '2B',
  },
  [EUnitNoOfRoom['2B+']]: {
    id: EUnitNoOfRoom['2B+'],
    value: EUnitNoOfRoom['2B+'],
    viewValue: '2B+',
  },
  [EUnitNoOfRoom['3B']]: {
    id: EUnitNoOfRoom['3B'],
    value: EUnitNoOfRoom['3B'],
    viewValue: '3B',
  },
  [EUnitNoOfRoom['3B+']]: {
    id: EUnitNoOfRoom['3B+'],
    value: EUnitNoOfRoom['3B+'],
    viewValue: '3B+',
  },
  [EUnitNoOfRoom['4B']]: {
    id: EUnitNoOfRoom['4B'],
    value: EUnitNoOfRoom['4B'],
    viewValue: '4B',
  },
  [EUnitNoOfRoom['4B+']]: {
    id: EUnitNoOfRoom['4B+'],
    value: EUnitNoOfRoom['4B+'],
    viewValue: '4B+',
  },
  [EUnitNoOfRoom['5B']]: {
    id: EUnitNoOfRoom['5B'],
    value: EUnitNoOfRoom['5B'],
    viewValue: '5B',
  },
  [EUnitNoOfRoom['>5B']]: {
    id: EUnitNoOfRoom['>5B'],
    value: EUnitNoOfRoom['>5B'],
    viewValue: '>5B',
  },
  [EUnitNoOfRoom['Studio']]: {
    id: EUnitNoOfRoom['Studio'],
    value: EUnitNoOfRoom['Studio'],
    viewValue: 'Studio',
  },
  [EUnitNoOfRoom['N.A']]: {
    id: EUnitNoOfRoom['N.A'],
    value: EUnitNoOfRoom['N.A'],
    viewValue: 'N.A',
  },
};
