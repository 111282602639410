import { Injectable } from "@angular/core";
import { ApiService, IApiOption } from "@red/api";
import { PaginationAdapter } from "@red/data-access";
import { AppointmentTypeUpdateDto, AppointmentTypeCreateDto } from "@shared/data-access/dto";
import { IAppointmentType, IAppointmentTypeConfig, IProject } from "@shared/data-access/interfaces";
import { AppointmentTypeConfigModel, AppointmentTypeModel } from "@shared/data-access/models";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppointmentTypeApiService {
  static ROOT_POINT = 'transaction/appointment-types';
  static CONFIGS = 'transaction/appointment-types/configs';

  constructor(private apiService: ApiService) { }

  create(data: AppointmentTypeCreateDto): Observable<AppointmentTypeModel> {
    return this.apiService
        .post(`${AppointmentTypeApiService.ROOT_POINT}`, data)
        .pipe(
            map(
                (res: IAppointmentType) => AppointmentTypeModel.fromJson(res) as AppointmentTypeModel
            )
        );
  }
  get(itemId: number): Observable<AppointmentTypeModel> {
    return this.apiService
        .get(AppointmentTypeApiService.ROOT_POINT + '/' + itemId)
        .pipe(map(res => AppointmentTypeModel.fromJson(res) as AppointmentTypeModel));
  }
  getGlobalConfigs(): Observable<AppointmentTypeConfigModel[]> {
    return this.apiService
        .get(AppointmentTypeApiService.CONFIGS)
        .pipe(map((res:IAppointmentTypeConfig[])=> res.map(item => AppointmentTypeConfigModel.fromJson(item))));
  }
  update(id: number, data: AppointmentTypeUpdateDto): Observable<any> {
      return this.apiService.patch(AppointmentTypeApiService.ROOT_POINT + '/' + id, data,{excludeFields:['']});
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<AppointmentTypeModel>> {
      return this.apiService
          .get(AppointmentTypeApiService.ROOT_POINT, query, option)
          .pipe(map(data => new PaginationAdapter(AppointmentTypeModel,data)));
  }


  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(
      AppointmentTypeApiService.ROOT_POINT, {ids}, option
    );
  }


}

