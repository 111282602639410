import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieDict, isPresent, parseCookieString } from './auth.utils';

const COOKIE_SEPARATOR = ';';
export interface ICookieReader {
  /**
   * @description
   * Returns if the given cookie key exists or not.
   *
   * @param key Id to use for lookup.
   * @returns true if key exists, otherwise false.
   */
  hasKey(key: string): boolean;
  /**
   * @description
   * Returns the value of given cookie key.
   *
   * @param key Id to use for lookup.
   * @returns Raw cookie value.
   */
  get(key: string): string | undefined;
  readAllCookieAsString(): string;
  /**
   * @description
   * Returns a key value object with all the cookies.
   *
   * @returns All cookies
   */
  getAll(): CookieDict;
}
@Injectable()
export class CookieReaderService implements ICookieReader {
  constructor(@Inject(DOCUMENT) private document: any) {}
  /**
   * @description
   * Returns if the given cookie key exists or not.
   *
   * @param key Id to use for lookup.
   * @returns true if key exists, otherwise false.
   */
  hasKey(key: string): boolean {
    const value = this.get(key);
    return isPresent(value);
  }
  /**
   * @description
   * Returns the value of given cookie key.
   *
   * @param key Id to use for lookup.
   * @returns Raw cookie value.
   */
  get(key: string): string | undefined {
    const cookieDict = this.getAll();
    return cookieDict[key];
  }
  readAllCookieAsString(): string {
    return this.document.cookie || '';
  }
  /**
   * @description
   * Returns a key value object with all the cookies.
   *
   * @returns All cookies
   */
  getAll(): CookieDict {
    const cookieString = this.readAllCookieAsString();
    console.log('cookieString', cookieString);
    return parseCookieString(cookieString);
  }
}
