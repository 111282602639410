import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class InvoiceDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  contactId?: number;

  @Expose()
  @Type(() => Number)
  contactPersonId?: number;

  @Expose()
  @Type(() => Number)
  contactAddressId?: number;

  @Expose()
  remarks!: string;

  @Expose()
  reference!: string;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  @Type(() => Number)
  paymentTerm?: number;

  // for tax field in summary
  @Expose()
  @Type(() => Number)
  gstPercent?: number;

  @Expose()
  @Type(() => Number)
  salePersonId?: number;

  @Expose()
  billingAddressCustom?: string;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  @Type(() => Number)
  templateId!: number;

  @Expose()
  currency!: string;

  @Expose()
  @Type(() => Number)
  taxValue!: number;

  @Expose()
  @Default(false)
  gstEdited?: boolean;
}
