import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IEcddOption } from '../interfaces';

export class EcddOptionModel extends BaseModel implements IEcddOption {
  @Expose()
  id!: number;

  @Expose()
  description!: string;

  @Expose()
  optionGroupId!: number;

  @Expose()
  autoFill!: number[];
}
