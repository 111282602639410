import { FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class LinkedInvoiceCreditNoteDto {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  date?: string;

  @Expose()
  number?: string;
}
