import { IAssetCategory } from './asset-category.interface';
import { IProfitCentres } from './profit-centres.interface';
import { ITaxInvoice } from './tax-invoice-enhanced.interface';
import { IUserAccount } from './user-account.interface';

export interface IAssetRegisterComputeDep {
  startDate: string;
  yearPeriod: number;
  monthPeriod: number;
  initialAmount: number;
  residualAmount: number;
}

export interface IAssetRegisterDepreciation {
  accumulatedAmount?: number;
  accumulatedMonth: number;
  businessUnitId: number;
  depreciationAmount: number;
  finalDepValue: number;
  firstDepValue: number;
  id: number;
  initialAmount: number;
  lastAmount?: number;
  monthPeriod: number;
  residualAmount: number;
  seqDepValue: number;
  startDate: string;
  totalDepValue: number;
  totalMonth: number;
  yearPeriod: number;
}

export interface IAssetRegister {
  // General Detail
  id: number;
  code: string;
  businessUnitId?: number;
  description: string;
  category?: Partial<IAssetCategory>;
  categoryId: number;
  serialNumber?: string;
  profitCenter?: Partial<IProfitCentres>;
  profitCenterId?: number;
  location?: string;
  specification?: string;

  // Person In Charge
  inChargePersonId?: number;
  inChargePerson?: IUserAccount;

  // Purchase Detail
  invoice?: ITaxInvoice;
  invoiceId: number;
  purchaseDate: string;
  purchaseAmount: number;
  purchaseQuantity: number;
  remarks?: string;
  items?: string[];

  // Depreciation Detail
  depreciationId: number;
  depreciation: IAssetRegisterDepreciation;
}
