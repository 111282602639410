import { IPersonalParticular } from './personal-particular.interface';

export interface IInternalCobrokeReferral {
  id: number;
  salespersonId: number;
  salesperson: IPersonalParticular;
  absorbType: { agent: boolean; hr: boolean };
  fee: number;
  formula: string;
  createdAt: string;
  updatedAt: string;
}
