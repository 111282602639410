export interface IImportReceipt {
  receiptNumber: string;
  receiptDate: string;
  type: string;
  contactCode: string;
  payerName: string;
  chequeNumber: string;
  itemNo: string;
  itemType: string;
  description: string;
  itemAmount: string;
  itemCreditAccount: string;
  bankAccount: string;
  profitCentre: string;
  currencySign: string;
  currencyRate: string;
  glEntry: string;
  recordId: string;
  invoiceItemNo: string;
}

export interface IImportReceiptColumn {
  id: keyof IImportReceipt;
  label: string;
  index: number;
  required?: boolean;
  maxLength?: number;
}
