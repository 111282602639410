import { ECommMatrixVerifyStatus } from '../enums';
import { StatusRecord } from '../types/status.type';

export const COMMISSION_MATRIX_STATUS = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'inactive',
    name: 'InActive',
  },
] as const;

export const COMMISSION_MATRIX_VERIFY_STATUS: StatusRecord<ECommMatrixVerifyStatus> = {
  [ECommMatrixVerifyStatus.draft]: {
    id: ECommMatrixVerifyStatus.draft,
    name: 'Draft',
    filterName: 'Draft',
    class: 'status-box-default',
    canSubmit: true,
    canAgentEdit: true,
    canEdit: true,
    canDelete: true,
    canAgentDelete: true,
    canSave: true,
  },
  [ECommMatrixVerifyStatus.rework]: {
    id: ECommMatrixVerifyStatus.rework,
    name: 'Send Back',
    filterName: 'Send Back',
    class: 'status-box-danger',
    canSubmit: true,
    canEdit: true,
    // canAgentEdit: true,
    canDelete: true,
  },
  [ECommMatrixVerifyStatus.submitted]: {
    id: ECommMatrixVerifyStatus.submitted,
    name: 'Pending Verification',
    filterName: 'Pending',
    class: 'status-box-warning',
    canEdit: true,
    canDelete: true,
  },
  [ECommMatrixVerifyStatus.approved]: {
    id: ECommMatrixVerifyStatus.approved,
    name: 'Approved',
    filterName: 'Approved',
    class: 'status-box-success',
    canEdit: true,
    canDelete: true,
  },
  [ECommMatrixVerifyStatus.rejected]: {
    id: ECommMatrixVerifyStatus.rejected,
    name: 'Rejected',
    filterName: 'Rejected',
    class: 'status-box-danger',
    canEdit: true,
  },
  [ECommMatrixVerifyStatus.locked]: {
    id: ECommMatrixVerifyStatus.locked,
    name: 'Locked',
    filterName: 'Locked',
    class: 'status-box-default',
    canEdit: true,
  },
};
