import { IDateTimeConfig } from '@agent-portal/config';

export enum DATETIME_FORMAT {
  DATE = 'DD/MM/YYYY',
  SHORT_DATE = 'DD MMM YYYY',
  DATETIME = 'DD MMM YYYY LT',
  TIME = 'HH:mm',
}
export const dateTimeConfig: IDateTimeConfig = {
  date: DATETIME_FORMAT.DATE,
  shortDate: DATETIME_FORMAT.SHORT_DATE,
  time: DATETIME_FORMAT.TIME,
  dateTime: DATETIME_FORMAT.DATETIME,
};
