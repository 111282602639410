import { Observable, ReplaySubject } from 'rxjs';

export class PermissionStore<T> {
  /** Currently-selected values. */
  private _selection = new Set<T>();

  /** Keeps track of the selected options that haven't been emitted by the change event. */
  private _selectedToEmit: T[] = [];
  /** Cache for the array value of the selected items. */
  private _selected!: T[] | null;

  /** Selected values. */
  get selected(): T[] {
    if (!this._selected) {
      this._selected = Array.from(this._selection.values());
    }

    return this._selected;
  }
  constructor(initiallySelectedValues?: T[], public compareWith?: (o1: T, o2: T) => boolean) {
    if (initiallySelectedValues && initiallySelectedValues.length) {
      initiallySelectedValues.forEach(value => this._markSelected(value));
    }
    this._emitChangeEvent();
  }
  /** Event emitted when the value has changed. */
  private _permission: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  /** Selects a value. */
  private _markSelected(value: T) {
    if (!this.isSelected(value)) {
      if (!this.isSelected(value)) {
        this._selection.add(value);
      }

      // if (this._emitChanges) {
      this._selectedToEmit.push(value);
      // }
    }
  }
  /** Whether there are queued up change to be emitted. */
  private _hasQueuedChanges() {
    return !!this._selectedToEmit.length;
  }
  /** Emits a change event and clears the records of selected and deselected values. */
  private _emitChangeEvent() {
    // Clear the selected values so they can be re-cached.
    this._selected = null;

    if (this._selectedToEmit.length) {
      this._permission.next(this._selectedToEmit);
      this._selectedToEmit = [];
    }
  }

  /**
   * Determines whether a value is selected.
   */
  isSelected(value: T): boolean {
    if (this.compareWith) {
      for (const otherValue of this._selection) {
        if (this.compareWith(otherValue, value)) {
          return true;
        }
      }
      return false;
    }
    return this._selection.has(value);
  }

  find(compareFn: (item: T) => boolean): T | null {
    for (const otherValue of this._selection) {
      const selected = compareFn(otherValue);
      if (selected) {
        return otherValue;
      }
    }
    return null;
  }
  forcedEmitEvent(): void {
    this._permission.next(this._selectedToEmit);
  }

  /**
   * Selects a value or an array of values.
   * @param values The values to select
   * @return Whether the selection changed as a result of this call
   * @breaking-change 16.0.0 make return type boolean
   */
  select(...values: T[]): boolean {
    values.forEach(value => this._markSelected(value));
    const changed = this._hasQueuedChanges();
    this._emitChangeEvent();
    return changed;
  }

  /**
   * Clears all of the selected values.
   * @param flushEvent Whether to flush the changes in an event.
   *   If false, the changes to the selection will be flushed along with the next event.
   * @return Whether the selection changed as a result of this call
   * @breaking-change 16.0.0 make return type boolean
   */
  clear(flushEvent = true): boolean | void {
    this._unmarkAll();
    const changed = this._hasQueuedChanges();
    if (flushEvent) {
      this._emitChangeEvent();
    }
    return changed;
  }

  /**
   * Determines whether the model does not have a value.
   */
  isEmpty(): boolean {
    return this._selection.size === 0;
  }
  /** Deselects a value. */
  private _unmarkSelected(value: T) {
    if (this.isSelected(value)) {
      this._selection.delete(value);
    }
  }

  /** Clears out the selected values. */
  private _unmarkAll() {
    if (!this.isEmpty()) {
      this._selection.forEach(value => this._unmarkSelected(value));
    }
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get permissions$(): Observable<T[]> {
    return this._permission.asObservable();
  }
}
