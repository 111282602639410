import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ProfitSharingCreateItemDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  commId!: number;

  @Expose()
  @Type(() => Number)
  sequence!: number;

  @Expose()
  @Type(() => Number)
  profit!: number;
}

export class ProfitSharingCreateDto extends BaseModel {
  @Expose()
  @Type(() => String)
  code!: string;

  @Expose()
  @Type(() => Number)
  schemeTypeId!: number;

  @Expose()
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => Number)
  branchOutAmount!: number;

  @Expose()
  @Type(() => Number)
  maximumGroup1!: number;

  @Expose()
  @Type(() => Number)
  maximumGroup2!: number;

  @Expose()
  @Type(() => ProfitSharingCreateItemDto)
  group1!: ProfitSharingCreateItemDto[];

  @Expose()
  @Type(() => ProfitSharingCreateItemDto)
  group2!: ProfitSharingCreateItemDto[];
}

export class ProfitSharingUpdateDto extends BaseModel {
  @Expose()
  @Type(() => String)
  code!: string;

  @Expose()
  @Type(() => Number)
  schemeTypeId!: number;

  @Expose()
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => Number)
  branchOutAmount!: number;

  @Expose()
  @Type(() => Number)
  maximumGroup1!: number;

  @Expose()
  @Type(() => Number)
  maximumGroup2!: number;

  @Expose()
  @Type(() => ProfitSharingCreateItemDto)
  group1!: ProfitSharingCreateItemDto[];

  @Expose()
  @Type(() => ProfitSharingCreateItemDto)
  group2!: ProfitSharingCreateItemDto[];
}
