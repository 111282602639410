import { MatTab, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExceljsWorkBook } from '../models';
import { ExceljsWorksheetComponent } from '../exceljs-worksheet/exceljs-worksheet.component';
import { Subject, Subscription, startWith, takeUntil } from 'rxjs';

/** Used to generate unique ID's for each tab component */
let nextId = 0;
@Component({
  selector: 'red-exceljs-workbook',
  standalone: true,
  imports: [CommonModule, ExceljsWorksheetComponent, MatTabsModule],
  templateUrl: './exceljs-workbook.component.html',
  styleUrls: ['./exceljs-workbook.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ExceljsWorkbookComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() set workbook(val: ExceljsWorkBook | undefined) {
    this._workbook = val;
    // this.renderWorksheet();
  }
  get workbook() {
    return this._workbook;
  }
  private _workbook!: ExceljsWorkBook | undefined;
  @Output() worksheetSelectedChange = new EventEmitter<ExceljsWorksheetComponent>();
  private _indexToSelect: number | null = null;
  private _selectedIndex: number | null = null;
  /** Subscription to tabs being added/removed. */
  private _tabsSubscription = Subscription.EMPTY;
  private _tabSubscription = Subscription.EMPTY;
  private _groupId: number;
  @ContentChildren(MatTab) _tabs!: QueryList<MatTab>;
  @ViewChild(MatTabGroup, { static: true }) _tabGroup!: MatTabGroup;
  @ViewChildren(ExceljsWorksheetComponent) _worksheets!: QueryList<ExceljsWorksheetComponent>;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    this._groupId = nextId++;
  }
  ngAfterContentInit(): void {
    this._tabsSubscription = this._tabGroup.selectedIndexChange.subscribe((index: number) => {
      const indexToSelect = index;
      console.log('ngAfterViewInit children changes-->', indexToSelect);
      // Maintain the previously-selected tab if a new tab is added or removed and there is no
      // explicit change that selects a different tab.
      // if (indexToSelect === this._selectedIndex) {
      const worksheets = this._worksheets.toArray();
      console.log('ngAfterViewInit worksheets changes-->', worksheets);
      let selectedTab: ExceljsWorksheetComponent | undefined;
      for (let i = 0; i < worksheets.length; i++) {
        worksheets[i].isActive = i === index;
        if (worksheets[i].isActive) {
          // Assign both to the `_indexToSelect` and `_selectedIndex` so we don't fire a changed
          // event, otherwise the consumer may end up in an infinite loop in some edge cases like
          // adding a tab within the `selectedIndexChange` event.
          this._indexToSelect = this._selectedIndex = i;
          selectedTab = worksheets[i];
          break;
        }
        // If we haven't found an active tab and a tab exists at the selected index, it means
        // that the active tab was swapped out. Since this won't be picked up by the rendering
        // loop in `ngAfterContentChecked`, we need to sync it up manually.
        if (!selectedTab && worksheets[indexToSelect]) {
          Promise.resolve().then(() => {
            worksheets[indexToSelect].isActive = true;
          });
        }
      }
      if (selectedTab) {
        this.worksheetSelectedChange.emit(selectedTab);
      }
      // }
      this._changeDetectorRef.markForCheck();
    });
  }
  /** Listens to changes in all of the tabs. */
  // private _subscribeToAllTabChanges() {
  //   // Since we use a query with `descendants: true` to pick up the tabs, we may end up catching
  //   // some that are inside of nested tab groups. We filter them out manually by checking that
  //   // the closest group to the tab is the current one.
  //   this._tabs.changes.pipe(startWith(this._tabs)).subscribe((tabs: QueryList<MatTab>) => {
  //     this._worksheets.reset(
  //       tabs.filter(tab => {
  //         return tab._closestWorkBook === this || !tab._closestWorkBook;
  //       })
  //     );
  //     console.log('notifyOnChanges -->', this._worksheets);
  //     this._worksheets.notifyOnChanges();
  //   });
  // }
  ngOnDestroy(): void {
    this._tabsSubscription.unsubscribe();
    this._tabSubscription.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.worksheetSelectedChange.complete();
  }

  ngOnInit(): void {
    console.log('ngOnInit children -->', this._tabs);
  }
  // private _subscribleToTabScroll(selected: ExceljsWorksheetComponent): void {
  //   if (this._tabSubscription) {
  //     this._tabSubscription.unsubscribe();
  //   }
  //   if (selected.table.scrollController) {
  //     this._tabSubscription = selected.table.scrollController.srollToEnd.subscribe(data => {});
  //   }
  // }
  // renderWorksheet(): void {}
  /** Clamps the given index to the bounds of 0 and the tabs length. */
  private _clampTabIndex(index: number | null): number {
    // Note the `|| 0`, which ensures that values like NaN can't get through
    // and which would otherwise throw the component into an infinite loop
    // (since Math.max(NaN, 0) === NaN).
    return Math.min(this._tabs.length - 1, Math.max(index || 0, 0));
  }
}
