import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { ITransferEmplacementHistory } from '../interfaces';
import { ETransferEmplacementType } from '../enums/transfer-emplacement.enum';
import { TRANSFER_EMPLACEMENT_TYPES } from '../data/transfer-emplacement-type.data';
import { UpdatedByModel } from './updated-by.model';

export class TransferEmplacementHistoryModel extends BaseModel implements ITransferEmplacementHistory {
  @Expose()
  businessUnitId!: number;

  @Expose()
  manager!: {
    id: number;
    businessName: string;
    nricName: string;
    nricfin: string;
    ceaRegNo: string;
    isActive: boolean;
  };

  @Expose()
  managerId!: number;

  @Expose()
  salespersonId!: number;

  @Expose()
  effectiveDate!: string;

  @Expose()
  remarks!: string;

  @Expose()
  type!: ETransferEmplacementType;

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  deletedAt!: string;

  @Expose()
  deletedBy!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  get typeDisplay() {
    return TRANSFER_EMPLACEMENT_TYPES.find(type => type.id === this.type)?.name;
  }
}
