import { MetabaseNumberSeparator, MetabaseTimeEnabled, MetabaseTimeStyle, MetabaseViewAs, MetadataColDataType } from './metabase.type';

export interface IMetabaseCollection {
  description: string;
  slug: string;
  name: string;
  effective_ancestors: IMetabaseEffectiveAncestor[];
  effective_location: string;
  parent_id: number;
  id: number;
}
export interface IMetabaseEffectiveAncestor {
  name: string;
  id: number;
}
export type IMetabaseCardDataRow = Array<Array<string | number | null>>;
export interface IMetabaseCardDataCol {
  name: string;
  baseType: MetadataColDataType;
  displayName: string;
  semanticType: string;
  fieldRef: [string, string, Record<string, any>];
  effectiveType: string;
}
export interface IMetabaseCardData {
  rows: IMetabaseCardDataRow;
  cols: IMetabaseCardDataCol[];
  resultsMetadata: {
    columns: IMetabaseCardDataCol[];
  };
  resultsTimezone: string;
}
export interface IMetabaseColSetting {
  // value settings
  timeEnabled: MetabaseTimeEnabled;
  timeStyle: MetabaseTimeStyle;
  numberSeparators: MetabaseNumberSeparator;
  prefix: string;
  // template settings
  viewAs?: MetabaseViewAs;
  linkText?: string;
  linkUrl?: string;
}
export interface IMetabaseColSettings {
  [x: string]: IMetabaseColSetting;
}
export interface IMetabaseTableColumnSetting {
  name: string;
  fieldRef: [string, string, Record<string, any>];
  enabled: boolean;
}
export interface IMetadataVisualizationSetting {
  columnSettings: IMetabaseColSettings;
  tableColumns: IMetabaseTableColumnSetting[];
}
export interface IMetabaseCardQuery {
  data: IMetabaseCardData;
  rowCount: number;
}
export interface IMetabaseCardParameter {
  default: string;
  id: string;
  name: string;
  slug: string;
  target: [string, [string, string]];
  type: string;
  value?: any;
}
export interface IMetabaseDataSetQueryTemplateTag {
  default: string;
  displayName: string;
  id: string;
  name: string;
  required: boolean;
  type: string;
}
export interface IMetabaseDataSetQueryTemplateTags {
  [x: string]: IMetabaseDataSetQueryTemplateTag;
}
export interface IMetabaseDataSetQueryNative {
  templateTags: IMetabaseDataSetQueryTemplateTags;
}
export interface IMetabaseDataSetQuery {
  native: IMetabaseDataSetQueryNative;
}
export interface IMetadataCard {
  id: number;
  name: string;
  collectionId: number;
  collection: IMetabaseCollection;
  datasetQuery: IMetabaseDataSetQuery;
  resultMetadata: IMetabaseCardDataCol[];
  parameters: IMetabaseCardParameter[];
  visualizationSettings: IMetadataVisualizationSetting;
}

export type IMetabaseParameterFieldControlInput = IMetabaseCardParameter & {
  controlType: string;
};
