import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, Subject, tap, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PageTitleAsyncData, PAGE_TITLE_ASYNC } from './page-title.token';

@Injectable({ providedIn: 'root' })
export class PageDetailTitleAsyncResolver {
  private cached = new Map<number | string, string>();
  constructor(@Inject(PAGE_TITLE_ASYNC) private pageTitle$: Subject<PageTitleAsyncData>) {
    if (!this.pageTitle$) {
      throw Error('can not find PAGE_TITLE_ASYNC');
    }
    this.fetchData()
      .pipe(
        tap(val => {
          this.cached.set(val.id, val.title);
        })
      )
      .subscribe();
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let nextRoute: ActivatedRouteSnapshot | null = state.root;
    while (nextRoute && !nextRoute.paramMap.has('id')) {
      nextRoute = nextRoute.firstChild;
    }
    if (!nextRoute) {
      return throwError(() => new Error(`can not find id`));
    }
    const id = Number(nextRoute.paramMap.get('id') as string);
    if (this.cached.has(id)) {
      return of(this.popValue(id));
    }
    return this.fetchData().pipe(switchMap(() => this.resolve(route, state)));
  }

  popValue(id: number | string): string {
    const title = this.cached.get(id) as string;
    this.cached.delete(id);
    return title;
  }

  fetchData() {
    return this.pageTitle$;
  }
}
