import { BaseModel, Default } from '@red/data-access';
import { IImportJournalEntry, IImportJournalEntryColumn } from '../interfaces';
import { Expose, Transform, Type } from 'class-transformer';

export class ImportJournalEntryModel extends BaseModel implements IImportJournalEntry {
  @Expose()
  rowIndex!: number;

  @Expose()
  @Default(false)
  isHover!: boolean;

  @Expose()
  @Type(() => String)
  @Default('')
  journalNumber!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  journalDate!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  title!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  accountCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  description!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  debit!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  credit!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  profitCentre!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  batchCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  batchDescription!: string;

  get isEmpty(): boolean {
    const checkingProperties: (keyof IImportJournalEntry)[] = [
      'journalNumber',
      'journalDate',
      'title',
      'accountCode',
      'description',
      'debit',
      'credit',
      'profitCentre',
      'batchCode',
      'batchDescription',
    ];

    return checkingProperties.every(c => {
      const value = this[c];
      return !value || value.trim() === '';
    });
  }
}

export class ImportJournalEntryColumnModel extends BaseModel implements IImportJournalEntryColumn {
  @Expose()
  id!: keyof IImportJournalEntry;

  @Expose()
  label!: string;

  @Expose()
  index!: number;

  @Expose()
  @Default(false)
  required!: boolean;

  @Expose()
  @Default(300)
  maxLength!: number;

  @Expose()
  @Transform(({ obj }) => {
    return `${obj.label} [${obj.maxLength ?? 'NA'}]${obj.required ? '*' : ''}`;
  })
  title!: string;
}
