import { BaseModel, Default, PhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EAgentBlankFormSession, EBusinessDirector } from '../enums';
import { IAppointmentType, IBlankFormBd } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';
import { PersonalParticularModel } from './personal-particular.model';
import { SplitPartModel } from './split-part.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';

export class BlankFormBdModel extends BaseModel implements IBlankFormBd {
  @Expose()
  @Transform(({ value }) => AppointmentTypeModel.fromJson(value))
  appointmentType!: IAppointmentType;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  appTypeCode!: string;

  @Expose()
  agentType!: EAgentBlankFormSession;

  @Expose()
  id!: number | null;

  @Expose()
  data?: any;

  @Expose()
  @Transform(({ value }) => {
    if (!value || value.length === 0) {
      return [
        {
          name: 'Recruiter',
          level: 4,
        },
      ];
    }
    return value;
  })
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedDirectPercent!: number | null;

  @Expose()
  @Type(() => Number)
  receivedValue?: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  salespersonId!: number | null;

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Transform(({ value }) => {
    if (!value || value.length === 0) {
      return [
        {
          name: 'Commission',
          level: 1,
        },
        {
          name: 'Tier 1',
          level: 2,
        },
        {
          name: 'Tier 2',
          level: 3,
        },
      ];
    }
    return value;
  })
  @Type(() => SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  @Type(() => Number)
  totalAmount?: number;

  @Expose()
  transactionId!: number | null;

  @Expose()
  type!: EBusinessDirector;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  grouping!: string;

  // Transform enum into type

  @Expose()
  brokerName?: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone?: PhoneNumber;

  @Expose()
  contactId?: number;

  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact?: ContactCustomerAndSupplierModel;

  @Expose()
  attrs!: any;
}
