import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ProjectExternalCobrokeReferralCreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  projectId!: number;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  @Type(() => String)
  formula!: string;

  @Expose()
  @Type(() => Number)
  fee!: number;
}

export class ProjectExternalCobrokeReferralUpdateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  @Type(() => String)
  formula!: string;

  @Expose()
  @Type(() => Number)
  fee!: number;
}
