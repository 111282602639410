export interface IBank {
  id: number;
  code: string;
  name: string;
  // status:string;
  isActive: boolean;
  isOther: boolean;
  createdAt: string;
  updatedAt: string;
  swiftCode: string;
  sequence?: number;

  minAccountDigit: number;
  maxAccountDigit?: number;
}
