import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { CommissionPayoutModel } from '@shared/data-access/models';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommissionPayoutApiService {
  static ROOT_POINT = 'commission-payout/portal/payouts';

  mockedData: PaginationAdapter<CommissionPayoutModel> = {
    results: [
      {
        id: 1,
        caseNo: '123456',
        subjectProperty: '7 Amber Gardens One Amber #01-01, Singapore 439964',
        commType: 'OR T1 - CA',
        payoutDate: '2022-12-08T02:38:23.060Z',
        payoutMethod: 'Bank Transfer',
        payoutValue: 1000,
        commScheme: 1,
        transactionType: 'Sale',
      },
      {
        id: 2,
        caseNo: '123456',
        subjectProperty: '277, ORCHARD ROAD, #12-99 ORCHARD GATEWAY, SINGAPORE, 238858',
        commType: 'OR T2 - CA',
        payoutDate: '2022-13-08T06:38:23.060Z',
        payoutMethod: 'GIRO',
        payoutValue: 20840.12,
        commScheme: 1,
        transactionType: 'Resale',
      },
    ],
    pagination: {
      page: 1,
      total: 11,
      pageCount: 1,
      pageSizeOptions: [12, 24, 36, 48],
      limit: 10,
    },
  };

  constructor(private apiService: ApiService) {}

  // search(query = {}, option?: IApiOption): Observable<PaginationAdapter<CommissionPayoutModel>> {
  //   return this.apiService.get(CommissionPayoutApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(CommissionPayoutModel, data)));
  // }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<CommissionPayoutModel>> {
    return of(this.mockedData);
  }
}
