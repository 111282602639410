import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { cloneDeep, concat, defaults, differenceWith, findIndex, get, groupBy, isEqual, mapKeys, mapValues, reduce, values } from 'lodash-es';
import { isObservable, map, Observable, Subject, take, takeUntil } from 'rxjs';
import { PaginationAdapter } from './../data-access/adapters/pagination.adapter';

type Differ<T> = {
  item: T;
  type?: 'new' | 'updated' | 'deleted';
};

export function registryChangeDependency(
  formGroup: UntypedFormGroup,
  unsubscribeAll: Subject<void>,
  sourceName: string,
  distName: string,
  fn: (value: any) => any | Observable<any>
): void {
  formGroup
    .get(sourceName)
    ?.valueChanges.pipe(takeUntil(unsubscribeAll))
    .subscribe(val => {
      if (isObservable(fn(val))) {
        fn(val)
          .pipe(take(1))
          .subscribe((data: any) => {
            formGroup.get(distName)?.setValue(data);
          });
      } else {
        formGroup.get(distName)?.setValue(fn(val));
      }
    });
}

export function valueChangeDependOn(
  source: AbstractControl,
  dist: AbstractControl | null,
  unsubscribeAll: Subject<void>,
  fn: (value: any) => any | Observable<any>
): void {
  source?.valueChanges.pipe(takeUntil(unsubscribeAll)).subscribe(val => {
    if (isObservable(fn(val))) {
      fn(val)
        .pipe(take(1))
        .subscribe((data: any) => {
          dist?.patchValue(data);
        });
    } else {
      dist?.patchValue(fn(val));
    }
  });
}
export function paginationAdapterToList<T>(pagination: Observable<PaginationAdapter<T>>): Observable<T[]> {
  return pagination.pipe(map(data => data.results));
}

export function postFixer<T>(source: T[], dist: T[], primaryKey?: string): Differ<T>[] {
  const differs = differenceWith(dist, source, isEqual);
  const intersections = differenceWith(dist, differs);
  const res: Differ<T>[] = source.map(item => {
    const indexDist = findIndex(differs, diff => {
      if (primaryKey) {
        const sourceVal = get(item, primaryKey);
        const distVal = get(diff, primaryKey);
        return isEqual(sourceVal, distVal);
      }
      return isEqual(diff, item);
    });
    if (indexDist >= 0) {
      const itemDiff = differs[indexDist];
      differs.splice(indexDist, 1);
      return {
        item: itemDiff,
        type: 'updated',
      };
    }
    return intersections.some(val => isEqual(val, item))
      ? { item }
      : {
          item,
          type: 'deleted',
        };
  });
  const diffNew: Differ<T>[] = differs.map(item => ({
    item,
    type: 'new',
  }));
  return concat(res, diffNew);
}
// source: A     B      C      D
// dist  : A     C(u)   E      F
// differs = [C E F];

export interface FlatToTreeOption<T> {
  nodeIdFn: (item: T) => string | number;
  createParentIfUndefine: (parentId: any, keyParent?: any) => T;
  groupBys?: (keyof T)[];
}
export type TExtends<T> = T & { children: TExtends<T>[] };
export type PropertyName = string | number | symbol;

export function flatToTree<T>(arr: T[], option: FlatToTreeOption<T>): TExtends<T>[] {
  // const nodes: { [x in PropertyName]: TExtends<T> } = {};
  const groupBys = option.groupBys || [];
  // arr.forEach(obj => {
  //   const id = option.nodeIdFn(obj);

  //   nodes[id] = defaults(obj, nodes[id], { children: [] });

  //   function createNode(root: T, child: TExtends<T>, keys: (keyof T)[]): void {
  //     if (keys.length) {
  //       const curKey = keys[keys.length - 1];
  //       const nest = keys.slice(0, keys.length - 1);
  //       const keyParent = nest.length > 0 ? nest[nest.length - 1] : undefined;
  //       const parId = root[curKey];
  //       if (typeof parId === 'number' || typeof parId === 'string') {
  //         if (parId) {
  //           if (!nodes[parId]) {
  //             let objDefault = { children: [] };
  //             if (keyParent) {
  //               objDefault = { children: [], [keyParent]: root[keyParent] };
  //             }
  //             nodes[parId] = defaults(option.createParentIfUndefine(parId), objDefault);
  //           }
  //           if (!nodes[parId]['children'].some((c: T) => c === child)) {
  //             nodes[parId]['children'].push(child);
  //           }
  //           createNode(root, nodes[parId], nest);
  //         }
  //       }
  //     }
  //   }
  //   createNode(obj, nodes[id], groupBys);
  // });
  // const res = values<TExtends<T>>(nodes).filter(item => !item[groupBys[0]]);
  function buildFileTree(obj: { [index: string | number | symbol]: any }, level: number, parentId?: string | number): TExtends<T>[] {
    return Object.keys(obj).reduce<TExtends<T>[]>((accumulator, key) => {
      const value = obj[key];
      const id = parentId ? `${parentId}_${key}` : key;
      // const id =
      let node = defaults(option.createParentIfUndefine(id, key), { children: [] }) as TExtends<T>;
      // node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            node.children = value;
          } else {
            node.children = buildFileTree(value, level + 1, id);
          }
        } else {
          node = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
  const grouped = multiGroupBy(arr, cloneDeep(groupBys));
  console.log('grouped', grouped);
  const tree = buildFileTree(grouped, 0);
  // console.log(grouped);
  // console.log('tree -->', res, groupBy(arr, groupBys[0]));
  return tree;
}

/**
 * Transforms a string into a number (if needed).
 */
export function strToNumber(value: number | string): number {
  // Convert strings to numbers
  if (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value))) {
    return Number(value);
  }
  if (typeof value !== 'number') {
    throw new Error(`${value} is not a number`);
  }
  return value;
}

export function multiGroupBy(seq: any, keys: (string | number | symbol)[]): { [index: string]: any } {
  if (!keys.length) return seq;
  const first = keys[0];
  const rest = keys.slice(1);
  return mapValues(groupBy(seq, first), function (value) {
    return multiGroupBy(value, rest);
  });
}
