import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IProjectTransactionCobrokeReferral, IProjectTransactionCompanyInfoSummary, IProjectTransactionOverriding, IProjectTransactionPool } from '../interfaces';

import { ProjectTransactionOverridingModel } from './project-transaction-overriding.model';
import { ProjectTransactionPoolModel } from './project-transaction-pool.model';
import { ProjectTransactionCobrokeReferralModel } from './project-transaction-cobroke-referral.model';

export class ProjectTransactionCompanyInfoSummaryModel extends BaseModel implements IProjectTransactionCompanyInfoSummary {
  @Expose()
  companyTierProfit!: number;

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionOverriding) => ProjectTransactionOverridingModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionOverridings!: ProjectTransactionOverridingModel[];

  @Expose()
  @Default([])
  transactionPdManagements!: any[];

  @Expose()
  @Default([])
  transactionPdReferrals!: any[];

  @Expose()
  @Default([])
  transactionPds!: any[];

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionPool) => ProjectTransactionPoolModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionPools!: ProjectTransactionPoolModel[];

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionCobrokeReferral) => ProjectTransactionCobrokeReferralModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionReferrals!: ProjectTransactionCobrokeReferralModel[];
}
