import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IEcdd, IEcddOptionGroup } from '../interfaces';
import { EcddOptionModel } from './ecdd-option.model';

export class EcddOptionGroupModel extends BaseModel implements IEcddOptionGroup {
  @Expose()
  id!: number;

  @Expose()
  @Type(() => EcddOptionModel)
  choosenOption!: EcddOptionModel;

  @Expose()
  choosenOptionId!: number;

  @Expose()
  ecdd!: IEcdd;

  @Expose()
  ecddId!: number;

  @Expose()
  metadata!: unknown;

  @Expose()
  @Type(() => EcddOptionGroupModel)
  optionGroup!: EcddOptionGroupModel;

  @Expose()
  optionGroupId!: number;

  @Expose()
  remarks!: string;

  @Expose()
  sequence!: number;

  @Expose()
  @Type(() => EcddOptionModel)
  options!: EcddOptionModel[];

  @Expose()
  parentId!: number;

  @Expose()
  name!: string;

  @Expose()
  step!: string;

  @Expose()
  html!: string;

  @Expose()
  css!: string;

  @Expose()
  tags!: string;

  @Expose()
  @Default([])
  depends!: number[][];

  @Expose()
  appear!: number;

  @Expose()
  @Default([])
  @Type(() => EcddOptionGroupModel)
  children!: EcddOptionGroupModel[];
}
