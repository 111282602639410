import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { PreviewTaxInvoiceTemplateDto } from '@shared/data-access/dto';
import { IProfitCentres } from '@shared/data-access/interfaces';
import { LedgerPathModel, ProfitCentresModel } from '@shared/data-access/models';
import { plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfitCentresApiService {
  static ROOT_POINT = 'finance/profit-center';

  constructor(private apiService: ApiService) {}

  create(data: ProfitCentresModel): Observable<ProfitCentresModel> {
    return this.apiService
      .post(`${ProfitCentresApiService.ROOT_POINT}`, data)
      .pipe(map((res: IProfitCentres) => ProfitCentresModel.fromJson(res) as ProfitCentresModel));
  }

  get(itemId: number, query = {}): Observable<ProfitCentresModel> {
    return this.apiService.get(ProfitCentresApiService.ROOT_POINT + '/' + itemId, query).pipe(map(res => ProfitCentresModel.fromJson(res) as ProfitCentresModel));
  }

  update(id: number, data: any): Observable<any> {
    return this.apiService.patch(ProfitCentresApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProfitCentresModel>> {
    return this.apiService.get(ProfitCentresApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ProfitCentresModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProfitCentresApiService.ROOT_POINT + '/' + id, data, option);
  }

  getPath(id: number): Observable<LedgerPathModel[]> {
    return this.apiService.get(ProfitCentresApiService.ROOT_POINT + '/' + id + '/paths').pipe(
      map((res: LedgerPathModel[]) => {
        return plainToInstance(LedgerPathModel, res);
      })
    );
  }

  viewPdf(data: PreviewTaxInvoiceTemplateDto): Observable<any> {
    return this.apiService.post(ProfitCentresApiService.ROOT_POINT + '/template/preview', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }
}
