import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { assign, cloneDeep } from 'lodash-es';
import { USER_GROUPS_OPTIONS } from '../data';
import { EUserGroup } from '../enums';
import { IUserGroupAccessible, IUserRole } from '../interfaces';
import { UserGroupRecord } from '../types';
import { UpdatedByModel } from './updated-by.model';
import { UserGroupAccessibleModel } from './user-group.model';
import { getModuleGroupPermssion } from './user-permission.model';

export class UserRoleModel extends BaseModel implements IUserRole {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  description!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  // @Expose()
  // status!: string;

  // @Expose()
  // @Transform(({ obj }: { obj: IUserRole }) => {
  //   switch (obj.status) {
  //     case 'active':
  //       return {
  //         className: 'status-box-success',
  //         value: obj.status,
  //         displayName: 'active',
  //       };
  //     default:
  //       return {
  //         className: 'status-box-default',
  //         value: obj.status,
  //         displayName: obj.status,
  //       };
  //   }
  // })
  // statusObj!: { className: string; value: string; displayName: string };

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  @Default([])
  @Type(() => UserGroupAccessibleModel)
  accessible!: UserGroupAccessibleModel[];

  @Expose()
  @Transform(({ obj }: { obj: IUserRole }) => {
    const options = cloneDeep(USER_GROUPS_OPTIONS);
    const accessible = Object.values(
      (obj.accessible ?? []).reduce((acc: Record<string, IUserGroupAccessible>, item: IUserGroupAccessible) => {
        const moduleName = getModuleGroupPermssion(item.module);
        acc[moduleName] = assign(acc[moduleName] || item, { module: moduleName, total: (acc[moduleName]?.total || 0) + (item.total || 0) });
        return acc;
      }, {})
    );

    accessible.forEach(v => {
      if (!v.module || v.module === EUserGroup.PROJECT || v.module === EUserGroup.SETTINGS) return;
      options[v.module].accessible = v.total > 0;
      options[v.module].total = v.total ?? 0;
    });

    return options;
  })
  accessibleInfo!: UserGroupRecord;
}
