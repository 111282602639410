import { Inject, Injectable, InjectionToken, Optional, Provider, Type } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BaseModel } from '@red/data-access';
import { PageTitleAsyncData, PAGE_TITLE_ASYNC } from '@shared/features/page-title';
import { catchError, Observable, Subject, tap, throwError } from 'rxjs';
import { DynamicTitlePageFn, DYNAMIC_TITLE_PAGE_FN } from './title-page';

@Injectable()
export abstract class ApiServiceForDetailControllerResolver {
  abstract getById(id: number): Observable<unknown>;
}

export const DETAIL_CONTROLLER_SERVICE = new InjectionToken<ApiServiceForDetailControllerResolver>('DETAIL_CONTROLLER_SERVICE');
@Injectable()
export class DetailControllerResolver<T extends BaseModel> implements Resolve<T> {
  constructor(
    @Inject(DETAIL_CONTROLLER_SERVICE) private _apiService: ApiServiceForDetailControllerResolver,
    private _router: Router,
    @Inject(DYNAMIC_TITLE_PAGE_FN) @Optional() private _titlePageFn: DynamicTitlePageFn,
    @Inject(PAGE_TITLE_ASYNC) private page$: Subject<PageTitleAsyncData>
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let nextRoute: ActivatedRouteSnapshot | null = state.root;
    console.log('routerouteroute', route)
    while (nextRoute && !nextRoute.paramMap.has('id')) {
      nextRoute = nextRoute.firstChild;
    }
    if (!nextRoute) {
      return throwError(() => new Error(`can not find id`));
    }
    const id: number = parseInt(nextRoute.paramMap.get('id') as string);
    if (isNaN(id)) {
      return throwError(() => new Error(`can not find id`));
    }
    return this._apiService.getById(id).pipe(
      tap(item => {
        if (this._titlePageFn) {
          const title = this._titlePageFn(item);
          // console.log('{ id, title }', { id, item, title });
          this.page$.next({ id, title });
          // this._titleService.setTitle(title);
        }
      }),
      // Error here means the requested contact is not available
      catchError(error => {
        // Log the error
        console.error(error);

        // Get the parent url
        const parentUrl = state.url.split('/').slice(0, -2).join('/');

        // Navigate to there
        this._router.navigateByUrl(parentUrl);

        // Throw an error
        return throwError(() => error);
      })
    );
  }
}

export function getProvidersForDetailController(service: Type<ApiServiceForDetailControllerResolver>, titleFn?: DynamicTitlePageFn): Provider[] {
  return [
    DetailControllerResolver,
    {
      provide: DETAIL_CONTROLLER_SERVICE,
      useClass: service,
    },
    {
      provide: DYNAMIC_TITLE_PAGE_FN,
      useValue: titleFn,
    },
  ];
}
