import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedIconService } from './icon.service';
import { RED_ICON } from './icon.token';
import { IRedIcon } from './icon.interface';
import { RED_ICON_DATA } from './icon.data';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class RedIconModule {
  constructor(
    @Optional() @SkipSelf() parentModule: RedIconModule,
    private iconService:RedIconService) {
    if (parentModule) {
        throw new Error('RedIconService is already loaded. Import it in the AppModule only!');
    }
}
static forRoot(icons:IRedIcon[]=[]): ModuleWithProviders<RedIconModule> {
  icons = [...RED_ICON_DATA,...icons];
    return {
        ngModule: RedIconModule,
        providers: [
            {
                provide: RED_ICON,
                useValue: icons
            },
            RedIconService
        ]
    };
}
}
