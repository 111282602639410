export interface IBatchingApprovalHistory {
  id: 4;
  actualComm: number;
  batchId: number;
  businessUnitId: number;
  createdAt: string;
  createdBy: number;
  deletedAt: string;
  deletedBy: number;
  itemCodes: string[];
  rate: number;
  totalComm: number;
  updatedAt: string;
  updatedBy: number;
}
