import { ECreditNoteDetailStatus, ECreditNoteStatus } from '../enums';
import { StatusRecord } from '../types';

export const CREDIT_NOTE_STATUSES = [
  {
    id: ECreditNoteStatus.CANCELLED,
    name: 'Cancelled',
  },
  {
    id: ECreditNoteStatus.ABORTED,
    name: 'Aborted',
  },
  {
    id: ECreditNoteStatus.PARTIAL,
    // name: 'Partial Credit Note',
    name: 'Cancelled Partial',
  },
];

export const CREDIT_NOTE_PAID_STATUS: StatusRecord<ECreditNoteDetailStatus> = {
  [ECreditNoteDetailStatus.View]: {
    id: ECreditNoteDetailStatus.View,
    name: 'View',
    detailName: 'View',
    class: 'status-box-default',
    detailClass: 'status-box-default',
  },
  [ECreditNoteDetailStatus.UnPaid]: {
    id: ECreditNoteDetailStatus.UnPaid,
    name: 'Un-Paid',
    detailName: 'Un-Paid',
    class: 'status-box-default',
    detailClass: 'status-box-default',
  },
  [ECreditNoteDetailStatus.PartPaid]: {
    id: ECreditNoteDetailStatus.PartPaid,
    name: 'Part Paid',
    detailName: 'Part Paid',
    class: 'status-box-warning',
    detailClass: 'status-box-warning',
  },
  [ECreditNoteDetailStatus.FullyPaid]: {
    id: ECreditNoteDetailStatus.FullyPaid,
    name: 'Fully Paid',
    detailName: 'Fully Paid',
    class: 'status-box-success',
    detailClass: 'status-box-success',
  },
  [ECreditNoteDetailStatus.Cancelled]: {
    id: ECreditNoteDetailStatus.Cancelled,
    name: 'Cancelled',
    detailName: 'Void',
    class: 'status-box-smokey-topaz',
    detailClass: 'status-box-smokey-topaz',
  },
  [ECreditNoteDetailStatus.Void]: {
    id: ECreditNoteDetailStatus.Void,
    name: 'Void',
    class: 'status-box-smokey-topaz',
  },
  // [ECreditNoteDetailStatus.Confirmed]: {
  //   id: ECreditNoteDetailStatus.Confirmed,
  //   name: 'Confirmed',
  //   filterName: 'Confirmed',
  //   class: 'status-box-success',
  // },
  // [ECreditNoteDetailStatus.Withhold]: {
  //   id: ECreditNoteDetailStatus.Withhold,
  //   name: 'Withhold',
  //   filterName: 'Withhold',
  //   class: 'status-box-electric-orange',
  // },
  [ECreditNoteDetailStatus.PaymentInProgress]: {
    id: ECreditNoteDetailStatus.PaymentInProgress,
    name: 'Payment In Progress',
    detailName: 'Payment In Progress',
    class: 'status-box-warning',
    detailClass: 'status-box-warning',
  },
};
