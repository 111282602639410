import { EEcddIdentityType, EEcddStatus, EEcddTransactionType } from '../enums';
import { OptionDescription } from '../types';
import { StatusRecord } from '../types/status.type';

export const ECDD_STATUSES: StatusRecord<EEcddStatus> = {
  [EEcddStatus.draft]: {
    id: EEcddStatus.draft,
    name: 'Draft',
    filterName: 'Draft',
    showOnFilter: true,
    class: 'status-box-default',
    canSubmit: true,
    canEdit: true,
    canDelete: true,
  },
  [EEcddStatus.clientSigned]: {
    id: EEcddStatus.clientSigned,
    name: 'Client Signed',
    filterName: 'Client Signed',
    showOnFilter: false,
    class: 'status-box-purple',
    canEdit: true,
    canDelete: false,
  },
  [EEcddStatus.submitted]: {
    id: EEcddStatus.submitted,
    name: 'Pending Approval',
    filterName: 'Pending Approval',
    showOnFilter: true,
    class: 'status-box-warning',
    canEdit: false,
    canDelete: false,
  },
  [EEcddStatus.approved]: {
    id: EEcddStatus.approved,
    name: 'Manually Approved',
    filterName: 'Manually Approved',
    showOnFilter: true,
    class: 'status-box-success',
    canEdit: false,
    canDelete: false,
  },
  [EEcddStatus.autoApproved]: {
    id: EEcddStatus.autoApproved,
    name: 'Approved',
    filterName: 'Approved',
    showOnFilter: true,
    class: 'status-box-success',
    canEdit: false,
    canDelete: false,
  },
  [EEcddStatus.rejected]: {
    id: EEcddStatus.rejected,
    name: 'Rejected',
    filterName: 'Rejected',
    showOnFilter: true,
    class: 'status-box-danger',
    canEdit: false,
    canDelete: false,
  },
};

export const ECDD_TRANSACTION_TYPE_OPTIONS: Record<EEcddTransactionType, OptionDescription<EEcddTransactionType>> = {
  [EEcddTransactionType.Rental]: {
    id: EEcddTransactionType.Rental,
    value: EEcddTransactionType.Rental,
    viewValue: 'Rental',
  },
  [EEcddTransactionType.Sales]: {
    id: EEcddTransactionType.Sales,
    value: EEcddTransactionType.Sales,
    viewValue: 'Sale & Purchase',
  },
};

export const ECDD_IDENTIFY_TYPE_OPTIONS: Record<EEcddIdentityType, OptionDescription<EEcddIdentityType>> = {
  [EEcddIdentityType.NRIC]: {
    id: EEcddIdentityType.NRIC,
    value: EEcddIdentityType.NRIC,
    viewValue: 'NRIC',
  },
  [EEcddIdentityType.Passport]: {
    id: EEcddIdentityType.Passport,
    value: EEcddIdentityType.Passport,
    viewValue: 'Passport',
  },
  [EEcddIdentityType.WorkPass]: {
    id: EEcddIdentityType.WorkPass,
    value: EEcddIdentityType.WorkPass,
    viewValue: 'Work Pass',
  },
  [EEcddIdentityType.Others]: {
    id: EEcddIdentityType.Others,
    value: EEcddIdentityType.Others,
    viewValue: 'Others',
  },
};
