import { Expose, Type } from 'class-transformer';
import { ECommType, EProjectType } from '../enums';
import { CommissionMatrixModel } from './commission-matrix.model';
import { ProjectModel } from './project.model';

export class ProjectCommissionMatrixModel extends CommissionMatrixModel {
  @Expose()
  projectId!: number;

  @Expose()
  @Type(() => ProjectModel)
  project!: ProjectModel;

  @Expose()
  projectStage!: string;

  @Expose()
  startDate!: string;

  @Expose()
  endDate!: string;

  @Expose()
  startDateMarketing!: string;

  @Expose()
  endDateMarketing!: string;
}
