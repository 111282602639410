import { SplitMatrixModel } from './split-matrix.model';
import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { PersonalParticularModel } from './personal-particular.model';
import { AppointmentTypeModel } from './appointment-type.model';
import { IProjectTransactionOverriding } from '../interfaces';
import { SplitPartModel } from './split-part.model';
import { CategoryModel } from './category.model';
import { EAgentSession } from '../enums';

export class ProjectTransactionOverridingModel extends BaseModel implements IProjectTransactionOverriding {
  @Expose()
  id!: number;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  appTypeCode!: string;

  @Expose()
  agentType!: EAgentSession;

  @Expose()
  grouping!: string;

  @Expose()
  @Type(() => AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  @Transform(({ obj, value }) => {
    if (!value || !value?.id) {
      return obj?.tiers?.[0]?.salesperson;
    }
    return value;
  })
  salesperson!: PersonalParticularModel;

  @Expose()
  @Transform(({ value }) => {
    if (!value || value.length === 0) {
      return [
        {
          name: 'Recruiter',
          level: 4,
        },
      ];
    }
    return value;
  })
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Transform(({ value }) => {
    if (!value || value.length === 0) {
      return [
        {
          name: 'Commission',
          level: 1,
        },
        {
          name: 'Tier 1',
          level: 2,
        },
        {
          name: 'Tier 2',
          level: 3,
        },
      ];
    }
    return value;
  })
  @Type(() => SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  @Type(() => SplitMatrixModel)
  splitMatrix!: SplitMatrixModel;

  @Expose()
  receivedDirectPercent!: number;

  @Expose()
  receivedPercent!: number;

  @Expose()
  receivedValue!: number;

  @Expose({ groups: ['summary'] })
  @Transform(({ obj }) => {
    return obj?.receivedValue - obj?.totalAmount;
  })
  companyAmount!: number;

  @Expose()
  transactedPercent!: number;

  @Expose()
  transactedValue!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;
}
