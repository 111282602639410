import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { LoadingBoxModule } from '@shared/components/loading-box';

import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetabaseQuestionComponent } from './metabase-question.component';
import { MetabaseQuestionRoutingModule } from './metabase-question-routing.module';
import { MetabaseTableModule } from '../metabase-table/metabase-table.module';
import { MatButtonModule } from '@angular/material/button';
import { MetabaseParameterModule } from '../metabase-parameter/metabase-parameter.module';
import { MetabaseParameterService } from '../metabase-parameter/metabase-parameter.service';
const components = [MetabaseTableModule, LoadingBoxModule, MatButtonModule, MatIconModule, MatTooltipModule];
@NgModule({
  imports: [CommonModule, MetabaseQuestionRoutingModule, MetabaseParameterModule, MetabaseParameterModule, ...components],
  declarations: [MetabaseQuestionComponent],
  providers: [],
})
export class MetabaseQuestionModule {}
