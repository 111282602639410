import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { ICommLeadershipSchemeCreateDto, ICommLeadershipSchemeUpdateDto } from '../interfaces';

export class RequiredCommSchemeDto extends BaseModel {
  @Expose()
  commissionSchemeId!: number;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;
}

export class RequiredTeamMemberDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  commissionSchemeId!: number;

  @Expose()
  @Type(() => Number)
  headcount!: number;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;
}

export class grossCommissionEarnedDto extends BaseModel {
  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => Number)
  commissionReceivedFrom!: number;

  @Expose()
  @Type(() => Number)
  commissionReceivedTo!: number;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  fixedEvaluationPeriod!: boolean;

  @Expose()
  @Type(() => Number)
  accumulatedPeriod!: number;

  @Expose()
  @Type(() => Number)
  resetAccumulatedPeriod!: number;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  excludeOwnTransaction!: boolean;
}

export class CommLeadershipSchemeCreateDto extends BaseModel implements ICommLeadershipSchemeCreateDto {
  @Expose()
  @Type(() => Number)
  schemeTypeId!: number;

  @Expose()
  designation!: string;

  @Expose()
  code!: string;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  autoApprove!: boolean;

  @Expose()
  @Type(() => Number)
  sequence!: number;

  @Expose()
  @Type(() => Number)
  directHeadcount!: number;

  @Expose()
  @Type(() => Number)
  totalHeadcount!: number;

  @Expose()
  @Type(() => RequiredCommSchemeDto)
  requiredCommissionScheme!: RequiredCommSchemeDto[];

  @Expose()
  @Type(() => RequiredTeamMemberDto)
  requiredTeamMember!: RequiredTeamMemberDto[];

  @Expose()
  @Type(() => grossCommissionEarnedDto)
  grossCommissionEarned!: grossCommissionEarnedDto;
}

export class CommLeadershipSchemeUpdateDto extends BaseModel implements ICommLeadershipSchemeUpdateDto {
  @Expose()
  @Type(() => Number)
  schemeTypeId!: number;

  @Expose()
  designation!: string;

  @Expose()
  code!: string;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  autoApprove!: boolean;

  @Expose()
  @Type(() => Number)
  sequence!: number;

  @Expose()
  @Type(() => Number)
  directHeadcount!: number;

  @Expose()
  @Type(() => Number)
  totalHeadcount!: number;

  @Expose()
  @Type(() => RequiredCommSchemeDto)
  requiredCommissionScheme!: RequiredCommSchemeDto[];

  @Expose()
  @Type(() => RequiredTeamMemberDto)
  requiredTeamMember!: RequiredTeamMemberDto[];

  @Expose()
  @Type(() => grossCommissionEarnedDto)
  grossCommissionEarned!: grossCommissionEarnedDto;
}
