import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { ISchemeType } from '../interfaces';


export class SchemeTypeModel extends BaseModel implements ISchemeType {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  status!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  description!: string;
}
