export enum EGceAmountStatus {
  recorded = 'recorded',
  approved = 'approved',
}

export enum EPaymentType {
  manualFee = 'manualFee',
  transaction = 'transaction',
  blankForm = 'blankForm',
  resale = 'resale',
  resaleAdj = 'resaleAdj',
  blankFormAdj = 'blankFormAdj',
  bankReferralFee = 'bankReferralFee',
  creditNote = 'creditNote',
}