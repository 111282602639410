import { AdditionalInformationModel } from '@shared/data-access/models';
import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { map, Observable } from 'rxjs';
import { IAdditionalInformation, IPersonalParticular } from '@shared/data-access/interfaces';
import { AdditionalInformationDto } from '@shared/data-access/dto';

@Injectable({
  providedIn: 'root',
})
export class SalespersonAdditionalInformationApiService {
  static ADDITIONAL_INFORMATION = 'hrm/additional-informations';

  constructor(private apiService: ApiService) {}

  create(salespersonId: number, data: AdditionalInformationDto): Observable<AdditionalInformationModel> {
    return this.apiService
      .post(`${SalespersonAdditionalInformationApiService.ADDITIONAL_INFORMATION}/${salespersonId}`, data)
      .pipe(map((res: IPersonalParticular) => AdditionalInformationModel.fromJson(res) as AdditionalInformationModel));
  }

  update(id: number, data: AdditionalInformationDto): Observable<any> {
    return this.apiService.patch(SalespersonAdditionalInformationApiService.ADDITIONAL_INFORMATION + '/' + id, data, { excludeFields: [''] });
  }

  getAll(salespersonId: number, query = {}, option?: IApiOption): Observable<AdditionalInformationModel[]> {
    // return of(fakeData.map(item =>  AdditionalInformationModel.fromJson(item)));
    return this.apiService
      .get(`${SalespersonAdditionalInformationApiService.ADDITIONAL_INFORMATION}/${salespersonId}`, query, option)
      .pipe(map((data: IAdditionalInformation[]) => data.map(item => AdditionalInformationModel.fromJson(item))));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(SalespersonAdditionalInformationApiService.ADDITIONAL_INFORMATION + '/' + id, {}, option);
  }
}

const fakeData: IAdditionalInformation[] = [
  {
    id: 1,
    typeId: 1,
    effectiveDate: '2022-03-08T04:12:50.000Z',
    remarks: 'abc',
    createdAt: '2022-03-08T04:12:50.000Z',
    updatedAt: '2022-03-08T04:12:50.000Z',
  },
];
