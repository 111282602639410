import { TransactedTypeEnum } from '../enums';

export const PROJECT_TRANSACTION_TYPES = [
  {
    id: TransactedTypeEnum.cashAdvance,
    name: 'Cash Advance',
  },
  {
    id: TransactedTypeEnum.discount,
    name: 'Discount',
  },
  // {
  //   id: TransactedTypeEnum.powerDiscount,
  //   name: 'Power Discount',
  // },
  {
    id: TransactedTypeEnum.incentive,
    name: 'Incentive',
  },
  {
    id: TransactedTypeEnum.buyerRebate,
    name: 'Buyer Rebate',
  },
] as const;

export const PROJECT_TRANSACTION_DISCOUNT_ABSORDS = [
  {
    id: 'companyNett',
    name: 'Company Nett',
  },
  {
    id: 'agent',
    name: 'Agent',
  },
] as const;
