import { EReceiptStatus } from '../enums';
import { StatusRecord } from '../types/status.type';

export const RECEIPT_TYPES = [
  {
    id: 'OneTimeReceipt',
    name: 'One Time Receipt',
  },
  {
    id: 'CustomerReceipt',
    name: 'Customer Receipt',
  },
  {
    id: 'SupplierRefund',
    name: 'Supplier Refund',
  },
] as const;

export const RECEIPT_MODES = [
  {
    id: 'ChequeReceipt',
    name: 'Cheque Receipt',
  },
  {
    id: 'BankDirectDebit',
    name: 'Bank Transfer',
  },
  {
    id: 'NETS',
    name: 'NETS',
  },
  {
    id: 'CashReceipt',
    name: 'Cash Receipt',
  },
] as const;

export const RECEIPT_PRESET_TYPE = [
  {
    id: 'tax-invoice',
    name: 'TAX INVOICE',
  },
  {
    id: 'debit-note',
    name: 'DEBIT NOTE',
  },
] as const;

export const RECEIPT_OFFSET_TYPES = [
  {
    id: 'PR',
    name: 'Payment Reversal',
  },
] as const;

export const RECEIPT_STATUS: StatusRecord<EReceiptStatus> = {
  [EReceiptStatus.confirmed]: {
    id: EReceiptStatus.confirmed,
    name: 'Active',
    detailName: 'View',
    filterName: 'Active',
    class: 'status-box-success',
    detailClass: 'status-box-default',
  },
  [EReceiptStatus.reversed]: {
    id: EReceiptStatus.reversed,
    name: 'Reversed',
    detailName: 'View',
    filterName: 'Reversed',
    class: 'status-box-default',
    detailClass: 'status-box-default',
  },
  [EReceiptStatus.cancelled]: {
    id: EReceiptStatus.cancelled,
    name: 'Cancelled',
    detailName: 'Void',
    filterName: 'Cancelled',
    class: 'status-box-smokey-topaz',
    detailClass: 'status-box-smokey-topaz',
  },
};
