import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport, FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY, ViewportRuler } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Inject, NgZone, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, animationFrameScheduler, asapScheduler, auditTime, startWith, takeUntil } from 'rxjs';
const SCROLL_SCHEDULER = typeof requestAnimationFrame !== 'undefined' ? animationFrameScheduler : asapScheduler;
export class ExceljsVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(42, 128, 208);
  }
}
@Directive({
  selector: 'cdk-virtual-scroll-viewport[redExceljsVirtualScrollController]',
  standalone: true,
  exportAs: 'exceljsVirtualScrollController',
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: ExceljsVirtualScrollStrategy }, CdkFixedSizeVirtualScroll],
})
export class ExceljsVirtualScrollControllerDirective implements AfterViewInit, OnDestroy {
  @Output() srollToEnd = new EventEmitter<{ range: { start: number; end: number }; dataLength: number }>();
  @Output() topHeader = 0;
  // @Input() searchWith!: (filters: IQuerySearch) => Observable<any[] | PaginationAdapter<any>>;
  // @HostBinding('style.height.px') height = this._fixedSize._itemSize * 4;
  results = new BehaviorSubject<any[]>([]);
  length = 0;
  total = 0;
  page = 1;
  loading!: boolean;
  waitToFetch!: Subscription;
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  // searcher:
  constructor(
    private _cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    viewportRuler: ViewportRuler,
    @Inject(VIRTUAL_SCROLL_STRATEGY) private _fixedSize: CdkFixedSizeVirtualScroll,
    @Inject(CdkVirtualScrollViewport) private _scrollViewport: CdkVirtualScrollViewport
  ) {
    // this._scrollViewport
    // console.log('_fixedSize', this._fixedSize);
    // this._scrollViewport.scrollable.elementScrolled();
  }
  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() =>
      Promise.resolve().then(() => {
        this._scrollViewport.scrollable
          .elementScrolled()
          .pipe(
            // Start off with a fake scroll event so we properly detect our initial position.
            startWith(null),
            // Collect multiple events into one until the next animation frame. This way if
            // there are multiple scroll events in the same frame we only need to recheck
            // our layout once.
            auditTime(0, SCROLL_SCHEDULER),
            takeUntil(this._onDestroy)
          )
          .subscribe(() => {
            this.onContentScrol();
          });
      })
    );
  }
  onContentScrol(): void {
    const renderedRange = this._scrollViewport.getRenderedRange();
    const newRange = { start: renderedRange.start, end: renderedRange.end };
    const viewportSize = this._scrollViewport.getViewportSize();
    const dataLength = this._scrollViewport.getDataLength();
    const scrollOffset = this._scrollViewport.measureScrollOffset();
    const results = this.results.getValue();
    // Prevent NaN as result when dividing by zero.
    const firstVisibleIndex = this._fixedSize._itemSize > 0 ? scrollOffset / this._fixedSize._itemSize : 0;
    // console.log('onContentScrol --> ',this._fixedSize._itemSize, newRange, results, dataLength,scrollOffset, firstVisibleIndex);
    // If user scrolls to the bottom of the list and data changes to a smaller list

    if (newRange.end >= dataLength) {
      this.ngZone.run(() => {
        this.srollToEnd.emit({ range: newRange, dataLength });
      });
    }
    this.topHeader = (newRange ? newRange?.start : 0) * (this._fixedSize._itemSize ? - (this._fixedSize._itemSize) : 0)
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.srollToEnd.complete();
  }

  triggerSearch(): void {
    this.loading = true;
    if (this.waitToFetch && !this.waitToFetch.closed) {
      this.waitToFetch.unsubscribe();
    }
    // const filters = { key: this.searchCtrl.getRawValue(), limit: this.limit, page: this.page };
    // this.waitToFetch = this.searchWith(filters).subscribe(data => {
    //   const newItems = Array.isArray(data) ? data : data.results;
    //   const list = concat(this.results.getValue(), newItems);
    //   this.length = list.length;
    //   this.total = Array.isArray(data) ? this.length : data.pagination.total;
    //   this.results.next(list);
    //   // this._scrollViewport.scrollToIndex(0);
    //   this.loading = false;
    // });
  }
}
