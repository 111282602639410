import { takeUntil } from 'rxjs/operators';
import { race, zip, Subject, zipWith } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MetabaseCard, MetabaseCardQueryModel } from './../models/metabase.model';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { getColumnSetting, normalizeTemplateRefName, normalizeValue } from '../metabase.util';
import { MetabaseColumnDef } from '../metabase-table.util';

@Component({
  selector: 'red-metabase-table',
  templateUrl: './metabase-table.component.html',
  styleUrls: ['./metabase-table.component.scss'],
})
export class MetabaseTableComponent implements OnDestroy {
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  @Input()
  get columns(): MetabaseColumnDef[] {
    return this._cols;
  }
  set columns(val: MetabaseColumnDef[]) {
    this._cols = val;
    this._initializer();
  }
  _cols: MetabaseColumnDef[] = [];
  displayColums: string[] = [];
  @Input()
  dataSource: Array<Record<string, string | number | null>> = [];

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  _initializer(): void {
    this.displayColums = this.columns
      .filter((col, index) => {
        // console.log('index -->', index, col);

        return col.enabled;
      })
      .map(col => col.columnDef);
  }
}
