import { Component, Input } from '@angular/core';

@Component({
  selector: 'red-loading-box',
  templateUrl: './loading-box.component.html',
  styleUrls: ['./loading-box.component.scss'],
})
export class LoadingBoxComponent {
  @Input() spinnerSize: 'tiny' | 'medium' | 'large' = 'medium';
  size = {
    tiny: 20,
    medium: 50,
    large: 100,
  };
}
