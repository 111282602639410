import { BaseModel, Default, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EProjectType } from '../enums';
import { DistributionMatrixDto, OverridingDistributionMatrixDto } from './project-commission-matrix.dto';

export class ProjectCommissionMatrixHICreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  projectId!: number;

  @Expose()
  projectStage!: string;

  @Expose()
  @Type(() => Number)
  commissionTypeId!: number;

  @Expose()
  name!: string;

  @Expose()
  type!: EProjectType;

  @Expose()
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => Boolean)
  isSpecial!: boolean;

  @Expose()
  unitNo!: string;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;

  @Expose()
  @Type(() => Number)
  developerTier!: number;

  @Expose()
  @Type(() => Number)
  agentTier!: number;

  @Expose()
  @Type(() => Number)
  companyTier!: number;

  @Expose()
  remarks!: string;

  @Expose()
  @Type(() => OverridingDistributionMatrixDto)
  headHi!: OverridingDistributionMatrixDto;

  @Expose()
  @Type(() => OverridingDistributionMatrixDto)
  bd!: OverridingDistributionMatrixDto;

  @Expose()
  @Type(() => DistributionMatrixDto)
  hiProfit!: DistributionMatrixDto;
}

export class ProjectCommissionMatrixHIDataUpdateDto extends BaseModel {
  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  projectId!: number;

  @Expose({ groups: ['full', 'general'] })
  projectStage!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  commissionTypeId!: number;

  @Expose({ groups: ['full', 'general'] })
  name!: string;

  @Expose({ groups: ['full', 'general'] })
  type!: EProjectType;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Boolean)
  isSpecial!: boolean;

  @Expose({ groups: ['full', 'general'] })
  unitNo!: string;

  @Expose({ groups: ['full', 'general'] })
  description!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  developerTier!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  agentTier!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  companyTier!: number;

  @Expose({ groups: ['full', 'general', 'remark'] })
  remarks!: string;

  @Expose({ groups: ['full', 'bd'] })
  @Type(() => OverridingDistributionMatrixDto)
  headHi!: OverridingDistributionMatrixDto;

  @Expose({ groups: ['full', 'bd'] })
  @Type(() => OverridingDistributionMatrixDto)
  bd!: OverridingDistributionMatrixDto;

  @Expose({ groups: ['full', 'bd'] })
  @Type(() => DistributionMatrixDto)
  hiProfit!: DistributionMatrixDto;
}

export class ProjectCommissionMatrixHIUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'general' | 'agent' | 'company' | 'isActive' | 'full' | 'remark';

  @Expose()
  @Transform(params => {
    return ProjectCommissionMatrixHIDataUpdateDto.fromJson(params.value, { groups: [params.obj.group] });
  })
  data!: ProjectCommissionMatrixHIDataUpdateDto;
}
