import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { PersonalVerifyDto } from '@shared/data-access/dto';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { EPersonalVerifyType } from '@shared/data-access/enums';
import { PersonalParticularModel, PersonalVerifyModel } from '@shared/data-access/models';

@Injectable({
  providedIn: 'root',
})
export class BankVerificationInfoApiService {
  static ROOT_POINT = 'hrm/salespersons-verify/bank';

  constructor(private _apiService: ApiService) { }


  get(itemId: number): Observable<PersonalVerifyModel[]> {
    const query: IQuerySearch = { limit: 1000, status: 'submitted' };
    return this._apiService
      .get([BankVerificationInfoApiService.ROOT_POINT, itemId].join('/'), query)
      .pipe(map(res => res.items.map((item: unknown) => PersonalVerifyModel.fromJson(item) as PersonalVerifyModel)));
  }

  update(id: number, data: PersonalVerifyDto[]): Observable<unknown> {
    return this._apiService.post([BankVerificationInfoApiService.ROOT_POINT, id, 'action'].join('/'), { data }, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this._apiService
      .get(BankVerificationInfoApiService.ROOT_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(PersonalParticularModel, data)));
  }
}
