import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { ITaxInvoice } from './tax-invoice-enhanced.interface';

export interface IInvoiceWriteoff {
  id?: number;
  businessUnitId: number;
  taxInvoiceId?: number;
  debitAccountId?: number;
  debitAccount?: ILedgerAccount;
  code?: string;
  writeOffDate: Date;
  total?: number;
  updatedBy: {
    id: number;
    name: string;
  };
  updatedAt: string;

  contact?: IContactCustomerAndSupplier;
  contactName?: string;
  invoice?: ITaxInvoice;
  invoiceDate?: Date;
  invoiceNumber?: string;
  invoiceCurrency?: string;
  inClosedPeriod?: boolean;
}

export interface IInvoiceWriteoffRecord {
  id?: number;
  sourceId?: number;
  type: string;
  description: string;
  credit: number;
  debit: number;
  account: ILedgerAccount;
  accountId?: number;
}

export interface IInvoiceWriteoffPosting {
  id?: number;
  accountId?: number;
  account: ILedgerAccount;
  description: string;
  credit: number;
  debit: number;
  amount: number;
}

export interface IInvoiceWriteoffData {
  amount: number;
  details: IInvoiceWriteoffRecord[];
  postings: IInvoiceWriteoffPosting[];
}
