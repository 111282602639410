import { UpdatedByModel } from './updated-by.model';
import { PersonalParticularModel } from './personal-particular.model';
import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IKeyAppointment } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';
import { ProjectModel } from './project.model';

export class KeyAppointmentModel extends BaseModel implements IKeyAppointment {
  @Expose()
  id!: number;

  @Expose()
  projectId!: number;

  @Expose()
  @Type(() => ProjectModel)
  project!: ProjectModel;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  isMainProjectIC!: boolean;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  @Type(() => AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;

  @Expose()
  startDate!: string;

  @Expose()
  endDate!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  remarks?: string;

  // @Expose()
  // @Default(false)
  // isPersonInCharge!: boolean;

  //#region getters
  get projectInChargeName(): string | null {
    const salesperson = this.salesperson;

    if (!salesperson) return null;
    if (salesperson.ceaRegNo) return `${salesperson.businessName} (${salesperson.ceaRegNo})`;

    return salesperson.businessName;
  }
  //#endregion
  @Expose()
  appTypes!: { appType: AppointmentTypeModel }[];

  @Expose()
  appointmentTypes!: AppointmentTypeModel[];
}
