import { Pipe, PipeTransform } from '@angular/core';
type ValueFn = (item:any)=>boolean
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], field: string | string[], value: string | ValueFn): any[] {
    if (!items) {
      return [];
    }
    if (!value || value.length === 0) {
      return items;
    }
    if(typeof value === 'function'){
      return items.filter(it => value(it));
    }
    if (typeof field === 'string') {
      return items.filter(it => it[field].toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      return items.filter(it => field.some(key => it[key].toLowerCase().indexOf(value.toLowerCase()) !== -1));
    }
  }
}
