import { CitizenRecord } from '../types';
import { ECitizen } from '../enums';

export const CITIZEN_OPTIONS: CitizenRecord = {
  [ECitizen.SingaporeCitizen]: {
    id: ECitizen.SingaporeCitizen,
    value: ECitizen.SingaporeCitizen,
    viewValue: 'Singapore Citizen (SC)',
  },
  [ECitizen.SingaporePr]: {
    id: ECitizen.SingaporePr,
    value: ECitizen.SingaporePr,
    viewValue: 'Singapore PR',
  },
  [ECitizen.Foreigner]: {
    id: ECitizen.Foreigner,
    value: ECitizen.Foreigner,
    viewValue: 'Foreigner',
  },
};
