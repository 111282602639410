import { AfterContentInit, Directive, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject, take, takeUntil, tap } from 'rxjs';
import { ExceljsCard, ExceljsParameter, IExceljsParameterControlType } from '../models';
import { ExceljsParameterService } from './exceljs-parameter.service';

@Directive()
export abstract class ExceljsParameterFieldBase implements OnInit, AfterContentInit, OnDestroy {
  @Input()
  public get data(): Partial<ExceljsParameter> {
    return this._data;
  }
  public set data(value: Partial<ExceljsParameter>) {
    this._data = value;
    if (value) {
      this.dataChange$.next(value);
    }
  }
  protected _data!: Partial<ExceljsParameter>;

  @Input() card!: ExceljsCard;

  dataChange$ = new Subject<Partial<ExceljsParameter>>();
  dataUpcast = new FormControl<unknown>('', []);
  dataDowncast = new FormControl<unknown>('', []);

  abstract get controlType(): IExceljsParameterControlType;
  protected _parameterService = inject(ExceljsParameterService);
  protected _unsubscribeAll: Subject<void> = new Subject<void>();

  ngAfterContentInit(): void {
    const slug = this.data.slug;
    if (!slug) {
      throw new Error('slug can not null');
    }
    this._parameterService.select({ values: { key: slug, value: this.dataUpcast.getRawValue() } });
    this._parameterService.register({ key: slug, formCtr: this.dataDowncast });
    this.dataUpcast.valueChanges
      .pipe(
        debounceTime(300),
        tap(val => {
          this.onValueChange(val);
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
    this.dataChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      const slug = this.data.slug;
      if (slug) {
        this._parameterService.register({ key: slug, formCtr: this.dataDowncast });
        const dataDowncast = this.toDataUpcastValue(this.data, this.data.value);
        this.dataUpcast.setValue(dataDowncast, { emitEvent: false });
        this._parameterService.select({ values: { key: slug, value: this.dataUpcast.getRawValue() } });
      }
    });
  }

  ngOnInit(): void {
    this.init();
    // this.dataUpcast.valueChanges
    // .pipe(
    //   tap(value => {
    //     this._parameterService.select({ values: { key: this.data.slug!, value } });
    //   }),
    //   take(1)
    // )
    // .subscribe();
    // this._parameterService.register({ key: this.data.slug!, formCtr: this.dataDowncast });
  }

  onValueChange(val: unknown): void {
    console.log('onValueChange --> ', this.data.slug);
    this.dataDowncast?.setValue(this.toDataDowncastValue(this.data, val));
  }

  abstract toDataDowncastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown;
  abstract toDataUpcastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown;

  init(): void {
    const dataDowncast = this.toDataUpcastValue(this.data, this.data.value);
    this.dataUpcast.setValue(dataDowncast);
    // this.dataDowncast = new FormControl('', []);
    console.log('init --> ', this.data.slug);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
