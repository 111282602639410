export interface IIdType {
  id:number;
  code:string;
  identification:string;
  iras:string;
  status:string;
  isActive:boolean;
  createdAt:string;
  updatedAt:string;
}
