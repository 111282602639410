import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IPermission, IPermissionConfig } from './../interfaces/permission.interface';
export class Permission extends BaseModel implements IPermission {
  @Expose()
  id!: number;

  @Expose()
  module!: string;

  @Expose()
  feature!: string;

  @Expose()
  scope!: string;

  @Expose()
  slug!: string;

  @Expose()
  description!: string;
}

export class PermissionConfig extends BaseModel implements IPermissionConfig {
  @Expose()
  prefix?: string[];

  @Expose()
  redirectCommand?: string[];
}
