import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { ClassProvider, InjectionToken, ModuleWithProviders, NgModule, Provider, Type, ValueProvider } from '@angular/core';
import { AuthInterceptor } from './auth.interceptor';
import { AuthProvider, OauthAuthProvider } from './auth.service';
import { AUTH_COOKIE_READER, FORCED_LOGOUT_FN, LOGIN_URL, LOGOUT_URL } from './auth.token';
import { TokenAuthGuard, TOKEN_AUTH_NAME, SSOAuthGuard } from './guards';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/noAuth.guard';
import { AuthSSRProvider } from './auth-ssr.service';
import { CookieReaderService } from './cookie.service';
import { SSONoAuthGuard } from './guards/ssoNoAuth.guard';
import { IRedocOauth2Config, RedocOAuthLogger, RedocOAuthStorage } from '@redocco/oauth2-redoc-connect';
import { OauthService, OAUTH2_CONFIG, createDefaultLogger, createDefaultStorage } from './oauth';
@NgModule()
export class AuthModule {
  static forRoot(configs: {
    serviceInstance: ClassProvider;
    loginRoute: string;
    logoutRoute: string;
    forcedLogoutFn?: (error: HttpErrorResponse) => boolean;
    customAuthInterceptor?: boolean;
  }): ModuleWithProviders<AuthModule> {
    const valueProviders: ValueProvider[] = [
      {
        provide: LOGIN_URL,
        useValue: configs.loginRoute,
      },
      {
        provide: LOGOUT_URL,
        useValue: configs.logoutRoute,
      },
    ];
    const classProviders: Provider[] = [];
    if (!configs.customAuthInterceptor) {
      classProviders.push({
        provide: HTTP_INTERCEPTORS,
        useValue: AuthInterceptor,
        multi: true,
      });
    }
    if (configs.forcedLogoutFn) {
      classProviders.push({
        provide: FORCED_LOGOUT_FN,
        useValue: configs.forcedLogoutFn,
      });
    }
    const guards = [AuthGuard, NoAuthGuard];
    return {
      ngModule: AuthModule,
      providers: [...guards, AuthProvider, configs.serviceInstance, ...valueProviders, ...classProviders],
    };
  }
  static forSSOFeature(configs: {
    configSSO: Partial<IRedocOauth2Config>;
    storage?: Storage;
    serviceInstance: ClassProvider;
    loginRoute: string;
    logoutRoute: string;
    forcedLogoutFn?: (error: HttpErrorResponse) => boolean;
    customAuthInterceptor?: boolean;
  }): ModuleWithProviders<AuthModule> {
    const valueProviders: Provider[] = [
      {
        provide: LOGIN_URL,
        useValue: configs.loginRoute,
      },
      {
        provide: LOGOUT_URL,
        useValue: configs.logoutRoute,
      },
      {
        provide: AUTH_COOKIE_READER,
        useClass: CookieReaderService,
      },
      OauthService,
      { provide: RedocOAuthLogger, useFactory: createDefaultLogger },
      {
        provide: RedocOAuthStorage,
        useFactory: configs.storage ? () => configs.storage : createDefaultStorage,
      },
      {
        provide: OAUTH2_CONFIG,
        useValue: configs.configSSO,
      },
      {
        provide: AuthProvider,
        useClass: OauthAuthProvider,
      },
    ];
    const classProviders: Provider[] = [];
    if (!configs.customAuthInterceptor) {
      classProviders.push({
        provide: HTTP_INTERCEPTORS,
        useValue: AuthInterceptor,
        multi: true,
      });
    }
    if (configs.forcedLogoutFn) {
      classProviders.push({
        provide: FORCED_LOGOUT_FN,
        useValue: configs.forcedLogoutFn,
      });
    }
    const guards = [SSOAuthGuard, SSONoAuthGuard];
    return {
      ngModule: AuthModule,
      providers: [...guards, AuthProvider, configs.serviceInstance, ...valueProviders, ...classProviders],
    };
  }
  static forSSRFeature(configs: {
    serviceInstance: ClassProvider;
    loginRoute: string;
    logoutRoute: string;
    forcedLogoutFn?: (error: HttpErrorResponse) => boolean;
    customAuthInterceptor?: boolean;
  }): ModuleWithProviders<AuthModule> {
    const valueProviders: Provider[] = [
      {
        provide: LOGIN_URL,
        useValue: configs.loginRoute,
      },
      {
        provide: LOGOUT_URL,
        useValue: configs.logoutRoute,
      },
      {
        provide: AUTH_COOKIE_READER,
        useClass: CookieReaderService,
      },
      {
        provide: AuthProvider,
        useClass: AuthSSRProvider,
      },
    ];
    const classProviders: Provider[] = [];
    if (!configs.customAuthInterceptor) {
      classProviders.push({
        provide: HTTP_INTERCEPTORS,
        useValue: AuthInterceptor,
        multi: true,
      });
    }
    if (configs.forcedLogoutFn) {
      classProviders.push({
        provide: FORCED_LOGOUT_FN,
        useValue: configs.forcedLogoutFn,
      });
    }
    const guards = [AuthGuard, NoAuthGuard];
    return {
      ngModule: AuthModule,
      providers: [...guards, AuthProvider, configs.serviceInstance, ...valueProviders, ...classProviders],
    };
  }
}
