import { IPhoneNumber } from '@red/data-access';
import { EUserAccountStatus } from '../enums';
import { IPersonalParticularAddress } from './personal-particular.interface';
import { IUpdatedBy } from './updated-by.interface';
import { IUserGroup } from './user-group.interface';
import { IUserRole } from './user-role.interface';

export interface IUserAccount {
  id: number;
  name: string;
  email: string;
  username: string;
  roles: IUserRole[];
  groups: IUserGroup[];
  status: EUserAccountStatus;
  avatar: string;
  businessUnitId: number;
  isActive: boolean;
  office: IPhoneNumber;
  phone: IPhoneNumber;
  mobile: IPhoneNumber;
  fax: IPhoneNumber;
  dateOfBirth: string;
  address: IPersonalParticularAddress;
  companies: unknown[];
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  createdBy?: IUpdatedBy;
  updatedBy?: IUpdatedBy;
  deletedBy?: IUpdatedBy;
}

export interface IUserAccountCreate {
  name: string;
  email: string;
  username: string;
  status: EUserAccountStatus;
  avatar: string;
  isActive: boolean;
  office: IPhoneNumber;
  phone: IPhoneNumber;
  mobile: IPhoneNumber;
  fax: IPhoneNumber;
  dateOfBirth: string;
  address: IPersonalParticularAddress;
}
