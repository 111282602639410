import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { AccountSetupCreateDto, AccountSetupUpdateCurrencyDto, AccountSetupUpdateDto } from '@shared/data-access/dto';
import { AccountSettingType } from '@shared/data-access/enums';
import { IUpdatedBy } from '@shared/data-access/interfaces';
import { AccountSetupModel, LastUpdatedModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountSetupApiService {
  static ROOT_POINT = 'finance/account-settings';
  static BANK_POINT_LIST = 'finance/bank-accounts/availables';
  static BANK_POINT_DELETE = 'finance/bank-accounts/';
  static BANK_POINT_CREATE_EDIT = 'finance/bank-accounts/setup';
  static OUTPUT_TAX = 'finance/account-settings/control/OUTPUT_TAX';
  static LAST_UPDATED = 'finance/account-settings/last-updated';

  constructor(private _apiService: ApiService) {}

  // Normal Account
  search(query = {}, type: AccountSettingType): Observable<PaginationAdapter<AccountSetupModel>> {
    return this._apiService.get(AccountSetupApiService.ROOT_POINT, { ...query, type }).pipe(map(data => new PaginationAdapter(AccountSetupModel, data)));
  }

  create(data: AccountSetupCreateDto) {
    return this._apiService.post(AccountSetupApiService.ROOT_POINT, data);
  }

  update(id: number, data: AccountSetupUpdateDto | AccountSetupUpdateCurrencyDto | null) {
    return this._apiService.patch(AccountSetupApiService.ROOT_POINT + '/' + id, data);
  }

  delete(id: number, data = {}) {
    return this._apiService.delete(AccountSetupApiService.ROOT_POINT + '/' + id, data);
  }

  // Bank Account
  searchBankAccount(query = {}): Observable<PaginationAdapter<AccountSetupModel>> {
    return this._apiService.get(AccountSetupApiService.BANK_POINT_LIST, query).pipe(map(data => new PaginationAdapter(AccountSetupModel, data)));
  }

  createBankAccount(data: AccountSetupCreateDto) {
    return this._apiService.post(AccountSetupApiService.BANK_POINT_CREATE_EDIT, data);
  }

  updateBankAccount(id: number, data: AccountSetupUpdateDto | AccountSetupUpdateCurrencyDto | null) {
    return this._apiService.patch(AccountSetupApiService.BANK_POINT_CREATE_EDIT + '/' + id, data);
  }

  deleteBankAccount(id: number, data = {}) {
    return this._apiService.delete(AccountSetupApiService.BANK_POINT_DELETE + '/' + id, data);
  }

  // Others
  getOutTaxAccount(query = {}): Observable<AccountSetupModel> {
    return this._apiService.get(AccountSetupApiService.OUTPUT_TAX, query).pipe(map(data => AccountSetupModel.fromJson(data)));
  }

  getLastUpdated(): Observable<{ updatedAt: string; updatedBy: IUpdatedBy }> {
    return this._apiService.get(AccountSetupApiService.LAST_UPDATED);
  }
}
