import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { map, Observable } from 'rxjs';
import { ITransferEmplacementHistory } from '@shared/data-access/interfaces';
import { EmploymentHistoryDto } from '@shared/data-access/dto';
import { TransferEmplacementHistoryModel } from '@shared/data-access/models';
@Injectable({
  providedIn: 'root',
})
export class TransferEmplacementHistoryApiService {
  static ROOT_POINT = 'hrm/emplacements';

  constructor(private apiService: ApiService) {}

  getList(salespersonId: number, query = {}, option?: IApiOption): Observable<TransferEmplacementHistoryModel[]> {
    return this.apiService
      .get(`${TransferEmplacementHistoryApiService.ROOT_POINT}/${salespersonId}`, query, option)
      .pipe(map((data: { items: ITransferEmplacementHistory[] }) => data?.items.map(item => TransferEmplacementHistoryModel.fromJson(item))));
  }
}
