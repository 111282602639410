import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class ResaleReportCreateDto extends BaseModel {

  @Expose()
  name!: string;
}

export class ResaleReportUpdateDto extends BaseModel {

  @Expose()
  name!: string;
}
