import { FormGroup } from '@angular/forms';
import { BaseModel } from '@red/data-access';
import {  Expose, Type } from 'class-transformer';
import {  IPoolChart } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';


export class PoolChartModel extends BaseModel implements IPoolChart {
  @Expose()
  appointmentTypeId!: number;

  @Expose()
  @Type(()=> AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;


}
