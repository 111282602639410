import { ClassProvider, FactoryProvider, Injectable, InjectionToken, Type } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
export interface IUser {
  name: string;
  email: string;
  avatar: string;
}
export const USER_LOADER = new InjectionToken<UserAdapter>('USER_LOADER');

@Injectable()
export abstract class UserAdapterBase<T> {
  protected _user: ReplaySubject<T> = new ReplaySubject<T>(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: T) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<T> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  get(): Observable<T> {
    return of();
  }
}

@Injectable()
export class UserAdapter extends UserAdapterBase<IUser> {}

export const useUserAdapterService = <T>(instance: Type<UserAdapterBase<T>>): ClassProvider => ({
  provide: USER_LOADER,
  useClass: instance,
});
