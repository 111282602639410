import { BaseModel, Default } from '@red/data-access';
import { Helper } from '@red/utils';
import { Expose, Transform, Type } from 'class-transformer';
import { PROJECT_PROPERTY_TYPES_OPTIONS } from '../data';
import { PROJECT_TRANSACTION_VERIFY_STATUS } from '../data/project-transaction-status.data';
import { EBlankFormReferralSourceType, EManagementCommissionPdRole, EProjectPropertyType, EProjectTransactionStatus } from '../enums';
import { IBlankFormSummary } from '../interfaces';

import { BlankFormIcModel } from './blank-form-ic.model';
import { BlankFormPdModel } from './blank-form-pd.model';
import { BlankFormReferralModel } from './blank-form-referral.model';
import { BlankFormOtherFeeModel } from './blank-form-other-fee.model';
import { BlankFormBdModel } from './blank-form-bd.model';
import { BlankFormInternalCoBrokeModel } from './blank-form-internal-co-broke.model';
import { BlankFormExternalCoBrokeModel } from './blank-form-external-co-broke.model';
import { BlankFormBuyerInfoModel } from './blank-form-buyer.model';

export class BlankFormSummaryModel extends BaseModel implements IBlankFormSummary {
  @Expose()
  @Type(() => BlankFormInternalCoBrokeModel)
  @Transform(
    ({ obj, value, options }) =>
      (obj?.main ? [BlankFormInternalCoBrokeModel.fromJson(obj.main)] : value?.map((item: any) => BlankFormInternalCoBrokeModel.fromJson(item, options))) ?? []
  )
  mains!: BlankFormInternalCoBrokeModel[];

  @Expose()
  @Type(() => BlankFormInternalCoBrokeModel)
  @Default([])
  internals!: BlankFormInternalCoBrokeModel[];

  @Expose()
  @Type(() => BlankFormExternalCoBrokeModel)
  @Default([])
  externals!: BlankFormExternalCoBrokeModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormInternalCoBrokeModel)
  @Transform(
    ({ obj, value, options }) =>
      (obj?.leader
        ? [BlankFormInternalCoBrokeModel.fromJson(obj.leader, options)]
        : value?.map((item: any) => BlankFormInternalCoBrokeModel.fromJson(item, options))) ?? []
  )
  leaders!: BlankFormInternalCoBrokeModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  blankFormBonuses!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  blankFormOverridings!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  blankFormPools!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormPdModel)
  blankFormPdOverrides!: BlankFormPdModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormPdModel)
  blankFormPdReferrals!: BlankFormPdModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormPdModel)
  blankFormPdManagements!: BlankFormPdModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormReferralModel)
  blankFormReferrals!: BlankFormReferralModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormOtherFeeModel)
  blankFormOthers!: BlankFormOtherFeeModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormBdModel)
  blankFormBds!: BlankFormBdModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormBuyerInfoModel)
  blankFormBuyer!: BlankFormBuyerInfoModel[];
}
