export const AUTH_ROUTE = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  FORGOT_ACCOUNT: 'forgot-account',
  RESET_PASSWORD: 'reset-password',
};

export const DASHBOARD_ROUTE = 'dashboard';
export const ROUTE = {
  DASHBOARD: 'dashboard',
  PUBLIC: 'public',
  CREATE: 'create',
  EDIT: 'edit',
  AUTH: {
    LOGIN: 'login',
    LOGOUT: 'logout',
    FORGOT_ACCOUNT: 'forgot-account',
    RESET_PASSWORD: 'reset-password',
  },
  PROJECT: {
    PROJECT_SETUP: 'project/setup',
    KEY_APPOINTMENT: 'project/key-appointment',
    COMM_MATRIX: 'project/comm-matrix',
    PROJECT_CONSULTANT: 'project/project-consultant',

    // Project Transaction
    PROJECT_TRANSACTION: 'project/project-transaction',
    PROJECT_TRANSACTION_GENERAL: 'general',
    PROJECT_TRANSACTION_AGENT_GENERAL: 'agent-general',
    PROJECT_TRANSACTION_COBROKE: 'cobroke',
    PROJECT_TRANSACTION_GROUP_LEADER: 'group-leader',

    // Blank Form
    BLANK_FORM: 'project/blank-form',
    BLANK_FORM_GENERAL: 'general',
    BLANK_FORM_CO_BROKE: 'co-broke',
    BLANK_FORM_GROUP_LEADER: 'group-leader',

    //Agent project transaction
    AGENT_PROJECT_TRANSACTION: 'project/agent-project-transaction',
    AGENT_PROJECT_TRANSACTION_GENERAL: 'general',
    AGENT_PROJECT_TRANSACTION_CO_BROKE: 'co-broke',
    AGENT_PROJECT_TRANSACTION_GROUP_LEADER: 'group-leader',

    //Agent Blank Form
    AGENT_BLANK_FORM: 'project/agent-blank-form',
    AGENT_BLANK_FORM_GENERAL: 'general',
    AGENT_BLANK_FORM_CO_BROKE: 'co-broke',
    AGENT_BLANK_FORM_GROUP_LEADER: 'group-leader',


  },
  RESALE_TRANSACTION: {
    LIST: 'resale-transaction',
    PROPERTY: 'property',
    BILLING_INFO: 'billing-info',
    EXTERNAL: 'external',
    HUTTONS_AGENTS: 'huttons-agents',
    UPLOAD_DOCUMENT: 'upload-document',
    SUMMARY: 'summary',
    TEMPLATE_PREVIEW: 'template-preview',
  },
  ECDD: {
    ROOT: 'ecdd',
    INDIVIDUAL: 'individual',
    ENTITY: 'entity',
    AML: 'aml',
    CUSTOMER_PARTICULARS_FORM: 'customer-particulars-form',
    THANK_YOU: 'thank-you',
    PDF_PREVIEW: 'pdf-preview',
  },
  PERSONAL_INFO: {
    PATH: 'personal-info',
    PERSONAL_PROFILE: 'personal-profile',
    UPLOAD_DOCUMENT: 'upload-document',
  },
  TEAM_ORG_CHART: {
    PATH: 'team-org-chart',
  },
  RECRUITMENT: {
    PATH: 'recruitment',
    APPLICATION: 'application',
    MY_RECRUITMENT: 'my-recruitment',
    TEAM_RECRUITMENT: 'team-recruitment',
    COMPLETED: 'completed',
    THANK_YOU: 'thank-you',
    ERROR: 'error',
  },
  COMMISSION_PAYOUT: {
    // PATH: 'commission-payout',
    LIST: 'commission-payout',
    SEARCH: 'search',
  },
  PAYMENT: {
    PATH: 'payment',
    TEMPLATE_PREVIEW: 'template-preview',
  },
  REPORT: {
    PATH: 'reports',
  },
};

interface ObjRouter extends Record<string, ObjRouter | string> {
  slug: string;
}

// export const ROUTER:ObjRouter = {
//   slug:'',
//   PROJECT:{
//     slug:'project',
//     COMM_MATRIX:{
//       slug:'comm-matrix',
//       COMM_MATRIX_GENERAL_INFO:'general',
//       COMM_MATRIX_AGENT_INFO:'agent'
//     }
//   }
// }
