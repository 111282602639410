import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IAssetCategory } from '../interfaces';
import { AssetCategoryAccountType } from '../types';
import { UpdatedByModel } from './updated-by.model';

export class AssetCategoryAccountModel extends BaseModel implements AssetCategoryAccountType {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  status!: string;

  get isActive(): boolean {
    return this.status === 'active';
  }
}

export class AssetCategoryModel extends BaseModel implements IAssetCategory {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  assetAccountId!: number;

  @Expose()
  assetAccount?: AssetCategoryAccountModel | null;

  @Expose()
  assetDepreciationAccountId!: number;

  @Expose()
  assetDepreciationAccount?: AssetCategoryAccountModel | null;

  @Expose()
  assetAccumulatedAccountId!: number;

  @Expose()
  assetAccumulatedAccount?: AssetCategoryAccountModel | null;

  @Expose()
  assetDisposalAccountId!: number;

  @Expose()
  assetDisposalAccount?: AssetCategoryAccountModel | null;

  @Expose()
  yearPeriod!: number;

  @Expose()
  monthPeriod!: number;

  // Time stamp
  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt?: string;

  @Expose()
  createdBy?: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  deletedBy?: string;
}
