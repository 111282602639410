import { HTTP_ERROR } from '@agent-portal/constants';
import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { ForgotDto, LoginDto, LoginResponseDto, RefreshTokenResponseDto } from '@shared/data-access/dto';
import { UserAccountModel } from '@shared/data-access/models';
import { TokenType } from '@shared/data-access/types';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  static ROOT_POINT = 'auth';
  static REFRESH_TOKEN = 'auth/access-token';
  static RESET_PASSWORD = 'auth/reset-password';
  static FORGOT_PASSWORD = 'auth/forgot-password';
  static CHECK_TOKEN = 'auth/check-token';

  constructor(private apiService: ApiService) {}

  getMe(): Observable<UserAccountModel> {
    return this.apiService.get(AuthApiService.ROOT_POINT).pipe(map(res => UserAccountModel.fromJson(res)));
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: LoginDto): Observable<LoginResponseDto> {
    return this.apiService.post(AuthApiService.ROOT_POINT, credentials);
  }

  /**
   * Refresh token
   *
   * @param credentials
   */
  refreshToken(refreshToken: string): Observable<RefreshTokenResponseDto> {
    return this.apiService.post(AuthApiService.REFRESH_TOKEN, { refreshToken });
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: { password: string }): Observable<any> {
    return this.apiService.post(AuthApiService.RESET_PASSWORD, data);
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(data: ForgotDto): Observable<any> {
    return this.apiService.post(AuthApiService.FORGOT_PASSWORD, data);
  }

  /**
   * check token is valid or not
   *
   * @param password
   */
  isTokenValid(data: { type: TokenType; token: string }): Observable<any> {
    return this.apiService.post(AuthApiService.CHECK_TOKEN, data, {
      exposeHeaders: {
        [HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['404'],
      },
    });
  }
}
