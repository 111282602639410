export interface IDiscipline{
  id:number;
  code:string;
  name:string;
  type:string;
  isActive:boolean;
  // status:string;
  createdAt:string;
  updatedAt:string;
}
