import { BaseModel, Default } from '@red/data-access';
import { Helper } from '@red/utils';
import { Expose, Transform, Type } from 'class-transformer';
import {
  IProjectTransaction,
  IProjectTransactionBds,
  IProjectTransactionBonus,
  IProjectTransactionCobrokeReferral,
  IProjectTransactionOther,
  IProjectTransactionOverriding,
  IProjectTransactionPd,
  IProjectTransactionPool,
  IProjectTransactionRelatedAgent,
  IProjectTransactionSummary,
} from '../interfaces';

import { ProjectTransactionRelatedCobrokeModel } from './project-transaction-related-cobroke.model';
import { ProjectTransactionRelatedAgentModel } from './project-transaction-related-agent.model';
import { ProjectTransactionBonusModel } from './project-transaction-bonus.model';
import { ProjectTransactionBdsModel } from './project-transaction-bds.model';
import { ProjectTransactionRelatedInternalCobrokeModel } from './project-transaction-related-internal-cobroke.model';
import { ProjectTransactionPoolModel } from './project-transaction-pool.model';
import { ProjectTransactionCobrokeReferralModel } from './project-transaction-cobroke-referral.model';
import { ProjectTransactionOverridingModel } from './project-transaction-overriding.model';
import { ProjectTransactionOtherModel } from './project-transaction-other.model';
import { ProjectTransactionPdModel } from './project-transaction-pd.model';

export class ProjectTransactionSummaryModel extends BaseModel implements IProjectTransactionSummary {
  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionRelatedAgent) => ProjectTransactionRelatedCobrokeModel.fromJson(item, { groups: ['summary'] }));
  })
  externals!: ProjectTransactionRelatedCobrokeModel[];

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionRelatedAgent) => ProjectTransactionRelatedInternalCobrokeModel.fromJson(item, { groups: ['summary'] }));
  })
  internals!: ProjectTransactionRelatedInternalCobrokeModel[];

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return ProjectTransactionRelatedAgentModel.fromJson(value, { groups: ['summary'] });
  })
  leaders!: ProjectTransactionRelatedAgentModel[];

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return ProjectTransactionRelatedAgentModel.fromJson(value, { groups: ['summary'] });
  })
  main!: ProjectTransactionRelatedAgentModel;

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionBonus) => ProjectTransactionBonusModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionBonuses!: ProjectTransactionBonusModel[];

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionBds) => ProjectTransactionBdsModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionBds!: ProjectTransactionBdsModel[];

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionOverriding) => ProjectTransactionOverridingModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionOverridings!: ProjectTransactionOverridingModel[];

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionPd) => ProjectTransactionPdModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionPdManagements!: ProjectTransactionPdModel[];

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionPd) => ProjectTransactionPdModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionPdReferrals!: ProjectTransactionPdModel[];

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionPd) => ProjectTransactionPdModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionPdOverrides!: ProjectTransactionPdModel[];

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionPool) => ProjectTransactionPoolModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionPools!: ProjectTransactionPoolModel[];

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionCobrokeReferral) => ProjectTransactionCobrokeReferralModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionReferrals!: ProjectTransactionCobrokeReferralModel[];

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionOther) => ProjectTransactionOtherModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionOthers!: ProjectTransactionOtherModel[];

  @Expose()
  hiProfit!: {
    bdProfit?: number;
    bdReceived?: number;
    headHiProfit?: number;
    headHiReceived?: number;
    hiTierReceived?: number;
    ultimateProfit?: number;
  };
  @Expose()
  profit!: {
    agentTierProfit?: number;
    agentTierReceived?: number;
    companyTierProfit?: number;
    companyTierReceived?: number;
    profitSharingAmount?: number;
    ultimateProfit?: number;
  };
}
