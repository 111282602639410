import { EPaymentReversalStatus } from '../enums';
import { StatusRecord } from '../types';

export const PAYMENT_REVERSAL_TYPES = [
  {
    id: 'Payment',
    name: 'Payment Reversal',
  },
  {
    id: 'Receipt',
    name: 'Receipt Reversal',
  },
] as const;

export const PAYMENT_REVERSAL_RECORD_TYPES = [
  {
    id: 'IV',
    name: 'Tax Invoice',
  },
  {
    id: 'CN',
    name: 'Credit Note',
  },
  {
    id: 'VCN',
    name: 'Supplier Credit Note',
  },
  {
    id: 'SCN',
    name: 'Supplier Credit Note',
  },
  {
    id: 'VI',
    name: 'Supplier Invoice',
  },
  {
    id: 'DN',
    name: 'Debit Note',
  },
] as const;

export const PAYMENT_REVERSAL_STATUS: StatusRecord<EPaymentReversalStatus> = {
  [EPaymentReversalStatus.Active]: {
    id: EPaymentReversalStatus.Active,
    name: 'Active',
    detailName: 'View',
    filterName: 'Active',
    class: 'status-box-success',
    detailClass: 'status-box-default',
  },
  [EPaymentReversalStatus.Cancel]: {
    id: EPaymentReversalStatus.Cancel,
    name: 'Cancelled',
    detailName: 'Void',
    filterName: 'Cancelled',
    class: 'status-box-smokey-topaz',
    detailClass: 'status-box-smokey-topaz',
  },
};
