import { EBatchType } from '../enums/batch.enum';
import { BatchhingAppovalProformaModel } from '../models';
import { IBatchingTransactionSelection } from './batching-transaction-selection.interface';
import { IGrossCommissionEarned } from './gross-commission-earned.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IProject } from './project.interface';
import { ITaxInvoice } from './tax-invoice-enhanced.interface';
import { IUpdatedBy } from './updated-by.interface';

export interface IBatchingApproval {
  id: number;
  projectId: number;
  type: EBatchType;
  project: IProject;
  code: string;
  name: string;
  grossComm: IGrossCommissionEarned;
  devGrossComm: IGrossCommissionEarned;
  hiGrossComm: IGrossCommissionEarned;
  profomaGrossComm: IGrossCommissionEarned;
  proformaCode: string;
  proforma: BatchhingAppovalProformaModel;
  details: IBatchingTransactionSelection[];
  detailBlankForms: IBatchingTransactionSelection[];
  batchedAt: string;
  batchedBy: IPersonalParticular;
  invoiceAt: string;
  invoiceId?: number;
  invoiceCode?: string;
  invoice?: ITaxInvoice;
  isAuto: boolean;
  hiInvoiceId?: number;
  hiInvoiceCode?: string;
  hiInvoice?: ITaxInvoice;
  runAt: string;
  remarks: string;
  comments?: string;
  createdBy: IUpdatedBy;
  status: string;
  createdAt: string;
  updatedAt: string;
  actualComm?: number;
  indexRow?: number;
}
