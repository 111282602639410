import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IAppointmentType, IBlankFormIc } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';
import { PersonalParticularModel } from './personal-particular.model';
import { SplitPartModel } from './split-part.model';
import { EAgentBlankFormSession } from '../enums';

export class BlankFormIcModel extends BaseModel implements IBlankFormIc {
  @Expose()
  id!: number;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  appTypeCode!: string;

  @Expose()
  agentType!: EAgentBlankFormSession;

  @Expose()
  @Transform(({ value }) => AppointmentTypeModel.fromJson(value))
  appointmentType!: IAppointmentType;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  blankFormId!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  updatedAt!: string;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  note: any;

  @Expose()
  data: any;

  @Expose()
  name!: string;

  @Expose()
  attrs!: any;

  @Expose()
  companyAmount!: number;

  @Expose()
  grouping!: string;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;
}
