import { EResaleNoOfRoom, EResaleNoOfRoomHdb } from '../enums';
import { ICountry } from './country.interface';

export interface IAddress {
  id: number;
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressTo: string;
  addressType: string;
  countryId: number;
  country: ICountry;
  isDefault: boolean;
  postcode: number;
  postal: string;
  unit: string;
  floor: string;
  building: string;
  noOfRooms: EResaleNoOfRoom | EResaleNoOfRoomHdb | string;
  block: string;
  street: string;
  district: string;
  state: string;
  city: string;
  updatedAt: string;
}

export interface IAddressBuyerDto {
  postal: string;
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
}
