import { ISplitMatrix } from './split-matrix.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { EBlankFormReferralType, EBLankFormReferralFormula, EBlankFormReferralSourceType } from '../enums';
import { IBlankFormReferralTypeDescription } from './blank-form-ic.interface';
import { IReferralExternal } from './referral-external.interface';
import { ISplitPart } from './split-part.interface';

export interface IBlankFormReferral extends ISplitMatrix {
  id: number;
  blankFormId: number;
  blankFormReferralType: EBlankFormReferralType;
  formula: EBLankFormReferralFormula;
  absorbType: { agent: boolean; hr: boolean };
  type: EBlankFormReferralSourceType;
  salespersonId: number;
  salesperson: IPersonalParticular;
  agentId: number;
  agent: IReferralExternal;
  note: any;
  data: any;
  parties: ISplitPart[];
  splitMatrixId: number;
  tiers: ISplitPart[];
  receivedValue: number;
  createdAt: string;
  updatedAt: string;
  isDefault: boolean;
  blankFormReferralTypeDescription?: IBlankFormReferralTypeDescription;
}
