export enum EPersonalVerifyStatus {
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
}

export enum EPersonalVerifyType {
  general = 'general',
  bank = 'bank',
}

export enum EPersonalVerifyKey {
  address = 'address',
  associatesCode = 'associatesCode',
  bankAccountNo = 'bankAccountNo',
  bankId = 'bankId',
  email1 = 'email1',
  email2 = 'email2',
  fax = 'fax',
  mobile1 = 'mobile1',
  mobile2 = 'mobile2',
  phone = 'phone',
  postalCode = 'postalCode',
}
