import { BaseModel, GetByPath, If } from '@red/data-access';
import { Expose } from 'class-transformer';
import 'reflect-metadata';

export type ReportCardDriverName = 'metabase' | 'exceljs';

export interface IReportCard {
  id: number;
  type: ReportCardDriverName;
  name: string;
  code: string;
}
export class ReportCard extends BaseModel implements IReportCard {
  @Expose()
  type!: ReportCardDriverName;

  @Expose()
  @GetByPath('data.id')
  id!: number;

  @Expose()
  @GetByPath('data.name')
  name!: string;

  @Expose()
  @If([obj => obj.type === 'exceljs', (obj: any) => obj.data.code])
  code!: string;
}
