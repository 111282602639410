export const DEVELOPER_ROLES = [
  {
    id:'customer',
    name:'Customer'
  },
  {
    id:'supplier',
    name:'Supplier'
  },
  {
    id:'both',
    name:'Both'
  }
] as const;
