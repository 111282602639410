export interface IProjectSearchEngineExternal {
  businessUnitId?: number;
  contactId: number;
  contactCode: string;
  contactName: string;
  invoiceId?: number;
  invoiceDeletedAt: Date | null;
  role: string;
  subTotalAmount: number;
  totalAmount: number;
  taxAmount: number;
}

export interface IProjectSearchEngineHIECBExternal {
  businessUnitId: number;
  contactId: number;
  contactCode: string;
  contactName: string;
  invoiceId: number;
  invoiceDeletedAt?: Date | null;
}

export interface IProjectSearchEngineBlankFormAdjExternal {
  blankFormId: number;
  contactId: number;
  name: string;
  receivedValue: number;
  invoiceId: number | null;
  blankFormCode: string;
}

export interface IProjectSearchEngine {
  id: number;
  batchCode: string;
  batchId: number;
  batchName: string;
  batchStatus: string;
  blankFormAdjExternals: IProjectSearchEngineBlankFormAdjExternal[];
  code: string;
  createdAt: string;
  deletedAt: string;
  entity: string;
  externals: IProjectSearchEngineExternal[];
  externalBds: IProjectSearchEngineHIECBExternal;
  hiInvoiceCode: string;
  hiInvoiceDate: string;
  invoiceCode: string;
  invoiceDate: string;
  optionDate: string;
  optionNo: string;
  projectCode: string;
  projectCommInternationalCode: string;
  projectCommInternationalId: number;
  projectCommInternationalName: string;
  projectCommissionCode: string;
  projectCommissionId: number;
  projectCommissionName: string;
  projectId: number;
  projectName: string;
  salespersonBusinessName: string;
  salespersonCeaRegNo: string;
  salespersonId: number;
  salespersonNricName: string;
  salespersonRole: string;
  unitId: number;
  unitNo: string;
  updatedAt: string;
  subTotalAmount: number;
  totalAmount: number;
}
