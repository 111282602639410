import { BaseModel, Default, PhoneNumber } from '@red/data-access';
import { IAddressSplitter } from '@shared/components/address-splitter';
import { Expose, Transform, Type } from 'class-transformer';
import { GENDER_OPTIONS, YES_NO_OPTIONS } from '../data';
import { ECitizen, EGender, EYesNo } from '../enums';
import { ICarrerProgression, IPersonalParticular, IPersonalParticularAddress, IPersonalParticularLeadership, IPersonalParticularUser } from '../interfaces';
import { YesNoOption } from '../types';
import { BankModel } from './bank.model';
import { CommissionSalespersonSchemeModel } from './commission-salesperson-scheme.model';
import { CompanyModel } from './company.model';
import { CountryModel } from './country.model';
import { CredentialModel } from './credential.model';
import { IdTypeModel } from './id-type.model';
import { MaritalModel } from './marital.model';
import { NationalityModel } from './nationality.model';
import { SchemeTypeModel } from './scheme-type.model';
import { UpdatedByModel } from './updated-by.model';
import { UserGroupModel } from './user-group.model';
import { UserRoleModel } from './user-role.model';
import { SplitPartModel } from './split-part.model';

export class PersonalParticularAddressModel extends BaseModel implements IPersonalParticularAddress {
  @Expose()
  house!: string;

  @Expose()
  street!: string;

  @Expose()
  address!: string;

  @Expose()
  district?: string;

  @Expose()
  floor?: string;

  @Expose()
  unit?: string;

  @Expose()
  buildingName?: string;

  @Expose()
  postalCode?: string;

  @Expose()
  addressLine1?: string;

  @Expose()
  addressLine2?: string;

  @Expose()
  addressLine3?: string;
}

export class PersonalParticularUserModel extends BaseModel implements IPersonalParticularUser {
  @Expose()
  email!: string;

  @Expose()
  businessUnitId?: number;

  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  status!: string;

  @Expose()
  username!: string;

  @Expose()
  @Type(() => CompanyModel)
  companies?: CompanyModel[];

  @Expose()
  @Type(() => UserGroupModel)
  groups?: UserGroupModel[];

  @Expose()
  @Type(() => UserRoleModel)
  roles?: UserRoleModel[];

  @Expose()
  groupIds?: number[];

  @Expose()
  roleIds?: number[];

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt?: string | null;

  @Expose()
  createdBy?: string | null;

  @Expose()
  updatedBy?: string | null;

  @Expose()
  deletedBy?: string | null;

  @Expose()
  @Transform(({ obj }: { obj: IPersonalParticularUser }) => {
    return obj.groups?.map(group => group.name).join(', ') ?? null;
  })
  groupNames!: string | null;

  @Expose()
  @Transform(({ obj }: { obj: IPersonalParticularUser }) => {
    return obj.roles?.map(role => role.name).join(', ') ?? null;
  })
  roleNames!: string | null;
}

export class PersonalParticularLeadershipModel extends BaseModel implements IPersonalParticularLeadership {
  @Expose()
  id!: number;

  @Expose()
  autoApprove!: boolean;

  @Expose()
  code!: string;

  @Expose()
  commissionType!: string;

  @Expose()
  designation!: string;

  @Expose()
  directHeadcount!: number;

  @Expose()
  status!: string;

  @Expose()
  totalHeadcount!: number;
}

export class PersonalParticularModel extends BaseModel implements IPersonalParticular {
  @Expose()
  id!: number;

  @Expose()
  appTypeIds?: { id: number }[];

  @Expose()
  appointmentType?: {
    code: string;
    id: number;
    isActive: boolean;
    name: string;
  };

  @Expose()
  credentialId!: number;

  @Expose()
  @Type(() => CredentialModel)
  credential?: CredentialModel;

  @Expose()
  careers?: ICarrerProgression[];

  @Expose()
  parentId!: number | undefined;

  @Expose()
  @Type(() => PersonalParticularModel)
  parent!: PersonalParticularModel;

  @Expose()
  @Type(() => CommissionSalespersonSchemeModel)
  commissionScheme!: CommissionSalespersonSchemeModel;

  @Expose()
  commissionSchemeId!: number;

  @Expose()
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  recruiterId?: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  recruiter?: PersonalParticularModel;

  @Expose()
  @Default(null)
  recruiterDateFrom?: string;

  @Expose()
  @Default(null)
  recruiterDateTo?: string;

  @Expose()
  associatesCode?: string;

  @Expose()
  businessName!: string;

  @Expose()
  nricName?: string;

  @Expose()
  joinDate?: string;

  @Expose()
  resignDate?: string;

  @Expose()
  resignReason?: string;

  @Expose()
  companyId?: number;

  @Expose()
  @Type(() => CompanyModel)
  company?: CompanyModel;

  @Expose()
  companyIds?: { id: number }[];

  @Expose()
  @Type(() => CompanyModel)
  companies?: CompanyModel[];

  @Expose()
  managerEffectiveDate?: string;

  @Expose()
  earliestManagerDate?: string;

  @Expose()
  transferRemark?: string;

  @Expose()
  emplacementRemark?: string;

  @Expose()
  nricfin?: string;

  @Expose()
  idTypeId?: number;

  @Expose()
  @Type(() => IdTypeModel)
  idType?: IdTypeModel;

  @Expose()
  issueDate?: string;

  @Expose()
  expireDate?: string;

  @Expose()
  ceaRegNo?: string;

  @Expose()
  dateOfBirth?: string;

  @Expose()
  effectiveDate?: string;

  @Expose()
  gender?: EGender;

  @Expose()
  maritalId?: number;

  @Expose()
  @Type(() => MaritalModel)
  marital?: MaritalModel;

  @Expose()
  citizen?: ECitizen;

  @Expose()
  nationalityId?: number;

  @Expose()
  @Type(() => NationalityModel)
  nationality?: NationalityModel;

  @Expose()
  countryId?: number;

  @Expose()
  @Type(() => CountryModel)
  country?: CountryModel;

  @Expose()
  bankId?: number;

  @Expose()
  @Type(() => BankModel)
  bank?: BankModel;

  @Expose()
  bankAccountNo?: string;

  @Expose()
  propertyName?: string;

  @Expose()
  state?: string;

  @Expose()
  address?: PersonalParticularAddressModel;

  @Expose()
  postalCode?: string;

  @Expose()
  @Type(() => PhoneNumber)
  phone?: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  mobile1?: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  mobile2?: PhoneNumber;

  @Expose()
  email1?: string;

  @Expose()
  email2?: string;

  @Expose()
  avatar?: string;

  @Expose()
  accumulateCommission!: number;

  @Expose()
  receivedCommission?: number;

  @Expose()
  @Type(() => PhoneNumber)
  fax?: PhoneNumber;

  @Expose()
  @Type(() => PersonalParticularUserModel)
  user?: PersonalParticularUserModel;

  @Expose()
  @Type(() => SchemeTypeModel)
  schemeType?: SchemeTypeModel;

  @Expose()
  schemeTypeId?: number;

  @Expose()
  @Type(() => PersonalParticularLeadershipModel)
  leadership?: PersonalParticularLeadershipModel;

  @Expose()
  leadershipId?: number;

  //#region timestamps
  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt?: string | null;

  @Expose()
  createdBy?: string | null;

  @Expose()
  updatedBy?: UpdatedByModel | null;

  @Expose()
  deletedBy?: UpdatedByModel | null;
  //#endregion

  @Expose()
  @Transform(({ obj }: { obj: IPersonalParticular }) => {
    return YES_NO_OPTIONS[obj.isActive ? EYesNo.Yes : EYesNo.No] ?? null;
  })
  activeInfo!: YesNoOption | null;

  @Expose()
  @Transform(({ obj }: { obj: IPersonalParticular }) => {
    if (!obj.ceaRegNo) return obj.businessName;

    return `${obj.businessName} (${obj.ceaRegNo})`;
  })
  businessCeaRegNoName!: string;

  @Expose()
  matrix!: {
    id: number;
    tiers: SplitPartModel[];
    parties: SplitPartModel[];
  };

  get addressLine() {
    if (this.address?.floor && this.address?.unit) {
      return [
        this.address?.house,
        this.address?.street,
        `#${this.address?.floor}-${this.address?.unit}${this.address?.buildingName ? ' ' + this.address?.buildingName : ''}`,
        this.country?.name,
        this.postalCode,
      ]
        .filter(str => !!str)
        .join(', ');
    }
    return [this.address?.house, this.address?.street, this.country?.name, this.postalCode].filter(str => !!str).join(', ');
  }

  get ceaRegName(): string | null {
    return this.ceaRegNo ? `CEA No: ${this.ceaRegNo}` : null;
  }

  get mobile1Name(): string | null {
    return this.mobile1 ? `Mobile: (+${this.mobile1?.countryCode}) ${this.mobile1?.phoneNumber}` : null;
  }

  get shortInfo(): string {
    return [this.nricName, this.ceaRegName].join(' • ');
  }

  get tierName(): string | null {
    if (!this.ceaNameMobile1Name) return this.businessName ?? null;
    return `${this.businessName} (${this.ceaNameMobile1Name})`;
  }

  get ceaNameMobile1Name(): string | null {
    const info = [];
    this.ceaRegName && info.push(this.ceaRegName);
    this.mobile1Name && info.push(this.mobile1Name);
    return info.join(' • ') ?? null;
  }

  get divisionInfo(): string {
    return `${this.nricName ?? '-'} (${this.ceaRegNo ?? '-'}) - ${this.leadership?.code}`;
  }

  get genderInfo() {
    return this.gender ? GENDER_OPTIONS[this.gender] ?? null : null;
  }

  get addressSplitter(): IAddressSplitter {
    return {
      blockNo: this.address?.house,
      streetName: this.address?.street,
      floor: this.address?.floor,
      unitNo: this.address?.unit,
      buildingName: this.address?.buildingName,
      postalCode: this.postalCode,
      country: this.country?.name,
    };
  }
}

export class PersonalParticularModelWithHover extends PersonalParticularModel {
  isHover?: boolean;
}
