import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EBusinessDirector } from '../enums';
import { IHeadHi } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';
import { PersonalParticularModel } from './personal-particular.model';

export class HeadHiModel extends BaseModel implements IHeadHi {
  @Expose()
  id!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string | null | undefined;

  @Expose()
  createdBy!: string | null | undefined;

  @Expose()
  updatedBy!: string | null | undefined;

  @Expose()
  deletedBy!: string | null | undefined;

  @Expose()
  projectId!: number;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  type!: EBusinessDirector;

  @Expose()
  appointmentId!: number;

  @Expose()
  @Type(() => AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;

  @Expose()
  @Transform(({ value }) => new Date(value).toDateString())
  startDate!: string;

  @Expose()
  // @Transform(({ value }) => new Date(value).toDateString())
  endDate!: string | null;

  @Expose()
  isPersonInCharge!: boolean;

  @Expose()
  appTypes!: { appType: AppointmentTypeModel }[];

  @Expose()
  appointmentTypes!: AppointmentTypeModel[];
}
