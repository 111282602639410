import { isNil } from 'lodash-es';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_EMPTY_VALUE } from './empty.module';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  constructor(@Inject(DEFAULT_EMPTY_VALUE) private defaultValue: string | number | symbol) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    return !isNil(value) ? value : this.defaultValue;
  }
}
