import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { Ir8aAmountCreateDto, Ir8aAmountUpdateDto } from '@shared/data-access/dto';
import { IIr8aAmount } from '@shared/data-access/interfaces';
import { Ir8aAmountModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Ir8aAmountApiService {
  static ROOT_POINT = 'hrm/ir8a';

  constructor(private apiService: ApiService) {}

  private _getRootPointId(id: number): string {
    return [Ir8aAmountApiService.ROOT_POINT, id].join('/');
  }

  create(data: Ir8aAmountCreateDto): Observable<Ir8aAmountModel> {
    return this.apiService.post(Ir8aAmountApiService.ROOT_POINT, data).pipe(map((res: IIr8aAmount) => Ir8aAmountModel.fromJson(res) as Ir8aAmountModel));
  }

  get(id: number): Observable<Ir8aAmountModel> {
    return this.apiService.get(this._getRootPointId(id)).pipe(map(res => Ir8aAmountModel.fromJson(res) as Ir8aAmountModel));
  }

  update(id: number, data: Ir8aAmountUpdateDto): Observable<unknown> {
    return this.apiService.patch(this._getRootPointId(id), data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<Ir8aAmountModel>> {
    return this.apiService.get(Ir8aAmountApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(Ir8aAmountModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<Ir8aAmountModel> {
    return this.apiService.delete(Ir8aAmountApiService.ROOT_POINT, { ids }, option);
  }
}
