import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { round } from 'lodash-es';
import { BATCH_PAYMENT_MODE_OPTIONS, BATCH_RECEIPT_PAYMENT_MODE_DATA, EPaymentMode, EReceiptPaymentMode } from '../data';
import { IBatchSearchEngine, IBatchSearchEngineReceipt } from '../interfaces';
import { EPaymentReversalStatus, EReceiptStatus } from '../enums';

export class BatchSearchEngineReceiptModel extends BaseModel implements IBatchSearchEngineReceipt {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  receiptDate!: string;

  @Expose()
  reversalDate!: string;

  @Expose()
  @Transform(({ obj }) => (obj.reversalDate ? obj.totalAmount * -1 : obj.totalAmount))
  totalAmount!: number;

  @Expose()
  reference!: string;

  @Expose()
  businessUnitId!: number;

  @Expose()
  remarks!: string;

  @Expose()
  outstandingAmount!: number;

  @Expose()
  invoiceTotal!: number;

  @Expose()
  status!: EReceiptStatus | EPaymentReversalStatus;

  @Expose()
  get paymentMode(): EReceiptPaymentMode {
    if (this.reversalDate) {
      return EReceiptPaymentMode.reversal;
    }
    if (this.totalAmount >= this.invoiceTotal) {
      return EReceiptPaymentMode.full;
    }
    return EReceiptPaymentMode.partial;
  }

  @Expose()
  get paymentModeDisplay() {
    return BATCH_RECEIPT_PAYMENT_MODE_DATA[this.paymentMode];
  }
}

export class BatchSearchEngineModel extends BaseModel implements IBatchSearchEngine {
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  code!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  deletedAt!: string;

  @Expose()
  hiInvoiceCode!: string;

  @Expose()
  hiInvoiceDate!: string;

  @Expose()
  hiInvoiceId!: number;

  @Expose()
  invoiceBalanceDue!: number;

  @Expose()
  invoiceCode!: string;

  @Expose()
  invoiceDate!: string;

  @Expose()
  invoiceId!: number;

  @Expose()
  invoiceIsCancel!: boolean;

  @Expose()
  invoiceIsWriteOff!: boolean;

  @Expose()
  invoicePaidAmount!: number;

  @Expose()
  invoiceSubtotal!: number;

  @Expose()
  invoiceGST!: number;

  @Expose()
  invoiceTotal!: number;

  @Expose()
  name!: string;

  @Expose()
  projectCode!: string;

  @Expose()
  projectId!: number;

  @Expose()
  projectName!: string;

  @Expose()
  receiptCheque!: string;

  @Expose()
  receiptCode!: string;

  @Expose()
  receiptDate!: string;

  @Expose()
  receiptId!: number;

  @Expose()
  updatedAt!: string;

  @Expose()
  @Default([])
  creditNotes!: {
    id: number;
    code: string;
    creditNoteDate: string;
    totalAmount: number;
    totalPaid?: number;
    businessUnitId: number;
    status: string;
  }[];

  @Expose()
  @Default([])
  @Type(() => BatchSearchEngineReceiptModel)
  receipts!: BatchSearchEngineReceiptModel[];

  @Expose()
  @Type(() => BatchSearchEngineReceiptModel)
  @Default([])
  reversals!: BatchSearchEngineReceiptModel[];

  @Expose()
  get receiptTotalAmount() {
    return [...this.receipts, ...this.reversals]?.reduce((total, receipt) => total + receipt.totalAmount, 0) ?? 0;
  }

  @Expose()
  get creditNoteTotalAmount() {
    return this.creditNotes?.reduce((total, creditNote) => total + creditNote.totalAmount, 0) ?? 0;
  }

  @Expose()
  get paymentMode() {
    if (this.invoiceBalanceDue === 0) {
      return EPaymentMode.full;
    }
    if (this.invoiceBalanceDue > 0 && this.invoicePaidAmount > 0 && this.invoicePaidAmount < this.invoiceTotal) {
      return EPaymentMode.partial;
    }
    return EPaymentMode.all;
  }

  @Expose()
  get paymentModeDisplay() {
    return BATCH_PAYMENT_MODE_OPTIONS[this.paymentMode];
  }

  @Expose()
  get invoiceUrl() {
    return this.invoiceId && this.invoiceCode ? `/account-receivable/tax-invoice/customer/${this.invoiceId}` : '';
  }

  @Expose()
  get hiInvoiceUrl() {
    return this.hiInvoiceId ? `/account-receivable/tax-invoice/customer/${this.hiInvoiceId}` : '';
  }

  @Expose()
  get receiptUrl() {
    return this.receiptId ? `/receipt/${this.receiptId}` : '';
  }

  @Expose()
  get addReceiptUrl() {
    return this.paymentMode !== EPaymentMode.full && this.invoiceId && this.invoiceCode ? `/receipt/create-preset/tax-invoice/${this.invoiceId}` : '';
  }

  @Expose()
  get addHiReceiptUrl() {
    return this.paymentMode !== EPaymentMode.full && this.hiInvoiceId ? `/receipt/create-preset/tax-invoice/${this.hiInvoiceId}` : '';
  }

  @Expose()
  get addCreditNoteUrl() {
    return this.invoiceId && this.invoiceCode
      ? `/account-receivable/credit-note/customer/create?taxInvoiceId=${this.invoiceId}&invoiceNumber=${this.invoiceCode}`
      : '';
  }

  @Expose()
  get addHiCreditNoteUrl() {
    return this.hiInvoiceId && this.hiInvoiceCode
      ? `/account-receivable/credit-note/customer/create?taxInvoiceId=${this.hiInvoiceId}&invoiceNumber=${this.hiInvoiceCode}`
      : '';
  }

  @Expose()
  get addCreditNoteNewUrl() {
    return this.invoiceId && this.invoiceCode ? `/account-receivable/credit-note/customer/create-preset/${this.invoiceId}` : '';
  }

  @Expose()
  get addHiCreditNoteNewUrl() {
    return this.invoiceId && this.hiInvoiceCode ? `/account-receivable/credit-note/customer/create-preset/${this.hiInvoiceId}` : '';
  }

  @Expose()
  get isReverseReceipt() {
    return !!this.combinedReceipts().pop()?.reversalDate;
  }

  @Expose()
  get receiveAmount() {
    const receiptList: BatchSearchEngineReceiptModel[] = this.combinedReceipts();
    const receiptTotalAmount = receiptList.reduce((total, receipt) => total + receipt.totalAmount, 0);
    return receiptTotalAmount;
  }

  combinedReceipts(): BatchSearchEngineReceiptModel[] {
    const { invoiceTotal } = this;
    const validReversals = this.reversals.filter(reversal => reversal.status !== EPaymentReversalStatus.Cancel);

    const sortedReceipts = [...this.receipts, ...validReversals].sort(
      (a, b) => new Date(a.receiptDate || a.reversalDate).getTime() - new Date(b.receiptDate || b.reversalDate).getTime()
    );
    const combinedReceipts: BatchSearchEngineReceiptModel[] = [];
    sortedReceipts.reduce((outstandingAmount, receipt) => {
      const { totalAmount } = receipt;
      outstandingAmount = round(outstandingAmount - totalAmount, 2);
      combinedReceipts.push(BatchSearchEngineReceiptModel.merge(receipt, { invoiceTotal, outstandingAmount }));
      return outstandingAmount;
    }, invoiceTotal);

    const validReceipts = combinedReceipts.filter(receipt => receipt.status !== EReceiptStatus.cancelled);
    return validReceipts;
  }

  visibleReceipts(): BatchSearchEngineReceiptModel[] {
    const { invoiceTotal } = this;

    const sortedReceipts = [...this.receipts, ...this.reversals].sort(
      (a, b) => new Date(a.receiptDate || a.reversalDate).getTime() - new Date(b.receiptDate || b.reversalDate).getTime()
    );

    const visibleReceipt: BatchSearchEngineReceiptModel[] = [];
    sortedReceipts.reduce((outstandingAmount, receipt) => {
      const { totalAmount } = receipt;
      outstandingAmount = round(outstandingAmount - totalAmount, 2);
      visibleReceipt.push(BatchSearchEngineReceiptModel.merge(receipt, { invoiceTotal, outstandingAmount }));
      return outstandingAmount;
    }, invoiceTotal);

    const validReceipts = visibleReceipt.filter(receipt => receipt.status !== EReceiptStatus.cancelled);
    return validReceipts;
  }
}
