import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IAssetDisposal, IAssetDisposalRecord, IProfitCentres } from '../interfaces';
import { AssetCategoryAccountModel } from './asset-category.model';
import { ProfitCentresModel } from './profit-centres.model';
import { AssetRegisterModel } from './asset-register.model';

export class AssetDisposalRecordModel extends BaseModel implements IAssetDisposalRecord {
  @Expose()
  id!: number;

  @Expose()
  @Type(() => AssetRegisterModel)
  asset!: Partial<AssetRegisterModel>;

  @Expose()
  assetAccountId!: number;

  @Expose()
  assetAccount?: AssetCategoryAccountModel | null;

  @Expose()
  assetDepreciationAccountId!: number;

  @Expose()
  assetDepreciationAccount?: AssetCategoryAccountModel | null;

  @Expose()
  assetAccumulatedAccountId!: number;

  @Expose()
  assetAccumulatedAccount?: AssetCategoryAccountModel | null;

  @Expose()
  assetDisposalAccountId!: number;

  @Expose()
  assetDisposalAccount?: AssetCategoryAccountModel | null;

  @Expose()
  profitCenterId?: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: Partial<IProfitCentres>;

  @Expose()
  serialNumber?: string;

  @Expose()
  purchaseCost?: number;

  @Expose()
  initNBV!: number;

  @Expose()
  accDepTotal!: number;

  @Expose()
  accDepDisposal!: number;

  @Expose()
  disAmount!: number;

  @Expose()
  proceeds!: number;
}

export class AssetDisposalModel extends BaseModel implements IAssetDisposal {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  date!: string;

  @Expose()
  description!: string;

  @Expose()
  amount!: number;

  @Expose()
  initNBV!: number;

  @Expose()
  accDepTotal!: number;

  @Expose()
  accDepDisposal!: number;

  @Expose()
  disAmount!: number;

  @Expose()
  proceeds!: number;
}
