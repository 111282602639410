import { IPagination } from '../interfaces/pagination.interface';
import { BaseModel } from './base.model';
import { Expose, Exclude } from 'class-transformer';
import { Default } from '../decorators/default.decorator';



export class PaginationModel extends BaseModel implements IPagination {
  @Expose({ name: 'itemsPerPage' })
  @Default(12)
  limit!: number;

  @Expose({ name: 'currentPage' })
  @Default(1)
  page!: number;

  @Expose({ name: 'totalPages' })
  pageCount!: number;

  @Expose({ name: 'totalItems' })
  total!: number;


  @Expose()
  @Default([12, 24, 36, 48])
  pageSizeOptions!: number[];
}
