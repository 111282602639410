import { PhoneFax, PhoneNumber } from '@red/data-access';
import { IBase } from './base-finance.interface';

export interface IContactPerson extends IBase {
  name: string;
  designation?: string;
  email?: string;
  phone: PhoneNumber;
  mobile?: PhoneNumber;
  fax?: PhoneFax;
  default?: boolean;
  contactId: number;
  index?: number;
}
