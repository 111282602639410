import { BaseModel, Default, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EFinanceCalculatingOrigin } from '../enums';
import { EBalanceDocumentPostingType, ECustomerOrSupplier } from '../enums/tax-invoice-enhanced.enum';

export class SupplierInvoiceRecordDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  itemCode?: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount?: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Default(false)
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  //   @Expose()
  //   @Type(() => Number)
  //   gstValue!: number;

  @Expose()
  @Type(() => Number)
  accountId?: number;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  remarks?: string;

  @Expose()
  description?: string;

  @Expose()
  uom?: string;
}

export class SupplierInvoicePostingDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => Number)
  taxInvoiceDetailId?: number;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  debit?: number;

  @Expose()
  @Type(() => Number)
  credit?: number;

  @Expose()
  type?: EBalanceDocumentPostingType;
}

export class SupplierInvoiceDto extends BaseModel {
  //  ====== General ======
  @Expose()
  @Default(ECustomerOrSupplier.SUPPLIER)
  invoiceType!: ECustomerOrSupplier.SUPPLIER;

  @Expose()
  @Type(() => Number)
  contactId?: number;

  @Expose()
  @Type(() => Number)
  contactPersonId?: number;

  @Expose()
  @Type(() => Number)
  contactAddressId?: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDueDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  ecbInvoiceDate?: string;

  @Expose()
  @Default('SGD')
  @Transform(({ value }) => 'SGD')
  currency!: string;

  @Expose()
  @Type(() => Number)
  currencyId!: number;

  @Expose()
  fileAttachedName?: string;

  @Expose()
  fileAttachedViewName?: string;

  @Expose()
  invoiceNumber?: string;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  // @Expose()
  // @Type(() => Number)
  // @Default(0)
  // paymentTerm?: number;

  //  ====== Other detail ======
  @Expose()
  remarks?: string;

  @Expose()
  reference?: string;

  //  ====== Calculate data =====
  @Expose()
  @Type(() => Number)
  gstPercent?: number;

  @Expose()
  @Type(() => Number)
  gstValue?: number;

  @Expose()
  gstEdited?: boolean;

  @Expose()
  @Type(() => SupplierInvoiceRecordDto)
  details!: SupplierInvoiceRecordDto[];

  @Expose()
  @Type(() => SupplierInvoicePostingDto)
  postings!: SupplierInvoicePostingDto[];
}

export class SupplierInvoicePostingReqItemDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  amount?: number;
}

export class SupplierInvoicePostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Number)
  contactId!: number | null;

  @Expose()
  @Type(() => Number)
  taxInvoiceId!: number | null;

  @Expose()
  @Default(ECustomerOrSupplier.SUPPLIER)
  type!: ECustomerOrSupplier;

  @Expose()
  @Type(() => SupplierInvoicePostingReqItemDto)
  items!: SupplierInvoicePostingReqItemDto[];
}

export class SupplierInvoiceCalculateSummaryReqDto extends BaseModel {
  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  gstValue!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => SupplierInvoiceRecordDto)
  details!: SupplierInvoiceRecordDto[];
}
