import { ISplitMatrix } from './split-matrix.interface';

import { IPersonalParticular } from './personal-particular.interface';
import { IAppointmentType } from './appointment-type.interface';
import { EAgentSession } from '../enums';
export interface IProjectTransactionBonus {
  id: number;
  appointmentTypeId: number;
  agentType: EAgentSession;
  appointmentType: IAppointmentType;
  appTypeCode: string;
  salespersonId: number;
  salesperson: IPersonalParticular;
  splitMatrixId: number;
  splitMatrix: ISplitMatrix;
  receivedDirectPercent: number;
  receivedPercent: number;
  receivedValue: number;
  createdAt: string;
  updatedAt: string;
  grouping: string;
}
