export interface INationality {
  id: number;
  code: string;
  name: string;
  status: string;
  isDefault?: boolean;
  sequence?: number;
  createdAt: string;
  updatedAt: string;
}
