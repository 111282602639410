import { BaseModel, Default, PhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { isEmpty } from 'lodash-es';
import { ECitizen, EGender, EIndustryExam } from '../enums';
import { IRecruitmentApplication, IRecruitmentApplicationPersonal, IRecruitmentMetadata } from '../interfaces';
import { BankModel } from './bank.model';
import { MaritalModel } from './marital.model';
import { NationalityModel } from './nationality.model';
import { PersonalParticularAddressModel } from './personal-particular.model';
import { QualificationModel } from './qualification.model';

export class RecruitmentMetadataModel extends BaseModel implements IRecruitmentMetadata {
  @Expose()
  reason!: string;

  @Expose()
  qualifyOther!: string;

  @Expose()
  industryOther!: string;
}

export class RecruitmentApplicationPersonalModel extends BaseModel implements IRecruitmentApplicationPersonal {
  @Expose()
  nricfin!: string;

  @Expose()
  nricName!: string;

  @Expose()
  businessName!: string;

  @Expose()
  dateOfBirth!: string;

  @Expose()
  gender!: EGender;

  @Expose()
  email1!: string;

  @Expose()
  ceaRegNo!: string;

  @Expose()
  citizen!: ECitizen;

  @Expose()
  @Type(() => MaritalModel)
  marital!: MaritalModel;

  @Expose()
  @Type(() => Number)
  maritalId!: number;

  @Expose()
  @Type(() => PhoneNumber)
  mobile1!: PhoneNumber;

  @Expose()
  @Type(() => NationalityModel)
  nationality!: NationalityModel;

  @Expose()
  @Type(() => Number)
  nationalityId!: number;

  @Expose()
  @Type(() => BankModel)
  bank!: BankModel;

  @Expose()
  @Type(() => Number)
  bankId!: number;

  @Expose()
  bankAccountNo!: string;

  @Expose()
  bankName!: string;

  @Expose()
  @Type(() => QualificationModel)
  qualification!: QualificationModel;

  @Expose()
  @Type(() => Number)
  qualificationId!: number;

  @Expose()
  @Transform(({ obj }: { obj: IRecruitmentApplicationPersonal }) => {
    if (!isEmpty(obj.qualifyOther)) return obj.qualifyOther;

    if (obj.metadata && obj.metadata.qualifyOther) {
      return obj.metadata.qualifyOther;
    }

    return null;
  })
  qualifyOther!: string;

  @Expose()
  industryExam!: EIndustryExam;

  @Expose()
  @Transform(({ obj }: { obj: IRecruitmentApplicationPersonal }) => {
    if (!isEmpty(obj.industryOther)) return obj.industryOther;

    if (obj.metadata && obj.metadata.industryOther) {
      return obj.metadata.industryOther;
    }

    return null;
  })
  industryOther!: string;

  @Expose()
  hanyuPinyinName!: string;

  @Expose()
  website!: string;

  @Expose()
  @Type(() => RecruitmentMetadataModel)
  @Default({})
  metadata!: RecruitmentMetadataModel;
}

export class RecruitmentApplicationModel extends RecruitmentApplicationPersonalModel implements IRecruitmentApplication {
  @Expose()
  @Type(() => PersonalParticularAddressModel)
  address!: PersonalParticularAddressModel;

  @Expose()
  postal!: string;

  @Expose()
  signature!: string;
}
