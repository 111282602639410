import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ChequeBookCreateDto, ChequeUpdateDto } from '@shared/data-access/dto';
import { ICheque } from '@shared/data-access/interfaces';
import { ChequeModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChequeApiService {
  static ROOT_POINT = 'finance/cheques';
  static DETAIL_POINT = 'finance/cheques/:id';
  static CANCEL_POINT = 'finance/cheques/:id/cancel';
  static RESET_POINT = 'finance/cheques/:id/reset';
  static AVAILABLE_FOR_PAYMENT_POINT = 'finance/cheques/availables/payments';

  constructor(private apiService: ApiService) {}

  createBook(data: ChequeBookCreateDto): Observable<any> {
    return this.apiService.post(`${ChequeApiService.ROOT_POINT}`, data);
  }

  get(id: number, query = {}): Observable<ChequeModel> {
    return this.apiService.get(ChequeApiService.DETAIL_POINT, { id, ...query }).pipe(map(res => ChequeModel.fromJson(res) as ChequeModel));
  }

  update(id: number, data: ChequeUpdateDto): Observable<any> {
    return this.apiService.patch(ChequeApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ChequeModel>> {
    return this.apiService.get(ChequeApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ChequeModel, data)));
  }

  searchAvailableForPayment(query = {}, option?: IApiOption): Observable<PaginationAdapter<ChequeModel>> {
    return this.apiService.get(ChequeApiService.AVAILABLE_FOR_PAYMENT_POINT, query, option).pipe(map(data => new PaginationAdapter(ChequeModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(ChequeApiService.DETAIL_POINT, { id, ...data }, option);
  }

  cancel(id: number, data = {}): Observable<any> {
    return this.apiService.patch(ChequeApiService.CANCEL_POINT, { id, ...data }).pipe(map(res => ChequeModel.fromJson(res) as ChequeModel));
  }

  reset(id: number, data = {}): Observable<any> {
    return this.apiService.patch(ChequeApiService.RESET_POINT, { id, ...data }).pipe(map(res => ChequeModel.fromJson(res) as ChequeModel));
  }
}
