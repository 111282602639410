import { IPagination } from '../interfaces';
import { ClassConstructor, plainToInstance } from 'class-transformer';
import { PaginationModel } from '../models';
export interface IPaginationAdapter<T> {
  pagination: IPagination;

  results: T[];
}
export class PaginationAdapter<T> implements PaginationAdapter<T> {
  pagination!: PaginationModel;

  results!: T[];

  constructor(cls: ClassConstructor<T>, plain: { meta: Partial<IPagination>; items: unknown[] }, groups?: string[]) {
    this.pagination = PaginationModel.fromJson(plain.meta);
    this.results = plainToInstance(cls, plain.items, { groups });
  }
}
