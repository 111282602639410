import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ImportChartOfAccountsItemDto extends BaseModel {
  @Expose()
  @Type(() => String)
  accountType!: string;

  @Expose()
  @Type(() => String)
  accountCode!: string;

  @Expose()
  @Type(() => String)
  accountName!: string;

  @Expose()
  @Type(() => String)
  parentAccountCode!: string;
}

export class ImportChartOfAccountsDto extends BaseModel {
  @Expose()
  @Type(() => ImportChartOfAccountsItemDto)
  items?: ImportChartOfAccountsItemDto[];
}
