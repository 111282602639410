import { Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { Observable } from 'rxjs';
import { Currency } from '../enums';
import { IJournalPosting } from './../interfaces/journal-posting.interface';
import { BaseFixedModel } from './base.model';
import { LedgerAccountModel } from './ledger-account.model';
import { ProfitCentresModel } from './profit-centres.model';

export class JournalPostingModel extends BaseFixedModel implements IJournalPosting {
  @Expose()
  @Type(() => Number)
  journalId!: number;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  chartOfAccount!: LedgerAccountModel;

  @Expose()
  @Type(() => Number)
  profitCenterId!: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCentres!: ProfitCentresModel;

  @Expose()
  description?: string;

  @Expose()
  @Type(() => Number)
  debit!: number;

  @Expose()
  @Type(() => Number)
  credit!: number;

  @Expose()
  @Type(() => Number)
  order!: number;

  @Expose()
  @Default(Currency.SGD)
  currency!: string;

  @Expose()
  accountAsync!: Observable<LedgerAccountModel>;

  @Expose()
  profitAsync!: Observable<ProfitCentresModel>;

  @Expose()
  index?: number;
}
