// core
import { Expose, Type } from 'class-transformer';

// share
import { BaseModel, FormattedDateString, StartOfDate } from '@red/data-access';
import { EVerifyStatus } from '../enums';
import { IPromotionApprovalVerifyDto } from '../interfaces';

export class PromotionApprovalVerifyDto extends BaseModel implements IPromotionApprovalVerifyDto {
  @Expose()
  status!: EVerifyStatus;

  @Expose()
  rejectReason!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate!: string;
}
