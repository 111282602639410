import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { RED_MEDIA_STORAGE, UploadLinkPayload } from '@red/components/media-storage';
import { IMediaStorageConfig } from '@red/components/media-storage/media-storage.constant';
import { HuttonStorageApiService } from '@shared/features/media-storage';

@Injectable({ providedIn: 'root' })
export class RecruitmentApplicationStorageApiService extends HuttonStorageApiService {
  static GET_UPLOAD_URL = 'storage/public/upload/hrm';
  TYPE = 'hrm';

  constructor(apiService: ApiService, httpClient: HttpClient, @Inject(RED_MEDIA_STORAGE) config: IMediaStorageConfig) {
    super(apiService, httpClient, config);
  }

  override getUploadLink(data: UploadLinkPayload) {
    return this.apiService.get(RecruitmentApplicationStorageApiService.GET_UPLOAD_URL, {
      type: this.TYPE,
      folder: data.type,
      filename: data.name,
    });
  }
}
