import { BaseModel, Default } from '@red/data-access';
import { Expose, Type, Transform } from 'class-transformer';
import { IImportAR, IImportARColumn } from '../interfaces';

export class ImportARModel extends BaseModel implements IImportAR {
  @Expose()
  rowIndex!: number;

  @Expose()
  @Default(false)
  isHover!: boolean;

  @Expose()
  @Type(() => String)
  @Default('')
  customerCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  customerName!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  type!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  invoiceNo!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  invoiceDate!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemGstPercent!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemGstAmount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemNo!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemDescription!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  uom!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  quantity!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  unitPrice!: string;

  // @Expose()
  // @Type(() => String)
  // @Default('')
  // discountPercent!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  creditAccount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  debitAccount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  profitCentre!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemRemarks!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  currencySign!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  currencyRate!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  billingAddress!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  contactPerson!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  reference!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  creditTerm!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  salesperson!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  paymentTerms!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  remarks!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  glEntry!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  offsetInvoice!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  gstInclusive!: string;

  get isEmpty(): boolean {
    const checkingProperties: (keyof IImportAR)[] = [
      'customerCode',
      'customerName',
      'type',
      'invoiceNo',
      'invoiceDate',
      'itemGstPercent',
      'itemGstAmount',
      'itemNo',
      'itemCode',
      'itemDescription',
      'uom',
      'quantity',
      'unitPrice',
      // 'discountPercent',
      'creditAccount',
      'debitAccount',
      'profitCentre',
      'itemRemarks',
      'currencySign',
      'currencyRate',
      'billingAddress',
      'contactPerson',
      'reference',
      'creditTerm',
      'salesperson',
      'paymentTerms',
      'remarks',
      'glEntry',
      'offsetInvoice',
      'gstInclusive',
    ];

    return checkingProperties.every(c => {
      const value = this[c];
      return !value || value.trim() === '';
    });
  }
}

export class ImportARColumnModel extends BaseModel implements IImportARColumn {
  @Expose()
  id!: keyof IImportAR;

  @Expose()
  label!: string;

  @Expose()
  index!: number;

  @Expose()
  @Default(false)
  required!: boolean;

  @Expose()
  @Default(300)
  maxLength!: number;

  @Expose()
  @Transform(({ obj }) => {
    return `${obj.label} [${obj.maxLength ?? 'NA'}]${obj.required ? '*' : ''}`;
  })
  title!: string;
}
