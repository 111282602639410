import { IPhoneNumber } from '@red/data-access';
import { ICategory } from './category.interface';
import { IContactAddress } from './contact-address.interface';
import { ILedgerAccount } from './ledger-account.interface';

export interface IDeveloper {
  id: number;
  code: string;
  name: string;
  groupName: string;
  email: string;
  phone: IPhoneNumber;
  categoryId: number;
  category?: ICategory;
  accountReceivableId: number;
  accountReceivable: ILedgerAccount;
  accountPayableId: number;
  accountPayable: ILedgerAccount;
  businessRegistration: string;
  creditTerm: number;
  creditLimit: number;
  gstRegistration: string;
  role: string;
  remarks: string;
  isActive: boolean;
  commenceDate: string;
  createdAt: string;
  updatedAt: string;
  addresses: IContactAddress[];
}
