import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EGceAmountStatus, EPaymentType } from '../enums';
import { IGceAmount } from '../interfaces';
import { PersonalParticularModel } from './personal-particular.model';

export class GceAmountModel extends BaseModel implements IGceAmount {
  @Expose()
  id!: number;

  @Expose()
  amount!: number;

  @Expose()
  description!: string;

  @Expose()
  effectiveDate!: string;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  status!: EGceAmountStatus;

  @Expose()
  transactionCode!: string;

  @Expose()
  paymentType!: EPaymentType;

  get canDelete(): boolean {
    return this.paymentType === EPaymentType.manualFee;
  }

  get canEdit(): boolean {
    return this.paymentType === EPaymentType.manualFee;
  }

  //#region timestamps
  @Expose()
  createdAt!: string;
  @Expose()
  updatedAt!: string;
  @Expose()
  deletedAt?: string;
  @Expose()
  createdBy?: string;
  @Expose()
  updatedBy?: string;
  @Expose()
  deletedBy?: string;
  //#endregion
}

export class GceAmountModelWithHover extends GceAmountModel {
  @Expose()
  isHover?: boolean;
}
