import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { KeyAppointmentModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyAppointmentApiService {
  static ROOT_POINT = 'transaction/key-appointments/portal';
  static MAIN_IC_POINT = 'transaction/key-appointments/portal/main-ic';

  constructor(private apiService: ApiService) { }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<KeyAppointmentModel>> {
    return this.apiService.get(KeyAppointmentApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } }).pipe(map(data => new PaginationAdapter(KeyAppointmentModel, data)));
  }

  searchMainIc(query = {}, option?: IApiOption): Observable<PaginationAdapter<KeyAppointmentModel>> {
    return this.apiService
      .get(KeyAppointmentApiService.MAIN_IC_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(KeyAppointmentModel, data)));
  }

  get(itemId: number): Observable<KeyAppointmentModel> {
    return this.apiService.get(KeyAppointmentApiService.ROOT_POINT + '/' + itemId).pipe(map(res => KeyAppointmentModel.fromJson(res) as KeyAppointmentModel));
  }

  getMainIcByProjectId(projectId: number): Observable<KeyAppointmentModel> {
    return this.apiService
      .get(KeyAppointmentApiService.MAIN_IC_POINT, { projectIds: projectId })
      .pipe(map(res => KeyAppointmentModel.fromJson(res.items[0] || {}) as KeyAppointmentModel));
  }

  getTotalMembers(id: number, query = {}): Observable<any> {
    return this.apiService.get(KeyAppointmentApiService.ROOT_POINT + '/total/' + id, query);
  }
}
