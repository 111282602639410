import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentVisibilityService } from './document-visibility.service';

@NgModule({
  imports: [CommonModule],
  providers: [DocumentVisibilityService],
})
export class DocumentVisibilityModule {
  static injector: Injector | null = null;
  constructor(public injector: Injector) {
    DocumentVisibilityModule.injector = injector;
  }
}
