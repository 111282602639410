import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IInvoicePayable, IParentInvoicePayable } from '../interfaces';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { ReceiptModel } from './receipt.model';

export class ParentInvoicePayableModel extends BaseModel implements IParentInvoicePayable {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  total!: number;

  @Expose()
  @Type(() => ReceiptModel)
  receipts?: ReceiptModel[];
}

export class InvoicePayableModel extends BaseModel implements IInvoicePayable {
  @Expose()
  id!: number;

  @Expose()
  code?: string;

  @Expose()
  invoiceNumber!: string;

  @Expose()
  contactId!: number;

  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  @Default(0)
  @Type(() => Number)
  total!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  paidAmount!: number;

  @Expose()
  @Default('SGD')
  currency!: string;

  @Expose()
  @Default(0)
  @Type(() => Number)
  balanceDue!: number;

  @Expose()
  // @Type(() => Date)
  invoiceDate!: string;

  @Expose()
  // @Type(() => Date)
  // dueDate!: Date;
  invoiceDueDate?: Date;

  @Expose()
  @Type(() => Date)
  createdAt!: string;

  @Expose()
  @Type(() => Date)
  updatedAt!: string;

  @Expose()
  @Type(() => ParentInvoicePayableModel)
  parentInvoice?: ParentInvoicePayableModel;
}
