export interface IStatusDescription {
  id: string;
  name: string;
  detailName?: string;
  filterName?: string;
  showOnFilter?: boolean;
  class: string;
  detailClass?: string;
  canSubmit?: boolean;
  canAgentSubmit?: boolean;
  canEdit?: boolean;
  canAgentEdit?: boolean;
  canDelete?: boolean;
  canAgentDelete?: boolean;
  canSave?: boolean;
  icon?: string;
  canAgentUploadDocs?: boolean;
}
