import { IGrossCommissionEarnedConfig } from '../interfaces';
import { BaseModel } from "@red/data-access";
import {  Expose } from 'class-transformer';


export class IGrossCommissionEarnedConfigModel extends BaseModel implements IGrossCommissionEarnedConfig {
  @Expose()
  commissionReceivedFrom!: number;

  @Expose()
  commissionReceivedTo!: number;

  @Expose()
  fixedEvaluationPeriod!: boolean;

  @Expose()
  accumulatedPeriod!: number;

  @Expose()
  resetAccumulatedPeriod!: number;

  @Expose()
  excludeOwnTransaction!: boolean;

  @Expose()
  isActive!:boolean;

}
