export enum ERecruitmentType {
  BrandNew = 'BrandNew',
  Existing = 'Existing',
  RejoinIndustry = 'RejoinIndustry',
}

export enum ERecruitmentStatus {
  draft = 'draft',
  withdrawn = 'withdrawn',
  submitted = 'submitted',
  rework = 'rework',
  approved = 'approved',
  rejected = 'rejected',
  registered = 'registered',
}

export enum ERecruitmentDivision {
  HQSAVILLS = 'HQSAVILLS',
  HUTTONSAGENT = 'HUTTONSAGENT', // TODO: REMOVE
  KGD = 'KGD',
  MTD = 'MTD',
  NAVIS = 'NAVIS',
  PND = 'PND',
  RTD = 'RTD',
  WHD = 'WHD',
}
