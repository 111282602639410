import { EBalanceDocumentCode, EBalanceDocumentPostingType, ECustomerOrSupplier, EInvoiceCreateMode, ETaxInvoiceStatus } from '../enums/tax-invoice-enhanced.enum';
import { IContactAddress } from './contact-address.interface';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IContactPerson } from './contact-person.interface';
import { ICurrencyRate } from './currency-rate.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IProfitCentres } from './profit-centres.interface';
import { ITemplates } from './template.interface';
import { IUpdatedBy } from './updated-by.interface';

export interface ITaxInvoiceRecord {
  id?: number;
  itemCode?: string;

  description: string;

  amount?: number;
  quantity: number;
  unitPrice: number;
  discount: number;
  gstCategory: string;
  gstInclusive: boolean;
  gstValue?: number;
  // gstPercent?: number;

  bankReferralFee?: boolean;

  uom?: string;

  remarks?: string;
  remarkAddress?: string;

  account: ILedgerAccount;
  accountId: number;

  profitCenter: IProfitCentres;
  profitCenterId?: number;

  // taxInvoice: ITaxInvoiceEnhanced;
  taxInvoiceId: number;

  nric?: string;

  agentId?: number;
  agent?: IPersonalParticular;
  agentName?: string;

  referralDescription?: string;
  // transactionNo?: string;
  // propertyAddress?: string;
  // clientName?: string;
  // transactionType?: string;
  // paidAmount?: number;
  // total?: number;
  // balanceDue?: number;
  // clientType?: string;
  // propertyType?: string;
  // agentType?: string;
  // street?: string;
  // buildingName: string;
  // unitNo: string;
  // floor?: string;
  // country: string;
  // postalCode: string;
  // blockNo: string;
  // associate?: string;
  // commissionPercent?: number;
}

export interface ITaxInvoicePosting {
  account?: ILedgerAccount;
  accountId: number;

  profitCenter?: IProfitCentres;
  profitCenterId?: number;

  description?: string;

  debit: number;
  credit: number;
  amount: number;

  currency?: string;
  taxInvoiceId: number;
  taxInvoiceDetailId?: number;
  // taxInvoiceDetail?: ITaxInvoiceDetailEnhanced;

  type?: EBalanceDocumentPostingType;
}

export type ITaxInvoiceStatus = `${ETaxInvoiceStatus}`;
export type ITaxInvoiceCreatedMode = `${EInvoiceCreateMode}`;

export interface ITaxInvoice {
  // General
  contact: IContactCustomerAndSupplier;
  billingAddress: IContactAddress;
  contactId?: number;
  contactAddressId: number;

  invoiceNumber?: string;
  invoiceDate: string;
  invoiceDueDate?: Date;

  contactPerson?: IContactPerson;
  contactPersonId?: number;
  customerName?: string;

  // Summary
  amount: number;
  discount: number;
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gstEdited: boolean;
  gst?: number;

  // Other Details
  currencyId: number;
  // currency: CurrencyRateModel;

  billingAddressCustom?: string;
  contactPersonCustom?: string;

  reference?: string;

  creditTerm?: number;
  paymentTerm?: string;

  salePersonId?: number;
  salesperson?: IPersonalParticular;

  remarks?: string;

  templateId?: number;
  template?: ITemplates;

  // General
  id: number;
  businessUnitId?: number;
  status?: ITaxInvoiceStatus;
  createdMode?: ITaxInvoiceCreatedMode;
  paidStatus?: string;
  balanceDue?: number;
  invoiceType?: ECustomerOrSupplier.CUSTOMER;
  balanceDocumentCodeType?: EBalanceDocumentCode;

  inPaymentBilling?: boolean;
  inClosedPeriod?: boolean;

  createAt?: string;
  updateAt?: string;
  createdBy?: string;
  updatedBy?: IUpdatedBy;

  // invoiceDueDate?: string;

  projectId?: number;

  paidAmount?: number;
  // printFormat?: string;
  // invoiceType?: ECustomerOrSupplier;

  // ecbInvoiceDate?: string;

  // parentInvoice?: ITaxInvoiceEnhanced;
  // parentInvoiceId?: number;

  // batchComment?: string;
  // batchName?: string;
  // batchCode?: string;

  // attention?: string;

  // supplierInvoiceType?: ESupplierInvoice;

  // adjustment?: boolean;

  // fileAttachedName?: string;

  // fileAttachedViewName?: string;

  // balanceDocumentCodeType?: EBalanceDocumentCode;
}

export interface ITaxInvoiceSummary {
  amount: number;
  discount: number;
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gstEdited: boolean;
}

export interface ITaxInvoiceCalculateSummary {
  details: Partial<ITaxInvoiceRecord>[];
  summary: ITaxInvoiceSummary;
}

export interface ITaxInvoiceInitData {
  contact?: IContactCustomerAndSupplier;
  billingAddress?: IContactAddress;
  contactPerson?: IContactPerson;
  currency?: ICurrencyRate;
}

export interface ITaxInvoicePayment {
  amount: number;
  id: number;
  referenceNo: string;
  date: Date;
  type: string;
  paymentContactType: ECustomerOrSupplier;
}
