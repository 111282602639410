import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { AssetDepreciationCreateDto } from '@shared/data-access/dto';
import { IAssetDepreciation } from '@shared/data-access/interfaces';
import { AssetDepreciationModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetDepreciationApiService {
  static ROOT_POINT = 'finance/asset-depreciations';

  constructor(private apiService: ApiService) {}

  create(data: AssetDepreciationCreateDto): Observable<any> {
    return this.apiService.post(`${AssetDepreciationApiService.ROOT_POINT}`, data);
    // .pipe(map((res: IAssetDepreciation) => AssetDepreciationModel.fromJson(res) as AssetDepreciationModel));
  }

  get(itemId: number, query = {}): Observable<AssetDepreciationModel> {
    return this.apiService
      .get(AssetDepreciationApiService.ROOT_POINT + '/' + itemId, query)
      .pipe(map(res => AssetDepreciationModel.fromJson(res) as AssetDepreciationModel));
  }

  getNewDepreciation(query = {}): Observable<AssetDepreciationModel> {
    return this.apiService
      .get(AssetDepreciationApiService.ROOT_POINT + '/new', query)
      .pipe(map(res => AssetDepreciationModel.fromJson(res) as AssetDepreciationModel));
  }

  // update(id: number, data: AssetDepreciationUpdateDto): Observable<any> {
  //   return this.apiService.patch(AssetDepreciationApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  // }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<AssetDepreciationModel>> {
    return this.apiService.get(AssetDepreciationApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(AssetDepreciationModel, data)));
  }

  // delete(ids: number[], option?: IApiOption): Observable<any> {
  //   return this.apiService.delete(AssetDepreciationApiService.ROOT_POINT, { ids }, option);
  // }
}
