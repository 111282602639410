import { IPhoneNumber } from '@red/data-access';
import { IAddress } from './address.interface';
import { ICompany } from './company.interface';

export interface IBroker {
  id: number;
  name: string;
  address: string;
  phone: IPhoneNumber;
  companyId: string;
  company: ICompany;
  gstRegistered: boolean;
  addresses?: IAddress[];
  salePersonId?: number;
}
