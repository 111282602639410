import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { ICommissionPayout } from '../interfaces';

export class CommissionPayoutModel extends BaseModel implements ICommissionPayout {
  @Expose()
  id!: number;

  @Expose()
  caseNo!: string;

  @Expose()
  subjectProperty!: string;

  @Expose()
  commType!: string;

  @Expose()
  payoutDate!: string;

  @Expose()
  payoutMethod!: string;

  @Expose()
  payoutValue!: number;

  @Expose()
  commScheme!: number;

  @Expose()
  transactionType!: string;
}
