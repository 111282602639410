export enum EManagementCommissionActivePd {
  activePdOverride = 'activePdOverride',
  activePdReferal = 'activePdReferal',
  activePdManagement = 'activePdManagement',
}

export enum EManagementCommissionPdRole {
  PDOverride = 'PDOverrideEntity',
  PDReferral = 'PDReferralEntity',
  PDManagement = 'PDManagementEntity',
}

export enum EManagementCommissionStatus {
  draft = 'draft',
  approved = 'approved',
  rejected = 'rejected',
  submitted = 'submitted',
  rework = 'rework',
}
