import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExceljsParameterFieldBase } from '../exceljs-parameter-field-base.directive';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ExceljsParameter, IExceljsParameterControlType } from '../../models';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ExceljsParameterService } from '../exceljs-parameter.service';
import { NgxMaskModule } from 'ngx-mask';
import { isNil, isUndefined } from 'lodash-es';

@Component({
  selector: 'red-exceljs-parameter-text',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatAutocompleteModule, NgxMaskModule],
  templateUrl: './exceljs-parameter-text.component.html',
  styleUrls: ['./exceljs-parameter-text.component.scss'],
})
export class ExceljsParameterTextComponent extends ExceljsParameterFieldBase {
  options: { value: string | number | boolean; label: string }[] = [];
  @ViewChild('searchInput', { read: ElementRef, static: true }) searchInput!: ElementRef;
  isNumber = false;

  toDataDowncastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return val;
  }
  toDataUpcastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return val;
  }

  constructor() {
    super();
    this.dataChange$.subscribe(() => {
      this.options = this.data.options || [];
      this.isNumber = this.data.valueType?.toLocaleLowerCase().includes('number') ?? false;
    });
  }
  clear(): void {
    this.dataUpcast.setValue('');
  }
  get controlType(): IExceljsParameterControlType {
    return 'text';
  }
}
