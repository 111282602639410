import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PermissionConfig } from './models/permission.model';
import { providePermissionPrefix, providePermissionRedirectCommand } from './permission-provider';
import { PermissionDirective } from './permission.directive';
import { PermissionGuard } from './permission.guard';
import { PermissionPipe } from './permission.pipe';
import { PermissionsService } from './permission.service';

@NgModule({
  declarations: [PermissionDirective, PermissionPipe],
  imports: [CommonModule],
  exports: [PermissionDirective, PermissionPipe],
  providers: [PermissionGuard],
})
export class PermissionModule {
  static forRoot(config?: PermissionConfig): ModuleWithProviders<PermissionModule> {
    const providers = [];
    providers.push(providePermissionPrefix(config?.prefix), providePermissionRedirectCommand(config?.redirectCommand));

    return {
      ngModule: PermissionModule,
      providers: [PermissionsService, ...providers],
    };
  }

  static forChild(config?: PermissionConfig): ModuleWithProviders<PermissionModule> {
    const providers = [];
    if (config?.prefix) {
      providers.push(providePermissionPrefix(config.prefix));
    }
    if (config?.redirectCommand) {
      providers.push(providePermissionRedirectCommand(config.redirectCommand));
    }
    return {
      ngModule: PermissionModule,
      providers,
    };
  }
}
