import { Pipe, PipeTransform } from '@angular/core';
import { ReportCard } from './models';

@Pipe({
  name: 'reportCardRoute',
})
export class ReportCardRoutePipe implements PipeTransform {
  transform(value: ReportCard): string[] {
    if (value.type === 'metabase') {
      return ['question', value.id.toString()];
    }
    return ['question', 'ejs', value.code];
  }
}
