import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { PaymentCreateDto, PaymentPostingReqDto, PaymentUpdateDto } from '@shared/data-access/dto';
import { IPayment } from '@shared/data-access/interfaces';
import { DealValueModel, PaymentDataSource, PaymentModel, PaymentOffsetModel, PaymentPostingDataSourceModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  static ROOT_POINT = 'finance/payments';
  static EMAIL_POINT = 'finance/payments/:id/email';
  static CANCEL_POINT = 'finance/payments/:id/cancelled';
  static OFFSETS_POINT = 'finance/payments/:id/offsets';
  static PREVIEW_POSTING = 'finance/payments/postings/preview';
  static PREVIEW_VOUCHER = 'finance/payments/:id/templates/preview';
  static PREVIEW_TOTAL_AMOUNT = 'finance/payments/total-amount';
  static REVERSAL_AVAILABLE_POINT = 'finance/payments/availables/reversals';
  static RECORDS_AND_POSTING_DETAIL = 'finance/payments/:id/details';

  constructor(private apiService: ApiService) { }

  create(data: PaymentCreateDto): Observable<PaymentModel> {
    return this.apiService.post(`${PaymentApiService.ROOT_POINT}`, data).pipe(map((res: IPayment) => PaymentModel.fromJson(res) as PaymentModel));
  }

  get(itemId: number, query = {}): Observable<PaymentModel> {
    return this.apiService.get(PaymentApiService.ROOT_POINT + '/' + itemId, query).pipe(map(res => PaymentModel.fromJson(res) as PaymentModel));
  }

  getRecordsAndPostings(id: number, query = {}): Observable<PaymentDataSource> {
    return this.apiService.get(PaymentApiService.RECORDS_AND_POSTING_DETAIL, { id, ...query }).pipe(map(res => PaymentDataSource.fromJson(res) as PaymentDataSource));
  }

  update(id: number, data: PaymentUpdateDto): Observable<any> {
    return this.apiService.patch(PaymentApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PaymentModel>> {
    return this.apiService.get(PaymentApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(PaymentModel, data)));
  }

  searchReversalAvalables(query = {}, option?: IApiOption): Observable<PaginationAdapter<PaymentModel>> {
    return this.apiService.get(PaymentApiService.REVERSAL_AVAILABLE_POINT, query, option).pipe(map(data => new PaginationAdapter(PaymentModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(PaymentApiService.ROOT_POINT + '/' + id, data, option);
  }

  cancel(id: number, data = {}): Observable<any> {
    return this.apiService.post(PaymentApiService.CANCEL_POINT, { id, ...data });
  }

  previewTotalAmount(data: { details: { credit: DealValueModel; debit: DealValueModel }[] }): Observable<any> {
    return this.apiService.post(PaymentApiService.PREVIEW_TOTAL_AMOUNT, data);
  }

  previewPosting(data: PaymentPostingReqDto): Observable<PaymentPostingDataSourceModel> {
    return this.apiService
      .post(PaymentApiService.PREVIEW_POSTING, data)
      .pipe(map(res => PaymentPostingDataSourceModel.fromJson(res) as PaymentPostingDataSourceModel));
  }

  previewVoucher(id: number, data = {}): Observable<any> {
    return this.apiService.post(
      PaymentApiService.PREVIEW_VOUCHER,
      { id, ...data },
      {
        pretreatmentResponse: false,
        requestOptions: { responseType: 'arraybuffer' },
      }
    );
  }

  getOffsets(id: number, query = {}): Observable<any> {
    return this.apiService.get(PaymentApiService.OFFSETS_POINT, { id, ...query }).pipe(map(res => PaymentOffsetModel.fromJson(res) as PaymentOffsetModel));
  }

  sendConfirmEmail(id: number, code: string, query = {}): Observable<any> {
    return this.apiService.post(PaymentApiService.EMAIL_POINT, { id, code, ...query });
  }
}
