import { ERebateBy } from '@shared/data-access/enums';

export interface ITransactedType {
  type: string;
  specifyAmount: number;
  absorbBy: string;
  fromAgent: number;
  fromCompany: number;
  rebateBy: ERebateBy;
  currency: string;
  foreignAmount: number;
  foreignCurrency: string;
}
