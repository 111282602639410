import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { assign, cloneDeep } from 'lodash-es';
import { USER_GROUPS_OPTIONS } from '../data';
import { EUserGroup } from '../enums';
import { IUserGroup, IUserGroupAccessible } from '../interfaces';
import { UserGroupRecord } from '../types';
import { UpdatedByModel } from './updated-by.model';
import { getModuleGroupPermssion } from './user-permission.model';
import { UserRoleModel } from './user-role.model';

export class UserGroupAccessibleModel extends BaseModel implements IUserGroupAccessible {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  module!: EUserGroup;

  @Expose()
  @Type(() => Number)
  total!: number;

  @Expose()
  @Type(() => Number)
  totalRead!: number;

  @Expose()
  @Type(() => Number)
  totalCreate!: number;

  @Expose()
  @Type(() => Number)
  totalUpdate!: number;

  @Expose()
  @Type(() => Number)
  totalDelete!: number;
}

export class UserGroupModel extends BaseModel implements IUserGroup {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  description!: string;

  @Expose()
  status!: string;

  @Expose()
  @Type(() => UserRoleModel)
  roles!: UserRoleModel[];

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  @Default([])
  @Type(() => UserGroupAccessibleModel)
  accessible!: UserGroupAccessibleModel[];

  @Expose()
  @Transform(({ obj }: { obj: IUserGroup }) => {
    const options = cloneDeep(USER_GROUPS_OPTIONS);
    const accessible = Object.values(
      (obj.accessible ?? []).reduce((acc: Record<string, IUserGroupAccessible>, item: IUserGroupAccessible) => {
        const moduleName = getModuleGroupPermssion(item.module);
        acc[moduleName] = assign(acc[moduleName] || item, { module: moduleName, total: (acc[moduleName]?.total || 0) + (item.total || 0) });
        return acc;
      }, {})
    );

    accessible.forEach(v => {
      if (!v.module || v.module === EUserGroup.PROJECT || v.module === EUserGroup.SETTINGS) return;
      options[v.module].accessible = v.total > 0;
      options[v.module].total = v.total ?? 0;
    });

    return options;
  })
  accessibleInfo!: UserGroupRecord;
}
