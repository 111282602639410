import {BaseModel, Default} from "@red/data-access";
import {Expose, Type} from "class-transformer";

export class DepartmentCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  @Type(() => Number)
  level!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;
}
