export enum Status {
  active = 'active',
  inactive = 'inactive',
}

export enum EChartOfACcountDisplayStatus {
  active = 'active',
  inactive = 'inactive',
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
}

export enum EUserAccountStatus {
  enabled = 'enabled',
  disabled = 'disabled',
}
