export const DIRECT_DEBIT_PAYMENT_TRANSACTION_CODES = [
  {
    id: 'MiscCredit',
    name: '20 - Misc. Credit',
  },
  {
    id: 'SalaryCredit',
    name: '22 - Salary Credit',
  },
  {
    id: 'DividendCredit',
    name: '23 - Dividend Credit',
  },
  {
    id: 'RemittanceCredit',
    name: '24 - Remittance Credit',
  },
  {
    id: 'BillCredit',
    name: '25 - Bill Credit',
  },
] as const;
