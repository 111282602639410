import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ContactAddressModel, ContactCustomerAndSupplierModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactApiService {
  static ROOT_POINT = 'finance/contacts';
  static SEARCH_POINT = 'finance/contacts/commons';
  static ADDRESS_POINT = 'finance/contacts/:contactId/addresses';

  constructor(private apiService: ApiService) {}

  commonList(query = {}, option?: IApiOption): Observable<PaginationAdapter<ContactCustomerAndSupplierModel>> {
    return this.apiService.get(ContactApiService.SEARCH_POINT, query, option).pipe(map(data => new PaginationAdapter(ContactCustomerAndSupplierModel, data)));
  }

  getContactAddresses(contactId: number, query = {}): Observable<PaginationAdapter<ContactAddressModel>> {
    return this.apiService.get(ContactApiService.ADDRESS_POINT, { contactId, ...query }).pipe(
      map((data: any[]) => data.map(item => ContactAddressModel.fromJson(item))),
      map(
        data =>
          new PaginationAdapter(ContactAddressModel, {
            items: data,
            meta: {},
          })
      )
    );
  }
}
