import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetabaseTableComponent } from './metabase-table.component';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MetabaseLinkModule } from '../metabase-link/metabase-link.module';
import { MetabaseTableColSettingDirective } from './matabase-table-col-setting.directive';
const components = [MatTableModule, MatTooltipModule, RouterModule, MetabaseLinkModule];
@NgModule({
  declarations: [MetabaseTableComponent, MetabaseTableColSettingDirective],
  imports: [CommonModule, ...components],
  exports: [MetabaseTableComponent],
})
export class MetabaseTableModule {}
