import { IAssetRegister } from './asset-register.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IProfitCentres } from './profit-centres.interface';
import { ITimestamp } from './timestamp.interface';

export interface IAssetDepreciationAssetItem extends Partial<IAssetRegister> {
  uuid: string;
  assetDepreciationId: number;
}

export interface IAssetDepreciationPostingItem {
  id: number;
  depreciationId?: number;
  accountId?: number;
  account?: Partial<ILedgerAccount>;
  profitCenter?: Partial<IProfitCentres>;
  profitCenterId?: number;
  description: string;
  debit: number;
  credit: number;
  amount: number;
  currency: string;
  currencyRate: number;
}

export interface IAssetDepreciation {
  id: number;
  code: string;
  depreciationDate: string;
  description: string;
  amount: number;
  assets: IAssetDepreciationAssetItem[];
  postings: IAssetDepreciationPostingItem[];
}
