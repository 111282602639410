
import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentModel } from '@shared/data-access/models';

@Injectable({
    providedIn: 'root'
})
export class DocumentApiService {

    constructor(private apiService: ApiService) {
    }

    static DOCUMENT_SERVICE = 'contracts';
    static DOCUMENT_UPLOAD = 'contracts/upload';
    static DOCUMENT_UPDATE_URL = 'contracts/:id';
    static ADD_ATTACHMENT = 'contracts/:id/attachment';
    static DELETE_ATTACHMENT = 'contracts/:contractId/attachment/:attachmentId';
    static START = 'contracts/:id/start';
    static DOCUMENT_PREVIEW = 'contracts/preview/:id/:type';
    static DOCUMENT_PREVIEW_ORIGIN = 'contracts/preview/:id/pdf/e-signing/origin';
    static ABORT = 'contracts/:id/abort';
    static AUDIT_TRAIL = 'contracts-audit-trails/:id';
    static FORCE_COMPLETE = 'contracts/:id/manual-complete';
    static CONTRACTS_SETTINGS = 'contracts-settings';
    static CONTRACTS_SHARE = 'contracts/:id/share';
    static RESEND_CONTRACT = 'contracts/:contractId/resend-invitation';
    static CONTRACT_AUDIT = 'contracts-audit-trails/manual-audit';
    static CONTRACT_REMINDER_OF_CONTRACT = 'contracts-reminders/contract/:contractId';
    static CONTRACT_REMINDER = 'contracts-reminders';
    static CONTRACT_REMINDER_ID = 'contracts-reminders/:id';


    search(query = {}, option?: IApiOption): Observable<PaginationAdapter<DocumentModel>> {
        return this.apiService
                   .get(DocumentApiService.DOCUMENT_SERVICE, query, option)
                   .pipe(map(data => new PaginationAdapter(DocumentModel,data)));
    }

    // getAuditTrail(documentId: string): Observable<AuditTrailModel[]> {
    //     return this.apiService
    //                .get(DocumentApiService.AUDIT_TRAIL, {id: documentId})
    //                .pipe(map(res => res.map(v => AuditTrailModel.fromJson(v) as AuditTrailModel)));
    // }

    findById(documentId: string): Observable<DocumentModel> {
        return this.apiService
                   .get(DocumentApiService.DOCUMENT_SERVICE + '/' + documentId + '/*')
                   .pipe(map(res => DocumentModel.fromJson(res) as DocumentModel));
    }

    // update(data): Observable<any> {
    //     return this.apiService.patch(DocumentApiService.DOCUMENT_UPDATE_URL, data, {
    //         excludeFields: ['fields', 'participants', 'metadata', 'items', 'content']
    //     });
    // }

    // addAttachment(data): Observable<any> {
    //     return this.apiService.post(DocumentApiService.ADD_ATTACHMENT, data);
    // }

    // create(data): Observable<DocumentModel> {
    //     return this.apiService
    //                .post(DocumentApiService.DOCUMENT_SERVICE, data,
    //                    {excludeFields: ['fields', 'participants', 'metadata', 'items', 'content']})
    //                .pipe(
    //                    map((res: DocumentInterface) => DocumentModel.fromJson(res))
    //                );
    // }

    // upload(data): Observable<DocumentModel> {
    //     const newHeader = this.apiService.headers.delete('Content-Type');
    //     return this.apiService
    //                .post(DocumentApiService.DOCUMENT_UPLOAD, data,
    //                    {
    //                        excludeFields: ['fields', 'participants', 'metadata', 'items', 'content'],
    //                        requestOptions: {
    //                            headers: newHeader
    //                        }
    //                    })
    //                .pipe(
    //                    map((res: DocumentInterface) => DocumentModel.fromJson(res))
    //                );
    // }

    // start(data): Observable<any> {
    //     return this.apiService.post(DocumentApiService.START, data);
    // }

    // previewDocument(data: { id?: string; type?: 'pdf' | 'html'; }, options: ApiOptionInterface = {}): Observable<any> {
    //     return this.apiService.get(DocumentApiService.DOCUMENT_PREVIEW, data, {
    //         ...options,
    //         pretreatmentResponse: false,
    //         requestOptions: {responseType: data.type === 'pdf' ? 'arraybuffer' : 'text'}
    //     });
    // }

    // previewPdfOrigin(data: { id?: string; }, options: ApiOptionInterface = {}): Observable<any> {
    //     return this.apiService.get(DocumentApiService.DOCUMENT_PREVIEW_ORIGIN, data, {
    //         ...options,
    //         pretreatmentResponse: false,
    //         requestOptions: {responseType: 'arraybuffer'}
    //     });
    // }

    // deleteAttachment(data): Observable<any> {
    //     return this.apiService.delete(DocumentApiService.DELETE_ATTACHMENT, data);
    // }

    // abort(data: { id: string; }) {
    //     return this.apiService.post(DocumentApiService.ABORT, data);
    // }

    // forceComplete(data: { id: string, reason: string; }): Observable<any> {
    //     return this.apiService.post(DocumentApiService.FORCE_COMPLETE, data);
    // }

    // createOrUpdateSetting(data: any): Observable<any> {
    //     return this.apiService.post(DocumentApiService.CONTRACTS_SETTINGS, data);
    // }

    // getSettingByKey(keyName: string): Observable<any> {
    //     return this.apiService.get(DocumentApiService.CONTRACTS_SETTINGS + `/${keyName}`, {}, {
    //         exposeHeaders: {
    //             [AppConstant.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['404']
    //         }
    //     });
    // }

    // getSettingFromJson(): Observable<any> {
    //     return this.apiService.get(DocumentApiService.CONTRACTS_SETTINGS + `/system-field-mapping.json`).pipe(
    //         map(data => SettingModel.fromJson(data))
    //     );
    // }

    // share(data: {
    //     id: string, users: {
    //         email: string;
    //         name: string;
    //         role: string; // view
    //         metadata: {
    //             emailSubject: string;
    //             emailBody: string;
    //         };
    //     }[];
    // }): Observable<any> {
    //     return this.apiService.post(DocumentApiService.CONTRACTS_SHARE, data);
    // }

    // resend(data: { contractId: number; participantId: number; emailSubject?: string; emailBody?: string; }): Observable<any> {
    //     return this.apiService.post(DocumentApiService.RESEND_CONTRACT, data);
    // }

    // manualAudit(data) {
    //     return this.apiService.post(DocumentApiService.CONTRACT_AUDIT, data);
    // }

    // getReminders(contractId: number | string) {
    //     return this.apiService.get(DocumentApiService.CONTRACT_REMINDER_OF_CONTRACT, {
    //         contractId
    //     }).pipe(
    //         map((data: any[]) => data.map(item => ContractGeneralReminderModel.fromJson(item)))
    //     );
    // }

    // addReminder(reminder: any) {
    //     return this.apiService.post(DocumentApiService.CONTRACT_REMINDER, reminder).pipe(
    //         map(data => ContractGeneralReminderModel.fromJson(data))
    //     );
    // }

    // updateReminder(reminderId: string | number, info: any) {
    //     return this.apiService.patch(DocumentApiService.CONTRACT_REMINDER_ID, {
    //         id: reminderId,
    //         ...info
    //     });
    // }

    // deleteReminder(reminderId: string | number) {
    //     return this.apiService.delete(DocumentApiService.CONTRACT_REMINDER_ID, {
    //         id: reminderId
    //     });
    // }
}
