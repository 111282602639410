export enum EBusinessDirector {
  BUSINESS_DIRECTOR = 'businessDirector',
  HEAD_HI = 'headHI',

  EXTERNAL = 'External',

  CLOSINGAGENT = 'ClosingAgent',
  INTERNAL = 'Internal',
  BONUS = 'Bonus',
  TEAMLEADER = 'TeamLeader',
  TIER1 = 'Tier1',
  TIER2 = 'Tier2',
  RECRUITER = 'Recruiter',
  MULTITIER = 'MultiTier',
  PDREFERRAL = 'PDReferral',
  INTERNALREFFERAL = 'InternalRefferal',
  EXTERNALREFFERAL = 'ExternalRefferal',
  PDOVERRIDE = 'PDOverride',
  PDMANAGEMENT = 'PDManagement',
  OTHMANAGEMENT = 'OTHManagement',
  OVERRIDING = 'Overriding',
  POOL = 'Pool',
  BD = 'BD',
  MF = 'MF',
}
