import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IExternalCobrokeReferral } from '../interfaces';
import { ReferralExternalModel } from './referral-external.model';

export class ExternalCobrokeReferralModel extends BaseModel implements IExternalCobrokeReferral {
  @Expose()
  id!: number;

  @Expose()
  agentId!: number;

  @Expose()
  @Type(() => ReferralExternalModel)
  agent!: ReferralExternalModel;

  @Expose()
  formula!: string;

  @Expose()
  fee!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;
}
