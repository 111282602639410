import { BaseModel } from '@red/data-access';
import {  Expose } from 'class-transformer';
import { IDiscipline } from '../interfaces';


export class DisciplineModel extends BaseModel implements IDiscipline {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  type!: string;

  @Expose()
  name!: string;

  // @Expose()
  // status!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;
}
