import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { EcddActionDto, EcddRejectedDto, EcddUpdateRemarkDto } from '@shared/data-access/dto';
import { EcddModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcddApiService {
  static ROOT_POINT = 'transaction/ecdds';
  static REJECT_POINT = 'transaction/ecdds/reject';
  static APPROVE_POINT = 'transaction/ecdds/action';
  static VERIFICATION_POINT = 'transaction/ecdds/verifications';
  static DOWNLOAD_PDF_POINT = 'transaction/ecdds/:id/download-pdf';
  static FOR_RESALE_POINT = 'transaction/ecdds/for-resale';

  constructor(private apiService: ApiService) { }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<EcddModel>> {
    return this.apiService.get(EcddApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } }).pipe(map(data => new PaginationAdapter(EcddModel, data)));
  }

  searchVerification(query = {}, option?: IApiOption): Observable<PaginationAdapter<EcddModel>> {
    return this.apiService.get(EcddApiService.VERIFICATION_POINT, query, { ...option, removeEmpty: { enable: true } }).pipe(map(data => new PaginationAdapter(EcddModel, data)));
  }

  approve(payload: EcddActionDto) {
    return this.apiService.post(EcddApiService.APPROVE_POINT, payload);
  }

  reject(payload: EcddRejectedDto) {
    return this.apiService.post(EcddApiService.REJECT_POINT, payload);
  }

  viewPdf(id: number): Observable<any> {
    return this.apiService.get(
      EcddApiService.DOWNLOAD_PDF_POINT,
      { id },
      {
        pretreatmentResponse: false,
        requestOptions: { responseType: 'arraybuffer' },
      }
    );
  }

  searchForResale(query = {}, option?: IApiOption) {
    return this.apiService.get(EcddApiService.FOR_RESALE_POINT, query, option).pipe(map(data => new PaginationAdapter(EcddModel, data)));
  }

  updateRemarks(id: number, data: EcddUpdateRemarkDto): Observable<any> {
    return this.apiService.patch(EcddApiService.ROOT_POINT + '/' + id + '/remarks', data);
  }
}
