export enum EDebitNoteStatus {
  CANCELLED = 'CANCELLED',
  ABORTED = 'ABORTED',
  PARTIAL = 'PARTIAL',
}

export enum EDebitNoteDetailStatus {
  // UnPaid = 'UnPaid',
  View = 'View',
  PartPaid = 'PartPaid',
  FullyPaid = 'FullyPaid',
  Cancelled = 'Cancelled',
  // Confirmed = 'Confirmed',
  // Withhold = 'Withhold',
  // PaymentInProgress = 'PaymentInProgress',
}
