import { IPersonalParticular } from './personal-particular.interface';
import { ITimestamp } from './timestamp.interface';
import { EIr8aAmountStatus } from '../enums';

export interface IIr8aAmount extends ITimestamp {
  id: number;
  amount: number;
  totalAmount: number;
  description: string;
  effectiveDate: string;
  salespersonId: number;
  salesperson: IPersonalParticular;
  status: EIr8aAmountStatus;
}

export interface IIr8aAmountDto {
  amount: number;
  description: string;
  effectiveDate: string;
  salespersonId: number;
}
