import { BaseModel, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ChequeBookCreateDto extends BaseModel {
  @Expose()
  bankAccountId!: number;

  @Expose()
  chequeNumberFrom!: string;

  @Expose()
  chequeNumberTo!: string;
}

export class ChequeUpdateDto extends BaseModel {
  @Expose()
  name!: string;
}
