import { Directive, ElementRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MetabaseColumnDef } from '../metabase-table.util';
import { MetadataColDataType } from '../models/metabase.type';

@Directive({
  selector: 'mat-cell[redMetabaseTableColSetting], mat-header-cell[redMetabaseTableColSetting]',
})
export class MetabaseTableColSettingDirective implements OnChanges {
  @Input('redMetabaseTableColSetting') get columnDef(): MetabaseColumnDef {
    return this._columnDef;
  }
  set columnDef(val: MetabaseColumnDef) {
    this._columnDef = val;
  }
  private _columnDef!: MetabaseColumnDef;
  @HostBinding('class.cell-align-left') get alginLeft() {
    return this._textAlign === 'left';
  }
  @HostBinding('class.cell-align-right') get alginRight() {
    return this._textAlign === 'right';
  }
  @HostBinding('class.cell-align-center') get alginCenter() {
    return this._textAlign === 'center';
  }
  private _textAlign?: 'left' | 'right' | 'center';
  constructor(private _el: ElementRef) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columnDef'] && changes['columnDef'].currentValue !== changes['columnDef'].previousValue) {
      this.renderTextAlign();
    }
  }

  renderTextAlign(): void {
    switch (this.columnDef.colData.baseType) {
      case MetadataColDataType.FLOAT:
      case MetadataColDataType.INTEGER:
        this._textAlign = 'right';
        break;
      default:
        this._textAlign = 'left';
    }
  }
}
