import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { format } from 'date-fns';
import { isMoment } from 'moment';
import { ExceljsParameter, IExceljsParameterControlType } from '../../models';
import { ExceljsParameterFieldBase } from '../exceljs-parameter-field-base.directive';

@Component({
  selector: 'red-exceljs-parameter-date',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, MatFormFieldModule, MatInputModule],
  templateUrl: './exceljs-parameter-date.component.html',
  styleUrls: ['./exceljs-parameter-date.component.scss'],
})
export class ExceljsParameterDateComponent extends ExceljsParameterFieldBase {
  toDataDowncastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    if (isMoment(val)) {
      return val.format('YYYY-MM-DD');
    }
    if (val instanceof Date) {
      return format(val, 'yyyy-MM-dd');
    }
    return val;
  }
  toDataUpcastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return val;
  }

  get controlType(): IExceljsParameterControlType {
    return 'date/single';
  }
}
