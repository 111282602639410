import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EEcddTransactionType } from '../enums';
import { IEcddCheckList } from '../interfaces';
import { EcddOptionGroupModel } from './ecdd-option-group.model';

export class EcddChecklistModel extends BaseModel implements IEcddCheckList {
  @Expose()
  id!: number;

  @Expose()
  type!: EEcddTransactionType;

  @Expose()
  isDefault!: boolean;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Default([])
  @Type(() => EcddOptionGroupModel)
  checklistOptionGroups!: EcddOptionGroupModel[];
}
