import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ProjectTeamCreateDto, ProjectTeamMemberCreateDto, ProjectTeamMemberUpdateDto, ProjectTeamUpdateDto } from '@shared/data-access/dto';
import { IProjectConsultant, IProjectConsultantMember } from '@shared/data-access/interfaces';
import { ProjectTeamMemberModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectTeamMemberApiService {
  static ROOT_POINT = 'transaction/team-members/portal';
  static LEADERS_POINT = 'transaction/team-members/portal/leaders';

  constructor(private apiService: ApiService) { }

  create(data: ProjectTeamMemberCreateDto): Observable<ProjectTeamMemberModel> {
    return this.apiService
      .post(`${ProjectTeamMemberApiService.ROOT_POINT}`, data)
      .pipe(map((res: IProjectConsultantMember) => ProjectTeamMemberModel.fromJson(res) as ProjectTeamMemberModel));
  }
  get(itemId: number): Observable<ProjectTeamMemberModel> {
    return this.apiService
      .get(ProjectTeamMemberApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => ProjectTeamMemberModel.fromJson(res) as ProjectTeamMemberModel));
  }
  update(id: number, data: ProjectTeamMemberUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectTeamMemberApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTeamMemberModel>> {
    return this.apiService.get(ProjectTeamMemberApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } }).pipe(map(data => new PaginationAdapter(ProjectTeamMemberModel, data)));
  }

  getTeamLeader(teamId: number, option?: IApiOption): Observable<ProjectTeamMemberModel> {
    const query = { teamIds: [teamId], isLeader: true };
    return this.apiService.get(ProjectTeamMemberApiService.ROOT_POINT, query, option).pipe(
      map(data => new PaginationAdapter(ProjectTeamMemberModel, data)),
      map(data => data.results[0])
    );
  }

  countMember(teamId: number, hasLeader = false): Observable<{ teamId: number; total: number }> {
    return this.apiService.get(ProjectTeamMemberApiService.ROOT_POINT + '/total/' + teamId, { hasLeader });
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectTeamMemberApiService.ROOT_POINT, { ids }, option);
  }

  getTeamLeaders(query = {}, option?: IApiOption): Observable<ProjectTeamMemberModel[]> {
    return this.apiService
      .get(ProjectTeamMemberApiService.LEADERS_POINT, query, option)
      .pipe(map(res => res.map((data: any) => ProjectTeamMemberModel.fromJson(data) as ProjectTeamMemberModel)));
  }
}
