import { BuyerPropertyTypeRecord, BuyerTypeRecord } from '../types';
import { EBuyerPropertyType, EBuyerType } from '../enums';

export const BUYER_TYPE_OPTIONS: BuyerTypeRecord = {
  [EBuyerType.Individual]: {
    id: EBuyerType.Individual,
    value: EBuyerType.Individual,
    viewValue: 'Individual',
  },
  [EBuyerType.Company]: {
    id: EBuyerType.Company,
    value: EBuyerType.Company,
    viewValue: 'Company',
  },
  [EBuyerType.Trust]: {
    id: EBuyerType.Trust,
    value: EBuyerType.Trust,
    viewValue: 'Trust',
  },
};

export const BUYER_PROPERTY_TYPE_OPTIONS: BuyerPropertyTypeRecord = {
  [EBuyerPropertyType.ResidentialCondominiumApartment]: {
    id: EBuyerPropertyType.ResidentialCondominiumApartment,
    value: EBuyerPropertyType.ResidentialCondominiumApartment,
    viewValue: 'Residential - Condominium / Apartment',
  },
  [EBuyerPropertyType.ResidentialExecutiveCondominium]: {
    id: EBuyerPropertyType.ResidentialExecutiveCondominium,
    value: EBuyerPropertyType.ResidentialExecutiveCondominium,
    viewValue: 'Residential - Executive Condominium',
  },
  [EBuyerPropertyType.LandedTerraceHouse]: {
    id: EBuyerPropertyType.LandedTerraceHouse,
    value: EBuyerPropertyType.LandedTerraceHouse,
    viewValue: 'Landed - Terrace House',
  },
  [EBuyerPropertyType.LandedSemiDetachedHouse]: {
    id: EBuyerPropertyType.LandedSemiDetachedHouse,
    value: EBuyerPropertyType.LandedSemiDetachedHouse,
    viewValue: 'Landed - Semi-Detached House',
  },
  [EBuyerPropertyType.LandedDetachedHouse]: {
    id: EBuyerPropertyType.LandedDetachedHouse,
    value: EBuyerPropertyType.LandedDetachedHouse,
    viewValue: 'Landed - Detached House',
  },
  [EBuyerPropertyType.LandedStrataLanded]: {
    id: EBuyerPropertyType.LandedStrataLanded,
    value: EBuyerPropertyType.LandedStrataLanded,
    viewValue: 'Landed - Strata Landed',
  },
  [EBuyerPropertyType.Others]: {
    id: EBuyerPropertyType.Others,
    value: EBuyerPropertyType.Others,
    viewValue: 'Others',
  },
};
