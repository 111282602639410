export enum MetadataColDataType {
  INTEGER = 'type/Integer',
  FLOAT = 'type/Float',
  TEXT = 'type/Text',
  DATE_TIME = 'type/DateTime',
  DATETIME_WITH_ZONE_OFFSET = 'type/DateTimeWithZoneOffset',
}

export enum MetabaseTimeEnabled {
  MINUTES = 'minutes',
}

export enum MetabaseTimeStyle {
  HHmm = 'HH:mm',
  hmmA = 'h:mm A',
}
export enum MetabaseNumberSeparator {
  DOT_COMMA = '.,',
  COMMA_DOT = ',.',
  SPACE_DOT = ' .',
}
export enum MetabaseViewAs {
  LINK = 'link',
  EMAIL_LINK = 'email_link',
}
