import { PromotionMatrixStatus } from "../types";
import { ICommissionMatrix } from "./commission-matrix.interface";

export interface IPromotionMatrix {
    id: number;
    name: string;
    maxCommission: number;
    splitMatrixIds: number[];
    splitMatrices: ICommissionMatrix[];
    status: PromotionMatrixStatus;
}
