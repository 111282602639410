import { EUserAccountStatus } from '../enums';
import { UserAccountStatusRecord } from '../types';

export const USER_ACCOUNT_STATUS_OPTIONS: UserAccountStatusRecord = {
  [EUserAccountStatus.enabled]: {
    id: EUserAccountStatus.enabled,
    value: EUserAccountStatus.enabled,
    viewValue: 'Enabled',
    viewClass: 'status-box-success',
  },
  [EUserAccountStatus.disabled]: {
    id: EUserAccountStatus.disabled,
    value: EUserAccountStatus.disabled,
    viewValue: 'Disabled',
    viewClass: 'status-box-default',
  },
};
