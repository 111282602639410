import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ProjectConsultantCreateDto, ProjectConsultantUpdateDto } from '@shared/data-access/dto';
import { IProjectConsultant } from '@shared/data-access/interfaces';
import { ProjectConsultantModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectConsultantApiService {
  static ROOT_POINT = 'transaction/teams/portal';

  constructor(private apiService: ApiService) {}

  create(data: ProjectConsultantCreateDto): Observable<ProjectConsultantModel> {
    return this.apiService
      .post(`${ProjectConsultantApiService.ROOT_POINT}`, data)
      .pipe(map((res: IProjectConsultant) => ProjectConsultantModel.fromJson(res) as ProjectConsultantModel));
  }
  get(itemId: number): Observable<ProjectConsultantModel> {
    return this.apiService
      .get(ProjectConsultantApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => ProjectConsultantModel.fromJson(res) as ProjectConsultantModel));
  }
  update(id: number, data: ProjectConsultantUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectConsultantApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectConsultantModel>> {
    return this.apiService
      .get(ProjectConsultantApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectConsultantModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectConsultantApiService.ROOT_POINT, { ids }, option);
  }
}
