// core
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// share
import { IRecruitment } from '@shared/data-access/interfaces';
import { RecruitmentModel } from '@shared/data-access/models';
import { PaginationAdapter } from '@red/data-access';
import { ApiService, IApiOption } from '@red/api';
import {
  RecruitmentApplicationCreateDto,
  RecruitmentCheckDuplicatedDto,
  RecruitmentCreateDto,
  RecruitmentUpdateDto,
  RecruitmentActionDto,
} from '@shared/data-access/dto';

@Injectable({ providedIn: 'root' })
export class RecruitmentApiService {
  static ROOT_POINT = 'hrm/recruitments/portal';
  static PUBLIC_POINT = 'hrm/recruitments/public';

  constructor(private _apiService: ApiService) {}

  create(data: RecruitmentCreateDto): Observable<RecruitmentModel> {
    return this._apiService.post(RecruitmentApiService.ROOT_POINT, data).pipe(map((res: IRecruitment) => RecruitmentModel.fromJson(res)));
  }

  get(id: number): Observable<RecruitmentModel> {
    return this._apiService.get([RecruitmentApiService.ROOT_POINT, id].join('/')).pipe(map(res => RecruitmentModel.fromJson(res)));
  }

  getPublic(id: number): Observable<RecruitmentModel> {
    return this._apiService.get([RecruitmentApiService.PUBLIC_POINT, id].join('/')).pipe(map(res => RecruitmentModel.fromJson(res)));
  }

  update(id: number, data: RecruitmentUpdateDto): Observable<unknown> {
    return this._apiService.patch([RecruitmentApiService.ROOT_POINT, id].join('/'), data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<RecruitmentModel>> {
    return this._apiService.get(RecruitmentApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(RecruitmentModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<unknown> {
    return this._apiService.delete(RecruitmentApiService.ROOT_POINT, { ids }, option);
  }

  withdraw(dto: RecruitmentActionDto[], option?: IApiOption): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.ROOT_POINT, 'withdraw'].join('/'), { recruitments: dto }, option);
  }

  applicantSubmit(id: number, dto: RecruitmentApplicationCreateDto): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.PUBLIC_POINT, id, 'submit'].join('/'), dto, { excludeFields: [''] });
  }

  checkDuplicated(dto: RecruitmentCheckDuplicatedDto): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.PUBLIC_POINT, 'check-duplicated'].join('/'), dto);
  }

  searchTeamRecruitment(query = {}, option?: IApiOption): Observable<PaginationAdapter<RecruitmentModel>> {
    return this._apiService
      .get([RecruitmentApiService.ROOT_POINT, 'team-recruitment'].join('/'), query, option)
      .pipe(map(data => new PaginationAdapter(RecruitmentModel, data)));
  }

  getTeamRecruitment(id: number): Observable<RecruitmentModel> {
    return this._apiService.get([RecruitmentApiService.ROOT_POINT, 'team-recruitment', id].join('/')).pipe(map(res => RecruitmentModel.fromJson(res)));
  }
}
