import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { BaseModel, PaginationAdapter } from '@red/data-access';
import { ContactAddressDto, ContactPersonDto } from '@shared/data-access/dto';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { ContactAddressModel, ContactCustomerAndSupplierModel, ContactPersonModel } from '@shared/data-access/models';
import { ClassConstructor, plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactBaseApiService {
  static ROOT_POINT = 'finance/contacts';
  static PERSON_POINT = 'finance/contacts/:contactId/persons';
  static ADDRESS_POINT = 'finance/contacts/:contactId/addresses';
  static PERSON_DETAIL_POINT = 'finance/contacts/:contactId/persons/:contactPersonId';
  static ADDRESS_DETAIL_POINT = 'finance/contacts/:contactId/addresses/:addressId';

  static SEARCH_POINT = 'finance/contacts/commons';

  constructor(private apiService: ApiService) {}

  search<T extends BaseModel>(modelCtor: ClassConstructor<T>, query = {}, option?: IApiOption): Observable<PaginationAdapter<T>> {
    return this.apiService.get(ContactBaseApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(modelCtor, data)));
  }

  create<T extends BaseModel, V>(modelCtor: ClassConstructor<T>, data: Partial<V>): Observable<T> {
    return this.apiService.post(ContactBaseApiService.ROOT_POINT, data).pipe(map(res => plainToInstance(modelCtor, res) as T));
  }

  update<T>(id: number, data: Partial<T>): Observable<any> {
    return this.apiService.patch(ContactBaseApiService.ROOT_POINT + '/' + id, data);
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(ContactBaseApiService.ROOT_POINT + '/' + id, data, option);
  }

  get<T extends BaseModel>(modelCtor: ClassConstructor<T>, id: number, query = {}): Observable<T> {
    return this.apiService.get(ContactBaseApiService.ROOT_POINT + '/' + id, query).pipe(map(res => plainToInstance(modelCtor, res) as T));
  }

  getContactAddresses(query: IQuerySearch & { contactId: number }): Observable<ContactAddressModel[]> {
    return this.apiService.get(ContactBaseApiService.ADDRESS_POINT, query).pipe(map((data: any[]) => data.map(item => ContactAddressModel.fromJson(item))));
  }

  getContactAddressesById(query: IQuerySearch & { contactId: number; addressId: number }): Observable<ContactAddressModel> {
    return this.apiService.get(ContactBaseApiService.ADDRESS_DETAIL_POINT, query).pipe(map(data => ContactAddressModel.fromJson(data)));
  }

  getContactPersion(query: IQuerySearch & { contactId: number }): Observable<ContactPersonModel[]> {
    return this.apiService.get(ContactBaseApiService.PERSON_POINT, query).pipe(map((data: any[]) => data.map(item => ContactPersonModel.fromJson(item))));
  }

  getContactPersionById(query: IQuerySearch & { contactId: number; contactPersonId: number }): Observable<ContactPersonModel> {
    return this.apiService.get(ContactBaseApiService.PERSON_POINT, query).pipe(map(data => ContactPersonModel.fromJson(data)));
  }

  getById(contactId: number, query = {}, option?: IApiOption): Observable<ContactCustomerAndSupplierModel> {
    return this.apiService
      .get(ContactBaseApiService.ROOT_POINT + '/' + contactId, query, option)
      .pipe(map(res => ContactCustomerAndSupplierModel.fromJson(res) as ContactCustomerAndSupplierModel));
  }

  updateContactAddress(data: { contactId: number; items: ContactAddressDto[] }): Observable<unknown> {
    return this.apiService.patch(ContactBaseApiService.ADDRESS_POINT, data, { excludeFields: [''] });
  }

  updateContactPerson(data: { contactId: number; items: ContactPersonDto[] }): Observable<ContactCustomerAndSupplierModel> {
    return this.apiService.patch(ContactBaseApiService.PERSON_POINT, data, { excludeFields: [''] });
  }

  // New Endpoint
  commonList<T extends BaseModel>(modelCtor: ClassConstructor<T>, query = {}, option?: IApiOption): Observable<PaginationAdapter<T>> {
    return this.apiService.get(ContactBaseApiService.SEARCH_POINT, query, option).pipe(map(data => new PaginationAdapter(modelCtor, data)));
  }
}
