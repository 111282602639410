import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ProfitSharingCreateDto, ProfitSharingUpdateDto } from '@shared/data-access/dto';
import { IProfitSharing } from '@shared/data-access/interfaces';
import { ProfitSharingModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfitSharingApiService {
  static PROFIT_SHARING = 'hrm/profit-sharings';

  constructor(private apiService: ApiService) {}

  create(data: ProfitSharingCreateDto): Observable<ProfitSharingModel> {
    return this.apiService
      .post(`${ProfitSharingApiService.PROFIT_SHARING}`, data)
      .pipe(map((res: IProfitSharing) => ProfitSharingModel.fromJson(res) as ProfitSharingModel));
  }
  get(itemId: number): Observable<ProfitSharingModel> {
    return this.apiService.get(ProfitSharingApiService.PROFIT_SHARING + '/' + itemId).pipe(map(res => ProfitSharingModel.fromJson(res) as ProfitSharingModel));
  }
  update(id: number, data: ProfitSharingUpdateDto): Observable<any> {
    return this.apiService.patch(ProfitSharingApiService.PROFIT_SHARING + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProfitSharingModel>> {
    return this.apiService.get(ProfitSharingApiService.PROFIT_SHARING, query, option).pipe(map(data => new PaginationAdapter(ProfitSharingModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProfitSharingApiService.PROFIT_SHARING, { ids }, option);
  }
}
