import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class ProjectReportCreateDto extends BaseModel {

  @Expose()
  name!: string;
}

export class ProjectReportUpdateDto extends BaseModel {

  @Expose()
  name!: string;
}
