import { IPhoneNumber } from '@red/data-access';
export interface IContact {
  id: number;
  name: string;
  phone: IPhoneNumber;
  mobile: IPhoneNumber;
  fax: string;
  email: string;
  designation: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  code: string;
}
