import { ISplitMatrix } from './split-matrix.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { ICategory } from './category.interface';
import { ISplitPart, IProfitSplitPart } from './split-part.interface';
import { EBlankFormInternalAgentType } from '../enums';

export interface IBlankFormInternalCoBroke extends ISplitMatrix {
  agent: IPersonalParticular;
  agentId: number;
  type: EBlankFormInternalAgentType;
  attrs: any;
  blankFormId: number;
  id: number;
  note: any;
  parties: ISplitPart[];
  splitMatrixId: number;
  tiers: ISplitPart[];
  createdAt: string;
  updatedAt: string;
  isDefault: boolean;
  profitTiers: IProfitSplitPart[];
  receivedValue: number;
  categoryId: number;
  category: ICategory;
  grouping: string;
  companyAmount: number;
}
