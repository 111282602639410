import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { IApiOption } from '../../api/interfaces';
import { IMediaStorageConfig } from './media-storage.constant';
import { RED_MEDIA_STORAGE } from './token';
export interface UploadLinkPayload {
  type?: string;
  name?: string;
  acl?: string;
}
export interface PublicLinkPayload {
  keyName: string;
}
export interface FilePayloadRequest {
  file: File;
  isPublic?: boolean;
  fields: Record<string, any>;
  url: string;
}
@Injectable()
export class MediaStorageService {
  static STORAGE_UPLOAD_LINK = 'storage/upload/:type';
  static STORAGE_PUBLIC_LINK = 'storage/link';

  constructor(protected apiService: ApiService, protected httpClient: HttpClient, @Inject(RED_MEDIA_STORAGE) protected config: IMediaStorageConfig) {}

  getUploadLink<D>(data: UploadLinkPayload & Record<string, any>): Observable<D> {
    return this.apiService.get(this.config.uploadLink ?? MediaStorageService.STORAGE_UPLOAD_LINK, data);
  }

  getPublicLink<T extends PublicLinkPayload>(data: T, requestOption?: IApiOption) {
    return this.apiService.get(this.config.publicLink ?? MediaStorageService.STORAGE_PUBLIC_LINK, data, requestOption);
  }

  uploadFile<T extends FilePayloadRequest>(data: T): Observable<{ keyName: string }> {
    if (this.config.storageDrive === 'azure') {
      return this.uploadAzureFile(data).pipe(map(() => ({ keyName: data.fields['key'] })));
    } else if (this.config.storageDrive === 's3') {
      return this.uploadS3File(data).pipe(map(() => ({ keyName: data.fields['key'] })));
    }
    return throwError(() => new Error(`storageDrive ${this.config.storageDrive} is not supported`));
  }

  uploadS3File<T extends FilePayloadRequest>(data: T) {
    const input = new FormData();
    const fields = new Map(Object.entries(data.fields || {}));
    if (data.isPublic) {
      fields.set('acl', 'public-read');
    }

    for (const [key, value] of fields) {
      input.append(key, value);
    }

    input.append('file', data.file);
    return this.httpClient.post(data.url, input);
  }

  uploadAzureFile<T extends FilePayloadRequest>(data: T) {
    const fields = new Map(Object.entries(data.fields || {}));
    const header = new HttpHeaders({
      'x-ms-blob-type': fields.get('x-ms-blob-type'),
      'Content-Type': fields.get('Content-Type') ?? data.file.type,
    });
    console.log('uploadAzureFile -->', data.url, data.file);
    return this.httpClient.put(data.url, data.file, { headers: header });
  }
}
