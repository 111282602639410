import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { EcddCustomerSignatureDto, EcddDto } from '@shared/data-access/dto';
import { EcddChecklistModel, EcddModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';
import { IIngeniqueApiSearchAmlQuery, IIngeniqueApiSearchAmlResponse } from '@shared/components/ingenique-api';

@Injectable({
  providedIn: 'root',
})
export class EcddApiService {
  static ROOT_POINT = 'transaction/ecdds/portal';
  static CHECKLIST_POINT = 'transaction/checklists';
  static PUBLIC_ROOT_POINT = 'transaction/ecdds/public';
  static DOWNLOAD_PDF_POINT = 'transaction/ecdds';
  static SEARCH_AML_POINT = 'transaction/ecdds/aml/search';
  static FOR_RESALE_POINT = 'transaction/ecdds/portal/for-resale';

  constructor(private _apiService: ApiService) { }

  get(itemId: number) {
    return this._apiService.get(EcddApiService.ROOT_POINT + '/' + itemId).pipe(map(res => EcddModel.fromJson(res)));
  }

  search(query = {}, option?: IApiOption) {
    return this._apiService.get(EcddApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } }).pipe(map(data => new PaginationAdapter(EcddModel, data)));
  }

  create(data: EcddDto) {
    return this._apiService.post(EcddApiService.ROOT_POINT, data).pipe(map(res => EcddModel.fromJson(res)));
  }

  update(id: number, data: EcddDto) {
    return this._apiService.patch(EcddApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  delete(id: number) {
    return this._apiService.delete(EcddApiService.ROOT_POINT + '/' + id);
  }

  submit(id: number) {
    return this._apiService.post(EcddApiService.ROOT_POINT + '/' + id + '/submit');
  }

  searchChecklist(query = {}, option?: IApiOption) {
    return this._apiService.get(EcddApiService.CHECKLIST_POINT, query, option).pipe(map(data => new PaginationAdapter(EcddChecklistModel, data)));
  }

  getChecklist(id: number) {
    return this._apiService.get(EcddApiService.CHECKLIST_POINT + '/' + id).pipe(map(res => EcddChecklistModel.fromJson(res)));
  }

  getPublic(id: number | string) {
    return this._apiService.get(EcddApiService.PUBLIC_ROOT_POINT + '/' + id).pipe(map(res => EcddModel.fromJson(res)));
  }

  updateCustomerSignature(id: number | string, data: EcddCustomerSignatureDto) {
    return this._apiService.post(EcddApiService.PUBLIC_ROOT_POINT + '/' + id + '/customer-signature', data);
  }

  downloadPdf(id: number) {
    return this._apiService.get(
      EcddApiService.DOWNLOAD_PDF_POINT + '/' + id + '/download-pdf',
      {},
      { pretreatmentResponse: false, requestOptions: { responseType: 'arraybuffer' } }
    );
  }

  downloadPublicPdf(id: number | string) {
    return this._apiService.get(
      EcddApiService.PUBLIC_ROOT_POINT + '/' + id + '/download-pdf',
      {},
      { pretreatmentResponse: false, requestOptions: { responseType: 'arraybuffer' } }
    );
  }

  searchAml(query: IIngeniqueApiSearchAmlQuery, option?: IApiOption): Observable<IIngeniqueApiSearchAmlResponse[]> {
    return this._apiService.post(EcddApiService.SEARCH_AML_POINT, query, option);
  }

  searchForResale(query = {}, option?: IApiOption) {
    return this._apiService.get(EcddApiService.FOR_RESALE_POINT, query, option).pipe(map(data => new PaginationAdapter(EcddModel, data)));
  }
}
