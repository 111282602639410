import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class UserGroupCreateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  @Type(() => String)
  code!: string;

  @Expose()
  description!: string;
}

export class UserGroupUpdateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  @Type(() => String)
  code!: string;

  @Expose()
  description!: string;
}
