import { JournalEntriesModel } from '../models';
import { JournalPostingModel } from '../models/journal-posting.model';
import { IBase } from './base-finance.interface';

export interface IJournalEntries extends IBase {
  code: string;
  name: string;
  reversal: string;
  journalDate?: string;
  scheduleEnable?: boolean;
  scheduleName?: string;
  scheduleType?: string;
  scheduleStartDate?: string;
  scheduleEndDate?: string;
  totalDebit?: number;
  totalCredit?: number;
  formatCode?: string;
  postings?: JournalPostingModel[];
  reversalJournal?: JournalEntriesModel;
  inClosedPeriod?: boolean;
}
