import { ICommissionSalespersonScheme } from './commission-salesperson-scheme.interface';
import { ICommissionScheme, IGrossCommissionEarnedDto, IRequiredCommSchemeDto, IRequiredTeamMemberDto } from './commission-scheme.interface';
import { IGrossCommissionEarnedConfig } from './gross-commission-earned-config.interface';

export interface ICommissionLeadershipScheme extends ICommissionScheme {
  directHeadcount: number;
  totalHeadcount: number;
  requiredTeamMember: {
    commissionSchemeId: number;
    commissionScheme: ICommissionSalespersonScheme;
    headcount: number;
    isActive: boolean;
  }[];
  requiredCommissionScheme: {
    commissionSchemeId: number;
    commissionScheme: ICommissionSalespersonScheme;
  }[];
  grossCommissionEarned: IGrossCommissionEarnedConfig;
}

export interface ICommLeadershipSchemeCreateDto {
  schemeTypeId: number;
  designation: string;
  code: string;
  isActive: boolean;
  autoApprove: boolean;
  sequence: number;
  directHeadcount: number;
  totalHeadcount: number;
  requiredCommissionScheme: IRequiredCommSchemeDto[];
  requiredTeamMember: IRequiredTeamMemberDto[];
  grossCommissionEarned: IGrossCommissionEarnedDto;
}

export interface ICommLeadershipSchemeUpdateDto {
  schemeTypeId: number;
  designation: string;
  code: string;
  isActive: boolean;
  autoApprove: boolean;
  sequence: number;
  directHeadcount: number;
  totalHeadcount: number;
  requiredCommissionScheme: IRequiredCommSchemeDto[];
  requiredTeamMember: IRequiredTeamMemberDto[];
  grossCommissionEarned: IGrossCommissionEarnedDto;
}
