import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IImportPayment, IImportPaymentColumn } from '../interfaces';

export class ImportPaymentModel extends BaseModel {
  @Expose()
  rowIndex!: number;

  @Expose()
  @Default(false)
  isHover!: boolean;

  @Expose()
  @Type(() => String)
  @Default('')
  paymentNumber!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  paymentDate!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  type!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  contactCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  payerName!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  chequeNumber!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemNo!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemType!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  description!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemAmount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  itemCreditAccount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  bankAccount!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  profitCentre!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  currencySign!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  currencyRate!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  glEntry!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  recordId!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  invoiceItemNo!: string;

  get isEmpty(): boolean {
    const checkingProperties: (keyof IImportPayment)[] = [
      'paymentNumber',
      'paymentDate',
      'type',
      'contactCode',
      'payerName',
      'chequeNumber',
      'itemNo',
      'itemType',
      'description',
      'itemAmount',
      'itemCreditAccount',
      'bankAccount',
      'profitCentre',
      'currencySign',
      'currencyRate',
      'glEntry',
      'recordId',
      'invoiceItemNo',
    ];

    return checkingProperties.every(c => {
      const value = this[c];
      return !value || value.trim() === '';
    });
  }
}

export class ImportPaymentColumnModel extends BaseModel implements IImportPaymentColumn {
  @Expose()
  id!: keyof IImportPayment;

  @Expose()
  label!: string;

  @Expose()
  index!: number;

  @Expose()
  @Default(false)
  required!: boolean;

  @Expose()
  @Default(300)
  maxLength!: number;

  @Expose()
  @Transform(({ obj }) => {
    return `${obj.label} [${obj.maxLength ?? 'NA'}]${obj.required ? '*' : ''}`;
  })
  title!: string;
}
