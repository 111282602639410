import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { UNIT_NO_OF_ROOM_OPTIONS } from '../data';
import { EUnitNoOfRoom } from '../enums';
import { IUnit, IUnitImportResponse } from '../interfaces';
import { OptionDescription } from '../types';
import { ProjectModel } from './project.model';
import { UpdatedByModel } from './updated-by.model';

export class UnitModel extends BaseModel implements IUnit {
  @Expose()
  id!: number;

  @Expose()
  projectId!: number;

  @Expose()
  @Type(() => ProjectModel)
  project!: ProjectModel;
  @Expose()
  unitNo!: string;

  @Expose()
  blkNo!: string;

  @Expose()
  unitType!: string;

  @Expose()
  noOfRoom!: EUnitNoOfRoom;

  @Expose()
  areaSqft!: number;

  @Expose()
  areaSqm!: number;

  @Expose()
  sellingPrice!: number;

  @Expose()
  psf!: string;

  @Expose()
  bookingFee!: number;

  @Expose()
  postalCode!: string;

  @Expose()
  status!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  rowNumber?: number; // for duplicated unit

  get noOfRoomInfo(): OptionDescription<EUnitNoOfRoom> | null {
    return this.noOfRoom ? UNIT_NO_OF_ROOM_OPTIONS[this.noOfRoom] : null;
  }
}

export class UnitImportErrorMetadataModel {
  @Expose()
  @Type(() => UnitModel)
  data!: UnitModel;

  @Expose()
  @Default('Duplicated Unit No')
  errorCode?: string;

  @Expose()
  rowNumber?: number;
}

export class UnitImportResponseModel extends BaseModel implements IUnitImportResponse {
  @Expose()
  status!: string;

  @Expose()
  numberOfImport!: number;

  @Expose()
  errors!: UnitImportErrorMetadataModel[];
}
