import { BaseModel, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
export class ECBInvoiceDto extends BaseModel {
  @Expose()
  source!: string;

  @Expose()
  sourceId!: number;

  @Expose()
  contactId!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  ecbInvoiceDate!: string;

  @Expose()
  invoiceNumber!: string;
}
