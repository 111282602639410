import { BaseModel, PhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { USER_ACCOUNT_STATUS_OPTIONS, YES_NO_OPTIONS } from '../data';
import { EUserAccountStatus, EYesNo } from '../enums';
import { IUserAccount } from '../interfaces';
import { UserAccountStatusOption, YesNoOption } from '../types';
import { PersonalParticularAddressModel } from './personal-particular.model';
import { UpdatedByModel } from './updated-by.model';
import { UserGroupModel } from './user-group.model';
import { UserRoleModel } from './user-role.model';

export class UserAccountModel extends BaseModel implements IUserAccount {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  email!: string;

  @Expose()
  username!: string;

  @Expose()
  @Type(() => UserRoleModel)
  roles!: UserRoleModel[];

  @Expose()
  @Type(() => UserGroupModel)
  groups!: UserGroupModel[];

  @Expose()
  status!: EUserAccountStatus;

  @Expose()
  avatar!: string;

  @Expose()
  businessUnitId!: number;

  @Expose()
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => PhoneNumber)
  office!: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  mobile!: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  fax!: PhoneNumber;

  @Expose()
  dateOfBirth!: string;

  @Expose()
  @Type(() => PersonalParticularAddressModel)
  address!: PersonalParticularAddressModel;

  @Expose()
  companies!: unknown[];

  //#region timestamps
  @Expose()
  createdAt!: string;
  @Expose()
  updatedAt!: string;
  @Expose()
  deletedAt!: string;
  @Expose()
  @Type(() => UpdatedByModel)
  createdBy?: UpdatedByModel;
  @Expose()
  @Type(() => UpdatedByModel)
  updatedBy?: UpdatedByModel;
  @Expose()
  @Type(() => UpdatedByModel)
  deletedBy?: UpdatedByModel;
  //#endregion

  @Expose()
  @Transform(({ obj }: { obj: IUserAccount }) => {
    return USER_ACCOUNT_STATUS_OPTIONS[obj.status] ?? null;
  })
  statusInfo!: UserAccountStatusOption | null;

  @Expose()
  @Transform(({ obj }: { obj: IUserAccount }) => {
    return YES_NO_OPTIONS[obj.isActive ? EYesNo.Yes : EYesNo.No] ?? null;
  })
  activeInfo!: YesNoOption | null;
}
