import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { ETeam } from '../enums';
import { IProjectConsultant, IProjectConsultantError, IProjectConsultantImportResponse } from '../interfaces';
import { ProjectModel } from './project.model';
import { UpdatedByModel } from './updated-by.model';
import { ProjectTeamMemberModel } from './project-team-member.model';

export class ProjectConsultantModel extends BaseModel implements IProjectConsultant {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  type!: `${ETeam}`;

  @Expose()
  @Type(() => ProjectModel)
  project!: ProjectModel;

  @Expose()
  projectId!: number;

  @Expose()
  status!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  startDate!: string;

  @Expose()
  endDate?: string;

  @Expose()
  taggerStartDate?: string;

  @Expose()
  taggerEndDate?: string;

  @Expose()
  remarks?: string;

  @Expose()
  members?: ProjectTeamMemberModel[];
}

export class ProjectConsultantImportErrorMetadataModel {
  @Expose()
  data!: IProjectConsultantError;

  @Expose()
  @Default('Duplicated Project Consultant')
  errorCode?: string;

  @Expose()
  rowNumber?: number;
}
export class ProjectConsultantImportResponseModel extends BaseModel implements IProjectConsultantImportResponse {
  @Expose()
  typeProgress!: number;

  @Expose()
  status!: string;

  @Expose()
  numberOfImport!: number;

  @Expose()
  errors!: ProjectConsultantImportErrorMetadataModel[];
}
