import { IImportJournalEntryColumn } from '../interfaces';

export const IMPORT_JOURNAL_ENTRY_DATA: IImportJournalEntryColumn[] = [
  {
    id: 'journalNumber',
    label: 'Journal Number',
    index: 0,
    required: false,
    maxLength: 20,
  },
  {
    id: 'journalDate',
    label: 'Journal Date',
    index: 1,
    required: true,
    maxLength: 300,
  },
  {
    id: 'title',
    label: 'Journal Title',
    index: 2,
    required: true,
    maxLength: 300,
  },
  {
    id: 'accountCode',
    label: 'Account Code',
    index: 3,
    required: true,
    maxLength: 20,
  },
  {
    id: 'profitCentre',
    label: 'Profit Centre',
    index: 4,
    required: false,
    maxLength: 20,
  },
  {
    id: 'description',
    label: 'Detail Description',
    index: 5,
    required: true,
    maxLength: 300,
  },
  {
    id: 'debit',
    label: 'Debit',
    index: 6,
    required: true,
    maxLength: 15,
  },
  {
    id: 'credit',
    label: 'Credit',
    index: 7,
    required: true,
    maxLength: 15,
  },
  {
    id: 'batchCode',
    label: 'Existing Batch No.',
    index: 8,
    required: false,
    maxLength: 20,
  },
  {
    id: 'batchDescription',
    label: 'New Batch Description',
    index: 9,
    required: false,
    maxLength: 300,
  },
];
