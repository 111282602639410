import { ICurrencyRate } from './currency-rate.interface';

export interface ICountry {
  id: number;
  code: string;
  name: string;
  status: string;
  isDefault?: boolean;
  defaultCurrency?: ICurrencyRate;
  defaultCurrencyId?: number;
  createdAt: string;
  updatedAt: string;
}
