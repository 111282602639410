import { PhoneFax, PhoneNumber } from '@red/data-access';
import { Expose, Type, Transform } from 'class-transformer';
import { IContactPerson } from '../interfaces/contact-person.interface';
import { BaseFixedModel } from './base.model';

export class ContactPersonModel extends BaseFixedModel implements IContactPerson {
  @Expose()
  name!: string;

  @Expose()
  designation?: string;

  @Expose()
  email?: string;

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  mobile!: PhoneNumber;

  @Expose()
  @Type(() => PhoneFax)
  fax?: PhoneFax;

  @Expose()
  default = false;

  @Expose()
  @Type(() => Number)
  contactId!: number;

  @Expose()
  @Type(() => Number)
  index?: number;

  @Expose()
  @Transform(({ obj }) => {
    const normalized = `${obj.name ?? ''} ${obj.phone?.countryCode && obj.phone?.phoneNumber ? '(+' + obj.phone?.countryCode + ') ' + obj.phone?.phoneNumber : ''}`;
    return normalized.trim();
  })
  normalizedContactPersonName!: string;

  @Expose()
  @Transform(({ obj }) => {
    return obj.name?.trim();
  })
  normalizedContactPersonNameForInvoicePrinting!: string;
}
