import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MetabaseLinkComponent } from './metabase-link.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [MetabaseLinkComponent],
  exports: [MetabaseLinkComponent],
})
export class MetabaseLinkModule {}
