<div class="red-vertical-navigation-item-wrapper" [class.red-vertical-navigation-item-has-subtitle]="!!item.subtitle" [ngClass]="item.classes?.wrapper">
  <div
    class="red-vertical-navigation-item"
    [ngClass]="{
      'red-vertical-navigation-item-disabled': item.disabled,
      'red-vertical-navigation-item--has-child-active': isActived
    }"
    [matTooltip]="item.tooltip || ''"
    (click)="toggleCollapsable()"
  >
    <!-- Icon -->
    <ng-container *ngIf="item.icon; else noIcon">
      <mat-icon
        *ngIf="item.isSvgIcon"
        [color]="isActived ? 'primary' : 'default'"
        class="red-vertical-navigation-item-icon"
        [ngClass]="item.classes?.icon!"
        [svgIcon]="item.icon"
      ></mat-icon>
      <mat-icon *ngIf="!item.isSvgIcon" [color]="isActived ? 'primary' : 'default'" class="red-vertical-navigation-item-icon" [ngClass]="item.classes?.icon">{{
        item.icon
      }}</mat-icon>
    </ng-container>
    <ng-template #noIcon>
      <!-- <div class="red-vertical-navigation-item-dot-icon-wrapper">
              <div class="flex justify-center items-center bg-white w-full h-full">
                <div class="bg-primary red-vertical-navigation-item-dot-icon"></div>
              </div>
          </div> -->
    </ng-template>

    <!-- Title & Subtitle -->
    <div class="red-vertical-navigation-item-title-wrapper">
      <div class="red-vertical-navigation-item-title">
        <span [ngClass]="item.classes?.title">
          {{ item.title }}
        </span>
      </div>
      <ng-container *ngIf="item.subtitle">
        <div class="red-vertical-navigation-item-subtitle">
          <span [ngClass]="item.classes?.subtitle">
            {{ item.subtitle }}
          </span>
        </div>
      </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
      <div class="red-vertical-navigation-item-badge">
        <div class="red-vertical-navigation-item-badge-content" [ngClass]="item.badge.classes!">
          {{ item.badge.title }}
        </div>
      </div>
    </ng-container>

    <!-- Arrow -->
    <mat-icon class="red-vertical-navigation-item-arrow icon-size-4">chevron_right</mat-icon>
  </div>
</div>

<div class="red-vertical-navigation-item-children" *ngIf="!isCollapsed" @expandCollapse>
  <ng-container *ngFor="let item of item.children; trackBy: trackByFn">
    <!-- Skip the hidden items -->
    <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
      <!-- Basic -->
      <ng-container *ngIf="item.type === 'basic'">
        <red-vertical-navigation-basic-item [item]="item" [name]="name"></red-vertical-navigation-basic-item>
      </ng-container>

      <!-- Collapsable -->
      <ng-container *ngIf="item.type === 'collapsable'">
        <red-vertical-navigation-collapsable-item [item]="item" [name]="name" [autoCollapse]="autoCollapse"></red-vertical-navigation-collapsable-item>
      </ng-container>

      <!-- Divider -->
      <ng-container *ngIf="item.type === 'divider'">
        <red-vertical-navigation-divider-item [item]="item" [name]="name"></red-vertical-navigation-divider-item>
      </ng-container>

      <!-- Group -->
      <ng-container *ngIf="item.type === 'group'">
        <red-vertical-navigation-group-item [item]="item" [name]="name"></red-vertical-navigation-group-item>
      </ng-container>

      <!-- Spacer -->
      <ng-container *ngIf="item.type === 'spacer'">
        <red-vertical-navigation-spacer-item [item]="item" [name]="name"></red-vertical-navigation-spacer-item>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
