import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class SupplierReportCreateDto extends BaseModel {

  @Expose()
  name!: string;
}

export class SupplierReportUpdateDto extends BaseModel {

  @Expose()
  name!: string;
}
