import { BaseModel, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { ICareerProgressionCreateDto } from '../interfaces';

export class CareerProgressionCreateDto extends BaseModel implements ICareerProgressionCreateDto {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate!: string;

  @Expose()
  @Type(() => Number)
  schemeTypeId!: number;

  @Expose()
  @Type(() => Number)
  commissionSchemeId!: number;

  @Expose()
  @Type(() => Number)
  leadershipId!: number | null;

  @Expose()
  appTypeIds!: { id: number }[];

  // @Expose()
  // @Type(() => Number)
  // salespersonId!: number;

  // @Expose()
  // @Type(() => Number)
  // agentTypeId!: number;
}

export class CareerProgressionUpdateDto extends CareerProgressionCreateDto {}
