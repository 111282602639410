import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input, OnDestroy, ViewContainerRef, OnChanges, SimpleChanges } from '@angular/core';
import { from, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { LoadingBoxComponent } from './loading-box.component';

@Directive({
  selector: '[redLoadingBox]',
})
export class LoadingBoxDirective implements OnDestroy {
  @Input('redLoadingBox')
  get isLoading(): boolean {
    return this.#loading;
  }
  set isLoading(val: any) {
    this.#loading = coerceBooleanProperty(val);
    this.changesIsLoading$.next(this.#loading);
  }
  @Input() redLoadingBoxSize: 'tiny' | 'medium' | 'large' = 'medium';
  #loading = false;
  _unsubscribeAll = new Subject<void>();
  changesIsLoading$ = new Subject<boolean>();
  constructor(private el: ElementRef, private vcr: ViewContainerRef) {
    this.changesIsLoading$
      .pipe(
        switchMap(isLoading => {
          this.vcr.clear();
          if (isLoading) {
            return from(import('./loading-box.component')) as Observable<any>;
          }
          return of(null);
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(component => {
        if (component) {
          const { instance } = this.vcr.createComponent(LoadingBoxComponent);
          instance.spinnerSize = this.redLoadingBoxSize;
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
