import { IPayment } from './payment.interface';
import { IBankAccount } from './bank-account.interface';

export interface ICheque {
  id: number;
  businessUnitId: number;
  bankAccountId: number;
  bankAccount: IBankAccount;
  paymentId: number;
  payment: IPayment;
  chequeNumber: string;
  chequeDate: Date;
  amount: number;
  isCancel: boolean;
}

export interface IChequeCompact {
  id: number;
  chequeDate: Date;
  chequeNumber: string;
  amount?: number;
}
