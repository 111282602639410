import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EPersonalVerifyKey, EPersonalVerifyStatus, EPersonalVerifyType } from '../enums';
import { IPersonalVerify, IPersonalVerifyChangeValue } from '../interfaces';
import { PersonalParticularModel } from './personal-particular.model';

export class PersonalVerifyChangeValueModel extends BaseModel implements IPersonalVerifyChangeValue {
  @Expose()
  value!: unknown;

  @Expose()
  data!: unknown;
}

export class PersonalVerifyModel extends BaseModel implements IPersonalVerify {
  @Expose()
  id!: number;

  @Expose()
  status!: EPersonalVerifyStatus;

  @Expose()
  type!: EPersonalVerifyType;

  @Expose()
  name!: EPersonalVerifyKey;

  @Expose()
  @Type(() => PersonalVerifyChangeValueModel)
  newValue!: PersonalVerifyChangeValueModel;

  @Expose()
  @Type(() => PersonalVerifyChangeValueModel)
  oldValue!: PersonalVerifyChangeValueModel;

  @Expose()
  salespersonId!: number;

  @Expose()
  salesperson!: PersonalParticularModel;

  //#region timestamps
  @Expose()
  createdAt!: string;
  @Expose()
  updatedAt!: string;
  @Expose()
  deletedAt?: string;
  @Expose()
  createdBy?: string;
  @Expose()
  updatedBy?: string;
  @Expose()
  deletedBy?: string;
  //#endregion
}
