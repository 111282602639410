import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, from, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AUTH_COOKIE_READER, AUTH_PROVIDER, LOGIN_URL } from '../auth.token';
import { AuthUtils } from '../auth.utils';
import { OauthService } from '../oauth';
import { AuthProvider } from '../auth.service';

@Injectable()
export class SSONoAuthGuard {
  _authService = inject(AuthProvider);
  _oauthService = inject(OauthService);
  _cookieReader = inject(AUTH_COOKIE_READER);
  _loginUrl = inject(LOGIN_URL);
  /**
   * Constructor
   */
  constructor(private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('heell canActivate');
    return this._check();
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._check();
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(): Observable<boolean> {
    // console.log('this._authService', this._authService);
    // Check the authentication status
    // return this._authService.check().pipe(
    //   switchMap(authenticated => {
    //     console.log('noAuth -->', authenticated);
    //     // If the user is authenticated...
    //     if (authenticated) {
    //       // Prevent the access
    //       return of(false).pipe(
    //         tap(() => {
    //           // Redirect to the root
    //           this._router.navigate(['']);
    //         })
    //       );
    //     }

    //     // Allow the access
    //     return of(true);
    //   })
    // );
    return from(this._oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      switchMap(() => {
        if (this._oauthService.hasValidAccessToken()) {
          this._authService.accessToken = this._oauthService.getAccessToken() as string;
          this._authService.refreshToken = this._oauthService.getRefreshToken() as string;
        }
        // if (!!this._authService.accessToken && !this.isLoginWithSameUser(this._authService.accessToken)) {
        //   this._authService.accessToken = '';
        //   this._authService.refreshToken = '';
        // }
        console.log('this._authService -->', this._authService.accessToken, this.isLoginWithSameUser(this._authService.accessToken));
        // Check the authentication status
        return this._authService.check().pipe(
          switchMap(authenticated => {
            console.log('this._authService', authenticated);
            // return of(false);
            // If the user is authenticated...
            if (authenticated || this.isLoginWithSameUser(this._authService.accessToken)) {
              this._oauthService.cleanUp();
              // Prevent the access
              return of(false).pipe(
                tap(() => {
                  let stateUrl = this._oauthService.state;
                  console.log('stateUrl', stateUrl);
                  if (stateUrl.startsWith('/') === false) {
                    stateUrl = decodeURIComponent(stateUrl);
                  }
                  console.log(`There was state of ${this._oauthService.state}, so we are sending you to: ${stateUrl}`);
                  this._router.navigateByUrl(stateUrl);
                  // }
                })
              );
            }
            // return of(false).pipe(
            //   tap(() => {
            //     console.log('this._authService', authenticated);
            //     // this._oauthService.loginWithRedirect('/');
            //   })
            // );

            // Allow the access
            return of(true);
          })
        );
      })
    );
  }
  isLoginWithSameUser(currentToken: string): boolean {
    if (!currentToken) {
      return false;
    }
    const doc = this._oauthService.getDiscoveryDocument();
    if (!doc) {
      throw new Error('Discovery document must be loaded');
    }
    const { session } = doc;
    const c_user_name = [session ? session.prefix : '', 'hits.c_user'].filter(item => !!item).join('_');
    const cuid = this._cookieReader.get(c_user_name);

    const { sourceId } = AuthUtils.decodeToken(currentToken);
    console.log('compare id ---> ', cuid, sourceId);
    return String(sourceId) === String(cuid);
  }
}
