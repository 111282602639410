import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { CreditNoteDto, CreditNotePostingReqDto, CreditNotePreviewInvoicePdfDto } from '@shared/data-access/dto';
import { ICreditNote, ICreditNotePayment, ICreditNotePosting } from '@shared/data-access/interfaces';
import { CreditNoteModel, CreditNotePostingModel, CreditNoteRecordModel, CreditNoteSupplierRefundModel, ReceiptMemoModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreditNoteEnhancedApiService {
  static ROOT_POINT = 'finance/credit-notes';
  static CANCEL_POINT = 'finance/credit-notes/:id/cancel';
  static PAYMENTS_POINT = 'finance/credit-notes/:id/payments';
  static PREVIEW_POSTING = 'finance/credit-notes/postings/preview';

  static FOR_RECEIPT_POINT = 'finance/credit-notes/for-receipts';
  static FOR_PAYMENT_POINT = 'finance/credit-notes/for-payments';
  static FOR_SUPPLIER_REFUND_POINT = 'finance/credit-notes/suppliers/for-receipts';

  constructor(private apiService: ApiService, private _router: Router) {}

  get(id: number, query = {}): Observable<CreditNoteModel> {
    return this.apiService.get(CreditNoteEnhancedApiService.ROOT_POINT + '/' + id, query).pipe(map(res => CreditNoteModel.fromJson(res) as CreditNoteModel));
  }

  create(data: CreditNoteDto): Observable<CreditNoteModel> {
    return this.apiService.post(CreditNoteEnhancedApiService.ROOT_POINT, data).pipe(map((res: ICreditNote) => CreditNoteModel.fromJson(res) as CreditNoteModel));
  }

  update(id: number, data: CreditNoteDto): Observable<CreditNoteModel> {
    return this.apiService.patch(CreditNoteEnhancedApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  getDetails(id: number, query = {}): Observable<PaginationAdapter<CreditNoteRecordModel>> {
    return this.apiService
      .get(CreditNoteEnhancedApiService.ROOT_POINT + '/' + id + '/details', query)
      .pipe(map(res => new PaginationAdapter(CreditNoteRecordModel, res)));
  }

  getPostings(id: number, query = {}): Observable<PaginationAdapter<CreditNotePostingModel>> {
    return this.apiService
      .get(CreditNoteEnhancedApiService.ROOT_POINT + '/' + id + '/postings', query)
      .pipe(map(res => new PaginationAdapter(CreditNotePostingModel, res)));
  }

  getPayments(id: number, query = {}): Observable<ICreditNotePayment[]> {
    return this.apiService.get(CreditNoteEnhancedApiService.PAYMENTS_POINT, { id, ...query });
  }

  previewPosting(data: CreditNotePostingReqDto): Observable<CreditNotePostingModel[]> {
    return this.apiService
      .post(CreditNoteEnhancedApiService.PREVIEW_POSTING, data)
      .pipe(map(res => res.map((item: ICreditNotePosting) => CreditNotePostingModel.fromJson(item))));
  }

  viewPdf(data: CreditNotePreviewInvoicePdfDto): Observable<any> {
    return this.apiService.post(CreditNoteEnhancedApiService.ROOT_POINT + '/template/preview', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(CreditNoteEnhancedApiService.ROOT_POINT + '/' + id, data, option);
  }

  cancel(id: number, data = {}): Observable<any> {
    return this.apiService.patch(CreditNoteEnhancedApiService.CANCEL_POINT, { id, ...data });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<CreditNoteModel>> {
    return this.apiService.get(CreditNoteEnhancedApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(CreditNoteModel, data)));
  }

  getCreditNoteForPayment(query = {}): Observable<PaginationAdapter<CreditNoteModel>> {
    return this.apiService.get(CreditNoteEnhancedApiService.FOR_PAYMENT_POINT, query).pipe(map(res => new PaginationAdapter(CreditNoteModel, res)));
  }

  getSupplierCreditNoteforReceipt(query = {}): Observable<PaginationAdapter<CreditNoteSupplierRefundModel>> {
    return this.apiService
      .get(CreditNoteEnhancedApiService.FOR_SUPPLIER_REFUND_POINT, query)
      .pipe(map(res => new PaginationAdapter(CreditNoteSupplierRefundModel, res)));
  }

  getCreditNoteForReceipt(query = {}): Observable<PaginationAdapter<ReceiptMemoModel>> {
    return this.apiService.get(CreditNoteEnhancedApiService.FOR_RECEIPT_POINT, query).pipe(map(res => new PaginationAdapter(ReceiptMemoModel, res)));
  }
}
