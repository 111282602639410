import { EPaymentReversalStatus, EReceiptStatus } from '../enums';

export interface IBatchSearchEngineReceipt {
  id: number;
  code: string;
  receiptDate: string;
  reversalDate: string;
  totalAmount: number;
  reference: string;
  businessUnitId: number;
  remarks: string;
  status: EReceiptStatus | EPaymentReversalStatus;
}

export interface IBatchSearchEngine {
  id: number;
  code: string;
  createdAt: string;
  deletedAt: string;
  hiInvoiceCode: string;
  hiInvoiceDate: string;
  hiInvoiceId: number;
  invoiceBalanceDue: number;
  invoiceCode: string;
  invoiceDate: string;
  invoiceId: number;
  invoiceIsCancel: boolean;
  invoiceIsWriteOff: boolean;
  invoicePaidAmount: number;
  invoiceSubtotal: number;
  invoiceGST: number;
  invoiceTotal: number;
  name: string;
  projectCode: string;
  projectId: number;
  projectName: string;
  receiptCheque: string;
  receiptCode: string;
  receiptDate: string;
  receiptId: number;
  receiptTotalAmount: number;
  creditNotes: {
    id: number;
    code: string;
    creditNoteDate: string;
    totalAmount: number;
    businessUnitId: number;
    status: string;
  }[];
  receipts: IBatchSearchEngineReceipt[];
  reversals: IBatchSearchEngineReceipt[];
  updatedAt: string;
}
