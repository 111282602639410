import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IBlankFormRelatedAgent } from '../interfaces';
import { BlankFormExternalCoBrokeModel, BlankFormInternalCoBrokeModel } from '@shared/data-access/models';

export class BlankFormRelatedAgentModel extends BaseModel implements IBlankFormRelatedAgent {
  @Expose()
  @Type(() => BlankFormInternalCoBrokeModel)
  @Transform(
    ({ obj, value, options }) =>
      (obj?.main
        ? [BlankFormInternalCoBrokeModel.fromJson(obj.main)]
        : value?.map((item: any) => BlankFormInternalCoBrokeModel.fromJson(item, options))) ?? []
  )
  mains!: BlankFormInternalCoBrokeModel[];

  @Expose()
  @Type(() => BlankFormInternalCoBrokeModel)
  @Default([])
  internals!: BlankFormInternalCoBrokeModel[];

  @Expose()
  @Type(() => BlankFormExternalCoBrokeModel)
  @Default([])
  externals!: BlankFormExternalCoBrokeModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormInternalCoBrokeModel)
  @Transform(
    ({ obj, value, options }) =>
      (obj?.leader
        ? [BlankFormInternalCoBrokeModel.fromJson(obj.leader, options)]
        : value?.map((item: any) => BlankFormInternalCoBrokeModel.fromJson(item, options))) ?? []
  )
  leaders!: BlankFormInternalCoBrokeModel[];
}
