import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import {
  BlankFormModel,
  AgentBlankFormModel,
} from '@shared/data-access/models';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgentBlankFormApiService {
  static AGENT_BLANK_FORM = 'transaction/portal/blank-form-transactions/agent';

  constructor(private _apiService: ApiService) { }

  get(id: number): Observable<AgentBlankFormModel> {
    return this._apiService.get(AgentBlankFormApiService.AGENT_BLANK_FORM + '/' + id).pipe(map(res => AgentBlankFormModel.fromJson(res)));
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<BlankFormModel>> {
    return this._apiService
      .get(AgentBlankFormApiService.AGENT_BLANK_FORM, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(BlankFormModel, data)));
  }

  delete(id: number) {
    return this._apiService.delete(AgentBlankFormApiService.AGENT_BLANK_FORM + '/' + id);
  }

}
