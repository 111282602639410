import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import {
  ResaleBillingDto,
  ResaleBillingUpdateDto,
  ResaleDocumentSetUpDto,
  ResaleTransactionCheckDuplicateDto,
  ResaleTransactionDto,
  ResaleTransactionPreviewDto,
  ResaleTransactionUpdateDto,
  ResaleUpdateRemarksDto,
} from '@shared/data-access/dto';
import { IResaleTransaction } from '@shared/data-access/interfaces';
import { ResaleBillingModel, ResaleDocumentModel, ResaleStatusHistoryModel, ResaleTransactionModel } from '@shared/data-access/models';
import { Observable, forkJoin, map, of, switchMap, takeLast } from 'rxjs';
import { EResaleStatus } from '@shared/data-access/enums';
import { merge } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ResaleTransactionApiService {
  static ROOT_POINT = 'transaction/portal/resale-transactions';
  static BILLINGS_POINT = 'transaction/resale-billings/portal';
  static DOCUMENTS_POINT = 'transaction/resale-documents/portal';
  static DOCUMENTS_SETUP_POINT = 'transaction/resale-documents/portal/setup';
  static SUBMIT_POINT = 'transaction/portal/resale-transactions/submit';
  static REWORK_POINT = 'transaction/portal/resale-transactions/:id/rework';
  static CONFIRM_POINT = 'transaction/portal/resale-transactions/:id/action';
  static WITHDRAW_POINT = 'transaction/portal/resale-transactions/:id/withdraw';
  static SUBMIT_MULTIPLE_POINT = 'transaction/portal/resale-transactions/submit';
  static REWORK_MULTIPLE_POINT = 'transaction/portal/resale-transactions/rework';
  static CONFIRM_MULTIPLE_POINT = 'transaction/portal/resale-transactions/action';
  static WITHDRAW_MULTIPLE_POINT = 'transaction/portal/resale-transactions/withdraw';
  static STATUS_HISTORIES_POINT = 'transaction/resale-status-histories';
  static DELETE_POINT = 'transaction/portal/resale-transactions';
  static PREVIEW_POINT = 'transaction/portal/resale-transactions/preview';
  static UPDATE_REMARKS_POINT = 'transaction/portal/resale-transactions/:id/remarks';
  static DASHBOARD_POINT = 'transaction/dashboard/portal/resale-transactions';
  static SAVE_DRAFT_POINT = 'transaction/portal/resale-transactions/save-draft';
  static UPDATE_SAVE_DRAFT_POINT = 'transaction/portal/resale-transactions/:id/save-draft';

  constructor(private _apiService: ApiService) { }

  get(itemId: number) {
    return this._apiService.get(ResaleTransactionApiService.ROOT_POINT + '/' + itemId).pipe(map(res => ResaleTransactionModel.fromJson(res)));
  }

  search(query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ResaleTransactionModel, data)));
  }

  searchDashboard(query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.DASHBOARD_POINT, query, option).pipe(map(data => new PaginationAdapter(ResaleTransactionModel, data)));
  }

  create(data: ResaleTransactionDto) {
    return this._apiService.post(ResaleTransactionApiService.ROOT_POINT, data).pipe(map(res => ResaleTransactionModel.fromJson(res)));
  }

  update(id: number, data: ResaleTransactionUpdateDto) {
    return this._apiService.patch(ResaleTransactionApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  delete(id: number) {
    return this._apiService.delete(ResaleTransactionApiService.DELETE_POINT + '/' + id);
  }

  createSaveDraft(data: ResaleTransactionDto) {
    return this._apiService.post(ResaleTransactionApiService.SAVE_DRAFT_POINT, data).pipe(map(res => ResaleTransactionModel.fromJson(res)));
  }

  updateSaveDraft(id: number, data: ResaleTransactionUpdateDto) {
    return this._apiService.patch(ResaleTransactionApiService.UPDATE_SAVE_DRAFT_POINT, Object.assign({ id }, data));
  }

  searchBillings(query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.BILLINGS_POINT, query, option).pipe(map(data => new PaginationAdapter(ResaleBillingModel, data)));
  }

  getBillingsByResaleId(resaleId: number, query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.BILLINGS_POINT + '/' + resaleId, query, option).pipe(map(item => ResaleBillingModel.fromJson(item)));
  }

  createBilling(data: ResaleBillingDto) {
    return this._apiService.post(ResaleTransactionApiService.BILLINGS_POINT, data).pipe(map(res => ResaleBillingModel.fromJson(res)));
  }

  updateBilling(id: number, data: ResaleBillingUpdateDto) {
    return this._apiService.patch(ResaleTransactionApiService.BILLINGS_POINT + '/' + id, data, { excludeFields: [''] });
  }

  searchDocuments(query = {}, option?: IApiOption) {
    return this._apiService
      .get(ResaleTransactionApiService.DOCUMENTS_POINT, query, option)
      .pipe(map(data => merge(new PaginationAdapter(ResaleDocumentModel, data), data?.additionals)));
  }

  getDocumentsByResaleId(resaleId: number, query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.DOCUMENTS_POINT + '/' + resaleId, query, option);
    // .pipe(map(item => FileUploaderModel.fromJson(item)));
  }

  setUpDocuments(data: ResaleDocumentSetUpDto) {
    return this._apiService.post(ResaleTransactionApiService.DOCUMENTS_SETUP_POINT, data);
  }

  submit(data: { id: number; data: object; metadata: object }) {
    return this._apiService.post(ResaleTransactionApiService.SUBMIT_POINT, data);
  }

  rework(id: number, reason = '') {
    return this._apiService.post(ResaleTransactionApiService.REWORK_POINT, { id, data: { reason } });
  }

  confirm(id: number, reason = '') {
    return this._apiService.post(ResaleTransactionApiService.CONFIRM_POINT, { id, data: { reason } });
  }

  withdraw(id: number, reason = '') {
    return this._apiService.post(ResaleTransactionApiService.WITHDRAW_POINT, { id, data: { reason } });
  }

  submitMultiple(resales: { id: number; data: object; metadata: object }[]) {
    return this._apiService.post(ResaleTransactionApiService.SUBMIT_MULTIPLE_POINT, {
      resales,
    });
  }

  reworkMultiple(resales: { id: number; data: { reason: string } }[]) {
    return this._apiService.post(ResaleTransactionApiService.REWORK_MULTIPLE_POINT, { resales });
  }

  confirmMultiple(resales: { id: number; data: { reason?: string } }[]) {
    return this._apiService.post(ResaleTransactionApiService.CONFIRM_MULTIPLE_POINT, { resales });
  }

  withdrawMultiple(resales: { id: number; data: { reason?: string } }[]) {
    return this._apiService.post(ResaleTransactionApiService.WITHDRAW_MULTIPLE_POINT, { resales });
  }

  searchStatusHistories(query = {}, option?: IApiOption) {
    return this._apiService
      .get(ResaleTransactionApiService.STATUS_HISTORIES_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ResaleStatusHistoryModel, data)));
  }

  createFullData(data: ResaleTransactionModel) {
    const dataJson = ResaleTransactionModel.toJson(data) as IResaleTransaction;
    const payload = ResaleTransactionDto.fromJson(dataJson);
    if (dataJson.status === EResaleStatus.draft) {
      return this.createSaveDraft(payload).pipe(
        switchMap(({ id: resaleId }) => {
          const { documents } = dataJson;
          const updatePayload = ResaleTransactionUpdateDto.fromJson({
            group: 'full',
            data: payload,
          });
          const documentsPayload = ResaleDocumentSetUpDto.fromJson({ isOverride: true, resaleId, data: documents });
          return forkJoin([documents.length ? this.setUpDocuments(documentsPayload) : of(true)]).pipe(map(() => resaleId));
        }),
        takeLast(1)
      );
    } else {
      return this.create(payload).pipe(
        switchMap(({ id: resaleId }) => {
          const { documents } = dataJson;
          const updatePayload = ResaleTransactionUpdateDto.fromJson({
            group: 'full',
            data: dataJson,
          });
          const documentsPayload = ResaleDocumentSetUpDto.fromJson({ isOverride: true, resaleId, data: documents });
          return forkJoin([documents.length ? this.setUpDocuments(documentsPayload) : of(true)]).pipe(map(() => resaleId));
        }),
        takeLast(1)
      );
    }
  }

  updateFullData(data: ResaleTransactionModel) {
    const dataJson = ResaleTransactionModel.toJson(data) as IResaleTransaction;
    const payload = ResaleTransactionUpdateDto.fromJson({
      group: 'full',
      data: dataJson,
    });
    const { id: resaleId, documents } = dataJson;
    const documentsPayload = ResaleDocumentSetUpDto.fromJson({ isOverride: true, resaleId, data: documents });
    if (dataJson.status === EResaleStatus.draft) {
      return this.updateSaveDraft(resaleId, payload).pipe(
        switchMap(({ id: resaleId }) => {
          // then call api setupDocment final to send email payment
          return this.setUpDocuments(documentsPayload).pipe(map(() => resaleId));
        }),
        takeLast(1)
      );

    } else {
      return this.update(resaleId, payload).pipe(
        switchMap(({ id: resaleId }) => {
          // then call api setupDocment final to send email payment
          return this.setUpDocuments(documentsPayload).pipe(map(() => resaleId));
        }),
        takeLast(1)
      );
    }
  }

  updateSubmitFullData(data: ResaleTransactionModel) {
    const dataJson = ResaleTransactionModel.toJson(data) as IResaleTransaction;
    const payload = ResaleTransactionUpdateDto.fromJson({
      group: 'full',
      data: dataJson,
    });
    const { id: resaleId, documents } = dataJson;
    const documentsPayload = ResaleDocumentSetUpDto.fromJson({ isOverride: true, resaleId, data: documents });
    return this.update(resaleId, payload).pipe(
      switchMap(({ id: resaleId }) => {
        // then call api setupDocment final to send email payment
        return this.setUpDocuments(documentsPayload).pipe(map(() => resaleId));
      }),
      takeLast(1)
    );
  }

  preview(data: ResaleTransactionPreviewDto): Observable<IResaleTransaction> {
    return this._apiService.post(ResaleTransactionApiService.PREVIEW_POINT, data);
  }

  updateRemarks(id: number, data: ResaleUpdateRemarksDto) {
    return this._apiService.patch(ResaleTransactionApiService.UPDATE_REMARKS_POINT, { id, ...data });
  }

  checkDuplicate(data: ResaleTransactionCheckDuplicateDto) {
    return this._apiService.post(ResaleTransactionApiService.ROOT_POINT + '/check-duplicated', data);
  }
}
