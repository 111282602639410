import { BaseModel, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { AssetDepreciationAssetItemModel, AssetDepreciationPostingItemModel } from '../models';
export class AssetDepreciationCreateDto extends BaseModel {
  @Expose()
  description!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  depreciationDate!: string;

  @Expose()
  amount!: number;

  // Just return the object that BE gave, not gonna transform it
  @Expose()
  assets!: AssetDepreciationAssetItemModel[];

  @Expose()
  postings!: AssetDepreciationPostingItemModel[];
}
