import { IReferenceConfig } from './../interfaces/reference-config.interface';
import { Expose, Type } from 'class-transformer';
import { BaseModel } from '@red/data-access';
import { CompanyModel } from './company.model';

export class ReferenceConfigModel extends BaseModel implements IReferenceConfig {
  @Expose()
  id!: number;

  @Expose()
  companyCode!: string;

  @Expose()
  @Type(() => CompanyModel)
  company!: CompanyModel;
}
