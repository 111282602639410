import { ApiService } from './api.service';
import { API_CONFIG } from './constants/token';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { IApiConfig } from './interfaces';

@NgModule()
export class RedApiModule {
  constructor(
    @Optional() @SkipSelf() parentModule: RedApiModule,
    private apiService:ApiService) {
    if (parentModule) {
        throw new Error('SharedIconModule is already loaded. Import it in the AppModule only!');
    }
  }
  static forRoot(config:IApiConfig): ModuleWithProviders<RedApiModule> {
      return {
          ngModule: RedApiModule,
          providers: [
              {
                  provide: API_CONFIG,
                  useValue: config
              }

          ]
      };
  }
}
