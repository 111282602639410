import { UserAccountModel } from './user-account.model';
import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IAssetRegisterDepreciation, IAssetRegister } from '../interfaces';
import { AssetCategoryModel } from './asset-category.model';
import { ProfitCentresModel } from './profit-centres.model';
import { UpdatedByModel } from './updated-by.model';
import { TaxInvoiceModel } from './tax-invoice-enhanced.model';

export class AssetRegisterComputeDepDto extends BaseModel {
  @Expose()
  startDate!: string;

  @Expose()
  @Type(() => Number)
  yearPeriod!: number;

  @Expose()
  @Type(() => Number)
  monthPeriod!: number;

  @Expose()
  @Type(() => Number)
  initialAmount!: number;

  @Expose()
  @Type(() => Number)
  residualAmount!: number;
}

export class AssetRegisterDepreciationModel extends BaseModel implements IAssetRegisterDepreciation {
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  startDate!: string;

  @Expose()
  yearPeriod!: number;

  @Expose()
  monthPeriod!: number;

  @Expose()
  totalMonth!: number;

  @Expose()
  initialAmount!: number;

  @Expose()
  residualAmount!: number;

  @Expose()
  accumulatedAmount!: number;

  @Expose()
  accumulatedMonth!: number;

  @Expose()
  lastAmount?: number;

  @Expose()
  depreciationAmount!: number;

  @Expose()
  firstDepValue!: number;

  @Expose()
  seqDepValue!: number;

  @Expose()
  finalDepValue!: number;

  @Expose()
  totalDepValue!: number;

  @Expose()
  isManualCalculation?: boolean;
}

export class AssetRegisterModel extends BaseModel implements IAssetRegister {
  // Timestamp
  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  createdBy?: string | null;

  @Expose()
  updatedBy?: UpdatedByModel | null;

  @Expose()
  deletedAt?: string | null;

  @Expose()
  deletedBy?: string | null;

  // General Detail
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  businessUnitId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => AssetCategoryModel)
  category!: AssetCategoryModel;

  @Expose()
  categoryId!: number;

  @Expose()
  serialNumber?: string;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  location?: string;

  @Expose()
  specification?: string;

  // Person In Charge
  @Expose()
  inChargePersonId?: number;

  @Expose()
  inChargePerson?: UserAccountModel;

  // Purchase Detail
  @Expose()
  @Type(() => TaxInvoiceModel)
  invoice?: TaxInvoiceModel;

  @Expose()
  invoiceId!: number;

  @Expose()
  purchaseDate!: string;

  @Expose()
  purchaseAmount!: number;

  @Expose()
  purchaseQuantity!: number;

  @Expose()
  remarks?: string;

  @Expose()
  items?: string[];

  // Depreciation Detail
  @Expose()
  depreciationId!: number;

  @Expose()
  depreciation!: AssetRegisterDepreciationModel;
}
