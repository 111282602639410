import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { JournalPostingCreateDto } from '@shared/data-access/dto';
import { JournalPostingModel } from '@shared/data-access/models/journal-posting.model';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JournalPostingApiService {
  static ROOT_POINT = 'finance/journals';

  constructor(private apiService: ApiService) {}

  create(journalId: number, data: JournalPostingCreateDto): Observable<JournalPostingModel> {
    return this.apiService
      .post(JournalPostingApiService.ROOT_POINT + '/' + journalId + '/postings', data)
      .pipe(map((res: any) => JournalPostingModel.fromJson(res) as JournalPostingModel));
  }

  update(journalId: number, postingId: number, data: JournalPostingCreateDto): Observable<JournalPostingModel> {
    return this.apiService.patch(JournalPostingApiService.ROOT_POINT + '/' + journalId + '/postings/' + postingId, data, { excludeFields: [''] });
  }

  search(journalId?: number, query: any = {}, option?: IApiOption): Observable<PaginationAdapter<JournalPostingModel>> {
    return this.apiService
      .get(JournalPostingApiService.ROOT_POINT + '/' + journalId + '/postings', query, option)
      .pipe(map(data => new PaginationAdapter(JournalPostingModel, data)));
  }

  delete(journalId: number, postingId: number, option?: IApiOption): Observable<JournalPostingModel> {
    return this.apiService.delete(JournalPostingApiService.ROOT_POINT + '/' + journalId + '/postings/' + postingId, {}, option);
  }
}
