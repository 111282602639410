import { BaseModel, Default } from '@red/data-access';
import { Expose } from 'class-transformer';

export class CountryCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  @Default(null)
  defaultCurrencyId?: number;
}
