import { ISplitMatrix } from './split-matrix.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { EManagementCommissionPdRole } from '../enums';
import { IAppointmentType } from './appointment-type.interface';
import { ISplitPart } from './split-part.interface';

export interface IBlankFormPd extends ISplitMatrix {
  id: number;
  additional: number;
  salespersonId: number;
  salesperson: IPersonalParticular;
  parties: ISplitPart[];
  tiers: ISplitPart[];
  splitMatrixId: number;
  createdAt: string;
  updatedAt: string;
  isDefault: boolean;
  pdRole: EManagementCommissionPdRole;
  appointmentTypeId: number;
  appTypeCode: string;
  appointmentType: IAppointmentType;
  isActive: boolean;
  data: any;
  attrs: any;
  receivedValue: number;
  companyAmount: number;
}
