import { IRedIcon } from './icon.interface';

export const RED_ICON_DATA: IRedIcon[] = [
  'hamburger',
  'document',
  'document_template',
  'filter',
  'check_ibox',
  'user_circle',
  'menu',
  'cog',
  'logout',
  'dollar_sign_octagon',
  'star_octagon',
  { key: 'gps_fixed', isSvg: false },
  'blank_paper',
  'pdf',
];
