export enum ECreditNoteStatus {
  CANCELLED = 'CANCELLED',
  ABORTED = 'ABORTED',
  PARTIAL = 'PARTIAL',
}

export enum ECreditNoteDetailStatus {
  UnPaid = 'UnPaid',
  PartPaid = 'PartPaid',
  View = 'View',
  FullyPaid = 'FullyPaid',

  Cancelled = 'Cancelled',
  Void = 'Void',
  // Confirmed = 'Confirmed',
  // Withhold = 'Withhold',
  PaymentInProgress = 'PaymentInProgress',
}

export enum ECreditNoteTemplate {
  resaleCreditNoteSale = 'resale_credit_note_sale',
  resaleCreditNoteLease = 'resale_credit_note_lease',
  projectCreditNote = 'project_credit_note',
}

export enum ECreditNoteCreateMode {
  MANUAL = 'MANUAL',
  BATCH = 'BATCH',
  RESALE = 'RESALE',
}
