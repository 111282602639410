import { MetabaseColSetting } from '../models';
import { MetadataColDataType } from '../models/metabase.type';
export type MetabaseConversionTransformFn = (value: any, colSetting: MetabaseColSetting) => string;
export class MetabaseConversionApi {
  static transformData(value: any, colSetting: MetabaseColSetting, transformFn: MetabaseConversionTransformFn): string {
    const data = transformFn(value, colSetting);
    if (colSetting?.prefix) {
      return `${colSetting.prefix}${data}`;
    }
    return data;
  }
}
