import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { PageTitleConfigs, PAGE_TITLE_CONFIGS } from './page-title.token';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title, @Inject(PAGE_TITLE_CONFIGS) private _configs?: PageTitleConfigs) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    this.title.setTitle(generatePageTitle(title, this._configs));
  }
}

export function generatePageTitle(title: string | null | undefined, configs?: PageTitleConfigs): string {
  const names = [title || configs?.default];
  if (configs?.prefix) {
    names.unshift(`[${configs.prefix.toUpperCase()}]`);
  }
  if (configs?.suffix) {
    names.push(`[${configs.suffix.toUpperCase()}]`);
  }
  return names.join(' ');
}
