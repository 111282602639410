import { BaseModel, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { ETeam } from '../enums';

export class TeamCreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  projectId!: number;

  @Expose()
  name!: string;

  @Expose()
  type!: `${ETeam}`;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate?: string;
}
