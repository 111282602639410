import { Injectable } from '@angular/core';
import { IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ContactAddressDto, ContactPersonDto, SupplierCreateDto, SupplierUpdateDto } from '@shared/data-access/dto';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { ContactAddressModel, ContactPersonModel, SupplierModel } from '@shared/data-access/models';
import { Observable } from 'rxjs';
import { ContactBaseApiService } from './contact-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierApiService {
  constructor(private apiService: ContactBaseApiService) {}

  create(data: SupplierCreateDto): Observable<SupplierModel> {
    return this.apiService.create(SupplierModel, data);
  }

  get(itemId: number, query = {}): Observable<SupplierModel> {
    return this.apiService.get(SupplierModel, itemId, query);
  }

  update(id: number, data: SupplierUpdateDto): Observable<any> {
    return this.apiService.update(id, data);
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<SupplierModel>> {
    return this.apiService.search(SupplierModel, query, option);
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(id, data, option);
  }

  getContactAddresses(query: IQuerySearch & { contactId: number }): Observable<ContactAddressModel[]> {
    return this.apiService.getContactAddresses(query);
  }

  getContactAddressesById(query: IQuerySearch & { contactId: number; addressId: number }): Observable<ContactAddressModel> {
    return this.apiService.getContactAddressesById(query);
  }

  updateContactAddress(data: { contactId: number; items: ContactAddressDto[] }): Observable<unknown> {
    return this.apiService.updateContactAddress(data);
  }

  getContactPersion(query: IQuerySearch & { contactId: number }): Observable<ContactPersonModel[]> {
    return this.apiService.getContactPersion(query);
  }

  getContactPersionById(query: IQuerySearch & { contactId: number; contactPersonId: number }): Observable<ContactPersonModel> {
    return this.apiService.getContactPersionById(query);
  }

  updateContactPerson(data: { contactId: number; items: ContactPersonDto[] }): Observable<unknown> {
    return this.apiService.updateContactPerson(data);
  }
}
