import { FormControl, UntypedFormControl } from '@angular/forms';
import { PaginationAdapter, PaginationModel } from '@red/data-access';
import { assign, cloneDeep, concat, defaults, get, isEqual, union } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  Observable,
  of,
  ReplaySubject,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { IQuerySearch } from '@shared/data-access/interfaces';
import { ClassConstructor } from 'class-transformer';

// import {Event} from "@angular/common";

export class Searcher<T> {
  searchCtrl = new UntypedFormControl();
  loading = false;
  results = new ReplaySubject<T[]>(1);
  readyToSearch = new Subject<boolean>();
  constructor(public callback: (filter: IQuerySearch) => Observable<PaginationAdapter<T>>, public unsubscribeAll: Subject<void>) {
    this.listen();
  }
  private listen() {
    combineLatest({
      forced: this.readyToSearch,
      search: this.listenSearchChange(),
    })
      .pipe(
        tap(() => {
          this.loading = true;
        }),
        map(data => data.search),
        switchMap(search => this.callback(this.createFilter(search))),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe({
        next: data => {
          this.fetchCompleted(data);
        },
        error: error => {
          // no errors in our simulated example
          this.loading = false;
          // handle error...
        },
      });
    this.readyToSearch.next(true);

    this.unsubscribeAll.pipe(take(1)).subscribe(() => {
      this.destroy();
    });
  }
  protected listenSearchChange(): Observable<string> {
    return this.searchCtrl.valueChanges.pipe(startWith(''), debounceTime(200));
  }
  protected fetchCompleted(data: PaginationAdapter<T>): void {
    this.loading = false;
    this.results.next(data.results);
  }

  createFilter(key: string): IQuerySearch {
    return { key };
  }
  forceSearch(): void {
    this.readyToSearch.next(true);
  }
  private destroy() {
    this.results.complete();
    this.readyToSearch.complete();
  }
}

export class InfintityScrollSeacher<T> extends Searcher<T> {
  // If the user has scrolled within 200px of the bottom, add more data
  buffer = 200;
  limit = 8;
  page = 1;
  pageCount = 1;
  total = 0;
  dataSource: T[] = [];
  indexToLoadMore = 5;
  constructor(public override callback: (filter: IQuerySearch) => Observable<PaginationAdapter<T>>, public override unsubscribeAll: Subject<void>) {
    super(callback, unsubscribeAll);
    // this.indexToLoadMore = this.limit > 6 ? this.limit - 4 : 1;
  }
  override listenSearchChange(): Observable<string> {
    return this.searchCtrl.valueChanges.pipe(
      startWith(''),
      tap(() => {
        this.limit = 8;
        this.page = 1;
        this.dataSource = [];
        // this.results.next([]);
      }),
      debounceTime(200)
    );
  }
  override createFilter(key: string): IQuerySearch {
    return {
      key,
      page: this.page,
      limit: this.limit,
    };
  }
  override fetchCompleted(data: PaginationAdapter<T>): void {
    this.loading = false;
    this.limit = data.pagination.limit;
    this.page = data.pagination.page;
    this.total = data.pagination.total;
    this.pageCount = Math.ceil(this.total / this.limit) || 1;
    this.dataSource.push(...data.results);
    this.results.next(this.dataSource);
  }

  scrolledIndexChange(index: number): void {
    // console.log('this.limit * this.page - this.indexToLoadMore', index, this.limit, this.page, this.indexToLoadMore, this.limit * this.page - this.indexToLoadMore);
    if (index >= this.limit * this.page - this.indexToLoadMore && this.pageCount > this.page && !this.loading) {
      const currentPageScrolling = Math.floor(index / this.limit) + 1;
      if (currentPageScrolling === this.page) {
        this.page += 1;
        this.forceSearch();
      }
    }
  }
  reset(): void {
    this.limit = 8;
    this.page = 1;
    this.pageCount = 1;
    this.dataSource = [];
    this.total = 0;
  }
}
export class LocalSearcher<T> {
  adapter!: PaginationAdapter<T>;
  isInProgress = false;
  private waitSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(public cls: ClassConstructor<T>, public avalablePaths: string[] = [], public api: () => Observable<PaginationAdapter<T>>) {
    this.init();
  }
  init(): void {
    this.isInProgress = true;
    this.api()
      .pipe(take(1))
      .subscribe({
        next: res => {
          this.isInProgress = false;
          this.waitSubject.next(true);
          this.adapter = res;
        },
        error: () => {
          this.isInProgress = false;
          this.waitSubject.next(true);
          this.adapter = new PaginationAdapter(this.cls, { meta: {}, items: [] });
        },
      });
  }
  filter(filters: IQuerySearch): Observable<PaginationAdapter<T>> {
    if (this.isInProgress) {
      return this.waitSubject.pipe(
        debounceTime(300),
        filter(val => val !== null),
        take(1),
        switchMap(() => this.fetch(filters)),
        finalize(() => this.waitSubject.complete())
      );
    } else {
      return this.fetch(filters);
    }
  }

  fetch(filters: IQuerySearch): Observable<PaginationAdapter<T>> {
    if (!filters['key']) {
      return of(this.adapter);
    }
    const filtered = this.adapter.results.filter(item => {
      return this.avalablePaths.some(path => {
        const val = get(item, path);
        if (typeof val !== 'string') {
          return false;
        }
        const keyword = filters['key'] as string;
        return val.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
      });
    });
    const clone = cloneDeep(this.adapter);
    clone.results = filtered;
    clone.pagination = PaginationModel.fromJson({
      itemsPerPage: filtered.length,
      currentPage: 1,
      totalPages: 1,
      totalItems: filtered.length,
    });
    return of(clone);
  }
}
export class LocalSearcher2<T> {
  items: T[] = [];
  isInProgress = false;

  private waitSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(public cls: ClassConstructor<T>, public avalablePaths: string[] = [], public dataSource: Observable<T[]> | T[]) {
    this.init();
  }
  init(): void {
    this.isInProgress = true;

    if (this.dataSource && Array.isArray(this.dataSource)) {
      this.dataSource = of(this.dataSource);
    }
    this.dataSource.pipe(take(1)).subscribe({
      next: res => {
        this.isInProgress = false;
        this.waitSubject.next(true);
        this.items = res;
      },
      error: () => {
        this.isInProgress = false;
        this.waitSubject.next(true);
        this.items = [];
      },
    });
  }
  filter(filters: IQuerySearch): Observable<PaginationAdapter<T>> {
    if (this.isInProgress) {
      return this.waitSubject.pipe(
        debounceTime(300),
        filter(val => val !== null),
        take(1),
        switchMap(() => this.fetch(filters)),
        finalize(() => this.waitSubject.complete())
      );
    } else {
      return this.fetch(filters);
    }
  }
  fetch(filters: IQuerySearch): Observable<PaginationAdapter<T>> {
    const filtered = this.items.filter(item => {
      return this.avalablePaths.some(path => {
        const val = get(item, path);
        if (typeof val !== 'string') {
          return false;
        }
        const keyword = filters['key'] as string;
        return val.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());
      });
    });
    const currentPage = (filters['page'] as number) ?? 1;
    const itemsPerPage: number = filters['limit'] ? (filters['limit'] as number) : 10;
    const totalItems = filtered.length;
    const totalPages = Math.floor(totalItems / itemsPerPage);
    const results = filtered.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    return of({
      pagination: PaginationModel.fromJson({
        currentPage,
        itemsPerPage,
        totalItems,
        totalPages,
      }),
      results,
    });
  }
  destroy(): void {
    this.waitSubject.complete();
  }
}

// export class AbstractDataSource<T> extends DataSource<T> {
//   private _length = 100000;
//   private _pageSize = 100;
//   private _cachedData = Array.from<T>({length: this._length});
//   private _fetchedPages = new Set<number>();
//   private readonly _dataStream = new BehaviorSubject<(T)[]>(this._cachedData);
//   private readonly _subscription = new Subscription();
//   constructor(
//     private callback:(filter:IQuerySearch)=> Observable<PaginationAdapter<T>>
//   ){
//     super();
//   }

//   connect(collectionViewer: CollectionViewer): Observable<(T)[]> {
//     this._subscription.add(
//       collectionViewer.viewChange.subscribe(range => {
//         const startPage = this._getPageForIndex(range.start);
//         const endPage = this._getPageForIndex(range.end - 1);
//         for (let i = startPage; i <= endPage; i++) {
//           this._fetchPage(i);
//         }
//       }),
//     );
//     return this._dataStream;
//   }

//   disconnect(): void {
//     this._subscription.unsubscribe();
//   }

//   private _getPageForIndex(index: number): number {
//     return Math.floor(index / this._pageSize);
//   }

//   private _fetchPage(page: number) {
//     if (this._fetchedPages.has(page)) {
//       return;
//     }
//     this._fetchedPages.add(page);

//     // Use `setTimeout` to simulate fetching data from server.
//     setTimeout(() => {
//       this._cachedData.splice(
//         page * this._pageSize,
//         this._pageSize,
//         ...Array.from({length: this._pageSize}).map((_, i) => `Item #${page * this._pageSize + i}`),
//       );
//       this._dataStream.next(this._cachedData);
//     }, Math.random() * 1000 + 200);
//   }
// }

export class SearchingModel<T> {
  items$ = new BehaviorSubject<T[]>([]);
  loading$ = new BehaviorSubject<boolean>(false);
  defaultFilters = {
    limit: 50,
    page: 1,
  };
  indexToLoadMore = 5;
  filter$ = new BehaviorSubject<IQuerySearch>({});
  searchCtrl = new FormControl('');
  constructor(
    public callback: (filter: IQuerySearch) => Observable<PaginationAdapter<T>>,
    public unsubscribeAll: Subject<void>,
    private options?: {
      isInfinityScroll: boolean;
      defaultFilters?: IQuerySearch;
    }
  ) {
    this.defaultFilters = defaults(this.defaultFilters, options?.defaultFilters);
    // this.indexToLoadMore = this.defaultFilters.limit > 6 ? this.defaultFilters.limit - 4 : 1;
    this.searchCtrl.valueChanges
      .pipe(
        debounceTime(300),
        tap(key => {
          const filters = assign(this.filter$.getValue(), this.defaultFilters, { key, page: 1 });
          this.fetch(filters);
        }),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe();
  }

  fetch(data?: IQuerySearch): void {
    const filters = defaults(data, this.defaultFilters);
    // console.log('filters --->', filters);

    this.loading$.next(true);
    this.callback(filters)
      .pipe(
        tap(dataSource => {
          // console.log('this.getItemsCached()', this.getItemsCached());
          // console.log('dataSource.results', dataSource.results);
          const newItems = concat(this.getItemsCached(), dataSource.results);
          this.filter$.next(filters);
          this.items$.next(newItems);
          this.loading$.next(false);
        }),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe({
        error: err => {
          this.loading$.next(false);
        },
      });
  }
  private getItemsCached(): T[] {
    if (this.options?.isInfinityScroll) {
      return this.items$.getValue();
    }
    return [];
  }

  scrolledIndexChange(index: number): void {
    if (!this.options?.isInfinityScroll) {
      return;
    }
    // const { page } = this.filter$.value;
    // console.log('filter form -->', page);
    // const currentPage = typeof page === 'number' ? page : this.defaultFilters.page;
    // console.log({ index, currentPage, delta: this.defaultFilters.limit * currentPage - this.indexToLoadMore, loading: !this.loading$.getValue() });
    // if (index >= this.defaultFilters.limit * currentPage - this.indexToLoadMore && !this.loading$.getValue()) {
    //   const currentPageScrolling = Math.floor(index / this.defaultFilters.limit) + 1;
    //   if (currentPageScrolling === currentPage) {
    //     const nextPage = currentPage + 1;
    //     this.fetch(assign(this.filter$.getValue(), this.defaultFilters, { page: nextPage }));
    //   }
    // }
    of(index)
      .pipe(
        withLatestFrom(this.loading$, this.filter$),
        filter(([index, loading, filters]) => !loading),
        map(([index, loading, filters]) => {
          console.log('index', index);
          const currentPage = typeof filters['page'] === 'number' ? filters['page'] : this.defaultFilters.page;
          return { index, currentPage, filters };
        }),
        filter(({ index, currentPage, filters }) => {
          return index >= this.defaultFilters.limit * currentPage - this.indexToLoadMore;
        }),
        filter(({ index, currentPage, filters }) => {
          const currentPageScrolling = Math.floor(index / this.defaultFilters.limit) + 1;
          return currentPageScrolling === currentPage;
        }),
        map(({ index, currentPage, filters }) => {
          return assign(filters, this.defaultFilters, { page: currentPage + 1 });
        })
      )
      .subscribe(filters => {
        console.log('sf', filters);
        this.fetch(filters);
      });
  }
  reset(): void {
    this.fetch(this.defaultFilters);
  }
}

export class ClonedSearchingModel<T> {
  defaultFilters = {
    limit: 50,
    page: 1,
  };
  indexToLoadMore = 5;
  searchCtrl = new FormControl('');
  items$ = new BehaviorSubject<T[]>([]);
  cachedItem$ = new BehaviorSubject<T[]>([]);
  loading$ = new BehaviorSubject<boolean>(false);
  filter$ = new BehaviorSubject<IQuerySearch>({});

  constructor(
    public callback: (filter: IQuerySearch) => Observable<PaginationAdapter<T>>,
    public unsubscribeAll: Subject<void>,
    private options?: {
      isInfinityScroll: boolean;
      defaultFilters?: IQuerySearch;
    }
  ) {
    this.defaultFilters = defaults(this.defaultFilters, options?.defaultFilters);
    this.searchCtrl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(key => {
          const filters = assign(this.filter$.getValue(), this.defaultFilters, { key, page: 1 });
          this.clearCache();
          this.fetch(filters);
        }),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe();
  }

  fetch(data?: IQuerySearch): void {
    const filters = defaults(data, this.defaultFilters);
    this.loading$.next(true);
    this.callback(filters)
      .pipe(
        distinctUntilChanged((a, b) => isEqual(a, b)),
        tap(dataSource => {
          const newItems = concat(this.getItemsCached(), dataSource.results);
          // const newItems = union(this.getItemsCached(), dataSource.results);
          this.cachedItem$.next(dataSource.results);
          this.filter$.next(filters);
          this.items$.next(newItems);
          this.loading$.next(false);
        }),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe({
        error: err => {
          this.loading$.next(false);
        },
      });
  }

  private getItemsCached(): T[] {
    if (this.options?.isInfinityScroll) {
      return this.cachedItem$.getValue();
    }
    return [];
  }

  scrolledIndexChange(index: number): void {
    if (!this.options?.isInfinityScroll) {
      return;
    }
    of(index)
      .pipe(
        withLatestFrom(this.loading$, this.filter$),
        filter(([index, loading, filters]) => !loading),
        map(([index, loading, filters]) => {
          console.log('index', index);
          const currentPage = typeof filters['page'] === 'number' ? filters['page'] : this.defaultFilters.page;
          return { index, currentPage, filters };
        }),
        filter(({ index, currentPage, filters }) => {
          return index >= this.defaultFilters.limit * currentPage - this.indexToLoadMore;
        }),
        filter(({ index, currentPage, filters }) => {
          const currentPageScrolling = Math.floor(index / this.defaultFilters.limit) + 1;
          return currentPageScrolling === currentPage;
        }),
        map(({ index, currentPage, filters }) => {
          return assign(filters, this.defaultFilters, { page: currentPage + 1 });
        })
      )
      .subscribe(filters => {
        console.log('sf', filters);
        this.fetch(filters);
      });
  }

  reset(): void {
    this.fetch(this.defaultFilters);
  }

  clearCache(): void {
    this.cachedItem$.next([]);
  }
}
