import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { Observable } from 'rxjs';

export interface IQueryReport {
  type: 'all' | 'transaction' | 'resale';
  year: number;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardReportApiService {
  static SUMMARY_RESALE = 'transaction/dashboard/portal/summary-resale';
  static SUMMARY_TRANSACTION = 'transaction/dashboard/portal/summary-transaction';
  static VOLUME = 'transaction/dashboard/portal/volume';
  static IR8A = 'hrm/dashboard/portal/ir8a';
  static GCE = 'hrm/dashboard/portal/gce';

  constructor(private _apiService: ApiService) {}

  // getSummaryResale(query: { year: number }): Observable<{ status: string; total: number }[]> {
  //   return this._apiService.get(DashboardReportApiService.SUMMARY_RESALE, query);
  // }

  // getSummaryTransaction(query: { year: number }): Observable<{ [x in string]: number }> {
  //   return this._apiService.get(DashboardReportApiService.SUMMARY_TRANSACTION, query);
  // }

  getVolume(
    query: IQueryReport
  ): Observable<{ totalVolume: number; total: number; resale: { status: string; total: number }[]; transaction: { [x in string]: number } }> {
    return this._apiService.get(DashboardReportApiService.VOLUME, query);
  }

  getIR8A(query: IQueryReport): Observable<{ ir8a: { salespersonId: number; month: number; total: number; year: number }[] }> {
    return this._apiService.get(DashboardReportApiService.IR8A, query);
  }

  getGCE(query: IQueryReport): Observable<{ gce: number }> {
    return this._apiService.get(DashboardReportApiService.GCE, query);
  }
}
