import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ExceljsQuestionRoutingModule } from './exceljs-question-routing.module';
import { ExceljsQuestionComponent } from './exceljs-question.component';
import { LoadingBoxModule } from '@shared/components/loading-box';
import { ExceljsTableComponent } from '../exceljs-table/exceljs-table.component';
import { ExceljsParameterComponent } from '../exceljs-parameter/exceljs-parameter.component';
import { ExceljsWorkbookComponent } from '../exceljs-workbook/exceljs-workbook.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const components = [
  LoadingBoxModule,
  MatPaginatorModule,
  MatBadgeModule,
  MatSidenavModule,
  MatButtonModule,
  MatIconModule,
  ExceljsTableComponent,
  ExceljsWorkbookComponent,
  ExceljsParameterComponent,
  MatProgressSpinnerModule
];
@NgModule({
  declarations: [ExceljsQuestionComponent],
  imports: [CommonModule, ExceljsQuestionRoutingModule, ...components],
})
export class ExceljsQuestionModule { }
