import { ClassProvider, Injectable, InjectionToken, Type } from '@angular/core';
import { IApiOption } from '@red/api';
import { Observable } from 'rxjs';
import { MetabaseCard, MetabaseCardQueryModel } from './models';
export const METABASE_HTTP_LOADER = new InjectionToken<MetabaseHttpLoader>('METABASE_HTTP_LOADER');

@Injectable()
export abstract class MetabaseHttpLoader {
  /**
   * getCard
   *
   * @param cardId: number
   */
  abstract getCard(cardId: number): Observable<MetabaseCard>;
  /**
   * getCards
   *
   * @param query
   * @param option
   */
  abstract getCards(query: Record<string, any>, option?: IApiOption): Observable<MetabaseCard[]>;

  /**
   * query
   *
   * @param cardId
   * @param option
   */
  abstract query(cardId: number, data: Record<string, any>, option?: IApiOption): Observable<MetabaseCardQueryModel>;

  /**
   * export
   *
   * @param cardId
   * @param option
   */
  abstract export(cardId: number, data: Record<string, any>, ext?: 'xlsx'): Observable<BlobPart>;
}

export const useMetabaseLoader = (instance: Type<MetabaseHttpLoader>): ClassProvider => ({
  provide: METABASE_HTTP_LOADER,
  useClass: instance,
  multi: false,
});
