import { Directive } from '@angular/core';
import { ApiService } from '@red/api';
import { ExceljsCard, ExceljsFileSupportedConfig, ExceljsWorkBook } from '@shared/components/exceljs-viewer';
import { isObject } from 'lodash-es';
import { Observable, map } from 'rxjs';

export enum ExceljsCardCollection {
  AgentPortalReport = 'hrm/hr/reports/portal',
}
@Directive()
export abstract class ExceljsApiService {
  abstract collectionEndpoint: ExceljsCardCollection;

  constructor(protected apiService: ApiService) { }

  getExceljsCard(code: string, query = {}): Observable<ExceljsCard> {
    return this.apiService.get(this.collectionEndpoint + '/' + code, query).pipe(map(res => ExceljsCard.fromJson(res) as ExceljsCard));
  }

  queryExceljsCard(code: string, data: Record<string, any>): Observable<ExceljsWorkBook> {
    return this.apiService.post(this.collectionEndpoint + '/' + code, data).pipe(map(res => ExceljsWorkBook.fromJson(res) as ExceljsWorkBook));
  }

  getExceljsCardXlsxFile(code: string, data: Record<string, any> = {}, extConfig?: string | ExceljsFileSupportedConfig): Observable<any> {
    const ext = isObject(extConfig) ? extConfig.type : extConfig;
    const isStream = isObject(extConfig) ? extConfig.stream : false;
    return this.apiService.download('post', this.collectionEndpoint + '/' + code + '/download',
      { ...data, type: ext ?? 'xlsx' }, isStream)
    // return this.apiService
    //   .post(
    //     this.collectionEndpoint + '/' + code + '/download',
    //     { ...data, type: ext ?? 'xlsx' },
    //     {
    //       pretreatmentResponse: false,
    //       requestOptions: {
    //         responseType: 'arraybuffer',
    //       },
    //     }
    //   )
    //   .pipe(
    //     map(res => {
    //       return res;
    //     })
    //   );
  }
}
