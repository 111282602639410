import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ContactCategoryCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  // @Expose()
  // @Default("")
  // description!: string;

  // @Expose()
  // status!: string;

  @Expose()
  categoryRole!: string;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;
}
