import { Expose, Transform, Type } from 'class-transformer';
import { IProfitCentres } from './../interfaces/profit-centres.interface';
import { BaseFixedModel } from './base.model';

export class ProfitCentresModel extends BaseFixedModel implements IProfitCentres {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  status!: string;

  @Expose()
  description!: string;

  @Expose()
  refCode?: string;

  @Expose()
  parentId?: string;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCentres!: ProfitCentresModel;

  @Expose()
  @Transform(({ obj }) => {
    const normalized = `${obj.code || ''} ${obj.name || ''}`;
    return normalized.trim();
  })
  normalizedName!: string;
}
