import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, fromEvent, Subject, takeUntil, tap } from 'rxjs';
import { MetabaseCardParameter } from '../../models';
import { MetabaseParameterFieldBase } from '../metabase-parameter-field-base.interface';

@Component({
  selector: 'red-metabase-parameter-text',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule],
  templateUrl: './metabase-parameter-text.component.html',
  styleUrls: ['./metabase-parameter-text.component.scss'],
})
export class MetabaseParameterTextComponent extends MetabaseParameterFieldBase {
  @ViewChild('searchInput', { read: ElementRef, static: true }) searchInput!: ElementRef;
  toDataDowncastValue(scheme: Partial<MetabaseCardParameter>, val: unknown): unknown {
    return val;
  }
  toDataUpcastValue(scheme: Partial<MetabaseCardParameter>, val: unknown): unknown {
    return val;
  }

  constructor(private _fb: FormBuilder) {
    super();
  }
  clear(): void {
    this.dataUpcast.setValue('');
  }
  get controlType(): string {
    return 'text';
  }
}
