import { IImportSupplierInvoiceColumn } from '../interfaces';

export const IMPORT_SUPPLIER_INVOICE_DATA: IImportSupplierInvoiceColumn[] = [
  {
    id: 'supplierCode',
    label: 'Supplier Code',
    index: 0,
    required: true,
    maxLength: 20,
  },
  {
    id: 'supplierName',
    label: 'Supplier Name',
    index: 1,
    required: false,
    maxLength: 300,
  },
  {
    id: 'invoiceNo',
    label: 'Invoice No',
    index: 2,
    required: true,
    maxLength: 50,
  },
  {
    id: 'invoiceDate',
    label: 'Invoice Date (dd/mm/yyyy)',
    index: 3,
    required: true,
    // maxLength: 300,
  },
  {
    id: 'creditTerm',
    label: 'Credit Term',
    index: 4,
    required: false,
    maxLength: 2,
  },
  {
    id: 'gstPercent',
    label: 'GST %',
    index: 5,
    required: true,
    maxLength: 2,
  },
  {
    id: 'gstAmount',
    label: 'GST Amount',
    index: 6,
    required: true,
    maxLength: 20,
  },
  {
    id: 'itemNo',
    label: 'Item No',
    index: 7,
    required: true,
    maxLength: 20,
  },
  {
    id: 'itemCode',
    label: 'Item Code',
    index: 8,
    required: false,
    maxLength: 20,
  },
  {
    id: 'itemDescription',
    label: 'Item Description',
    index: 9,
    required: true,
    maxLength: 300,
  },
  {
    id: 'itemAmount',
    label: 'Item Amount',
    index: 10,
    required: true,
    maxLength: 20,
  },
  {
    id: 'creditAccount',
    label: 'Credit Account',
    index: 11,
    required: false,
    maxLength: 20,
  },
  {
    id: 'debitAccount',
    label: 'Debit Account',
    index: 12,
    required: true,
    maxLength: 20,
  },
  {
    id: 'profitCentre',
    label: 'Profit Center',
    index: 13,
    required: false,
    maxLength: 20,
  },
  {
    id: 'currencySign',
    label: 'Currency',
    index: 15,
    required: false,
    maxLength: 20,
  },
  {
    id: 'currencyRate',
    label: 'Currency Rate',
    index: 16,
    required: false,
    maxLength: 10,
  },
  {
    id: 'reference',
    label: 'Reference',
    index: 17,
    required: false,
    maxLength: 300,
  },
  {
    id: 'remarks',
    label: 'Remarks',
    index: 18,
    required: false,
    maxLength: 1000,
  },
  {
    id: 'glEntry',
    label: 'GL Entry? (Y)',
    index: 19,
    required: false,
    maxLength: 1,
  },
  {
    id: 'offsetInvoice',
    label: 'Offset Invoice',
    index: 20,
    required: false,
    maxLength: 300,
  },
  {
    id: 'isCreditNote',
    label: 'Is Credit Note? (Y)',
    index: 21,
    required: false,
    maxLength: 1,
  },
];
