import { Expose, Transform, Type } from 'class-transformer';
import { IJournalEntries } from '../interfaces/journal-entries.interface';
import { BaseFixedModel } from './base.model';
import { JournalPostingModel } from './journal-posting.model';

export class JournalEntriesModel extends BaseFixedModel implements IJournalEntries {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  reversal!: string;

  @Expose()
  journalDate?: string;

  @Expose()
  scheduleEnable?: boolean;

  @Expose()
  scheduleName?: string;

  @Expose()
  scheduleType?: string;

  @Expose()
  scheduleStartDate?: string;

  @Expose()
  scheduleEndDate?: string;

  @Expose()
  totalDebit?: number;

  @Expose()
  formatCode?: string;

  @Expose()
  @Type(() => Number)
  totalCredit?: number;

  @Expose()
  @Type(() => JournalPostingModel)
  postings?: JournalPostingModel[];

  @Expose()
  @Type(() => JournalEntriesModel)
  reversalJournal?: JournalEntriesModel;

  @Expose()
  inClosedPeriod?: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return !obj.inClosedPeriod;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return !obj.inClosedPeriod;
  })
  canDelete!: boolean;
}
