import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { PreviewTaxInvoiceTemplateDto } from '@shared/data-access/dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaxInvoiceEnhancedApiService {
  static ROOT_POINT = 'finance/tax-invoices';
  constructor(private apiService: ApiService) {}

  viewOriginalPdf(data: PreviewTaxInvoiceTemplateDto): Observable<unknown> {
    return this.apiService.post(TaxInvoiceEnhancedApiService.ROOT_POINT + '/template/preview/original', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }
}
