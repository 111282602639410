import { Expose, Type } from 'class-transformer';
import { ISupplier } from '../interfaces';
import { ContactBaseModel } from './contact-base.model';
import { SupplierBankAccountModel } from './supplier-bank-account.model';

export class SupplierModel extends ContactBaseModel implements ISupplier {
  @Expose()
  isWithHold!: boolean;

  @Expose()
  @Type(() => SupplierBankAccountModel)
  bankAccount!: SupplierBankAccountModel;
}
