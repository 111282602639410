import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { assign, get, isNumber, isObject } from 'lodash-es';
import { ExceljsValueType, ExecljsParameterValueType, IExceljsParameter } from './models';
export const DYNAMICSTRING_REGEX = /{{([\w\s-_]*)}}/g;
import { DefaultUrlSerializer, UrlTree } from '@angular/router';
export function normalizeValueByDefaultValueParameter(value: any, parameter: Partial<IExceljsParameter>): any {
  if (typeof parameter.default === 'string') {
    return value.toString();
  }
  if (Array.isArray(parameter.default)) {
    return Array.isArray(value) ? value : [value];
  }
  return value;
}
export function normalizeValueByType(value: any, parameter: Partial<IExceljsParameter>): any {
  // console.log('parameter.valueType value --> ', value, parameter);
  if (parameter.valueType?.toLocaleLowerCase().includes('array')) {
    return (Array.isArray(value) ? value : [value]).map(val =>
      normalizeValueByType(val, { valueType: parameter.valueType?.replace('array', '') as ExecljsParameterValueType })
    );
  }
  if (parameter.valueType?.toLocaleLowerCase().includes('boolean')) {
    return coerceBooleanProperty(value);
  }
  if (parameter.valueType?.toLocaleLowerCase().includes('number')) {
    return value == '' ? null : Number(value);
  }
  if (parameter.valueType?.toLocaleLowerCase().includes('object')) {
    if (!value) {
      return {};
    }
    const normalizedValue: Record<string, any> = isObject(value) ? value : { value };
    return Object.keys(normalizedValue).reduce((acc: Record<string, any>, key) => {
      const parameterChild = parameter.properties ? parameter.properties[key] : null;

      if (parameterChild) {
        // acc[key] = normalizeValueByType(normalizedValue[key], parameterChild);
        const defaultObjectValue: Record<string, any> = parameter.default && isObject(parameter.default) ? parameter.default : {};
        acc[key] = getValue(parameterChild, normalizedValue[key], defaultObjectValue[key]);
      }

      return acc;
    }, {});
  }

  return value ? String(value) : value;
}
export function normalizeQueryParams(parameters: IExceljsParameter[], data: Record<string, any>): Record<string, any> {
  return parameters.reduce((acc: Record<string, any>, item) => {
    if (item.type === 'date/range') {
      acc[item.slug] = {
        start: data[`${item.slug}Start`],
        end: data[`${item.slug}End`],
      };
    }
    return acc;
  }, {});
}
function getValue(parameter: IExceljsParameter, value: any, defaultValue: any): any {
  return typeof value !== 'undefined' ? normalizeValueByType(normalizeValueByDefaultValueParameter(value, parameter), parameter) : defaultValue;
}
export function bindingDataParameter(parameters: IExceljsParameter[], data: Record<string, any>): IExceljsParameter[] {
  // console.log('bindingDataParameter --> ', data);
  return parameters.map(parameter => {
    const key = parameter.slug;
    // const value = typeof data[key] !== 'undefined' ? normalizeValueByType(normalizeValueByDefaultValueParameter(data[key], parameter), parameter) : parameter.default;
    const value = getValue(parameter, data[key], parameter.default);
    return assign(parameter, { value });
  });
}

export function isNumberValue(value: any, type: ExceljsValueType): boolean {
  if (type === ExceljsValueType.NUMBER) {
    return true;
  }
  if (type === ExceljsValueType.FORMULA) {
    return isNumber(value);
  }
  return false;
}
export function getColIndex(address: string): number {
  const addressLabel = (address.match(/[A-Z]/g) as RegExpMatchArray).join('');
  const labelSelected = addressLabel[addressLabel.length - 1] as string;
  const addressIndex = AphabelString.length * (addressLabel.length - 1) + AphabelString.indexOf(labelSelected) + 1;
  return addressIndex;
}

export const AphabelString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function parseUrl(url: string): UrlTree {
  const serializer = new DefaultUrlSerializer();
  return serializer.parse(url);
}
export class ExceljsQueryParam {
  /**
   * Serialize query params data right to your parameters
   * @param parameters IExceljsParameter[]
   * @param data Record<string, any>
   * @returns Record<string, any>
   */
  static serialize(parameters: IExceljsParameter[], data: Record<string, any>): Record<string, any> {
    return parameters.reduce((acc: Record<string, any>, item) => {
      if (item.type === 'date/range') {
        acc[item.slug] = {
          start: data[`${item.slug}Start`],
          end: data[`${item.slug}End`],
        };
      } else {
        acc[item.slug] = data[item.slug];
      }
      return acc;
    }, {});
  }

  static deserialize(parameters: IExceljsParameter[], data: Map<string, any>): Record<string, any> {
    return parameters.reduce((acc: Record<string, any>, item) => {
      if (item.type === 'date/range') {
        const value = data.get(item.slug) as { start: string | null; end: string | null } | null;
        acc[`${item.slug}Start`] = value?.start;
        acc[`${item.slug}End`] = value?.end;
        console.log('acc --> ', acc)
      } else {
        acc[item.slug] = data.get(item.slug);
      }
      return acc;
    }, {});
  }
}
export function getNormalizedString(input: string, data: Record<string, any>) {
  if (DYNAMICSTRING_REGEX.exec(input)) {
    return input.replace(DYNAMICSTRING_REGEX, (value: string, ...arg: string[]) => {
      return get(data, arg[0]);
    });
  }
  return input;
}
