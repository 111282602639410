import { EUserGroup } from '../enums';
import { UserGroupRecord } from '../types';

export const USER_GROUPS_OPTIONS: UserGroupRecord = {
  [EUserGroup.HR]: {
    id: EUserGroup.HR,
    value: EUserGroup.HR,
    viewValue: 'HR',
    accessible: false,
    total: 0,
  },
  [EUserGroup.TRANSACTION]: {
    id: EUserGroup.TRANSACTION,
    value: EUserGroup.TRANSACTION,
    viewValue: 'Transaction',
    accessible: false,
    total: 0,
  },
  [EUserGroup.FINANCE]: {
    id: EUserGroup.FINANCE,
    value: EUserGroup.FINANCE,
    viewValue: 'Finance',
    accessible: false,
    total: 0,
  },
  [EUserGroup.CONFIGURATION]: {
    id: EUserGroup.CONFIGURATION,
    value: EUserGroup.CONFIGURATION,
    viewValue: 'Configuration',
    accessible: false,
    total: 0,
  },
};
