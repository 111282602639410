import { BaseModel, Default } from '@red/data-access';
import { Expose } from 'class-transformer';
export type SystemDto = 'hms' | 'agent';
export class LoginDto extends BaseModel {
  @Expose()
  email!: string;

  @Expose()
  password!: string;

  @Expose()
  @Default('hms')
  system!: SystemDto;
}
export interface LoginResponseDto {
  accessToken: string;
  refreshToken: string;
}

export type RefreshTokenResponseDto = LoginResponseDto;

export interface ForgotDto {
  email: string;
  requestType: string;
  system?: SystemDto;
}
