import { BaseModel, StripComma } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ImportSupplierInvoiceItemDto extends BaseModel {
  @Expose()
  @Type(() => String)
  supplierCode?: string;

  @Expose()
  @Type(() => String)
  supplierName?: string;

  @Expose()
  @Type(() => String)
  invoiceNo?: string;

  @Expose()
  @Type(() => String)
  invoiceDate?: string;

  @Expose()
  @Type(() => String)
  creditTerm?: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  gstPercent?: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  gstAmount?: string;

  @Expose()
  @Type(() => String)
  itemNo?: string;

  @Expose()
  @Type(() => String)
  itemCode?: string;

  @Expose()
  @Type(() => String)
  itemDescription?: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  itemAmount?: string;

  @Expose()
  @Type(() => String)
  creditAccount?: string;

  @Expose()
  @Type(() => String)
  debitAccount?: string;

  @Expose()
  @Type(() => String)
  profitCentre?: string;

  @Expose()
  @Type(() => String)
  currencySign?: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  currencyRate?: string;

  @Expose()
  @Type(() => String)
  reference?: string;

  @Expose()
  @Type(() => String)
  remarks?: string;

  @Expose()
  @Type(() => String)
  glEntry?: string;

  @Expose()
  @Type(() => String)
  offsetInvoice?: string;

  @Expose()
  @Type(() => String)
  isCreditNote?: string;
}

export class ImportSupplierInvoiceDto extends BaseModel {
  @Expose()
  @Type(() => ImportSupplierInvoiceItemDto)
  items?: ImportSupplierInvoiceItemDto[];
}
