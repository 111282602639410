import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class SummaryModel<T> extends BaseModel {
  @Expose()
  @Default(0)
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  discount!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  tax!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  taxValue!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  gstAmount!: number;

  @Expose()
  invoiceDetails!: T[];


  // Sub total = sum amount - discount
  get subTotal(): number {
    return (this.amount - this.discount) || 0;
  }
}
