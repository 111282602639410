import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseModel, Default, PaginationAdapter } from '@red/data-access';
import { Helper } from '@red/utils';
import { OnPageVisible } from '@shared/components/document-visibility';
// import { IQuerySearch } from '@shared/data-access/interfaces';
import { Expose } from 'class-transformer';
import { assign, cloneDeep, has, isArray, isEmpty, isEqual, isNil, keys, merge, omit, omitBy, pick, pickBy, reduce, union, values } from 'lodash-es';
import { debounceTime, map, Observable, Subject, takeUntil } from 'rxjs';
import { distinctUntilChanged, pairwise, startWith, tap } from 'rxjs/operators';
interface IQuerySearch {
  [x: string]: unknown;
}
export interface SearchFormKeyConfig {
  [x: string]: {
    type?: 'single-selection' | 'multiple-selection';
    valueType?: 'string' | 'number';
  };
}
export class AbstractListFilters extends BaseModel {
  @Expose()
  key!: string;

  @Expose()
  orderBy!: string;

  @Expose()
  orderType!: string;

  @Expose()
  limit!: number;

  @Expose()
  page!: number;
}

@Directive()
export abstract class AbstractList<T> implements OnInit, AfterViewInit, OnDestroy {
  private _queryDefault: IQuerySearch = {
    key: '',
    orderBy: 'updatedAt',
    orderType: 'desc',
    limit: 50,
    page: 1,
  };

  private _ignoreResetListWithKeys = ['page', 'orderType'];

  #searchFormKeys: IQuerySearch = {
    ...this._queryDefault,
    ...this.searchFormDefaults,
  };

  protected _unsubscribeAll: Subject<void> = new Subject<void>();
  protected routeData: any;
  items: T[] = [];
  totalItem = 0;
  masterSearchControl = new UntypedFormControl('');
  searchForm!: UntypedFormGroup;
  availableFields = new SelectionModel<string>(true);
  isDrawerOpened = false;
  isFiltering = false;
  isLoading = false;
  currentQueryParam: Params = {};

  constructor(protected _formBuilder: UntypedFormBuilder, protected _activatedRoute: ActivatedRoute, protected _router: Router, protected _cdr: ChangeDetectorRef) {
    this.availableFields.select(...keys(this.#searchFormKeys));
    this.routeData = this.nomarlizeQueryParams(pick(this._activatedRoute.snapshot.data, this.availableFields.selected));
    // const defaults = assign(this.searchFormDefaults,this.routeData);
    this.#searchFormKeys = assign(this._queryDefault, this.searchFormDefaults, this.routeData);
    this.searchForm = this.createFormFilter();
  }
  ngAfterViewInit(): void {
    keys(this.searchFormDefaults).forEach(key => {
      this.searchForm
        .get(key)
        ?.valueChanges.pipe(takeUntil(this._unsubscribeAll))
        .subscribe(res => {
          // console.log('fliter change -->', res);
        });
    });
  }

  ngOnInit(): void {
    const queries = this.nomarlizeQueryParams(this._activatedRoute.snapshot.queryParams);
    const searchKeySnapshot = queries['key'];
    if (searchKeySnapshot && this.masterSearchControl.value !== searchKeySnapshot) {
      this.masterSearchControl.setValue(searchKeySnapshot, { emitEvent: false });
    }
    keys(this.searchForm.controls).forEach(key => {
      this.searchForm.controls[key].setValue(queries[key] ?? this.getValueDefault(key), { emitEvent: false });
    });
    // console.log('snapshot -->', this._activatedRoute.snapshot)
    // Subscribe to query params change
    this._activatedRoute.queryParams
      .pipe(
        map(data => this.nomarlizeQueryParams(data)),
        map(data => {
          Object.keys(this.routeData).forEach(key => {
            if (!data[key]) {
              data[key] = this.routeData[key];
            }
          });
          return data;
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(queryParams => {
        // this.currentQueryParam = queryParams;
        const availableObject = pick(queryParams, this.availableFields.selected);
        this.currentQueryParam = availableObject;
        // Fill the form with the values from query
        // params without emitting any form events
        this.fetch(availableObject);
        const filtered = omit(availableObject, ['key', 'limit', 'page', 'orderBy', 'orderType']);
        // console.log('filtered --->', filtered);
        this.isFiltering = values(filtered).some(value => {
          if (Array.isArray(value)) {
            return value.length !== 0;
          }
          if (typeof value === 'object') {
            return !isEmpty(value);
          }
          return !!value;
        });
      });

    this.masterSearchControl.valueChanges
      .pipe(
        map(key => key.trim()),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(key => {
        // console.log('key --->', key);
        this.searchForm.patchValue({ key });
        // this.search();
      });

    // combineLatest([
    //   this.searchForm.valueChanges,
    //   // ...this.fromEvents().map(obs => obs.pipe(startWith({})))
    // ]).pipe(
    //   map(data => this.reduceQueries(data)),
    //   // map(data => {
    //   //   const res = Helper.clearMoment(data);
    //   //   return res
    //   // }),
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe((data) => {
    //   this.search();

    // })

    this.searchForm.valueChanges
      .pipe(
        startWith(this.searchForm.value),
        pairwise(),
        map(([cur, previos]) => {
          return this.difference(cur, previos);
        }),
        debounceTime(500),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(res => {
        const list = union(this._ignoreResetListWithKeys, res);
        if (list.length !== this._ignoreResetListWithKeys.length) {
          this.searchForm.get('page')?.setValue(1, { emitEvent: false });
        }
        this.search();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  get searchFormDefaults(): IQuerySearch {
    return {};
  }
  get searchFormDefaultConfig(): SearchFormKeyConfig {
    return {};
  }

  createFormFilter(): UntypedFormGroup {
    const obj = reduce(
      keys(cloneDeep(this.#searchFormKeys)).map(key => {
        return { [key]: [this.#searchFormKeys[key]] };
      }),
      (acc, item) => assign(acc, item),
      {}
    );
    return this._formBuilder.group(obj);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Reset the search form using the default
   */
  reset(): void {
    this.searchForm.reset(cloneDeep(this.#searchFormKeys));
  }

  /**
   * Perform the search
   */
  search(): void {
    const raw = cloneDeep(this.searchForm.getRawValue());
    const payload = Helper.removeEmpty(Helper.clearMoment(raw, { milliseconds: true }));
    // Add query params using the router
    this._router.navigate([], {
      queryParams: payload,
      relativeTo: this._activatedRoute,
    });
  }

  fetch(queryParams: IQuerySearch = {}): void {
    const requiredObject = cloneDeep(this.#searchFormKeys) as IQuerySearch;
    keys(requiredObject).forEach((key: string) => {
      if (!(key in queryParams)) {
        queryParams[key] = requiredObject[key];
      }
    });
    this.isLoading = true;
    this._cdr.detectChanges();
    this.getItems(queryParams).subscribe(res => {
      this.totalItem = res.pagination?.total || res.results.length;
      this.isLoading = false;
      this._cdr.detectChanges();

      this.items = res.results;
    });
  }

  toogleFilter(): void {
    this.isFiltering = !this.isFiltering;
    this._cdr.detectChanges();
  }

  changePage(event: PageEvent | number) {
    if (typeof event === 'number') {
      this.searchForm.patchValue({ page: event });
    } else {
      this.searchForm.patchValue({ page: event.pageIndex + 1, limit: event.pageSize });
    }
    // this.search()
  }

  changeItemPerPage(event: number): void {
    this.searchForm.patchValue({ limit: event }, { emitEvent: false });
    this.search();
  }

  refresh(): void {
    // this.currentQueryParam = queryParams;
    //     const availableObject = pick(queryParams,this.availableFields.selected)
    // Fill the form with the values from query
    // params without emitting any form events
    this.fetch(this.currentQueryParam);
    // this.fetch();
  }

  fromEvents(): Observable<IQuerySearch>[] {
    return [];
  }

  drawerOpenedChange(isOpened: boolean): void {
    this.isDrawerOpened = isOpened;
    this._cdr.detectChanges();
  }

  private nomarlizeQueryParams(data: Record<string, any>): IQuerySearch {
    const res: IQuerySearch = {};
    Object.keys(data).map(key => {
      let selected = data[key];
      if (this.searchFormDefaultConfig[key]?.type === 'multiple-selection' && !isArray(selected)) {
        selected = [this.nomarlizeValue(key, selected)];
      }
      if (isArray(selected)) {
        res[key] = selected.map((item: string) => {
          return this.nomarlizeValue(key, item);
        });
      } else {
        res[key] = this.nomarlizeValue(key, selected);
      }
    });
    return res;
  }

  private nomarlizeValue(key: string, value: string): unknown {
    if (this.searchFormDefaultConfig[key]?.valueType === 'number') {
      return isNaN(+value) ? value : +value;
    }
    return value;
  }

  private reduceQueries(data: IQuerySearch[]): IQuerySearch {
    return data.reduce((acc, item) => {
      const keys = Object.keys(item);
      keys.forEach(key => {
        if (isNil(acc[key])) {
          if (this.searchFormDefaultConfig[key]?.type === 'multiple-selection') {
            acc[key] = [item[key]];
          } else {
            acc[key] = item[key];
          }
        } else if (isArray(acc[key])) {
          (acc[key] as unknown[]).push(item[key]);
        } else {
          acc[key] = [acc[key], item[key]];
        }
      });
      return acc;
    }, {});
  }
  @OnPageVisible()
  onPageVisible(): void {
    setTimeout(() => this.refresh(), 100);
    // this.refresh();
  }
  private getValueDefault(key: string): any {
    //  if(this.searchFormDefaultConfig[key]?.type === 'multiple-selection'){
    //    return []
    //  }
    return this.#searchFormKeys[key];
  }
  difference(current: IQuerySearch, previous: IQuerySearch): string[] {
    const res = keys(pickBy(current, (v, k) => !isEqual(previous[k], v)));
    return res;
  }

  abstract getItems(filters: IQuerySearch): Observable<PaginationAdapter<T>>;
}
