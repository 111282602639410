import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class NationalityCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  @Type(() => Number)
  sequence?: number;
}
