import { ISplitMatrix } from './../interfaces/split-matrix.interface';
import { BaseModel } from '@red/data-access';
import {  Expose, Transform, Type } from 'class-transformer';
import { SplitPartModel } from './split-part.model';


export class SplitMatrixModel extends BaseModel implements ISplitMatrix {
  @Expose()
  id!: number;

  @Expose()
  name!:string;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Transform(({value})=>{
    if(!value || value.length === 0){
      return [
                {
                  name: "Commission",
                  level: 1
                },
                {
                    name: "Tier 1",
                    level: 2
                },
                {
                    name: "Tier 2",
                    level: 3
                },
              ]
    }
    return value
  })
  @Type(()=>SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  @Transform(({value})=>{
    if(!value || value.length === 0){
      return [
        {
          name: "Recruiter",
          level: 4
      }
      ]
    }
    return value
  })
  @Type(()=>SplitPartModel)
  parties!: SplitPartModel[];
}
