export interface IImportContact {
  contactCode: string;
  contactName: string;
  type: string;
  accountReceivable: string;
  accountPayable: string;

  contactCategory: string;
  commenceDate: string;

  email: string;
  phonePrefix: string;
  phoneNumber: string;

  faxPrefix: string;
  faxNumber: string;
  businessReg: string;
  gstReg: string;
  creditTerm: string;
  creditLimit: string;
  remarks: string;
  address: string;
  country: string;
  postCode: string;
  personName: string;
  personDesignation: string;
  personEmail: string;
  personPhonePrefix: string;
  personPhoneNumber: string;
  // personFaxPrefix: string;
  // personFaxNumber: string;

  bankCode: string;
  bankAccountNumber: string;
  bankAccountName: string;

  status: string;
}

export interface IImportContactColumn {
  id: keyof IImportContact;
  label: string;
  index: number;
  required?: boolean;
  maxLength?: number;
}

export interface IImportInvalidity {
  invalid: boolean;
  message?: string;
}
