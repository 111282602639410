import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IProjectTransactionOther } from '@shared/data-access/interfaces/project-transaction-other.interface';

export class ProjectTransactionOtherModel extends BaseModel implements IProjectTransactionOther {
  @Expose()
  id!: number;

  @Expose()
  blankFormId!: number;

  @Expose()
  code!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  data!: any;

  @Expose()
  deletedAt!: string;

  @Expose()
  deletedBy!: string;

  @Expose()
  name!: string;

  @Expose()
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: string;

  @Expose()
  @Default(false)
  isDefault!: boolean;
}
