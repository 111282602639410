import { RouterModule } from '@angular/router';
import { VerticalNavigationComponent } from './vertical-navigation.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VerticalNavigationAsideItemComponent } from './components/aside/aside.component';
import { VerticalNavigationBasicItemComponent } from './components/basic/basic.component';
import { VerticalNavigationCollapsableItemComponent } from './components/collapsable/collapsable.component';
import { VerticalNavigationDividerItemComponent } from './components/divider/divider.component';
import { VerticalNavigationGroupItemComponent } from './components/group/group.component';
import { VerticalNavigationSpacerItemComponent } from './components/spacer/spacer.component';
import { MatIconModule } from '@angular/material/icon';
import { ScrollbarModule } from '../../scrollbar/scrollbar.module';
import { NavigationItemRouteLink } from './pipes/navigation-item-route-link.pipe';

@NgModule({
  declarations: [
    VerticalNavigationComponent,
    VerticalNavigationAsideItemComponent,
    VerticalNavigationBasicItemComponent,
    VerticalNavigationCollapsableItemComponent,
    VerticalNavigationDividerItemComponent,
    VerticalNavigationGroupItemComponent,
    VerticalNavigationSpacerItemComponent,
  ],
  imports: [CommonModule, MatTooltipModule, MatIconModule, RouterModule, ScrollbarModule, NavigationItemRouteLink],
  exports: [VerticalNavigationComponent],
})
export class RedVerticalNavigationModule {}
