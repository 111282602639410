import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';
import { BroadcastChannelService } from './broadcast-channel.service';
import { BROADCAST_CHANNELS } from './broadcast-channel.token';
import { BroadcastChannelDefault } from './model/broadcast-channel.interface';

@NgModule({
  imports: [CommonModule],
})
export class BroadcastChannelModule {
  static forRoot(options?: { channelNames: string[] }): ModuleWithProviders<BroadcastChannelModule> {
    const records = new Map<string, BroadcastChannel>();
    options?.channelNames.map(name => {
      if (!records.has(name)) {
        records.set(name, new BroadcastChannel(name));
      }
    });
    if (!records.has(BroadcastChannelDefault.APP)) {
      records.set(BroadcastChannelDefault.APP, new BroadcastChannel(BroadcastChannelDefault.APP));
    }
    const providers: Provider[] = [
      {
        provide: BROADCAST_CHANNELS,
        useValue: records,
      },
      BroadcastChannelService,
    ];
    return {
      ngModule: BroadcastChannelModule,
      providers: [...providers],
    };
  }
}
