import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IOverridingDistribution } from '../interfaces';
import { OverridingChartModel } from './overriding-chart.model';
import { UpdatedByModel } from './updated-by.model';

export class OverridingDistributionModel extends BaseModel implements IOverridingDistribution {
  @Expose()
  id!: number;

  @Expose()
  @Type(() => OverridingChartModel)
  overridingCharts!: OverridingChartModel[];

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  status!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;
}
