export enum EMessageOutboxStatus {
  draft = 'draft',
  submitted = 'submitted',
  sent = 'sent',
  skipped = 'skipped ',
  failed = 'failed ',
}

export enum EMessageOutboxType {
  Email = 'Email',
  SMS = 'SMS',
}

export enum EMessageType {
  PaymentNoti = 'PaymentNoti',
  ResaleInvoice = 'ResaleInvoice',
}
