export enum ETaxInvoiceTemplate {
  taxInvoice = 'tax_invoice',
  profomaInvoice = 'profoma_invoice',
  taxInvoiceResaleIcbEcb = 'tax_invoice_resale_icb_ecb',
  taxInvoiceResaleIcb = 'tax_invoice_resale_icb',
  taxInvoiceLeaseIndividualCustomer = 'tax_invoice_lease_individual_customer',
  taxInvoiceResaleIndividualCustomer = 'tax_invoice_resale_individual_customer',
  receipt = 'receipt',
  taxInvoiceDeveloperSale = 'tax_invoice_developer_sale',
  taxInvoiceLeaseBusinessCustomer = 'tax_invoice_lease_business_customer',
  taxInvoiceResaleBusinessCustomer = 'tax_invoice_resale_business_customer',

  // Using now
  hiTaxInvoice = 'hi_tax_invoice',
  resaleLeaseTaxInvoice = 'resale_lease_tax_invoice',
  projectTaxInvoice = 'project_tax_invoice',


  miscTaxInvoice = 'misc_tax_invoice',
  miscBankReferralFeeTaxInvoice = 'misc_bank_referral_feetax_invoice',
  creditNoteResale = 'resale_credit_note',
  creditNoteProjectPartialCN = 'project_credit_note_partial',
  creditNoteProjectFullCN = 'project_credit_note_full',
  paymentVoucherAgent = 'payment_voucher_agent',
  paymentVoucherECB = 'payment_voucher_ecb',
  paymentVoucherMISC = 'payment_voucher_misc',
}
