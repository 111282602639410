import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ProjectTeamCreateDto, ProjectTeamUpdateDto, ProjectTeamUpdateRemarkDto } from '@shared/data-access/dto';
import { IProjectConsultant } from '@shared/data-access/interfaces';
import { ProjectConsultantImportResponseModel, ProjectConsultantModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectConsultantApiService {
  static ROOT_POINT = 'transaction/teams';

  static IMPORT_CONSULTANT = 'transaction/import/projects/:id/consultants';
  static IMPORT_CONSULTANT_TEMPLATE = 'transaction/import/projects/:id/consultants/template';
  // static REMARKS_POINT = 'transaction/teams/:id/remarks';
  static DOWNLOAD_CONSULTANT = 'transaction/teams/download';

  constructor(private apiService: ApiService) {}

  create(data: ProjectTeamCreateDto): Observable<ProjectConsultantModel> {
    return this.apiService
      .post(`${ProjectConsultantApiService.ROOT_POINT}`, data)
      .pipe(map((res: IProjectConsultant) => ProjectConsultantModel.fromJson(res) as ProjectConsultantModel));
  }
  get(itemId: number): Observable<ProjectConsultantModel> {
    return this.apiService
      .get(ProjectConsultantApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => ProjectConsultantModel.fromJson(res) as ProjectConsultantModel));
  }
  update(id: number, data: ProjectTeamUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectConsultantApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  updateRemarks(id: number, data: ProjectTeamUpdateRemarkDto): Observable<any> {
    return this.apiService.patch(ProjectConsultantApiService.ROOT_POINT + '/' + id + '/remarks', data);
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectConsultantModel>> {
    return this.apiService
      .get(ProjectConsultantApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectConsultantModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectConsultantApiService.ROOT_POINT, { ids }, option);
  }

  getImportTemplate(id: number): Observable<any> {
    return this.apiService.post(
      ProjectConsultantApiService.IMPORT_CONSULTANT_TEMPLATE,
      { id },
      {
        pretreatmentResponse: false,
        requestOptions: { responseType: 'arraybuffer' },
      }
    );
  }

  importConsultants(id: number, file: File): Observable<ProjectConsultantImportResponseModel> {
    const data = new FormData();
    data.append('file', file);
    return this.apiService
      .post(ProjectConsultantApiService.IMPORT_CONSULTANT.replace(':id', id.toString()), data, {
        pretreatmentResponse: false,
        requestOptions: { reportProgress: true, observe: 'events' },
      })
      .pipe(
        map((event: any) => {
          return ProjectConsultantImportResponseModel.fromJson({ ...event?.body?.data, typeProgress: event?.type });
        })
      );
  }

  downloadConsultants(query = {}, option?: IApiOption): Observable<any> {
    return this.apiService.post(ProjectConsultantApiService.DOWNLOAD_CONSULTANT, query, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }
}
