import { BaseModel, IPhoneNumber } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IContact } from '../interfaces';

export class ContactModel extends BaseModel implements IContact {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  phone!: IPhoneNumber;

  @Expose()
  mobile!: IPhoneNumber;

  @Expose()
  fax!: string;

  @Expose()
  email!: string;

  @Expose()
  designation!: string;

  @Expose()
  status!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  code!: string;

}
