import { BaseModel, Default, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import * as moment from 'moment';
import { ETeam } from '../enums';
import { AbstractListFilters } from '@shared/core';

export class ProjectTeamCreateDto extends BaseModel {
  @Expose()
  projectId!: number;

  @Expose()
  name!: string;

  @Expose()
  type!: `${ETeam}`;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Default(new Date())
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  taggerStartDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  taggerEndDate!: string;
}

export class ProjectTeamUpdateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  type!: `${ETeam}`;

  // @Expose()
  // @Type(() => Number)
  // appointmentTypeId!: number;

  @Expose()
  @Default(new Date())
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;
}

export class ProjectTeamUpdateRemarkDto extends BaseModel {
  @Expose()
  remarks!: string;
}

export class ProjectConsultantFilters extends AbstractListFilters {
  @Expose()
  searchType!: string;

  @Expose()
  projectName!: string;

  @Expose()
  name!: string;

  @Expose()
  totalMember!: number;

  @Expose()
  leader!: string;

  @Expose()
  member!: string;

  @Expose()
  projectStatus!: string[];

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  startDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  startDateEnd!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  endDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  endDateEnd!: string;

  @Expose()
  remarks!: string;
}
