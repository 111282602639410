import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastData } from './toast-data.interface';
import { ToastComponent } from './toast.component';

@Injectable()
export class ToastService {
  constructor(private _snackBar: MatSnackBar) { }

  open(data: ToastData) {
    return this._snackBar.openFromComponent(ToastComponent, {
      duration: data.duration || 5000,
      panelClass: 'toast-container',
      data,
      horizontalPosition: data.horizontalPosition || 'center',
    });
  }
}
