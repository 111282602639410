import { ManagementCommissionPdRecord, ManagementCommissionStatusRecord } from '@shared/data-access/types';
import {
  EManagementCommissionActivePd,
  EManagementCommissionPdRole,
  EManagementCommissionStatus,
} from '@shared/data-access/enums';

export const MANAGEMENT_COMMISSION_CODE_EXISTED_ERROR_CODE = 2627;

export const MANAGEMENT_COMMISSION_STATUSES: ManagementCommissionStatusRecord = {
  [EManagementCommissionStatus.submitted]: {
    id: EManagementCommissionStatus.submitted,
    name: 'Pending Approval',
    class: 'status-box-warning',
  },
  [EManagementCommissionStatus.approved]: {
    id: EManagementCommissionStatus.approved,
    name: 'Approved',
    class: 'status-box-success',
  },
  [EManagementCommissionStatus.rejected]: {
    id: EManagementCommissionStatus.rejected,
    name: 'Rejected',
    class: 'status-box-danger',
  },
  [EManagementCommissionStatus.draft]: {
    id: EManagementCommissionStatus.draft,
    name: 'Not submitted',
    class: 'status-box-default',
  },
  [EManagementCommissionStatus.rework]: {
    id: EManagementCommissionStatus.rework,
    name: 'Pending Approval',
    class: 'status-box-warning',
  },
};

export const MANAGEMENT_COMMISSION_PD_DESCRIPTIONS: ManagementCommissionPdRecord = {
  [EManagementCommissionPdRole.PDOverride]: {
    id: EManagementCommissionPdRole.PDOverride,
    activeKey: EManagementCommissionActivePd.activePdOverride,
    relativeKey: 'pdOverride',
    label: 'PD Override',
  },
  [EManagementCommissionPdRole.PDReferral]: {
    id: EManagementCommissionPdRole.PDReferral,
    activeKey: EManagementCommissionActivePd.activePdReferal,
    relativeKey: 'pdReferral',
    label: 'PD Referral',
  },
  [EManagementCommissionPdRole.PDManagement]: {
    id: EManagementCommissionPdRole.PDManagement,
    activeKey: EManagementCommissionActivePd.activePdManagement,
    relativeKey: 'pdManagement',
    label: 'PD Management',
  },
};
