import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class ProjectConsultantCreateDto extends BaseModel {
  @Expose()
  name!: string;
}

export class ProjectConsultantUpdateDto extends BaseModel {
  @Expose()
  name!: string;
}
