import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { AssetCategoryCreateDto } from '@shared/data-access/dto';
import { IAssetCategory } from '@shared/data-access/interfaces';
import { AssetCategoryModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetCategoryApiService {
  static ROOT_POINT = 'finance/asset-categories';

  constructor(private apiService: ApiService) {}

  create(data: AssetCategoryCreateDto): Observable<AssetCategoryModel> {
    return this.apiService
      .post(`${AssetCategoryApiService.ROOT_POINT}`, data)
      .pipe(map((res: IAssetCategory) => AssetCategoryModel.fromJson(res) as AssetCategoryModel));
  }

  get(itemId: number, query = {}): Observable<AssetCategoryModel> {
    return this.apiService.get(AssetCategoryApiService.ROOT_POINT + '/' + itemId, query).pipe(map(res => AssetCategoryModel.fromJson(res) as AssetCategoryModel));
  }

  update(id: number, data: AssetCategoryCreateDto): Observable<any> {
    return this.apiService.patch(AssetCategoryApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<AssetCategoryModel>> {
    return this.apiService.get(AssetCategoryApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(AssetCategoryModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(AssetCategoryApiService.ROOT_POINT + '/' + id, data, option);
  }
}
