import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ExceljsParameterFieldBase } from '../exceljs-parameter-field-base.directive';
import { ExceljsParameter, IExceljsParameterControlType } from '../../models';

@Component({
  selector: 'red-exceljs-parameter-checkbox',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule],
  templateUrl: './exceljs-parameter-checkbox.component.html',
  styleUrls: ['./exceljs-parameter-checkbox.component.scss'],
})
export class ExceljsParameterCheckboxComponent extends ExceljsParameterFieldBase {
  toDataDowncastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return coerceBooleanProperty(val);
  }
  toDataUpcastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return coerceBooleanProperty(val);
  }

  get controlType(): IExceljsParameterControlType {
    return 'checkbox';
  }
}
