import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class UserRoleCreateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  description!: string;
}

export class UserRoleUpdateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  description!: string;
}
