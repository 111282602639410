import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ICommissionSalespersonScheme } from '../interfaces';
import { SchemeTypeModel } from './scheme-type.model';
import { SplitMatrixModel } from './split-matrix.model';
import { UpdatedByModel } from './updated-by.model';

export class CommissionSalespersonSchemeModel extends BaseModel implements ICommissionSalespersonScheme {
  @Expose()
  id!: number;

  @Expose()
  schemeTypeId!: number;

  @Expose()
  @Type(() => SchemeTypeModel)
  schemeType!: SchemeTypeModel;

  @Expose()
  designation!: string;

  @Expose()
  code!: string;

  @Expose()
  status!: string;

  @Expose()
  autoApprove!: boolean;

  @Expose()
  isActive!: boolean;

  @Expose()
  minCommission!: number;

  @Expose()
  @Default(Infinity)
  maxCommission!: number;

  @Expose()
  @Type(() => Number)
  sequence!: number;

  @Expose()
  @Transform(({ value }) => {
    if (!value || value.length === 0) {
      return [
        {
          name: '',
          isActive: true,
          tiers: [
            {
              name: 'Commission',
              level: 1,
            },
            {
              name: 'Tier 1',
              level: 2,
            },
            {
              name: 'Tier 2',
              level: 3,
            },
          ],
          parties: [
            {
              name: 'Recruiter',
              level: 4,
            },
          ],
        },
      ];
    }
    return value;
  })
  @Type(() => SplitMatrixModel)
  splitMatrices!: SplitMatrixModel[];

  //#region timestamps
  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt?: string | null;

  @Expose()
  createdBy?: string | null;

  @Expose()
  updatedBy?: UpdatedByModel | null;

  @Expose()
  deletedBy?: UpdatedByModel | null;
  //#endregion

  //#region getter
  @Expose()
  get splitMatrixDefault(): SplitMatrixModel {
    return this.splitMatrices[0];
  }

  @Expose()
  get matricies() {
    if (!this.splitMatrixDefault?.tiers.length) return null;

    return {
      comm: this.splitMatrixDefault.tiers[0] ?? null,
      tier1: this.splitMatrixDefault.tiers[1] ?? null,
      tier2: this.splitMatrixDefault.tiers[2] ?? null,
    };
  }

  @Expose()
  get designationRate(): number[] {
    const comm = this.splitMatrices[0]?.tiers.find(v => v.level === 1) ?? this.splitMatrices[0]?.tiers[0];
    const commPercent = comm?.percentage ?? 0;

    return commPercent > 0 ? [commPercent, 100 - commPercent] : [90, 10];
  }

  @Expose()
  get designationRateName(): string {
    return [this.designation, `(${this.designationRate.join('/')})`].join(' ');
  }
  //#endregion
}
