import { IImportPaymentColumn } from '../interfaces';

export const IMPORT_PAYMENT_DATA: IImportPaymentColumn[] = [
  {
    id: 'paymentNumber',
    label: 'Payment Number',
    index: 0,
    required: false,
    maxLength: 20,
  },
  {
    id: 'paymentDate',
    label: 'Payment Date (dd/mm/yyyy)',
    index: 1,
    required: true,
    //maxLength: 300,
  },
  {
    id: 'type',
    label: 'Type (CH-Cheque, CS-Cash, BT-Bank Transfer)',
    index: 2,
    required: true,
    maxLength: 2,
  },
  {
    id: 'contactCode',
    label: 'Contact Code',
    index: 3,
    required: false,
    maxLength: 20,
  },
  {
    id: 'payerName',
    label: "Payee's Name",
    index: 4,
    required: false,
    maxLength: 300,
  },
  {
    id: 'chequeNumber',
    label: 'Cheque Number',
    index: 5,
    required: false,
    maxLength: 20,
  },
  {
    id: 'itemNo',
    label: 'Item No',
    index: 6,
    required: true,
    maxLength: 4,
  },
  {
    id: 'itemType',
    label: 'Item Type (VI-Invoice, VCN-Credit Note, CN-Customer Credit Note)',
    index: 7,
    required: false,
    maxLength: 10,
  },
  {
    id: 'description',
    label: 'Invoice Number [20] / Description',
    index: 8,
    required: true,
    maxLength: 300,
  },
  {
    id: 'itemAmount',
    label: 'Item Amount',
    index: 9,
    required: true,
    maxLength: 20,
  },
  {
    id: 'itemCreditAccount',
    label: 'Item Credit Account',
    index: 10,
    required: true,
    maxLength: 20,
  },
  {
    id: 'bankAccount',
    label: 'Bank Account',
    index: 11,
    required: true,
    maxLength: 20,
  },
  {
    id: 'profitCentre',
    label: 'Profit Centre',
    index: 12,
    required: false,
    maxLength: 20,
  },
  {
    id: 'currencySign',
    label: 'Currency Sign',
    index: 13,
    required: true,
    maxLength: 3,
  },
  {
    id: 'currencyRate',
    label: 'Currency Rate',
    index: 14,
    required: true,
    maxLength: 20,
  },
  {
    id: 'glEntry',
    label: 'GL Entry (Y/N) ',
    index: 15,
    required: true,
    maxLength: 1,
  },
  {
    id: 'recordId',
    label: 'Record ID',
    index: 16,
    required: false,
    maxLength: 300,
  },
  {
    id: 'invoiceItemNo',
    label: 'Item SN',
    index: 17,
    required: false,
    maxLength: 20,
  },
];
