import { IGrossCommissionEarned } from './gross-commission-earned.interface';

import { EProjectPropertyType, EProjectTransactionPropertyType } from '../enums';
import { ICommissionMatrix } from './commission-matrix.interface';
import { ICurrency } from './currency.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IProjectHICommMatrix } from './project-hi-commission-matrix.interface';
import { IProjectTransactionBuyerInfo } from './project-transaction-buyer-info.interface';
import { IProjectTransactionRelatedAgent } from './project-transaction-related-agent.interface';
import { IProjectTransactionRelatedCobroke } from './project-transaction-related-cobroke.interface';
import { IProject } from './project.interface';
import { ITransactedType } from './transacted-type.interface';
import { IUnit } from './unit.interface';
import { ProjectTransactionBdsModel, ProjectTransactionPdModel } from '../models';
import { IProjectTransactionOther } from './project-transaction-other.interface';
import { IProjectTransactionBds } from './project-transaction-bds.interface';
import { IProjectTransactionBonus } from './project-transaction-bonus.interface';
import { IProjectTransactionOverriding } from './project-transaction-overriding.interface';
import { IProjectTransactionPool } from './project-transaction-pool.interface';
import { IProjectTransactionCobrokeReferral } from './project-transaction-cobroke-referral.interface';
import { IProjectTransactionPd } from './project-transaction-pd.interface';

export interface IProjectTransactionSummary {
  main: IProjectTransactionRelatedAgent;
  leaders: IProjectTransactionRelatedAgent[];
  internals: IProjectTransactionRelatedAgent[];
  externals: IProjectTransactionRelatedCobroke[];
  hiProfit: {
    bdProfit?: number;
    bdReceived?: number;
    headHiProfit?: number;
    headHiReceived?: number;
    hiTierReceived?: number;
    ultimateProfit?: number;
  };
  profit: {
    agentTierProfit?: number;
    agentTierReceived?: number;
    companyTierProfit?: number;
    companyTierReceived?: number;
    profitSharingAmount?: number;
    ultimateProfit?: number;
  };
  transactionBds: IProjectTransactionBds[];
  transactionBonuses: IProjectTransactionBonus[];
  transactionOthers: IProjectTransactionOther[];
  transactionOverridings: IProjectTransactionOverriding[];
  transactionPdManagements: IProjectTransactionPd[];
  transactionPdOverrides: IProjectTransactionPd[];
  transactionPdReferrals: IProjectTransactionPd[];
  transactionPools: IProjectTransactionPool[];
  transactionReferrals: IProjectTransactionCobrokeReferral[];
}
