import { UpdatedByModel } from './updated-by.model';
import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { PersonalParticularModel } from './personal-particular.model';
import { PAYMENT_DUE_DATE_OPTIONS, PROJECT_TYPES, PROJECT_SALE_TYPES } from '../data';
import { IProject } from '../interfaces/project.interface';
import { AppointmentTypeModel } from './appointment-type.model';
import { CompanyModel } from './company.model';
import { CountryModel } from './country.model';
import { DeveloperModel } from './developer.model';
import { DistrictModel } from './district.model';
import { ManagementCommissionModel } from './management-commission.model';
import { KeyAppointmentModel } from './key-appointment.model';
import { EPaymentDueDate } from '../enums';
import { GstCategoryLookupModel } from './gst-category.model';

export class ProjectModel extends BaseModel implements IProject {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  type!: string;

  @Expose()
  tenure!: string;

  @Expose()
  tenureYear!: number;

  @Expose()
  address!: string;

  @Expose()
  districtId!: number;

  @Expose()
  @Type(() => DistrictModel)
  district!: DistrictModel;

  @Expose()
  countryId?: number;

  @Expose()
  @Type(() => CountryModel)
  country?: CountryModel;

  @Expose()
  entity!: string;

  @Expose()
  companyId!: number;

  @Expose()
  @Default(1)
  localCompanyId!: number;

  @Expose()
  @Type(() => CompanyModel)
  company!: CompanyModel;

  @Expose()
  paymentDueDate!: EPaymentDueDate;

  @Expose()
  paymentByDeveloper!: string;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => GstCategoryLookupModel)
  gstCategoryLookup?: GstCategoryLookupModel;

  @Expose()
  gstPercentage!: number;

  @Expose()
  managementCommId!: number;

  @Expose()
  managementCommCode!: string;

  @Expose()
  @Type(() => ManagementCommissionModel)
  managementComm!: ManagementCommissionModel;

  @Expose()
  developerId!: number;

  @Expose()
  developerCode!: string;

  @Expose()
  @Type(() => DeveloperModel)
  developer!: DeveloperModel;

  @Expose()
  totalUnit!: number;

  @Expose()
  status!: string;

  @Expose()
  saleType?: string;

  @Expose()
  appointmentTypeId?: number;

  @Expose()
  @Type(() => AppointmentTypeModel)
  appointmentType?: AppointmentTypeModel;

  @Expose()
  keyAppointments?: KeyAppointmentModel[];

  @Expose()
  salespersonId?: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson?: PersonalParticularModel;

  @Expose()
  startDate?: string;

  @Expose()
  endDate?: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  remarks!: string;

  //#region getters
  @Expose()
  get projectTypeDisplay() {
    return PROJECT_TYPES.find(type => type.id === this.type)?.name;
  }

  get projectInCharge(): KeyAppointmentModel | null {
    return this.keyAppointments?.find(appointment => this.id === appointment.projectId) ?? null;
  }

  get projectInChargeName(): string | null {
    const salesperson = this.projectInCharge?.salesperson;

    if (!salesperson) return null;
    if (salesperson.ceaRegNo) return `${salesperson.businessName} (${salesperson.ceaRegNo})`;

    return salesperson.businessName;
  }

  get paymentDueDateInfo() {
    return PAYMENT_DUE_DATE_OPTIONS[this.paymentDueDate] ?? null;
  }

  get saleTypeDisplay() {
    return PROJECT_SALE_TYPES.find(type => type.id === this.saleType)?.name;
  }

  @Expose()
  appTypes!: { appType: AppointmentTypeModel }[];

  @Expose()
  appointmentTypes!: AppointmentTypeModel[];

  //#endregion
}

// export class ProjectModel extends BaseModel{
//   @Expose()
//   id!: number;

//   @Expose()
//   developerId!: number;

//   @Expose()
//   @Type(()=> DeveloperModel)
//   developer!: DeveloperModel;

//   @Expose()
//   totalUnit!:number;

//   @Expose()
//   status!: string;
// }
