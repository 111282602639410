import { EManagementCommissionPdRole } from '../enums';
import { IAppointmentType } from './appointment-type.interface';

export interface IManagementCommissionPD {
  id: number;
  appointmentType: IAppointmentType;
  appointmentTypeId: number;
  appTypeCode: string;
  managementCommId: number;
  percentage: number;
  additional: number;
  pdRole?: EManagementCommissionPdRole;
}
