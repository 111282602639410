import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class AssetDisposalCreateDto extends BaseModel {
  @Expose()
  name!: string;
}

export class AssetDisposalUpdateDto extends BaseModel {
  @Expose()
  name!: string;
}
