import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { AppointmentTypeModel, PersonalParticularModel, ProjectTransactionModel, SplitPartModel } from '@shared/data-access/models';
import { EAgentSession, EManagementCommissionPdRole } from '@shared/data-access/enums';
import { IProjectTransactionPd } from '@shared/data-access/interfaces/project-transaction-pd.interface';
import { IAppointmentType } from '@shared/data-access/interfaces';

export class ProjectTransactionPdModel extends BaseModel implements IProjectTransactionPd {
  @Expose()
  id!: number;

  @Expose()
  additional!: number;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  @Transform(({ obj, value }) => {
    if (!value || !value?.id) {
      return obj?.tiers?.[0]?.salesperson;
    }
    return value;
  })
  salesperson!: PersonalParticularModel;

  @Expose()
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  @Transform(({ value }) => AppointmentTypeModel.fromJson(value))
  appointmentType!: IAppointmentType;

  @Expose()
  data!: any;

  @Expose()
  pdRole!: EManagementCommissionPdRole;

  @Expose()
  transactionId!: number;

  @Expose()
  @Type(() => ProjectTransactionModel)
  transaction!: ProjectTransactionModel;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedDirectPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  @Type(() => Number)
  totalAmount!: number;

  @Expose()
  isActive!: boolean;

  @Expose()
  name!: string;

  @Expose()
  note!: string;

  @Expose()
  agentType!: EAgentSession;

  @Expose()
  grouping!: string;


  @Expose({ groups: ['summary'] })
  @Transform(({ obj }) => {
    return obj?.receivedValue - obj?.totalAmount;
  })
  companyAmount!: number;
}
