import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import {
  BLankFormBdsSetUpDto,
  BlankFormBuyerDto,
  BlankFormCreateDto,
  BlankFormIcSetUpDto,
  BLankFormOtherFeeSetUpDto,
  BLankFormPdSetUpDto,
  BLankFormPdsPreviewDto,
  BLankFormReferralSetUpDto,
  BlankFormUpdateDto,
} from '@shared/data-access/dto';
import {
  BlankFormBdModel,
  BlankFormBuyerModel,
  BlankFormCommValueModel,
  BlankFormIcModel,
  BlankFormModel,
  BlankFormOtherFeeModel,
  BlankFormPdModel,
  BlankFormReferralModel,
  BlankFormStatusHistoryModel,
  BlankFormSummaryModel,
} from '@shared/data-access/models';
import { catchError, map, Observable, of } from 'rxjs';

export interface IBlankFormBuyerPayload {
  isOverride: boolean;
  blankFormId: number;
  data: BlankFormBuyerDto[];
}

@Injectable({
  providedIn: 'root',
})
export class BlankFormApiService {
  static BLANK_FORM = 'transaction/blank-form-transactions';
  static BLANK_FORM_FOR_BATCH = 'transaction/blank-form-transactions/for-batch';
  static BLANK_FORM_BONUSES = 'transaction/blank-form-bonuses';
  static BLANK_FORM_BONUSES_SET_UP = 'transaction/blank-form-bonuses/setup';
  static BLANK_FORM_OVERRIDINGS = 'transaction/blank-form-overridings';
  static BLANK_FORM_OVERRIDINGS_SET_UP = 'transaction/blank-form-overridings/setup';
  static BLANK_FORM_POOLS = 'transaction/blank-form-pools';
  static BLANK_FORM_POOLS_SET_UP = 'transaction/blank-form-pools/setup';
  static BLANK_FORM_REFERRALS = 'transaction/blank-form-referrals';
  static BLANK_FORM_REFERRALS_SET_UP = 'transaction/blank-form-referrals/setup';
  static BLANK_FORM_SUBMIT = 'transaction/blank-form-transactions/:id/submit';
  static BLANK_FORM_REWORK = 'transaction/blank-form-transactions/:id/rework';
  static BLANK_FORM_CONFIRM = 'transaction/blank-form-transactions/:id/action';
  static BLANK_FORM_SUBMIT_MULTIPLE = 'transaction/blank-form-transactions/submit';
  static BLANK_FORM_REWORK_MULTIPLE = 'transaction/blank-form-transactions/rework';
  static BLANK_FORM_CONFIRM_MULTIPLE = 'transaction/blank-form-transactions/action';
  static BLANK_FORM_STATUS_HISTORIES = 'transaction/blank-form-status-histories';
  static BLANK_FORM_PDS = 'transaction/blank-form-pds';
  static BLANK_FORM_PDS_SET_UP = 'transaction/blank-form-pds/setup';
  static BLANK_FORM_PDS_PREVIEW = 'transaction/blank-form-pds/preview';
  static BLANK_FORM_OTHER_FEE = 'transaction/blank-form-others';
  static BLANK_FORM_OTHER_FEE_SET_UP = 'transaction/blank-form-others/setup';
  static BLANK_FORM_BDS = 'transaction/blank-form-bds';
  static BLANK_FORM_BDS_SET_UP = 'transaction/blank-form-bds/setup';
  static BLANK_FORM_BUYER = 'transaction/blank-form-buyers';
  static BLANK_FORM_COMM_VALUE = 'transaction/blank-form-transactions/comm-value';
  static BLANK_FORM_VERIFICATION = 'transaction/blank-form-transactions/verification';
  static BLANK_FORM_SUMMARY = 'transaction/blank-form-transactions/summary';

  constructor(private _apiService: ApiService) {}

  get(id: number): Observable<BlankFormModel> {
    return this._apiService.get(BlankFormApiService.BLANK_FORM + '/' + id).pipe(map(res => BlankFormModel.fromJson(res)));
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<BlankFormModel>> {
    return this._apiService
      .get(BlankFormApiService.BLANK_FORM, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(BlankFormModel, data)));
  }

  searchForBatch(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_FOR_BATCH, query, option).pipe(map(data => new PaginationAdapter(BlankFormModel, data)));
  }

  create(data: BlankFormCreateDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM, data).pipe(map(res => BlankFormModel.fromJson(res)));
  }

  update(id: number, data: BlankFormUpdateDto) {
    return this._apiService.patch(BlankFormApiService.BLANK_FORM + '/' + id, data);
  }

  searchBonuses(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_BONUSES, query, option).pipe(map(data => new PaginationAdapter(BlankFormIcModel, data)));
  }

  setUpBonuses(data: BlankFormIcSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_BONUSES_SET_UP, data);
  }

  searchOverridings(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_OVERRIDINGS, query, option).pipe(map(data => new PaginationAdapter(BlankFormIcModel, data)));
  }

  setUpOverridings(data: BlankFormIcSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_OVERRIDINGS_SET_UP, data);
  }

  searchPools(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_POOLS, query, option).pipe(map(data => new PaginationAdapter(BlankFormIcModel, data)));
  }

  setUpPools(data: BlankFormIcSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_POOLS_SET_UP, data);
  }

  searchReferrals(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_REFERRALS, query, option).pipe(map(data => new PaginationAdapter(BlankFormReferralModel, data)));
  }

  setUpReferrals(data: BLankFormReferralSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_REFERRALS_SET_UP, data);
  }

  submit(id: number, reason?: string) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_SUBMIT, { id, data: { reason } });
  }

  rework(id: number, reason = '') {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_REWORK, { id, data: { reason } });
  }

  confirm(id: number, reason = '') {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_CONFIRM, { id, data: { reason } });
  }

  delete(id: number) {
    return this._apiService.delete(BlankFormApiService.BLANK_FORM + '/' + id);
  }

  submitMultiple(blankForms: { id: number; data: { reason: string } }[]) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_SUBMIT_MULTIPLE, { blankForms });
  }

  reworkMultiple(blankForms: { id: number; data: { reason: string } }[]) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_REWORK_MULTIPLE, { blankForms });
  }

  confirmMultiple(blankForms: { id: number; data: { reason?: string } }[]) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_CONFIRM_MULTIPLE, { blankForms });
  }

  searchStatusHistories(query = {}, option?: IApiOption) {
    return this._apiService
      .get(BlankFormApiService.BLANK_FORM_STATUS_HISTORIES, query, option)
      .pipe(map(data => new PaginationAdapter(BlankFormStatusHistoryModel, data)));
  }

  searchPds(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_PDS, query, option).pipe(map(data => new PaginationAdapter(BlankFormPdModel, data)));
  }

  setUpPds(data: BLankFormPdSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_PDS_SET_UP, data);
  }

  previewPds(data: BLankFormPdsPreviewDto): Observable<BlankFormPdModel[]> {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_PDS_PREVIEW, data).pipe(map(data => data.map((item: any) => BlankFormPdModel.fromJson(item))));
  }

  searchOtherFees(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_OTHER_FEE, query, option).pipe(
      map(data => new PaginationAdapter(BlankFormOtherFeeModel, data)),
      catchError(() =>
        of(
          new PaginationAdapter(BlankFormOtherFeeModel, {
            items: [],
            meta: {},
          })
        )
      )
    );
  }

  setUpOtherFees(data: BLankFormOtherFeeSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_OTHER_FEE_SET_UP, data);
  }

  searchBds(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_BDS, query, option).pipe(
      map(data => new PaginationAdapter(BlankFormBdModel, data)),
      catchError(() =>
        of(
          new PaginationAdapter(BlankFormBdModel, {
            items: [],
            meta: {},
          })
        )
      )
    );
  }

  setUpBds(data: BLankFormBdsSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_BDS_SET_UP, data);
  }

  // buyer
  searchBuyers(query = {}, option?: IApiOption): Observable<PaginationAdapter<BlankFormBuyerModel>> {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_BUYER, query, option).pipe(map(data => new PaginationAdapter(BlankFormBuyerModel, data)));
  }

  getBuyer(itemId: number): Observable<BlankFormBuyerModel> {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_BUYER + '/' + itemId).pipe(map(res => BlankFormBuyerModel.fromJson(res)));
  }

  createBuyer(data: IBlankFormBuyerPayload): Observable<BlankFormBuyerModel> {
    return this._apiService.post(`${BlankFormApiService.BLANK_FORM_BUYER}/setup`, data).pipe(map(res => BlankFormBuyerModel.fromJson(res)));
  }

  deleteBuyers(ids: number[], option?: IApiOption): Observable<unknown> {
    return this._apiService.delete(BlankFormApiService.BLANK_FORM_BUYER, { ids }, option);
  }

  getCommValueFromSource(source: 'transaction' | 'blankForm', sourceId: number) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_COMM_VALUE, { source, sourceId }).pipe(map(res => BlankFormCommValueModel.fromJson(res)));
  }

  searchVerification(query = {}, option?: IApiOption): Observable<PaginationAdapter<BlankFormModel>> {
    return this._apiService
      .get(BlankFormApiService.BLANK_FORM_VERIFICATION, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(BlankFormModel, data)));
  }

  // summary global
  getSummary(id: number, query = {}, option?: IApiOption): Observable<BlankFormSummaryModel> {
    return this._apiService.get(`${BlankFormApiService.BLANK_FORM_SUMMARY}/${id}`, query, option).pipe(map(data => BlankFormSummaryModel.fromJson(data)));
  }
}
