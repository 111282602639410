import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AUTH_API_LOADER, AuthApiLoader } from './auth-api.loader';
import { AuthLoginResponseDto, RefreshTokenResponseDto } from './auth.model';
import { AuthUtils } from './auth.utils';
import { AuthActionEvent } from './auth.service';
import { isPlatformServer } from '@angular/common';
import { AUTH_COOKIE_READER } from './auth.token';
import { LoginDto, LoginResponseDto } from '@shared/data-access/dto';

@Injectable()
export class AuthSSRProvider {
  actionEvent = new ReplaySubject<AuthActionEvent>(1);
  // cookieStorage
  cookieStorage = inject(AUTH_COOKIE_READER);
  // localStorage
  // storage = inject(AUTH_STORAGE);
  private authenticated = false;
  platformId = inject(PLATFORM_ID);
  isServer = isPlatformServer(this.platformId);
  /**
   * Constructor
   */
  constructor(@Inject(AUTH_API_LOADER) private apiService: AuthApiLoader) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for access token
   */
  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *  This function only run on Client
   * @param credentials
   */
  signIn(credentials: LoginDto): Observable<LoginResponseDto> {
    console.log('asdsadsad', credentials);
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this.apiService.signIn(credentials).pipe(
      switchMap((response: LoginResponseDto) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Push notification
        this.actionEvent.next(AuthActionEvent.LOGIN);

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   * This function only run on Client
   */
  signInUsingRefreshToken(): Observable<boolean> {
    console.log('signInUsingRefreshToken');
    // this.accessToken = '';
    if (!this.refreshToken || AuthUtils.isTokenExpired(this.refreshToken)) {
      return of(false);
    }
    // Renew token
    return this.apiService.refreshToken(this.refreshToken).pipe(
      switchMap((response: RefreshTokenResponseDto) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Return true
        return of(true);
      }),
      catchError(() =>
        // Return false
        of(false)
      )
    );
  }
  /**
   * Sign out
   * This function only run on Client
   */

  signOut(): void {
    // Remove the tokens from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    // Set the authenticated flag to false
    this.authenticated = false;

    // Push notification
    this.actionEvent.next(AuthActionEvent.LOGOUT);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    if (this.authenticated) {
      return of(true);
    }
    const c_user = this.cookieStorage.get('redoc.c_user');
    console.log('check c_user', c_user);
    if (!c_user) {
      return of(false);
    }
    return of(true);
  }
}
