<mat-form-field class="w-full" appearance="fill">
  <mat-label>{{ data.name }}</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="Start date" />
    <input matEndDate formControlName="end" placeholder="End date" />
  </mat-date-range-input>
  <!-- <input matInput [placeholder]="data.name || ''" [formControl]="dataUpcast" [matDatepicker]="picker" /> -->
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker (closed)="updateValue()"></mat-date-range-picker>
  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
</mat-form-field>
