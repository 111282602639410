export enum TemplateType {
  INVOICE = 'INVOICE',
  SUPPLIER_INVOICE = 'SUPPLIER_INVOICE',
  CUSTOMER_CREDIT_NOTE = 'CUSTOMER_CREDIT_NOTE',
  SUPPLIER_CREDIT_NOTE = 'SUPPLIER_CREDIT_NOTE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  RECEIPT = 'RECEIPT',
  PAYMENT = 'PAYMENT',
  Protection = 'Protection',
  Authorisation = 'Authorisation',
  Agreement = 'Agreement',
  INVOICE_PROFORMA = 'INVOICE_PROFORMA',
  PAYMENTADVICE = 'PaymentAdvice'
}
