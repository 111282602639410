import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { ProjectTransactionPoolModel } from './project-transaction-pool.model';
import { ProjectTransactionRelatedAgentModel } from './project-transaction-related-agent.model';
import { ProjectTransactionRelatedInternalCobrokeModel } from './project-transaction-related-internal-cobroke.model';
import { ProjectTransactionOverridingModel } from './project-transaction-overriding.model';
import { ProjectTransactionBonusModel } from './project-transaction-bonus.model';
import { ProjectTransactionBdsModel } from './project-transaction-bds.model';
import { ProjectTransactionPdModel } from './project-transaction-pd.model';
import { ProjectTransactionCobrokeReferralModel } from './project-transaction-cobroke-referral.model';
import { ProjectTransactionModel } from './project-transaction.model';
import { PROJECT_TRANSACTION_VERIFY_STATUS } from '../data';

export class AgentProjectTransactionModel extends BaseModel {

  @Expose()
  transaction!: ProjectTransactionModel;

  @Expose()
  @Type(() => ProjectTransactionBdsModel)
  bds!: ProjectTransactionBdsModel[];

  @Expose()
  @Type(() => ProjectTransactionBonusModel)
  bonuses!: ProjectTransactionBonusModel[];

  @Expose()
  @Type(() => ProjectTransactionOverridingModel)
  overridings!: ProjectTransactionOverridingModel;

  @Expose()
  @Type(() => ProjectTransactionPoolModel)
  pools!: ProjectTransactionPoolModel[];

  @Expose()
  @Type(() => ProjectTransactionRelatedAgentModel)
  main!: ProjectTransactionRelatedAgentModel;

  @Expose()
  @Type(() => ProjectTransactionRelatedAgentModel)
  leaders!: ProjectTransactionRelatedAgentModel[];

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionRelatedInternalCobrokeModel)
  internals!: ProjectTransactionRelatedInternalCobrokeModel[];

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionPdModel)
  pdManagements!: ProjectTransactionPdModel[];

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionPdModel)
  pdReferrals!: ProjectTransactionPdModel[];

  @Expose()
  @Type(() => ProjectTransactionPdModel)
  pdOverrides!: ProjectTransactionPdModel[];

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionCobrokeReferralModel)
  referrals!: ProjectTransactionCobrokeReferralModel[];
  // =============== End Calculating Comm ===============
}
