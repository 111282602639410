import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IBank } from '../interfaces';
import { UpdatedByModel } from './updated-by.model';

export class BankModel extends BaseModel implements IBank {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  // @Expose()
  // status!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  isOther!: boolean;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  swiftCode!: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  sequence?: number;

  @Expose()
  minAccountDigit!: number;

  @Expose()
  maxAccountDigit?: number;
}
