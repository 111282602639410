import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  static TEAMPLATES_PREVIEW_POINT = 'finance/payments/portal/templates/preview';

  constructor(private _apiService: ApiService) {}

  templatesPreview(data: object) {
    return this._apiService.post(PaymentApiService.TEAMPLATES_PREVIEW_POINT, data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }
}
