import { BaseModel, PhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { BrokerModel } from './broker.model';
import { IProjectTransactionRelatedCobroke } from '../interfaces';
import { SplitMatrixModel } from './split-matrix.model';
import { TransactedTypeValueModel } from './transacted-type-value.model';

export class ProjectTransactionRelatedCobrokeModel extends BaseModel implements IProjectTransactionRelatedCobroke {
  @Expose()
  id!: number;

  @Expose()
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  agentId!: number;

  @Expose()
  agentType!: string;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;

  @Expose()
  @Type(() => BrokerModel)
  agent!: BrokerModel;

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => SplitMatrixModel)
  splitMatrix!: SplitMatrixModel;

  @Expose()
  receivedDirectPercent!: number;

  @Expose()
  receivedPercent!: number;

  @Expose()
  receivedValue!: number;

  @Expose()
  transactedPercent!: number;

  @Expose()
  transactedValue!: number;

  @Expose()
  attrs!: {
    [key: string]: any;
  };

  @Expose()
  @Type(() => TransactedTypeValueModel)
  transacted!: TransactedTypeValueModel;

  @Expose({ groups: ['summary'] })
  totalAmount!: number;

  @Expose({ groups: ['summary'] })
  @Transform(({ obj }) => {
    return obj?.receivedValue - obj?.totalAmount;
  })
  companyAmount!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  salePersonId?: number;
}
