import { BaseModel, Default, IsoString } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { round } from 'lodash-es';
import {
  BATCH_RECEIPT_PAYMENT_MODE_DATA,
  CLIENT_TYPE_OPTIONS,
  EReceiptPaymentMode,
  PAYMENT_ADVICE_OPTIONS,
  RESALE_PROPERTY_TYPE_OPTIONS,
  RESALE_STATUSES,
  RESALE_TYPE_OPTIONS,
} from '../data';
import { ECreditNoteStatus, EPaymentReversalStatus, EReceiptStatus, EResaleAdjustmentStatus, EResalePropertyType, EResaleStatus, EResaleType } from '../enums';
import {
  IResaleSearchEngine,
  IResaleSearchEngineAdjExternal,
  IResaleSearchEngineCreditNote,
  IResaleSearchEngineExternal,
  IResaleSearchEngineInternal,
  IResaleSearchEngineReceivedPayment,
  IStatusDescription,
} from '../interfaces';
import { ResaleDateKey } from '../types';
import { EClientType, EPaymentAdvice } from './../enums/resale.enum';

export class ResaleSearchEngineReceivedPaymentModel extends BaseModel implements IResaleSearchEngineReceivedPayment {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  receiptDate!: string;

  @Expose()
  reversalDate!: string;

  @Expose()
  @Transform(({ obj }) => (obj.reversalDate ? obj.totalAmount * -1 : obj.totalAmount))
  totalAmount!: number;

  @Expose()
  reference!: string;

  @Expose()
  businessUnitId!: number;

  @Expose()
  remarks!: string;

  @Expose()
  outstandingAmount!: number;

  @Expose()
  invoiceTotal!: number;

  @Expose()
  status!: EReceiptStatus | EPaymentReversalStatus;

  @Expose()
  get paymentMode(): EReceiptPaymentMode {
    if (this.reversalDate) {
      return EReceiptPaymentMode.reversal;
    }
    if (this.totalAmount >= this.invoiceTotal) {
      return EReceiptPaymentMode.full;
    }
    return EReceiptPaymentMode.partial;
  }

  @Expose()
  get paymentModeDisplay() {
    return BATCH_RECEIPT_PAYMENT_MODE_DATA[this.paymentMode];
  }
}

export class ResaleSearchEngineExternalModel extends BaseModel implements IResaleSearchEngineExternal {
  @Expose()
  @Type(() => Number)
  contactId!: number;

  @Expose()
  contactCode!: string;

  @Expose()
  contactName!: string;

  @Expose()
  @Type(() => Number)
  invoiceId?: number;

  @Expose()
  invoiceDeletedAt!: Date | null;

  @Expose()
  role!: string;

  @Expose()
  subTotalAmount!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  taxAmount!: number;
}

export class ResaleSearchEngineAdjExternalModel extends BaseModel implements IResaleSearchEngineAdjExternal {
  @Expose()
  @Type(() => Number)
  resaleAdjId!: number;

  @Expose()
  @Type(() => Number)
  invoiceId!: number | null;

  @Expose()
  resaleAdjStatus!: EResaleAdjustmentStatus;

  @Expose()
  resaleAdjCode!: string;

  @Expose()
  resaleAdjExternalContactId!: number;
}

export class ResaleSearchEngineInternalModel extends BaseModel implements IResaleSearchEngineInternal {
  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  role!: string;
}

export class VerifiedByModel extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  name!: string;
}

export class ResaleSearchEngineCreditNoteModel extends BaseModel implements IResaleSearchEngineCreditNote {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  @IsoString()
  creditNoteDate!: string;

  @Expose()
  remarks!: string;

  @Expose()
  status!: ECreditNoteStatus;

  @Expose()
  subTotalAmount!: number;

  @Expose()
  taxAmount!: number;

  @Expose()
  totalAmount!: number;
}

export class ResaleSearchEngineModel extends BaseModel implements IResaleSearchEngine {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @IsoString()
  createdAt!: string;

  @Expose()
  @IsoString()
  updatedAt!: string;

  @Expose()
  @IsoString()
  deletedAt!: string | null;

  @Expose()
  code!: string;

  @Expose()
  name!: string | null;

  @Expose()
  status!: EResaleStatus;

  @Expose()
  resaleType!: EResaleType;

  @Expose()
  propertyType!: EResalePropertyType;

  @Expose()
  address!: string | null;

  @Expose()
  @IsoString()
  submissionDate!: string | null;

  @Expose()
  @IsoString()
  agreementDate!: string | null;

  @Expose()
  @IsoString()
  commencementDate!: string | null;

  @Expose()
  @IsoString()
  optionDate!: string | null;

  @Expose()
  @IsoString()
  expireDate!: string | null;

  @Expose()
  @IsoString()
  completionDate!: string | null;

  @Expose()
  billingAttention!: string;

  @Expose()
  billingClientCode!: string;

  @Expose()
  @IsoString()
  billingDate!: string | null;

  @Expose()
  billingClientName!: string;

  @Expose()
  billingClientType!: `${EClientType}`;

  @Expose()
  @Type(() => Number)
  billingEnvDelivery!: number;

  @Expose()
  billingPaymentAdvice!: `${EPaymentAdvice}`;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  salespersonRole!: string;

  @Expose()
  salespersonBusinessName!: string;

  @Expose()
  salespersonNricName!: string;

  @Expose()
  salespersonCeaRegNo!: string | null;

  @Expose()
  externalRole!: string | null;

  @Expose()
  externalId!: number | null;

  @Expose()
  externalsSize!: number;

  @Expose()
  internalsSize!: number;

  @Expose()
  @Type(() => ResaleSearchEngineExternalModel)
  externals!: ResaleSearchEngineExternalModel[];

  @Expose()
  @Type(() => ResaleSearchEngineInternalModel)
  internals!: ResaleSearchEngineInternalModel[];

  @Expose()
  @Type(() => Number)
  invoiceId!: number;

  @Expose()
  invoiceIsCancel!: boolean;

  @Expose()
  invoiceIsWriteOff!: boolean;

  @Expose()
  invoiceCode!: string;

  @Expose()
  @IsoString()
  invoiceDate!: string | null;

  @Expose()
  @Type(() => Number)
  invoiceTotal!: number;

  @Expose()
  @Type(() => Number)
  invoicePaidAmount!: number;

  @Expose()
  @Type(() => Number)
  invoiceBalanceDue!: number;

  @Expose()
  receiptId!: number | null;

  @Expose()
  receiptCode!: string;

  @Expose()
  receiptReference!: string;

  @Expose()
  @IsoString()
  receiptDate!: string | null;

  @Expose()
  @Type(() => Number)
  receiptTotalAmount!: number | null;

  @Expose()
  @Type(() => ResaleSearchEngineReceivedPaymentModel)
  receipts!: ResaleSearchEngineReceivedPaymentModel[];

  @Expose()
  @Type(() => ResaleSearchEngineAdjExternalModel)
  resaleAdjExternals!: ResaleSearchEngineAdjExternalModel[];

  @Expose()
  @Type(() => ResaleSearchEngineReceivedPaymentModel)
  reversals!: ResaleSearchEngineReceivedPaymentModel[];

  @Expose()
  @Type(() => ResaleSearchEngineCreditNoteModel)
  @Default([])
  creditNotes!: ResaleSearchEngineCreditNoteModel[];

  @Expose()
  @Type(() => Number)
  subTotalAmount!: number;

  @Expose()
  @Type(() => Number)
  taxAmount!: number;

  @Expose()
  @Type(() => Number)
  totalAmount!: number;

  @Expose()
  @Type(() => Number)
  totalCommission!: number | null;

  @Expose()
  propertyAddress!: string;

  @Expose()
  remarksInternal!: string;

  @Expose()
  verifiedBy!: {
    id: number;
    name: string;
  };

  @Expose()
  @Transform(({ obj }) => {
    if (
      obj.status === EResaleStatus.draft ||
      obj.status === EResaleStatus.submitted ||
      obj.status === EResaleStatus.sendBack ||
      obj.status === EResaleStatus.withdrawn ||
      obj.status === EResaleStatus.confirmed
    ) {
      return false
    }
    else {
      return true
    }
  })
  showUpdatedBy!: boolean;

  // Additional fields
  @Expose()
  @Transform(({ obj }) => {
    if (!obj.creditNotes?.length) return 'default';

    const lastCreditNote = obj.creditNotes[0];
    switch (lastCreditNote.status.toUpperCase()) {
      case ECreditNoteStatus.CANCELLED:
        return 'cancelled';
      case ECreditNoteStatus.ABORTED:
        return 'aborted';
      default:
        return 'default';
    }
  })
  paymentDetailStatus!: 'cancelled' | 'aborted' | 'default';

  @Expose()
  @Transform(({ obj }) => {
    if (!obj.creditNotes?.length) return null;

    const lastCreditNote = obj.creditNotes[0];
    return lastCreditNote;
  })
  lastCreditNote!: ResaleSearchEngineCreditNoteModel | null;

  @Expose()
  @Transform(({ obj }) => {
    return RESALE_STATUSES[obj.status as EResaleStatus];
  })
  statusDescription!: IStatusDescription;

  @Expose()
  get receiveAmount() {
    const receiptList: ResaleSearchEngineReceivedPaymentModel[] = this.combinedReceipts();
    const receiptTotalAmount = receiptList.reduce((total, receipt) => total + receipt.totalAmount, 0);
    return receiptTotalAmount;
  }

  get propertyTypeDescription() {
    return this.propertyType ? RESALE_PROPERTY_TYPE_OPTIONS[this.propertyType] : null;
  }

  get resaleTypeDescription() {
    return this.resaleType ? RESALE_TYPE_OPTIONS[this.resaleType] : null;
  }

  get clientTypeDescription() {
    return this.billingClientType ? CLIENT_TYPE_OPTIONS[this.billingClientType]?.viewValue : null;
  }

  get paymentAdviceDescription() {
    return this.billingPaymentAdvice ? PAYMENT_ADVICE_OPTIONS[this.billingPaymentAdvice]?.viewValue : null;
  }

  get canGoToApprovalList(): boolean {
    return !!this.id && this.status === EResaleStatus.confirmed;
  }

  get canGoToInvoiceList(): boolean {
    return !!this.id && this.status === EResaleStatus.verified;
  }

  get canShowReceivedAmount(): boolean {
    return this.receipts.length > 0 && this.receipts.some(receipt => receipt.status === EReceiptStatus.confirmed);
  }

  get canShowInvoiceButton(): boolean {
    return (
      !!this.id &&
      [
        EResaleStatus.approved,
        EResaleStatus.approvedPC,
        EResaleStatus.received,
        EResaleStatus.receivedPartial,
        EResaleStatus.partial,
        EResaleStatus.cancelled,
        EResaleStatus.cancelledPartial,
        EResaleStatus.aborted,
        EResaleStatus.writeOff,
        EResaleStatus.rejected,
        EResaleStatus.completed,
      ].includes(this.status)
    );
  }

  // Buttons
  get canCreateReceipt(): boolean {
    return (
      !!this.id &&
      !!this.invoiceId &&
      !!this.invoiceCode &&
      [EResaleStatus.approved, EResaleStatus.approvedPC, EResaleStatus.receivedPartial, EResaleStatus.partial].includes(this.status) &&
      this.invoiceBalanceDue > 0
    );
  }

  get canCreateCreditNote(): boolean {
    return (
      !!this.id &&
      !!this.invoiceId &&
      !!this.invoiceCode &&
      [EResaleStatus.approved, EResaleStatus.approvedPC, EResaleStatus.receivedPartial, EResaleStatus.partial].includes(this.status) &&
      this.invoiceBalanceDue > 0
    );
  }

  get canCreateECBInvoice(): boolean {
    return (
      !!this.id &&
      this.externals &&
      this.externals.length > 0 &&
      [
        EResaleStatus.approved,
        EResaleStatus.aborted,
        EResaleStatus.received,
        EResaleStatus.receivedPartial,
        EResaleStatus.cancelled,
        EResaleStatus.cancelledPartial,
        EResaleStatus.partial,
        EResaleStatus.writeOff,
        EResaleStatus.completed,
        EResaleStatus.approvedPC,
      ].includes(this.status)
    );
  }

  // Popup
  get showReceivedPaymentTablePopup(): boolean {
    return (
      !!this.id &&
      [
        EResaleStatus.approved,
        EResaleStatus.receivedPartial,
        EResaleStatus.partial,
        EResaleStatus.cancelled,
        EResaleStatus.aborted,
        EResaleStatus.completed,
      ].includes(this.status)
    );
  }

  get canShowAssociatePayout(): boolean {
    return !!this.invoiceId;
  }

  get canShowExternalPayout(): boolean {
    return !!this.invoiceId && this.externals && this.externals.length > 0;
  }

  get canShowSummaryBeforeInvoice(): boolean {
    return !!this.id;
  }

  get canShowSummaryAfterInvoice(): boolean {
    return !!this.id && !!this.invoiceId;
  }

  get canShowSummaryAfterReceipt(): boolean {
    return !!this.id && !!this.invoiceId && this.receipts.length > 0;
  }

  canShowFieldDependOnResaleType(key: ResaleDateKey) {
    return RESALE_TYPE_OPTIONS[this.resaleType]?.correspondingField.includes(key);
  }

  combinedReceipts(): ResaleSearchEngineReceivedPaymentModel[] {
    const { invoiceTotal } = this;
    const validReversals = this.reversals.filter(reversal => reversal.status !== EPaymentReversalStatus.Cancel);

    const sortedReceipts = [...this.receipts, ...validReversals].sort(
      (a, b) => new Date(a.receiptDate || a.reversalDate).getTime() - new Date(b.receiptDate || b.reversalDate).getTime()
    );
    const combinedReceipts: ResaleSearchEngineReceivedPaymentModel[] = [];
    sortedReceipts.reduce((outstandingAmount, receipt) => {
      const { totalAmount } = receipt;
      outstandingAmount = round(outstandingAmount - totalAmount, 2);
      combinedReceipts.push(ResaleSearchEngineReceivedPaymentModel.merge(receipt, { invoiceTotal, outstandingAmount }));
      return outstandingAmount;
    }, invoiceTotal);

    // return combinedReceipts;

    const validReceipts = combinedReceipts.filter(receipt => receipt.status !== EReceiptStatus.cancelled);
    return validReceipts;
  }

  visibleReceipts(): ResaleSearchEngineReceivedPaymentModel[] {
    const { invoiceTotal } = this;

    const sortedReceipts = [...this.receipts, ...this.reversals].sort(
      (a, b) => new Date(a.receiptDate || a.reversalDate).getTime() - new Date(b.receiptDate || b.reversalDate).getTime()
    );

    const visibleReceipt: ResaleSearchEngineReceivedPaymentModel[] = [];
    sortedReceipts.reduce((outstandingAmount, receipt) => {
      const { totalAmount } = receipt;
      outstandingAmount = round(outstandingAmount - totalAmount, 2);
      visibleReceipt.push(ResaleSearchEngineReceivedPaymentModel.merge(receipt, { invoiceTotal, outstandingAmount }));
      return outstandingAmount;
    }, invoiceTotal);

    const validReceipts = visibleReceipt.filter(receipt => receipt.status !== EReceiptStatus.cancelled);
    return validReceipts;
  }
}
