import { Injectable } from "@angular/core";
import { ApiService } from "@red/api";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class UtilsApiService {
  static EJS_POINT = 'finance/utilities/ejs';

  constructor(private _apiService: ApiService) {}

  embeddedTemplate(html:string,params:Record<string,any>):Observable<string> {
    return this._apiService.post(UtilsApiService.EJS_POINT,{html,params});
  }
}
