import { ISplitMatrix } from './split-matrix.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { ISplitPart } from '@shared/data-access/interfaces/split-part.interface';
import { IProjectTransaction } from '@shared/data-access/interfaces/project-transaction.interface';
import {IReferralExternal} from "@shared/data-access/interfaces/referral-external.interface";

export interface IProjectTransactionReferral extends ISplitMatrix {
  id: number;
  salespersonId: number;
  salesperson: IPersonalParticular;
  agent: IReferralExternal;
  agentId: number;
  note: any;
  parties: ISplitPart[];
  splitMatrixId: number;
  tiers: ISplitPart[];
  transactionId: number;
  transaction: IProjectTransaction;
  isSplit: boolean;
  type: 'internal' | 'external';
  formula: string;
  fee: number;
  receivedValue: number;
  totalAmount: number;
  data: any;
  absorbType: { agent: boolean; hr: boolean; };
}
