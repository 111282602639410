import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IProjectConsultantMember } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';
import { PersonalParticularModel } from './personal-particular.model';
import { ProjectConsultantModel } from './project-consultant.model';
import { UpdatedByModel } from './updated-by.model';

export class ProjectTeamMemberModel extends BaseModel implements IProjectConsultantMember {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  teamId!: number;

  @Expose()
  @Type(() => ProjectConsultantModel)
  team!: ProjectConsultantModel;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  @Type(() => AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;

  @Expose()
  startDate!: string;

  @Expose()
  endDate!: string;

  @Expose()
  isLeader!: boolean;

  @Expose()
  status!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;
}
