import { Expose, Transform } from 'class-transformer';
import { IProjectHICommMatrix } from '../interfaces';
import { OverridingDistributionModel } from './overriding-distribution.model';
import { ProjectCommissionMatrixModel } from './project-commission-matrix.model';

export class ProjectHICommMatrixModel extends ProjectCommissionMatrixModel implements IProjectHICommMatrix {
  @Expose()
  commissionMatrixId!: number | null;

  @Expose()
  unitId!: number | null;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Transform(({ value }) => {
    if (!value) return null;
    return {
      agent: value.agent,
      hr: value.hr,
      disOverridingId: value.disOverridingId,
      disOverridingCode: value.disOverridingCode,
      disOverriding: OverridingDistributionModel.fromJson(value.disOverriding),
      value: value.value,
    };
  })
  headHi!: {
    agent: boolean;
    hr: boolean;
    disOverriding: OverridingDistributionModel;
    disOverridingId: number;
    disOverridingCode: string;
    value: number;
  };

  @Expose()
  @Transform(({ value }) => {
    if (!value) return null;
    return {
      agent: value.agent,
      hr: value.hr,
      disOverridingId: value.disOverridingId,
      disOverridingCode: value.disOverridingCode,
      disOverriding: OverridingDistributionModel.fromJson(value.disOverriding),
      value: value.value,
    };
  })
  bd!: {
    agent: boolean;
    hr: boolean;
    value: number;
    disOverriding: OverridingDistributionModel;
    disOverridingId: number;
    disOverridingCode: string;
  };

  @Expose()
  @Transform(({ value }) => {
    if (!value) return null;
    return {
      agent: value.agent,
      hr: value.hr,
      value: value.value,
    };
  })
  hiProfit!: {
    agent: true;
    hr: true;
    value: 1;
  };

  @Expose()
  submittedAt!: string | null;

  @Expose()
  submittedBy!: string | null;

  @Expose()
  reviewedAt!: string | null;

  @Expose()
  reviewedBy!: string | null;

  @Expose()
  createdAt!: string;

  @Expose()
  deletedAt!: string | null | undefined;

  @Expose()
  createdBy!: string | null | undefined;

  @Expose()
  deletedBy!: string | null | undefined;
}
