import { IUpdatedBy } from './updated-by.interface';

export interface ITransferEmplacementHistory {
  businessUnitId: number;
  manager: {
    id: number;
    businessName: string;
    nricName: string;
    nricfin: string;
    ceaRegNo: string;
    isActive: boolean;
  };
  managerId: number;
  salespersonId: number;
  effectiveDate: string;
  remarks: string;
  type: string;
  createdAt: string;
  createdBy: string;
  deletedAt: string;
  deletedBy: string;
  updatedBy: IUpdatedBy;
  updatedAt: string;
}
