import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsCurrencyPipe, CurrencyPipeModule } from '../../pipes/currency';

@NgModule({
  declarations: [],
  providers: [CurrencyPipe, CmsCurrencyPipe],
  exports: [],
  imports: [CurrencyPipeModule],
})
export class CurrencyDirectiveModule {}
