import { BaseModel } from '@red/data-access';
import { IExternalPayout } from '../interfaces';
import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class ExternalPayoutModel extends BaseModel implements IExternalPayout {
  @Expose()
  agreedComm!: number;

  @Expose()
  value!: number;

  @Expose()
  gstAmount!: number;

  @Expose()
  gstType!: string;

  @Expose()
  invoiceNumber!: string;

  @Expose()
  ecbInvoiceDate!: string;

  @Expose()
  payoutDate!: string;

  @Expose()
  chequeNumber!: string;

  @Expose()
  contactName!: string;
}
