import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { FileUploaderModel, PaginationAdapter } from '@red/data-access';
import { IPersonalParticular } from '@shared/data-access/interfaces';
import { PersonalParticularModel, TransferEmplacementHistoryModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PersonalParticularApiService {
  static SALESPERSONS = 'hrm/salespersons';
  static MYINFO = 'hrm/salespersons/my-info';
  static DOCUMENTS = 'hrm/documents';
  static COMMON_SALESPERSONS = 'hrm/salespersons/list-common';

  constructor(protected apiService: ApiService) { }

  create(data: Partial<IPersonalParticular>): Observable<PersonalParticularModel> {
    return this.apiService
      .post(PersonalParticularApiService.SALESPERSONS, data, { removeEmpty: { enable: true } })
      .pipe(map((res: IPersonalParticular) => PersonalParticularModel.fromJson(res)));
  }

  update(id: number, data: Partial<IPersonalParticular>): Observable<unknown> {
    return this.apiService.patch(PersonalParticularApiService.SALESPERSONS + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this.apiService.get(PersonalParticularApiService.SALESPERSONS, query, option).pipe(map(data => new PaginationAdapter(PersonalParticularModel, data)));
  }

  searchCommon(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this.apiService
      .get(PersonalParticularApiService.COMMON_SALESPERSONS, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(PersonalParticularModel, data)));
  }

  delete(itemIds: number[], option?: IApiOption): Observable<unknown> {
    return this.apiService.delete(PersonalParticularApiService.SALESPERSONS, { salespersonIds: itemIds }, option);
  }

  get(itemId: number): Observable<PersonalParticularModel> {
    return this.apiService.get(PersonalParticularApiService.SALESPERSONS + '/' + itemId).pipe(map(res => PersonalParticularModel.fromJson(res)));
  }

  getHead(id: number) {
    return this.apiService.get(PersonalParticularApiService.SALESPERSONS + '/' + id + '/count-member');
  }

  getHeadDivision(id: number) {
    return this.apiService.get(PersonalParticularApiService.SALESPERSONS + '/' + id + '/head-of-team');
  }

  getInfo(query = {}, option?: IApiOption): Observable<PersonalParticularModel> {
    return this.apiService.get(PersonalParticularApiService.MYINFO, query, option).pipe(map(res => PersonalParticularModel.fromJson(res)));
  }

  familyTree(id: number, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this.apiService
      .get([PersonalParticularApiService.SALESPERSONS, id, 'descendants'].join('/'), {}, option)
      .pipe(map(data => new PaginationAdapter(PersonalParticularModel, { meta: {}, items: data })));
  }

  getDocument(salespersonId: number): Observable<FileUploaderModel[]> {
    return this.apiService
      .get([PersonalParticularApiService.DOCUMENTS, salespersonId].join('/'))
      .pipe(map((res: unknown[]) => res.map((item: unknown) => FileUploaderModel.fromJson(item))));
  }

  uploadDocument(salespersonId: number, file: FileUploaderModel): Observable<unknown> {
    return this.apiService.post([PersonalParticularApiService.DOCUMENTS, salespersonId].join('/'), { name: file.name, url: file.key, size: file.size });
  }

  deleteDocument(documentId: number): Observable<unknown> {
    return this.apiService.delete([PersonalParticularApiService.DOCUMENTS, documentId].join('/'));
  }

  getCommonSalesperson(id: number, query = {}, option?: IApiOption) {
    return this.apiService
      .get([PersonalParticularApiService.SALESPERSONS, 'common', id].join('/'), query, option)
      .pipe(map(data => PersonalParticularModel.fromJson(data)));
  }
}
