import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { AccountingPeriodsCreateDto } from '@shared/data-access/dto';
import { IAccountingPeriods } from '@shared/data-access/interfaces';
import { AccountingPeriodsModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountingPeriodsApiService {
  static ROOT_POINT = 'finance/account-period';

  constructor(private apiService: ApiService) {}

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<AccountingPeriodsModel>> {
    return this.apiService.get(AccountingPeriodsApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(AccountingPeriodsModel, data)));
  }

  getLastAccountingPeriod(query = {}): Observable<AccountingPeriodsModel> {
    return this.apiService
      .get(`${AccountingPeriodsApiService.ROOT_POINT}/last`, query)
      .pipe(map((res: IAccountingPeriods) => AccountingPeriodsModel.fromJson(res) as AccountingPeriodsModel));
  }

  closePeriod(data: AccountingPeriodsCreateDto): Observable<AccountingPeriodsModel> {
    return this.apiService
      .post(`${AccountingPeriodsApiService.ROOT_POINT}`, data)
      .pipe(map((res: IAccountingPeriods) => AccountingPeriodsModel.fromJson(res) as AccountingPeriodsModel));
  }

  openLastPeriod(data = {}): Observable<AccountingPeriodsModel> {
    return this.apiService
      .post(`${AccountingPeriodsApiService.ROOT_POINT}/last/open`, data)
      .pipe(map((res: IAccountingPeriods) => AccountingPeriodsModel.fromJson(res) as AccountingPeriodsModel));
  }
}
