import { EAgentSession } from "../enums";

export const AgentSession = {
    [EAgentSession.ClosingAgent]: 'Closing Agent',
    [EAgentSession.Internal]: 'Co-Broke',
    [EAgentSession.TeamLeader]: 'Group Leader/LIC',
    [EAgentSession.Pool]: 'Pool',
    [EAgentSession.Overriding]: 'IC Comm',
    [EAgentSession.Bonus]: 'IC Bonus',
    [EAgentSession.InternalRefferal]: '⁠Internal Referral',
    [EAgentSession.PDOverride]: 'PD Override',
    [EAgentSession.PDReferral]: 'PD Referral',
    [EAgentSession.PDManagement]: 'PD Management',
    [EAgentSession.OTHManagement]: 'OTH Management',
    [EAgentSession.HeadHI]: 'Head of HI',
    [EAgentSession.BusinessDirector]: 'BDs',
}