import { Component, Inject, Input } from '@angular/core';
import { Router, Params } from '@angular/router';
import { METABASE_PREFIX_URL } from '../metabase.module';

@Component({
  selector: 'red-metabase-link',
  templateUrl: './metabase-link.component.html',
  styleUrls: ['./metabase-link.component.scss'],
})
export class MetabaseLinkComponent {
  constructor(@Inject(Router) private router: Router, @Inject(METABASE_PREFIX_URL) private prefix: string) {}
  @Input()
  set metabaseLink(url: string) {
    const tree = this.router.parseUrl(url);
    const route = [`/${this.prefix}`, ...tree.root.children['primary'].segments.map(segment => segment.path)];
    const queryParams = tree.queryParams;
    this._queryParams = queryParams;
    this._routerLink = route;
  }
  _routerLink: string[] = [];
  _queryParams: Params | null = null;
}
