import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IProjectTransactionAgentAdvises, IProjectTransactionRelatedAgent } from '../interfaces';
import { CategoryModel } from './category.model';
import { PersonalParticularModel } from './personal-particular.model';
import { ProjectConsultantModel } from './project-consultant.model';
import { SplitMatrixModel } from './split-matrix.model';
import { ProfitSplitPartModel, SplitPartModel } from './split-part.model';
import { TransactedTypeValueModel } from './transacted-type-value.model';
import { EAgentSession } from '../enums';

// import { SplitPartEnhancedModel } from "./split-part.model";

export class ProjectTransactionRelatedAgentModel extends BaseModel implements IProjectTransactionRelatedAgent {
  @Expose()
  id!: number;

  @Expose()
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  agentId!: number;

  @Expose()
  agentType!: EAgentSession;

  @Expose()
  @Default({})
  @Type(() => PersonalParticularModel)
  @Transform(({ obj, value }) => {
    if (!value || !value?.id) {
      return obj?.tiers?.[0]?.salesperson;
    }
    return value;
  })
  agent!: PersonalParticularModel;

  @Expose()
  @Transform(({ obj, value }) => {
    if (value) {
      return value;
    }
    return obj?.teamId ? 'team' : 'hyperTeam';
  })
  teamType!: string;

  @Expose()
  teamId!: number;

  @Expose()
  @Type(() => ProjectConsultantModel)
  team!: ProjectConsultantModel;

  @Expose()
  isCobroke!: boolean;

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => SplitMatrixModel)
  splitMatrix!: SplitMatrixModel;

  @Expose()
  @Default([])
  @Type(() => SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  @Default([])
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  @Default({})
  @Type(() => TransactedTypeValueModel)
  transacted!: TransactedTypeValueModel;

  @Expose()
  receivedDirectPercent!: number;

  @Expose()
  receivedPercent!: number;

  @Expose()
  @Default(0)
  receivedValue!: number;

  @Expose()
  transactedPercent!: number;

  @Expose()
  transactedValue!: number;

  @Expose({ groups: ['summary'] })
  totalAmount!: number;

  @Expose({ groups: ['summary'] })
  @Transform(({ obj }) => {
    return obj?.receivedValue - obj?.totalAmount;
  })
  companyAmount!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  @Default([])
  @Type(() => ProfitSplitPartModel)
  profitTiers!: ProfitSplitPartModel[];

  @Expose()
  categoryId!: number;

  @Expose()
  grouping!: string;

  @Expose()
  @Default({})
  @Type(() => CategoryModel)
  category!: CategoryModel;

  @Expose()
  @Transform(({ obj }) => {
    if (obj.receivedPercent === 0) {
      return false;
    } else {
      return true;
    }
  })
  isActive!: boolean;

  @Expose()
  appTypeCode!: string;

  @Expose()
  gce!: number;

  @Expose()
  advises!: IProjectTransactionAgentAdvises[];

  @Expose()
  attrs!: {
    [key: string]: any;
  };

}
