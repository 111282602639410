import { Observable, Subject, debounceTime, map, takeUntil, tap } from 'rxjs';
import { get } from 'lodash-es';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, InjectionToken, OnDestroy, OnInit, ViewChild, forwardRef, inject } from '@angular/core';
import {
  ExceljsCard,
  ExceljsParameter,
  ExceljsParameterApiTemplateRef,
  ExceljsParameterTemplateRef,
  ExceljsParameterTemplateRefOnChangeObject,
  IExceljsParameter,
} from '../../models';
import { ExceljsQueryParam, getNormalizedString } from '../../exceljs.utils';
import { MatTable, MatTableModule } from '@angular/material/table';
import { EmptyModule } from '@red/pipes/empty';
import { PagingDataSource, Sort } from '@shared/core';
import { PaginationAdapter, PaginationModel } from '@red/data-access';
import { EXCELJS_HTTP_LOADER } from '../../exceljs-http-loader.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { LoadingBoxModule } from '@shared/components/loading-box';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ExceljsParameterComponent } from '../../exceljs-parameter/exceljs-parameter.component';
import { ExceljsParameterService } from '../../exceljs-parameter/exceljs-parameter.service';
import { FilterModule } from '@red/pipes/filter';
import { MatButtonModule } from '@angular/material/button';
export type ExceljsTableSelectionMode = 'single' | 'multi';
export const EXCELJS_TABLE_SELECTION_MODE = new InjectionToken<ExceljsTableSelectionMode>('EXCELJS_TABLE_SELECTION_MODE', {
  providedIn: 'root',
  factory: () => 'single',
});
export const EXCELJS_TABLE_INITIALLY_SELECTED = new InjectionToken<Record<string, any>[]>('EXCELJS_TABLE_INITIALLY_SELECTED', {
  providedIn: 'root',
  factory: () => [],
});
export const EXCELJS_TABLE_PARAMETER_DEFAULT_VALUE = new InjectionToken<Record<string, any>>('EXCELJS_TABLE_PARAMETER_DEFAULT_VALUE');
export const EXCELJS_TABLE_COMPARE_WITH_FN = new InjectionToken<(o1: any, o2: any) => boolean>('EXCELJS_TABLE_COMPARE_WITH_FN', {
  providedIn: 'root',
  factory: () => (o1: any, o2: any) => o1 === o2,
});

// export const EXCELJS_TABLE_FETCH_DATA_FN = new InjectionToken<(filters: Record<string, any>) => Observable<PaginationAdapter<any>>>('EXCELJS_TABLE_FETCH_DATA_FN');
export const EXCELJS_TABLE_TEMPLATE_REF = new InjectionToken<ExceljsParameterTemplateRef>('EXCELJS_TABLE_TEMPLATE_REF');

@Component({
  selector: 'red-exceljs-table-selection',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBoxModule,
    EmptyModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    FilterModule,
    MatButtonModule,
    forwardRef(() => ExceljsParameterComponent),
  ],
  templateUrl: './exceljs-table-selection.component.html',
  styleUrls: ['./exceljs-table-selection.component.scss'],
  providers: [ExceljsParameterService],
})
export class ExceljsTableSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
  selectionModel!: SelectionModel<Record<string, any>>;
  dataSource!: PagingDataSource<any, Record<string, any>>;
  parameters: ExceljsParameter[] = [];
  masterSearchControl = new FormControl('');
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<any>;
  private mode = inject(EXCELJS_TABLE_SELECTION_MODE);
  private initiallySelectedValues = inject(EXCELJS_TABLE_INITIALLY_SELECTED);
  private compareWithFn = inject(EXCELJS_TABLE_COMPARE_WITH_FN);
  private _parameterService = inject(ExceljsParameterService);
  private defaultValue = inject(EXCELJS_TABLE_PARAMETER_DEFAULT_VALUE, { optional: true })
  templateRef = inject(EXCELJS_TABLE_TEMPLATE_REF);
  private apiConfig: { method: 'get' | 'post'; endpoint: string } =
    typeof this.templateRef.api === 'string'
      ? { method: 'get', endpoint: this.templateRef.api }
      : ({ ...this.templateRef.api, method: this.templateRef.api.method.toLocaleLowerCase() } as { method: 'get' | 'post'; endpoint: string });
  private apiService = inject(EXCELJS_HTTP_LOADER);
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  templateRefNormalized = this.normalizeTemplateRef(this.templateRef);
  columns!: {
    columnDef: string;
    header: string;
    cell?: (item: any, index: number) => any;
    click?: (event: any, item: any) => void;
  }[];
  displayedColumns!: string[];
  fakeCard!: ExceljsCard;
  latestFilters: Record<string, any> = {};
  showItemSelected = false;
  hasFilter = false;
  constructor() {
    this.selectionModel = new SelectionModel(this.mode === 'multi', this.initiallySelectedValues, true, this.compareWithFn.bind(this));
    console.log('   this.selectionModel', this.selectionModel);
    this.columns = [
      {
        columnDef: 'index',
        header: 'SN',
        cell: (item: any, index: number) => {
          const num = (this.dataSource.pagination.page - 1) * this.dataSource.pagination.limit + index + 1;
          return num
        },
      },
      ...this.templateRefNormalized.columns.map(col => ({
        columnDef: col.columnRef,
        header: col.header,
        cell: (item: any) => {
          return getNormalizedString(col.cell, item);
        },
      })),
      {
        columnDef: 'action',
        header: '',

      },
    ];
    this.displayedColumns = this.columns.map(col => col.columnDef);
    let sorts: Sort<any> = { orderBy: 'updatedAt', orderType: 'asc' };
    const querys = (this.templateRefNormalized.api as ExceljsParameterApiTemplateRef).query;
    querys.forEach((q) => {
      if (q.slug === 'orderBy' && q.default) {
        sorts.orderBy = q.default as string;
      }
      if (q.slug === 'orderType' && q.default) {
        sorts.orderType = q.default as "asc" | "desc";
      }
    })

    if (!this.dataSource) {
      this.dataSource = new PagingDataSource<any, Record<string, any>>(
        (filter: Record<string, any>) =>
          this.apiService.get(this.apiConfig.method, this.apiConfig.endpoint, filter).pipe(
            map(data => {
              console.log('hello tap method-->', data);
              this.latestFilters = filter;
              return {
                results: data.items,
                pagination: PaginationModel.fromJson(data.meta),
              };
            })
          ),
        10,
        sorts
      );
    } else {
      this.dataSource.queryBy({});
    }
    const queries = (this.templateRefNormalized.api as ExceljsParameterApiTemplateRef).query?.map(item => {
      item.default = this.defaultValue && typeof this.defaultValue[item.slug] !== 'undefined' ? this.defaultValue[item.slug] : item.default;
      return item
    })
    this.fakeCard = ExceljsCard.fromJson({
      parameters: queries,
    });
    // this.dataSource.itemsSubject.pipe(tap(data => console.log('hello tap -->', data))).subscribe();
    this.parameters = this.fakeCard.parameters;
    this.hasFilter = this.parameters.filter(parameter => {
      return ['key', 'page', 'limit', 'orderBy', 'orderType'].every(key => key !== parameter.slug)
    }).length > 0
    this._parameterService
      .connect()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(val => {
        val.set('key', this.masterSearchControl.value)
        // console.log('---_parameterService connect---', val);
        this.dataSource.queryBy(ExceljsQueryParam.deserialize(this.parameters, val));
      });
    // console.log('---ExceljsTableSelectionComponent----', this.fakeCard, this.parameters, this.templateRefNormalized);
    this.selectionModel.changed.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {
      if (this.selectionModel.selected.length === 0) {
        this.showItemSelected = false;
      }
    })
  }

  ngOnInit() { }
  ngAfterViewInit(): void {
    this.paginator.page.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => this.dataSource.page(val.pageIndex + 1));
    this.masterSearchControl.valueChanges.pipe(debounceTime(300), takeUntil(this._unsubscribeAll)).subscribe(val => {
      this.dataSource.queryBy({ key: val || '' });
    });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  normalizeTemplateRef(template: ExceljsParameterTemplateRef): ExceljsParameterTemplateRef {
    return { ...template, columns: template.columns ? template.columns : [], api: this.normalizeApiOption(template.api) };
  }
  normalizeApiOption(api: string | ExceljsParameterApiTemplateRef) {
    const apiConfig: { method: 'get' | 'post'; endpoint: string; query: IExceljsParameter[] } =
      typeof api === 'string'
        ? { method: 'get', endpoint: api, query: [] }
        : ({ ...api, method: api.method.toLocaleLowerCase() } as { method: 'get' | 'post'; endpoint: string; query: IExceljsParameter[] });
    return apiConfig;
  }
  compareFn(item: IExceljsParameter): boolean {
    return item.slug !== 'key'
  }

  getCurrentFilters() {
    return this.latestFilters
  }
  toggle(item: any) {
    if (this.selectionModel.isSelected(item)) {
      const selected = this.selectionModel.selected.find(data => this.compareWithFn(data, item));
      if (selected) {
        this.selectionModel.deselect(selected)
      }

    } else {
      this.selectionModel.select(item)
    }
    // this.selectionModel.toggle(item)
  }
  previewItemSeleted() {
    this.showItemSelected = !this.showItemSelected;
  }
}
