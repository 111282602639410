import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IInternalCobrokeReferral } from '../interfaces';
import { PersonalParticularModel } from './personal-particular.model';

export class InternalCobrokeReferralModel extends BaseModel implements IInternalCobrokeReferral {
  @Expose()
  id!: number;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  formula!: string;

  @Expose()
  fee!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;
}
