import { BaseModel, Default, EndOfDate, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { AbstractListFilters } from '@shared/core';

export class ProjectCreateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  type!: string;

  @Expose()
  tenure!: string;

  @Expose()
  tenureYear!: number;

  @Expose()
  address!: string;

  @Expose()
  districtId!: number;

  @Expose()
  entity!: string;

  @Expose()
  companyId!: number;

  @Expose()
  countryId!: number;

  @Expose()
  @Type(() => Number)
  paymentDueDate!: number;

  @Expose()
  paymentByDeveloper!: string;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  gstPercentage!: number;

  @Expose()
  managementCommId!: number;

  @Expose()
  developerId!: number;

  @Expose()
  managementCommCode!: string;

  @Expose()
  developerCode!: string;

  @Expose()
  status!: string;

  @Expose()
  saleType?: string;

  @Expose()
  appointmentTypeId?: number;

  @Expose()
  salespersonId?: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate?: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  endDate?: string;

  @Expose()
  appTypes!: { appTypeId: number }[];

  @Expose()
  remarks!: string;
}

export class ProjectUpdateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  type!: string;

  @Expose()
  tenure!: string;

  @Expose()
  tenureYear!: number;

  @Expose()
  address!: string;

  @Expose()
  districtId!: number;

  @Expose()
  entity!: string;

  @Expose()
  companyId!: number;

  @Expose()
  @Type(() => Number)
  paymentDueDate!: number;

  @Expose()
  paymentByDeveloper!: string;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  gstPercentage!: number;

  @Expose()
  managementCommId!: number;

  @Expose()
  developerId!: number;

  @Expose()
  status!: string;

  @Expose()
  countryId!: number;

  @Expose()
  saleType?: string;

  @Expose()
  appointmentTypeId?: number;

  @Expose()
  salespersonId?: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate?: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  endDate?: string;

  @Expose()
  appTypes!: { appTypeId: number }[];
}

export class ProjectUpdateRemarkDto extends BaseModel {
  @Expose()
  remarks!: string;
}
export class ProjectFilters extends AbstractListFilters {
  @Expose()
  @Default('all')
  searchType!: string;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  address!: string;

  @Expose()
  district!: string;

  @Expose()
  totalUnit!: number;

  @Expose()
  paymentByDevelopers!: string;

  // @Expose()
  // salesperson!: string;

  @Expose()
  salespersonIds!: number[];

  @Expose()
  status!: string;

  @Expose()
  developer!: string;

  @Expose()
  remarks!: string;
}
