import { ProjectTransactionPropertyTypeRecord } from '../types';
import { EProjectTransactionPropertyType } from '../enums';

export const PROJECT_TRANSACTION_PROPERTY_TYPE_OPTIONS: ProjectTransactionPropertyTypeRecord = {
  [EProjectTransactionPropertyType.ResidentialCondominiumApartment]: {
    id: EProjectTransactionPropertyType.ResidentialCondominiumApartment,
    value: EProjectTransactionPropertyType.ResidentialCondominiumApartment,
    viewValue: 'Residential - Condominium / Apartment',
  },
  [EProjectTransactionPropertyType.ResidentialExecutiveCondominium]: {
    id: EProjectTransactionPropertyType.ResidentialExecutiveCondominium,
    value: EProjectTransactionPropertyType.ResidentialExecutiveCondominium,
    viewValue: 'Residential - Executive Condominium',
  },
  [EProjectTransactionPropertyType.LandedTerraceHouse]: {
    id: EProjectTransactionPropertyType.LandedTerraceHouse,
    value: EProjectTransactionPropertyType.LandedTerraceHouse,
    viewValue: 'Landed - Terrace House',
  },
  [EProjectTransactionPropertyType.LandedSemiDetachedHouse]: {
    id: EProjectTransactionPropertyType.LandedSemiDetachedHouse,
    value: EProjectTransactionPropertyType.LandedSemiDetachedHouse,
    viewValue: 'Landed - Semi-Detached House',
  },
  [EProjectTransactionPropertyType.LandedDetachedHouse]: {
    id: EProjectTransactionPropertyType.LandedDetachedHouse,
    value: EProjectTransactionPropertyType.LandedDetachedHouse,
    viewValue: 'Landed - Detached House',
  },
  [EProjectTransactionPropertyType.LandedStrataLanded]: {
    id: EProjectTransactionPropertyType.LandedStrataLanded,
    value: EProjectTransactionPropertyType.LandedStrataLanded,
    viewValue: 'Landed - Strata Landed',
  },
  [EProjectTransactionPropertyType.CommercialOffice]: {
    id: EProjectTransactionPropertyType.CommercialOffice,
    value: EProjectTransactionPropertyType.CommercialOffice,
    viewValue: 'Commercial - Office',
  },
  [EProjectTransactionPropertyType.CommercialShophouse]: {
    id: EProjectTransactionPropertyType.CommercialShophouse,
    value: EProjectTransactionPropertyType.CommercialShophouse,
    viewValue: 'Commercial - Shophouse',
  },
  [EProjectTransactionPropertyType.CommercialRetail]: {
    id: EProjectTransactionPropertyType.CommercialRetail,
    value: EProjectTransactionPropertyType.CommercialRetail,
    viewValue: 'Commercial - Retail',
  },
  [EProjectTransactionPropertyType.IndustrialWarehouse]: {
    id: EProjectTransactionPropertyType.IndustrialWarehouse,
    value: EProjectTransactionPropertyType.IndustrialWarehouse,
    viewValue: 'Industrial - Warehouse',
  },
  [EProjectTransactionPropertyType.IndustrialBusinessHub]: {
    id: EProjectTransactionPropertyType.IndustrialBusinessHub,
    value: EProjectTransactionPropertyType.IndustrialBusinessHub,
    viewValue: 'Industrial - Business Hub',
  },
  [EProjectTransactionPropertyType.IndustrialSingleUserFactory]: {
    id: EProjectTransactionPropertyType.IndustrialSingleUserFactory,
    value: EProjectTransactionPropertyType.IndustrialSingleUserFactory,
    viewValue: 'Industrial - Single-User Factory',
  },
  [EProjectTransactionPropertyType.IndustrialMultipleUserFactory]: {
    id: EProjectTransactionPropertyType.IndustrialMultipleUserFactory,
    value: EProjectTransactionPropertyType.IndustrialMultipleUserFactory,
    viewValue: 'Industrial - Multiple-User Factory',
  },
  [EProjectTransactionPropertyType.Retail]: {
    id: EProjectTransactionPropertyType.Retail,
    value: EProjectTransactionPropertyType.Retail,
    viewValue: 'Retail',
  },
  [EProjectTransactionPropertyType.Others]: {
    id: EProjectTransactionPropertyType.Others,
    value: EProjectTransactionPropertyType.Others,
    viewValue: 'Others',
  },
};
