import { IPersonalParticularAddress } from './personal-particular.interface';
import { ECitizen, EGender, EIndustryExam } from '../enums';
import { IQualification } from './qualification.interface';
import { INationality } from './nationality.interface';
import { IPhoneNumber } from '@red/data-access';
import { IMarital } from './marital.interface';
import { IBank } from './bank.interface';

export interface IRecruitmentMetadata {
  reason: string;
  qualifyOther: string;
  industryOther: string;
}

export interface IRecruitmentApplication extends IRecruitmentApplicationPersonal {
  address: IPersonalParticularAddress;
  postal: string;
  signature: string;
}

export interface IRecruitmentApplicationCreateDto {
  nricfin: string;
  nricName: string;
  businessName: string;
  dateOfBirth: string;
  gender: EGender;
  maritalId: number;
  email1: string;
  mobile1: IPhoneNumber;
  nationalityId: number;
  bankId: number;
  bankAccountNo: string;
  bankName: string;
  ceaRegNo: string;
  citizen: ECitizen;
  qualificationId: number;
  industryExam: EIndustryExam;
  hanyuPinyinName: string;
  website: string;
  metadata: IRecruitmentMetadata;

  address: IPersonalParticularAddress;
  postal: string;
  signature: string;
}

export interface IRecruitmentApplicationPersonal {
  nricfin: string;
  nricName: string;
  businessName: string;
  dateOfBirth: string;
  gender: EGender;
  marital: IMarital;
  maritalId: number;
  email1: string;
  mobile1: IPhoneNumber;
  nationality: INationality;
  nationalityId: number;
  bank: IBank;
  bankId: number;
  bankAccountNo: string;
  bankName: string;
  ceaRegNo: string;
  citizen: ECitizen;
  qualification: IQualification;
  qualificationId: number;
  qualifyOther: string;
  industryExam: EIndustryExam;
  industryOther: string;
  hanyuPinyinName: string;
  website: string;

  metadata: IRecruitmentMetadata;
}
