import { ERecruitmentType } from '../enums';

export interface IPackage {
  id: number;
  name: string;
  html: string;
  description: string;
  options: IPackageOption[];
}

export interface IPackageOption {
  id: number;
  name: string;
  applicantType: ERecruitmentType;
  html: string;
  acknowledge: string;
  undertaking: string;
}
