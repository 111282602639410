import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ResaleAdjustmentDataDto, ResaleAdjustmentUpdateDto } from '@shared/data-access/dto';
import { IResaleAdjustment } from '@shared/data-access/interfaces';
import { ResaleAdjustmentModel, ResaleStatusHistoryModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResaleAdjustmentApiService {
  static ROOT_POINT = 'transaction/resale-adjustments';
  static SUBMIT_POINT = 'transaction/resale-adjustments/:id/submit';
  static REWORK_POINT = 'transaction/resale-adjustments/:id/rework';
  static CONFIRM_POINT = 'transaction/resale-adjustments/:id/action';
  static SUBMIT_MULTIPLE_POINT = 'transaction/resale-adjustments/submit';
  static REWORK_MULTIPLE_POINT = 'transaction/resale-adjustments/rework';
  static CONFIRM_MULTIPLE_POINT = 'transaction/resale-adjustments/action';
  static STATUS_HISTORIES_POINT = '/transaction/resale-status-histories';

  constructor(private _apiService: ApiService) {}

  create(data: ResaleAdjustmentDataDto): Observable<ResaleAdjustmentModel> {
    return this._apiService
      .post(`${ResaleAdjustmentApiService.ROOT_POINT}`, data)
      .pipe(map((res: IResaleAdjustment) => ResaleAdjustmentModel.fromJson(res) as ResaleAdjustmentModel));
  }

  get(itemId: number): Observable<ResaleAdjustmentModel> {
    return this._apiService.get(ResaleAdjustmentApiService.ROOT_POINT + '/' + itemId).pipe(map(res => ResaleAdjustmentModel.fromJson(res) as ResaleAdjustmentModel));
  }

  update(id: number, data: ResaleAdjustmentUpdateDto): Observable<any> {
    return this._apiService.patch(ResaleAdjustmentApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ResaleAdjustmentModel>> {
    return this._apiService.get(ResaleAdjustmentApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ResaleAdjustmentModel, data)));
  }

  delete(id: number, option?: IApiOption) {
    return this._apiService.delete(ResaleAdjustmentApiService.ROOT_POINT + '/' + id, {}, option);
  }

  submit(id: number, reason?: string) {
    return this._apiService.post(ResaleAdjustmentApiService.SUBMIT_POINT, { id, data: { reason } });
  }

  rework(id: number, reason = '') {
    return this._apiService.post(ResaleAdjustmentApiService.REWORK_POINT, { id, data: { reason } });
  }

  confirm(id: number, reason = '') {
    return this._apiService.post(ResaleAdjustmentApiService.CONFIRM_POINT, { id, data: { reason } });
  }

  reworkMultiple(resales: { id: number; data: { reason: string } }[]) {
    return this._apiService.post(ResaleAdjustmentApiService.REWORK_MULTIPLE_POINT, { resales });
  }

  confirmMultiple(resales: { id: number; data: { reason?: string } }[]) {
    return this._apiService.post(ResaleAdjustmentApiService.CONFIRM_MULTIPLE_POINT, { resales });
  }

  searchStatusHistories(query = {}, option?: IApiOption) {
    return this._apiService
      .get(ResaleAdjustmentApiService.STATUS_HISTORIES_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ResaleStatusHistoryModel, data)));
  }
}
