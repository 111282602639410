import { EGceAmountStatus } from '../enums';
import { IPersonalParticular } from './personal-particular.interface';
import { ITimestamp } from './timestamp.interface';

export interface IGceAmount extends ITimestamp {
  id: number;
  amount: number;
  description: string;
  effectiveDate: string;
  salespersonId: number;
  salesperson: IPersonalParticular;
  status: EGceAmountStatus;
}

export interface IGceAmountDto {
  amount: number;
  description: string;
  effectiveDate: string;
  salespersonId: number;
}
