<div class="flex flex-auto flex-col gap-6">
  <div class="flex flex-col absolute inset-0">
    <div class="flex flex-col flex-auto h-full overflow-hidden">
      <mat-drawer-container class="w-full h-full">
        <mat-drawer #drawer class="w-80" mode="side">
          <div class="w-full h-full flex flex-col overflow-hidden">
            <div class="flex flex-auto flex-col p-4 overflow-auto" [class.hidden]="!hasFilter">
              <red-exceljs-parameter [card]="fakeCard" [parameters]="parameters | filter:[]:this.compareFn"></red-exceljs-parameter>
            </div>
          </div>
        </mat-drawer>
        <div class="absolute inset-0 flex flex-col flex-auto">
          <div class="p-4 flex flex-row w-full" *ngIf="selectionModel.selected.length > 0">
            <div class="flex flex-row flex-auto items-center justify-between rounded bg-green-100">
              <div class="flex flex-row flex-auto items-center px-4 gap-2">
                <mat-icon class="text-green-500">info</mat-icon>
                <span>Selected {{ selectionModel.selected.length || 0 }} items</span>
              </div>
              <button mat-button type="button" (click)="previewItemSeleted()">
                <span>View / Edit</span>
                <mat-icon>navigate_next</mat-icon>
              </button>
            </div>
          </div>
          <div class="flex flex-row">
            <mat-form-field
              class="w-full fuse-mat-no-subscript fuse-mat-rounded-sm fuse-mat-dense fuse-search-box has-border fuse-mat-float-label-always"
              appearance="fill"
              floatLabel="always"
            >
              <mat-icon matPrefix class="icon-size-5 text-theme-gray">search</mat-icon>
              <mat-icon *ngIf="masterSearchControl.value" matSuffix class="icon-size-5 text-theme-dark" (click)="masterSearchControl.setValue('')">clear</mat-icon>
              <input #searchInput type="text" matInput [formControl]="masterSearchControl" />
            </mat-form-field>
            <div [class.hidden]="!hasFilter">
              <button *ngIf="parameters && parameters.length > 0" class="w-10" mat-icon-button color="primary" (click)="drawer.toggle()">
                <mat-icon>filter_list</mat-icon>
              </button>
            </div>
            
          </div>
            <div class="flex flex-col flex-auto overflow-auto" [class.hidden]="!showItemSelected">

              <table class="w-full max-h-64" mat-table [dataSource]="selectionModel.selected">
                <ng-container *ngFor="let col of columns; let isLastCol = last; let isFirstCol = first" [matColumnDef]="col.columnDef">
                  <th mat-header-cell *matHeaderCellDef>{{ col.header }}</th>
                  <td mat-cell *matCellDef="let item; let i = index">
                    <ng-container [ngSwitch]="col.columnDef">
                      <div *ngSwitchCase="'index'">
                        <span>
                          {{ col.cell && col.cell(item, i) | empty }}
                        </span>
                      </div>
                      <div *ngSwitchCase="'action'" class="text-center">
                        <mat-checkbox [checked]="selectionModel.isSelected(item)" (click)="$event.stopPropagation()"  (change)="toggle(item)"> </mat-checkbox>
                      </div>
                      <div *ngSwitchDefault>
                        <div class="truncate">
                          {{ col.cell && col.cell(item, i) | empty }}
                        </div>
                      </div>
                    </ng-container>
                  </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <ng-template *ngIf="showItemSelected" [redLoadingBox]="dataSource.loading$ | async"></ng-template>
  
            </div>
          <div class="flex flex-col flex-auto overflow-hidden" [class.hidden]="showItemSelected">
            <div class="flex flex-col flex-auto overflow-auto">

              <table class="w-full max-h-64" mat-table [dataSource]="dataSource">
                <ng-container *ngFor="let col of columns; let isLastCol = last; let isFirstCol = first" [matColumnDef]="col.columnDef">
                  <th mat-header-cell *matHeaderCellDef>{{ col.header }}</th>
                  <td mat-cell *matCellDef="let item; let i = index">
                    <ng-container [ngSwitch]="col.columnDef">
                      <div *ngSwitchCase="'index'">
                        <span>
                   
                          {{ col.cell && col.cell(item, i) | empty }}
                        </span>
                      </div>
                      <div *ngSwitchCase="'action'" class="text-center">
                        <mat-checkbox [checked]="selectionModel.isSelected(item)" (click)="$event.stopPropagation()"  (change)="toggle(item)"> </mat-checkbox>
                      </div>
                      <div *ngSwitchDefault>
                        <div class="truncate">
                          {{ col.cell && col.cell(item, i) | empty }}
                        </div>
                      </div>
                    </ng-container>
                  </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <ng-template *ngIf="showItemSelected" [redLoadingBox]="dataSource.loading$ | async"></ng-template>
  
            </div>
            <div >
              <mat-paginator  [length]="dataSource.pagination.total" [pageSize]="dataSource.pagination.limit"></mat-paginator>
            </div>
          </div>
         
         
        </div>


      </mat-drawer-container>
    </div>
  </div>
</div>
