import { reduce, keys, assign, cloneDeep, isObject } from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IsActiveMatchOptions } from '@angular/router';
import { isMoment } from 'moment';

export class Helper {
  static getScreenWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  static removeCommaFromText(txt: string) {
    let finalString = txt;
    if (txt && Object.prototype.toString.call(txt) === '[object String]') {
      finalString = finalString.replace(/,/g, '');
    }
    return +finalString;
  }

  static removeEmpty(data: any, exclude: string[] = []): any {
    if (!data) {
      return data;
    }
    switch (typeof data) {
      case 'object': {
        if (Array.isArray(data)) {
          return data.filter(item => !(item === null || item === undefined || item === '' || (Array.isArray(item) && !item.length)));
        }

        Object.keys(data).forEach(key => {
          if (!exclude.includes(key)) {
            const val = Helper.removeEmpty(data[key], exclude);
            if (val === null || val === undefined || val === '' || (Array.isArray(val) && !val.length)) {
              delete data[key];
            } else {
              data[key] = val;
            }
          }
        });
        return data;
      }
      case 'string': {
        return data.trim();
      }
      default:
        return data;
    }
  }

  static flatParams(params: Record<string, any>): object {
    if (!params) {
      return params;
    }
    Object.keys(params).forEach(key => {
      if (Array.isArray(params[key])) {
        params[key] = params[key].join(',');
      }
    });
    return params;
  }

  static slugify(input: string): string {
    return (
      input
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        // eslint-disable-next-line no-useless-escape
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '')
        .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
        .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
        .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
        .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
        .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
        .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
        .replace(/đ/g, 'd')
        // eslint-disable-next-line no-useless-escape
        .replace(/[^\w\-]+/g, '')
    ); // Remove all non-word chars;
  }

  static camelize(input: string): string {
    return (
      Helper.slugify(input)
        // eslint-disable-next-line no-useless-escape
        .replace(/\-(.)/g, char => char.toUpperCase()) // UpperCase first character
        .replace(/-/g, '')
    ); // Remove -
  }

  static groupBy(list: any[], keyGetter: (data: any) => string, converter?: (data: any) => any) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [converter ? converter(item) : item]);
      } else {
        collection.push(converter ? converter(item) : item);
      }
    });
    return map;
  }

  static isObject(item: any): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  static mergeDeep(target: any, source: any): any {
    const output = Object.assign({}, target);
    if (Helper.isObject(target) && Helper.isObject(source)) {
      Object.keys(source).forEach(key => {
        if (Helper.isObject(source[key])) {
          if (!(key in target)) {
            Object.assign(output, { [key]: source[key] });
          } else {
            output[key] = Helper.mergeDeep(target[key], source[key]);
          }
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
    return output;
  }

  static leftJoin(target: any, source: any): any {
    const output = Object.assign({}, target);
    if (Helper.isObject(target) && Helper.isObject(source)) {
      Object.keys(target).forEach(key => {
        if (Helper.isObject(target[key])) {
          if (!(key in source)) {
            Object.assign(output, { [key]: target[key] });
          } else {
            output[key] = Helper.mergeDeep(target[key], source[key]);
          }
        } else {
          Object.assign(output, { [key]: source[key] || target[key] });
        }
      });
    }
    return output;
  }

  static isEqual(target: any, source: any): boolean {
    const targetType = typeof target;
    const sourceType = typeof source;
    if (targetType !== sourceType) {
      console.log('khac type', target, source);
      return false;
    }
    if (Array.isArray(target) && Array.isArray(source)) {
      const length = target.length;
      if (length !== source.length) {
        console.log('khac len');
        return false;
      }
      let check = true;
      for (let i = 0; i < length; i++) {
        check = Helper.isEqual(target[i], source[i]);
        if (!check) {
          break;
        }
      }
      return check;
    }

    if (Helper.isObject(target) && Helper.isObject(source)) {
      const targetValue = Object.values(target);
      const sourceValue = Object.values(source);
      return Helper.isEqual(targetValue, sourceValue);
    }

    return target === source;
  }

  static isEmpty(input: any): boolean {
    if (!input) {
      return true;
    }
    if (Array.isArray(input)) {
      return input.length === 0;
    }
    if (typeof input === 'object') {
      return Object.keys(input).length === 0;
    }
    return false;
  }

  static transformAddressObjectToString(objectAddress: {
    postalCode?: string;
    unitNumber?: string;
    blockNumber?: string;
    streetName?: string;
    buildingName?: string;
  }): string {
    const str =
      (objectAddress?.blockNumber ? objectAddress?.blockNumber : '') +
      ' ' +
      (objectAddress?.streetName ? objectAddress?.streetName : '') +
      ' ' +
      (objectAddress?.unitNumber ? objectAddress?.unitNumber : '') +
      ' ' +
      (objectAddress?.buildingName ? objectAddress?.buildingName : '') +
      ' SINGAPORE ' +
      (objectAddress?.postalCode ? objectAddress?.postalCode : '');
    return str.replace(/[^\S\r\n]{2,}/, ' ').trim();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = true".
   */
  static get exactMatchOptions(): IsActiveMatchOptions {
    return {
      paths: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'exact',
    };
  }

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = false".
   */
  static get subsetMatchOptions(): IsActiveMatchOptions {
    return {
      paths: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'subset',
    };
  }

  /**
   * Generates a random id
   *
   * @param length
   */
  static randomId(length: number = 10): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let name = '';

    for (let i = 0; i < 10; i++) {
      name += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return name;
  }

  static clearMoment(data: any, config?: { milliseconds: boolean }): any {
    if (isMoment(data)) {
      if (config?.milliseconds) {
        return data.valueOf();
      }
      return data.toDate().toISOString();
    }
    if (Array.isArray(data)) {
      return data.map(item => Helper.clearMoment(item, config));
    }
    if (data instanceof Date) {
      return data.toISOString();
    }
    if (isObject(data)) {
      const res = reduce(
        keys(cloneDeep(data)).map(key => {
          return { [key]: Helper.clearMoment((data as Record<string, unknown>)[key], config) };
        }),
        (acc, item) => {
          return assign(acc, item);
        },
        {}
      );
      return res;
    }

    return data;
  }
  static momentToDate(data: any): any {
    if (isMoment(data)) {
      return data.toDate();
    }
    if (Array.isArray(data)) {
      return data.map(item => Helper.momentToDate(item));
    }
    if (data instanceof Date) {
      return data;
    }
    if (isObject(data)) {
      const res = reduce(
        keys(cloneDeep(data)).map(key => {
          return { [key]: Helper.momentToDate((data as Record<string, unknown>)[key]) };
        }),
        (acc, item) => {
          return assign(acc, item);
        },
        {}
      );
      return res;
    }

    return data;
  }
  static round(num: number, precision: number) {
    return precision > -1 ? Math.round((num + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision) : num;
  }

  static floor(num: number, precision: number) {
    return precision > -1 ? Math.floor((num + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision) : num;
  }

  static isNricNumber(nric: string): boolean {
    const nricRegex = /^[STFG]\d{7}[A-Z]$/;

    if (!nricRegex.test(nric)) {
      return false;
    }

    const lastLetter = nric[8];
    const nricCheckDigits = 'JZIHGFEDCBA';
    const finCheckDigits = 'XWUTRQPNMLK';
    const digits = nric.slice(1, 8);
    const weights = [2, 7, 6, 5, 4, 3, 2];
    let sum = 0;

    if (isNaN(+digits)) {
      return false;
    }

    if (['T', 'G'].includes(nric[0])) {
      sum += 4;
    }

    for (let i = 0; i < digits.length; i++) {
      sum += parseInt(digits.charAt(i), 10) * weights[i];
    }

    if (['S', 'T'].includes(nric[0])) {
      return nricCheckDigits.charAt(sum % 11) === lastLetter;
    }

    return finCheckDigits.charAt(sum % 11) === lastLetter;
  }
}
