import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { CREDIT_NOTE_PAID_STATUS } from '../data';
import { ECreditNoteDetailStatus, ECreditNoteStatus } from '../enums';
import { EBalanceDocumentPostingType, ECustomerOrSupplier, EInvoiceCreateMode } from '../enums/tax-invoice-enhanced.enum';
import { ICreditNoteSupplierRefund, IStatusDescription } from '../interfaces';
import { ICreditNote, ICreditNotePosting, ICreditNotePostingAccount, ICreditNoteRecord, ICreditNoteSummary } from '../interfaces/credit-note-enhanced.interface';
import { ContactAddressModel } from './contact-address.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { ContactPersonModel } from './contact-person.model';
import { CurrencyRateModel } from './currency-rate.model';
import { GstCategoryLookupModel } from './gst-category.model';
import { LedgerAccountModel } from './ledger-account.model';
import { PersonalParticularModel } from './personal-particular.model';
import { ProfitCentresModel } from './profit-centres.model';
import { TaxInvoiceModel } from './tax-invoice-enhanced.model';
import { TemplateModel } from './template.model';
import { UpdatedByModel } from './updated-by.model';

export class CreditNoteRecordModel extends BaseModel implements ICreditNoteRecord {
  @Expose()
  id?: number;

  @Expose()
  itemCode?: string;

  @Expose()
  description!: string;

  @Expose()
  amount!: number;

  @Expose()
  quantity!: number;

  @Expose()
  unitPrice!: number;

  @Expose()
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => GstCategoryLookupModel)
  gstCategoryLookup?: GstCategoryLookupModel;

  @Expose()
  @Transform(({ obj }) => obj.gstPercent ?? Number(obj.gstCategoryLookup?.gst_rate))
  @Type(() => Number)
  gstPercent?: number;

  @Expose()
  @Transform(({ obj }) => obj.gstCharged ?? Number(obj.gstCategoryLookup?.gst_charged))
  @Type(() => Number)
  gstCharged?: number;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  gstValue?: number;

  // @Expose()
  // gstPercent?: number;

  //   @Expose()
  //   bankReferralFee?: boolean;

  @Expose()
  uom?: string;

  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter!: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  @Type(() => Number)
  taxInvoiceDetailId?: number;

  @Expose()
  @Type(() => Number)
  taxInvoiceId?: number;

  @Expose()
  @Type(() => TaxInvoiceModel)
  taxInvoice?: TaxInvoiceModel;

  @Expose()
  @Type(() => Number)
  creditNoteId?: number;

  // @Expose()
  // CreditNote!: CreditNoteModel;

  // @Expose()
  // CreditNoteId!: number;

  //   @Expose()
  //   agentId?: number;

  //   @Expose()
  //   @Type(() => PersonalParticularModel)
  //   agent?: PersonalParticularModel;

  @Expose()
  index?: number;

  @Expose()
  createdMode?: EInvoiceCreateMode;
}

export class CreditNotePostingModel extends BaseModel implements ICreditNotePosting {
  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  description?: string;

  @Expose()
  debit!: number;

  @Expose()
  credit!: number;

  @Expose()
  amount!: number;

  @Expose()
  currency?: string;

  @Expose()
  creditNoteId!: number;

  @Expose()
  creditNoteDetailId?: number;

  // @Expose()
  // CreditNoteDetail?: ICreditNoteDetailEnhanced;

  @Expose()
  type?: EBalanceDocumentPostingType;
}


export class CreditNotePostingAccountModel extends BaseModel implements ICreditNotePostingAccount {
  @Expose()
  accountCode!: string;

  @Expose()
  accountId!: number;

  @Expose()
  accountName!: string;

  @Expose()
  @Transform(({ obj }) => {
    const normalized = `${obj.accountCode || ''} ${obj.accountName || ''}`;
    return normalized.trim();
  })
  normalizedAccountName!: string;
}


export class CreditNoteModel extends BaseModel implements ICreditNote {
  // General
  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  @Type(() => ContactAddressModel)
  billingAddress!: ContactAddressModel;

  @Expose()
  contactId?: number;

  @Expose()
  contactAddressId!: number;

  @Expose()
  creditNoteNumber?: string;

  @Expose()
  creditNoteDate!: string;

  @Expose()
  @Type(() => ContactPersonModel)
  contactPerson?: ContactPersonModel;

  @Expose()
  contactPersonId?: number;

  @Expose()
  customerName?: string;

  @Expose()
  taxInvoiceId?: number;

  @Expose()
  @Type(() => TaxInvoiceModel)
  @Default([])
  taxInvoices?: TaxInvoiceModel[];

  @Expose()
  inClosedPeriod?: boolean;

  // Summary
  @Expose()
  gstEdited!: boolean;

  @Expose()
  amount!: number;

  @Expose()
  discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gst?: number;

  @Expose()
  balanceDue?: number;

  // Other Details
  @Expose()
  currencyId!: number;

  @Expose()
  currencyCode?: string;

  @Expose()
  @Type(() => CurrencyRateModel)
  currency!: CurrencyRateModel;

  @Expose()
  billingAddressCustom?: string;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  reference?: string;

  @Expose()
  creditTerm?: number;

  @Expose()
  paymentTerm?: string;

  @Expose()
  salePersonId?: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson?: PersonalParticularModel;

  @Expose()
  remarks?: string;

  @Expose()
  creditNoteRemarks?: string;

  @Expose()
  templateId?: number;

  @Expose()
  @Type(() => TemplateModel)
  template?: TemplateModel;

  // General
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  type?: ECustomerOrSupplier.CUSTOMER;

  @Expose()
  status?: ECreditNoteStatus; // cancel, abort, partial

  @Expose()
  @Transform(({ value }) => {
    if (!value) return undefined;
    if (typeof value === 'string' && !isNaN(+value)) return undefined;

    return CREDIT_NOTE_PAID_STATUS[value as ECreditNoteDetailStatus];
  })
  paidStatus?: IStatusDescription;

  @Expose()
  createdMode?: EInvoiceCreateMode;

  @Expose()
  projectId?: number;

  @Expose()
  paidAmount?: number;

  @Expose()
  isWithHold?: boolean;

  @Expose()
  adjustment?: boolean;

  @Expose()
  miscAdjustment?: boolean;

  @Expose()
  isPaid?: boolean;

  // For payments
  @Expose()
  @Type(() => CreditNotePostingAccountModel)
  posting?: CreditNotePostingAccountModel

  // Base
  @Expose()
  createdAt?: string;

  @Expose()
  updatedAt?: string;

  @Expose()
  createdBy?: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [ECreditNoteDetailStatus.Cancelled, ECreditNoteDetailStatus.PaymentInProgress].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.isPaid;

    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [ECreditNoteDetailStatus.PaymentInProgress].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.isPaid;

    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod;
  })
  canDelete!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [ECreditNoteDetailStatus.Cancelled, ECreditNoteDetailStatus.PaymentInProgress].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.isPaid;

    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod;
  })
  canCancel!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [ECreditNoteDetailStatus.Cancelled, ECreditNoteDetailStatus.PaymentInProgress].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.isPaid;

    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid;
  })
  isLock!: boolean;

  // @Expose()
  // invoiceType?: ECustomerOrSupplier;

  // @Expose()
  // printFormat?: string;

  // @Expose()
  // invoiceDueDate?: string;

  // @Expose()
  // ecbInvoiceDate?: string;

  // @Expose()
  // paidStatus?: string;

  // @Expose()
  // balanceDue?: number;

  // @Expose()
  // parentInvoice?: CreditNoteModel;

  // @Expose()
  // parentInvoiceId?: number;

  // @Expose()
  // batchComment?: string;

  // @Expose()
  // batchName?: string;

  // @Expose()
  // batchCode?: string;

  // @Expose()
  // attention?: string;

  // @Expose()
  // supplierInvoiceType?: ESupplierInvoice;

  // @Expose()
  // adjustment?: boolean;

  // @Expose()
  // fileAttachedName?: string;

  // @Expose()
  // fileAttachedViewName?: string;

  // @Expose()
  // balanceDocumentCodeType?: EBalanceDocumentCode;
}

export class CreditNoteSummaryModel extends BaseModel implements ICreditNoteSummary {
  @Expose()
  amount!: number;

  @Expose()
  discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gstEdited!: boolean;
}

export class CreditNoteSupplierRefundModel implements ICreditNoteSupplierRefund {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => CreditNoteModel)
  creditNote?: Partial<CreditNoteModel>;

  @Expose()
  @Type(() => Number)
  total?: number;

  @Expose()
  @Type(() => Number)
  paidAmount?: number;

  @Expose()
  @Type(() => Number)
  balanceDue!: number;
}
