import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyPipe } from './empty.pipe';

export const DEFAULT_EMPTY_VALUE = new InjectionToken<string | number | symbol>('DEFAULT_EMPTY_VALUE');

@NgModule({
  declarations: [EmptyPipe],
  imports: [CommonModule],
  exports: [EmptyPipe],
})
export class EmptyModule {
  static forRoot(configs: { defaultValue?: string | number | symbol }): ModuleWithProviders<EmptyModule> {
    const defaultValue = configs?.defaultValue || '';
    return {
      ngModule: EmptyModule,
      providers: [
        {
          provide: DEFAULT_EMPTY_VALUE,
          useValue: defaultValue,
        },
      ],
    };
  }
}
