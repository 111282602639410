import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IAssetDepreciation, IAssetDepreciationAssetItem, IAssetDepreciationPostingItem, ILedgerAccount, IProfitCentres } from '../interfaces';
import { AssetRegisterModel } from './asset-register.model';
import { LedgerAccountModel } from './ledger-account.model';
import { ProfitCentresModel } from './profit-centres.model';
import { UpdatedByModel } from './updated-by.model';

export class AssetDepreciationAssetItemModel extends AssetRegisterModel implements IAssetDepreciationAssetItem {
  @Expose()
  uuid!: string;

  @Expose()
  @Type(() => Number)
  assetDepreciationId!: number;
}

export class AssetDepreciationPostingItemModel extends BaseModel implements IAssetDepreciationPostingItem {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => Number)
  depreciationId?: number;

  @Expose()
  @Type(() => Number)
  accountId?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account?: Partial<LedgerAccountModel>;

  @Expose()
  @Type(() => Number)
  profitCenterId!: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: Partial<ProfitCentresModel>;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  debit!: number;

  @Expose()
  @Type(() => Number)
  credit!: number;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  currency!: string;

  @Expose()
  @Type(() => Number)
  currencyRate!: number;
}

export class AssetDepreciationModel extends BaseModel implements IAssetDepreciation {
  // Timestamp
  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  createdBy?: string | null;

  @Expose()
  updatedBy?: UpdatedByModel | null;

  @Expose()
  deletedAt?: string | null;

  @Expose()
  deletedBy?: string | null;

  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  depreciationDate!: string;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => AssetDepreciationAssetItemModel)
  assets!: AssetDepreciationAssetItemModel[];

  @Expose()
  @Type(() => AssetDepreciationPostingItemModel)
  postings!: AssetDepreciationPostingItemModel[];
}
