export enum ESupplierCreditNoteDetailStatus {
  // UnPaid = 'UnPaid',
  View = 'View',
  PartPaid = 'PartPaid',
  FullyPaid = 'FullyPaid',
  // Confirmed = 'Confirmed',
  Withhold = 'Withhold',
  Pending = 'Pending',
  PaymentInProgress = 'PaymentInProgress',
}

export enum ESupplierCreditNoteStatus {
  CANCELLED = 'CANCELLED',
  ABORTED = 'ABORTED',
  PARTIAL = 'PARTIAL',
}
