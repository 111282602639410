import { IndustryExamRecord } from '../types';
import { EIndustryExam } from '../enums';

export const INDUSTRY_EXAM_OPTIONS: IndustryExamRecord = {
  [EIndustryExam.CEHA]: {
    id: EIndustryExam.CEHA,
    value: EIndustryExam.CEHA,
    viewValue: 'CEHA',
  },
  [EIndustryExam.CEA]: {
    id: EIndustryExam.CEA,
    value: EIndustryExam.CEA,
    viewValue: 'CEA',
  },
  [EIndustryExam.CES]: {
    id: EIndustryExam.CES,
    value: EIndustryExam.CES,
    viewValue: 'CES',
  },
  [EIndustryExam.REA]: {
    id: EIndustryExam.REA,
    value: EIndustryExam.REA,
    viewValue: 'REA',
  },
  [EIndustryExam.RES]: {
    id: EIndustryExam.RES,
    value: EIndustryExam.RES,
    viewValue: 'RES',
  },
  [EIndustryExam.Others]: {
    id: EIndustryExam.Others,
    value: EIndustryExam.Others,
    viewValue: 'Others',
  },
};
