import { BaseModel, Default, FormattedDateString, IPhoneNumber, PhoneNumber, StartOfDate } from '@red/data-access';
import { Exclude, Expose, Transform, TransformFnParams, Type } from 'class-transformer';
import { isEmpty, isNil, omitBy } from 'lodash-es';
import { ECitizen, EGender, EIndustryExam, ERecruitmentDivision, ERecruitmentType } from '../enums';
import {
  IPersonalParticularAddress,
  IRecruitment,
  IRecruitmentActionDto,
  IRecruitmentCheckDuplicatedDto,
  IRecruitmentCreateDto,
  IRecruitmentMetadata,
  IrecruitmentUpdateDto,
} from '../interfaces';

export class RecruitmentCreateDto extends BaseModel implements IRecruitmentCreateDto {
  @Expose()
  applicantType!: ERecruitmentType;

  @Expose()
  applicantName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  applicantMobile!: PhoneNumber;

  @Expose()
  division!: ERecruitmentDivision;

  @Expose()
  @Type(() => Boolean)
  isParent!: boolean;

  @Expose()
  @Type(() => Number)
  parentId!: number;

  @Expose()
  parentName!: string;

  @Expose()
  parentEmail!: string;

  @Expose()
  parentCeaRegNo!: string;

  @Expose()
  @Type(() => Boolean)
  isRecruiter!: boolean;

  @Expose()
  @Type(() => Number)
  recruiterId!: number;

  @Expose()
  recruiterName!: string;

  @Expose()
  recruiterEmail!: string;

  @Expose()
  recruiterCeaRegNo!: string;

  @Expose()
  @Type(() => Number)
  packageId!: number;

  @Expose()
  @Type(() => Number)
  designationId!: number;

  @Expose()
  // @Transform((params: TransformFnParams) => {
  //   if (!params.value) return undefined;
  //   if (params.value === 'now') return params.value;
  //   return params.value;
  // })
  // @StartOfDate()
  // @FormattedDateString()
  joinOption!: string;
}

export class RecruitmentUpdateDto extends RecruitmentCreateDto implements IrecruitmentUpdateDto {
  @Expose()
  remarks!: string;

  //#region RecruitmentApplicationCreateDto
  @Expose()
  nricfin!: string;

  @Expose()
  nricName!: string;

  @Expose()
  businessName!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  dateOfBirth!: string;

  @Expose()
  gender!: EGender;

  @Expose()
  maritalId!: number;

  @Expose()
  email1!: string;

  @Expose()
  mobile1!: IPhoneNumber;

  @Expose()
  nationalityId!: number;

  @Expose()
  bankId!: number;

  @Expose()
  bankAccountNo!: string;

  @Expose()
  bankName!: string;

  @Expose()
  ceaRegNo!: string;

  @Expose()
  citizen!: ECitizen;

  @Expose()
  qualificationId!: number;

  @Expose()
  industryExam!: EIndustryExam;

  @Expose()
  hanyuPinyinName!: string;

  @Expose()
  website!: string;

  @Expose()
  @Transform(({ obj }: { obj: IRecruitment }) => {
    const metadata = {
      industryOther: !isEmpty(obj.industryOther) ? obj.industryOther : null,
      qualifyOther: !isEmpty(obj.qualifyOther) ? obj.qualifyOther : null,
      reason: obj?.metadata?.reason,
    };

    return omitBy(metadata, isNil);
  })
  metadata!: IRecruitmentMetadata;

  @Expose()
  address!: IPersonalParticularAddress;

  @Expose()
  postal!: string;

  @Expose()
  signature!: string;
  //#endregion
}

@Exclude()
export class RecruitmentActionDto extends BaseModel implements IRecruitmentActionDto {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  ceaRegNo?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  joinDate?: string;

  @Expose()
  @Default({})
  metadata!: {
    reason?: string;
    qualifyOther?: string;
    industryOther?: string;
  };
}

export class RecruitmentCheckDuplicatedDto extends BaseModel implements IRecruitmentCheckDuplicatedDto {
  @Expose()
  ceaRegNo!: string;

  @Expose()
  email1!: string;
}
