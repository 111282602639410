import { BaseModel, StripComma } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ImportARItemDto extends BaseModel {
  @Expose()
  @Type(() => String)
  customerCode!: string;

  @Expose()
  @Type(() => String)
  customerName!: string;

  @Expose()
  @Type(() => String)
  type!: string;

  @Expose()
  @Type(() => String)
  invoiceNo!: string;

  @Expose()
  @Type(() => String)
  invoiceDate!: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  itemGstPercent!: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  itemGstAmount!: string;

  @Expose()
  @Type(() => String)
  itemNo!: string;

  @Expose()
  @Type(() => String)
  itemCode!: string;

  @Expose()
  @Type(() => String)
  itemDescription!: string;

  @Expose()
  @Type(() => String)
  uom!: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  quantity!: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  unitPrice!: string;

  // @Expose()
  // @Type(() => String)
  // @StripComma()
  // discountPercent!: string;

  @Expose()
  @Type(() => String)
  creditAccount!: string;

  @Expose()
  @Type(() => String)
  debitAccount!: string;

  @Expose()
  @Type(() => String)
  profitCentre!: string;

  @Expose()
  @Type(() => String)
  itemRemarks!: string;

  @Expose()
  @Type(() => String)
  currencySign!: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  currencyRate!: string;

  @Expose()
  @Type(() => String)
  billingAddress!: string;

  @Expose()
  @Type(() => String)
  contactPerson!: string;

  @Expose()
  @Type(() => String)
  reference!: string;

  @Expose()
  @Type(() => String)
  creditTerm!: string;

  @Expose()
  @Type(() => String)
  salesperson!: string;

  @Expose()
  @Type(() => String)
  paymentTerms!: string;

  @Expose()
  @Type(() => String)
  remarks!: string;

  @Expose()
  @Type(() => String)
  glEntry!: string;

  @Expose()
  @Type(() => String)
  offsetInvoice!: string;

  @Expose()
  @Type(() => String)
  gstInclusive!: string;
}

export class ImportARDto extends BaseModel {
  @Expose()
  @Type(() => ImportARItemDto)
  items?: ImportARItemDto[];
}
