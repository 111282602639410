import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
export class AppointmentTypeConfigDto extends BaseModel {
  @Expose()
  appointmentTypeConfig!: string;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;
}
export class AppointmentTypeCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  sorting!: string;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;

  @Expose()
  @Type(() => AppointmentTypeConfigDto)
  configs!: AppointmentTypeConfigDto[];

  @Expose()
  remarks!: string;
}

export class AppointmentTypeDataUpdateDto extends BaseModel {
  @Expose({ groups: ['full', 'general'] })
  code!: string;

  @Expose({ groups: ['full', 'general'] })
  name!: string;

  @Expose({ groups: ['full', 'general'] })
  sorting!: string;

  @Expose({ groups: ['full', 'general'] })
  remarks!: string;

  @Expose({ groups: ['full', 'general', 'isActive'] })
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;

  @Expose({ groups: ['full', 'general', 'configs'] })
  @Type(() => AppointmentTypeConfigDto)
  configs!: AppointmentTypeConfigDto[];
}

export class AppointmentTypeUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'general' | 'configs' | 'isActive' | 'full';

  @Expose()
  @Transform(params => {
    return AppointmentTypeDataUpdateDto.fromJson(params.value, { groups: [params.obj.group] });
  })
  data!: AppointmentTypeDataUpdateDto;
}
