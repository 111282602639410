import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { FileUploaderModel, PaginationAdapter } from '@red/data-access';
import { PersonalParticularUpdateDto } from '@shared/data-access/dto';
import { ICarrerProgression, IQuerySearch } from '@shared/data-access/interfaces';
import { CareerProgressionModel, PersonalParticularModel, PersonalVerifyModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class PersonalProfileApiService {
  static ROOT_POINT = 'hrm/salespersons/portal';
  static HEAD_DIVISION_POINT = 'hrm/salespersons/portal/head-of-team';
  static GET_LIST_POINT = 'hrm/salespersons/portal/list';
  static UPLOAD_DOCUMENT_POINT = 'hrm/documents/portal';
  static CAREER_PROGRESSION_POINT = 'hrm/career-progressions/portal';
  static ALL_VERIFY_POINT = 'hrm/salespersons-verify/portal/all';
  static COMMON_SALESPERSONS = 'hrm/salespersons/list-common';
  static GET_LIST_COMMON_POINT = 'hrm/salespersons/portal/list-common';

  constructor(private _apiService: ApiService) { }

  get(): Observable<PersonalParticularModel> {
    return this._apiService.get(PersonalProfileApiService.ROOT_POINT).pipe(map(res => PersonalParticularModel.fromJson(res) as PersonalParticularModel));
  }

  update(data: PersonalParticularUpdateDto): Observable<PersonalParticularModel> {
    return this._apiService.patch(PersonalProfileApiService.ROOT_POINT, data);
  }

  getHeadDivision(): Observable<PersonalParticularModel | null> {
    return this._apiService.get(PersonalProfileApiService.HEAD_DIVISION_POINT).pipe(map(res => PersonalParticularModel.fromJson(res) as PersonalParticularModel));
  }

  getList(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this._apiService.get(PersonalProfileApiService.GET_LIST_POINT, query, option).pipe(map(data => new PaginationAdapter(PersonalParticularModel, data)));
  }

  getListCommon(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this._apiService.get(PersonalProfileApiService.GET_LIST_COMMON_POINT, query, option).pipe(map(data => new PaginationAdapter(PersonalParticularModel, data)));
  }

  getDocument() {
    return this._apiService.get(PersonalProfileApiService.UPLOAD_DOCUMENT_POINT);
  }

  uploadDocument(file: FileUploaderModel): Observable<unknown> {
    return this._apiService.post(PersonalProfileApiService.UPLOAD_DOCUMENT_POINT, {
      name: file.name,
      url: file.key,
      size: file.size,
    });
  }

  deleteDocument(documentId: number): Observable<unknown> {
    return this._apiService.delete([PersonalProfileApiService.UPLOAD_DOCUMENT_POINT, documentId].join('/'));
  }

  getCareerProgression(): Observable<CareerProgressionModel[]> {
    return this._apiService
      .get(PersonalProfileApiService.CAREER_PROGRESSION_POINT)
      .pipe(map(res => res.map((item: ICarrerProgression[]) => CareerProgressionModel.fromJson(item)) as CareerProgressionModel[]));
  }

  getAllVerifys(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalVerifyModel>> {
    return this._apiService.get(PersonalProfileApiService.ALL_VERIFY_POINT, query, option).pipe(map(data => new PaginationAdapter(PersonalVerifyModel, data)));
  }

  searchCommon(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this._apiService
      .get(PersonalProfileApiService.COMMON_SALESPERSONS, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(PersonalParticularModel, data)));
  }
}
