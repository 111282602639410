import { IImportARColumn } from '../interfaces';

export const IMPORT_AR_DATA: IImportARColumn[] = [
  {
    id: 'customerCode',
    label: 'Customer Code',
    index: 0,
    required: true,
    maxLength: 20,
  },
  {
    id: 'customerName',
    label: 'Customer Name',
    index: 1,
    required: false,
    maxLength: 300,
  },
  {
    id: 'type',
    label: 'Type (IV/CN/DN)',
    index: 2,
    required: false,
    maxLength: 2,
  },
  {
    id: 'invoiceNo',
    label: 'Invoice No',
    index: 3,
    required: true,
    maxLength: 50,
  },
  {
    id: 'invoiceDate',
    label: 'Invoice Date (dd/mm/yyyy)',
    index: 4,
    required: true,
    // maxLength: 20,
  },
  {
    id: 'itemGstPercent',
    label: 'Item GST %',
    index: 5,
    required: true,
    maxLength: 2,
  },
  {
    id: 'itemGstAmount',
    label: 'Item GST Amount',
    index: 6,
    required: false,
    maxLength: 20,
  },
  {
    id: 'itemNo',
    label: 'Item No',
    index: 7,
    required: true,
    maxLength: 20,
  },
  {
    id: 'itemCode',
    label: 'Item Code',
    index: 8,
    required: false,
    maxLength: 20,
  },
  {
    id: 'itemDescription',
    label: 'Item Description',
    index: 9,
    required: true,
    maxLength: 300,
  },
  {
    id: 'uom',
    label: 'UOM',
    index: 10,
    required: false,
    maxLength: 20,
  },
  {
    id: 'quantity',
    label: 'Quantity',
    index: 11,
    required: false,
    maxLength: 20,
  },
  {
    id: 'unitPrice',
    label: 'Unit Price',
    index: 12,
    required: true,
    maxLength: 20,
  },
  // {
  //   id: 'discountPercent',
  //   label: 'Discount %',
  //   index: 13,
  //   required: true,
  //   maxLength: 20,
  // },
  {
    id: 'creditAccount',
    label: 'Credit Account',
    index: 13,
    required: true,
    maxLength: 20,
  },
  {
    id: 'debitAccount',
    label: 'Debit Account',
    index: 14,
    required: true,
    maxLength: 20,
  },
  {
    id: 'profitCentre',
    label: 'Profit Center',
    index: 15,
    required: false,
    maxLength: 20,
  },
  {
    id: 'itemRemarks',
    label: 'Item Remarks',
    index: 16,
    required: false,
    maxLength: 1000,
  },
  {
    id: 'currencySign',
    label: 'Currency',
    index: 17,
    required: false,
    maxLength: 20,
  },
  {
    id: 'currencyRate',
    label: 'Currency Rate',
    index: 18,
    required: false,
    maxLength: 10,
  },
  {
    id: 'billingAddress',
    label: 'Billing Address',
    index: 19,
    required: false,
    maxLength: 1000,
  },
  {
    id: 'contactPerson',
    label: 'Contact Person',
    index: 20,
    required: false,
    maxLength: 300,
  },
  {
    id: 'reference',
    label: 'Reference',
    index: 21,
    required: false,
    maxLength: 300,
  },
  {
    id: 'creditTerm',
    label: 'Credit Term',
    index: 22,
    required: false,
    maxLength: 4,
  },
  {
    id: 'salesperson',
    label: 'Salesperson',
    index: 23,
    required: false,
    maxLength: 300,
  },
  {
    id: 'paymentTerms',
    label: 'Payment Terms',
    index: 24,
    required: false,
    maxLength: 500,
  },
  {
    id: 'remarks',
    label: 'Remarks',
    index: 25,
    required: false,
    maxLength: 1000,
  },
  {
    id: 'glEntry',
    label: 'GL Entry? (Y)',
    index: 26,
    required: false,
    maxLength: 1,
  },
  {
    id: 'offsetInvoice',
    label: 'Offset Invoice For CN',
    index: 27,
    required: false,
    maxLength: 20,
  },
  {
    id: 'gstInclusive',
    label: 'GST Inclusive? (Y)',
    index: 28,
    required: false,
    maxLength: 1,
  },
];
