import { ClassProvider, InjectionToken, ValueProvider } from '@angular/core';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetabaseParameterModule } from './metabase-parameter/metabase-parameter.module';
export const METABASE_PREFIX_URL = new InjectionToken<string>('METABASE_PREFIX_URL');

@NgModule({
  imports: [CommonModule, MetabaseParameterModule.forRoot()],
})
export class MetabaseModule {
  static forRoot(configs?: { load: ClassProvider[]; prefix?: string }): ModuleWithProviders<MetabaseModule> {
    const valueProviders: ValueProvider[] = [
      {
        provide: METABASE_PREFIX_URL,
        useValue: configs?.prefix || '',
      },
    ];
    const classProviders: ClassProvider[] = configs?.load || [];
    return {
      ngModule: MetabaseModule,
      providers: [...valueProviders, ...classProviders],
    };
  }
  static forFeature(configs: { prefix: string }): ModuleWithProviders<MetabaseModule> {
    const valueProviders: ValueProvider[] = [
      {
        provide: METABASE_PREFIX_URL,
        useValue: configs.prefix,
      },
    ];

    return {
      ngModule: MetabaseModule,
      providers: [...valueProviders],
    };
  }
}
