import { YesNoRecord } from '../types';
import { EYesNo } from '../enums';

export const YES_NO_OPTIONS: YesNoRecord = {
  [EYesNo.Yes]: {
    id: EYesNo.Yes,
    value: true,
    viewValue: 'Yes',
    viewClass: 'status-box-success',
  },
  [EYesNo.No]: {
    id: EYesNo.No,
    value: false,
    viewValue: 'No',
    viewClass: 'status-box-default',
  },
};
