import { BaseModel, Default, EndOfDate, FormattedDateString, PhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ECitizen, EGender } from '../enums';
import { MaritalModel } from '../models';
import { AbstractListFilters } from '@shared/core';

export class PersonalParticularAddressDto extends BaseModel {
  @Expose()
  @Default('')
  buildingName?: string;

  @Expose()
  @Default('')
  district?: string;

  @Expose()
  @Default('')
  floor?: string;

  @Expose()
  @Default('')
  house!: string;

  @Expose()
  @Default('')
  street!: string;

  @Expose()
  @Default('')
  address!: string;

  @Expose()
  @Default('')
  unit?: string;

  @Expose()
  @Default('')
  postalCode?: string;

  @Expose()
  @Default('')
  addressLine1?: string;

  @Expose()
  @Default('')
  addressLine2?: string;

  @Expose()
  @Default('')
  addressLine3?: string;
}

export class PersonalParticularRecruiterDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  associatesCode!: string;

  @Expose()
  businessName!: string;

  @Expose()
  nricName!: string;
}

export class PersonalParticularCreateDto extends BaseModel {
  @Expose()
  @Type(() => PersonalParticularAddressDto)
  address!: PersonalParticularAddressDto;

  @Expose()
  associatesCode!: string;

  @Expose()
  avatar?: string;

  @Expose()
  bankAccountNo?: string | null;

  @Expose()
  @Type(() => Number)
  bankId?: number | null;

  @Expose()
  businessName!: string;

  @Expose()
  ceaRegNo!: string;

  @Expose()
  companyIds!: number[];

  @Expose()
  @Type(() => Number)
  countryId!: number;

  @Expose()
  credential!: {
    username: string;
  };

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Default(null)
  dateOfBirth?: string;

  @Expose()
  email1!: string;

  @Expose()
  email2?: string;

  @Expose()
  emplacementRemark?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  expireDate?: string;

  @Expose()
  @Type(() => PhoneNumber)
  fax?: PhoneNumber;

  @Expose()
  gender!: EGender;

  @Expose()
  @Type(() => Number)
  idTypeId?: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  issueDate?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  joinDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Default(null)
  managerEffectiveDate?: string;

  @Expose()
  @Type(() => Number)
  maritalId?: number;

  @Expose()
  @Type(() => MaritalModel)
  marital?: MaritalModel;

  @Expose()
  citizen?: ECitizen;

  @Expose()
  @Type(() => PhoneNumber)
  mobile1!: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  mobile2?: PhoneNumber;

  @Expose()
  nationalityId?: string;

  @Expose()
  nricName!: string;

  @Expose()
  nricfin!: string;

  @Expose()
  // @Type(() => Number)
  parentId?: number | null;

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  postalCode?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Default(null)
  recruiterDateFrom?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Default(null)
  recruiterDateTo?: string;

  @Expose()
  recruiterId?: number;

  @Expose()
  @Type(() => PersonalParticularRecruiterDto)
  recruiter?: PersonalParticularRecruiterDto;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Default(null)
  resignDate?: string;

  @Expose()
  resignReason?: string;

  @Expose()
  transferRemark?: string;

  @Expose()
  schemeTypeId?: number;

  @Expose()
  @Type(() => Number)
  groupIds?: number[];

  @Expose()
  @Type(() => Number)
  roleIds?: number[];
}

export class PersonalParticularUpdateDto extends PersonalParticularCreateDto { }

export class SalespersonSearchDto extends BaseModel {
  @Expose()
  key?: string;

  @Expose()
  page?: number;

  @Expose()
  limit?: number;

  @Expose()
  entities?: string;

  @Expose()
  isActive?: boolean;

  @Expose()
  orderBy!: string;

  @Expose()
  orderType!: string;

  @Expose()
  getTiers!: boolean;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  optionDate!: string;

  @Expose()
  exceptIds?: number[];
}
export class TeamOrgChartFilters extends AbstractListFilters {
  @Expose()
  companyIds!: number[];

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  joinDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  joinDateEnd!: string;

  // New filters
  @Expose()
  ceaNoAndBusinessName!: string;

  @Expose()
  nricNoAndNricName!: string;

  @Expose()
  tier1!: string;

  @Expose()
  tier2!: string;

  @Expose()
  @Transform(({ obj, value }) => {
    const data = value.map((item: 'true' | 'false') => (item === 'true' ? true : false));
    return data;
  })
  isActive!: boolean[];

  @Expose()
  searchType!: string;
}