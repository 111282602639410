import { AccountSettingType } from '../enums';
import { ITimestamp } from './timestamp.interface';

export interface IAccountSetupDisplay {
  id: number;
  code: string;
  name: string;
}

export interface IAccountSetup extends ITimestamp {
  id: number;
  usage: string;
  type: AccountSettingType;
  currency: string | null | undefined;
  accountId: number | null | undefined;
  account: IAccountSetupDisplay | null | undefined;
}
