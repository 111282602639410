import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { EBlankFormStatus } from '../enums';
import { IBlankFormStatusHistory } from '../interfaces';


export class BlankFormStatusHistoryModel extends BaseModel implements IBlankFormStatusHistory {
  @Expose()
  id!: number;

  @Expose()
  blankFormId!: number;

  @Expose()
  action!: EBlankFormStatus;

  @Expose()
  data!: { reason: string };

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string;

  @Expose()
  deletedBy!: string;

  @Expose()
  state!: string;
}
