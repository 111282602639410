import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '@red/api';
import { ICountryPhoneNumber } from '@shared/data-access/interfaces';
import { cloneDeep } from 'lodash-es';
import { countryPhoneNumbers } from '@shared/data-access/data';

@Injectable({
  providedIn: 'root',
})
export class MetadataApiService {
  private readonly _countryPhoneNumbers: ICountryPhoneNumber[] = countryPhoneNumbers;
  constructor(private _httpClient: HttpClient, private _apiService: ApiService) {}

  getCountryPhoneNumber(): Observable<ICountryPhoneNumber[]> {
    return of(cloneDeep(this._countryPhoneNumbers));
  }
}
