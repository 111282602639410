import { EProjectPropertyType } from '../enums';
import { ProjectPropertyTypeRecord } from '../types';

export const PROJECT_TYPES = [
  {
    id: EProjectPropertyType.condominiumApartment,
    name: 'Residential - Condominium / Apartment ',
  },
  {
    id: EProjectPropertyType.excutiveCondominium,
    name: 'Residential - Executive Condominium',
  },
  {
    id: EProjectPropertyType.landedTerraceHouse,
    name: 'Landed - Terrace House',
  },
  {
    id: EProjectPropertyType.landedSemiDetachedHouse,
    name: 'Landed - Semi-Detached House',
  },
  {
    id: EProjectPropertyType.landedDetachedHouse,
    name: 'Landed - Detached House',
  },
  {
    id: EProjectPropertyType.landedStrataHouse,
    name: 'Landed - Strata Landed',
  },
  {
    id: EProjectPropertyType.commercialOffice,
    name: 'Commercial - Office',
  },
  {
    id: EProjectPropertyType.commercialShophouse,
    name: 'Commercial - Shophouse',
  },
  {
    id: EProjectPropertyType.commercialRetail,
    name: 'Commercial - Retail',
  },
  {
    id: EProjectPropertyType.industrialWarehouse,
    name: 'Industrial - Warehouse',
  },
  {
    id: EProjectPropertyType.industrialBusinessHub,
    name: 'Industrial - Business Park',
  },
  {
    id: EProjectPropertyType.industrialSingleUserFactory,
    name: 'Industrial - Single-User Factory',
  },
  {
    id: EProjectPropertyType.industrialMultipleUserFactory,
    name: 'Industrial - Multiple-User Factory',
  },
  {
    id: EProjectPropertyType.retail,
    name: 'Retail',
  },
] as const;

export const PROJECT_SALE_TYPES = [
  {
    id: 'condon',
    name: 'New Sales - Condo',
  },
  {
    id: 'excutiveCondon',
    name: 'New Sales - Excutive Condo',
  },
  {
    id: 'landed',
    name: 'New Sales - Landed',
  },
  {
    id: 'commercial',
    name: 'Commercial',
  },
  {
    id: 'retail',
    name: 'Retail',
  },
  {
    id: 'office',
    name: 'Office',
  },
] as const;

function mapDataProjectPropertyTypes() {
  const object: any = {};
  PROJECT_TYPES.forEach(data => {
    object[data.id] = {
      id: data.id,
      value: data.id,
      viewValue: data.name,
    };
  });

  return object;
}

export const PROJECT_PROPERTY_TYPES_OPTIONS: ProjectPropertyTypeRecord = mapDataProjectPropertyTypes();
