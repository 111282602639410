import { IBroker } from './broker.interface';
import { ISplitPart } from './split-part.interface';
import { IPhoneNumber } from '@red/data-access';

export interface IProjectTransactionRelatedCobroke {
  id: number;
  absorbType: { agent: boolean; hr: boolean };
  agentId: number;
  agent: IBroker;
  brokerName: string;
  brokerPhone: IPhoneNumber;
  receivedDirectPercent: number;
  receivedPercent: number;
  receivedValue: number;
  transactedPercent: number;
  transactedValue: number;
  createdAt: string;
  updatedAt: string;
  parties?: ISplitPart[];
  tiers?: ISplitPart[];
  agentType: string;
}
