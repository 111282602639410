import { coerceBooleanProperty, coerceStringArray } from '@angular/cdk/coercion';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { IPermission } from './interfaces/permission.interface';
import { PermissionStore } from './models/permission-store.model';
import { Permission } from './models/permission.model';
import { PermissionLoader, PERMISSION_LOADER } from './permission-loader';
import { RedPermissionState, RedPermissionStateType } from './permission-state.type';

@Injectable()
export class PermissionsService {
  private _store = new PermissionStore<Permission>([], (o1, o2) => o1.id === o2.id);
  private _state$ = new BehaviorSubject<RedPermissionStateType>(RedPermissionState.INITIAL);
  constructor(@Inject(PERMISSION_LOADER) private _loader: PermissionLoader) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for permissions
   */
  get permissions$(): Observable<Permission[]> {
    return this._store.permissions$;
  }

  /**
   * Getter for state
   */
  get state$(): Observable<RedPermissionStateType> {
    return this._state$.asObservable();
  }

  getPermissions(): Permission[] {
    return this._store.selected;
  }

  get(): Observable<Permission[]> {
    const currentState = this._state$.getValue();
    if (currentState === RedPermissionState.LOAD_SUCCESSFUL) {
      return of(this.getPermissions());
    }
    this._state$.next(RedPermissionState.LOADING);
    return this._loader.get().pipe(
      map((data: IPermission[]) => data.map(item => Permission.fromJson(item))),
      tap(permissions => {
        const changed = this._store.select(...permissions);
        if (!changed) {
          this._store.forcedEmitEvent();
        }
        this._state$.next(RedPermissionState.LOAD_SUCCESSFUL);
      }),
      catchError(err => {
        this._state$.next(RedPermissionState.LOAD_FAILED);
        return throwError(() => err);
      })
    );
  }
  canAccess(slug: string | string[]): boolean {
    const slugs = coerceStringArray(slug);
    const selected = this._store.find(item => slugs.some(checked => checked === item.slug));
    return !!selected;
  }
  canAsyncAccess(slug: string | string[], exception?: Observable<boolean>): Observable<boolean> {
    const slugs = coerceStringArray(slug);
    const selected = this._store.find(item => slugs.some(checked => checked === item.slug));
    if (selected && exception) {
      return exception.pipe(
        map(val => {
          return coerceBooleanProperty(val) && coerceBooleanProperty(selected);
        })
      );
    }
    return of(false);
  }
  canDeny(slug: string | string[]): boolean {
    const slugs = coerceStringArray(slug);
    const selected = this._store.find(item => slugs.some(checked => checked === item.slug));
    return !selected;
  }
  reset(): void {
    this._state$.next(RedPermissionState.INITIAL);
    this._store.clear();
  }
}
