import { ESupplierInvoicePaidStatus, ESupplierInvoiceStatus } from '../enums';
import { EGstCategorySupplier, ESupplierInvoice } from '../enums/tax-invoice-enhanced.enum';
import { StatusRecord } from '../types';

export const SUPPLIER_INVOICE_GST_CATEGORY_MAX_TAX_PERCENT: Record<EGstCategorySupplier, number> = {
  [EGstCategorySupplier.NR]: 0,
  [EGstCategorySupplier.TX7]: 7,
  [EGstCategorySupplier.TX8]: 8,
  [EGstCategorySupplier.EP]: 0,
  [EGstCategorySupplier.BL]: 8,
  [EGstCategorySupplier.ZP]: 0,
  [EGstCategorySupplier.IM]: 8,
  [EGstCategorySupplier.ME]: 0,
  [EGstCategorySupplier.TXE33]: 8,
  [EGstCategorySupplier.OP]: 0,
  [EGstCategorySupplier.TXN33]: 8,
  [EGstCategorySupplier.TXRE]: 8,
};

export const SUPPLIER_INVOICE_TYPES: StatusRecord<ESupplierInvoice> = {
  [ESupplierInvoice.NonPO]: {
    id: ESupplierInvoice.NonPO,
    name: 'Non-PO Invoice',
    class: 'status-box-dark-electric-blue',
  },
};

export const SUPPLIER_INVOICE_STATUSES: StatusRecord<ESupplierInvoiceStatus> = {
  // [ESupplierInvoiceStatus.View]: {
  //   id: ESupplierInvoiceStatus.View,
  //   name: 'View',
  //   class: 'status-box-purple',
  // },
  [ESupplierInvoiceStatus.Pending]: {
    id: ESupplierInvoiceStatus.Pending,
    name: 'Pending',
    class: 'status-box-warning',
  },
  [ESupplierInvoiceStatus.PartPaid]: {
    id: ESupplierInvoiceStatus.PartPaid,
    name: 'Part Paid',
    class: 'status-box-warning',
  },
  [ESupplierInvoiceStatus.FullyPaid]: {
    id: ESupplierInvoiceStatus.FullyPaid,
    name: 'Fully Paid',
    class: 'status-box-success',
  },
  [ESupplierInvoiceStatus.Confirmed]: {
    id: ESupplierInvoiceStatus.Confirmed,
    name: 'Confirmed',
    class: 'status-box-success',
  },
  [ESupplierInvoiceStatus.Withhold]: {
    id: ESupplierInvoiceStatus.Withhold,
    name: 'Withhold',
    class: 'status-box-danger',
  },
  [ESupplierInvoiceStatus.PaymentInProgress]: {
    id: ESupplierInvoiceStatus.PaymentInProgress,
    name: 'Payment In Progress',
    class: 'status-box-warning',
  },
};

export const SUPPLIER_INVOICE_PAID_STATUSES: StatusRecord<ESupplierInvoicePaidStatus> = {
  [ESupplierInvoicePaidStatus.NOT_PAID]: {
    id: ESupplierInvoicePaidStatus.NOT_PAID,
    name: 'Not Paid',
    class: 'status-box-dark-electric-blue',
  },
  [ESupplierInvoicePaidStatus.NOT_FULL_PAID]: {
    id: ESupplierInvoicePaidStatus.NOT_FULL_PAID,
    name: 'Not Full Paid',
    class: 'status-box-dark-electric-blue',
  },
  [ESupplierInvoicePaidStatus.PaymentInProgress]: {
    id: ESupplierInvoicePaidStatus.PaymentInProgress,
    name: 'Payment In Progress',
    class: 'status-box-dark-electric-blue',
  },
  [ESupplierInvoicePaidStatus.PENDING]: {
    id: ESupplierInvoicePaidStatus.PENDING,
    name: 'Pending',
    class: 'status-box-dark-electric-blue',
  },
  [ESupplierInvoicePaidStatus.FULLY_PAID]: {
    id: ESupplierInvoicePaidStatus.FULLY_PAID,
    name: 'Fully Paid',
    class: 'status-box-dark-electric-blue',
  },
  [ESupplierInvoicePaidStatus.CREDITED]: {
    id: ESupplierInvoicePaidStatus.CREDITED,
    name: 'Credited',
    class: 'status-box-dark-electric-blue',
  },
};
