import { BaseModel, Default, FormattedDateString, FormattedStringToDateOfBirth, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EEcddCustomerType, EEcddTransactionType, EEcddType } from '../enums';
import { AddressModel } from '../models';

export class EcddCustomerSignatureDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  signature!: string;
}

export class EcddOptionGroupDto extends BaseModel {
  @Expose()
  optionGroupId!: number;

  @Expose()
  remarks!: string;

  @Expose()
  metadata!: object;

  @Expose()
  choosenOptionId!: number;

  @Expose()
  sequence!: number;
}

export class EcddCustomerDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  type!: EEcddCustomerType;

  @Expose()
  name!: string;

  @Expose()
  beneficialOwner!: string;

  @Expose()
  nricfin!: string;

  @Expose()
  idTypeId!: number;

  @Expose()
  // @StartOfDate()
  @FormattedStringToDateOfBirth()
  dateOfBirth!: string;

  @Expose()
  nationalityId!: number;

  @Expose()
  nationality!: string;

  @Expose()
  occupation!: string;

  @Expose()
  countryId!: number;

  @Expose()
  country!: string;

  @Expose()
  registrationNumber!: string;

  @Expose()
  identifyingType!: string;

  @Expose()
  mainBusiness!: string;

  @Expose()
  signature!: string;
}

export class EcddAmlNameDetailDto extends BaseModel {
  @Expose()
  surname!: string;

  @Expose()
  firstName!: string;

  @Expose()
  middleName!: string;

  @Expose()
  entityName!: string;
}

export class EcddAmlDateDetailDto extends BaseModel {
  @Expose()
  dateType!: string;

  @Expose()
  dateValue!: string;
}

export class EcddAmlCountryDetailDto extends BaseModel {
  @Expose()
  countryType!: string;

  @Expose()
  country!: any;
}

export class EcddAmlSanctionsDetailDto extends BaseModel {
  @Expose()
  sanctionName!: string;

  @Expose()
  sanctionStatus!: string;

  @Expose()
  sanctionReferenceId!: number;

  @Expose()
  sanction!: Record<string, any>;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  sinceDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  toDate!: string;
}

export class EcddAmlDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  @Type(() => EcddAmlNameDetailDto)
  nameDetail!: EcddAmlNameDetailDto;

  @Expose()
  gender!: string;

  @Expose()
  @Type(() => EcddAmlDateDetailDto)
  dateDetails!: EcddAmlDateDetailDto[];

  @Expose()
  @Type(() => EcddAmlCountryDetailDto)
  countryDetails!: EcddAmlCountryDetailDto[];

  @Expose()
  @Type(() => EcddAmlSanctionsDetailDto)
  sanctionsReferences!: EcddAmlSanctionsDetailDto[];
}
export class EcddDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  type!: EEcddType;

  @Expose()
  transactionType!: EEcddTransactionType;

  @Expose()
  documentTitle!: string;

  @Expose()
  salespersonId!: number;

  @Expose()
  signature!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  completedAt!: string;

  @Expose()
  @Type(() => AddressModel)
  address!: AddressModel;

  @Expose()
  isManually!: boolean;

  @Expose()
  @Type(() => EcddCustomerDto)
  customers!: EcddCustomerDto[];

  @Expose()
  @Type(() => EcddAmlDto)
  amlClient!: EcddAmlDto;

  @Expose()
  @Type(() => EcddOptionGroupDto)
  ecddOptionGroups!: EcddOptionGroupDto[];

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  checklistAt!: string;

  @Expose()
  nricName!: string;

  @Expose()
  ceaRegNo!: string;
}

export class EcddUpdateRemarkDto extends BaseModel {
  @Expose()
  remarks!: string;
}

export class EcddUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'full';

  @Expose()
  @Type(() => EcddDto)
  data!: EcddDto;
}
