import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IBase } from '../interfaces/base-finance.interface';
import { UpdatedByModel } from './updated-by.model';
export class BaseFixedModel extends BaseModel implements IBase {
  @Expose()
  id!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  deletedBy!: string;
}