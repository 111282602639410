// core
import { Expose, Type } from 'class-transformer';

// share
import { PersonalParticularModel } from './personal-particular.model';
import { CommissionSchemeModel } from './commission-scheme.model';
import { IPromotionApproval } from '../interfaces';
import { VERIFY_STATUS_OPTIONS } from '../data';
import { BaseModel } from '@red/data-access';
import { EVerifyStatus } from '../enums';

export class PromotionApprovalModel extends BaseModel implements IPromotionApproval {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  status!: EVerifyStatus;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  currentCareerId!: number;

  @Expose()
  @Type(() => Number)
  newSalespersonId!: number;

  @Expose()
  @Type(() => Number)
  newLeadershipId!: number;

  @Expose()
  rejectReason!: string;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  @Type(() => CommissionSchemeModel)
  currentCareerProgression!: CommissionSchemeModel;

  @Expose()
  @Type(() => CommissionSchemeModel)
  careerProgression!: CommissionSchemeModel;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  updatedBy!: string;

  @Expose()
  deletedBy!: string;

  //#region getter
  get statusInfo() {
    return VERIFY_STATUS_OPTIONS[this.status] ?? null;
  }
  //#endregion
}
