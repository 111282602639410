import { Directive, Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ReportCard } from '@shared/components/report-manager';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { map, Observable } from 'rxjs';
import { MetabaseApiService, MetabaseCardCollection } from './metabase-report-api.service';
import { Mixin } from 'ts-mixer';
import { ExceljsCard } from '@shared/components/exceljs-viewer';
import { ExceljsApiService, ExceljsCardCollection } from './exceljs-report-api.service';
@Directive()
abstract class FinanceReportsMetabaseApiService extends MetabaseApiService {
  collection: MetabaseCardCollection = 'finance_reports';
}

@Directive()
abstract class FinanceReportsExceljsApiService extends ExceljsApiService {
  collectionEndpoint = ExceljsCardCollection.FinanceReport;
}
@Directive()
abstract class FinanceReportsCardApiService {
  static REPORT_CARD_POINT = 'finance/reports';
  constructor(protected apiService: ApiService) {}

  findReportCards(query: IQuerySearch = {}, option?: IApiOption): Observable<PaginationAdapter<ReportCard>> {
    return this.apiService.get(FinanceReportsCardApiService.REPORT_CARD_POINT, query, option).pipe(map(data => new PaginationAdapter(ReportCard, data)));
  }
}

@Injectable({
  providedIn: 'root',
})
export class FinanceReportsApiService extends Mixin(FinanceReportsCardApiService, FinanceReportsMetabaseApiService, FinanceReportsExceljsApiService) {}
