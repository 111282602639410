import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class InvoiceDetailDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  itemCode?: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Boolean)
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  remarks!: string;

  @Expose()
  description!: string;

  @Expose()
  uom?: string;

  @Expose()
  @Type(() => Number)
  gstAmount!: number;
}
