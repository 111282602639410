import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrimValueAccessorDirective } from './trim.directive';



@NgModule({
  declarations: [

    TrimValueAccessorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [

    TrimValueAccessorDirective
    ]
})
export class TrimModule { }
