import { EAgentBlankFormSession } from "../enums";

export const AgentBlankFormSession = {
    [EAgentBlankFormSession.ClosingAgent]: 'Closing Agent',
    [EAgentBlankFormSession.Internal]: 'Co-Broke',
    [EAgentBlankFormSession.TeamLeader]: 'Group Leader/LIC',
    [EAgentBlankFormSession.Pool]: 'Pool',
    [EAgentBlankFormSession.Overriding]: 'IC Comm',
    [EAgentBlankFormSession.Bonus]: 'IC Bonus',
    [EAgentBlankFormSession.InternalRefferal]: 'Internal Referral',
    [EAgentBlankFormSession.PDOverride]: 'PD Override',
    [EAgentBlankFormSession.PDReferral]: 'PD Referral',
    [EAgentBlankFormSession.OTHManagement]: 'OTH Management',
    [EAgentBlankFormSession.PDManagement]: 'PD Management',
    [EAgentBlankFormSession.HIBD]: 'HIBD',
    [EAgentBlankFormSession.HeadHI]: 'Head Of Hi',
    [EAgentBlankFormSession.BusinessDirector]: 'BDs',
}