import { BaseModel, Default } from '@red/data-access';
import { Expose } from 'class-transformer';

export class PaginationDto extends BaseModel {
  @Expose()
  @Default(12)
  limit?: number;

  @Expose()
  @Default(1)
  page?: number;

  @Expose()
  @Default('updatedAt')
  orderBy?: string;

  @Expose()
  @Default('desc')
  orderType?: string;
}
