import { EPaymentDirectStatus } from '../enums';
import { IContact } from './contact.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IMemoPayable } from './memo-payable.interface';
import { IReceipt } from './receipt.interface';

export interface IDirectDebitPaymentPostingAccount {
  accountCode: string
  accountId: number
  accountName: string
}

export interface IDirectDebitPaymentRecord {
  paymentMemoDetailId: number;
  amount: number;
  paymentMemoType: 'SupplierInvoice' | 'CustomerCreditNote';
  paymentMemoDetail: IMemoPayable;
  updatedAt: Date;
  id: number;
  paymentDirectId: number;
  receipt: IReceipt;
  receiptId: number;
  contactId?: number;
  contact?: IContact;
  invoiceId: number;
  posting: IDirectDebitPaymentPostingAccount;
  errorMessage: string;
  errorCode: string;
  supplierInvoiceCode: string;
}
export type DirectDebitPaymentStatus = 'Created' | 'EmailSent' | 'Posted';
export interface IDirectDebitPayment {
  id: number;
  code: string;
  transactionCode: string;
  // submissionDate: Date;
  submissionDate: string;
  bankReturnFile: string;
  // valueDate: Date;
  valueDate: string;
  accountId: number;
  account: ILedgerAccount;
  description: string;
  items: IDirectDebitPaymentRecord[];
  status: EPaymentDirectStatus;
}
