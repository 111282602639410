/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IMockCommissionPayout {
  id: number;
  caseNo: string;
  subjectProperty: string;
  commType: string;
  payoutDate: string;
  payoutMethod: string;
  payoutValue: number;
  commScheme: number;
  transactionType: string;
}

export interface IMockCommissionPayoutSummary {
  totalPayout: number;
  totalTransaction: number;
}

export interface ICommissionPayout extends IMockCommissionPayout {}

export interface ICommissionPayoutSummary extends IMockCommissionPayoutSummary {}
