export interface IProjectTransactionOther {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
  code: string;
  name: string;
  receivedPercent: number;
  receivedValue: number;
  totalAmount: number;
  data: any;
  isDefault: boolean;
}
