import { BaseModel, FormattedDateString, PhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ContactType, Status } from '../enums';

export class ContactEmailDto extends BaseModel {
  @Expose()
  id?: number;

  @Expose()
  email!: string;
}

export class ContactCustomerOrSupplierDto extends BaseModel {
  @Expose()
  type!: ContactType;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  category!: string;

  @Expose()
  @Type(() => Number)
  categoryId?: number;

  @Expose()
  @Transform(({ value }) => value.filter((item: ContactEmailDto) => item.email))
  @Type(() => ContactEmailDto)
  emails!: ContactEmailDto[];

  @Expose()
  phone!: PhoneNumber;

  @Expose()
  fax!: string;

  @Expose()
  status!: Status;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  @Type(() => Number)
  creditLimit?: number;

  @Expose()
  @Type(() => Number)
  receiveAccountId?: number;

  @Expose()
  @Type(() => Number)
  paidAccountId?: number;

  @Expose()
  businessRegNo?: string;

  @Expose()
  gstRegNo?: string;

  @Expose()
  remark?: string;

  @Expose()
  groupName?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  commerceDate?: string;

  @Expose()
  gstRegistered!: boolean;

  @Expose()
  isWithHold?: boolean;
}
