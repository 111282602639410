import {
  EClientType,
  ECommType,
  EFurnished,
  EGstType,
  EPaymentAdvice,
  EResaleAgentType,
  EResaleEmailTo,
  EResaleNoOfRoom,
  EResaleNoOfRoomHdb,
  EResalePaymentMethod,
  EResalePropertyType,
  EResalePropertyTypeSearch,
  EResaleStatus,
  EResaleType,
} from '../enums';
import { EMessageOutboxStatus } from '../enums/message-outbox.enum';
import {
  FurnishedRecord,
  OptionDescription,
  PaymentAdviceRecord,
  ResaleNoOfRoomTypeHdbRecord,
  ResaleNoOfRoomTypeRecord,
  ResalePropertyTypeRecord,
  ResalePropertyTypeSearchRecord,
  ResaleTypeOptionRecord,
} from '../types';
import { StatusRecord } from '../types/status.type';

export const RESALE_STATUSES: StatusRecord<EResaleStatus> = {
  [EResaleStatus.draft]: {
    id: EResaleStatus.draft,
    name: 'Draft',
    filterName: 'Draft',
    showOnFilter: true,
    class: 'status-box-dark-electric-blue',
    canSubmit: true,
    canEdit: true,
    canDelete: true,
    canAgentDelete: true,
    canAgentEdit: true,
  },
  [EResaleStatus.submitted]: {
    id: EResaleStatus.submitted,
    name: 'Submitted',
    filterName: 'Submitted',
    showOnFilter: true,
    class: 'status-box-vivid-crimson',
    canEdit: true,
    canDelete: true,
    canAgentUploadDocs: true,
  },
  [EResaleStatus.rework]: {
    id: EResaleStatus.rework,
    class: 'status-box-brilliant-azure',
    name: 'Send Back (See Remarks)',
    filterName: 'Send Back (See Remarks)',
    showOnFilter: true,
    canSubmit: true,
    canEdit: true,
    canDelete: true,
    canAgentUploadDocs: true,
  },
  [EResaleStatus.withdrawn]: {
    id: EResaleStatus.withdrawn,
    class: 'status-box-dim-gray',
    name: 'Withdrawn',
    filterName: 'Withdrawn',
    showOnFilter: true,
    canEdit: true,
    canDelete: true,
  },
  [EResaleStatus.confirmed]: {
    id: EResaleStatus.confirmed,
    class: 'status-box-warning',
    name: 'Pending Approval',
    filterName: 'Pending Approval',
    showOnFilter: true,
    // canSubmit: true,
    canEdit: true,
    canAgentUploadDocs: true,

  },
  [EResaleStatus.sendBack]: {
    id: EResaleStatus.sendBack,
    class: 'status-box-brilliant-azure',
    name: 'Send Back (See Remarks)',
    filterName: 'Send Back (See Remarks)',
    showOnFilter: true,
    canSubmit: true,
    canEdit: true,
    canAgentUploadDocs: true,

  },
  [EResaleStatus.verified]: {
    id: EResaleStatus.verified,
    name: 'Pending Invoice',
    filterName: 'Pending Invoice',
    showOnFilter: true,
    class: 'status-box-bright-yellow',
    canAgentUploadDocs: true,
  },
  [EResaleStatus.approved]: {
    id: EResaleStatus.approved,
    name: 'Outstanding',
    filterName: 'Outstanding',
    showOnFilter: true,
    class: 'status-box-turquoise-surf',
    canAgentUploadDocs: true,
  },
  [EResaleStatus.approvedPC]: {
    id: EResaleStatus.approvedPC,
    name: 'Outstanding (P-PC Docs)',
    filterName: 'Outstanding (P-PC Docs)',
    showOnFilter: true,
    class: 'status-box-turquoise-surf',
    canAgentUploadDocs: true,

  },
  [EResaleStatus.receivedPartial]: {
    id: EResaleStatus.receivedPartial,
    name: 'Received Partial',
    filterName: 'Received Partial',
    showOnFilter: true,
    class: 'status-box-grape',
    canAgentUploadDocs: true,
  },
  [EResaleStatus.partial]: {
    id: EResaleStatus.partial,
    name: 'Partial CN',
    filterName: 'Partial CN',
    showOnFilter: true,
    class: 'status-box-zaffer',
  },
  [EResaleStatus.cancelled]: {
    id: EResaleStatus.cancelled,
    name: 'Cancelled',
    filterName: 'Cancelled',
    showOnFilter: true,
    class: 'status-box-electric-orange',
  },
  [EResaleStatus.cancelledPartial]: {
    id: EResaleStatus.cancelledPartial,
    name: 'Cancelled Partial',
    filterName: 'Cancelled Partial',
    showOnFilter: true,
    class: 'status-box-zaffer',
  },
  [EResaleStatus.aborted]: {
    id: EResaleStatus.aborted,
    name: 'Aborted',
    filterName: 'Aborted',
    showOnFilter: true,
    class: 'status-box-smokey-topaz',
  },
  [EResaleStatus.writeOff]: {
    id: EResaleStatus.writeOff,
    name: 'Write Off',
    filterName: 'Write Off',
    showOnFilter: true,
    class: 'status-box-deep-pink',
  },
  [EResaleStatus.rejected]: {
    id: EResaleStatus.rejected,
    name: 'Rejected',
    filterName: 'Rejected',
    showOnFilter: false,
    class: 'status-box-danger',
  },
  [EResaleStatus.completed]: {
    id: EResaleStatus.completed,
    name: 'Completed',
    filterName: 'Completed',
    showOnFilter: true,
    class: 'status-box-green',
  },
  [EResaleStatus.received]: {
    id: EResaleStatus.received,
    name: 'Received',
    filterName: 'Received',
    showOnFilter: true,
    class: 'status-box-green',
  },
};

export const RESALE_TYPE_OPTIONS: ResaleTypeOptionRecord = {
  [EResaleType.resale]: {
    id: EResaleType.resale,
    value: EResaleType.resale,
    viewValue: 'RESALE',
    correspondingField: ['submissionDate', 'optionDate', 'completionDate'],
    mandatoryField: ['optionDate', 'completionDate'],
  },
  [EResaleType.enBloc]: {
    id: EResaleType.enBloc,
    value: EResaleType.enBloc,
    viewValue: 'EN BLOC',
    correspondingField: ['submissionDate', 'optionDate', 'completionDate'],
    mandatoryField: ['optionDate', 'completionDate'],
  },
  [EResaleType.lease]: {
    id: EResaleType.lease,
    value: EResaleType.lease,
    viewValue: 'LEASE',
    correspondingField: ['submissionDate', 'agreementDate', 'commencementDate', 'expireDate'],
    mandatoryField: ['agreementDate', 'commencementDate', 'expireDate'],
  },
  [EResaleType.leaseRental]: {
    id: EResaleType.leaseRental,
    value: EResaleType.leaseRental,
    viewValue: 'LEASE (ROOM RENTAL)',
    correspondingField: ['submissionDate', 'agreementDate', 'commencementDate', 'expireDate'],
    mandatoryField: ['agreementDate', 'commencementDate', 'expireDate'],
  },
  [EResaleType.developerSale]: {
    id: EResaleType.developerSale,
    value: EResaleType.developerSale,
    viewValue: 'NEW SALE (UNCOMPLETED)',
    correspondingField: ['submissionDate', 'optionDate', 'completionDate'],
    mandatoryField: ['optionDate']
  },
  [EResaleType.assignment]: {
    id: EResaleType.assignment,
    value: EResaleType.assignment,
    viewValue: 'ASSIGNMENT',
    correspondingField: ['submissionDate', 'agreementDate', 'commencementDate', 'expireDate'],
    mandatoryField: ['agreementDate', 'commencementDate', 'expireDate'],
  },
};

export const RESALE_PROPERTY_TYPE_OPTIONS: ResalePropertyTypeRecord = {
  [EResalePropertyType.ResidentialCondominiumApartment]: {
    id: EResalePropertyType.ResidentialCondominiumApartment,
    value: EResalePropertyType.ResidentialCondominiumApartment,
    viewValue: 'Residential - Condominium / Apartment',
    hidden: false,
    mandatoryField: ['noOfRooms', 'areaSqm', 'areaSqft', 'floor', 'unit'],
  },
  [EResalePropertyType.ResidentialExecutiveCondominium]: {
    id: EResalePropertyType.ResidentialExecutiveCondominium,
    value: EResalePropertyType.ResidentialExecutiveCondominium,
    viewValue: 'Residential - Executive Condominium',
    hidden: false,
    mandatoryField: ['noOfRooms', 'areaSqm', 'areaSqft', 'floor', 'unit'],
  },
  [EResalePropertyType.ResidentialHDB]: {
    id: EResalePropertyType.ResidentialHDB,
    value: EResalePropertyType.ResidentialHDB,
    viewValue: 'Residential - HDB',
    hidden: false,
    mandatoryField: ['noOfRooms', 'areaSqm', 'areaSqft', 'floor', 'unit'],
  },
  [EResalePropertyType.LandedTerraceHouse]: {
    id: EResalePropertyType.LandedTerraceHouse,
    value: EResalePropertyType.LandedTerraceHouse,
    viewValue: 'Landed - Terrace House',
    hidden: false,
    mandatoryField: ['noOfRooms', 'landSqm', 'landSqft'],
  },
  [EResalePropertyType.LandedSemiDetachedHouse]: {
    id: EResalePropertyType.LandedSemiDetachedHouse,
    value: EResalePropertyType.LandedSemiDetachedHouse,
    viewValue: 'Landed - Semi-Detached House',
    hidden: false,
    mandatoryField: ['noOfRooms', 'landSqm', 'landSqft'],
  },
  [EResalePropertyType.LandedDetachedHouse]: {
    id: EResalePropertyType.LandedDetachedHouse,
    value: EResalePropertyType.LandedDetachedHouse,
    viewValue: 'Landed - Detached House',
    hidden: false,
    mandatoryField: ['noOfRooms', 'landSqm', 'landSqft'],
  },
  [EResalePropertyType.LandedStrataHouse]: {
    id: EResalePropertyType.LandedStrataHouse,
    value: EResalePropertyType.LandedStrataHouse,
    viewValue: 'Landed - Strata House',
    hidden: false,
    mandatoryField: ['noOfRooms', 'landSqm', 'landSqft'],
  },
  [EResalePropertyType.CommercialOffice]: {
    id: EResalePropertyType.CommercialOffice,
    value: EResalePropertyType.CommercialOffice,
    viewValue: 'Commercial - Office',
    hidden: false,
    mandatoryField: ['areaSqm', 'areaSqft', 'floor', 'unit'],
  },
  [EResalePropertyType.CommercialShopHouse]: {
    id: EResalePropertyType.CommercialShopHouse,
    value: EResalePropertyType.CommercialShopHouse,
    viewValue: 'Commercial - Shop House',
    hidden: false,
    mandatoryField: ['areaSqm', 'areaSqft', 'floor', 'unit'],
  },
  [EResalePropertyType.CommercialRetail]: {
    id: EResalePropertyType.CommercialRetail,
    value: EResalePropertyType.CommercialRetail,
    viewValue: 'Commercial - Retail',
    hidden: false,
    mandatoryField: ['areaSqm', 'areaSqft', 'floor', 'unit'],
  },
  [EResalePropertyType.IndustrialWarehouse]: {
    id: EResalePropertyType.IndustrialWarehouse,
    value: EResalePropertyType.IndustrialWarehouse,
    viewValue: 'Industrial - Warehouse',
    hidden: false,
    mandatoryField: ['areaSqm', 'areaSqft', 'floor', 'unit'],
  },
  [EResalePropertyType.IndustrialBusinessPark]: {
    id: EResalePropertyType.IndustrialBusinessPark,
    value: EResalePropertyType.IndustrialBusinessPark,
    viewValue: 'Industrial - Business Park',
    hidden: false,
    mandatoryField: ['areaSqm', 'areaSqft', 'floor', 'unit'],
  },
  [EResalePropertyType.IndustrialSingleUserFactory]: {
    id: EResalePropertyType.IndustrialSingleUserFactory,
    value: EResalePropertyType.IndustrialSingleUserFactory,
    viewValue: 'Industrial - Single-User Factory',
    hidden: false,
    mandatoryField: ['areaSqm', 'areaSqft', 'landSqm', 'landSqft', 'floor', 'unit'],
  },
  [EResalePropertyType.IndustrialMultipleUserFactory]: {
    id: EResalePropertyType.IndustrialMultipleUserFactory,
    value: EResalePropertyType.IndustrialMultipleUserFactory,
    viewValue: 'Industrial - Multiple-User Factory',
    hidden: false,
    mandatoryField: ['areaSqm', 'areaSqft', 'floor', 'unit'],
  },
  // [EResalePropertyType.Retail]: {
  //   id: EResalePropertyType.Retail,
  //   value: EResalePropertyType.Retail,
  //   viewValue: 'Retail',
  // },
  [EResalePropertyType.Landed]: {
    id: EResalePropertyType.Landed,
    value: EResalePropertyType.Landed,
    viewValue: 'Landed',
    hidden: true,
    mandatoryField: ['noOfRooms', 'landSqm', 'landSqft'],
  },
  [EResalePropertyType.Commercial]: {
    id: EResalePropertyType.Commercial,
    value: EResalePropertyType.Commercial,
    viewValue: 'Commercial',
    hidden: true,
    mandatoryField: ['areaSqm', 'areaSqft', 'floor', 'unit'],

  },
  [EResalePropertyType.Industrial]: {
    id: EResalePropertyType.Industrial,
    value: EResalePropertyType.Industrial,
    viewValue: 'Industrial',
    hidden: true,
    mandatoryField: ['areaSqm', 'areaSqft', 'floor', 'unit'],

  },
  [EResalePropertyType.EcAptCondoClusterHse]: {
    id: EResalePropertyType.EcAptCondoClusterHse,
    value: EResalePropertyType.EcAptCondoClusterHse,
    viewValue: 'Ec / Apt / Condo / Cluster / Hse',
    hidden: true,
    mandatoryField: []
  },
};

export const RESALE_PROPERTY_TYPE_SEARCH_OPTIONS: ResalePropertyTypeSearchRecord = {
  [EResalePropertyTypeSearch.EcAptCondoClusterHse]: {
    id: EResalePropertyTypeSearch.EcAptCondoClusterHse,
    value: EResalePropertyTypeSearch.EcAptCondoClusterHse,
    viewValue: 'Ec / Apt / Condo / Cluster / Hse',
  },
  [EResalePropertyTypeSearch.ResidentialCondominiumApartment]: {
    id: EResalePropertyTypeSearch.ResidentialCondominiumApartment,
    value: EResalePropertyTypeSearch.ResidentialCondominiumApartment,
    viewValue: 'Residential - Condominium / Apartment',
  },
  [EResalePropertyTypeSearch.ResidentialExecutiveCondominium]: {
    id: EResalePropertyTypeSearch.ResidentialExecutiveCondominium,
    value: EResalePropertyTypeSearch.ResidentialExecutiveCondominium,
    viewValue: 'Residential - Executive Condominium',
  },
  [EResalePropertyTypeSearch.ResidentialHDB]: {
    id: EResalePropertyTypeSearch.ResidentialHDB,
    value: EResalePropertyTypeSearch.ResidentialHDB,
    viewValue: 'Residential - HDB',
  },
  [EResalePropertyTypeSearch.Landed]: {
    id: EResalePropertyTypeSearch.Landed,
    value: EResalePropertyTypeSearch.Landed,
    viewValue: 'Landed',
  },
  [EResalePropertyTypeSearch.LandedTerraceHouse]: {
    id: EResalePropertyTypeSearch.LandedTerraceHouse,
    value: EResalePropertyTypeSearch.LandedTerraceHouse,
    viewValue: 'Landed - Terrace House',
  },
  [EResalePropertyTypeSearch.LandedSemiDetachedHouse]: {
    id: EResalePropertyTypeSearch.LandedSemiDetachedHouse,
    value: EResalePropertyTypeSearch.LandedSemiDetachedHouse,
    viewValue: 'Landed - Semi-Detached House',
  },
  [EResalePropertyTypeSearch.LandedDetachedHouse]: {
    id: EResalePropertyTypeSearch.LandedDetachedHouse,
    value: EResalePropertyTypeSearch.LandedDetachedHouse,
    viewValue: 'Landed - Detached House',
  },
  [EResalePropertyTypeSearch.LandedStrataHouse]: {
    id: EResalePropertyTypeSearch.LandedStrataHouse,
    value: EResalePropertyTypeSearch.LandedStrataHouse,
    viewValue: 'Landed - Strata House',
  },
  [EResalePropertyTypeSearch.Commercial]: {
    id: EResalePropertyTypeSearch.Commercial,
    value: EResalePropertyTypeSearch.Commercial,
    viewValue: 'Commercial',
  },
  [EResalePropertyTypeSearch.CommercialOffice]: {
    id: EResalePropertyTypeSearch.CommercialOffice,
    value: EResalePropertyTypeSearch.CommercialOffice,
    viewValue: 'Commercial - Office',
  },
  [EResalePropertyTypeSearch.CommercialShopHouse]: {
    id: EResalePropertyTypeSearch.CommercialShopHouse,
    value: EResalePropertyTypeSearch.CommercialShopHouse,
    viewValue: 'Commercial - Shop House',
  },
  [EResalePropertyTypeSearch.CommercialRetail]: {
    id: EResalePropertyTypeSearch.CommercialRetail,
    value: EResalePropertyTypeSearch.CommercialRetail,
    viewValue: 'Commercial - Retail',
  },
  [EResalePropertyTypeSearch.Industrial]: {
    id: EResalePropertyTypeSearch.Industrial,
    value: EResalePropertyTypeSearch.Industrial,
    viewValue: 'Industrial',
  },
  [EResalePropertyTypeSearch.IndustrialWarehouse]: {
    id: EResalePropertyTypeSearch.IndustrialWarehouse,
    value: EResalePropertyTypeSearch.IndustrialWarehouse,
    viewValue: 'Industrial - Warehouse',
  },
  [EResalePropertyTypeSearch.IndustrialBusinessPark]: {
    id: EResalePropertyTypeSearch.IndustrialBusinessPark,
    value: EResalePropertyTypeSearch.IndustrialBusinessPark,
    viewValue: 'Industrial - Business Park',
  },
  [EResalePropertyTypeSearch.IndustrialSingleUserFactory]: {
    id: EResalePropertyTypeSearch.IndustrialSingleUserFactory,
    value: EResalePropertyTypeSearch.IndustrialSingleUserFactory,
    viewValue: 'Industrial - Single-User Factory',
  },
  [EResalePropertyTypeSearch.IndustrialMultipleUserFactory]: {
    id: EResalePropertyTypeSearch.IndustrialMultipleUserFactory,
    value: EResalePropertyTypeSearch.IndustrialMultipleUserFactory,
    viewValue: 'Industrial - Multiple-User Factory',
  },
};

export const FURNISHED_OPTIONS: FurnishedRecord = {
  [EFurnished.full]: {
    id: EFurnished.full,
    value: EFurnished.full,
    viewValue: 'Full',
  },
  [EFurnished.partial]: {
    id: EFurnished.partial,
    value: EFurnished.partial,
    viewValue: 'Partial',
  },
  [EFurnished.unfurnished]: {
    id: EFurnished.unfurnished,
    value: EFurnished.unfurnished,
    viewValue: 'Unfurnished',
  },
};

export const PAYMENT_ADVICE_OPTIONS: PaymentAdviceRecord = {
  [EPaymentAdvice.tenant]: {
    id: EPaymentAdvice.tenant,
    value: EPaymentAdvice.tenant,
    viewValue: 'Tenant',
    resaleTypesApply: [EResaleType.lease, EResaleType.leaseRental, EResaleType.assignment],
    showInPortal: true,
  },
  [EPaymentAdvice.landlord]: {
    id: EPaymentAdvice.landlord,
    value: EPaymentAdvice.landlord,
    viewValue: 'Landlord',
    resaleTypesApply: [EResaleType.lease, EResaleType.leaseRental, EResaleType.assignment],
    showInPortal: true,
    isDefault: true,
  },
  [EPaymentAdvice.cobroke]: {
    id: EPaymentAdvice.cobroke,
    value: EPaymentAdvice.cobroke,
    viewValue: 'Co-broke',
    resaleTypesApply: [EResaleType.resale, EResaleType.enBloc, EResaleType.developerSale, EResaleType.lease, EResaleType.leaseRental, EResaleType.assignment],
  },
  [EPaymentAdvice.buyer]: {
    id: EPaymentAdvice.buyer,
    value: EPaymentAdvice.buyer,
    viewValue: 'Buyer',
    resaleTypesApply: [EResaleType.resale, EResaleType.enBloc, EResaleType.developerSale],
    showInPortal: true,
  },
  [EPaymentAdvice.seller]: {
    id: EPaymentAdvice.seller,
    value: EPaymentAdvice.seller,
    viewValue: 'Seller',
    resaleTypesApply: [EResaleType.resale, EResaleType.enBloc, EResaleType.developerSale],
    showInPortal: true,
    isDefault: true,
  },
  [EPaymentAdvice.external]: {
    id: EPaymentAdvice.external,
    value: EPaymentAdvice.external,
    viewValue: 'External Co-broke',
    resaleTypesApply: [],
  },
  [EPaymentAdvice.poa]: {
    id: EPaymentAdvice.poa,
    value: EPaymentAdvice.poa,
    viewValue: 'POA',
  },
  [EPaymentAdvice.solicitor]: {
    id: EPaymentAdvice.solicitor,
    value: EPaymentAdvice.solicitor,
    viewValue: 'Solicitor',
  },
};

export const CLIENT_TYPE_OPTIONS: Record<EClientType, OptionDescription<EClientType>> = {
  [EClientType.seller]: {
    id: EClientType.seller,
    value: EClientType.seller,
    viewValue: 'Seller',
    resaleTypesApply: [EResaleType.resale, EResaleType.enBloc, EResaleType.developerSale],
    isDefault: true,
  },
  [EClientType.buyer]: {
    id: EClientType.buyer,
    value: EClientType.buyer,
    viewValue: 'Buyer',
    resaleTypesApply: [EResaleType.resale, EResaleType.enBloc, EResaleType.developerSale],
  },
  [EClientType.tenant]: {
    id: EClientType.tenant,
    value: EClientType.tenant,
    viewValue: 'Tenant',
    resaleTypesApply: [EResaleType.lease, EResaleType.leaseRental, EResaleType.assignment],
  },
  [EClientType.landlord]: {
    id: EClientType.landlord,
    value: EClientType.landlord,
    viewValue: 'Landlord',
    resaleTypesApply: [EResaleType.lease, EResaleType.leaseRental, EResaleType.assignment],
    isDefault: true,
  },
  [EClientType.cobroke]: {
    id: EClientType.cobroke,
    value: EClientType.cobroke,
    viewValue: 'Co-broke',
    resaleTypesApply: [EResaleType.resale, EResaleType.enBloc, EResaleType.developerSale, EResaleType.lease, EResaleType.leaseRental, EResaleType.assignment],
  },
  [EClientType.poa]: {
    id: EClientType.poa,
    value: EClientType.poa,
    viewValue: 'POA',
    resaleTypesApply: [EResaleType.resale, EResaleType.enBloc, EResaleType.developerSale, EResaleType.lease, EResaleType.leaseRental, EResaleType.assignment],
  },
  [EClientType.solicitor]: {
    id: EClientType.solicitor,
    value: EClientType.solicitor,
    viewValue: 'Solicitor',
    resaleTypesApply: [EResaleType.resale, EResaleType.enBloc, EResaleType.developerSale],
  },
};

export const GST_TYPE_OPTIONS: Record<EGstType, OptionDescription<EGstType>> = {
  [EGstType.gstInclusive]: {
    id: EGstType.gstInclusive,
    value: EGstType.gstInclusive,
    viewValue: 'GST Inclusive',
  },
  [EGstType.gstExclusive]: {
    id: EGstType.gstExclusive,
    value: EGstType.gstExclusive,
    viewValue: 'GST Exclusive',
  },
  [EGstType.gstNo]: {
    id: EGstType.gstNo,
    value: EGstType.gstNo,
    viewValue: 'No GST',
  },
};

export const RESALE_EXTERNAL_COMM_TYPE_OPTIONS: Record<ECommType, OptionDescription<ECommType>> = {
  [ECommType.percentage]: {
    id: ECommType.percentage,
    value: ECommType.percentage,
    viewValue: 'Comm Received Percentage',
  },
  [ECommType.value]: {
    id: ECommType.value,
    value: ECommType.value,
    viewValue: 'Lump Sum Comm SGD',
  },
};

export const RESALE_AGENT_TYPE_OPTIONS: Record<EResaleAgentType, OptionDescription<EResaleAgentType>> = {
  [EResaleAgentType.cobroke]: {
    id: EResaleAgentType.cobroke,
    value: EResaleAgentType.cobroke,
    viewValue: 'Co-Broke',
  },
  [EResaleAgentType.referral]: {
    id: EResaleAgentType.referral,
    value: EResaleAgentType.referral,
    viewValue: 'Referral',
  },
};

export const RESALE_PAYMENT_METHOD_OPTIONS: Record<EResalePaymentMethod, OptionDescription<EResalePaymentMethod>> = {
  // [EResalePaymentMethod.byPost]: {
  //   id: EResalePaymentMethod.byPost,
  //   value: EResalePaymentMethod.byPost,
  //   viewValue: 'CHEQUE GIVEN TO OFFICE STAFF / CHEQUE MAILED TO OFFICE (BY POST)',
  // },
  [EResalePaymentMethod.dropCheque]: {
    id: EResalePaymentMethod.dropCheque,
    value: EResalePaymentMethod.dropCheque,
    viewValue: 'DROP CHEQUE',
  },
  [EResalePaymentMethod.bankTransfer]: {
    id: EResalePaymentMethod.bankTransfer,
    value: EResalePaymentMethod.bankTransfer,
    viewValue: 'FUNDS TRANSFER / PAYNOW',
  },
};

export const RESALE_EMAIL_TO_OPTIONS: Record<
  EResaleEmailTo,
  {
    id: EResaleEmailTo;
    value: EResaleEmailTo;
    viewValue: string;
    clientTypeApplyToEnable: EClientType[];
    showEmailField: boolean;
  }
> = {
  [EResaleEmailTo.agent]: {
    id: EResaleEmailTo.agent,
    value: EResaleEmailTo.agent,
    viewValue: 'Invoice to be email to agent (Default)',
    clientTypeApplyToEnable: [EClientType.seller, EClientType.buyer, EClientType.tenant, EClientType.landlord, EClientType.poa, EClientType.solicitor],
    showEmailField: false,
  },
  [EResaleEmailTo.ecb]: {
    id: EResaleEmailTo.ecb,
    value: EResaleEmailTo.ecb,
    viewValue: 'Invoice to ECB; To CC Agent (Optional)',
    clientTypeApplyToEnable: [],
    showEmailField: true,
  },
  [EResaleEmailTo.client]: {
    id: EResaleEmailTo.client,
    value: EResaleEmailTo.client,
    viewValue: 'Invoice to be email to client; To CC Agent (Optional)',
    clientTypeApplyToEnable: [EClientType.seller, EClientType.buyer, EClientType.tenant, EClientType.landlord, EClientType.poa],
    showEmailField: true,
  },
  [EResaleEmailTo.solicitor]: {
    id: EResaleEmailTo.solicitor,
    value: EResaleEmailTo.solicitor,
    viewValue: 'Invoice to be email to solicitor; To CC Agent (Optional)',
    clientTypeApplyToEnable: [EClientType.poa, EClientType.solicitor],
    showEmailField: true,
  },
  [EResaleEmailTo.hardcopy]: {
    id: EResaleEmailTo.hardcopy,
    value: EResaleEmailTo.hardcopy,
    viewValue: 'To send hardcopy invoice by post (Optional)',
    clientTypeApplyToEnable: [
      EClientType.seller,
      EClientType.buyer,
      EClientType.cobroke,
      EClientType.tenant,
      EClientType.landlord,
      EClientType.poa,
      EClientType.solicitor,
    ],
    showEmailField: false,
  },
};

export const RESALE_NO_OF_ROOMS_OPTIONS_1 = ['Studio', '1B', '1B+', '2B', '2B+', '3B', '3B+', '4B', '4B+', '5B', '>5B'];
export const RESALE_NO_OF_ROOMS_OPTIONS_2 = [
  '1 Room',
  '2 Room',
  '3 Room',
  '4 Room',
  '5 Room',
  'Multi-Gen',
  'Jumbo',
  'Executive',
  'Maisonette',
  'Terrace House',
  'Others',
];

export const RESALE_DROP_CHEQUE_TIME_OPTIONS = [
  '7:00',
  '7:30',
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
];

export const RESALE_STATUS_FILTER_OPTIONS = [
  {
    name: 'Draft',
    value: EResaleStatus.draft,
  },
  {
    name: 'Submitted',
    value: EResaleStatus.submitted,
  },
  {
    name: 'Send Back (See Remarks)',
    value: [EResaleStatus.rework, EResaleStatus.sendBack],
  },
  {
    name: 'Pending Approval',
    value: EResaleStatus.confirmed,
  },
  {
    name: 'Pending Invoice',
    value: EResaleStatus.verified,
  },
  {
    name: 'Outstanding',
    value: EResaleStatus.approved,
  },
  {
    name: 'Outstanding (P-PC Docs)',
    value: EResaleStatus.approvedPC,
  },
  {
    name: 'Received Partial',
    value: EResaleStatus.receivedPartial,
  },
  {
    name: 'Received',
    value: EResaleStatus.received,
  },
  {
    name: 'Cancelled Partial',
    value: EResaleStatus.cancelledPartial,
  },
  {
    name: 'Cancelled',
    value: EResaleStatus.cancelled,
  },
  {
    name: 'Aborted',
    value: EResaleStatus.aborted,
  },
  {
    name: 'Withdrawn',
    value: EResaleStatus.withdrawn,
  },
];

export const RESALE_STATUS_SEARCH_OPTIONS = [
  {
    name: 'Submitted',
    value: EResaleStatus.submitted,
  },
  {
    name: 'Pending Approval',
    value: EResaleStatus.confirmed,
  },
  {
    name: 'Pending Invoice',
    value: EResaleStatus.verified,
  },
  {
    name: 'Outstanding (P-PC Docs)',
    value: EResaleStatus.approvedPC,
  },
  {
    name: 'Outstanding',
    value: EResaleStatus.approved,
  },
  {
    name: 'Received Partial',
    value: EResaleStatus.receivedPartial,
  },
  {
    name: 'Cancelled',
    value: EResaleStatus.cancelled,
  },
  {
    name: 'Cancelled Partial',
    value: EResaleStatus.cancelledPartial,
  },
  {
    name: 'Aborted',
    value: EResaleStatus.aborted,
  },
  {
    name: 'Received',
    value: EResaleStatus.received,
  },
];

export const RESALE_INVOICE_DELIVERY_STATUS_FILTER_OPTIONS = [
  {
    name: 'Processed',
    value: EMessageOutboxStatus.sent,
  },
  {
    name: 'Skipped',
    value: [EMessageOutboxStatus.skipped, EMessageOutboxStatus.submitted],
  },
  {
    name: 'Failed',
    value: EMessageOutboxStatus.failed,
  },
];

export const RESALE_NO_OF_ROOM_OPTIONS: ResaleNoOfRoomTypeRecord = {
  [EResaleNoOfRoom['1B']]: {
    id: EResaleNoOfRoom['1B'],
    value: EResaleNoOfRoom['1B'],
    viewValue: '1B',
  },
  [EResaleNoOfRoom['1B+']]: {
    id: EResaleNoOfRoom['1B+'],
    value: EResaleNoOfRoom['1B+'],
    viewValue: '1B+',
  },
  [EResaleNoOfRoom['2B']]: {
    id: EResaleNoOfRoom['2B'],
    value: EResaleNoOfRoom['2B'],
    viewValue: '2B',
  },
  [EResaleNoOfRoom['2B+']]: {
    id: EResaleNoOfRoom['2B+'],
    value: EResaleNoOfRoom['2B+'],
    viewValue: '2B+',
  },
  [EResaleNoOfRoom['3B']]: {
    id: EResaleNoOfRoom['3B'],
    value: EResaleNoOfRoom['3B'],
    viewValue: '3B',
  },
  [EResaleNoOfRoom['3B+']]: {
    id: EResaleNoOfRoom['3B+'],
    value: EResaleNoOfRoom['3B+'],
    viewValue: '3B+',
  },
  [EResaleNoOfRoom['4B']]: {
    id: EResaleNoOfRoom['4B'],
    value: EResaleNoOfRoom['4B'],
    viewValue: '4B',
  },
  [EResaleNoOfRoom['4B+']]: {
    id: EResaleNoOfRoom['4B+'],
    value: EResaleNoOfRoom['4B+'],
    viewValue: '4B+',
  },
  [EResaleNoOfRoom['5B']]: {
    id: EResaleNoOfRoom['5B'],
    value: EResaleNoOfRoom['5B'],
    viewValue: '5B',
  },
  [EResaleNoOfRoom['>5B']]: {
    id: EResaleNoOfRoom['>5B'],
    value: EResaleNoOfRoom['>5B'],
    viewValue: '>5B',
  },
  [EResaleNoOfRoom['Studio']]: {
    id: EResaleNoOfRoom['Studio'],
    value: EResaleNoOfRoom['Studio'],
    viewValue: 'Studio',
  },
};

export const RESALE_NO_OF_ROOM_OPTIONS_HDB: ResaleNoOfRoomTypeHdbRecord = {
  [EResaleNoOfRoomHdb['1 Room']]: {
    id: EResaleNoOfRoomHdb['1 Room'],
    value: EResaleNoOfRoomHdb['1 Room'],
    viewValue: '1 Room',
  },
  [EResaleNoOfRoomHdb['2 Room']]: {
    id: EResaleNoOfRoomHdb['2 Room'],
    value: EResaleNoOfRoomHdb['2 Room'],
    viewValue: '2 Room',
  },
  [EResaleNoOfRoomHdb['3 Room']]: {
    id: EResaleNoOfRoomHdb['3 Room'],
    value: EResaleNoOfRoomHdb['3 Room'],
    viewValue: '3 Room',
  },
  [EResaleNoOfRoomHdb['4 Room']]: {
    id: EResaleNoOfRoomHdb['4 Room'],
    value: EResaleNoOfRoomHdb['4 Room'],
    viewValue: '4 Room',
  },
  [EResaleNoOfRoomHdb['5 Room']]: {
    id: EResaleNoOfRoomHdb['5 Room'],
    value: EResaleNoOfRoomHdb['5 Room'],
    viewValue: '5 Room',
  },
  [EResaleNoOfRoomHdb['Multi-Gen']]: {
    id: EResaleNoOfRoomHdb['Multi-Gen'],
    value: EResaleNoOfRoomHdb['Multi-Gen'],
    viewValue: 'Multi-Gen',
  },
  [EResaleNoOfRoomHdb['Jumbo']]: {
    id: EResaleNoOfRoomHdb['Jumbo'],
    value: EResaleNoOfRoomHdb['Jumbo'],
    viewValue: 'Jumbo',
  },
  [EResaleNoOfRoomHdb['Executive']]: {
    id: EResaleNoOfRoomHdb['Executive'],
    value: EResaleNoOfRoomHdb['Executive'],
    viewValue: 'Executive',
  },
  [EResaleNoOfRoomHdb['Maisonette']]: {
    id: EResaleNoOfRoomHdb['Maisonette'],
    value: EResaleNoOfRoomHdb['Maisonette'],
    viewValue: 'Maisonette',
  },
  [EResaleNoOfRoomHdb['Terrace House']]: {
    id: EResaleNoOfRoomHdb['Terrace House'],
    value: EResaleNoOfRoomHdb['Terrace House'],
    viewValue: 'Terrace House',
  },
  [EResaleNoOfRoomHdb['Others']]: {
    id: EResaleNoOfRoomHdb['Others'],
    value: EResaleNoOfRoomHdb['Others'],
    viewValue: 'Others',
  },
};
