export enum EProjectType {
  INTERNATIONAL = 'international',
  LOCAL = 'local',
}

export enum EProjectPropertyType {
  condominiumApartment = 'condominiumApartment',
  excutiveCondominium = 'excutiveCondominium',
  landedTerraceHouse = 'landedTerraceHouse',
  landedSemiDetachedHouse = 'landedSemiDetachedHouse',
  landedDetachedHouse = 'landedDetachedHouse',
  landedStrataHouse = 'landedStrataHouse',
  commercialOffice = 'commercialOffice',
  commercialShophouse = 'commercialShophouse',
  commercialRetail = 'commercialRetail',
  industrialWarehouse = 'industrialWarehouse',
  industrialBusinessHub = 'industrialBusinessHub',
  industrialSingleUserFactory = 'industrialSingleUserStory',
  industrialMultipleUserFactory = 'industrialMultipleUserFactory',
  retail = 'retail',
}
