import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import {
  BLankFormBdsSetUpDto,
  BlankFormCreateDto,
  BlankFormIcSetUpDto,
  BLankFormOtherFeeSetUpDto,
  BLankFormPdSetUpDto,
  BLankFormReferralSetUpDto,
  BlankFormUpdateDto,
} from '@shared/data-access/dto';
import { EProjectType } from '@shared/data-access/enums';
import {
  BlankFormBdModel,
  BlankFormIcModel,
  BlankFormModel,
  BlankFormOtherFeeModel,
  BlankFormPdModel,
  BlankFormReferralModel,
  BlankFormStatusHistoryModel,
} from '@shared/data-access/models';
import { catchError, forkJoin, map, mapTo, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlankFormApiService {
  static BLANK_FORM = 'transaction/portal/blank-form-transactions';
  static BLANK_FORM_FOR_BATCH = 'transaction/blank-form-transactions/for-batch';
  static BLANK_FORM_BONUSES = 'transaction/portal/blank-form-bonuses';
  static BLANK_FORM_BONUSES_SET_UP = 'transaction/portal/blank-form-bonuses/setup';
  static BLANK_FORM_OVERRIDINGS = 'transaction/portal/blank-form-overridings';
  static BLANK_FORM_OVERRIDINGS_SET_UP = 'transaction/portal/blank-form-overridings/setup';
  static BLANK_FORM_POOLS = 'transaction/portal/blank-form-pools';
  static BLANK_FORM_POOLS_SET_UP = 'transaction/portal/blank-form-pools/setup';
  static BLANK_FORM_REFERRALS = 'transaction/portal/blank-form-referrals';
  static BLANK_FORM_REFERRALS_SET_UP = 'transaction/portal/blank-form-referrals/setup';
  static BLANK_FORM_SUBMIT = 'transaction/portal/blank-form-transactions/:id/submit';
  static BLANK_FORM_REWORK = 'transaction/portal/blank-form-transactions/:id/rework';
  static BLANK_FORM_CONFIRM = 'transaction/portal/blank-form-transactions/:id/action';
  static BLANK_FORM_SUBMIT_MULTIPLE = 'transaction/portal/blank-form-transactions/submit';
  static BLANK_FORM_REWORK_MULTIPLE = 'transaction/portal/blank-form-transactions/rework';
  static BLANK_FORM_CONFIRM_MULTIPLE = 'transaction/portal/blank-form-transactions/action';
  static BLANK_FORM_STATUS_HISTORIES = 'transaction/blank-form-status-histories/portal';
  static BLANK_FORM_PDS = 'transaction/portal/blank-form-pds';
  static BLANK_FORM_PDS_SET_UP = 'transaction/portal/blank-form-pds/setup';
  static BLANK_FORM_OTHER_FEE = 'transaction/portal/blank-form-others';
  static BLANK_FORM_OTHER_FEE_SET_UP = 'transaction/portal/blank-form-others/setup';
  static BLANK_FORM_BDS = 'transaction/portal/blank-form-bds';
  static BLANK_FORM_BDS_SET_UP = 'transaction/portal/blank-form-bds/setup';

  constructor(private _apiService: ApiService) {}

  get(id: number): Observable<BlankFormModel> {
    return this._apiService.get(BlankFormApiService.BLANK_FORM + '/' + id).pipe(map(res => BlankFormModel.fromJson(res)));
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<BlankFormModel>> {
    return this._apiService
      .get(BlankFormApiService.BLANK_FORM, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(BlankFormModel, data)));
  }

  searchForBatch(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_FOR_BATCH, query, option).pipe(map(data => new PaginationAdapter(BlankFormModel, data)));
  }

  create(data: BlankFormCreateDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM, data).pipe(map(res => BlankFormModel.fromJson(res)));
  }

  update(id: number, data: BlankFormUpdateDto) {
    return this._apiService.patch(BlankFormApiService.BLANK_FORM + '/' + id, data);
  }

  searchBonuses(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_BONUSES, query, option).pipe(map(data => new PaginationAdapter(BlankFormIcModel, data)));
  }

  setUpBonuses(data: BlankFormIcSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_BONUSES_SET_UP, data);
  }

  searchOverridings(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_OVERRIDINGS, query, option).pipe(map(data => new PaginationAdapter(BlankFormIcModel, data)));
  }

  setUpOverridings(data: BlankFormIcSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_OVERRIDINGS_SET_UP, data);
  }

  searchPools(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_POOLS, query, option).pipe(map(data => new PaginationAdapter(BlankFormIcModel, data)));
  }

  setUpPools(data: BlankFormIcSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_POOLS_SET_UP, data);
  }

  searchReferrals(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_REFERRALS, query, option).pipe(map(data => new PaginationAdapter(BlankFormReferralModel, data)));
  }

  setUpReferrals(data: BLankFormReferralSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_REFERRALS_SET_UP, data);
  }

  submit(id: number, reason?: string) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_SUBMIT, { id, data: { reason } });
  }

  rework(id: number, reason = '') {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_REWORK, { id, data: { reason } });
  }

  confirm(id: number, reason = '') {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_CONFIRM, { id, data: { reason } });
  }

  delete(id: number) {
    return this._apiService.delete(BlankFormApiService.BLANK_FORM + '/' + id);
  }

  submitMultiple(blankFormIds: number[]) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_SUBMIT_MULTIPLE, {
      blankForms: blankFormIds.map(id => ({
        id,
        data: {},
      })),
    });
  }

  reworkMultiple(blankForms: { id: number; data: { reason: string } }[]) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_REWORK_MULTIPLE, { blankForms });
  }

  confirmMultiple(blankForms: { id: number; data: { reason?: string } }[]) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_CONFIRM_MULTIPLE, { blankForms });
  }

  searchStatusHistories(query = {}, option?: IApiOption) {
    return this._apiService
      .get(BlankFormApiService.BLANK_FORM_STATUS_HISTORIES, query, option)
      .pipe(map(data => new PaginationAdapter(BlankFormStatusHistoryModel, data)));
  }

  searchPds(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_PDS, query, option).pipe(map(data => new PaginationAdapter(BlankFormPdModel, data)));
  }

  setUpPds(data: BLankFormPdSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_PDS_SET_UP, data);
  }

  searchOtherFees(query: {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_OTHER_FEE, query, option).pipe(
      map(data => new PaginationAdapter(BlankFormOtherFeeModel, data)),
      catchError(() =>
        of(
          new PaginationAdapter(BlankFormOtherFeeModel, {
            items: [],
            meta: {},
          })
        )
      )
    );
  }

  setUpOtherFees(data: BLankFormOtherFeeSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_OTHER_FEE_SET_UP, data);
  }

  searchBds(query = {}, option?: IApiOption) {
    return this._apiService.get(BlankFormApiService.BLANK_FORM_BDS, query, option).pipe(
      map(data => new PaginationAdapter(BlankFormBdModel, data)),
      catchError(() =>
        of(
          new PaginationAdapter(BlankFormBdModel, {
            items: [],
            meta: {},
          })
        )
      )
    );
  }

  setUpBds(data: BLankFormBdsSetUpDto) {
    return this._apiService.post(BlankFormApiService.BLANK_FORM_BDS_SET_UP, data);
  }

  createFullData(data: BlankFormModel) {
    const dataJson = BlankFormModel.toJson(data);
    const payload = BlankFormCreateDto.fromJson(dataJson);
    return this.create(payload).pipe(
      switchMap(({ id }) => {
        const { hiProfitAmount, bds, bonuses, overridings, pools, pds, referrals, otherFees } = data;
        const isInternational = data.project?.entity === EProjectType.INTERNATIONAL;
        const bdsSetUpPayload = BLankFormBdsSetUpDto.fromJson({
          blankFormId: id,
          isOverride: true,
          hiProfitAmount,
          data: bds,
        });
        const poolSetUpPayload = BlankFormIcSetUpDto.fromJson({
          blankFormId: id,
          isOverride: true,
          data: pools,
        });
        const pdSetUpPayload = BLankFormPdSetUpDto.fromJson({
          blankFormId: id,
          isOverride: true,
          data: pds,
        });
        const referralSetUpPayload = BLankFormReferralSetUpDto.fromJson({
          blankFormId: id,
          isOverride: true,
          data: referrals,
        });
        const otherFeeSetUpPayload = BLankFormOtherFeeSetUpDto.fromJson({
          blankFormId: id,
          isOverride: true,
          data: otherFees,
        });
        return forkJoin([
          bds.length && isInternational ? this.setUpBds(bdsSetUpPayload) : of(null),
          pools.length ? this.setUpPools(poolSetUpPayload) : of(true),
          pds.length ? this.setUpPds(pdSetUpPayload) : of(true),
          referrals.length ? this.setUpReferrals(referralSetUpPayload) : of(true),
          otherFees.length ? this.setUpOtherFees(otherFeeSetUpPayload) : of(true),
        ]).pipe(mapTo(id));
      })
    );
  }

  editFullData(data: BlankFormModel) {
    const dataJson = BlankFormModel.toJson(data);
    const { id } = data;
    const payload = BlankFormUpdateDto.fromJson({
      group: 'full',
      data: dataJson,
    });
    const { hiProfitAmount, bds, bonuses, overridings, pools, pds, referrals, otherFees } = data;
    const isInternational = data.project?.entity === EProjectType.INTERNATIONAL;
    const bdsSetUpPayload = BLankFormBdsSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: bds,
      hiProfitAmount,
    });
    const poolSetUpPayload = BlankFormIcSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: pools,
    });
    const pdSetUpPayload = BLankFormPdSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: pds,
    });
    const referralSetUpPayload = BLankFormReferralSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: referrals,
    });
    const otherFeeSetUpPayload = BLankFormOtherFeeSetUpDto.fromJson({
      blankFormId: id,
      isOverride: true,
      data: otherFees,
    });
    return forkJoin([
      this.update(id, payload),
      isInternational ? this.setUpBds(bdsSetUpPayload) : of(true),
      this.setUpPools(poolSetUpPayload),
      this.setUpPds(pdSetUpPayload),
      this.setUpReferrals(referralSetUpPayload),
      this.setUpOtherFees(otherFeeSetUpPayload),
    ]).pipe(mapTo(id));
  }
}
