import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { DeveloperCreateDto, DeveloperUpdateDto } from '@shared/data-access/dto';
import { IDeveloper } from '@shared/data-access/interfaces';
import { DeveloperModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeveloperApiService {
  static DEVELOPER = 'transaction/developers';
  static NEW_DEVELOPER = 'transaction/master-data/referral-externals?types=customerOnly&types=customerAndSupplier';

  constructor(private apiService: ApiService) {}

  create(data: DeveloperCreateDto): Observable<DeveloperModel> {
    return this.apiService.post(`${DeveloperApiService.DEVELOPER}`, data).pipe(map((res: IDeveloper) => DeveloperModel.fromJson(res) as DeveloperModel));
  }

  get(itemId: number): Observable<DeveloperModel> {
    return this.apiService.get(DeveloperApiService.DEVELOPER + '/' + itemId).pipe(map(res => DeveloperModel.fromJson(res) as DeveloperModel));
  }

  update(id: number, data: DeveloperUpdateDto): Observable<any> {
    return this.apiService.patch(DeveloperApiService.DEVELOPER + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<DeveloperModel>> {
    return this.apiService.get(DeveloperApiService.DEVELOPER, query, option).pipe(map(data => new PaginationAdapter(DeveloperModel, data)));
  }

  searchDev(query = {}): Observable<PaginationAdapter<DeveloperModel>> {
    return this.apiService.get(DeveloperApiService.NEW_DEVELOPER, query).pipe(map(data => new PaginationAdapter(DeveloperModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(DeveloperApiService.DEVELOPER, { id }, option);
  }

  updateStatus(id: number, status: string): Observable<any> {
    const payload = DeveloperUpdateDto.fromJson({ group: 'isActive', data: { isActive: status } });
    return this.update(id, payload);
  }
}
