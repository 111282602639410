import { IPersonalParticular } from './personal-particular.interface';
import { IAppointmentType } from './appointment-type.interface';
import { ICompany } from './company.interface';
import { ICountry } from './country.interface';
import { IDeveloper } from './developer.interface';
import { IDistrict } from './district.interface';
import { IKeyAppointment } from './key-appointment.interface';
import { IManagementCommission } from './management-commission.interface';
import { EPaymentDueDate } from '../enums';

export interface IProject {
  address: string;
  code: string;
  company: ICompany;
  companyId: number;
  // hard code id = 1 (Asia Company Id)
  localCompanyId: number;
  country?: ICountry;
  countryId?: number;
  developer: IDeveloper;
  developerId: number;
  district: IDistrict;
  districtId: number;
  entity: string;
  gstCategory: string;
  gstPercentage: number;
  id: number;
  managementComm: IManagementCommission;
  managementCommId: number;
  name: string;
  paymentByDeveloper: string;
  paymentDueDate: EPaymentDueDate;
  saleType?: string;
  status: string;
  tenure: string;
  tenureYear: number;
  totalUnit: number;
  type: string;
  keyAppointments?: IKeyAppointment[];
  appointmentTypeId?: number;
  appointmentType?: IAppointmentType;
  salespersonId?: number;
  salesperson?: IPersonalParticular;
  startDate?: string;
  endDate?: string;
  remarks?: string;
  appTypes: { appType: IAppointmentType }[];
}
