import { BaseModel, Default, EndOfDate, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ECommMatrixVerifyStatus } from '../enums';
import { AbstractListFilters } from '@shared/core';

export class DistributionMatrixDto extends BaseModel {
  @Expose()
  @Type(() => Boolean)
  agent!: boolean;

  @Expose()
  @Type(() => Boolean)
  hr!: boolean;

  @Expose()
  @Type(() => Number)
  value!: number;
}

export class BonusDistributionMatrixDto extends DistributionMatrixDto {
  @Expose()
  @Type(() => Number)
  disBonusId!: number;

  @Expose()
  @Type(() => String)
  disBonusCode!: string;
}

export class OverridingDistributionMatrixDto extends DistributionMatrixDto {
  @Expose()
  @Type(() => Number)
  disOverridingId!: number;

  @Expose()
  @Type(() => String)
  disOverridingCode!: string;
}

export class ProjectCommissionMatrixCreateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  @Type(() => Number)
  projectId!: number;

  @Expose()
  projectStage!: string;

  @Expose()
  unitNo!: string;

  // @Expose()
  // @Type(() => Number)
  // unitId!: number;

  @Expose()
  @Type(() => Number)
  commissionTypeId!: number;

  @Expose()
  @Type(() => Number)
  agentTier!: number;

  @Expose()
  @Type(() => Number)
  companyTier!: number;

  @Expose()
  @Type(() => Number)
  developerTier!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;

  // @Expose()
  // startDateMarketing!: string;

  // @Expose()
  // endDateMarketing!: string;

  @Expose()
  remarks!: string;

  @Expose()
  description!: string;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isSpecial!: boolean;

  @Expose()
  @Type(() => DistributionMatrixDto)
  closingAgent!: DistributionMatrixDto;

  @Expose()
  @Type(() => DistributionMatrixDto)
  teamLeader!: DistributionMatrixDto;

  @Expose()
  @Type(() => DistributionMatrixDto)
  otherFund!: DistributionMatrixDto;

  @Expose()
  @Type(() => BonusDistributionMatrixDto)
  icBonus!: BonusDistributionMatrixDto;

  @Expose()
  @Type(() => OverridingDistributionMatrixDto)
  icOverriding!: OverridingDistributionMatrixDto;
}

export class ProjectCommissionMatrixDataUpdateDto extends BaseModel {
  @Expose({ groups: ['full', 'general'] })
  name!: string;

  @Expose({ groups: ['full', 'general'] })
  code!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  projectId!: number;

  @Expose({ groups: ['full', 'general'] })
  projectStage!: string;

  @Expose({ groups: ['full', 'general'] })
  unitNo!: string;

  // @Expose({ groups: ['full', 'general'] })
  // @Type(() => Number)
  // unitId!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  commissionTypeId!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  agentTier!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  companyTier!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  developerTier!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;

  // @Expose({ groups: ['full', 'general'] })
  // startDateMarketing!: string;

  // @Expose({ groups: ['full', 'general'] })
  // endDateMarketing!: string;

  @Expose({ groups: ['full', 'general', 'remark'] })
  remarks!: string;

  @Expose({ groups: ['full', 'general'] })
  description!: string;

  @Expose({ groups: ['full', 'general'] })
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose({ groups: ['full', 'general'] })
  @Default(false)
  @Type(() => Boolean)
  isSpecial!: boolean;

  @Expose({ groups: ['full', 'agent'] })
  @Type(() => DistributionMatrixDto)
  closingAgent!: DistributionMatrixDto;

  @Expose({ groups: ['full', 'agent'] })
  @Type(() => DistributionMatrixDto)
  teamLeader!: DistributionMatrixDto;

  @Expose({ groups: ['full', 'agent'] })
  @Type(() => DistributionMatrixDto)
  otherFund!: DistributionMatrixDto;

  @Expose({ groups: ['full', 'agent'] })
  @Type(() => BonusDistributionMatrixDto)
  icBonus!: BonusDistributionMatrixDto;

  @Expose({ groups: ['full', 'company'] })
  @Type(() => OverridingDistributionMatrixDto)
  icOverriding!: OverridingDistributionMatrixDto;
}

export class ProjectCommissionMatrixUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'general' | 'agent' | 'company' | 'isActive' | 'full' | 'remark';

  @Expose()
  @Transform(params => {
    return ProjectCommissionMatrixDataUpdateDto.fromJson(params.value, { groups: [params.obj.group] });
  })
  data!: ProjectCommissionMatrixDataUpdateDto;
}

export class ProjectCommissionStatusUpdateDataDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  action!: Exclude<`${ECommMatrixVerifyStatus}`, ECommMatrixVerifyStatus.draft>;

  @Expose()
  @Default({})
  data?: any;
}

export class ProjectCommissionStatusUpdateDto extends BaseModel {
  @Expose()
  @Type(() => ProjectCommissionStatusUpdateDataDto)
  data!: ProjectCommissionStatusUpdateDataDto[];
}
export class ProjectCommissionMatrixFilters extends AbstractListFilters {
  @Expose()
  companyIds!: string;

  @Expose()
  types!: string;

  @Expose()
  searchType!: string;

  @Expose()
  name!: string;

  @Expose()
  projectName!: string;

  @Expose()
  remarks!: string;

  @Expose()
  agentTier!: number;

  @Expose()
  companyTier!: number;

  @Expose()
  developerTier!: number;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  startDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  startDateEnd!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  endDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  endDateEnd!: string;

  @Expose()
  icBonus!: string;

  @Expose()
  icOverriding!: string;

  @Expose()
  status!: string;

  @Expose()
  projectStatus!: string;
}

export class ProjectCommMatrixVerificationFilters extends AbstractListFilters {
  @Expose()
  companyIds!: string;

  @Expose()
  searchType!: string;

  @Expose()
  name!: string;

  @Expose()
  projectName!: string;

  @Expose()
  remarks!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  startDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  startDateEnd!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  endDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  endDateEnd!: string;

  @Expose()
  status!: string;

  @Expose()
  types!: string;

  @Expose()
  projectStatus!: string;
}
