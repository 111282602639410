import { ICommissionScheme } from './commission-scheme.interface';
import { ISplitMatrix, ISplitMatrixDto } from './split-matrix.interface';

export interface ICommissionSalespersonScheme extends ICommissionScheme {
  maxCommission: number;
  minCommission: number;
  splitMatrices: ISplitMatrix[];
}

export interface ICommSalespersonSchemePosition {
  positionCompare: 'before' | 'after';
  schemeIdCompare: number;
}

export interface ICommSalespersonSchemeCreateDto {
  schemeTypeId: number;
  designation: string;
  code: string;
  autoApprove: boolean;
  isActive: boolean;
  sequence: number;
  minCommission: number;
  maxCommission: number;
  splitMatrices: ISplitMatrixDto[];
}

export interface ICommSalespersonSchemeUpdateDto {
  designation: string;
  code: string;
  autoApprove: boolean;
  isActive: boolean;
  sequence: number;
  minCommission: number;
  maxCommission: number;
  splitMatrices: ISplitMatrixDto[];
  position: ICommSalespersonSchemePosition;
}
