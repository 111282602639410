import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IChequeCompact } from '../interfaces';

export class ChequeCompactModel extends BaseModel implements IChequeCompact {
  @Expose()
  id!: number;

  @Expose()
  chequeDate!: Date;

  @Expose()
  chequeNumber!: string;

  @Expose()
  amount!: number;
}
