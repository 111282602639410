export enum EAgentProjectTransactionSection {
  cobroke = 'Co-Broke',
  closingAgent = 'Closing Agent',
  leader = 'Leader',
  bds = "Bds",
  pools = 'Pools',
  internal = 'Internal',
  HIBD = 'HIBD',
  businessDirector = "BusinessDirector",
  bonus = 'Bonuses',
  teamLeader = 'TeamLeader',
  overriding = 'Overriding',
  pDManagementEntity = "PDManagementEntity",
  PDReferralEntity = "PDReferralEntity",
  PDOverrideEntity = "PDOverrideEntity",
  internalRefferal = "InternalRefferal"
}
