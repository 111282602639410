import { IAppointmentTypeConfig } from './appointment-type-config.interface';

export interface IAppointmentType {
  id: number;
  code: string;
  name: string;
  sorting: string;
  remarks: string;
  configs: IAppointmentTypeConfig[];
  status: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
}
