import { ClassProvider, ModuleWithProviders, NgModule, ValueProvider } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { Subject } from 'rxjs';
import { PageTitleStrategy } from './page-title.strategy';
import { PAGE_TITLE_CONFIGS, PAGE_TITLE_ASYNC, PageTitleConfigs } from './page-title.token';

@NgModule()
export class PageTitleModule {
  // constructor() {}
  static forRoot(configs?: PageTitleConfigs): ModuleWithProviders<PageTitleModule> {
    const valueProviders: ValueProvider[] = [
      {
        provide: PAGE_TITLE_CONFIGS,
        useValue: configs,
      },
      {
        provide: PAGE_TITLE_ASYNC,
        useValue: new Subject<string>(),
      },
    ];
    const classProviders: ClassProvider[] = [
      {
        provide: TitleStrategy,
        useClass: PageTitleStrategy,
      },
    ];
    return {
      ngModule: PageTitleModule,
      providers: [...valueProviders, ...classProviders],
    };
  }
}
