import {IGrossCommissionEarned, IResaleRelatedAgent, IResaleTransaction} from '.';
import { EResaleAdjustmentStatus } from '../enums';

export interface IResaleAdjustment {
  id: number;
  code: string;
  invoiceAt?: string;
  invoiceId?: number;
  invoice?: any;
  status: EResaleAdjustmentStatus;
  remarks?: string;
  resale?: IResaleTransaction;
  resaleId?: number;
  grossComm?: IGrossCommissionEarned;
  relatedAgent: IResaleRelatedAgent;
  rejectReason?: string;
}
