import { BaseModel, PhoneNumber } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IBroker } from '../interfaces';
import { AddressModel } from './address.model';
import { CompanyModel } from './company.model';

export class BrokerModel extends BaseModel implements IBroker {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  address!: string;

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  companyId!: string;

  @Expose()
  @Type(() => CompanyModel)
  company!: CompanyModel;

  @Expose()
  gstRegistered!: boolean;

  @Expose()
  @Type(() => AddressModel)
  addresses?: AddressModel[];

  @Expose()
  salePersonId?: number;

  @Expose()
  get contactNumber() {
    return this.phone && this.phone.phoneNumber ? `+${this.phone.countryCode} ${this.phone.phoneNumber}` : '';
  }
}
