import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { EResaleStatus } from '../enums';
import { IResaleStatusHistory } from '../interfaces';

export class ResaleStatusHistoryModel extends BaseModel implements IResaleStatusHistory {
  @Expose()
  id!: number;

  @Expose()
  resaleId!: number;

  @Expose()
  action!: EResaleStatus;

  @Expose()
  data!: { reason: string };

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string;

  @Expose()
  deletedBy!: string;

  @Expose()
  state!: string;
}
