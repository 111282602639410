import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IKeyAppointmentList } from '../interfaces';

export class KeyAppointmentListModel extends BaseModel implements IKeyAppointmentList {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;
}
