import { IUnit } from './unit.interface';
import { EProjectType } from '../enums';
import { CommisisonMatrixVerifyStatus } from '../types';
import { ICategory } from './category.interface';
export interface IAbsorbBy extends Record<string, unknown> {
  agent?: boolean;
  hr?: boolean;
  value?: number;
}
export interface ICommissionMatrix {
  type: `${EProjectType}`;
  agentTier: number;
  code: string;
  commissionType: ICategory;
  commissionTypeId: number;
  companyTier: number;
  description: string;
  developerTier: number;
  id: number;
  isSpecial: boolean;
  isLocked: boolean;
  name: string;
  rejectReason?: string | null;
  remarks: string | null;
  status: CommisisonMatrixVerifyStatus;
  // unit: IUnit;
  unitNo: string;
  // unitId: number;
  closingAgent: IAbsorbBy;
  teamLeader: IAbsorbBy;
  otherFund: IAbsorbBy;
  icBonus: {
    agent: boolean;
    hr: boolean;
    disBonusId: number;
    disBonusCode: string;
    value: number;
  };
  icOverriding: {
    agent: boolean;
    hr: boolean;
    disOverridingId: number;
    value: number;
    disOverridingCode: string;
  };
}
