import { BaseModel, Default, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EBatchType } from '@shared/data-access/enums';
import { AbstractListFilters } from '@shared/core';
export class BatchingApprovalTaskSubmittedDataDto extends BaseModel {
  @Expose()
  runAt!: string;

  @Expose()
  data!: {
    batchId: number;
    invoiceAt: string;
  }[];
}

export class BatchingApprovalBlankFormDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  blankFormId!: number;

  @Expose()
  remarks!: string;
}

export class BatchingApprovalTransactionDto extends BaseModel {
  // @Expose()
  // @Type(()=>Number)
  // id!:number;

  @Expose()
  @Type(() => Number)
  transactionId!: number;

  @Expose()
  remarks!: string;
}

export class BatchingApprovalCreateDto extends BaseModel {
  @Expose()
  @Default(EBatchType.transaction)
  type!: EBatchType;

  @Expose()
  @Type(() => Number)
  projectId!: number;

  // @Expose()
  // code!: string;

  @Expose()
  name!: string;

  @Expose()
  remarks!: string;

  @Expose()
  comments?: string;

  @Expose()
  @Type(() => BatchingApprovalTransactionDto)
  details!: BatchingApprovalTransactionDto[];

  @Expose()
  @Type(() => BatchingApprovalBlankFormDto)
  detailBlankForms!: BatchingApprovalBlankFormDto[];

  @Expose()
  @Default('pending')
  status!: string;
}

export class BatchingApprovalDataUpdateDto extends BaseModel {
  @Expose({ groups: ['full', 'general'] })
  @Default(EBatchType.transaction)
  type!: EBatchType;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  projectId!: number;

  // @Expose({groups:['full','general']})
  // code!: string;

  @Expose({ groups: ['full', 'general'] })
  name!: string;

  @Expose({ groups: ['full', 'general', 'remark'] })
  remarks!: string;

  @Expose({ groups: ['full', 'general'] })
  comments?: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => BatchingApprovalTransactionDto)
  details!: BatchingApprovalTransactionDto[];

  @Expose({ groups: ['full', 'general'] })
  @Type(() => BatchingApprovalBlankFormDto)
  detailBlankForms!: BatchingApprovalBlankFormDto[];

  @Expose({ groups: ['full', 'general'] })
  @Default('pending')
  status!: string;
}

export class BatchingApprovalUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'general' | 'status' | 'full' | 'remark';

  @Expose()
  @Transform(params => {
    return BatchingApprovalDataUpdateDto.fromJson(params.value, { groups: [params.obj.group] });
  })
  data!: BatchingApprovalDataUpdateDto;
}
export class BatchingApprovalFilters extends AbstractListFilters {
  @Expose()
  status!: string;

  @Expose()
  types!: string;

  @Expose()
  searchType!: string;

  @Expose()
  code!: string;

  @Expose()
  projectName!: string;

  @Expose()
  name!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  createdAtStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  createdAtEnd!: string;

  @Expose()
  remarks!: string;

  @Expose()
  invoiceCode!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  invoiceDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  invoiceDateEnd!: string;

  @Expose()
  subTotalAmount!: string;
  @Expose()
  taxAmount!: string;
  @Expose()
  totalAmount!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  runAtStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  runAtEnd!: string;
}
