export interface IResaleDocument {
  id: number;
  resaleId: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  url: string;
  size: number;
  type: string;
  publicLink: string;

  path: string;
  uploadedAt: string;
}

export interface IResaleDocumentHip {
  folder: string;
  files: IResaleDocument[];
}
