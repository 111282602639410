import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import {
  CreditNoteCalculateSummaryReqDto,
  DebitNoteCalculateSummaryReqDto,
  SupplierCreditNoteCalculateSummaryReqDto,
  SupplierInvoiceCalculateSummaryReqDto,
  TaxInvoiceCalculateSummaryReqDto,
} from '@shared/data-access/dto';
import {
  ICreditNoteCalculateSummary,
  IDebitNoteCalculateSummary,
  ISupplierCreditNoteCalculateSummary,
  ISupplierInvoiceCalculateSummary,
  ITaxInvoiceCalculateSummary,
} from '@shared/data-access/interfaces';
import { Observable, catchError, map, of, pipe } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DevUtilApiService {
  static RATE_GROUP_POINT = 'finance/rate-group/calculate';

  // Same URL
  static DEBIT_NOTE_SUMMARY_PREVIEW_POINT = 'finance/balance-documents/calculate-item';
  static TAX_INVOICE_SUMMARY_PREVIEW_POINT = 'finance/balance-documents/calculate-item';
  static CREDIT_NOTE_SUMMARY_PREVIEW_POINT = 'finance/balance-documents/calculate-item';
  static SUPPLIER_INVOICE_SUMMARY_PREVIEW_POINT = 'finance/balance-documents/calculate-item';
  static SUPPLIER_CREDIT_NOTE_SUMMARY_PREVIEW_POINT = 'finance/balance-documents/calculate-item';

  continueOnErrorPipe = pipe(catchError(error => of(null)));

  constructor(private apiService: ApiService) {}

  calculateRateGroup(data: any): Observable<any> {
    return this.apiService.post(DevUtilApiService.RATE_GROUP_POINT, data).pipe(this.continueOnErrorPipe);
  }

  calculateTaxInvoiceSummary(data: TaxInvoiceCalculateSummaryReqDto): Observable<ITaxInvoiceCalculateSummary> {
    return this.apiService.post(DevUtilApiService.TAX_INVOICE_SUMMARY_PREVIEW_POINT, data).pipe(
      this.continueOnErrorPipe,
      map(value => value as ITaxInvoiceCalculateSummary)
    );
  }

  calculateSupplierInvoiceSummary(data: SupplierInvoiceCalculateSummaryReqDto): Observable<ISupplierInvoiceCalculateSummary> {
    return this.apiService.post(DevUtilApiService.SUPPLIER_INVOICE_SUMMARY_PREVIEW_POINT, data).pipe(
      this.continueOnErrorPipe,
      map(value => value as ISupplierInvoiceCalculateSummary)
    );
  }

  calculateCreditNoteSummary(data: CreditNoteCalculateSummaryReqDto): Observable<ICreditNoteCalculateSummary> {
    return this.apiService.post(DevUtilApiService.CREDIT_NOTE_SUMMARY_PREVIEW_POINT, data).pipe(
      this.continueOnErrorPipe,
      map(value => value as ICreditNoteCalculateSummary)
    );
  }

  calculateSupplierCreditNoteSummary(data: SupplierCreditNoteCalculateSummaryReqDto): Observable<ISupplierCreditNoteCalculateSummary> {
    return this.apiService.post(DevUtilApiService.CREDIT_NOTE_SUMMARY_PREVIEW_POINT, data).pipe(
      this.continueOnErrorPipe,
      map(value => value as ISupplierCreditNoteCalculateSummary)
    );
  }

  calculateDebitNoteSummary(data: DebitNoteCalculateSummaryReqDto): Observable<IDebitNoteCalculateSummary> {
    return this.apiService.post(DevUtilApiService.DEBIT_NOTE_SUMMARY_PREVIEW_POINT, data).pipe(
      this.continueOnErrorPipe,
      map(value => value as IDebitNoteCalculateSummary)
    );
  }
}
