import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
export class ExceljsCardQueryParameterDto extends BaseModel {
  @Expose()
  slug!: string;

  @Expose()
  @Transform(({ obj, value }) => {
    return typeof value !== 'undefined' ? value : obj['default'];
  })
  value!: unknown;
}

export class ExceljsCardQueryDto extends BaseModel {
  @Expose()
  @Type(() => ExceljsCardQueryParameterDto)
  parameters!: ExceljsCardQueryParameterDto[];
}
