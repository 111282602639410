import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EAgentBlankFormSession, EManagementCommissionPdRole } from '../enums';
import { IAppointmentType, IBlankFormPd } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';
import { PersonalParticularModel } from './personal-particular.model';
import { SplitPartModel } from './split-part.model';

export class BlankFormPdModel extends BaseModel implements IBlankFormPd {
  @Expose()
  id!: number;

  @Expose()
  additional!: number;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  blankFormId!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => SplitPartModel)
  tiers!: SplitPartModel[];

  @Expose()
  updatedAt!: string;

  @Expose()
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  appTypeCode!: string;

  @Expose()
  agentType!: EAgentBlankFormSession;

  @Expose()
  @Transform(({ value }) => AppointmentTypeModel.fromJson(value))
  appointmentType!: IAppointmentType;

  @Expose()
  data!: any;

  @Expose()
  name!: string;

  @Expose()
  pdRole!: EManagementCommissionPdRole;

  @Expose()
  attrs!: any;

  @Expose()
  receivedValue!: number;

  @Expose()
  companyAmount!: number;

  @Expose()
  grouping!: string;
}
