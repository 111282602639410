import { BaseModel } from '@red/data-access';
import {  Expose, Type } from 'class-transformer';
import {  IBonusChart } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';


export class BonusChartModel extends BaseModel implements IBonusChart {
  @Expose()
  appointmentTypeId!: number;

  @Expose()
  @Type(()=> AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;

  @Expose()
  l1Percent!: number;
}
