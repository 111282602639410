import { Data } from '@angular/router';
export class PermissionRouteData {
  slug!: string;
  constructor(data: Data) {
    this.slug = data['slug'];
    if (!this.slug) {
      throw new Error('slug is required');
    }
  }
}
