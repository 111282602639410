import { defaults } from 'lodash-es';
import { IGrossCommissionEarnedConfigModel } from './gross-commission-earned-config.model';

import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ICommissionLeadershipScheme } from '../interfaces';
import { CommissionSalespersonSchemeModel } from './commission-salesperson-scheme.model';
import { SchemeTypeModel } from './scheme-type.model';
import { UpdatedByModel } from './updated-by.model';

export class CommissionLeadershipSchemeModel extends BaseModel implements ICommissionLeadershipScheme {
  @Expose()
  id!: number;

  @Expose()
  schemeTypeId!: number;

  @Expose()
  @Type(() => SchemeTypeModel)
  schemeType!: SchemeTypeModel;

  @Expose()
  designation!: string;

  @Expose()
  code!: string;

  @Expose()
  status!: string;

  @Expose()
  autoApprove!: boolean;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Default(0)
  directHeadcount!: number;

  @Expose()
  @Default(0)
  totalHeadcount!: number;

  @Expose()
  @Type(() => Number)
  sequence!: number;

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    return value?.map((item: { [x in string]: unknown }) =>
      defaults(item, { commissionScheme: CommissionSalespersonSchemeModel.fromJson(item['commissionScheme']) })
    );
  })
  requiredTeamMember!: {
    commissionSchemeId: number;
    commissionScheme: CommissionSalespersonSchemeModel;
    headcount: number;
    isActive: boolean;
  }[];

  @Expose()
  @Transform(({ value }) => {
    return value?.map((item: { [x in string]: unknown }) =>
      defaults(item, { commissionScheme: CommissionSalespersonSchemeModel.fromJson(item['commissionScheme']) })
    );
  })
  requiredCommissionScheme!: {
    commissionSchemeId: number;
    commissionScheme: CommissionSalespersonSchemeModel;
    isActive: boolean;
  }[];

  @Expose()
  @Type(() => IGrossCommissionEarnedConfigModel)
  grossCommissionEarned!: IGrossCommissionEarnedConfigModel;

  //#region timestamps
  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt?: string | null;

  @Expose()
  createdBy?: string | null;

  @Expose()
  updatedBy?: UpdatedByModel | null;

  @Expose()
  deletedBy?: UpdatedByModel | null;
  //#endregion

  //#region getter
  @Expose()
  get designationRate(): number[] {
    return [90, 10];
  }

  @Expose()
  get designationRateName(): string {
    return [this.designation, `(${this.designationRate.join('/')})`].join(' ');
  }
  //#endregion
}
