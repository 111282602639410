import { BaseModel, FormattedDateOfBirthToString } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EEcddCustomerType, EEcddIdentityType } from '../enums';
import { IEcdd, IEcddCustomer } from '../interfaces';
import { CountryModel } from './country.model';
import { NationalityModel } from './nationality.model';

export class EcddCustomerModel extends BaseModel implements IEcddCustomer {
  @Expose()
  id!: number;

  @Expose()
  ecddId!: number;

  @Expose()
  ecdd!: IEcdd;

  @Expose()
  type!: EEcddCustomerType;

  @Expose()
  name!: string;

  @Expose()
  beneficialOwner!: string;

  @Expose()
  nricfin!: string;

  @Expose()
  idTypeId!: number;

  @Expose()
  idType!: unknown;

  @Expose()
  @FormattedDateOfBirthToString()
  dateOfBirth!: string;

  @Expose()
  @Transform(params => {
    if (!params.obj.dateOfBirth) {
      return '';
    }
    const dateOfBirth = params.obj.dateOfBirth
    const [datePart] = dateOfBirth.split('T');
    const [year, month, day] = datePart.split('-');
    const formattedDateOfBirth = `${day}-${month}-${year}`;
    return formattedDateOfBirth
  })
  dateOfBirthDDMMYYYY!: string;

  @Expose()
  @Transform(params => {
    if (!params.obj.dateOfBirth) {
      return '';
    }
    const dateOfBirth = params.obj.dateOfBirth
    const [datePart] = dateOfBirth.split('T');
    const [year, month, day] = datePart.split('-');
    const formattedDateOfBirth = `${year}-${month}-${day}`;
    return formattedDateOfBirth
  })
  dateOfBirthYYYYMMDD!: string;

  @Expose()
  nationalityId!: number;

  @Expose()
  nationality!: string;

  @Expose()
  @Type(() => NationalityModel)
  nationalityObj!: NationalityModel;

  @Expose()
  occupation!: string;

  @Expose()
  countryId!: number;

  @Expose()
  country!: string;

  @Expose()
  @Type(() => CountryModel)
  countryObj!: CountryModel;

  @Expose()
  registrationNumber!: string;

  @Expose()
  identifyingType!: EEcddIdentityType | string;

  @Expose()
  mainBusiness!: string;

  @Expose()
  signature!: string;
}
