import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ProjectInternalCobrokeReferralCreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  projectId!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => String)
  formula!: string;

  @Expose()
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  @Type(() => Number)
  fee!: number;
}

export class ProjectInternalCobrokeReferralUpdateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => String)
  formula!: string;

  @Expose()
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  @Type(() => Number)
  fee!: number;
}
