import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IAppointmentType } from '../interfaces';
import { AppointmentTypeConfigModel } from './appointment-type-config.model';
import { UpdatedByModel } from './updated-by.model';

export class AppointmentTypeModel extends BaseModel implements IAppointmentType {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  sorting!: string;

  @Expose()
  @Type(() => AppointmentTypeConfigModel)
  configs!: AppointmentTypeConfigModel[];

  @Expose()
  status!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  isActive!: boolean;

  @Expose()
  remarks!: string;
}
