import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { BaseModel, PaginationAdapter } from '@red/data-access';
import { ProjectCreateDto, ProjectUpdateDto } from '@shared/data-access/dto';
import { IProject } from '@shared/data-access/interfaces';
import { ExternalCobrokeReferralModel, InternalCobrokeReferralModel, ProjectModel, ReferralExternalModel } from '@shared/data-access/models';
import { ClassConstructor, plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';

export abstract class ProjectReferrallApiService<T extends BaseModel> {
  ctor!: ClassConstructor<T>;
  abstract get endPoint(): string;

  constructor(protected apiService: ApiService) {}
  get(itemId: number): Observable<T> {
    console.log('ctor -->', plainToInstance(this.ctor, { id: 11 }));
    return this.apiService.get(this.endPoint + '/' + itemId).pipe(map(res => plainToInstance(this.ctor, res) as T));
  }
  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<T>> {
    return this.apiService.get(this.endPoint, query, option).pipe(map(data => new PaginationAdapter(this.ctor, data)));
  }
  create(data: Partial<T>): Observable<T> {
    return this.apiService.post(this.endPoint, data).pipe(map(res => plainToInstance(this.ctor, res) as T));
  }
  update(id: number, data: Partial<T>): Observable<any> {
    return this.apiService.patch(this.endPoint + '/' + id, data);
  }
  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(this.endPoint, { ids }, option);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectInternalReferralApiService extends ProjectReferrallApiService<InternalCobrokeReferralModel> {
  static ROOT_POINT = 'transaction/project-referral/internals';

  get endPoint(): string {
    return ProjectInternalReferralApiService.ROOT_POINT;
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }

  getSalespersonIdsInvited(query = {}, option?: IApiOption): Observable<number[]> {
    return this.apiService.get(this.endPoint + '/get-salesperson-invited', query, option);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectExternalReferralApiService extends ProjectReferrallApiService<ExternalCobrokeReferralModel> {
  static ROOT_POINT = 'transaction/project-referral/externals';
  get endPoint(): string {
    return ProjectExternalReferralApiService.ROOT_POINT;
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
  getContactIdsInvited(query = {}, option?: IApiOption): Observable<number[]> {
    return this.apiService.get(this.endPoint + '/get-contact-invited', query, option);
  }
}
