import { Injectable } from '@angular/core';
import { IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ContactAddressModel, ContactCustomerAndSupplierModel, ContactPersonModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';
import { ContactBaseApiService } from './contact-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ContactApiService {
  static ROOT_POINT = 'finance/contacts';

  constructor(private apiService: ContactBaseApiService) {}

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ContactCustomerAndSupplierModel>> {
    return this.apiService.search(ContactCustomerAndSupplierModel, query, option);
  }

  getContactAddresses(contactId: number, query = {}): Observable<PaginationAdapter<ContactAddressModel>> {
    return this.apiService.getContactAddresses({ contactId, ...query }).pipe(
      map(
        data =>
          new PaginationAdapter(ContactAddressModel, {
            items: data,
            meta: {},
          })
      )
    );
  }

  getAllContactAddresses(contactId: number, query = {}): Observable<ContactAddressModel[]> {
    return this.apiService.getContactAddresses({ contactId, ...query });
  }

  getContactAddressesById(contactId: number, contactAddressId: number, query = {}): Observable<ContactAddressModel> {
    return this.apiService.getContactAddressesById({ contactId, addressId: contactAddressId, ...query });
  }

  getAllContactPerson(contactId: number, query = {}): Observable<ContactPersonModel[]> {
    return this.apiService.getContactPersion({ contactId, ...query });
  }

  getContactPersionById(contactId: number, contactPersonId: number, query = {}): Observable<ContactPersonModel> {
    return this.apiService.getContactPersionById({ contactId, contactPersonId, ...query });
  }

  getById(contactId: number, query = {}, option?: IApiOption): Observable<ContactCustomerAndSupplierModel> {
    return this.apiService.getById(contactId, query, option);
  }

  // New endpoint
  commonList(query = {}, option?: IApiOption): Observable<PaginationAdapter<ContactCustomerAndSupplierModel>> {
    return this.apiService.commonList(ContactCustomerAndSupplierModel, query, option);
  }
}
