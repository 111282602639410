import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IRedocOauth2Config, RedocOAuthLogger, RedocOAuthStorage } from '@redocco/oauth2-redoc-connect';
import { OauthService } from './oauth.service';
import { createDefaultLogger, createDefaultStorage } from './provide-oauth';
import { OAUTH2_CONFIG } from './token';
import { AUTH_COOKIE_READER } from '../auth.token';
import { CookieReaderService } from '../cookie.service';
import { SSOAuthGuard } from '../guards';

@NgModule({
  imports: [CommonModule],
})
export class OauthModule {
  static forRoot(config: Partial<IRedocOauth2Config>, storage?: Storage): ModuleWithProviders<OauthModule> {
    return {
      ngModule: OauthModule,
      providers: [
        OauthService,
        { provide: RedocOAuthLogger, useFactory: createDefaultLogger },
        {
          provide: RedocOAuthStorage,
          useFactory: storage ? () => storage : createDefaultStorage,
        },
        {
          provide: OAUTH2_CONFIG,
          useValue: config,
        },
        SSOAuthGuard,
      ],
    };
  }
}
