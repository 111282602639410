import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ImportContactItemDto extends BaseModel {
  @Expose()
  @Type(() => String)
  contactCode?: string;

  @Expose()
  @Type(() => String)
  contactName?: string;

  @Expose()
  @Type(() => String)
  type?: string;

  @Expose()
  @Type(() => String)
  accountReceivable?: string;

  @Expose()
  @Type(() => String)
  accountPayable?: string;

  @Expose()
  @Type(() => String)
  contactCategory?: string;

  @Expose()
  @Type(() => String)
  commenceDate?: string;

  @Expose()
  @Type(() => String)
  email?: string;

  @Expose()
  @Type(() => String)
  phonePrefix?: string;

  @Expose()
  @Type(() => String)
  phoneNumber?: string;

  @Expose()
  @Type(() => String)
  faxPrefix?: string;

  @Expose()
  @Type(() => String)
  faxNumber?: string;

  @Expose()
  @Type(() => String)
  businessReg?: string;

  @Expose()
  @Type(() => String)
  gstReg?: string;

  @Expose()
  @Type(() => String)
  creditTerm?: string;

  @Expose()
  @Type(() => String)
  creditLimit?: string;

  @Expose()
  @Type(() => String)
  remarks?: string;

  @Expose()
  @Type(() => String)
  address?: string;

  @Expose()
  @Type(() => String)
  country?: string;

  @Expose()
  @Type(() => String)
  postCode?: string;

  @Expose()
  @Type(() => String)
  personName?: string;

  @Expose()
  @Type(() => String)
  personDesignation?: string;

  @Expose()
  @Type(() => String)
  personEmail?: string;

  @Expose()
  @Type(() => String)
  personPhoneNumber?: string;

  @Expose()
  @Type(() => String)
  personPhonePrefix?: string;

  @Expose()
  @Type(() => String)
  bankCode!: string;

  @Expose()
  @Type(() => String)
  bankAccountNumber!: string;

  @Expose()
  @Type(() => String)
  bankAccountName!: string;

  @Expose()
  @Type(() => String)
  status?: string;
}

export class ImportContactDto extends BaseModel {
  @Expose()
  @Type(() => ImportContactItemDto)
  items?: ImportContactItemDto[];
}
