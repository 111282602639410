import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { SupplierBankAccountDto, SupplierBankAccountValidateDto } from '@shared/data-access/dto';
import { ContactCustomerAndSupplierModel, SupplierBankAccountModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupplierBankAccountApiService {
  static ROOT_POINT = 'finance/supplier-bank-accounts';
  static DETAIL_POINT = 'finance/supplier-bank-accounts/:id';
  static VALIDATE_BANK_ACCOUNT_POINT = 'finance/supplier-bank-accounts/validate';

  constructor(private apiService: ApiService) {}

  create(data: SupplierBankAccountDto): Observable<SupplierBankAccountModel> {
    return this.apiService
      .post(`${SupplierBankAccountApiService.ROOT_POINT}`, data)
      .pipe(map((res: SupplierBankAccountModel) => SupplierBankAccountModel.fromJson(res)));
  }

  getById(contactId: number, query = {}): Observable<SupplierBankAccountModel> {
    return this.apiService.get(SupplierBankAccountApiService.ROOT_POINT + '/' + contactId, query).pipe(map(res => SupplierBankAccountModel.fromJson(res)));
  }

  update(data: SupplierBankAccountDto & { id: number }): Observable<SupplierBankAccountModel> {
    return this.apiService.patch(SupplierBankAccountApiService.DETAIL_POINT, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ContactCustomerAndSupplierModel>> {
    return this.apiService
      .get(SupplierBankAccountApiService.ROOT_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ContactCustomerAndSupplierModel, data)));
  }

  validateBankAccount(data: SupplierBankAccountValidateDto): Observable<any> {
    return this.apiService.post(SupplierBankAccountApiService.VALIDATE_BANK_ACCOUNT_POINT, data).pipe(map(value => value.valid));
    // return this.apiService.post(SupplierBankAccountApiService.VALIDATE_BANK_ACCOUNT_POINT, data);
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(SupplierBankAccountApiService.DETAIL_POINT, { id }, option);
  }
}
