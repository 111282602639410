<div class="flex flex-col flex-auto h-full overflow-hidden">
  <!-- Header -->
  <div class="nav-header flex px-6 py-2 z-10">
    <div class="flex items-center justify-start basis-18 max-w-lg border-r">
      <button mat-icon-button type="button" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="flex-1 font-bold self-center ml-9">{{ fileName }}</div>
    <div class="flex items-center justify-end w-full pr-4 max-w-lg gap-4">
      <button *ngIf="printFn" color="primary" mat-flat-button class="red-mat-button-small" (click)="print()">
        <mat-icon class="mr-1">print</mat-icon>
        Print
      </button>
      <a *ngIf="downloadFn" mat-flat-button class="red-mat-button-small" color="primary" type="button" (click)="download()">
        <mat-icon class="mr-1">file_download</mat-icon>
        Download
      </a>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-1 overflow-auto bg-theme-line">
    <div #table class="flex flex-auto flex-col my-4 mx-auto px-4 relative">
      <red-exceljs-table *ngFor="let worksheet of data?.workbook?.worksheets" [worksheet]="worksheet"></red-exceljs-table>
    </div>
  </div>
</div>
