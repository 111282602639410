import { isMoment } from 'moment';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MetabaseCardParameter } from '../../models';
import { MetabaseParameterFieldBase } from '../metabase-parameter-field-base.interface';

@Component({
  selector: 'red-metabase-parameter-date',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, MatFormFieldModule, MatInputModule],
  templateUrl: './metabase-parameter-date.component.html',
  styleUrls: ['./metabase-parameter-date.component.scss'],
})
export class MetabaseParameterDateComponent extends MetabaseParameterFieldBase {
  toDataDowncastValue(scheme: Partial<MetabaseCardParameter>, val: unknown): unknown {
    if (isMoment(val)) {
      return val.format('YYYY-MM-DD');
    }
    return val;
  }
  toDataUpcastValue(scheme: Partial<MetabaseCardParameter>, val: unknown): unknown {
    return val;
  }
  constructor(private _fb: FormBuilder) {
    super();
  }

  get controlType(): string {
    return 'date';
  }
}
