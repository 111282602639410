import { BaseModel, Default } from '@red/data-access';
import { Expose, Type, Transform } from 'class-transformer';
import { ICommSalespersonSchemeCreateDto, ICommSalespersonSchemePosition, ICommSalespersonSchemeUpdateDto, ISplitMatrixDto, ISplitPartDto } from '../interfaces';

export class SplitPartDto extends BaseModel implements ISplitPartDto {
  @Expose()
  name!: string;

  @Expose()
  @Default(0)
  @Type(() => Number)
  percentage!: number;

  @Expose()
  @Type(() => Number)
  level!: number;
}

export class SplitMatrixDto extends BaseModel implements ISplitMatrixDto {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  @Type(() => SplitPartDto)
  tiers!: SplitPartDto[];

  @Expose()
  @Type(() => SplitPartDto)
  parties!: SplitPartDto[];

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;
}

export class CommSalespersonSchemeCreateDto extends BaseModel implements ICommSalespersonSchemeCreateDto {
  @Expose()
  @Type(() => Number)
  schemeTypeId!: number;

  @Expose()
  designation!: string;

  @Expose()
  code!: string;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  autoApprove!: boolean;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => Number)
  sequence!: number;

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return 0;
    }
    return parseFloat(value);
  })
  @Type(() => Number)
  minCommission!: number;

  @Expose({ groups: ['hasMax'] })
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return parseFloat(value);
  })
  @Type(() => Number)
  maxCommission!: number;

  @Expose()
  @Type(() => SplitMatrixDto)
  splitMatrices!: SplitMatrixDto[];
}

export class CommSalespersonSchemeUpdateDto extends BaseModel implements ICommSalespersonSchemeUpdateDto {
  @Expose()
  designation!: string;

  @Expose()
  code!: string;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  autoApprove!: boolean;

  @Expose()
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => Number)
  sequence!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  minCommission!: number;

  @Expose({ groups: ['hasMax'] })
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return parseInt(value, 10);
  })
  maxCommission!: number;

  @Expose()
  @Type(() => SplitMatrixDto)
  splitMatrices!: SplitMatrixDto[];

  @Expose()
  position!: ICommSalespersonSchemePosition;
}
