export const IS_ACTIVE = [
  {
    id:'active',
    value:true,
    name:'Active'
  },
  {
    id:'inactive',
    value:false,
    name:'InActive'
  }
] as const;
