import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ProjectTransactionCreateDto, ProjectTransactionUpdateDto } from '@shared/data-access/dto';
import { IProjectTransaction } from '@shared/data-access/interfaces';
import { ProjectTransactionAgentModel, UnitModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectTransactionApiService {
  static ROOT_POINT = 'transaction/portal/transactions';
  static UNIT_FOR_SALE_POINT = 'transaction/units/portal/for-sale';
  static STATUS_HISTORIES_POINT = 'transaction/status-histories/portal';
  static DASHBOARD_POINT = 'transaction/dashboard/portal/transactions';

  constructor(private apiService: ApiService) {}

  create(data: ProjectTransactionCreateDto, option?: IApiOption): Observable<ProjectTransactionAgentModel> {
    return this.apiService
      .post(`${ProjectTransactionApiService.ROOT_POINT}`, data, option)
      .pipe(map((res: IProjectTransaction) => ProjectTransactionAgentModel.fromJson(res) as ProjectTransactionAgentModel));
  }

  get(itemId: number): Observable<ProjectTransactionAgentModel> {
    return this.apiService
      .get(ProjectTransactionApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => ProjectTransactionAgentModel.fromJson(res) as ProjectTransactionAgentModel));
  }

  update(id: number, data: ProjectTransactionUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectTransactionApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionAgentModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectTransactionAgentModel, data)));
  }

  searchDashboard(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionAgentModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.DASHBOARD_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionAgentModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectTransactionApiService.ROOT_POINT + '/' + id, option);
  }

  submit(id: number): Observable<any> {
    return this.apiService.post(ProjectTransactionApiService.ROOT_POINT + '/' + id + '/submit');
  }

  searchUnits(type: 'forSale', query = {}, option?: IApiOption): Observable<PaginationAdapter<UnitModel>> {
    const endPoint = ProjectTransactionApiService.UNIT_FOR_SALE_POINT;
    return this.apiService.get(endPoint, query, option).pipe(map(data => new PaginationAdapter(UnitModel, data)));
  }

  searchStatusHistories(query = {}, option?: IApiOption): Observable<PaginationAdapter<any>> {
    return this.apiService
      .get(ProjectTransactionApiService.STATUS_HISTORIES_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionAgentModel, data)));
  }
}
