import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import {
  ProjectCommissionMatrixCreateDto,
  ProjectCommissionMatrixHICreateDto,
  ProjectCommissionMatrixHIUpdateDto,
  ProjectCommissionMatrixUpdateDto,
  ProjectCommissionStatusUpdateDto,
} from '@shared/data-access/dto';
import { ProjectCommissionMatrixModel, ProjectHICommMatrixModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectCommissionMatrixApiService {
  static ROOT_POINT = 'transaction/project-commissions';
  static INTERNATIONAL_ROOT_POINT = 'transaction/project-commissions/international';
  static CHANGE_STATUS_POINT = 'transaction/project-commissions/change-status';
  static INTERNATIONAL_CHANGE_STATUS_POINT = 'transaction/project-commissions/international/change-status';
  static VERIFICATION_POINT = 'transaction/project-commissions/verification';
  static INTERNATIONAL_VERIFICATION_POINT = 'transaction/project-commissions/international/verification';

  constructor(private apiService: ApiService) {}

  create(data: ProjectCommissionMatrixCreateDto): Observable<ProjectCommissionMatrixModel> {
    return this.apiService
      .post(`${ProjectCommissionMatrixApiService.ROOT_POINT}`, data)
      .pipe(map(res => ProjectCommissionMatrixModel.fromJson(res) as ProjectCommissionMatrixModel));
  }
  get(itemId: number): Observable<ProjectCommissionMatrixModel> {
    return this.apiService
      .get(ProjectCommissionMatrixApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => ProjectCommissionMatrixModel.fromJson(res) as ProjectCommissionMatrixModel));
  }
  update(id: number, data: ProjectCommissionMatrixUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectCommissionMatrixApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectCommissionMatrixModel>> {
    return this.apiService
      .get(ProjectCommissionMatrixApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectCommissionMatrixModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectCommissionMatrixApiService.ROOT_POINT, { ids }, option);
  }

  updateStatus(id: number, status: string): Observable<any> {
    return this.apiService.patch(ProjectCommissionMatrixApiService.ROOT_POINT + '/' + id, { status });
  }

  changeCommissionStatus(payload: ProjectCommissionStatusUpdateDto): Observable<any> {
    return this.apiService.post(ProjectCommissionMatrixApiService.CHANGE_STATUS_POINT, payload);
  }

  // HI
  createCommMatrixHI(data: ProjectCommissionMatrixHICreateDto): Observable<ProjectHICommMatrixModel> {
    return this.apiService
      .post(ProjectCommissionMatrixApiService.INTERNATIONAL_ROOT_POINT, data)
      .pipe(map(res => ProjectHICommMatrixModel.fromJson(res) as ProjectHICommMatrixModel));
  }

  updateCommMatrixHI(id: number, data: ProjectCommissionMatrixHIUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectCommissionMatrixApiService.INTERNATIONAL_ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  searchCommMatrixHI(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectHICommMatrixModel>> {
    return this.apiService
      .get(ProjectCommissionMatrixApiService.INTERNATIONAL_ROOT_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectHICommMatrixModel, data)));
  }

  getDetailCommMatrixHI(id: number): Observable<ProjectHICommMatrixModel> {
    return this.apiService
      .get(ProjectCommissionMatrixApiService.INTERNATIONAL_ROOT_POINT + '/' + id)
      .pipe(map(res => ProjectHICommMatrixModel.fromJson(res) as ProjectHICommMatrixModel));
  }

  deleteCommMatrixHI(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectCommissionMatrixApiService.INTERNATIONAL_ROOT_POINT, { ids }, option);
  }

  changeCommissionStatusHI(payload: ProjectCommissionStatusUpdateDto): Observable<any> {
    return this.apiService.post(ProjectCommissionMatrixApiService.INTERNATIONAL_CHANGE_STATUS_POINT, payload);
  }

  searchVerificationHa(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectCommissionMatrixModel>> {
    return this.apiService
      .get(ProjectCommissionMatrixApiService.VERIFICATION_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectCommissionMatrixModel, data)));
  }

  searchVerificationHi(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectHICommMatrixModel>> {
    return this.apiService
      .get(ProjectCommissionMatrixApiService.INTERNATIONAL_VERIFICATION_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectHICommMatrixModel, data)));
  }
}
