import { ILedgerAccount } from './ledger-account.interface';
import { IProfitCentres } from './profit-centres.interface';
import { IUpdatedBy } from './updated-by.interface';

export interface IScheduleTypes {
  value: string[];
}

export interface IJournalEntryPosting {
  id: number;
  journalId: number;
  accountId: number;
  account: ILedgerAccount;
  profitCenterId?: number;
  profitCenter?: IProfitCentres;
  description?: string;
  debit: number;
  credit: number;
  order: number;
  rate?: number;
  currency: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  createdBy: IUpdatedBy;
  updatedBy: IUpdatedBy;
  deletedBy?: IUpdatedBy;
}

export interface IJournalEntry {
  businessUnitId?: number;
  code: string;
  createdAt?: string;
  createdBy?: IUpdatedBy;
  deletedAt?: string;
  deletedBy?: IUpdatedBy;
  formatCode?: string;
  id: number;
  inClosedPeriod?: boolean;
  journalDate?: string;
  name: string;
  reversal: boolean;
  reversalJournal?: IJournalEntry;
  reversalJournalId: number;
  scheduleEnable?: boolean;
  scheduleEndDate?: string;
  scheduleName?: string;
  scheduleStartDate?: string;
  scheduleType?: string;
  totalCredit: number;
  totalDebit: number;
  updatedAt?: string;
  updatedBy?: IUpdatedBy;
}
