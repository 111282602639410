import { IAppointmentType } from './appointment-type.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IProject } from './project.interface';

export interface IKeyAppointment {
  appointmentType: IAppointmentType;
  appointmentTypeId: number;
  createdAt: string;
  endDate: string;
  id: number;
  isMainProjectIC: boolean;
  // isPersonInCharge: boolean;
  projectId: number;
  project: IProject;
  salesperson: IPersonalParticular;
  salespersonId: number;
  startDate: string;
  updatedAt: string;
  remarks?: string;
  appTypes: { appType: IAppointmentType }[];
}
