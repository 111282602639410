import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IOverridingChart } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';
import { UpdatedByModel } from './updated-by.model';

export class OverridingChartModel extends BaseModel implements IOverridingChart {
  @Expose()
  appointmentTypeId!: number;

  @Expose()
  @Type(() => AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;

  @Expose()
  l1Percent!: number;

  @Expose()
  icWeightage!: number;

  @Expose()
  poolWeightage!: number;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;
}
