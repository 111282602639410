import { InjectionToken, Type } from '@angular/core';
import { ExceljsParameterCheckboxComponent } from './exceljs-parameter-checkbox/exceljs-parameter-checkbox.component';
import { ExceljsParameterDateComponent } from './exceljs-parameter-date/exceljs-parameter-date.component';
import { ExceljsParameterFieldBase } from './exceljs-parameter-field-base.directive';
import { ExceljsParameterSelectComponent } from './exceljs-parameter-select/exceljs-parameter-select.component';
import { ExceljsParameterTextComponent } from './exceljs-parameter-text/exceljs-parameter-text.component';
import { ExceljsParameterDateRangeComponent } from './exceljs-parameter-date-range/exceljs-parameter-date-range.component';
import { ExceljsParameterMonthComponent } from './exceljs-parameter-month/exceljs-parameter-month.component';
import { ExceljsParameterSelectionFromDialogComponent } from './exceljs-parameter-selection-from-dialog/exceljs-parameter-selection-from-dialog.component';
export const EXCELJS_PARAMETER_PLUGINS = new InjectionToken<Record<string, any>>('EXCELJS_PARAMETER_PLUGINS');
export const builtInParameterPlugins: Type<ExceljsParameterFieldBase>[] = [
  ExceljsParameterDateComponent,
  ExceljsParameterDateRangeComponent,
  ExceljsParameterMonthComponent,
  ExceljsParameterTextComponent,
  ExceljsParameterCheckboxComponent,
  ExceljsParameterSelectComponent,
  ExceljsParameterSelectionFromDialogComponent,
];
