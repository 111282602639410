import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { CHART_OF_ACCOUNT_DISPLAY_STATUSES } from '../data';
import { EChartOfACcountDisplayStatus } from '../enums';
import { ILedgerAccount, ILedgerAccountChangeRequest, IStatusDescription } from '../interfaces';
import { UpdatedByModel } from './updated-by.model';

export class LedgerAccountChangeRequestModel extends BaseModel implements ILedgerAccountChangeRequest {
  @Expose()
  current!: string;

  @Expose()
  request!: string;
}

export class LedgerAccountModel extends BaseModel implements ILedgerAccount {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  status!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  deletedBy!: string;

  @Expose()
  description!: string;

  @Expose()
  type?: string;

  @Expose()
  refCode?: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  parent?: LedgerAccountModel;

  @Expose()
  parentId?: string;

  @Expose()
  verifyStatus!: string | null;

  @Expose()
  rejectReason?: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  changeRequest?: Record<string, LedgerAccountChangeRequestModel>;

  @Expose()
  @Transform(({ obj }: { obj: ILedgerAccount }) => {
    switch (obj.status) {
      case 'active':
        return {
          className: 'status-box-success',
          value: obj.status,
          displayName: 'active',
        };
      case 'pending':
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: 'Pending Approval',
        };
      default:
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: obj.status,
        };
    }
  })
  statusObj!: { className: string; value: string; displayName: string };

  get coaDisplayStatus(): IStatusDescription {
    let status: IStatusDescription | null = null;

    switch (this.verifyStatus) {
      case EChartOfACcountDisplayStatus.pending:
        status = CHART_OF_ACCOUNT_DISPLAY_STATUSES.pending;
        break;
      case EChartOfACcountDisplayStatus.rejected:
        status = CHART_OF_ACCOUNT_DISPLAY_STATUSES.rejected;
        break;
      default:
        status = null;
    }

    if (status) return status;

    switch (this.status) {
      case EChartOfACcountDisplayStatus.active:
        status = CHART_OF_ACCOUNT_DISPLAY_STATUSES.active;
        break;
      case EChartOfACcountDisplayStatus.inactive:
        status = CHART_OF_ACCOUNT_DISPLAY_STATUSES.inactive;
        break;
      default:
        status = CHART_OF_ACCOUNT_DISPLAY_STATUSES.inactive;
    }

    return status;
  }

  @Expose()
  @Transform(({ obj }) => {
    const normalized = `${obj.code || ''} ${obj.name || ''}`;
    return normalized.trim();
  })
  normalizedAccountName!: string;
}
