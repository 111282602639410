import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { Status } from '../enums/status.enum';
import { AccountVerifyStatus } from '../enums';

export class LedgerAccountDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  type!: string;

  @Expose()
  refCode!: string;

  @Expose()
  status!: Status;

  @Expose()
  role: string = 'local' || 'master';

  @Expose()
  parentId?: number | null;

  @Expose()
  verifyStatus!: AccountVerifyStatus[];
}
