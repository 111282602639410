import { BaseModel} from '@red/data-access';
import {  Expose } from 'class-transformer';
import { IEmploymentHistory } from '../interfaces';


export class EmploymentHistoryModel extends BaseModel implements IEmploymentHistory {
  @Expose()
  id!: number;

  @Expose()
  companyName!: string;

  @Expose()
  designation!: string;

  @Expose()
  areaWork!: number;

  @Expose()
  address!: string;

  @Expose()
  reasonLeft!: string;

  @Expose()
  remarks!: string;

  @Expose()
  periodFrom!: string;

  @Expose()
  periodTo!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

}
