import { ISplitMatrix } from './split-matrix.interface';

import { IPersonalParticular } from './personal-particular.interface';
import { IAppointmentType } from './appointment-type.interface';
import { ISplitPart } from './split-part.interface';
import { EAgentSession } from '../enums';
export interface IProjectTransactionPool {
  id: number;
  appointmentTypeId: number;
  appTypeCode: string;
  appointmentType: IAppointmentType;
  salespersonId: number;
  salesperson: IPersonalParticular;
  splitMatrixId: number;
  splitMatrix: ISplitMatrix;
  receivedDirectPercent: number;
  receivedPercent: number;
  receivedValue: number;
  poolType: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  tiers: ISplitPart[];
  agentType: EAgentSession;
  grouping: string;
}
