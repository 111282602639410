import { IProfitSharing, IProfitSharingDetail, IProfitSharingItem, IProfitSharingItemDetail } from './../interfaces/profit-sharing.interface';
import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { SchemeTypeModel } from './scheme-type.model';
import { CommissionLeadershipSchemeModel } from './commission-leadership-scheme.model';
import { UpdatedByModel } from './updated-by.model';

export class ProfitSharingItemModel extends BaseModel implements IProfitSharingItem {
  @Expose()
  commId!: number;

  @Expose()
  sequence!: number;

  @Expose()
  profit!: number;
}

export class ProfitSharingItemDetailModel extends ProfitSharingItemModel implements IProfitSharingItemDetail {
  @Expose()
  comm?: {
    status: string;
    id: number;
    code: string;
    designation: string;
    commissionType: string;
  };
}

export class ProfitSharingModel extends BaseModel implements IProfitSharing {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  schemeTypeId!: number;

  @Expose()
  isActive!: boolean;

  @Expose()
  name!: string;

  @Expose()
  branchOutAmount!: number;

  @Expose()
  maximumGroup1!: number;

  @Expose()
  maximumGroup2!: number;

  @Expose()
  @Type(() => ProfitSharingItemDetailModel)
  group1!: ProfitSharingItemDetailModel[];

  @Expose()
  @Type(() => ProfitSharingItemDetailModel)
  group2!: ProfitSharingItemDetailModel[];

  @Expose()
  @Type(() => SchemeTypeModel)
  schemeType!: SchemeTypeModel;

  //#region timestamps
  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt?: string | null;

  @Expose()
  createdBy?: string | null;

  @Expose()
  updatedBy?: UpdatedByModel | null;

  // @Expose()
  // deletedBy?: UpdatedByModel | null;
  //#endregion
}

export class ProfitSharingDetailModel extends ProfitSharingModel implements IProfitSharingDetail {
  @Expose()
  @Type(() => ProfitSharingItemDetailModel)
  override group1!: ProfitSharingItemDetailModel[];

  @Expose()
  @Type(() => ProfitSharingItemDetailModel)
  override group2!: ProfitSharingItemDetailModel[];
}
