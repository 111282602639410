import { ECommType, EGstType, EResaleAgentType, EResaleCommissionTaxType } from '../enums';
import { IPhoneNumber } from '@red/data-access';
import { IGrossCommissionEarned } from '@shared/data-access/interfaces/gross-commission-earned.interface';
import { IContactCustomerAndSupplier } from '@shared/data-access/interfaces/contact-customer-and-supplier.interface';

export interface IResaleExternalCoBroke {
  id: number;
  contactId: number;
  contact: IContactCustomerAndSupplier;
  gstType: EGstType;
  brokerName: string;
  brokerIdNo: string;
  brokerPhone: IPhoneNumber;
  brokerEmail: string;
  remarks: string;
  commType: ECommType;
  receivedPercent: number;
  receivedValue: number;
  attrs: {
    sequence: number;
    commission?: {
      type: EResaleCommissionTaxType;
      value: number;
    };
    [key: string]: any;
  };
  type: EResaleAgentType;
  grossComm: IGrossCommissionEarned;
  isDefault?: boolean;
}
