export interface IFileUploader {
  id: number;
  name: string;
  isPreview: boolean;
  file?: File | any;
  uploading: boolean;
  error: {
    status: boolean;
    message: string;
  };
  key: string;
  fullKeyName?: string;
  data: string;
  url?: string;
  completed?: boolean;
  isExistFile: boolean;
  size?: number;
}
