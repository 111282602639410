export enum EPaymentStatus {
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  reversed = 'reversed',
}

export enum EPaymentMemoType {
  SupplierInvoice = 'SupplierInvoice',
  CustomerCreditNote = 'CustomerCreditNote',
  SupplierCreditNote = 'SupplierCreditNote',
}

export enum EPaymentRecordType {
  IV = 'IV', // Invoice
  DN = 'DN', // Customer Debit Note
  SAL = 'SAL', // Taxable Sales Receipt
  VCN = 'VCN', // Supplier (Vendor) Credit Note
  SCN = 'SCN', // Supplier (Vendor) Credit Note
  AD = 'AD', // Supplier Advance
  VI = 'VI', // Supplier (Vendor) Invoice
  CN = 'CN', // Customer Credit Note
  AR = 'AR', // Advance Receipt
}

export enum EPaymentAction {
  ADD_SUPPLIER_INVOICE = 'SUPPLIER_INVOICE',
  ADD_SUPPLIER_CREDIT_NOTE = 'SUPPLIER_CREDIT_NOTE',
  ADD_CUSTOMER_CREDIT_NOTE = 'CUSTOMER_CREDIT_NOTE',
}

export enum EPaymentInvoiceType {
  IV = 'IV',
  VI = 'VI',
  CN = 'CN',
  VCN = 'VCN',
  SCN = 'SCN',
  PA = 'PA',
  PR = 'PR',
  PV = 'PV',
  SAL = 'SAL',
  WR = 'WR',
}
