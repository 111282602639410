import { Directive, Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ReportCard } from '@shared/components/report-manager';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { map, Observable } from 'rxjs';
import { Mixin } from 'ts-mixer';
import { ExceljsApiService, ExceljsCardCollection } from './exceljs-report-api.service';
import { MetabaseApiService, MetabaseCardCollection } from './metabase-report-api.service';

// @Directive()
// abstract class TransactionReportsMetabaseApiService extends MetabaseApiService {
//   collection: MetabaseCardCollection = 'project_reports';
// }

@Directive()
abstract class TransactionReportsExceljsApiService extends ExceljsApiService {
  collectionEndpoint = ExceljsCardCollection.TransactionReport;
}
@Directive()
abstract class TransactionReportsCardApiService {
  static REPORT_CARD_POINT = 'transaction/common-reports';
  constructor(protected apiService: ApiService) {}

  findReportCards(query: IQuerySearch = {}, option?: IApiOption): Observable<PaginationAdapter<ReportCard>> {
    return this.apiService.get(TransactionReportsCardApiService.REPORT_CARD_POINT, query, option).pipe(map(data => new PaginationAdapter(ReportCard, data)));
  }
}

@Injectable({
  providedIn: 'root',
})
export class TransactionReportApiService extends Mixin(TransactionReportsCardApiService, TransactionReportsExceljsApiService) {}
