import { ReplaySubject, Subject } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export interface PageTitleAsyncData {
  id: number | string;
  title: string;
}
export interface PageTitleConfigs {
  default: string;
  prefix?: string;
  suffix?: string;
}
export const PAGE_TITLE_ASYNC = new InjectionToken<Subject<PageTitleAsyncData>>('PAGE_TITLE_ASYNC');
export const PAGE_TITLE_CONFIGS = new InjectionToken<PageTitleConfigs>('PAGE_TITLE_CONFIGS');
