// core
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// share
import { TemplateModel } from '@shared/data-access/models';
import { PaginationAdapter } from '@red/data-access';
import { ApiService, IApiOption } from '@red/api';

@Injectable({
  providedIn: 'root',
})
export class TemplateApiService {
  static ROOT_POINT = 'hrm/templates/portal';
  static PUBLIC_POINT = 'hrm/templates/public';

  constructor(private apiService: ApiService) {}

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<TemplateModel>> {
    return this.apiService.get(TemplateApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(TemplateModel, data)));
  }

  searchPublic(query = {}, option?: IApiOption): Observable<PaginationAdapter<TemplateModel>> {
    return this.apiService.get(TemplateApiService.PUBLIC_POINT, query, option).pipe(map(data => new PaginationAdapter(TemplateModel, data)));
  }
}
