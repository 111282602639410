import { filter, from, iif, Observable, OperatorFunction, Subject } from 'rxjs';
import { Inject, Injectable, NgZone } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';
import { IBroadcastChannelMessage } from './model/broadcast-channel.interface';
import { BROADCAST_CHANNELS } from './broadcast-channel.token';

@Injectable()
export class BroadcastChannelService {
  private onMessage = new Subject<IBroadcastChannelMessage<any>>();
  constructor(@Inject(BROADCAST_CHANNELS) private channels: Map<string, BroadcastChannel>, private ngZone: NgZone) {
    Array.from(this.channels.values()).forEach(broadcastChannel => {
      broadcastChannel.onmessage = message => {
        this.onMessage.next(message);
      };
    });
  }
  on<T>(channelName: string, eventType?: string): Observable<IBroadcastChannelMessage<T>> {
    return this.onMessage.pipe(
      // It is important that we are running in the NgZone. This will make sure that Angular component changes are immediately visible in the browser when they are updated after receiving messages.
      runInZone(this.ngZone),
      filter(message => {
        console.log('on msg -->', message);
        return message.channelName === channelName && (eventType ? message.type === eventType : true);
      })
    );
  }
  dispatch<T>(msg: IBroadcastChannelMessage<T>): Promise<void> {
    if (!this.has(msg.channelName)) {
      throw new Error(`Channel ${msg.channelName} is not existed`);
    }
    const broadcast = this.channels.get(msg.channelName) as BroadcastChannel;
    return broadcast.postMessage(msg);
  }
  has(channelName: string): boolean {
    return this.channels.has(channelName);
  }
  destroy(): void {
    Array.from(this.channels.values()).forEach(broadcastChannel => {
      broadcastChannel.close();
    });
    this.channels.clear();
    this.onMessage.complete();
  }
}

/**
 * Custom OperatorFunction that makes sure that all lifecycle hooks of an Observable
 * are run in the NgZone.
 */
export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return source => {
    return new Observable(observer => {
      const onNext = (value: T) => zone.run(() => observer.next(value));
      const onError = (e: any) => zone.run(() => observer.error(e));
      const onComplete = () => zone.run(() => observer.complete());
      return source.subscribe({ next: onNext, error: onError, complete: onComplete });
    });
  };
}

// export abstract class BroadcastChannelStagery<TChannel extends string>{
//   channelName!:TChannel
// }
