import { Inject, Injectable, inject } from '@angular/core';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from './auth.utils';
import { LoginDto, LoginResponseDto, RefreshTokenResponseDto } from '@shared/data-access/dto';
import { AuthApiLoader, AUTH_API_LOADER } from './auth-api.loader';
import { AuthLoginResponseDto } from './auth.model';
import { OauthService } from './oauth';
export enum AuthActionEvent {
  LOGIN = 1,
  LOGOUT = 2,
}
export interface AuthProviderBase {
  get accessToken(): string;
  set accessToken(token: string);
  get refreshToken(): string;
  set refreshToken(token: string);
  /**
   * Sign in
   *
   * @param credentials
   */
  signIn<TLoginDto, R extends AuthLoginResponseDto>(credentials: TLoginDto): Observable<R>;
  /**
   * Sign in using the access token
   */
  signInUsingRefreshToken(): Observable<boolean>;
  /**
   * Sign out
   */
  signOut(): void;
  /**
   * Check the authentication status
   */
  check(): Observable<boolean>;
}
@Injectable()
export class AuthProvider {
  actionEvent = new ReplaySubject<AuthActionEvent>(1);
  private authenticated = false;

  /**
   * Constructor
   */
  constructor(@Inject(AUTH_API_LOADER) private apiService: AuthApiLoader) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for access token
   */
  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: LoginDto): Observable<LoginResponseDto> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this.apiService.signIn(credentials).pipe(
      switchMap((response: LoginResponseDto) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Push notification
        this.actionEvent.next(AuthActionEvent.LOGIN);

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingRefreshToken(): Observable<boolean> {
    // this.accessToken = '';
    if (!this.refreshToken || AuthUtils.isTokenExpired(this.refreshToken)) {
      return of(false);
    }
    // Renew token
    return this.apiService.refreshToken(this.refreshToken).pipe(
      switchMap((response: RefreshTokenResponseDto) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Return true
        return of(true);
      }),
      catchError((error) => {
        console.log('error', error)
        this.accessToken = '';
        this.refreshToken = '';
        this.authenticated = false;
        return of(false)
      }
        // Return false

      )
    );
  }
  /**
   * Sign out
   */
  signOut(): void {
    // Remove the tokens from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    // Set the authenticated flag to false
    this.authenticated = false;

    // Push notification
    this.actionEvent.next(AuthActionEvent.LOGOUT);
    // location.reload();
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    try {
      if (AuthUtils.isTokenExpired(this.accessToken)) {
        return this.signInUsingRefreshToken();
      }
    } catch (error) {
      console.log('error', error);
      this.signOut();
      return of(false);
    }
    return of(true);
  }
}
@Injectable()
export class OauthAuthProvider {
  actionEvent = new ReplaySubject<AuthActionEvent>(1);
  private authenticated = false;
  oauthService = inject(OauthService);

  /**
   * Constructor
   */
  constructor(@Inject(AUTH_API_LOADER) private apiService: AuthApiLoader) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for access token
   */
  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: LoginDto): Observable<LoginResponseDto> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this.apiService.signIn(credentials).pipe(
      switchMap((response: LoginResponseDto) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Push notification
        this.actionEvent.next(AuthActionEvent.LOGIN);

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingRefreshToken(): Observable<boolean> {
    // this.accessToken = '';
    if (!this.refreshToken || AuthUtils.isTokenExpired(this.refreshToken)) {
      return of(false);
    }
    // Renew token
    return this.apiService.refreshToken(this.refreshToken).pipe(
      switchMap((response: RefreshTokenResponseDto) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Return true
        return of(true);
      }),
      catchError(() =>
        // Return false
        of(false)
      )
    );
  }
  /**
   * Sign out
   */
  signOut(): void {
    // Remove the tokens from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    // Set the authenticated flag to false
    this.authenticated = false;

    // Push notification
    this.actionEvent.next(AuthActionEvent.LOGOUT);
    // Reload the app
    console.log('hello signOut')
    this.oauthService?.logOut();
    // location.reload();
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    try {
      if (AuthUtils.isTokenExpired(this.accessToken)) {
        return this.signInUsingRefreshToken();
      }
    } catch (error) {
      console.log('error', error);
      this.signOut();
      return of(false);
    }
    return of(true);
  }
}
