import { EResaleStatus } from '@shared/data-access/enums';

export interface IResaleStatusHistory {
  id: number;
  action: EResaleStatus;
  resaleId: number;
  createdAt: string;
  createdBy: string;
  data: { reason: string };
  deletedAt: string;
  deletedBy: string;
  state: string;
  updatedAt: string;
}
