import { EPaymentDirectStatus } from "../enums";

export const DIRECT_DEBIT_PAYMENT_STATUS_FILTER_OPTIONS = [
  {
    name: 'Selected',
    value: EPaymentDirectStatus.Selected,
  },
  {
    name: 'Email Sent',
    value: EPaymentDirectStatus.EmailSent,
  },
  {
    name: 'Posted',
    value: EPaymentDirectStatus.Posted,
  },
];
