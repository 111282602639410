import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { SupplierInvoiceDto, SupplierInvoicePostingReqDto } from '@shared/data-access/dto';
import { ECustomerOrSupplier } from '@shared/data-access/enums/tax-invoice-enhanced.enum';
import { ISupplierInvoice, ISupplierInvoicePayment, ISupplierInvoicePosting, ISupplierInvoiceValidateCode } from '@shared/data-access/interfaces';
import {
  SupplierInvoiceCodeModel,
  SupplierInvoiceModel,
  SupplierInvoiceForCloneModel,
  SupplierInvoicePostingModel,
  SupplierInvoiceRecordModel,
} from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupplierInvoiceApiService {
  static ROOT_POINT = 'finance/supplier-invoices';
  static CODE = 'finance/supplier-invoices/code/next';
  static PREVIEW_POSTING = 'finance/supplier-invoices/postings/preview';
  static POSTING_POINT = 'finance/supplier-invoices/:id/postings';
  static DETAILS_POINT = 'finance/supplier-invoices/:id/details';
  static PAYMENTS_POINT = 'finance/supplier-invoices/:id/payments';
  static VALIDATOR_CODE = 'finance/supplier-invoices/validator/code';
  static FOR_CLONE_POINT = 'finance/supplier-invoices/for-clone';

  constructor(private apiService: ApiService) {}

  get(id: number, query = {}): Observable<SupplierInvoiceModel> {
    return this.apiService.get(SupplierInvoiceApiService.ROOT_POINT + '/' + id, query).pipe(map(res => SupplierInvoiceModel.fromJson(res) as SupplierInvoiceModel));
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<SupplierInvoiceModel>> {
    return this.apiService
      .get(SupplierInvoiceApiService.ROOT_POINT, { ...query, type: ECustomerOrSupplier.SUPPLIER }, option)
      .pipe(map(data => new PaginationAdapter(SupplierInvoiceModel, data)));
  }

  getForClone(query = {}, option?: IApiOption): Observable<PaginationAdapter<SupplierInvoiceForCloneModel>> {
    return this.apiService
      .get(SupplierInvoiceApiService.FOR_CLONE_POINT, { ...query, type: ECustomerOrSupplier.SUPPLIER }, option)
      .pipe(map(data => new PaginationAdapter(SupplierInvoiceForCloneModel, data)));
  }

  getNextCode(query = {}): Observable<SupplierInvoiceCodeModel> {
    return this.apiService.post(SupplierInvoiceApiService.CODE, query).pipe(map((res: unknown) => SupplierInvoiceCodeModel.fromJson(res)));
  }

  create(data: SupplierInvoiceDto): Observable<SupplierInvoiceModel> {
    return this.apiService
      .post(SupplierInvoiceApiService.ROOT_POINT, data)
      .pipe(map((res: ISupplierInvoice) => SupplierInvoiceModel.fromJson(res) as SupplierInvoiceModel));
  }

  update(id: number, data: SupplierInvoiceDto): Observable<SupplierInvoiceModel> {
    return this.apiService.patch(SupplierInvoiceApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(SupplierInvoiceApiService.ROOT_POINT + '/' + id, data, option);
  }

  previewPosting(data: SupplierInvoicePostingReqDto): Observable<SupplierInvoicePostingModel[]> {
    return this.apiService
      .post(SupplierInvoiceApiService.PREVIEW_POSTING, data)
      .pipe(map(res => res.map((item: ISupplierInvoicePosting) => SupplierInvoicePostingModel.fromJson(item))));
  }

  getNextInvoiceCode(data = {}): Observable<SupplierInvoiceCodeModel> {
    return this.apiService.post(`${SupplierInvoiceApiService.CODE}`, data).pipe(map((res: unknown) => SupplierInvoiceCodeModel.fromJson(res)));
  }

  getEnhancedDetails(id: number, query = {}): Observable<PaginationAdapter<SupplierInvoiceRecordModel>> {
    return this.apiService.get(SupplierInvoiceApiService.DETAILS_POINT, { id, ...query }).pipe(map(res => new PaginationAdapter(SupplierInvoiceRecordModel, res)));
  }

  getEnhancedPostings(id: number, query = {}): Observable<PaginationAdapter<SupplierInvoicePostingModel>> {
    return this.apiService.get(SupplierInvoiceApiService.POSTING_POINT, { id, ...query }).pipe(map(res => new PaginationAdapter(SupplierInvoicePostingModel, res)));
  }

  getEnhancedPayments(id: number, query = {}): Observable<ISupplierInvoicePayment[]> {
    return this.apiService.get(SupplierInvoiceApiService.PAYMENTS_POINT, { id, ...query });
  }

  validateReference(code: string): Observable<boolean> {
    return this.apiService.post(SupplierInvoiceApiService.VALIDATOR_CODE, { code: code ?? '' }).pipe(map((res: ISupplierInvoiceValidateCode) => res.valid));
  }
}
