import { BaseModel, Default, Record } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import {
  IMetabaseCardQuery,
  IMetabaseCardData,
  IMetabaseCardDataCol,
  IMetabaseCardDataRow,
  IMetabaseCollection,
  IMetabaseEffectiveAncestor,
  IMetadataCard,
  IMetadataVisualizationSetting,
  IMetabaseColSettings,
  IMetabaseColSetting,
  IMetabaseTableColumnSetting,
  IMetabaseCardParameter,
  IMetabaseDataSetQuery,
  IMetabaseDataSetQueryNative,
  IMetabaseDataSetQueryTemplateTags,
  IMetabaseDataSetQueryTemplateTag,
} from './metabase.interface';
import { MetabaseNumberSeparator, MetabaseTimeEnabled, MetabaseTimeStyle, MetabaseViewAs, MetadataColDataType } from './metabase.type';

export class MetabaseCollectionModel extends BaseModel implements IMetabaseCollection {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  description!: string;

  @Expose()
  slug!: string;

  @Expose()
  effective_ancestors!: IMetabaseEffectiveAncestor[];

  @Expose()
  effective_location!: string;

  @Expose()
  parent_id!: number;
}
export class MetabaseCardDataCol extends BaseModel implements IMetabaseCardDataCol {
  @Expose()
  name!: string;

  @Expose({ name: 'base_type' })
  baseType!: MetadataColDataType;

  @Expose({ name: 'display_name' })
  displayName!: string;

  @Expose({ name: 'semantic_type' })
  semanticType!: string;

  @Expose({ name: 'field_ref' })
  fieldRef!: [string, string, Record<string, any>];

  @Expose({ name: 'effective_type' })
  effectiveType!: string;
  @Expose()
  get fieldRefName(): string {
    return this.fieldRef[1];
  }
}
export class MetabaseCardData extends BaseModel implements IMetabaseCardData {
  @Expose()
  rows!: IMetabaseCardDataRow;

  @Expose()
  @Type(() => MetabaseCardDataCol)
  cols!: MetabaseCardDataCol[];

  @Expose({ name: 'results_metadata' })
  @Transform(({ value }: { value: { columns: IMetabaseCardDataCol[] } }) => {
    const columns = value ? value.columns : [];
    return {
      columns: columns.map(col => MetabaseCardDataCol.fromJson(col)),
    };
  })
  resultsMetadata!: { columns: MetabaseCardDataCol[] };

  @Expose({ name: 'results_timezone' })
  resultsTimezone!: string;
}
export class MetabaseCardQueryModel extends BaseModel implements IMetabaseCardQuery {
  @Expose()
  @Type(() => MetabaseCardData)
  data!: MetabaseCardData;

  @Expose({ name: 'row_count' })
  rowCount!: number;
}
export class MetabaseColSetting extends BaseModel implements IMetabaseColSetting {
  @Expose({ name: 'time_enabled' })
  timeEnabled!: MetabaseTimeEnabled;

  @Expose({ name: 'time_style' })
  timeStyle!: MetabaseTimeStyle;

  @Expose({ name: 'number_separators' })
  @Default(MetabaseNumberSeparator.COMMA_DOT)
  numberSeparators!: MetabaseNumberSeparator;

  @Expose()
  prefix!: string;
  // template Settings
  @Expose({ name: 'view_as' })
  viewAs?: MetabaseViewAs;

  @Expose({ name: 'link_text' })
  linkText?: string;

  @Expose({ name: 'link_url' })
  linkUrl?: string;
}
export class MetabaseColSettings implements IMetabaseColSettings {
  [x: string]: MetabaseColSetting;
}
export class MetabaseTableColumnSetting extends BaseModel implements IMetabaseTableColumnSetting {
  @Expose()
  name!: string;

  @Expose()
  enabled!: boolean;

  @Expose()
  fieldRef!: [string, string, Record<string, any>];

  @Expose()
  get fieldRefName(): string {
    return this.fieldRef[1];
  }
}
export class MetabaseVisualizationSetting extends BaseModel implements IMetadataVisualizationSetting {
  @Expose({ name: 'column_settings' })
  @Default({})
  @Record(() => MetabaseColSetting)
  columnSettings!: MetabaseColSettings;

  @Expose({ name: 'table.columns' })
  @Type(() => MetabaseTableColumnSetting)
  tableColumns!: MetabaseTableColumnSetting[];
}
export class MetabaseCardParameter extends BaseModel implements IMetabaseCardParameter {
  @Expose()
  default!: string;

  @Expose()
  id!: string;

  @Expose()
  name!: string;

  @Expose()
  slug!: string;

  @Expose()
  target!: [string, [string, string]];

  @Expose()
  type!: string;

  @Expose()
  value?: any;
}
export class MetabaseDataSetQueryTemplateTag extends BaseModel implements IMetabaseDataSetQueryTemplateTag {
  @Expose()
  default!: string;

  @Expose({ name: 'display-name' })
  displayName!: string;

  @Expose()
  id!: string;

  @Expose()
  name!: string;

  @Expose()
  required!: boolean;

  @Expose()
  type!: string;
}
export class MetabaseDataSetQueryNative extends BaseModel implements IMetabaseDataSetQueryNative {
  @Expose({ name: 'template-tags' })
  @Record(() => MetabaseDataSetQueryTemplateTag)
  templateTags!: IMetabaseDataSetQueryTemplateTags;
}
export class MetabaseDataSetQuery extends BaseModel implements IMetabaseDataSetQuery {
  @Expose()
  @Type(() => MetabaseDataSetQueryNative)
  native!: MetabaseDataSetQueryNative;
}
export class MetabaseCard extends BaseModel implements IMetadataCard {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  collectionId!: number;

  @Expose()
  @Type(() => MetabaseCollectionModel)
  collection!: MetabaseCollectionModel;

  @Expose({ name: 'result_metadata' })
  @Default([])
  @Type(() => MetabaseCardDataCol)
  resultMetadata!: MetabaseCardDataCol[];

  @Expose({ name: 'dataset_query' })
  @Type(() => MetabaseDataSetQuery)
  datasetQuery!: IMetabaseDataSetQuery;

  @Expose()
  @Default([])
  @Type(() => MetabaseCardParameter)
  parameters!: MetabaseCardParameter[];

  @Expose({ name: 'visualization_settings' })
  @Type(() => MetabaseVisualizationSetting)
  visualizationSettings!: MetabaseVisualizationSetting;
}
