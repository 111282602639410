import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IMemoPayable, IMemoPayablePostingAccount } from '../interfaces/memo-payable.interface';
import { InvoicePayableModel } from './invoice-payable.model';
import { ReceiptModel } from './receipt.model';


export class MemoPayablePostingAccountModel extends BaseModel implements IMemoPayablePostingAccount {
  @Expose()
  accountCode!: string;

  @Expose()
  accountId!: number;

  @Expose()
  accountName!: string;

  @Expose()
  @Transform(({ obj }) => {
    const normalized = `${obj.accountCode || ''} ${obj.accountName || ''}`;
    return normalized.trim();
  })
  normalizedAccountName!: string;
}


export class MemoPayableModel extends BaseModel implements IMemoPayable {
  @Expose()
  id!: number;

  @Expose()
  recordId!: number;

  @Expose()
  receiptId!: number;

  @Expose()
  @Type(() => ReceiptModel)
  receipt!: ReceiptModel;

  @Expose()
  itemCode!: string;

  @Expose()
  description!: string;

  @Expose()
  type!: string;

  @Expose()
  total!: number;

  @Expose()
  paidAmount!: number;

  @Expose()
  balanceDue!: number;

  @Expose()
  editedAmount!: number | string;

  @Expose()
  amount!: number;

  @Expose()
  @Type(() => InvoicePayableModel)
  invoice!: InvoicePayableModel;

  @Expose()
  invoiceId!: number;

  @Expose()
  updatedAt!: string;

  @Expose()
  @Transform(({ obj }) => obj.receipt ?? obj.invoice?.parentInvoice?.receipts[0])
  existingReceipt?: ReceiptModel;

  @Expose()
  @Type(() => MemoPayablePostingAccountModel)
  posting!: MemoPayablePostingAccountModel
}
