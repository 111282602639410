import { IProjectConsultant } from './project-consultant.interface';
import { ISplitMatrix } from './split-matrix.interface';

import { IPersonalParticular } from './personal-particular.interface';
import { ITransactedTypeValue } from './transacted-type-value.interface';
import { IProfitSplitPart, ISplitPart } from './split-part.interface';
import { ICategory } from './category.interface';
import { EAgentSession } from '../enums';

export interface IProjectTransactionRelatedAgent {
  id: number;
  absorbType: { agent: boolean; hr: boolean };
  agentId: number;
  agentType: EAgentSession;
  agent: IPersonalParticular;
  teamType: string;
  teamId: number;
  team: IProjectConsultant;
  isCobroke: boolean;
  splitMatrixId: number;
  splitMatrix: ISplitMatrix;
  receivedDirectPercent: number;
  receivedPercent: number;
  receivedValue: number;
  transactedPercent: number;
  transactedValue: number;
  transacted: ITransactedTypeValue;
  createdAt: string;
  updatedAt: string;
  parties: ISplitPart[];
  tiers: ISplitPart[];
  profitTiers: IProfitSplitPart[];
  categoryId: number;
  category: ICategory;
  grouping: string;
  appTypeCode: string;
  gce?: number;
  advises?: IProjectTransactionAgentAdvises[];
}
export interface IProjectTransactionAgentAdvises {
  agentId: number;
  batchCode: string;
  commSchemeCode: string;
  grouping: string;
  tiers: ISplitPart[];
  parties: ISplitPart[];
  receivedPercent: number;
  receivedValue: number;
  splitMatrixId: number;
  totalAmount: number;
  transactionCode: string;
  transactionType: string;
  type: string;
}
