import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ICarrerProgression } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';
import { CommissionLeadershipSchemeModel } from './commission-leadership-scheme.model';
import { CommissionSalespersonSchemeModel } from './commission-salesperson-scheme.model';
import { SchemeTypeModel } from './scheme-type.model';

export class CareerProgressionModel extends BaseModel implements ICarrerProgression {
  @Expose()
  id!: number;

  @Expose()
  status?: string | undefined;

  @Expose()
  @Transform(({ value }) => new Date(value).toDateString())
  effectiveDate!: string;

  @Expose()
  @Transform(({ value }) => new Date(value).toDateString())
  endDate?: string | undefined;

  @Expose()
  salespersonId!: number;

  @Expose()
  commissionSchemeId!: number;

  @Expose()
  @Type(() => CommissionSalespersonSchemeModel)
  commissionScheme!: CommissionSalespersonSchemeModel;

  @Expose()
  leadershipId!: number | null;

  @Expose()
  @Type(() => CommissionLeadershipSchemeModel)
  leadership!: CommissionLeadershipSchemeModel;

  @Expose()
  appTypeIds!: { id: number }[];

  @Expose()
  appTypes!: AppointmentTypeModel[];

  @Expose()
  @Transform(({ obj }: { obj: ICarrerProgression }) => {
    return !obj?.leadership || !obj?.leadershipId ? obj?.commissionScheme?.designation : obj?.leadership?.designation;
  })
  prioritizedDesignation!: string;

  // @Expose()
  // departmentId!: number;

  // @Expose()
  // @Type(() => DepartmentModel)
  // department!: DepartmentModel;

  @Expose()
  @Type(() => SchemeTypeModel)
  schemeType!: SchemeTypeModel;

  @Expose()
  schemeTypeId!: number;

  @Expose()
  @Transform(({ obj }: { obj: ICarrerProgression }) => {
    if (!obj.appTypes || obj.appTypes.length === 0) return null;
    return obj.appTypes.map(appType => appType.name).join(', ');
  })
  appTypesInfo!: string;
}
