import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { GceAmountCreateDto, GceAmountUpdateDto } from '@shared/data-access/dto';
import { IGceAmount } from '@shared/data-access/interfaces';
import { GceAmountModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GceAmountApiService {
  static ROOT_POINT = 'hrm/gce';

  constructor(private apiService: ApiService) {}

  private _getRootPointId(id: number): string {
    return [GceAmountApiService.ROOT_POINT, id].join('/');
  }

  create(data: GceAmountCreateDto): Observable<GceAmountModel> {
    return this.apiService.post(GceAmountApiService.ROOT_POINT, data).pipe(map((res: IGceAmount) => GceAmountModel.fromJson(res) as GceAmountModel));
  }

  get(id: number): Observable<GceAmountModel> {
    return this.apiService.get(this._getRootPointId(id)).pipe(map(res => GceAmountModel.fromJson(res) as GceAmountModel));
  }

  update(id: number, data: GceAmountUpdateDto): Observable<unknown> {
    return this.apiService.patch(this._getRootPointId(id), data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<GceAmountModel>> {
    return this.apiService.get(GceAmountApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(GceAmountModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<GceAmountModel> {
    return this.apiService.delete(GceAmountApiService.ROOT_POINT, { ids }, option);
  }
}
