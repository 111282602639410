export enum ERebateBy {
  developer = 'developer',
  agent = 'agent',
}

export enum EProjectTransactionStatus {
  draft = 'draft',
  rework1 = 'rework1',
  rework2 = 'rework2',
  rework3 = 'rework3',
  submitted = 'submitted',
  confirmed = 'confirmed',
  verified = 'verified',
  approved = 'approved',
  invoiced = 'invoiced',
  completed = 'completed',
}

export enum EProjectTransactionPropertyType {
  // transaction and buyer
  ResidentialCondominiumApartment = 'ResidentialCondominiumApartment',
  ResidentialExecutiveCondominium = 'ResidentialExecutiveCondominium',
  LandedTerraceHouse = 'LandedTerraceHouse',
  LandedSemiDetachedHouse = 'LandedSemiDetachedHouse',
  LandedDetachedHouse = 'LandedDetachedHouse',
  LandedStrataLanded = 'LandedStrataLanded',

  // transaction
  CommercialOffice = 'CommercialOffice',
  CommercialShophouse = 'CommercialShophouse',
  CommercialRetail = 'CommercialRetail',
  IndustrialWarehouse = 'IndustrialWarehouse',
  IndustrialBusinessHub = 'IndustrialBusinessHub',
  IndustrialSingleUserFactory = 'IndustrialSingleUserFactory',
  IndustrialMultipleUserFactory = 'IndustrialMultipleUserFactory',
  Retail = 'Retail',
  Others = 'Others',
}
