import { BaseModel, Default } from '@red/data-access';
import { IImportChartOfAccounts, IImportChartOfAccountsColumn } from '../interfaces';
import { Expose, Transform, Type } from 'class-transformer';

export class ImportChartOfAccountModel extends BaseModel implements IImportChartOfAccounts {
  @Expose()
  rowIndex!: number;

  @Expose()
  @Default(false)
  isHover!: boolean;

  // @Expose()
  // @Default(true)
  // empty!: boolean;

  @Expose()
  @Type(() => String)
  @Default('')
  accountType!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  accountCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  accountName!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  parentAccountCode!: string;

  get isEmpty(): boolean {
    const checkingProperties: (keyof IImportChartOfAccounts)[] = ['accountType', 'accountCode', 'accountName', 'parentAccountCode'];

    return checkingProperties.every(c => {
      const value = this[c];
      return !value || value.trim() === '';
    });
  }
}

export class ImportChartOfAccountColumnModel extends BaseModel implements IImportChartOfAccountsColumn {
  @Expose()
  id!: keyof IImportChartOfAccounts;

  @Expose()
  label!: string;

  @Expose()
  index!: number;

  @Expose()
  @Default(false)
  required!: boolean;

  @Expose()
  @Default(300)
  maxLength!: number;

  @Expose()
  @Transform(({ obj }) => {
    return `${obj.label} [${obj.maxLength ?? 'NA'}]${obj.required ? '*' : ''}`;
  })
  title!: string;
}
