import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { IPaginationAdapter, PaginationAdapter, PaginationModel } from '@red/data-access';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
export interface Sort<T> {
  orderBy: keyof T;
  orderType: 'asc' | 'desc';
}
export class PagingDataSource<T, Q> extends DataSource<T> {
  private pageNumber = new Subject<number>();
  itemsSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private errorSubject = new BehaviorSubject<any>(null);
  private sort!: BehaviorSubject<Sort<T> | undefined>;
  private query!: BehaviorSubject<Partial<Q> | undefined>;
  public loading$ = this.loadingSubject.asObservable();
  public error$ = this.errorSubject.asObservable();
  protected _unsubscribeAll: Subject<void> = new Subject<void>();
  pagination = PaginationModel.createEmpty();

  constructor(
    private endpoint: (filters: IQuerySearch) => Observable<IPaginationAdapter<T>>,
    public pageSize = 10,
    public initialSort?: Sort<T>,
    public initialQuery?: Q
  ) {
    super();
    this.query = new BehaviorSubject<Partial<Q> | undefined>(this.initialQuery);
    this.sort = new BehaviorSubject<Sort<T> | undefined>(this.initialSort);
    combineLatest([this.query, this.sort])
      .pipe(
        switchMap(([query, sort]) => {
          return this.pageNumber.pipe(
            startWith(1),
            tap(() => {
              this.loadingSubject.next(true);
            }),
            switchMap(page => {
              const filters = { page, limit: this.pageSize, ...sort, ...query };
              return this.endpoint(filters).pipe(catchError((error) => {
                this.errorSubject.next(error);
                return of({ results: [], pagination: PaginationModel.createEmpty() });
              }))
            }),
            takeUntil(this._unsubscribeAll)
          );
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(data => {
        this.itemsSubject.next(data.results);
        this.loadingSubject.next(false);
        this.pagination = data.pagination;
      });
  }
  connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
    return this.itemsSubject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this.itemsSubject.complete();
    this.loadingSubject.complete();
    this.pageNumber.complete();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  page(page: number): void {
    this.pageNumber.next(page);
  }

  queryBy(query: Partial<Q>): void {
    const lastQuery = this.query.getValue();
    const nextQuery = { ...lastQuery, ...query };
    this.query.next(nextQuery);
  }

  reset(): void {
    this.queryBy(this.initialQuery || {});
  }

  get data(): T[] {
    return this.itemsSubject.value;
  }

  set data(data: T[]) {
    data = Array.isArray(data) ? data : [];
    this.itemsSubject.next(data);
  }

  refresh(): void {
    const currentPage = this.pagination.page;
    this.page(currentPage);
  }
}
