import { BaseModel } from '@red/data-access';
import {  Expose } from 'class-transformer';
import { IIdType } from '../interfaces';


export class IdTypeModel extends BaseModel implements IIdType {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  identification!: string;

  @Expose()
  iras!: string;

  @Expose()
  status!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;



}
