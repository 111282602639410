import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { UpdatedByModel } from './updated-by.model';
import { ECDD_STATUSES, ECDD_TRANSACTION_TYPE_OPTIONS } from '../data';
import { EEcddStatus, EEcddTransactionType, EEcddType } from '../enums';
import { IEcdd, IStatusDescription } from '../interfaces';
import { EcddCustomerModel } from './ecdd-customer.model';
import { EcddOptionGroupModel } from './ecdd-option-group.model';
import { PersonalParticularModel } from './personal-particular.model';
import { AddressModel } from './address.model';

export class EcddModel extends BaseModel implements IEcdd {
  @Expose()
  id!: number;

  @Expose()
  @Default(EEcddStatus.draft)
  status!: EEcddStatus;

  @Expose()
  @Transform(({ obj }) => {
    return ECDD_STATUSES[obj.status as EEcddStatus];
  })
  displayStatus!: IStatusDescription;

  @Expose()
  type!: EEcddType;

  @Expose()
  @Default(EEcddTransactionType.Rental)
  transactionType!: EEcddTransactionType;

  @Expose()
  documentTitle!: string;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  signature!: string;

  @Expose()
  checklistAt!: string;

  @Expose()
  submittedAt!: string;

  @Expose()
  submittedBy!: any;

  @Expose()
  reviewedAt!: string;

  @Expose()
  reviewedBy!: any;

  @Expose()
  completedAt!: string;

  @Expose()
  @Type(() => AddressModel)
  address?: AddressModel;

  @Expose()
  isManually!: boolean;

  @Expose()
  @Type(() => EcddCustomerModel)
  @Default([])
  customers!: EcddCustomerModel[];

  @Expose()
  amlClient!: any;

  @Expose()
  @Type(() => EcddOptionGroupModel)
  @Default([])
  ecddOptionGroups!: EcddOptionGroupModel[];

  //#region timestamps
  @Expose()
  createdAt!: string | null;

  @Expose()
  updatedAt!: string | null;

  @Expose()
  deletedAt!: string | null;

  @Expose()
  createdBy!: PersonalParticularModel | null;

  @Expose()
  updatedBy!: UpdatedByModel | null;

  @Expose()
  deletedBy!: UpdatedByModel | null;
  //#endregion

  @Expose()
  metadata!: {
    reason?: string;
    rejectedFile?: string;
    approvedFile?: string;
  };

  @Expose()
  remarks!: string;

  @Expose()
  get clientDisplay() {
    return this.type === EEcddType.Individual
      ? `${this.customers?.[0]?.name} (${this.customers?.[0]?.nricfin})`
      : `${this.customers?.[0]?.name} (${this.customers?.[0]?.registrationNumber})`;
  }

  @Expose()
  get salespersonDisplay() {
    return this.salesperson ? this.salesperson?.businessName + ' (' + this.salesperson?.ceaRegNo + ')' : null;
  }

  @Expose()
  get transactionTypeDisplay() {
    return ECDD_TRANSACTION_TYPE_OPTIONS[this.transactionType]?.viewValue ?? '';
  }

  @Expose()
  get statusDescription() {
    return ECDD_STATUSES[this.status];
  }

  @Expose()
  get canShowStatusReason(): boolean {
    return [EEcddStatus.rejected, EEcddStatus.approved].includes(this.status);
  }

  @Expose()
  get canShowAMLfile(): boolean {
    return [EEcddStatus.submitted, EEcddStatus.approved, EEcddStatus.rejected, EEcddStatus.clientSigned, EEcddStatus.autoApproved].includes(this.status);
  }

  @Expose()
  get canShowStatusFile(): boolean {
    // return (!!this.metadata?.approvedFile && this.status === EEcddStatus.approved) || (!!this.metadata?.rejectedFile && this.status === EEcddStatus.rejected);
    return !!this.metadata?.approvedFile || !!this.metadata?.rejectedFile;
  }
  @Expose()
  pdfUrl!: string;

  @Expose()
  uuid!: string;

  @Expose()
  isOld!: boolean;
}
