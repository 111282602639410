import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { format, isMatch, isValid, parseISO } from 'date-fns';
import { ExceljsValueType } from './models';
import { isNumberValue } from './exceljs.utils';

@Pipe({
  name: 'cellValueFmt',
  standalone: true,
})
export class CellValueFmtPipe implements PipeTransform {
  // Create a pipe that will format a number according to the ExcelJS number format
  transform(value: any, type: ExceljsValueType, fmt: string): any {
    if (fmt === 'General') {
      return value;
    }
    if (isNumberValue(value, type)) {
      return this.transformNumber(value, fmt);
    }

    if (type === ExceljsValueType.DATE) {
      return this.transformDate(value, fmt);
    }

    // if (type === ExceljsValueType.STRING) {
    //   return this.transformString(value);
    // }

    return value;
  }
  transformNumber(value: any, fmt?: string) {
    if (fmt === '0') {
      return Number(value).toFixed(0);
    }

    if (fmt === '0.00') {
      return Number(value).toFixed(2);
    }

    if (fmt === '#,##0') {
      return Number(value).toLocaleString();
    }

    if (fmt === '#,##0.00') {
      return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    if (fmt === '0%') {
      return (Number(value) * 100).toFixed(0) + '%';
    }

    if (fmt === '0.00%') {
      return (Number(value) * 100).toFixed(2) + '%';
    }

    if (fmt === '##0.0000') {
      return Number(value).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 });
    }

    return value;
  }
  transformDate(value: any, fmt?: string) {
    if (fmt === 'dd mmm yyyy') {
      return format(new Date(value), 'dd MMM yyyy');
    }
    if (fmt === 'dd/mm/yyyy') {
      return format(new Date(value), 'dd/MM/yyyy');
    }
    return format(new Date(value), 'M/dd/yy');
  }

  // transformString(value: any): string {
  //   return value.replace(/(\\r\\n)|([\r\n])/g, '<br/>');
  // }
}
