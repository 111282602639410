export enum EEcddStatus {
  draft = 'draft',
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
  clientSigned = 'clientSigned',
  autoApproved = 'autoApproved',
}

export enum EEcddType {
  Individual = 'Individual',
  Entity = 'Entity',
}

export enum EEcddTransactionType {
  Sales = 'Sales',
  Rental = 'Rental',
}

export enum EEcddCustomerType {
  tenant = 'tenant',
  landlord = 'landlord',
  seller = 'seller',
  buyer = 'buyer',
}

export enum EEcddIdentityType {
  NRIC = 'NRIC',
  Passport = 'Passport',
  WorkPass = 'Work Pass',
  Others = 'Others',
}

export enum EEcddResponseType {
  create = 'create',
  edit = 'edit',
  proceedToChecklist = 'proceedToChecklist',
  sendUrlForCustomer = 'sendUrlForCustomer',
  customerSigned = 'customerSigned',
}
