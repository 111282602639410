import { BaseModel} from '@red/data-access';
import {  Expose } from 'class-transformer';
import { IAdditionalInformation } from '../interfaces';


export class AdditionalInformationModel extends BaseModel implements IAdditionalInformation {
  @Expose()
  id!: number;

  @Expose()
  typeId!: number;

  @Expose()
  effectiveDate!: string;

  @Expose()
  remarks!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

}
