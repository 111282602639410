import { EGstCategory } from '../enums';

export const PROJECT_GST_CATEGORIES = [
  {
    id: EGstCategory.SR,
    name: 'SR - Standard-Rated Supply',
  },
  {
    id: EGstCategory.ZR,
    name: 'ZR - Zero-Rated Supply',
  },
  {
    id: EGstCategory.OS,
    name: 'OS - Out-Of-Scope Supply',
  },
];
