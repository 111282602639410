import { EGender } from '../enums';
import { GenderRecord } from '../types';

export const GENDER_OPTIONS: GenderRecord = {
  [EGender.male]: {
    id: EGender.male,
    value: EGender.male,
    viewValue: 'M',
  },
  [EGender.female]: {
    id: EGender.female,
    value: EGender.female,
    viewValue: 'F',
  },
};
