import { BaseModel, FormattedDateString, PhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EUserAccountStatus } from '../enums';
import { IUserAccountCreate } from '../interfaces';
import { PersonalParticularAddressDto } from './personal-particular.dto';

export class UserAccountCreateDto extends BaseModel implements IUserAccountCreate {
  @Expose()
  name!: string;

  @Expose()
  email!: string;

  @Expose()
  username!: string;

  @Expose()
  status!: EUserAccountStatus;

  @Expose()
  avatar!: string;

  @Expose()
  businessUnitId!: number;

  @Expose()
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => PhoneNumber)
  office!: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  mobile!: PhoneNumber;

  @Expose()
  @Type(() => PhoneNumber)
  fax!: PhoneNumber;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  dateOfBirth!: string;

  @Expose()
  @Type(() => PersonalParticularAddressDto)
  address!: PersonalParticularAddressDto;
}

export class UserAccountUpdateDto extends UserAccountCreateDto {}
