import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { CompanyModel, CurrencyModel, ReceiptModel, ReferralExternalModel, TaxInvoiceModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MasterDataApiService {
  static ROOT_POINT = 'finance';
  static COMPANIES = 'settings/companies/list-common';
  static TEMPLATES = 'transaction/master-data/templates';
  static RECEIPTS_POINT = 'transaction/master-data/receipts';
  static REFERRAL_FORMULAS_POINT = 'transaction/master-data/referral-formulas';
  static TRANSACTION_TAX_INVOICE_POINT = 'transaction/master-data/tax-invoices';
  static REFERRAL_EXTERNALS_POINT = 'transaction/master-data/referral-externals';
  static COMPANIES_FILTER = 'settings/profile/companies';

  constructor(private apiService: ApiService) {}

  getScheduleTypes(): Observable<{ value: string }[]> {
    return this.apiService.get(MasterDataApiService.ROOT_POINT + '/schedule-types').pipe(
      map(res => {
        let array = new Array<any>();
        array = res.map((value: string) => ({ value }));
        return array;
        // return ScheduleTypesModel.fromJson(array);
      })
    );
  }

  getCurrencies(query: any = {}, option?: IApiOption): Observable<PaginationAdapter<CurrencyModel>> {
    return this.apiService.get('collection/currencies', query, option).pipe(
      map(data => {
        return new PaginationAdapter(CurrencyModel, data);
      })
    );
  }

  getReferralExternals(query: any = {}, option?: IApiOption): Observable<PaginationAdapter<ReferralExternalModel>> {
    return this.apiService.get(MasterDataApiService.REFERRAL_EXTERNALS_POINT, query, option).pipe(map(data => new PaginationAdapter(ReferralExternalModel, data)));
  }

  getReferralFormulas(query: any = {}, option?: IApiOption): Observable<any> {
    return this.apiService.get(MasterDataApiService.REFERRAL_FORMULAS_POINT, query, option);
  }

  searchTransactionTaxInvoice(query: any = {}, option?: IApiOption) {
    return this.apiService.get(MasterDataApiService.TRANSACTION_TAX_INVOICE_POINT, query, option).pipe(map(data => new PaginationAdapter(TaxInvoiceModel, data)));
  }

  getTemplates(query: any = {}, option?: IApiOption): Observable<any> {
    return this.apiService.get(MasterDataApiService.TEMPLATES, query, option);
  }

  getCompanies(query: any = {}, option?: IApiOption) {
    return this.apiService.get(MasterDataApiService.COMPANIES, query, option).pipe(map(data => new PaginationAdapter(CompanyModel, data)));
  }

  getReceipts(query: any = {}, option?: IApiOption): Observable<PaginationAdapter<ReceiptModel>> {
    return this.apiService.get(MasterDataApiService.RECEIPTS_POINT, query, option).pipe(map(data => new PaginationAdapter(ReceiptModel, data)));
  }
  getCompaniesFilter(query: any = {}, option?: IApiOption) {
    return this.apiService.get(MasterDataApiService.COMPANIES_FILTER, query, option).pipe(map(data => new PaginationAdapter(CompanyModel, data)));
  }
}
