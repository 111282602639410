import { ISplitMatrix } from './split-matrix.interface';

import { IPersonalParticular } from './personal-particular.interface';
import { ISplitPart } from './split-part.interface';
import { IReferralExternal } from './referral-external.interface';
import { IExternalCobrokeReferral } from './external-referral.interface';
import { EAgentSession } from '../enums';
export interface IProjectTransactionCobrokeReferral {
  id: number;
  absorbType: { agent: boolean; hr: boolean };
  agentId: number;
  agent: IReferralExternal;
  agentType: EAgentSession;
  salespersonId: number;
  salesperson: IPersonalParticular;
  fee: number;
  formula: string;
  type: 'internal' | 'external';
  splitMatrixId: number;
  splitMatrix: ISplitMatrix;
  receivedDirectPercent: number;
  receivedPercent: number;
  receivedValue: number;
  createdAt: string;
  updatedAt: string;
  parties: ISplitPart[];
  tiers: ISplitPart[];
  grouping: string;
}
