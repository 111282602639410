import { PhoneFax, PhoneNumber } from '@red/data-access';
import { Status } from '../enums';
import { ContactCategoryModel, LedgerAccountModel } from '../models';
import { IBase } from './base-finance.interface';
import { IContactAddress } from './contact-address.interface';
import { ISupplierBankAccount } from './supplier-bank-account.interface';

export interface IContactEmail {
  id?: number;
  email: string;
}

export interface IContactCustomerAndSupplier extends IBase {
  type: string;
  code: string;
  name: string;
  ceaRegNo?: string;
  category?: ContactCategoryModel;
  email?: string;
  emails?: IContactEmail[];
  phone: PhoneNumber;
  fax: PhoneFax;
  status: Status;
  creditTerm?: number;
  creditLimit?: number;
  receiveAccountId?: number;
  paidAccountId?: number;
  businessRegNo?: string;
  gstRegNo?: string;
  remark?: string;
  groupName?: string;
  commerceDate?: string;
  gstRegistered: boolean;
  receiveAccount?: LedgerAccountModel;
  paidAccount?: LedgerAccountModel;
  categoryId?: number;
  bankAccount?: ISupplierBankAccount;
  addresses?: IContactAddress[];
}
