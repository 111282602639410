export const FORMULA_REFERRAL = [
  {
    id: 'refFormulaA',
    name: 'REF FORMULA - A',
  },
  {
    id: 'refFormulaB',
    name: 'REF FORMULA - B',
  },
  {
    id: 'refFormulaC',
    name: 'REF FORMULA - C',
  },
  {
    id: 'refFormulaD',
    name: 'REF FORMULA - D',
  },
];
