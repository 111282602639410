import { Injectable, NgZone } from '@angular/core';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { DocumentVisibilityStateEnum } from './document-visibility.enum';

@Injectable({
  providedIn: 'root',
})
export class DocumentVisibilityService {
  eventHanlder!: any;
  private onPageVisibilityChangeSource: Subject<DocumentVisibilityStateEnum> = new Subject<DocumentVisibilityStateEnum>();
  private onPageVisibleSource: Subject<void> = new Subject<void>();
  private onPageHiddenSource: Subject<void> = new Subject<void>();

  $onPageVisibilityChange: Observable<DocumentVisibilityStateEnum> = this.onPageVisibilityChangeSource.asObservable().pipe(runInZone(this.ngZone));
  $onPageVisible: Observable<void> = this.onPageVisibleSource.asObservable().pipe(runInZone(this.ngZone));
  $onPageHidden: Observable<void> = this.onPageHiddenSource.asObservable().pipe(runInZone(this.ngZone));
  constructor(private ngZone: NgZone) {
    this.eventHanlder = this.onDocumentVisibilityChange.bind(this);
    document.addEventListener('visibilitychange', this.eventHanlder);
  }
  onDocumentVisibilityChange() {
    const afterVisibilityChangeAction = localStorage.getItem('after_visibility_change') || 'reload';
    if (afterVisibilityChangeAction !== 'reload') {
      return;
    }
    this.onPageVisibilityChangeSource.next(document.hidden ? DocumentVisibilityStateEnum.HIDDEN : DocumentVisibilityStateEnum.VISIBLE);
    if (document.hidden) {
      this.onPageHiddenSource.next();
    } else {
      this.onPageVisibleSource.next();
    }
  }
  destroy() {
    document.removeEventListener('visibilitychange', this.eventHanlder);
  }
}
export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return source => {
    return new Observable(observer => {
      const onNext = (value: T) => zone.run(() => observer.next(value));
      const onError = (e: any) => zone.run(() => observer.error(e));
      const onComplete = () => zone.run(() => observer.complete());
      return source.subscribe({ next: onNext, error: onError, complete: onComplete });
    });
  };
}
