import { BaseModel, StripComma } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class ImportPaymentItemDto extends BaseModel {
  @Expose()
  @Type(() => String)
  paymentNumber?: string;

  @Expose()
  @Type(() => String)
  paymentDate?: string;

  @Expose()
  @Type(() => String)
  type?: string;

  @Expose()
  @Type(() => String)
  contactCode?: string;

  @Expose()
  @Type(() => String)
  payerName?: string;

  @Expose()
  @Type(() => String)
  chequeNumber?: string;

  @Expose()
  @Type(() => String)
  itemNo?: string;

  @Expose()
  @Type(() => String)
  itemType?: string;

  @Expose()
  @Type(() => String)
  description?: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  itemAmount?: string;

  @Expose()
  @Type(() => String)
  itemCreditAccount?: string;

  @Expose()
  @Type(() => String)
  bankAccount?: string;

  @Expose()
  @Type(() => String)
  profitCentre?: string;

  @Expose()
  @Type(() => String)
  currencySign?: string;

  @Expose()
  @Type(() => String)
  @StripComma()
  currencyRate?: string;

  @Expose()
  @Type(() => String)
  glEntry?: string;

  @Expose()
  @Type(() => String)
  recordId?: string;

  @Expose()
  @Type(() => String)
  invoiceItemNo?: string;
}

export class ImportPaymentDto extends BaseModel {
  @Expose()
  @Type(() => ImportPaymentItemDto)
  items?: ImportPaymentItemDto[];
}
