import { EUnitNoOfRoom } from '../enums';
import { IProject } from './project.interface';

export interface IUnit {
  id: number;
  projectId: number;
  project: IProject;
  unitNo: string;
  blkNo: string;
  unitType: string;
  noOfRoom: EUnitNoOfRoom;
  areaSqft: number;
  areaSqm: number;
  sellingPrice: number;
  psf: string;
  bookingFee: number;
  postalCode: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUnitImportErrorMetadata {
  data: IUnit;
  errorCode?: string;
  rowNumber?: number;
}

export interface IUnitImportResponse {
  status: string;
  numberOfImport: number;
  errors: IUnitImportErrorMetadata[];
}
