import { ModuleWithProviders, NgModule } from '@angular/core';
import { LayoutConfigService } from './layout-config.service';
import { RED_LAYOUT_CONFIG } from './layout-config.token';

@NgModule()
export class RedLayoutConfigModule {
  /**
     * Constructor
     */
   constructor(private configService: LayoutConfigService)
   {
   }

   /**
    * forRoot method for setting user configuration
    *
    * @param config
    */
   static forRoot(config: any): ModuleWithProviders<RedLayoutConfigModule>
   {
       return {
           ngModule : RedLayoutConfigModule,
           providers: [
               {
                   provide : RED_LAYOUT_CONFIG,
                   useValue: config
               }
           ]
       };
   }
}
