import { BaseModel, FormattedDateString, PhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { isEmpty, isNil, omitBy } from 'lodash-es';
import { ECitizen, EGender, EIndustryExam } from '../enums';
import { IRecruitmentApplication, IRecruitmentApplicationCreateDto, IRecruitmentMetadata } from '../interfaces';
import { PersonalParticularAddressModel } from '../models';

export class RecruitmentApplicationCreateDto extends BaseModel implements IRecruitmentApplicationCreateDto {
  @Expose()
  nricfin!: string;

  @Expose()
  nricName!: string;

  @Expose()
  businessName!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  dateOfBirth!: string;

  @Expose()
  gender!: EGender;

  @Expose()
  email1!: string;

  @Expose()
  ceaRegNo!: string;

  @Expose()
  citizen!: ECitizen;

  @Expose()
  @Type(() => Number)
  maritalId!: number;

  @Expose()
  @Type(() => PhoneNumber)
  mobile1!: PhoneNumber;

  @Expose()
  @Type(() => Number)
  nationalityId!: number;

  @Expose()
  @Type(() => Number)
  bankId!: number;

  @Expose()
  bankAccountNo!: string;

  @Expose()
  bankName!: string;

  @Expose()
  @Type(() => Number)
  qualificationId!: number;

  @Expose()
  industryExam!: EIndustryExam;

  @Expose()
  hanyuPinyinName!: string;

  @Expose()
  website!: string;

  @Expose()
  @Type(() => PersonalParticularAddressModel)
  address!: PersonalParticularAddressModel;

  @Expose()
  postal!: string;

  @Expose()
  signature!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  applicantAt!: string;

  @Expose()
  @Transform(({ obj }: { obj: IRecruitmentApplication }) => {
    const metadata = {
      industryOther: !isEmpty(obj.industryOther) ? obj.industryOther : null,
      qualifyOther: !isEmpty(obj.qualifyOther) ? obj.qualifyOther : null,
    };

    return omitBy(metadata, isNil);
  })
  metadata!: IRecruitmentMetadata;
}
