export enum InvoiceCreateMode {
  MANUAL = 'MANUAL',
  BATCH = 'BATCH',
  RESALE = 'RESALE',
  RESALE_ECB = 'RESALE_ECB',
}

export enum SupplierInvoiceType {
  NonPO = 'Non-PO',
}

export enum GstCategoryCustomer {
  ZR = 'ZR - Zero-Rated Supply',
  SR = 'SR - Standard-Rated Supply',
  ES33 = 'ES33 - Exempt Supply',
  DS = 'DS - Deemed Supply',
  ESN33 = 'ESN33 - Non-Exempt Supply',
  OS = 'OS - Out-Of-Scope Supply',
}

export enum GstCategorySupplier {
  NR = 'NR - Purchase From Non-Registered Supplier',
  TX7 = 'TX7 - Standard-Rated-Purchase',
  TX8 = 'TX8 - Standard-Rated Purchase',
  EP = 'EP - Exempt Purchase',
  BL = 'BL - Non-Claimable Purchase',
  ZP = 'ZP - Zero-Rated Purchase',
  IM = 'IM - Import Of Goods Purchase',
  ME = 'ME - Import Under Special Scheme Purchase',
  TXE33 = 'TX-E33 - Exempt Supplies Purchase',
  OP = 'OP - Out-Of-Scope Purchase',
  TXN33 = 'TX-N33 - Non-Exempt Supplies Purchase',
  TXRE = 'TX-RE - Non-Taxable/Exempt Supplies Purchase',
}

export enum InvoicePaidStatus {
  NOT_PAID = 'NOT_PAID',
  NOT_FULL_PAID = 'NOT_FULL_PAID',
  FULLY_PAID = 'FULL_PAD',
  CREDITED = 'CREDITED',
}

export const InvoicePaidStatusLabel = {
  [InvoicePaidStatus.CREDITED]: 'Credited',
  [InvoicePaidStatus.FULLY_PAID]: 'Fully Paid',
};

export enum InvoicePostingType {
  FROM_DETAIL = 'FROM_DETAIL',
  GST = 'GST',
  TRADE_RECEIVABLE = 'TRADE_RECEIVABLE',
  AP = 'AP',
  AR = 'AR',
}

export enum Currency {
  SGD = 'SGD',
}

export enum CustomerOrSupplierType {
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER',
}

export enum CreditNoteStatus {
  CANCELLED = 'Cancelled',
  ABORTED = 'Aborted',
  PARTIAL = 'Partial Credit Note',
}
