import { InjectionToken, Provider } from '@angular/core';
export const PERMISSION_PREFIX = new InjectionToken<string[]>('PERMISSION_PREFIX');
export const PERMISSION_ROUTER_REDIRECT_COMMAND = new InjectionToken<string[]>('PERMISSION_ROUTER_REDIRECT_COMMAND');

export function providePermissionPrefix(prefix?: string[]): Provider[] {
  return [{ provide: PERMISSION_PREFIX, useValue: prefix }];
}

export function providePermissionRedirectCommand(command?: string[]): Provider[] {
  return [{ provide: PERMISSION_ROUTER_REDIRECT_COMMAND, useValue: command }];
}
