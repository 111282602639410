import { EPaymentDueDate } from '../enums';
import { PaymentDueDateRecord } from '../types';

export const PAYMENT_DUE_DATE_OPTIONS: PaymentDueDateRecord = {
  [EPaymentDueDate['30days']]: {
    id: EPaymentDueDate['30days'],
    value: EPaymentDueDate['30days'],
    viewValue: '30 days',
  },
  [EPaymentDueDate['60days']]: {
    id: EPaymentDueDate['60days'],
    value: EPaymentDueDate['60days'],
    viewValue: '60 days',
  },
  [EPaymentDueDate['90days']]: {
    id: EPaymentDueDate['90days'],
    value: EPaymentDueDate['90days'],
    viewValue: '90 days',
  },
};
