import { Component, Inject, Injector, OnInit, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  EXCELJS_TABLE_COMPARE_WITH_FN,
  EXCELJS_TABLE_INITIALLY_SELECTED,
  EXCELJS_TABLE_PARAMETER_DEFAULT_VALUE,
  EXCELJS_TABLE_SELECTION_MODE,
  EXCELJS_TABLE_TEMPLATE_REF,
  ExceljsTableSelectionComponent,
  ExceljsTableSelectionMode,
} from './exceljs-table-selection/exceljs-table-selection.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ExceljsParameterTemplateRef, ExceljsParameterTemplateRefOnChangeObject } from '../models';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EXCELJS_HTTP_LOADER } from '../exceljs-http-loader.service';
import { clone } from 'lodash';

@Component({
  selector: 'red-exceljs-table-search-dialog',
  standalone: true,
  imports: [CommonModule, ExceljsTableSelectionComponent, MatDialogModule, MatIconModule, MatButtonModule],
  templateUrl: './exceljs-table-search-dialog.component.html',
  styleUrls: ['./exceljs-table-search-dialog.component.scss'],
})
export class ExceljsTableSearchDialogComponent implements OnInit {
  instanceComponent!: ExceljsTableSelectionComponent;
  @ViewChild('container', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  mode!: ExceljsTableSelectionMode;
  selected!: ExceljsParameterTemplateRefOnChangeObject[];
  private apiService = inject(EXCELJS_HTTP_LOADER);
  constructor(
    private _dialogRef: MatDialogRef<ExceljsTableSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectionMode?: ExceljsTableSelectionMode;
      selected?: ExceljsParameterTemplateRefOnChangeObject[];
      template: ExceljsParameterTemplateRef;
      compareWithFn: (o1: any, o2: any) => boolean;
    }
  ) {
    this.mode = this.data?.selectionMode || 'single';
    this.selected = clone(this.data?.selected || []);
    console.log('selected --> ', this.selected)
  }
  ngOnInit(): void {
    const { instance } = this.container.createComponent<ExceljsTableSelectionComponent>(ExceljsTableSelectionComponent, {
      injector: Injector.create({
        providers: [
          { provide: EXCELJS_TABLE_SELECTION_MODE, useValue: this.mode },
          { provide: EXCELJS_TABLE_INITIALLY_SELECTED, useValue: this.selected },
          { provide: EXCELJS_TABLE_COMPARE_WITH_FN, useValue: this.data.compareWithFn.bind(this) },
          { provide: EXCELJS_TABLE_TEMPLATE_REF, useValue: this.data.template },
          { provide: EXCELJS_TABLE_PARAMETER_DEFAULT_VALUE, useValue: {} }
        ],
      }),
    });
    this.instanceComponent = instance;
  }

  cancel() {
    this._dialogRef.close();
  }
  save() {
    const selected = this.instanceComponent.selectionModel.selected;
    const currentFilters = this.instanceComponent.getCurrentFilters()
    // const parameters = this.instanceComponent.
    this._dialogRef.close({ selected: this.mode === 'multi' ? selected : selected[0], currentFilters });
  }
}
