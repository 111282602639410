// core
import { Expose, Type } from 'class-transformer';

// share
import { IPackage, IPackageOption } from '../interfaces';
import { BaseModel } from '@red/data-access';
import { ERecruitmentType } from '../enums';

export class PackageOptionModel extends BaseModel implements IPackageOption {
  @Expose()
  id!: number;
  @Expose()
  name!: string;
  @Expose()
  applicantType!: ERecruitmentType;
  @Expose()
  html!: string;
  @Expose()
  acknowledge!: string;
  @Expose()
  undertaking!: string;
}

export class PackageModel extends BaseModel implements IPackage {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  html!: string;

  @Expose()
  description!: string;

  @Expose()
  options!: PackageOptionModel[];

  //#region getter
  get packageInfo() {
    return this.options && this.options.length ? this.options[0] : null;
  }
  //#endregion
}
