import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { AccountSettingType } from '../enums';

export class AccountSetupCreateDto extends BaseModel {
  @Expose()
  type!: AccountSettingType;

  @Expose()
  accountIds!: number[];
}

export class AccountSetupUpdateDto extends BaseModel {
  @Expose()
  accountId!: number;
}

export class AccountSetupUpdateCurrencyDto extends BaseModel {
  @Expose()
  currency!: string;
}
