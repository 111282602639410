import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { Currency } from '../enums';

export class JournalPostingCreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId!: number;

  @Expose()
  @Default(Currency.SGD)
  currency!: string;

  @Expose()
  @Type(() => Number)
  debit!: number;

  @Expose()
  @Type(() => Number)
  credit!: number;
}
