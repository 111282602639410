import { Directive, Injectable } from '@angular/core';
import { MetabaseApiService, MetabaseCardCollection } from './metabase-report-api.service';
import { ExceljsApiService, ExceljsCardCollection } from './exceljs-report-api.service';
import { ApiService, IApiOption } from '@red/api';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { Observable, map } from 'rxjs';
import { PaginationAdapter } from '@red/data-access';
import { ReportCard } from '@shared/components/report-manager';
import { Mixin } from 'ts-mixer';
@Directive()
abstract class BankReportMetabaseApiService extends MetabaseApiService {
  collection: MetabaseCardCollection = 'bank_reports';
}

@Directive()
abstract class BankReportExceljsApiService extends ExceljsApiService {
  collectionEndpoint = ExceljsCardCollection.BankReport;
}
@Directive()
abstract class BankReportCardApiService {
  static REPORT_CARD_POINT = 'finance/banking/reports';
  constructor(protected apiService: ApiService) {}

  findReportCards(query: IQuerySearch = {}, option?: IApiOption): Observable<PaginationAdapter<ReportCard>> {
    return this.apiService.get(BankReportCardApiService.REPORT_CARD_POINT, query, option).pipe(map(data => new PaginationAdapter(ReportCard, data)));
  }
}

@Injectable({
  providedIn: 'root',
})
export class BankReportApiService extends Mixin(BankReportCardApiService, BankReportMetabaseApiService, BankReportExceljsApiService) {}
