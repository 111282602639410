import { IBase } from './base-finance.interface';

export interface IContactCategory extends IBase {
  code: string;
  name: string;
  isActive: boolean;
  description?: string;
  categoryRole?: string;
  categoryId?: number;
}
