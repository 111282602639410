import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { format } from 'date-fns';
import { isMoment } from 'moment';
import { ExceljsParameter, IExceljsParameterControlType } from '../../models';
import { ExceljsParameterFieldBase } from '../exceljs-parameter-field-base.directive';
import { MAT_DATE_FORMATS } from '@angular/material/core';
export const MONTH_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'red-exceljs-parameter-month',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, MatFormFieldModule, MatInputModule],
  templateUrl: './exceljs-parameter-month.component.html',
  styleUrls: ['./exceljs-parameter-month.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS }],
})
export class ExceljsParameterMonthComponent extends ExceljsParameterFieldBase {
  toDataDowncastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    if (isMoment(val)) {
      return val.format('YYYY-MM-DD');
    }
    if (val instanceof Date) {
      return format(val, 'yyyy-MM-dd');
    }
    return val;
  }
  toDataUpcastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return val;
  }

  get controlType(): IExceljsParameterControlType {
    return 'month/single';
  }

  setMonthAndYear(normalizedMonthAndYear: any, datepicker: MatDatepicker<any>) {
    const ctrlValue = typeof this.dataUpcast.value! === 'string' ? new Date(this.dataUpcast.value) : this.dataUpcast.value;
    console.log('ctrlValue', ctrlValue);
    if (isMoment(ctrlValue)) {
      ctrlValue.date(1);
      ctrlValue.month(normalizedMonthAndYear.month());
      ctrlValue.year(normalizedMonthAndYear.year());
    } else if (ctrlValue instanceof Date) {
      ctrlValue.setDate(1);
      ctrlValue.setMonth(normalizedMonthAndYear.month());
      ctrlValue.setFullYear(normalizedMonthAndYear.year());
    }

    this.dataUpcast.setValue(ctrlValue);
    console.log('normalizedMonthAndYear', normalizedMonthAndYear);
    datepicker.close();
  }
}
