import { ClassProvider, Injectable, InjectionToken, Provider, Type } from '@angular/core';
import { LoginDto, LoginResponseDto, RefreshTokenResponseDto } from '@shared/data-access/dto';
import { Observable } from 'rxjs';
export const AUTH_API_LOADER = new InjectionToken<AuthApiLoader>('AUTH_API_LOADER');

@Injectable()
export abstract class AuthApiLoader {
  private authenticated = false;
  /**
   * Sign in
   *
   * @param credentials
   */
  abstract signIn(credentials: LoginDto): Observable<LoginResponseDto>;
  /**
   * Refresh token
   *
   * @param credentials
   */
  abstract refreshToken(refreshToken: string): Observable<RefreshTokenResponseDto>;
}

export const useUathApiLoader = (instance: Type<AuthApiLoader>): ClassProvider => ({
  provide: AUTH_API_LOADER,
  useClass: instance,
  multi: false,
});
