import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { IPersonalParticular } from '@shared/data-access/interfaces';
import { PersonalParticularModel } from '@shared/data-access/models';

@Injectable({
  providedIn: 'root',
})
export class PersonalParticularApiService {
  static SALESPERSONS = 'hrm/salespersons';
  static FAMILYTREE_POINT = 'hrm/salespersons/portal/descendants';

  constructor(protected _apiService: ApiService) {}

  create(data: Partial<IPersonalParticular>): Observable<PersonalParticularModel> {
    return this._apiService
      .post(PersonalParticularApiService.SALESPERSONS, data, { removeEmpty: { enable: true } })
      .pipe(map((res: IPersonalParticular) => PersonalParticularModel.fromJson(res) as PersonalParticularModel));
  }

  update(id: number, data: Partial<IPersonalParticular>): Observable<unknown> {
    return this._apiService.patch(PersonalParticularApiService.SALESPERSONS + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this._apiService.get(PersonalParticularApiService.SALESPERSONS, query, option).pipe(map(data => new PaginationAdapter(PersonalParticularModel, data)));
  }

  delete(itemIds: number[], option?: IApiOption): Observable<unknown> {
    return this._apiService.delete(PersonalParticularApiService.SALESPERSONS, { salespersonIds: itemIds }, option);
  }

  get(itemId: number): Observable<PersonalParticularModel> {
    return this._apiService
      .get(PersonalParticularApiService.SALESPERSONS + '/' + itemId)
      .pipe(map(res => PersonalParticularModel.fromJson(res) as PersonalParticularModel));
  }

  getHead(id: number) {
    return this._apiService.get(PersonalParticularApiService.SALESPERSONS + '/' + id + '/count-member');
  }

  getHeadDivision(id: number) {
    return this._apiService.get(PersonalParticularApiService.SALESPERSONS + '/' + id + '/head-of-team');
  }

  familyTree(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this._apiService
      .get(PersonalParticularApiService.FAMILYTREE_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(PersonalParticularModel, { meta: {}, items: data })));
  }
}
