import {BaseModel, Default} from "@red/data-access";
import {Expose, Type} from "class-transformer";

export class IdTypeCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  identification!: string;

  @Expose()
  @Default("")
  iras!: string;

  @Expose()
  name!: string;

  // @Expose()
  // status!: string;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!:boolean;
}
