export const PROJECT_STATUS = [
  {
    id:'active',
    name:'Active'
  },
  {
    id:'inactive',
    name:'Inactive'
  },
  {
    id:'fullySold',
    name:'Fully Sold'
  }
] as const;
