import { IAppointmentType } from './appointment-type.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IProjectConsultant } from './project-consultant.interface';
import { IUpdatedBy } from './updated-by.interface';

export interface IProjectConsultantMember {
  id: number;
  name: string;
  teamId: number;
  team: IProjectConsultant;
  salespersonId: number;
  salesperson: IPersonalParticular;
  isLeader: boolean;
  appointmentTypeId: number;
  appointmentType: IAppointmentType;
  status: string;
  createdAt: string;
  updatedAt: string;
  updatedBy?: IUpdatedBy;
  appTypeCode?: string;
}
