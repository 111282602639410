import { BaseModel, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { AbstractListFilters } from '@shared/core';
import { Expose } from 'class-transformer';

export class KeyAppointmentListCreateDto extends BaseModel {
  @Expose()
  name!: string;
}

export class KeyAppointmentListUpdateDto extends BaseModel {
  @Expose()
  name!: string;
}

export class KeyAppointmentListFilters extends AbstractListFilters {
  @Expose()
  isMain!: boolean;

  @Expose()
  searchType!: string;

  @Expose()
  projectName!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  startDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  startDateEnd!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  endDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  endDateEnd!: string;

  @Expose()
  totalAppointees!: number;

  @Expose()
  mainIc!: string;

  @Expose()
  ic!: string;

  @Expose()
  projectStatus!: string;

  @Expose()
  remarks!: string;
}
