import { IPhoneNumber } from '@red/data-access';
import { ERecruitmentDivision, ERecruitmentStatus, ERecruitmentType } from '../enums';
import { ICommSchemeGeneric } from './commission-scheme.interface';
import { IPackage } from './package.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IRecruitmentApplication, IRecruitmentApplicationCreateDto } from './recruitment-application.interface';
import { IUpdatedBy } from './updated-by.interface';

export interface IRecruitment extends IRecruitmentApplication {
  id: number;
  applicantType: ERecruitmentType;
  applicantName: string;
  applicantMobile: IPhoneNumber;
  status: ERecruitmentStatus;
  division: ERecruitmentDivision;
  isParent: boolean;
  parent: IPersonalParticular;
  parentId: number;
  parentName: string;
  parentEmail: string;
  parentCeaRegNo: string;
  isRecruiter: boolean;
  recruiter: IPersonalParticular;
  recruiterId: number;
  recruiterName: string;
  recruiterEmail: string;
  recruiterCeaRegNo: string;
  package: IPackage;
  packageId: number;
  designation: ICommSchemeGeneric;
  designationId: number;
  protectionTemplateId: number;
  authorisationTemplateId: number;
  agreementTemplateId: number;
  joinOption: string;
  remarks: string;
  salespersonId: number;

  applicantAt: string;
  recruiterAt: string;
  submittedAt: string;
  reviewedAt: string;
  reviewedBy: IPersonalParticular;
  registeredAt: string;
  registeredBy: IPersonalParticular;

  //#region timestamp
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: IUpdatedBy;
  updatedBy: IUpdatedBy;
  deletedBy: IUpdatedBy;
  //#endregion
}

export interface IRecruitmentCreateDto {
  applicantType: ERecruitmentType;
  applicantName: string;
  applicantMobile: IPhoneNumber;
  division: string;
  isParent: boolean;
  parentId: number;
  parentName: string;
  parentEmail: string;
  parentCeaRegNo: string;
  isRecruiter: boolean;
  recruiterId: number;
  recruiterName: string;
  recruiterEmail: string;
  recruiterCeaRegNo: string;
  packageId: number;
  designationId: number;
  joinOption: string;
}

export interface IrecruitmentUpdateDto extends IRecruitmentCreateDto, IRecruitmentApplicationCreateDto {
  remarks: string;
}

export interface IRecruitmentActionDto {
  id: number;
  ceaRegNo?: string;
  joinDate?: string;
  metadata: {
    reason?: string;
  };
}

export interface IRecruitmentCheckDuplicatedDto {
  ceaRegNo?: string;
  email1: string;
}
