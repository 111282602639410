export interface IEducationRecord {
  id: number;
  qualificationId: number;
  disciplineId: number;
  countryId: number;
  school: string;
  achievement: string;
  highestQualification: boolean;
  remarks: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
}
