import {
  IBlankFormBd,
  IBlankFormBuyer,
  IBlankFormBuyerInfo,
  IBlankFormIc,
  IBlankFormOtherFee,
  IBlankFormPd,
  IBlankFormReferral,
  IBlankFormRelatedAgent,
  ICurrencyRate,
  IGrossCommissionEarned,
  IProject,
  IProjectTransaction,
  ITaxInvoice,
  IUnit,
} from '.';
import { EAgentRole, EBlankFormStatus, EBlankFormTypeDetail, EProjectPropertyType } from '../enums';
import { KeysOfBlankFormHaveAgent } from '../types';
import { IUpdatedBy } from './updated-by.interface';

export interface IBlankForm {
  address: string;
  code: string;
  createdAt: string;
  createdBy: string;
  deletedAt: string;
  deletedBy: string;
  buyer: IBlankFormBuyerInfo;
  buyers: IBlankFormBuyer[];
  grossComm: IGrossCommissionEarned;
  hiGrossComm?: IGrossCommissionEarned;
  devGrossComm?: IGrossCommissionEarned;
  id: number;
  optionDate: string;
  optionNo: string;
  projectId: number;
  project?: IProject;
  invoiceId?: number;
  invoice?: ITaxInvoice;
  invoiceCode?: string;
  relatedAgent: IBlankFormRelatedAgent;
  bonuses: IBlankFormIc[];
  overridings: IBlankFormIc[];
  pools: IBlankFormIc[];
  pds: IBlankFormPd[];
  pdOverride: IBlankFormPd[];
  pdReferral: IBlankFormPd[];
  pdManagement: IBlankFormPd[];
  referrals: IBlankFormReferral[];
  referralsExternal: IBlankFormReferral[];
  referralsInternal: IBlankFormReferral[];
  otherFees: IBlankFormOtherFee[];
  bds: IBlankFormBd[];
  companyProfit: number;
  remarks: string;
  status: EBlankFormStatus;
  transactedPrice: number;
  originalTransactedPrice?: number;
  originalCurrencyId?: number;
  originalCurrency?: ICurrencyRate;
  currencyCode?: string;
  originalCurrencyCode?: string;
  transactionId?: number;
  transaction?: IProjectTransaction;
  blankFormId?: number;
  blankForm?: IBlankForm;
  unitId?: number;
  unit?: IUnit;
  updatedAt: string;
  updatedBy: IUpdatedBy;
  blankFormType: EBlankFormTypeDetail;
  getIdsPersonExisting: (agentType: KeysOfBlankFormHaveAgent) => number[];
  canSubmit: boolean;
  canEdit: boolean;
  rejectReason: string | null;
  hiProfitAmount: number;
  role: EAgentRole;
  isReissue?: boolean;
  reissueDate?: string;
  propertyType: EProjectPropertyType;
  migrationId?: number;
  internationalRef?: string;
}
