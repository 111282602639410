import { Injectable } from "@angular/core";
import { ApiService, IApiOption } from "@red/api";
import { PaginationAdapter } from "@red/data-access";
import { OverridingDistributionCreateDto, OverridingDistributionUpdateDto } from "@shared/data-access/dto";
import { IOverridingDistribution } from "@shared/data-access/interfaces";
import { OverridingDistributionModel } from "@shared/data-access/models";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OverridingDistributionApiService {
  static ROOT_POINT = 'transaction/overriding-distributions';

  constructor(private apiService: ApiService) { }

  create(data: OverridingDistributionCreateDto): Observable<OverridingDistributionModel> {
    return this.apiService
        .post(`${OverridingDistributionApiService.ROOT_POINT}`, data)
        .pipe(
            map(
                (res: IOverridingDistribution) => OverridingDistributionModel.fromJson(res) as OverridingDistributionModel
            )
        );
  }
  get(itemId: number): Observable<OverridingDistributionModel> {
    return this.apiService
        .get(OverridingDistributionApiService.ROOT_POINT + '/' + itemId)
        .pipe(map(res => OverridingDistributionModel.fromJson(res) as OverridingDistributionModel));
  }
  update(id: number, data: OverridingDistributionUpdateDto): Observable<any> {
      return this.apiService.patch(OverridingDistributionApiService.ROOT_POINT + '/' + id, data,{excludeFields:['']});
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<OverridingDistributionModel>> {
      return this.apiService
          .get(OverridingDistributionApiService.ROOT_POINT, query, option)
          .pipe(map(data => new PaginationAdapter(OverridingDistributionModel,data)));
  }


  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(
      OverridingDistributionApiService.ROOT_POINT, {ids}, option
    );
  }

  updateStatus(id: number, status: string): Observable<any> {
    return this.apiService.patch(OverridingDistributionApiService.ROOT_POINT + '/' + id, {status});
  }

}

