export enum EBuyerType {
  Individual = 'Individual',
  Company = 'Company',
  Trust = 'Trust',
}

export enum EBuyerPropertyType {
  // transaction and buyer
  ResidentialCondominiumApartment = 'ResidentialCondominiumApartment',
  ResidentialExecutiveCondominium = 'ResidentialExecutiveCondominium',
  LandedTerraceHouse = 'LandedTerraceHouse',
  LandedSemiDetachedHouse = 'LandedSemiDetachedHouse',
  LandedDetachedHouse = 'LandedDetachedHouse',
  LandedStrataLanded = 'LandedStrataLanded',

  // // transaction
  // CommercialOffice = 'CommercialOffice',
  // CommercialShophouse = 'CommercialShophouse',
  // CommercialRetail = 'CommercialRetail',
  // IndustrialWarehouse = 'IndustrialWarehouse',
  // IndustrialBusinessHub = 'IndustrialBusinessHub',
  // IndustrialSingleUserFactory = 'IndustrialSingleUserFactory',
  // IndustrialMultipleUserFactory = 'IndustrialMultipleUserFactory',
  // Retail = 'Retail',

  Others = 'Others',
}
