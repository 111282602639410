import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import {
  CareerProgressionCreateDto,
  CareerProgressionUpdateDto
} from '@shared/data-access/dto';
import { ICarrerProgression } from '@shared/data-access/interfaces';
import { CareerProgressionModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CareerProgressionInfoApiService {
  static CAREER_PROGRESSION = 'hrm/career-progressions';

  constructor(private _apiService: ApiService) {}

  create(salespersonId: number, data: CareerProgressionCreateDto) {
    return this._apiService.post(
      CareerProgressionInfoApiService.CAREER_PROGRESSION + '/' + salespersonId,
      data
    );
  }

  update(id: number, data: CareerProgressionUpdateDto) {
    return this._apiService.patch(
      CareerProgressionInfoApiService.CAREER_PROGRESSION + '/' + id,
      data
    );
  }

  getAll(
    salespersonId: number,
    query = {},
    option?: IApiOption
  ): Observable<CareerProgressionModel[]> {
    return this._apiService
      .get(
        `${CareerProgressionInfoApiService.CAREER_PROGRESSION}/${salespersonId}`,
        query,
        option
      )
      .pipe(
        map((data: ICarrerProgression[]) =>
          data.map(item => CareerProgressionModel.fromJson(item))
        )
      );
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this._apiService.delete(
      CareerProgressionInfoApiService.CAREER_PROGRESSION + '/' + id,
      {},
      option
    );
  }
}
