import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { UserGroupCreateDto, UserGroupUpdateDto } from '@shared/data-access/dto';
import { IUserGroup } from '@shared/data-access/interfaces';
import { UserGroupModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserGroupApiService {
  static ROOT_POINT = 'settings/groups';

  constructor(private apiService: ApiService) {}

  create(data: UserGroupCreateDto): Observable<UserGroupModel> {
    return this.apiService.post(`${UserGroupApiService.ROOT_POINT}`, data).pipe(map((res: IUserGroup) => UserGroupModel.fromJson(res) as UserGroupModel));
  }
  get(itemId: number): Observable<UserGroupModel> {
    return this.apiService.get(UserGroupApiService.ROOT_POINT + '/' + itemId).pipe(map(res => UserGroupModel.fromJson(res) as UserGroupModel));
  }
  update(id: number, data: UserGroupUpdateDto): Observable<any> {
    return this.apiService.patch(UserGroupApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<UserGroupModel>> {
    return this.apiService.get(UserGroupApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(UserGroupModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(UserGroupApiService.ROOT_POINT, { ids }, option);
  }

  addUsers(id: number, userIds: number[]): Observable<unknown> {
    return this.apiService.patch(UserGroupApiService.ROOT_POINT + '/' + id + '/users', { userIds }, { excludeFields: [''] });
  }

  addRoles(id: number, roleIds: number[]): Observable<unknown> {
    return this.apiService.patch(UserGroupApiService.ROOT_POINT + '/' + id + '/roles', { roleIds }, { excludeFields: [''] });
  }
}
