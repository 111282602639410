import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { ICompany, ICompanyContact } from '../interfaces';

export class CompanyModel extends BaseModel implements ICompany {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  type!: string;

  @Expose()
  status!: string;

  @Expose()
  address!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  licenceNo!: string;

  @Expose()
  setup!: {
    contactId: number;
    licenceNo: string;
  };

  @Expose()
  isDefault!: boolean;

  @Expose()
  contacts!: ICompanyContact[];
}
