import { BaseModel, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class JournalEntryPostingDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  @Type(() => Number)
  journalId!: number;

  @Expose()
  @Type(() => Number)
  order!: number;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => Number)
  profitCenterId!: number;

  @Expose()
  description?: string;

  @Expose()
  @Type(() => Number)
  debit!: number;

  @Expose()
  @Type(() => Number)
  credit!: number;

  @Expose()
  @Type(() => Number)
  rate!: number;

  @Expose()
  currency!: string;

  @Expose()
  index?: number;
}

export class JournalEntryCreateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  reversal!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  journalDate?: string;

  @Expose()
  scheduleEnable?: boolean;

  @Expose()
  scheduleName?: string;

  @Expose()
  scheduleType?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  scheduleStartDate?: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  scheduleEndDate?: string;

  @Expose()
  @Type(() => JournalEntryPostingDto)
  postings?: JournalEntryPostingDto[];
}
