import {BaseModel, Default} from "@red/data-access";
import {Expose, Type} from "class-transformer";

export class DialectCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!:boolean;
}
