// core
import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';

// share
import { CommissionLeadershipSchemeModel, CommissionSalespersonSchemeModel } from '@shared/data-access/models';
import { PaginationAdapter } from '@red/data-access';
import { ApiService, IApiOption } from '@red/api';

@Injectable({
  providedIn: 'root',
})
export class CommissionSchemeApiService {
  static SALESPERSON_SCHEME = 'hrm/commission-schemes/portal/salesperson';
  static LEADERSHIP_SCHEME = 'hrm/commission-schemes/portal/leadership';

  constructor(private apiService: ApiService) {}

  //#region salesperson
  searchSalespersonScheme(query = {}, option?: IApiOption): Observable<PaginationAdapter<CommissionSalespersonSchemeModel>> {
    return this.apiService
      .get(CommissionSchemeApiService.SALESPERSON_SCHEME, query, option)
      .pipe(map(data => new PaginationAdapter(CommissionSalespersonSchemeModel, data)));
  }

  getSalespersonScheme(id: number): Observable<CommissionSalespersonSchemeModel> {
    return this.apiService
      .get(CommissionSchemeApiService.SALESPERSON_SCHEME + '/' + id)
      .pipe(map(res => CommissionSalespersonSchemeModel.fromJson(res) as CommissionSalespersonSchemeModel));
  }
  //#endregion

  //#region leadership
  searchLeadershipScheme(query = {}, option?: IApiOption): Observable<PaginationAdapter<CommissionLeadershipSchemeModel>> {
    return this.apiService
      .get(CommissionSchemeApiService.LEADERSHIP_SCHEME, query, option)
      .pipe(map(data => new PaginationAdapter(CommissionLeadershipSchemeModel, data)));
  }
  //#endregion
}
