import { BaseModel, Default, EndOfDate, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
export class PaymentGeneratorMethodData extends BaseModel {
  @Expose()
  paymentMode!: string;

  @Expose()
  bankAccountId!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  submissionDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  valueDate!: string;

  @Expose()
  accountId!: number;

  @Expose()
  description!: string;

  @Expose()
  chequeNumberFrom!: string;
}

export class PaymentGeneratorCreateDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  receiptDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  receiptDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  paymentDate!: string;

  @Expose()
  @Default(false)
  includeAdjustment!: boolean;

  @Expose()
  @Default(false)
  includeMiscCreditNote!: boolean;

  @Expose()
  @Default(false)
  payProfitSharingonly!: boolean;

  @Expose()
  @Default(false)
  payNormalCommissionOnly!: boolean;

  @Expose()
  @Default(true)
  excludePartialReceipt!: boolean;

  @Expose()
  @Default(false)
  includeMiscLinkAr!: boolean;

  @Expose()
  @Default(false)
  autoRunDataCompute!: boolean;

  @Expose()
  @Default(false)
  profitSharingCommmission!: boolean;

  @Expose()
  excludeSupplierInvoiceIds!: number[];

  @Expose()
  @Default([])
  excludePayeeIds!: number[];

  @Expose()
  @Default([])
  excludeTransactionCodes!: string[];

  @Expose()
  @Default([])
  includeTransactionCodes!: number[];

  @Expose()
  @Default([])
  includeTaxInvoiceIds!: number[];

  @Expose()
  @Default([])
  excludeTaxInvoiceIds!: number[];

  @Expose()
  @Default([])
  excludeSupplierCreditNoteIds!: number[];

  @Expose()
  filterMetaData!: any;

  @Expose()
  @Type(() => PaymentGeneratorMethodData)
  paymentMethodData!: PaymentGeneratorMethodData;
}

export class PaymentGeneratorUpdateDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  receiptDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  receiptDateTo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  paymentDate!: string;

  @Expose()
  @Default(false)
  includeAdjustment!: boolean;

  @Expose()
  @Default(false)
  includeMiscCreditNote!: boolean;

  @Expose()
  @Default(false)
  payProfitSharingonly!: boolean;

  @Expose()
  @Default(false)
  payNormalCommissionOnly!: boolean;

  @Expose()
  @Default(true)
  excludePartialReceipt!: boolean;

  @Expose()
  @Default(false)
  includeMiscLinkAr!: boolean;

  @Expose()
  @Default(false)
  autoRunDataCompute!: boolean;

  @Expose()
  @Default(false)
  profitSharingCommmission!: boolean;

  @Expose()
  excludeSupplierInvoiceIds!: number[];

  @Expose()
  @Default([])
  excludePayeeIds!: number[];

  @Expose()
  @Default([])
  excludeTransactionCodes!: string[];

  @Expose()
  @Default([])
  includeTransactionCodes!: number[];

  @Expose()
  @Default([])
  includeTaxInvoiceIds!: number[];

  @Expose()
  @Default([])
  excludeTaxInvoiceIds!: number[];

  @Expose()
  @Default([])
  excludeSupplierCreditNoteIds!: number[];

  @Expose()
  @Type(() => PaymentGeneratorMethodData)
  paymentMethodData!: PaymentGeneratorMethodData;

  @Expose()
  filterMetaData!: any;

  @Expose()
  @Default(true)
  reSelected!: boolean;
}

export class PaymentGeneratorQueryFilterPreviewDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  receiptDateFrom!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  receiptDateTo!: string;

  @Expose()
  @Default(false)
  includeAdjustment!: boolean;

  @Expose()
  @Default(false)
  includeMiscCreditNote!: boolean;

  @Expose()
  @Default(false)
  payProfitSharingonly!: boolean;

  @Expose()
  @Default(false)
  payNormalCommissionOnly!: boolean;

  @Expose()
  @Default(true)
  excludePartialReceipt!: boolean;

  @Expose()
  @Default(false)
  includeMiscLinkAr!: boolean;

  @Expose()
  @Default(false)
  autoRunDataCompute!: boolean;

  @Expose()
  @Default(false)
  profitSharingCommmission!: boolean;

  @Expose()
  @Default([])
  inTransactionCodes!: string[];

  @Expose()
  @Default([])
  exTransactionCodes!: string[];

  @Expose()
  @Default([])
  exPayeeIds!: number[];

  @Expose()
  @Default([])
  inTaxInvoiceIds!: number[];

  @Expose()
  @Default([])
  exTaxInvoiceIds!: number[];

  @Expose()
  @Default([])
  exSupInvoiceIds!: number[];

  @Expose()
  @Default([])
  exSupplierCreditNoteIds!: number[];

  @Expose()
  paymentGeneratorId?: number;
}
