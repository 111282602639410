import { BaseModel, PhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EMessageOutboxStatus, EMessageOutboxType, EMessageType } from '../enums/message-outbox.enum';
import { ICCInfo, IMessageOutbox } from '../interfaces';
import { EmailTemplateModel } from './email-template.model';

export class MessageOutboxModel extends BaseModel implements IMessageOutbox {
  @Expose()
  code!: string;

  @Expose()
  type!: EMessageOutboxType;

  @Expose()
  status!: EMessageOutboxStatus;

  @Expose()
  recipient!: string;

  @Expose()
  email!: string;

  @Expose()
  subject!: string;

  @Expose()
  emailTemplateId!: number;

  @Expose()
  @Type(() => EmailTemplateModel)
  emailTemplate!: EmailTemplateModel;

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  sequence!: number;

  @Expose()
  params!: {
    [x in string]: any;
  };

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  sentOn!: string;

  @Expose()
  messageType!: EMessageType;

  @Expose()
  from!: string;

  @Expose()
  cc!: ICCInfo[];

  @Expose()
  hardcopy!: boolean;

  @Expose()
  filenames!: {
    [x in string]: any;
  };

  @Expose()
  @Transform(({ obj }: { obj: IMessageOutbox }) => {
    switch (obj.status) {
      case EMessageOutboxStatus.draft:
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: EMessageOutboxStatus.draft,
        };

      case EMessageOutboxStatus.submitted:
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: EMessageOutboxStatus.submitted,
        };
      case EMessageOutboxStatus.sent:
        return {
          className: 'status-box-success',
          value: obj.status,
          displayName: obj.messageType === EMessageType.ResaleInvoice ? 'processed' : EMessageOutboxStatus.sent,
        };
      case EMessageOutboxStatus.skipped:
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: EMessageOutboxStatus.skipped,
        };
      case EMessageOutboxStatus.failed:
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: EMessageOutboxStatus.failed,
        };
      default:
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: obj.status,
        };
    }
  })
  statusObj!: { className: string; value: string; displayName: string };

  @Expose()
  get canSend(): boolean {
    return this.status !== EMessageOutboxStatus.sent;
  }

  @Expose()
  get canResend(): boolean {
    return this.status === EMessageOutboxStatus.sent;
  }

  @Expose()
  get displayCC(): string {
    return (this.cc || []).map(v => v.name + '<' + v.email + '>').join(', ');
  }

  @Expose()
  get firstCC(): ICCInfo | null {
    return this.cc && this.cc.length ? this.cc[0] : null;
  }

  @Expose()
  get files(): { key: string; name: string }[] {
    const arr: { key: string; name: string }[] = [];

    if (this.filenames) {
      Object.keys(this.filenames).forEach(key => {
        arr.push({
          key,
          name: this.filenames[key],
        });
      });
    }

    return arr;
  }
}
