import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import {
  ManagementCommissionCreateDto,
  ManagementCommissionPdCreateDto,
  ManagementCommissionPdUpdateDto,
  ManagementCommissionStatusUpdateDto,
  ManagementCommissionUpdateActivePd,
  ManagementCommissionUpdateDto,
} from '@shared/data-access/dto';
import { IManagementCommission } from '@shared/data-access/interfaces';
import { ManagementCommissionModel, ProjectCommMatrixtatusHistoryModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';
import { ManagementCommissionApproverModel } from '@shared/data-access/models/management-commission-approver.model';

@Injectable({
  providedIn: 'root',
})
export class ManagementCommissionApiService {
  static MANAGEMENT_COMMISSION = 'transaction/management-commissions';
  static MANAGEMENT_COMMISSION_PD = 'transaction/management-commissions/pd';
  static MANAGEMENT_COMMISSION_CHANGE_STATUS = 'transaction/management-commissions';
  static MANAGEMENT_COMMISSION_ACTION_HISTORY = 'transaction/status';
  static MANAGEMENT_COMMISSION_FIELD_HISTORY = 'transaction/management-commissions/:id/field-histories';
  static MANAGEMENT_COMMISSION_PD_STATE = 'transaction/management-commissions';

  constructor(private apiService: ApiService) {}

  create(data: ManagementCommissionCreateDto): Observable<ManagementCommissionModel> {
    return this.apiService
      .post(`${ManagementCommissionApiService.MANAGEMENT_COMMISSION}`, data)
      .pipe(map((res: IManagementCommission) => ManagementCommissionModel.fromJson(res) as ManagementCommissionModel));
  }

  get(itemId: number): Observable<ManagementCommissionModel> {
    return this.apiService
      .get(ManagementCommissionApiService.MANAGEMENT_COMMISSION + '/' + itemId)
      .pipe(map(res => ManagementCommissionModel.fromJson(res) as ManagementCommissionModel));
  }

  update(id: number, data: ManagementCommissionUpdateDto): Observable<any> {
    return this.apiService.patch(ManagementCommissionApiService.MANAGEMENT_COMMISSION + '/' + id, data, {
      excludeFields: [''],
    });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ManagementCommissionModel>> {
    return this.apiService
      .get(ManagementCommissionApiService.MANAGEMENT_COMMISSION, query, option)
      .pipe(map(data => new PaginationAdapter(ManagementCommissionModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(ManagementCommissionApiService.MANAGEMENT_COMMISSION, { ids: [id] }, option);
  }

  createPd(data: ManagementCommissionPdCreateDto) {
    return this.apiService.post(ManagementCommissionApiService.MANAGEMENT_COMMISSION_PD, data);
  }

  updatePd(pdId: number, data: ManagementCommissionPdUpdateDto) {
    return this.apiService.patch(
      ManagementCommissionApiService.MANAGEMENT_COMMISSION_PD + '/' + pdId,

      data
    );
  }

  deletePd(ids: number[], option?: IApiOption) {
    return this.apiService.delete(ManagementCommissionApiService.MANAGEMENT_COMMISSION_PD, { ids }, option);
  }

  changeStatus(id: number, data: ManagementCommissionStatusUpdateDto): Observable<any> {
    return this.apiService.post(ManagementCommissionApiService.MANAGEMENT_COMMISSION_CHANGE_STATUS + '/' + id + '/change-status', data, {
      excludeFields: [''],
    });
  }

  getApproverList(id: number) {
    return this.apiService
      .get(ManagementCommissionApiService.MANAGEMENT_COMMISSION + '/' + id + '/approvers')
      .pipe(map(res => new PaginationAdapter(ManagementCommissionApproverModel, res)));
  }

  getActionHistory(id: number) {
    return this.apiService.get(ManagementCommissionApiService.MANAGEMENT_COMMISSION_ACTION_HISTORY, {
      type: 'managementComm',
      sourceIds: id,
    });
  }

  getProjectCommissionMatrixStatusHistory(id: number): Observable<ProjectCommMatrixtatusHistoryModel[]> {
    return this.apiService
      .get(ManagementCommissionApiService.MANAGEMENT_COMMISSION_ACTION_HISTORY, {
        type: 'projectCommission',
        sourceIds: id,
        orderBy: 'updatedAt',
        orderType: 'desc',
      })
      .pipe(map(data => data.items));
  }

  getFieldHistory(id: number) {
    return this.apiService.get(ManagementCommissionApiService.MANAGEMENT_COMMISSION_FIELD_HISTORY, { id });
  }

  setPdState(id: number, data: ManagementCommissionUpdateActivePd) {
    return this.apiService.patch(ManagementCommissionApiService.MANAGEMENT_COMMISSION_PD_STATE + '/' + id + '/pd-state', data);
  }
}
