import { EAccountingPeriods } from '../enums/accounting-periods.enum';
import { IUpdatedBy } from './updated-by.interface';

export interface IAccountingPeriods {
  id: number;
  businessUnitId: number;
  createdAt: string;
  createdBy: number;
  updatedAt: string;
  updatedBy: IUpdatedBy;
  deletedAt: string;
  deletedBy: number;

  startDate: string;
  endDate: string;
  type: EAccountingPeriods;
  isOpen: boolean;
  version: number;
}
