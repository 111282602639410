import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingBoxDirective } from './loading-box.directive';
import { LoadingBoxComponent } from './loading-box.component';

@NgModule({
  declarations: [LoadingBoxDirective, LoadingBoxComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [LoadingBoxDirective],
})
export class LoadingBoxModule {}
