import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { BankAccountCreateDto, BankAccountUpdateDto } from '@shared/data-access/dto';
import { IAttachment, IBankAccount } from '@shared/data-access/interfaces';
import { AttachmentModel, BankAccountModel, LedgerAccountModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BankAccountApiService {
  static ROOT_POINT = 'finance/bank-accounts';
  static DOCUMENT_POINT = 'finance/bank-account-documents';
  static CAN_UPDATE_POINT = 'finance/bank-accounts/:id/can-update';
  static AVAILABLE_LIST_POINT = 'finance/bank-accounts/availables';
  static CHEQUE_AVAILABLE_LIST_POINT = 'finance/bank-accounts/linked-cheques';
  static LINKED_BANK_ACCOUNTS_POINT = 'finance/ledger-accounts/linked-bank-accounts';

  constructor(private apiService: ApiService) { }

  create(data: BankAccountCreateDto): Observable<BankAccountModel> {
    return this.apiService.post(`${BankAccountApiService.ROOT_POINT}`, data).pipe(map((res: IBankAccount) => BankAccountModel.fromJson(res) as BankAccountModel));
  }

  get(itemId: number, query = {}): Observable<BankAccountModel> {
    return this.apiService.get(BankAccountApiService.ROOT_POINT + '/' + itemId, query).pipe(map(res => BankAccountModel.fromJson(res) as BankAccountModel));
  }

  update(id: number, data: BankAccountUpdateDto): Observable<any> {
    return this.apiService.patch(BankAccountApiService.ROOT_POINT + '/' + id, data, { excludeFields: ['directDebitCollectionFileId', 'directDebitPaymentFileId'] });
  }

  canUpdate(id: number, fieldValidations: string[]): Observable<Record<string, boolean>> {
    return this.apiService.post(`${BankAccountApiService.CAN_UPDATE_POINT}`, { id, fieldValidations });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<BankAccountModel>> {
    return this.apiService
      .get(BankAccountApiService.AVAILABLE_LIST_POINT, { hasBank: true, ...query }, option)
      .pipe(map(data => new PaginationAdapter(BankAccountModel, data)));
  }

  searchAllBankAccounts(query = {}, option?: IApiOption): Observable<PaginationAdapter<BankAccountModel>> {
    return this.apiService.get(BankAccountApiService.AVAILABLE_LIST_POINT, query, option).pipe(map(data => new PaginationAdapter(BankAccountModel, data)));
  }

  searchForCheques(query = {}, option?: IApiOption): Observable<PaginationAdapter<BankAccountModel>> {
    return this.apiService.get(BankAccountApiService.CHEQUE_AVAILABLE_LIST_POINT, query, option).pipe(map(data => new PaginationAdapter(BankAccountModel, data)));
  }

  searchForLinkedBankAccounts(query = {}, option?: IApiOption): Observable<PaginationAdapter<LedgerAccountModel>> {
    return this.apiService
      .get(BankAccountApiService.LINKED_BANK_ACCOUNTS_POINT, { hasBank: false, ...query }, option)
      .pipe(map(data => new PaginationAdapter(LedgerAccountModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(BankAccountApiService.ROOT_POINT + '/' + id, data, option);
  }

  getCollectionFiles(type: 'collection' | 'payment'): Observable<AttachmentModel[]> {
    return this.apiService
      .get(BankAccountApiService.DOCUMENT_POINT, { type })
      .pipe(map((res: { items: IAttachment[] }) => res.items.map(item => AttachmentModel.fromJson(item))));
  }

  getAttachmentUrl(attachmentId: number): Observable<string> {
    return this.apiService.get(BankAccountApiService.DOCUMENT_POINT + '/' + attachmentId + '/preview');
  }
}
