import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Directive, Inject } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { MediaStorageService, PublicLinkPayload, RED_MEDIA_STORAGE, UploadLinkPayload } from '@red/components/media-storage';
import { map, Observable } from 'rxjs';
export interface HuttonUploadLink extends UploadLinkPayload {
  filename?: string;
  folder?: string;
}
export interface HuttonPublicLink extends PublicLinkPayload {
  filename?: string;
  folder?: string;
}
export interface HuttonLinkUploadResponse {
  driverName: string;
  fields: {
    container: string;
    fullKeyName: string;
    key: string;
    'x-ms-blob-type': string;
  };
  url: string;
}
@Directive()
export abstract class HuttonStorageApiService extends MediaStorageService {
  abstract TYPE: string;

  constructor(apiService: ApiService, httpClient: HttpClient, @Inject(RED_MEDIA_STORAGE) config: { storageDrive: string }) {
    super(apiService, httpClient, config);
  }

  override getUploadLink<D = HuttonLinkUploadResponse>(data: HuttonUploadLink): Observable<D> {
    return super.getUploadLink({
      type: this.TYPE,
      folder: data.type,
      filename: data.name,
    }) as Observable<D>;
  }

  override getPublicLink(data: HuttonPublicLink, requestOption?: IApiOption): Observable<string> {
    return super.getPublicLink(data);
  }

  // override uploadFile(data: any) {
  //   const headers = new HttpHeaders({ 'x-ms-blob-type': 'BlockBlob' });
  //   return this.httpClient.put(data.url, data.file, { headers });
  // }
}
