import { RedPermissionState, RedPermissionStateType } from './permission-state.type';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Data, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap, filter } from 'rxjs';
import { PermissionRouteData } from './models/permission-route-data.model';
import { PERMISSION_PREFIX, PERMISSION_ROUTER_REDIRECT_COMMAND } from './permission-provider';
import { PermissionsService } from './permission.service';
import { nomralizePermissionSlug } from './utls';

@Injectable()
export class PermissionGuard implements CanActivate, CanLoad {
  /**
   * Constructor
   */
  constructor(
    private _permissionService: PermissionsService,
    private _router: Router,
    @Inject(PERMISSION_PREFIX) private prefix: string[],
    @Inject(PERMISSION_ROUTER_REDIRECT_COMMAND) private commads: string[]
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    const slugs = this._getPermissionSlugs(route.data);
    return this._check(slugs);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const slugs = route.data ? this._getPermissionSlugs(route.data) : [];
    return this._check(slugs);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param slugs
   * @private
   */
  private _check(slugs: string[]): Observable<boolean> {
    // const authenticated = this._permissionService.canAccess(slugs);
    // console.log(' _check ->', authenticated);
    // Check the authentication status
    return this._permissionService.get().pipe(
      switchMap(() => {
        const authenticated = this._permissionService.canAccess(slugs);
        if (!authenticated) {
          // Prevent the access
          if (this.commads) {
            this._router.navigate(this.commads, { skipLocationChange: true, queryParams: { 'no-permission': 'true' } });
          }
          return of(false);
        }

        // Allow the access
        return of(true);
      })
    );
  }

  private _getPermissionSlugs(data: Data): string[] {
    const permission = data['permission'] as PermissionRouteData;
    const permissions = data['permissions'] as PermissionRouteData[];

    if (permission && permission.slug) {
      return Array.isArray(permission.slug)
        ? permission.slug.map(item => nomralizePermissionSlug(item, this.prefix))
        : [nomralizePermissionSlug(permission.slug, this.prefix)];
    }
    if (permissions) {
      const slugs = permissions.reduce((acc: string[], item) => {
        if (item.slug) {
          acc.push(nomralizePermissionSlug(item.slug, this.prefix));
        }
        return acc;
      }, []);
      return slugs;
    }
    throw new Error('config data into permission Object');
  }
}
