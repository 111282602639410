import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IReferralExternal } from '../interfaces/referral-external.interface';
import { ContactAddressModel } from './contact-address.model';

export class ReferralExternalModel extends BaseModel implements IReferralExternal {
  @Expose()
  category!: string;

  @Expose()
  code!: string;

  @Expose()
  email!: string;

  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  phone!: string;

  @Expose()
  status!: string;

  @Expose()
  type!: string;

  @Expose()
  @Type(() => ContactAddressModel)
  addresses!: ContactAddressModel[];
}
