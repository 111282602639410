import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
export class OverridingChartDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => Number)
  l1Percent!: number;

  @Expose()
  @Type(() => Number)
  icWeightage!: number;

  @Expose()
  @Type(() => Number)
  poolWeightage!: number;
}
export class OverridingDistributionCreateDto extends BaseModel {
  @Expose()
  @Transform(params => params.value.trim())
  code!: string;

  @Expose()
  @Transform(params => params.value.trim())
  name!: string;

  @Expose()
  @Type(() => OverridingChartDto)
  overridingCharts!: OverridingChartDto[];

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;

  @Expose()
  status!: string;
}

export class OverridingDistributionDataUpdateDto extends BaseModel {
  @Expose({ groups: ['full', 'general'] })
  @Transform(params => params.value.trim())
  code!: string;

  @Expose({ groups: ['full', 'general'] })
  @Transform(params => params.value.trim())
  name!: string;

  @Expose({ groups: ['full', 'chart'] })
  @Type(() => OverridingChartDto)
  overridingCharts!: OverridingChartDto[];

  @Expose({ groups: ['full', 'general', 'isActive'] })
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;
}

export class OverridingDistributionUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'general' | 'chart' | 'isActive' | 'full';

  @Expose()
  @Transform(params => {
    return OverridingDistributionDataUpdateDto.fromJson(params.value, { groups: [params.obj.group] });
  })
  data!: OverridingDistributionDataUpdateDto;
}
