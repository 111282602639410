import { Expose, Transform } from 'class-transformer';
import { IOneMapResult } from '../interfaces';
import { BaseModel } from './base.model';

export class OneMapResultModel extends BaseModel implements IOneMapResult {
  @Expose({ name: 'ADDRESS' })
  @Transform(({ value }) => (value === 'NIL' ? '' : value))
  address!: string;

  @Expose({ name: 'BLK_NO' })
  @Transform(({ value }) => (value === 'NIL' ? '' : value))
  blockNumber!: string;

  @Expose({ name: 'BUILDING' })
  @Transform(({ value }) => (value === 'NIL' ? '' : value))
  buildingName!: string;

  @Expose({ name: 'POSTAL' })
  @Transform(({ value }) => (value === 'NIL' ? '' : value))
  postalCode!: string;

  @Expose({ name: 'ROAD_NAME' })
  @Transform(({ value }) => (value === 'NIL' ? '' : value))
  streetName!: string;

  @Expose({ name: 'SEARCHVAL' })
  @Transform(({ value }) => (value === 'NIL' ? '' : value))
  searchVal!: string;
}
