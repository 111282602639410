import { CommonModule } from '@angular/common';
import { Component, ComponentRef, Inject, Input, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { assign, cloneDeep } from 'lodash-es';
import { Subject } from 'rxjs';
import { normalizeValueByDefaultValueParameter } from '../exceljs.utils';
import {
  ExceljsCard,
  ExceljsParameter,
  ExceljsParameterVisualizationMatrix,
  ExceljsParameterVisualizationMatrixItem,
  ExceljsParameterVisualizationMatrixItemInput
} from '../models';
import { ExceljsParameterContainerDirective } from './exceljs-parameter-container.directive';
import { ExceljsParameterFieldBase } from './exceljs-parameter-field-base.directive';
import { ExceljsParameterService } from './exceljs-parameter.service';
import { EXCELJS_PARAMETER_PLUGINS, builtInParameterPlugins } from './exceljs-parameter.utils';

@Component({
  selector: 'red-exceljs-parameter',
  standalone: true,
  imports: [CommonModule, ExceljsParameterContainerDirective],
  templateUrl: './exceljs-parameter.component.html',
  styleUrls: ['./exceljs-parameter.component.scss'],
  providers: [
    {
      provide: EXCELJS_PARAMETER_PLUGINS,
      useValue: builtInParameterPlugins.reduce((obj: Record<string, Type<ExceljsParameterFieldBase>>, ctor) => {
        obj[ctor.prototype.controlType] = ctor;
        return obj;
      }, {}),
    },
  ],
})
export class ExceljsParameterComponent implements OnInit, OnDestroy {
  @Input() get card(): ExceljsCard | undefined {
    return this._card;
  }
  set card(val: ExceljsCard | undefined) {
    this._card = cloneDeep(val);
    this.componentRefMap.clear();
    this._parameterService.clear();
    const viewContainerRef = this.container.viewContainerRef;
    viewContainerRef.clear();
    this.renderParameters();
  }
  @Input()
  get parameters(): ExceljsParameter[] {
    return this._parameters;
  }
  set parameters(val: ExceljsParameter[] | undefined) {
    this._parameters = val ? cloneDeep(val) : [];
    this.renderParameters();
  }

  @ViewChild(ExceljsParameterContainerDirective, { static: true }) container!: ExceljsParameterContainerDirective;
  ////////
  // @ViewChild(ExceljsParameterDataRowOutlet, {static: true}) _rowOutlet: ExceljsParameterDataRowOutlet;
  ////////

  private _parameters: ExceljsParameter[] = [];
  private _card?: ExceljsCard;
  protected _unsubscribeAll: Subject<void> = new Subject<void>();

  form!: FormGroup;
  componentRefMap = new Map<string, ComponentRef<any>>();
  constructor(
    private _fb: FormBuilder,
    @Inject(EXCELJS_PARAMETER_PLUGINS) private _plugins: Record<string, Type<ExceljsParameterFieldBase>>,
    private _parameterService: ExceljsParameterService
  ) {}

  ngOnInit() {
    console.log('ngOnInit');
    this.form = this.createForm();
  }
  createForm(): FormGroup {
    return this._fb.group({
      parameters: this._fb.array([]),
    });
  }
  createFormItem(item: Partial<ExceljsParameter>): FormGroup {
    return this._fb.group({
      name: [item.name],
      slug: [item.slug],
      value: [normalizeValueByDefaultValueParameter(item.value, item)],
    });
  }
  get parametersFormArray(): FormArray | undefined {
    return this.form?.get('parameters') as FormArray;
  }
  isParamerersValid(card?: ExceljsCard, parameters?: ExceljsParameter[]): boolean {
    if (!card || !parameters) {
      return false;
    }
    return true;
  }
  renderParameters(): void {
    if (!this.isParamerersValid(this.card, this.parameters)) {
      return;
    }
    // console.log(
    //   'renderParameters ---> ',
    //   this.parameters.map(item => ({ slug: item.slug, value: item.value }))
    // );
    const viewContainerRef = this.container.viewContainerRef;
    const parameterMap: Record<string, any> = (this.parameters || []).reduce((acc: Record<string, any>, param) => {
      acc[param.slug] = param.value;
      return acc;
    }, {});
    const matrices = renderMatrices(cloneDeep(this.card), parameterMap);
    matrices.forEach(matrixItem => {
      // if (matrixItem[0].hide) {
      //   return;
      // }
      const parameter = this.parameters.find(param => param.slug === matrixItem[0].slug);
      if (parameter) {
        if (this.componentRefMap.has(parameter.slug)) {
          const componentRef = this.componentRefMap.get(parameter.slug);
          if (componentRef && viewContainerRef.indexOf(componentRef.hostView) > -1) {
            const viewIndex = viewContainerRef.indexOf(componentRef.hostView);
            if (matrixItem[0].hide) {
              viewContainerRef.detach(viewIndex);
            }
            // console.log('componentRef --> ', componentRef.instance.data, parameter.value);
            if (componentRef.instance.data?.value !== parameter.value) {
              // console.log('change --> ');
              componentRef.instance.data = cloneDeep(parameter);
              componentRef.instance.card = cloneDeep(this.card);
            }
            // console.log('hasView', viewContainerRef.indexOf(componentRef.hostView));
            // componentRef.instance.data = parameter;
            return;
          }
        }
        if (matrixItem[0].hide) {
          return;
        }
        const component = this._plugins[parameter.type] || null;
        if (component) {
          const componentRef = viewContainerRef.createComponent<ExceljsParameterFieldBase>(component);
          // componentRef.instance.dataUpcast.valueChanges
          //   .pipe(
          //     tap(value => {
          //       this._parameterService.select({ values: { key: parameter.slug, value } });
          //     }),
          //     take(1)
          //   )
          //   .subscribe();
          // console.log(parameter.slug, '---> ', parameter);
          componentRef.instance.data = parameter;
          componentRef.instance.card = this.card as ExceljsCard;
          const matrixItemIndex = matrices.filter(row => !row[0].hide).findIndex(row => row[0].slug === matrixItem[0].slug);
          const viewIndex = viewContainerRef.indexOf(componentRef.hostView);
          if (matrixItemIndex < viewContainerRef.length) {
            viewContainerRef.move(componentRef.hostView, matrixItemIndex);
          }
          this.componentRefMap.set(parameter.slug, componentRef);
          // this._parameterService
          //   .connect()
          //   .pipe(takeUntil(this._unsubscribeAll))
          //   .subscribe(res => {
          //     console.log('_parameterService changes --> ', res);
          //     viewContainerRef.insert(componentRef.hostView, viewIndex);
          //   });
        }
      }
    });
    // this.parameters.forEach(parameter => {
    //   const component = this._plugins[parameter.type] || null;
    //   if (component) {
    //     const componentRef = viewContainerRef.createComponent<ExceljsParameterFieldBase>(component);
    //     componentRef.instance.dataUpcast.valueChanges
    //       .pipe(
    //         tap(value => {
    //           this._parameterService.select({ values: { key: parameter.slug, value } });
    //         }),
    //         take(1)
    //       )
    //       .subscribe();
    //     componentRef.instance.data = parameter;
    //     this._parameterService.register({ key: parameter.slug, formCtr: componentRef.instance.dataDowncast });
    //   }
    // });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

export function renderMatrices(input?: ExceljsCard, parameterMap: Record<string, any> = {}): ExceljsParameterVisualizationMatrix {
  if (!input) {
    return [];
  }
  if (input.parameterVisualization?.matrices) {
    return input.parameterVisualization.matrices.map(item => {
      if (Array.isArray(item)) {
        return item.map(child => normalizeMatrixItem(child, parameterMap));
      }
      return [normalizeMatrixItem(item, parameterMap)];
    });
  }
  return input.parameters.map(item => {
    return [normalizeMatrixItem(item.slug, parameterMap)];
  });
}
export function normalizeMatrixItem(
  item: ExceljsParameterVisualizationMatrixItemInput,
  parameterMap: Record<string, any>
): Required<ExceljsParameterVisualizationMatrixItem> {
  if (typeof item === 'string') {
    return { slug: item, hide: false };
  }
  if (!item.hide) {
    return assign(item, { hide: false });
  }
  return assign(item, { hide: evaluting(item.hide, parameterMap) });
}
export function evaluting(codeStr: string, parameters: Record<string, any>): boolean {
  return Boolean(eval(codeStr));
}
