import { IBlankFormInternalCoBroke } from './blank-form-internal-co-broke.interface';
import { IBlankFormExternalCoBroke } from './blank-form-external-co-broke.interface';
import { IBlankFormIc } from './blank-form-ic.interface';
import { IBlankFormBd } from './blank-form-bd.interface';
import { IBlankFormPd } from './blank-form-pd.interface';
import { IBlankFormReferral } from './blank-form-referral.interface';
import { IBlankFormOtherFee } from './blank-form-other-fee.interface';

export interface IBlankFormSummary {
  mains: IBlankFormInternalCoBroke[];
  leaders: IBlankFormInternalCoBroke[];
  internals: IBlankFormInternalCoBroke[];
  externals: IBlankFormExternalCoBroke[];
  blankFormBds: IBlankFormBd[];
  blankFormBonuses: IBlankFormIc[];
  blankFormOverridings: IBlankFormIc[];
  blankFormPools: IBlankFormIc[];
  // blankFormPds: IBlankFormPd[];
  blankFormPdOverrides: IBlankFormPd[];
  blankFormPdReferrals: IBlankFormPd[];
  blankFormPdManagements: IBlankFormPd[];
  // blankFormPd: IBlankFormPd[];
  blankFormReferrals: IBlankFormReferral[];
  // blankFormReferralsInternal: IBlankFormReferral[];
  // blankFormReferralsExternal: IBlankFormReferral[];
  blankFormOthers: IBlankFormOtherFee[];
}
