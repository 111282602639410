import { BaseModel, Default } from '@red/data-access';
import { IBlankFormOtherFee } from '../interfaces/blank-form-other-fee.interface';
import { Expose, Type } from 'class-transformer';
import { EBlankFormOtherFeeType } from '../enums';
import { BLANK_FORM_OTHER_FEE_TYPE_OPTIONS } from '../data';

export class BlankFormOtherFeeModel extends BaseModel implements IBlankFormOtherFee {
  @Expose()
  id!: number;

  @Expose()
  blankFormId!: number;

  @Expose()
  code!: EBlankFormOtherFeeType;

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  data!: any;

  @Expose()
  deletedAt!: string;

  @Expose()
  deletedBy!: string;

  @Expose()
  name!: string;

  @Expose()
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: string;

  @Expose()
  @Default(false)
  isDefault!: boolean;

  @Expose()
  attrs!: any;

  @Expose()
  get codeDisplay(): string {
    return BLANK_FORM_OTHER_FEE_TYPE_OPTIONS[this.code]?.viewValue;
  }
}
