import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { TeamCreateDto } from '@shared/data-access/dto';
import { IProjectConsultant } from '@shared/data-access/interfaces';
import { ProjectConsultantModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectTeamApiService {
  static ROOT_POINT = 'transaction/teams';

  constructor(private apiService: ApiService) {}

  create(data: TeamCreateDto): Observable<ProjectConsultantModel> {
    return this.apiService
      .post(ProjectTeamApiService.ROOT_POINT, data)
      .pipe(map((res: IProjectConsultant) => ProjectConsultantModel.fromJson(res) as ProjectConsultantModel));
  }
}
