import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IQualification } from '../interfaces';

export class QualificationModel extends BaseModel implements IQualification {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  type!: string;

  @Expose()
  name!: string;

  // @Expose()
  // status!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  isOther!: boolean;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;
}
