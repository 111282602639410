import { IImportChartOfAccountsColumn } from '../interfaces';

export const IMPORT_CHART_OF_ACCOUNTS_DATA: IImportChartOfAccountsColumn[] = [
  {
    id: 'accountType',
    label: 'Account Type',
    index: 0,
    required: true,
    maxLength: 50,
  },
  {
    id: 'accountCode',
    label: 'Account Code',
    index: 1,
    required: true,
    maxLength: 20,
  },
  {
    id: 'accountName',
    label: 'Account Name',
    index: 2,
    required: true,
    maxLength: 300,
  },
  {
    id: 'parentAccountCode',
    label: 'Parent Account Code',
    index: 3,
    required: false,
    maxLength: 20,
  },
];
