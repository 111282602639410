export interface AuthLoginDto {
  email: string;
  password: string;
}
export interface AuthForgotPasswordDto {
  email: string;
}
export interface AuthLoginResponseDto {
  accessToken: string;
  refreshToken: string;
}

export type RefreshTokenResponseDto = AuthLoginResponseDto;

export type IUser = Record<string, any> & {
  name: string;
  email: string;
  avatar: string;
};
