import { ERecruitmentType, ERecruitmentStatus, ERecruitmentDivision } from '../enums';
import { RecruitmentTypeRecord, RecruitmentStatusRecord, RecruitmentDivisionRecord } from '../types';

export const RECRUITMENT_TYPE_OPTIONS: RecruitmentTypeRecord = {
  [ERecruitmentType.BrandNew]: {
    id: ERecruitmentType.BrandNew,
    value: ERecruitmentType.BrandNew,
    viewValue: 'Brand New RES',
  },
  [ERecruitmentType.Existing]: {
    id: ERecruitmentType.Existing,
    value: ERecruitmentType.Existing,
    viewValue: 'Existing RES at Other Agencies',
  },
  [ERecruitmentType.RejoinIndustry]: {
    id: ERecruitmentType.RejoinIndustry,
    value: ERecruitmentType.RejoinIndustry,
    viewValue: 'RES rejoin the industry',
  },
};

export const RECRUITMENT_STATUS_OPTIONS: RecruitmentStatusRecord = {
  [ERecruitmentStatus.draft]: {
    id: ERecruitmentStatus.draft,
    value: ERecruitmentStatus.draft,
    viewValue: 'Pending Submission',
    viewClass: 'status-box-warning',
    viewStatus: 'Draft',
  },
  [ERecruitmentStatus.registered]: {
    id: ERecruitmentStatus.registered,
    value: ERecruitmentStatus.registered,
    viewValue: 'Registered',
    viewClass: 'status-box-success',
    viewStatus: 'Register',
  },
  [ERecruitmentStatus.submitted]: {
    id: ERecruitmentStatus.submitted,
    value: ERecruitmentStatus.submitted,
    viewValue: 'Pending Approval',
    viewClass: 'status-box-warning',
    viewStatus: 'Pending',
  },
  [ERecruitmentStatus.approved]: {
    id: ERecruitmentStatus.approved,
    value: ERecruitmentStatus.approved,
    viewValue: 'Approved',
    viewClass: 'status-box-success',
    viewStatus: 'Approve',
  },
  [ERecruitmentStatus.rejected]: {
    id: ERecruitmentStatus.rejected,
    value: ERecruitmentStatus.rejected,
    viewValue: 'Rejected',
    viewClass: 'status-box-danger',
    viewStatus: 'Reject',
  },
  [ERecruitmentStatus.withdrawn]: {
    id: ERecruitmentStatus.withdrawn,
    value: ERecruitmentStatus.withdrawn,
    viewValue: 'Withdrawn',
    viewClass: 'status-box-default',
    viewStatus: 'Withdraw',
  },
  [ERecruitmentStatus.rework]: {
    id: ERecruitmentStatus.rework,
    value: ERecruitmentStatus.rework,
    viewValue: 'Sent back for amendment',
    viewClass: 'status-box-purple',
    viewStatus: 'Query',
  },
};

export const RECRUITMENT_DIVISION_OPTIONS: RecruitmentDivisionRecord = {
  [ERecruitmentDivision.HQSAVILLS]: {
    id: ERecruitmentDivision.HQSAVILLS,
    value: ERecruitmentDivision.HQSAVILLS,
    viewValue: 'HQ & SAVILLS',
  },
  [ERecruitmentDivision.HUTTONSAGENT]: {
    id: ERecruitmentDivision.HUTTONSAGENT,
    value: ERecruitmentDivision.HUTTONSAGENT,
    viewValue: 'HUTTON AGENT',
  },
  [ERecruitmentDivision.KGD]: {
    id: ERecruitmentDivision.KGD,
    value: ERecruitmentDivision.KGD,
    viewValue: 'KGD',
  },

  [ERecruitmentDivision.MTD]: {
    id: ERecruitmentDivision.MTD,
    value: ERecruitmentDivision.MTD,
    viewValue: 'MTD',
  },

  [ERecruitmentDivision.NAVIS]: {
    id: ERecruitmentDivision.NAVIS,
    value: ERecruitmentDivision.NAVIS,
    viewValue: 'NAVIS',
  },
  [ERecruitmentDivision.PND]: {
    id: ERecruitmentDivision.PND,
    value: ERecruitmentDivision.PND,
    viewValue: 'PND',
  },
  [ERecruitmentDivision.RTD]: {
    id: ERecruitmentDivision.RTD,
    value: ERecruitmentDivision.RTD,
    viewValue: 'RTD',
  },
  [ERecruitmentDivision.WHD]: {
    id: ERecruitmentDivision.WHD,
    value: ERecruitmentDivision.WHD,
    viewValue: 'WHD',
  },
};
