import { VerifyStatusRecord } from '../types';
import { EVerifyStatus } from '../enums';

export const VERIFY_STATUS_OPTIONS: VerifyStatusRecord = {
  [EVerifyStatus.draft]: {
    id: EVerifyStatus.draft,
    value: EVerifyStatus.draft,
    viewValue: 'Darft',
    viewClass: 'status-box-default',
  },
  [EVerifyStatus.rework]: {
    id: EVerifyStatus.rework,
    value: EVerifyStatus.rework,
    viewValue: 'Query',
    viewClass: 'status-box-purple',
  },
  [EVerifyStatus.submitted]: {
    id: EVerifyStatus.submitted,
    value: EVerifyStatus.submitted,
    viewValue: 'Pending',
    viewClass: 'status-box-warning',
  },
  [EVerifyStatus.approved]: {
    id: EVerifyStatus.approved,
    value: EVerifyStatus.approved,
    viewValue: 'Approve',
    viewClass: 'status-box-success',
  },
  [EVerifyStatus.rejected]: {
    id: EVerifyStatus.rejected,
    value: EVerifyStatus.rejected,
    viewValue: 'Reject',
    viewClass: 'status-box-danger',
  },
  [EVerifyStatus.locked]: {
    id: EVerifyStatus.locked,
    value: EVerifyStatus.locked,
    viewValue: 'Lock',
    viewClass: 'status-box-warning',
  },
};
