export function nomralizePermissionSlug(slug: string, prefix: string[]): string {
  const parts = slug.split(':');
  if (parts.length === 3) {
    return slug;
  }
  if (parts.length < 3) {
    if (prefix) {
      const res = [...parts];
      let i = 3 - parts.length - 1;
      while (i > -1) {
        res.unshift(prefix[i]);
        i--;
      }
      return res.join(':');
    }
  }
  throw new Error('slug is not correct');
}
