import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { PersonalParticularModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FinanceMasterDataApiService {
  static SALESPERSON_POINT = 'finance/sale-persons';

  constructor(private apiService: ApiService) {}

  getSalepersons(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this.apiService.get(FinanceMasterDataApiService.SALESPERSON_POINT, query, option).pipe(map(data => new PaginationAdapter(PersonalParticularModel, data)));
  }
}
