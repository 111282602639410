import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { REDOC_BASE_URL, RedocConnectService } from '@shared/components/redoc-connect';
import { from, map, of, switchMap } from 'rxjs';
import { AuthProvider } from '../auth.service';
import { AuthUtils } from '../auth.utils';

export const CanGetAuthCode: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthProvider);
  const router = inject(Router)
  const ssoService = inject(RedocConnectService);

  const baseUrl = inject(REDOC_BASE_URL);
  const slug = state.url;

  const finalUrl = `${baseUrl}${slug || 'document'}`;
  console.log('finalUrl --> ', finalUrl)
  return authService.check().pipe(
    switchMap(authenticated => {
      console.log('authenticated --> ', authenticated)
      // If the user is not authenticated, reject
      if (!authenticated) return of(false);

      // If the user is authenticated, accept
      const accessToken = authService.accessToken;
      console.log('accessToken --> ', accessToken)
      const isTokenExpired = AuthUtils.isTokenExpired(accessToken);
      console.log('isTokenExpired --> ', isTokenExpired)
      if (!isTokenExpired) return of(true);

      console.log('signInUsingRefreshToken --> ', isTokenExpired)
      // If the token is expired, try to refresh it
      return authService.signInUsingRefreshToken();
    }),
    switchMap(authenticated => {
      console.log('signInUsingRefreshToken authenticated--> ', authenticated)
      // Both access token and refresh token are expired
      if (!authenticated) return router.navigate(['']);

      // Get the access token
      const accessToken = authService.accessToken;
      console.log('signInUsingRefreshToken accessToken--> ', accessToken)

      ssoService.loginWithRedirect(finalUrl, { organization: 'hutton', with_token: accessToken });
      return of(false)
    }),
  );
};

export const CanRedirectToRedoc: CanActivateFn = () => {
  const ssoService = inject(RedocConnectService);

  return from(ssoService.tryLogin()).pipe(map(() => {

    if (ssoService.hasValidAccessToken()) {
      const state = ssoService.state;
      window.location.href = decodeURIComponent(state);
    }

    return false
  }))
}
