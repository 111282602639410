import { MatSelectModule } from '@angular/material/select';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExceljsParameterFieldBase } from '../exceljs-parameter-field-base.directive';
import { ExceljsParameter, IExceljsParameterControlType } from '../../models';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ExceljsParameterService } from '../exceljs-parameter.service';
import { SelectSearchModule } from '@red/components/select-search';
import { FilterModule } from '@red/pipes/filter';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'red-exceljs-parameter-select',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, SelectSearchModule, FilterModule],
  templateUrl: './exceljs-parameter-select.component.html',
  styleUrls: ['./exceljs-parameter-select.component.scss'],
})
export class ExceljsParameterSelectComponent extends ExceljsParameterFieldBase {
  options: { value: string | number | boolean; label: string }[] = [];
  isMulti = false;
  searchMode = false;
  dataFormControl = new FormControl();
  toDataDowncastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return val;
  }
  toDataUpcastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return val;
  }

  constructor() {
    super();
    this.dataChange$.subscribe(() => {
      // If data not change, then we don't need to update this.options
      const dataChange = !isEqual(this.data.options, this.options);
      if (dataChange) this.options = this.data.options || [];

      this.searchMode = this.options.length > 5;
      this.isMulti = this.data.valueType?.toLocaleLowerCase().includes('array') ?? false;
    });
  }
  get controlType(): IExceljsParameterControlType {
    return 'selection';
  }
  openedChange(opened: boolean): void {
    console.log('opened --> ', opened);
    if (!opened && this.dataUpcast.dirty) {
      this.onValueChange(this.dataUpcast.getRawValue());
    }
  }
  override init(): void {
    const dataDowncast = this.toDataUpcastValue(this.data, this.data.value);
    this.dataUpcast.setValue(dataDowncast);
  }
}
