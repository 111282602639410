import { Observable } from 'rxjs';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExceljsTableComponent } from '../exceljs-table/exceljs-table.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExceljsWorkBook } from '../models';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import * as saveAs from 'file-saver';

@Component({
  selector: 'red-exceljs-viewer-dialog',
  standalone: true,
  imports: [CommonModule, ExceljsTableComponent, MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './exceljs-viewer-dialog.component.html',
  styleUrls: ['./exceljs-viewer-dialog.component.scss'],
})
export class ExceljsViewerDialogComponent implements OnInit {
  @ViewChild('table') tablesEl!: ElementRef<any>;

  downloadFn?: () => Observable<any>;
  printFn?: (el: HTMLElement) => void;
  fileName = 'sample';
  constructor(
    private _dialogRef: MatDialogRef<ExceljsViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data?: {
      workbook?: ExceljsWorkBook;
      downloadFn?: () => Observable<any>;
      printFn?: (el: HTMLElement) => void;
    }
  ) {
    this.downloadFn = data?.downloadFn;
    this.printFn = data?.printFn;
    this.fileName = data?.workbook?.name || 'sample';
  }

  ngOnInit(): void {}

  download(): void {
    if (this.downloadFn) {
      this.downloadFn().subscribe(res => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${this.fileName}.xlsx`);
      });
    }
  }

  print(): void {
    if (this.printFn) {
      this.printFn(this.tablesEl.nativeElement);
    }
  }

  close(): void {
    this._dialogRef.close();
  }
}
