import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { format, isMatch, isValid, parseISO } from 'date-fns';
import { ExceljsCol, ExceljsNormalizedCell } from './models';
import { getColIndex } from './exceljs.utils';

@Pipe({
  name: 'colWidth',
  standalone: true,
})
export class CollWidthPipe implements PipeTransform {
  // Create a pipe that will format a number according to the ExcelJS number format
  transform(cell: ExceljsNormalizedCell, cols: ExceljsCol[], widthDefault?: number): number | null {
    const colIndex = getColIndex(cell.address);
    if (cell.attrs?.colspan > 1) {
      return null;
    }
    const selected = cols.find(col => col.min <= colIndex && col.max >= colIndex);

    return this.calculate(selected ? selected.width : widthDefault ? widthDefault : 20);
  }
  calculate(widthPoint: number): number {
    // Excell: 8.43 = 64px
    return Math.floor((widthPoint * 64) / 8.43);
  }
}
