import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { builtInPlugins } from '../built-in-plugins';
// import { builtInPlugins } from '../built-in-plugins';
import { MetabaseParameterContainerDirective } from './metabase-parameter-container.directive';
import { MetabaseParameterFieldBase } from './metabase-parameter-field-base.interface';
import { MetabaseParameterComponent } from './metabase-parameter.component';
export const METABASE_PARAMETER_PLUGINS = new InjectionToken<Record<string, any>>('METABASE_PARAMETER_PLUGINS');
@NgModule({
  declarations: [MetabaseParameterComponent, MetabaseParameterContainerDirective],
  imports: [CommonModule],
  exports: [MetabaseParameterComponent, MetabaseParameterContainerDirective],
})
export class MetabaseParameterModule {
  static forRoot(): ModuleWithProviders<MetabaseParameterModule> {
    const collections = builtInPlugins.reduce((obj: Record<string, Type<MetabaseParameterFieldBase>>, ctor) => {
      obj[ctor.prototype.controlType] = ctor;
      return obj;
    }, {});
    return {
      ngModule: MetabaseParameterModule,
      providers: [
        {
          provide: METABASE_PARAMETER_PLUGINS,
          useValue: collections,
        },
      ],
    };
  }
}
