import { Observable } from 'rxjs';
import { LedgerAccountModel, ProfitCentresModel } from '../models';
import { IBase } from './base-finance.interface';

export interface IJournalPosting extends IBase {
  journalId: number;
  accountId: number;
  profitCenterId?: number;
  description?: string;
  debit: number;
  credit: number;
  order: number;
  currency: string;
  accountAsync: Observable<LedgerAccountModel>;
  profitAsync: Observable<ProfitCentresModel>;
  index?: number;
  chartOfAccount: LedgerAccountModel;
  profitCentres: ProfitCentresModel;
}
