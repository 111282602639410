import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { KeyAppointmentCreateDto, KeyAppointmentUpdateDto } from '@shared/data-access/dto';
import { IKeyAppointment } from '@shared/data-access/interfaces';
import { KeyAppointmentModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyAppointmentApiService {
  static ROOT_POINT = 'transaction/key-appointments';

  constructor(private apiService: ApiService) {}

  create(data: KeyAppointmentCreateDto): Observable<KeyAppointmentModel> {
    return this.apiService
      .post(`${KeyAppointmentApiService.ROOT_POINT}`, data)
      .pipe(map((res: IKeyAppointment) => KeyAppointmentModel.fromJson(res) as KeyAppointmentModel));
  }

  get(itemId: number): Observable<KeyAppointmentModel> {
    return this.apiService.get(KeyAppointmentApiService.ROOT_POINT + '/' + itemId).pipe(map(res => KeyAppointmentModel.fromJson(res) as KeyAppointmentModel));
  }

  update(id: number, data: KeyAppointmentUpdateDto): Observable<any> {
    return this.apiService.patch(KeyAppointmentApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<KeyAppointmentModel>> {
    return this.apiService
      .get(KeyAppointmentApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(KeyAppointmentModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(KeyAppointmentApiService.ROOT_POINT, { ids }, option);
  }

  updateStatus(id: number, status: string): Observable<any> {
    return this.apiService.patch(KeyAppointmentApiService.ROOT_POINT + '/' + id, { status });
  }

  getMainIC(projectId: number): Observable<PaginationAdapter<KeyAppointmentModel>> {
    return this.apiService.get(KeyAppointmentApiService.ROOT_POINT + '/main-ic/', { projectId }).pipe(map(data => new PaginationAdapter(KeyAppointmentModel, data)));
  }

  getTotalMembers(id: number, query = {}): Observable<any> {
    return this.apiService.get(KeyAppointmentApiService.ROOT_POINT + '/total/' + id, query);
  }
}
