import { FormControl } from '@angular/forms';
import { Observable, Subject, takeUntil, map, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
interface ExceljsParameterItemSelected {
  key: string;
  value: any;
}
@Injectable()
export class ExceljsParameterService implements OnDestroy {
  private parameters$ = new Subject<Map<string, any>>();
  private parameters = new Map<string, any>();
  readonly #imperative$: BehaviorSubject<Map<string, any>>;

  private formCtrCollection = new Map<string, { unsubscriber: Subject<void>; form: FormControl }>();
  constructor() {
    this.#imperative$ = new BehaviorSubject<Map<string, any>>(new Map());
  }
  connect(): Observable<Map<string, any>> {
    return this.parameters$.asObservable();
  }

  select(data: { values: ExceljsParameterItemSelected | ExceljsParameterItemSelected[]; configs?: { emitEvent: boolean } }): void {
    const values = Array.isArray(data.values) ? data.values : [data.values];
    console.log('select --> ', values)
    values.forEach(value => this._markSelected(value));
    if (data.configs?.emitEvent) {
      this.parameters$.next(this.parameters);
      this.#imperative$.next(this.parameters);
    }
  }

  register(...values: { key: string; formCtr: FormControl }[]): void {
    values.forEach(value => {
      const payload = {
        unsubscriber: new Subject<void>(),
        form: value.formCtr,
      };
      // console.log('register --> ', value.key);
      if (this.formCtrCollection.has(value.key)) {
        // console.log('has Olb');
        const oldControl = this.formCtrCollection.get(value.key) as { unsubscriber: Subject<void>; form: FormControl };
        oldControl.unsubscriber.next();
        oldControl.unsubscriber.complete();
      }
      this.formCtrCollection.set(value.key, payload);
      payload.form.valueChanges
        .pipe(
          map(val => {
            console.log('update value --> ', value.key, val);
            this.select({ values: { key: value.key, value: val }, configs: { emitEvent: true } });
          }),
          takeUntil(payload.unsubscriber)
        )
        .subscribe();
    });
  }

  clear(configs?: { emitEvent: boolean }): void {
    this.parameters.clear();
    this.formCtrCollection.forEach(item => {
      item.unsubscriber.next();
      item.unsubscriber.complete();
    });
    this.formCtrCollection.clear();
    if (configs?.emitEvent) {
      this.parameters$.next(this.parameters);
    }
  }

  private _markSelected(value: ExceljsParameterItemSelected): void {
    this.parameters.set(value.key, value.value);
  }

  getImperativeState(): Map<string, any> {
    return this.#imperative$.getValue();
  }
  ngOnDestroy(): void {
    console.log('ngOnDestroy --> ');
    this.parameters$.complete();
    this.formCtrCollection.forEach(item => {
      item.unsubscriber.next();
      item.unsubscriber.complete();
    });
    this.formCtrCollection.clear();
  }
}
