import { IPhoneNumber } from '@red/data-access';
import { ECitizen, EGender } from '../enums';
import { IBank } from './bank.interface';
import { ICarrerProgression } from './career-progression.interface';
import { ICommissionScheme } from './commission-scheme.interface';
import { ICompany } from './company.interface';
import { ICountry } from './country.interface';
import { ICredential } from './credential.interface';
import { IIdType } from './id-type.interface';
import { IMarital } from './marital.interface';
import { INationality } from './nationality.interface';
import { ISchemeType } from './scheme-type.interface';
import { ITimestamp } from './timestamp.interface';
import { IUserGroup } from './user-group.interface';
import { IUserRole } from './user-role.interface';
import { ISplitPart } from './split-part.interface';

export interface IPersonalParticularAddress {
  house: string;
  street: string;
  address: string;
  district?: string;
  floor?: string;
  unit?: string;
  buildingName?: string;
  postalCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
}

export interface IPersonalParticularLeadership {
  autoApprove: boolean;
  code: string;
  commissionType: string;
  designation: string;
  directHeadcount: number;
  id: number;
  status: string;
  totalHeadcount: number;
}

export interface IPersonalParticularUser extends ITimestamp {
  businessUnitId?: number;
  email: string;
  id: number;
  name: string;
  status: string;
  username: string;
  companies?: ICompany[];
  groupIds?: number[];
  groups?: IUserGroup[];
  roleIds?: number[];
  roles?: IUserRole[];
}

export interface IPersonalParticular {
  // promotionMatrixId: number;
  // promotionMatrix?: IPromotionMatrix;
  // level?: number;
  // pendingCommission: number;
  // nextCommission: number;
  // nextTier: number;

  appTypeIds?: { id: number }[];
  appointmentType?: {
    code: string;
    id: number;
    isActive: boolean;
    name: string;
  };
  receivedCommission?: number;
  accumulateCommission: number;
  address?: IPersonalParticularAddress;
  associatesCode?: string;
  avatar?: string;
  bank?: IBank;
  bankAccountNo?: string;
  bankId?: number;
  businessName: string;
  careers?: ICarrerProgression[];
  ceaRegNo?: string;
  commissionScheme?: ICommissionScheme;
  commissionSchemeId?: number;
  companies?: ICompany[];
  company?: ICompany;
  companyId?: number;
  companyIds?: { id: number }[];
  country?: ICountry;
  countryId?: number;
  createdAt?: string;
  credential?: ICredential;
  credentialId: number;
  dateOfBirth?: string;
  effectiveDate?: string;
  expireDate?: string;
  fax?: IPhoneNumber;
  gender?: EGender;
  id: number;
  idType?: IIdType;
  idTypeId?: number;
  issueDate?: string;
  joinDate?: string;
  leadership?: IPersonalParticularLeadership;
  leadershipId?: number;
  managerEffectiveDate?: string;
  maritalId?: number;
  marital?: IMarital;
  citizen?: ECitizen;
  nationality?: INationality;
  nationalityId?: number;
  nricfin?: string;
  nricName?: string;
  parent: IPersonalParticular;
  parentId: number | undefined;
  phone?: IPhoneNumber;
  postalCode?: string;
  propertyName?: string;
  recruiter?: IPersonalParticular;
  recruiterId?: number;
  recruiterDateFrom?: string;
  recruiterDateTo?: string;
  resignDate?: string;
  resignReason?: string;
  schemeType?: ISchemeType;
  schemeTypeId?: number;
  state?: string;
  transferRemark?: string;
  emplacementRemark?: string;
  user?: IPersonalParticularUser;
  isActive: boolean;
  matrix: {
    id: number;
    tiers: ISplitPart[];
    parties: ISplitPart[];
  };
}
