import { EPaymentStatus } from '../enums';
import { StatusRecord } from '../types/status.type';

export const PAYMENT_TYPES = [
  {
    id: 'OneTimePayment',
    name: 'One Time Payment',
  },
  {
    id: 'SupplierPayment',
    name: 'Supplier Payment',
  },
  {
    id: 'CustomerRefund',
    name: 'Customer Refund',
  },
];

export const PAYMENT_MODES = [
  {
    id: 'ChequePayment',
    name: 'Cheque Payment',
  },
  {
    id: 'CashPayment',
    name: 'Cash Payment',
  },
  {
    id: 'BankDirectDebit',
    name: 'Bank Transfer Payment',
  },
] as const;

export const PAYMENT_OFFSET_TYPES = [
  {
    id: 'PR',
    name: 'Payment Reversal',
  },
];

export const PAYMENT_BATCH_TYPE = [
  {
    id: 'DirectDebitPayment',
    name: 'Direct Debit Payment',
  },
  {
    id: 'Generator',
    name: 'Payment Generator',
  },
] as const;

export const PAYMENT_STATUS: StatusRecord<EPaymentStatus> = {
  [EPaymentStatus.confirmed]: {
    id: EPaymentStatus.confirmed,
    name: 'Active',
    detailName: 'View',
    filterName: 'Active',
    class: 'status-box-success',
    detailClass: 'status-box-default',
  },
  [EPaymentStatus.reversed]: {
    id: EPaymentStatus.reversed,
    name: 'Reversed',
    detailName: 'View',
    filterName: 'Reversed',
    class: 'status-box-default',
    detailClass: 'status-box-default',
  },
  [EPaymentStatus.cancelled]: {
    id: EPaymentStatus.cancelled,
    name: 'Cancelled',
    detailName: 'Void',
    filterName: 'Cancelled',
    class: 'status-box-smokey-topaz',
    detailClass: 'status-box-smokey-topaz',
  },
};

type EnumDescripted<T> = {
  [K in keyof T]: {
    readonly id: T[K];
    readonly description: string;
  };
}[keyof T];

type EnumStringDescripted<T> = {
  [K in keyof T]: {
    readonly id: K;
    readonly description: T[K];
  };
}[keyof T];

export function enumToDescriptedArray<T extends object>(enumeration: T, separatorRegex = /_/g): EnumDescripted<T>[] {
  return (Object.keys(enumeration) as Array<keyof T>)
    .filter(key => isNaN(Number(key)))
    .filter(key => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
    .map(key => ({
      id: enumeration[key],
      description: String(key).replace(separatorRegex, ' '),
    }));
}

export function enumStringToDescriptedArray<T extends object>(enumeration: T): EnumStringDescripted<T>[] {
  return (Object.keys(enumeration) as Array<keyof T>)
    .filter(key => isNaN(Number(key)))
    .filter(key => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
    .map(key => ({
      id: key,
      description: enumeration[key],
    }));
}
