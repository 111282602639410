import { ClassConstructor, Transform, TransformFnParams, Type, TypeHelpOptions, plainToClass } from 'class-transformer';

export function Record(typeFunction: (typeOption?: TypeHelpOptions) => ClassConstructor<any>) {
  return Transform((params: TransformFnParams) => {
    if (typeof params.value !== 'object') {
      return params.value;
    }
    const record = params.value as Record<string | number | symbol, any>;
    return Object.keys(record).reduce((obj: Record<string | number | symbol, any>, key) => {
      obj[key] = plainToClass(typeFunction(), record[key]);
      return obj;
    }, {});
  });
}
