import { IAddress, INationality, IPersonalParticular } from '.';
import { EEcddCustomerType, EEcddIdentityType, EEcddTransactionType, EEcddType } from '../enums';
import { IUpdatedBy } from './updated-by.interface';
import { ICountry } from './country.interface';

export interface IEcdd {
  id: number;
  status: string;
  type: EEcddType;
  transactionType: EEcddTransactionType;
  documentTitle: string;
  salespersonId: number;
  salesperson: IPersonalParticular;
  signature: string;
  checklistAt: string;
  submittedAt: string;
  submittedBy: any;
  reviewedAt: string;
  reviewedBy: any;
  completedAt: string;
  address?: IAddress;
  isManually: boolean;
  customers: IEcddCustomer[];
  amlClient: any;
  ecddOptionGroups: IEcddOptionGroup[];
  metadata: {
    reason?: string;
    rejectedFile?: string;
    approvedFile?: string;
  };

  //#region timestamp
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
  createdBy: IPersonalParticular | null;
  updatedBy: IUpdatedBy | null;
  deletedBy: IUpdatedBy | null;
  pdfUrl?: string;
  //#endregion
}

export interface IEcddCustomer {
  id: number;
  ecddId: number;
  ecdd: IEcdd;
  type: EEcddCustomerType;
  name: string;
  beneficialOwner: string;
  nricfin: string;
  idTypeId: number;
  idType: unknown;
  dateOfBirth: string;
  dateOfBirthYYYYMMDD: string;
  dateOfBirthDDMMYYYY: string;
  nationalityId: number;
  nationalityObj: INationality;
  nationality: string;
  occupation: string;
  countryId: number;
  country: string;
  countryObj: ICountry;
  registrationNumber: string;
  identifyingType: EEcddIdentityType | string;
  mainBusiness: string;
  signature: string;
}

export interface IEcddOption {
  id: number;
  description: string;
  optionGroupId: number;
  autoFill: number[];
}

export interface IEcddOptionGroup {
  id: number;
  ecddId: number;
  ecdd: IEcdd;
  optionGroupId: number;
  optionGroup?: IEcddOptionGroup;
  remarks: string;
  metadata: unknown;
  choosenOption: IEcddOption;
  choosenOptionId: number;
  sequence: number;
  options: IEcddOption[];
  parentId: number;
  name: string;
  step: string;
  html: string;
  css: string;
  tags: string;
  depends: number[][];
  appear: number;
  children: IEcddOptionGroup[];
}

export interface IEcddCheckList {
  id: number;
  type: EEcddTransactionType;
  isDefault: boolean;
  isActive: boolean;
  checklistOptionGroups: IEcddOptionGroup[];
}
