import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { DeveloperContactPersonUpdateDto, DeveloperContactPersonCreateDto } from '@shared/data-access/dto';
import { IDeveloper } from '@shared/data-access/interfaces';
import { ContactModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeveloperContactApiService {
  static ROOT_POINT = 'transaction/developer-contacts';

  constructor(private apiService: ApiService) {}

  create(data: DeveloperContactPersonCreateDto): Observable<ContactModel> {
    return this.apiService.post(`${DeveloperContactApiService.ROOT_POINT}`, data).pipe(map((res: IDeveloper) => ContactModel.fromJson(res) as ContactModel));
  }
  get(itemId: number): Observable<ContactModel> {
    return this.apiService.get(DeveloperContactApiService.ROOT_POINT + '/' + itemId).pipe(map(res => ContactModel.fromJson(res) as ContactModel));
  }
  getAddressDefaultByDeveloperId(developerId: number, option?: IApiOption): Observable<ContactModel | null> {
    const query = {
      developerId,
      isDefault: true,
    };
    return this.apiService.get(DeveloperContactApiService.ROOT_POINT, query, option).pipe(
      map(data => new PaginationAdapter(ContactModel, data)),
      map(data => {
        if (data.results.length > 0) {
          return data.results[0];
        }
        return null;
      })
    );
  }
  update(id: number, data: DeveloperContactPersonUpdateDto): Observable<any> {
    return this.apiService.patch(DeveloperContactApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ContactModel>> {
    return this.apiService.get(DeveloperContactApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ContactModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(DeveloperContactApiService.ROOT_POINT, { id }, option);
  }
}
