import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class LookupTableCellDto extends BaseModel {
  @Expose()
  fieldName!: string;

  @Expose()
  value!: string;
}

export class LookupTableDto extends BaseModel {
  @Expose()
  lookupSetupId!: number;

  @Expose()
  @Type(() => LookupTableCellDto)
  cells!: LookupTableCellDto[];
}
