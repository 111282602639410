import { IPhoneNumber } from '@red/data-access';
import { EBuyerPropertyType, EBuyerType, ECitizen, EGender, ENumberOf, EPurpose } from '../enums';
import { IAddress, IAddressBuyerDto } from './address.interface';
import { IContactCategory } from './contact-category.interface';
import { IDistrict } from './district.interface';
import { IMarital } from './marital.interface';
import { INationality } from './nationality.interface';
import { ITimestamp } from './timestamp.interface';

export interface IBlankFormBuyer extends ITimestamp {
  id: number;
  name: string;
  type: EBuyerType;
  gender: EGender;
  marital: IMarital;
  maritalId: number;
  propertyType: EBuyerPropertyType;
  categoryId: number;
  category: IContactCategory;
  postal: string;
  nricNo: string;
  yearOfBirth: number;
  address: IAddress;
  citizen: ECitizen;
  nationalityId: number;
  nationality: string;
  nationalityObj: INationality;
  mobile: IPhoneNumber;
  email: string;
  ownership: ENumberOf;
  occupation: string;
  districtId: number;
  district: IDistrict;
  isHdbOwnership: boolean;
  purposeOfPurchase: EPurpose;
}

export interface IBlankFormBuyerInfo {
  remark: string;
}

export interface IBlankFormBuyerDto {
  id: number;
  name: string;
  type: EBuyerType;
  gender: EGender;
  maritalId: number;
  propertyType: EBuyerPropertyType;
  categoryId: number;
  postal: string;
  nricNo: string;
  yearOfBirth: number;
  address: IAddressBuyerDto;
  email: string;
  mobile: IPhoneNumber;
  citizen: ECitizen;
  nationalityId: number;
  districtId: number;
  occupation: string;
  ownership: ENumberOf;
  isHdbOwnership: boolean;
  purposeOfPurchase: EPurpose;
}
