import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { IRawGstCategoryData } from '@shared/data-access/interfaces';
import { GstCategoryLookupModel } from '@shared/data-access/models';
import { GstCategoryAppearanceChoice } from '@shared/data-access/types';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GstCategoryLookupApiService {
  static ROOT_POINT = 'finance/gst-category';

  constructor(private apiService: ApiService) {}

  search(query = {}, type?: GstCategoryAppearanceChoice, option?: IApiOption): Observable<GstCategoryLookupModel[]> {
    const finalQuery = type ? { type, ...query } : query;
    return this.apiService
      .get(GstCategoryLookupApiService.ROOT_POINT, finalQuery, option)
      .pipe(map((results: IRawGstCategoryData[]) => results.map(item => GstCategoryLookupModel.fromJson(item))));
  }
}
