import { BaseModel, Default } from '@red/data-access';
import { Expose } from 'class-transformer';
import { EResaleDocumentType } from '../enums';
import { IResaleDocument } from '../interfaces/resale-document.interface';

export class ResaleDocumentModel extends BaseModel implements IResaleDocument {
  @Expose()
  id!: number;

  @Expose()
  resaleId!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  name!: string;

  @Expose()
  url!: string;

  @Expose()
  size!: number;

  @Expose()
  @Default(EResaleDocumentType.others)
  type!: EResaleDocumentType;

  @Expose()
  publicLink!: string;

  @Expose()
  @Default(false)
  isDocsHip!: boolean;

  @Expose()
  path!: string;

  @Expose()
  uploadedAt!: string;
}
