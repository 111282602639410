export const COBROKE_TYPES = [
  {
    id: 'internalCobroke',
    name:'Internal Co-Broke (ICB)'
  },{
    id: 'tagger',
    name:'Tagger'
  },{
    id: 'internalCobrokeIncentive',
    name:'Internal Co-Broke (ICB) Incentive'
  },{
    id: 'taggerIncentive',
    name:'Tagger Incentive'
  },{
    id: 'icIncentive',
    name:'IC Incentive'
  }
]
