import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ReferenceConfigDto } from '@shared/data-access/dto';
import { ICompany } from '@shared/data-access/interfaces';
import { CompanyModel, ReferenceConfigModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReferenceConfigApiService {
  static ROOT_POINT = 'settings/preferences/me';
  static COMPANY_POINT = 'settings/profile/companies';

  constructor(private _apiService: ApiService) {}

  get(): Observable<ReferenceConfigModel> {
    return this._apiService.get(ReferenceConfigApiService.ROOT_POINT).pipe(map(res => ReferenceConfigModel.fromJson(res) as ReferenceConfigModel));
  }
  getCompanies(): Observable<CompanyModel[]> {
    return this._apiService.get(ReferenceConfigApiService.COMPANY_POINT).pipe(map((res: ICompany[]) => res.map(item => CompanyModel.fromJson(item))));
  }
  update(data: ReferenceConfigDto): Observable<unknown> {
    return this._apiService.patch(ReferenceConfigApiService.ROOT_POINT, data);
  }
}
