import { FormControl } from '@angular/forms';
import { Observable, Subject, takeUntil, map } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
interface MetabaseParameterItemSelected {
  key: string;
  value: any;
}
@Injectable()
export class MetabaseParameterService implements OnDestroy {
  private parameters$ = new Subject<Map<string, any>>();
  private parameters = new Map<string, any>();
  private formCtrCollection = new Map<string, { unsubscriber: Subject<void>; form: FormControl }>();
  connect(): Observable<Map<string, any>> {
    return this.parameters$.asObservable();
  }

  select(data: { values: MetabaseParameterItemSelected | MetabaseParameterItemSelected[]; configs?: { emitEvent: boolean } }): void {
    const values = Array.isArray(data.values) ? data.values : [data.values];
    values.forEach(value => this._markSelected(value));
    if (data.configs?.emitEvent) {
      this.parameters$.next(this.parameters);
    }
  }
  register(...values: { key: string; formCtr: FormControl }[]): void {
    values.forEach(value => {
      const payload = {
        unsubscriber: new Subject<void>(),
        form: value.formCtr,
      };
      this.formCtrCollection.set(value.key, payload);
      payload.form.valueChanges
        .pipe(
          map(val => {
            this.select({ values: { key: value.key, value: val }, configs: { emitEvent: true } });
          }),
          takeUntil(payload.unsubscriber)
        )
        .subscribe();
    });
  }
  clear(configs?: { emitEvent: boolean }): void {
    this.parameters.clear();
    this.formCtrCollection.forEach(item => {
      item.unsubscriber.next();
      item.unsubscriber.complete();
    });
    this.formCtrCollection.clear();
    if (configs?.emitEvent) {
      this.parameters$.next(this.parameters);
    }
  }
  private _markSelected(value: MetabaseParameterItemSelected): void {
    this.parameters.set(value.key, value.value);
  }
  ngOnDestroy(): void {
    this.parameters$.complete();
  }
}
