import { Component, HostBinding, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExceljsWorkSheet } from '../models';
import { ExceljsTableComponent } from '../exceljs-table/exceljs-table.component';
import { ExceljsRowComponent } from '../exceljs-row/exceljs-row.component';
import { ExceljsCellComponent } from '../exceljs-cell/exceljs-cell.component';
import { MatTableModule } from '@angular/material/table';
import { ExceljsWorkbookComponent } from '../exceljs-workbook/exceljs-workbook.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'red-exceljs-worksheet',
  standalone: true,
  imports: [CommonModule, ExceljsTableComponent, ExceljsRowComponent, ExceljsCellComponent],
  templateUrl: './exceljs-worksheet.component.html',
  styleUrls: ['./exceljs-worksheet.component.scss'],
})
export class ExceljsWorksheetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() worksheet!: ExceljsWorkSheet;
  @ViewChild(ExceljsTableComponent, { static: true }) table!: ExceljsTableComponent;
  isActive = false;
  @HostBinding('class.exceljs-worksheet--active') get activedWorksheet() {
    return this.isActive === true;
  }
  /** Emits whenever the internal state of the tab changes. */
  readonly _stateChanges = new Subject<void>();
  constructor(@Inject(ExceljsWorkbookComponent) @Optional() public _closestWorkBook: ExceljsWorkbookComponent) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if ('worksheet' in changes) {
      this._stateChanges.next();
    }
  }

  ngOnDestroy(): void {
    this._stateChanges.complete();
  }
}
