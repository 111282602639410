import { CdkPortal } from "@angular/cdk/portal";
import { Directive, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
  selector:'[redGroupActionHeader]'
})
export class GroupActionHeaderDirective  extends CdkPortal{
  constructor(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
  ){
    super(templateRef,viewContainerRef);
  }
} 