// core
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// share
import { PromotionApprovalVerifyDto } from '@shared/data-access/dto';
import { IPromotionApproval } from '@shared/data-access/interfaces';
import { PromotionApprovalModel } from '@shared/data-access/models';
import { PaginationAdapter } from '@red/data-access';
import { ApiService, IApiOption } from '@red/api';

@Injectable({ providedIn: 'root' })
export class PromotionApprovalApiService {
  static ROOT_POINT = 'hrm/promotions';

  constructor(private _apiService: ApiService) {}

  create(data: PromotionApprovalVerifyDto): Observable<PromotionApprovalModel> {
    return this._apiService
      .post(PromotionApprovalApiService.ROOT_POINT, data)
      .pipe(map((res: IPromotionApproval) => PromotionApprovalModel.fromJson(res) as PromotionApprovalModel));
  }

  get(id: number): Observable<PromotionApprovalModel> {
    return this._apiService
      .get([PromotionApprovalApiService.ROOT_POINT, id].join('/'))
      .pipe(map(res => PromotionApprovalModel.fromJson(res) as PromotionApprovalModel));
  }

  update(id: number, data: PromotionApprovalVerifyDto): Observable<unknown> {
    return this._apiService.patch([PromotionApprovalApiService.ROOT_POINT, id].join('/'), data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PromotionApprovalModel>> {
    return this._apiService.get(PromotionApprovalApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(PromotionApprovalModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<unknown> {
    return this._apiService.delete(PromotionApprovalApiService.ROOT_POINT, { ids }, option);
  }

  action(id: number, dto: PromotionApprovalVerifyDto): Observable<unknown> {
    return this._apiService.post([PromotionApprovalApiService.ROOT_POINT, id, 'action'].join('/'), dto, { excludeFields: [''] });
  }
}
