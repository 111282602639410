import { Injectable, inject } from '@angular/core';
import { ApiService } from '@red/api';
import { BaseModel } from '@red/data-access';
import {
  ImportARDto,
  ImportChartOfAccountsDto,
  ImportContactDto,
  ImportJournalEntryDto,
  ImportPaymentDto,
  ImportReceiptDto,
  ImportSupplierInvoiceDto,
} from '@shared/data-access/dto';
import {
  ImportJournalEntryModel,
  ImportChartOfAccountModel,
  ImportContactModel,
  ImportPaymentModel,
  ImportReceiptModel,
  ImportSupplierInvoiceModel,
  ImportARModel,
} from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

export abstract class ImportApiBaseService<T extends BaseModel, DTO extends BaseModel> {
  abstract endPoint: string;

  private apiService = inject(ApiService);

  downloadTemplate(query = {}): Observable<any> {
    return this.apiService.post(this.endPoint + '/template', query, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  readFile(file: File): Observable<T[]> {
    const formData = new FormData();
    formData.append('file', file);

    return this.apiService
      .post(this.endPoint + '/reader', formData, {
        pretreatmentResponse: false,
        requestOptions: { reportProgress: true },
      })
      .pipe(map(res => res.data));
  }

  import(items: DTO): Observable<any> {
    return this.apiService.post(this.endPoint, items);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ImportContactApiService extends ImportApiBaseService<ImportContactModel, ImportContactDto> {
  endPoint = 'finance/import/contact';
}

@Injectable({
  providedIn: 'root',
})
export class ImportChartOfAccountApiService extends ImportApiBaseService<ImportChartOfAccountModel, ImportChartOfAccountsDto> {
  endPoint = 'finance/import/account';
}

@Injectable({
  providedIn: 'root',
})
export class ImportReceiptApiService extends ImportApiBaseService<ImportReceiptModel, ImportReceiptDto> {
  endPoint = 'finance/import/receipt';
}

@Injectable({
  providedIn: 'root',
})
export class ImportPaymentApiService extends ImportApiBaseService<ImportPaymentModel, ImportPaymentDto> {
  endPoint = 'finance/import/payment';
}

@Injectable({
  providedIn: 'root',
})
export class ImportJournalEntryApiService extends ImportApiBaseService<ImportJournalEntryModel, ImportJournalEntryDto> {
  endPoint = 'finance/import/journal';
}

@Injectable({
  providedIn: 'root',
})
export class ImportSupplierInvoiceApiService extends ImportApiBaseService<ImportSupplierInvoiceModel, ImportSupplierInvoiceDto> {
  endPoint = 'finance/import/supplier-invoice';
}

@Injectable({
  providedIn: 'root',
})
export class ImportARApiService extends ImportApiBaseService<ImportARModel, ImportARDto> {
  endPoint = 'finance/import/tax-invoice';
}
