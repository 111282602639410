import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { ContactAddressType } from '../enums';

export class ContactAddressDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  address?: string;

  @Expose()
  type!: ContactAddressType;

  @Expose()
  default?: boolean;

  @Expose()
  country!: string;

  @Expose()
  @Default('')
  postcode!: string;

  @Expose()
  @Default('')
  buildingName?: string;

  @Expose()
  @Default('')
  district?: string;

  @Expose()
  @Default('')
  floor?: string;

  @Expose()
  @Default('')
  house!: string;

  @Expose()
  @Default('')
  street!: string;

  @Expose()
  @Default('')
  unit?: string;
}
