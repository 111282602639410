import { ExternalCobrokeReferralModel } from './external-referral.model';
import { SplitPartModel } from './split-part.model';
import { IProjectTransactionCobrokeReferral } from './../interfaces/project-transaction-cobroke-referral.interface';
import { BaseModel, Default } from '@red/data-access';
import { IReferralExternal, IPersonalParticular, ISplitMatrix, ISplitPart } from '../interfaces';
import { Expose, Transform, Type } from 'class-transformer';
import { SplitMatrixModel } from './split-matrix.model';
import { PersonalParticularModel } from './personal-particular.model';
import { ReferralExternalModel } from './referral-external.model';
import { EAgentSession } from '../enums';
import { FORMULA_REFERRAL } from '../data/formula-referral.data';
import { transform } from 'lodash';

export class ProjectTransactionCobrokeReferralModel extends BaseModel implements IProjectTransactionCobrokeReferral {
  @Expose()
  id!: number;

  @Expose()
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  agentId!: number;

  @Expose()
  agentType!: EAgentSession;

  @Expose()
  grouping!: string;

  @Expose()
  @Transform(({ obj }) => {
    switch (obj.grouping) {
      case 'refFormulaA': {
        return 'REF FORMULA - A'
      }
      case 'refFormulaB': {
        return 'REF FORMULA - B'
      }
      case 'refFormulaC': {
        return 'REF FORMULA - C'
      }
      case 'refFormulaD': {
        return 'REF FORMULA - D'
      }
      default:
        obj.grouping
    }
    return obj.grouping
  })
  groupingDisplayName!: string

  @Expose()
  @Type(() => ReferralExternalModel)
  agent!: ReferralExternalModel;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  @Transform(({ obj, value }) => {
    if (!value || !value?.id) {
      return obj?.tiers?.[0]?.salesperson;
    }
    return value;
  })
  salesperson!: PersonalParticularModel;

  @Expose()
  fee!: number;

  @Expose()
  formula!: string;

  @Expose()
  type!: 'internal' | 'external';

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => SplitMatrixModel)
  splitMatrix!: SplitMatrixModel;

  @Expose()
  receivedDirectPercent!: number;

  @Expose()
  receivedPercent!: number;

  @Expose()
  receivedValue!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  @Default([])
  @Type(() => SplitPartModel)
  parties!: SplitPartModel[];

  @Expose()
  @Default([])
  @Type(() => SplitPartModel)
  tiers!: ISplitPart[];

  @Expose({ groups: ['summary'] })
  totalAmount!: number;

  @Expose({ groups: ['summary'] })
  @Transform(({ obj }) => {
    return obj?.receivedValue - obj?.totalAmount;
  })
  companyAmount!: number;

  @Expose({ groups: ['summary'] })
  @Transform(({ obj }: { obj: IProjectTransactionCobrokeReferral }) => {
    return obj.type === 'internal' ? (obj?.tiers[0] ? obj?.tiers[0]?.value : undefined) : obj?.receivedValue;
  })
  commSchemeAmount!: number;
}
