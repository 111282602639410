import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { SupplierCreditNoteDto, SupplierCreditNotePostingReqDto } from '@shared/data-access/dto';
import { ISupplierCreditNote, ISupplierCreditNotePayment, ISupplierCreditNotePosting } from '@shared/data-access/interfaces';
import {
  PaymentMemoModel,
  ReceiptMemoModel,
  SupplierCreditNoteModel,
  SupplierCreditNotePostingModel,
  SupplierCreditNoteRecordModel,
  SupplierInvoiceRecordModel,
} from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupplierCreditNoteEnhancedApiService {
  static ROOT_POINT = 'finance/credit-notes/suppliers';
  static PAYMENTS_POINT = 'finance/credit-notes/suppliers/:id/payments';
  static PREVIEW_POSTING = 'finance/credit-notes/suppliers/postings/preview';
  static FOR_PAYMENT_POINT = 'finance/credit-notes/suppliers/for-payments';
  static FOR_RECEIPT_POINT = 'finance/credit-notes/suppliers/for-receipts';
  static SUPPLIER_INVOICE_DETAILS_POINT = 'finance/supplier-invoices/details/for-supplier-credit-notes';

  constructor(private apiService: ApiService, private _router: Router) { }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<SupplierCreditNoteModel>> {
    return this.apiService
      .get(SupplierCreditNoteEnhancedApiService.ROOT_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(SupplierCreditNoteModel, data)));
  }

  get(id: number, query = {}): Observable<SupplierCreditNoteModel> {
    return this.apiService
      .get(SupplierCreditNoteEnhancedApiService.ROOT_POINT + '/' + id, query)
      .pipe(map(res => SupplierCreditNoteModel.fromJson(res) as SupplierCreditNoteModel));
  }

  create(data: SupplierCreditNoteDto): Observable<SupplierCreditNoteModel> {
    return this.apiService
      .post(SupplierCreditNoteEnhancedApiService.ROOT_POINT, data)
      .pipe(map((res: ISupplierCreditNote) => SupplierCreditNoteModel.fromJson(res) as SupplierCreditNoteModel));
  }

  update(id: number, data: SupplierCreditNoteDto): Observable<SupplierCreditNoteModel> {
    return this.apiService.patch(SupplierCreditNoteEnhancedApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(SupplierCreditNoteEnhancedApiService.ROOT_POINT + '/' + id, {}, option);
  }

  getSupplierInvoiceDetails(query = {}, option?: IApiOption): Observable<PaginationAdapter<SupplierInvoiceRecordModel>> {
    return this.apiService
      .get(SupplierCreditNoteEnhancedApiService.SUPPLIER_INVOICE_DETAILS_POINT, query, option)
      .pipe(map(res => new PaginationAdapter(SupplierInvoiceRecordModel, res)));
  }

  getDetails(id: number, query = {}): Observable<PaginationAdapter<SupplierCreditNoteRecordModel>> {
    return this.apiService
      .get(SupplierCreditNoteEnhancedApiService.ROOT_POINT + '/' + id + '/details', query)
      .pipe(map(res => new PaginationAdapter(SupplierCreditNoteRecordModel, res)));
  }

  getPostings(id: number, query = {}): Observable<PaginationAdapter<SupplierCreditNotePostingModel>> {
    return this.apiService
      .get(SupplierCreditNoteEnhancedApiService.ROOT_POINT + '/' + id + '/postings', query)
      .pipe(map(res => new PaginationAdapter(SupplierCreditNotePostingModel, res)));
  }

  getPayments(id: number, query = {}): Observable<ISupplierCreditNotePayment[]> {
    return this.apiService.get(SupplierCreditNoteEnhancedApiService.PAYMENTS_POINT, { id, ...query });
  }

  getSupplierCreditNotesForPayment(query = {}, option?: IApiOption): Observable<PaginationAdapter<PaymentMemoModel>> {
    return this.apiService.get(SupplierCreditNoteEnhancedApiService.FOR_PAYMENT_POINT, query, option).pipe(map(res => new PaginationAdapter(PaymentMemoModel, res)));
  }

  getSupplierCreditNotesForReceipt(query = {}, option?: IApiOption): Observable<PaginationAdapter<ReceiptMemoModel>> {
    return this.apiService.get(SupplierCreditNoteEnhancedApiService.FOR_RECEIPT_POINT, query, option).pipe(map(res => new PaginationAdapter(ReceiptMemoModel, res)));
  }

  previewPosting(data: SupplierCreditNotePostingReqDto): Observable<SupplierCreditNotePostingModel[]> {
    return this.apiService
      .post(SupplierCreditNoteEnhancedApiService.PREVIEW_POSTING, data)
      .pipe(map(res => res.map((item: ISupplierCreditNotePosting) => SupplierCreditNotePostingModel.fromJson(item))));
  }
}
