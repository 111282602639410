
import { BaseModel } from "@red/data-access";
import {  Expose } from "class-transformer";
import { IDocument } from "../interfaces";


export class DocumentModel extends BaseModel implements IDocument{

  @Expose()
  id!:number
}
