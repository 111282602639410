import { ESupplierCreditNoteStatus } from '../enums';
import { EBalanceDocumentPostingType, ECustomerOrSupplier, EInvoiceCreateMode } from '../enums/tax-invoice-enhanced.enum';
import { CurrencyRateModel } from '../models';
import { IContactAddress } from './contact-address.interface';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IContactPerson } from './contact-person.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IProfitCentres } from './profit-centres.interface';
import { IStatusDescription } from './status-description.interface';
import { ISupplierInvoice } from './supplier-invoice-enhanced.interface';
import { ITaxInvoice } from './tax-invoice-enhanced.interface';
import { ITemplates } from './template.interface';
import { IUpdatedBy } from './updated-by.interface';

export interface ISupplierCreditNoteRecord {
  id?: number;

  itemCode?: string;
  description: string;

  amount?: number;
  // quantity: number;
  // unitPrice: number;
  // discount: number;

  gstCategory: string;
  gstInclusive: boolean;
  gstValue?: number;
  // gstPercent?: number;

  uom?: string;
  remarks?: string;

  account: ILedgerAccount;
  accountId: number;

  profitCenter: IProfitCentres;
  profitCenterId?: number;

  supplierInvoiceDetailId?: number;

  // taxInvoiceDetailId?: number;
  // taxInvoiceId?: number;
  // taxInvoice?: ITaxInvoice;
  // creditNoteId?: number;
}

export interface ISupplierCreditNotePosting {
  account?: ILedgerAccount;
  accountId: number;

  profitCenter?: IProfitCentres;
  profitCenterId?: number;

  description?: string;

  debit: number;
  credit: number;
  amount: number;

  currency?: string;

  // creditNoteId: number;
  // creditNoteDetailId?: number;
  // taxInvoiceDetail?: ITaxInvoiceDetail;

  type?: EBalanceDocumentPostingType;
}

export interface ISupplierCreditNote {
  // General
  contact: IContactCustomerAndSupplier;
  billingAddress: IContactAddress;
  contactId?: number;
  contactAddressId: number;

  creditNoteNumber?: string;
  creditNoteDate: string;

  contactPerson?: IContactPerson;
  contactPersonId?: number;

  supplierInvoices?: ISupplierInvoice[];
  inClosedPeriod?: boolean;

  taxInvoiceId?: number;
  taxInvoice?: ITaxInvoice;

  // Summary
  gstEdited: boolean;
  amount: number;
  discount: number;
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gst?: number;
  balanceDue?: number;

  // Other Details
  currencyId: number;
  currency: CurrencyRateModel;

  billingAddressCustom?: string;
  contactPersonCustom?: string;

  reference?: string;

  creditTerm?: number;
  paymentTerm?: number;

  salePersonId?: number;
  salePerson?: IPersonalParticular;

  remarks?: string;

  templateId?: number;
  template?: ITemplates;

  // General
  id: number;
  businessUnitId: number;
  status?: ESupplierCreditNoteStatus;
  createdMode?: EInvoiceCreateMode;
  type: ECustomerOrSupplier;
  parentInvoice?: ITaxInvoice;
  parentInvoiceId?: number;
  isPaid?: boolean;

  projectId?: number;
  paidAmount?: number;
  paidStatus?: IStatusDescription;
  isWithHold?: boolean;
  adjustment?: boolean;
  miscAdjustment?: boolean;

  createAt?: string;
  updateAt?: string;
  createdBy?: string;
  updatedBy?: IUpdatedBy;
}

export interface ISupplierCreditNoteSummary {
  amount: number;
  discount: number;
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gstEdited: boolean;
}

export interface ISupplierCreditNoteCalculateSummary {
  details: Partial<ISupplierCreditNoteRecord>[];
  summary: ISupplierCreditNoteSummary;
}

export interface ISupplierCreditNoteInitData {
  contact: IContactCustomerAndSupplier;
}

export interface ISupplierCreditNotePayment {
  amount: number;
  date: Date;
  id: number;
  referenceNo: string;
  type: string;
  // paymentContactType: ECustomerOrSupplier;
}
