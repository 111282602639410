import { EDebitNoteDetailStatus, EDebitNoteStatus } from '../enums';
import { StatusRecord } from '../types';

export const DEBIT_NOTE_STATUSES = [
  {
    id: EDebitNoteStatus.CANCELLED,
    name: 'Cancelled',
  },
  {
    id: EDebitNoteStatus.ABORTED,
    name: 'Aborted',
  },
  {
    id: EDebitNoteStatus.PARTIAL,
    // name: 'Partial Debit Note',
    name: 'Cancelled Partial',
  },
];

export const DEBIT_NOTE_PAID_STATUS: StatusRecord<EDebitNoteDetailStatus> = {
  [EDebitNoteDetailStatus.View]: {
    id: EDebitNoteDetailStatus.View,
    name: 'View',
    detailName: 'View',
    class: 'status-box-default',
    detailClass: 'status-box-default',
  },
  // [EDebitNoteDetailStatus.UnPaid]: {
  //   id: EDebitNoteDetailStatus.UnPaid,
  //   name: 'Un-Paid',
  //   filterName: 'Un-Paid',
  //   class: 'status-box-warning',
  // },
  [EDebitNoteDetailStatus.PartPaid]: {
    id: EDebitNoteDetailStatus.PartPaid,
    name: 'Part Paid',
    detailName: 'Part Paid',
    class: 'status-box-warning',
    detailClass: 'status-box-warning',
  },
  [EDebitNoteDetailStatus.FullyPaid]: {
    id: EDebitNoteDetailStatus.FullyPaid,
    name: 'Fully Paid',
    detailName: 'Fully Paid',
    class: 'status-box-success',
    detailClass: 'status-box-success',
  },
  [EDebitNoteDetailStatus.Cancelled]: {
    id: EDebitNoteDetailStatus.Cancelled,
    name: 'Cancelled',
    detailName: 'Void',
    class: 'status-box-smokey-topaz',
    detailClass: 'status-box-smokey-topaz',
  },
  // [EDebitNoteDetailStatus.Confirmed]: {
  //   id: EDebitNoteDetailStatus.Confirmed,
  //   name: 'Confirmed',
  //   filterName: 'Confirmed',
  //   class: 'status-box-success',
  // },
  // [EDebitNoteDetailStatus.Withhold]: {
  //   id: EDebitNoteDetailStatus.Withhold,
  //   name: 'Withhold',
  //   filterName: 'Withhold',
  //   class: 'status-box-electric-orange',
  // },
  // [EDebitNoteDetailStatus.PaymentInProgress]: {
  //   id: EDebitNoteDetailStatus.PaymentInProgress,
  //   name: 'Payment In Progress',
  //   filterName: 'Payment In Progress',
  //   class: 'status-box-grape',
  // },
};
