import { BaseModel, FormattedDateString, IPhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ContactType, Status } from '../enums';
import { ContactEmailBaseDto } from './contact-base.dto';

export class CustomerDto extends BaseModel {
  @Expose()
  type!: ContactType;

  @Expose()
  name!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => value ?? null)
  commerceDate!: string | null;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  @Transform(({ value }) => value.filter((item: ContactEmailBaseDto) => item.email))
  @Type(() => ContactEmailBaseDto)
  emails!: ContactEmailBaseDto[];

  @Expose()
  phone!: IPhoneNumber;

  @Expose()
  fax!: string;

  @Expose()
  status!: Status;

  @Expose()
  @Type(() => Number)
  creditTerm!: number;

  @Expose()
  @Type(() => Number)
  creditLimit!: number;

  @Expose()
  @Type(() => Number)
  receiveAccountId!: number;

  @Expose()
  @Type(() => Number)
  paidAccountId!: number;

  @Expose()
  businessRegNo!: string;

  @Expose()
  gstRegistered!: boolean;

  @Expose()
  gstRegNo!: string;

  @Expose()
  remark!: string;
}
