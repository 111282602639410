import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, filter, take, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthProvider } from './auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LOGIN_URL } from './auth.token';
import { AuthUtils } from './auth.utils';
import { AuthInterceptor } from './auth.interceptor';
import { cloneDeep, map } from 'lodash-es';

@Injectable()
export class AuthWithRefreshTokenInterceptor extends AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  /**
   * Constructor
   */

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  override intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('source') !== 'ApiService') {
      return next.handle(req);
    }
    // Clone the request object
    let clonedReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (this._authService.accessToken && (!AuthUtils.isTokenExpired(this._authService.accessToken) || req.url.indexOf('access-token') !== -1)) {
      clonedReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken),
      });
    }
    // Response
    return next.handle(clonedReq).pipe(
      catchError(error => {
        console.log('error ->', error);
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (clonedReq.body?.refreshToken) {
            console.log('clonedReq.body?.refreshToken ->', clonedReq.body);
            // refresh token is invalid, errorCode maybe 30016
            this.forceLogout();
            return throwError(() => error);
          }
          if (this.router.url.indexOf(this.loginUrl) === -1) {
            if (this._forcedLogoutFn && this._forcedLogoutFn(error)) {
              this.forceLogout();
            } else if (this.refreshTokenInProgress) {
              console.log('refreshTokenInProgress');
              return this.refreshTokenSubject.pipe(
                filter(val => val !== null),
                take(1),
                switchMap(() => {
                  const newReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken),
                  });
                  console.log('refreshTokenInProgress next');
                  return next.handle(newReq);
                })
              );
              // } else {
              //   this.refreshTokenInProgress = true;
              //   this.refreshTokenSubject.next(null);
              //   console.log('start to call api refreshToken');
              //   return this._authService.signInUsingRefreshToken().pipe(
              //     switchMap(isAuth => {
              //       console.log('isAuth --> ', isAuth);
              //       if (isAuth) {
              //         this.refreshTokenSubject.next(true);
              //         this.refreshTokenInProgress = false;
              //         const newReq = req.clone({
              //           headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken),
              //         });
              //         return next.handle(newReq);
              //       } else {
              //         // expire token
              //         return throwError(() => error);
              //       }
              //     })
              //   );
              // }
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);
              console.log('start to call api refreshToken');
              this.triggerRefreshToken();
              return this.intercept(req, next)
            }
          }
        }
        return throwError(() => error);
      })
    );
  }
  triggerRefreshToken(): void {
    this._authService.signInUsingRefreshToken().pipe(
      tap(isAuth => {
        console.log('isAuth --> ', isAuth);
        if (isAuth) {
          this.refreshTokenSubject.next(true);
          this.refreshTokenInProgress = false;
        } else {
          // expire token
        }
      })).subscribe()

  }
}
