import { IGrossCommissionEarned } from './../interfaces';
import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class GrossCommissionEarnedModel extends BaseModel implements IGrossCommissionEarned {
  @Expose()
  amount!: number;

  @Expose()
  currency!: string;

  @Expose()
  grossType!: string;

  @Expose()
  includeGst!: boolean;

  @Expose()
  subTotalAmount!: number;

  @Expose()
  taxAmount!: number;

  @Expose()
  taxType!: 'number' | 'percentage';

  @Expose()
  taxValue!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  isStupidFollow!: boolean;
}
