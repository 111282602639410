import { BaseModel, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class InvoiceWriteoffCreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  taxInvoiceId!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  writeOffDate!: Date;

  @Expose()
  @Type(() => Number)
  debitAccountId!: number;

  @Expose()
  @Type(() => InvoiceWriteoffPostingDto)
  postings?: InvoiceWriteoffPostingDto[];
}

export class InvoiceWriteoffPostingDto extends BaseModel {
  @Expose()
  id?: number;

  @Expose()
  accountId!: number;

  @Expose()
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  credit!: number;

  @Expose()
  debit!: number;

  @Expose()
  amount!: number;
}

export class InvoiceWriteoffUpdateDto extends BaseModel {
  @Expose()
  name!: string;
}

export class InvoiceWriteoffPostingReqStateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  taxInvoiceId!: number;

  @Expose()
  @Type(() => Number)
  debitAccountId?: number;
}
