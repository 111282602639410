import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EBlankFormReferralSourceType, EManagementCommissionPdRole } from '../enums';
import { IProjectTransaction } from '../interfaces';
import { BlankFormBdModel } from './blank-form-bd.model';
import { BlankFormIcModel } from './blank-form-ic.model';
import { BlankFormOtherFeeModel } from './blank-form-other-fee.model';
import { BlankFormPdModel } from './blank-form-pd.model';
import { BlankFormReferralModel } from './blank-form-referral.model';

export class BlankFormCommValueModel extends BaseModel {
  @Expose()
  transaction!: IProjectTransaction;

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  bonuses!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  overridings!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  pools!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormPdModel)
  pds!: BlankFormPdModel[];

  get pdOverride(): BlankFormPdModel[] {
    return this.pds.filter(referral => referral.pdRole === EManagementCommissionPdRole.PDOverride);
  }

  get pdReferral(): BlankFormPdModel[] {
    return this.pds.filter(referral => referral.pdRole === EManagementCommissionPdRole.PDReferral);
  }

  get pdManagement(): BlankFormPdModel[] {
    return this.pds.filter(referral => referral.pdRole === EManagementCommissionPdRole.PDManagement && !referral.additional);
  }

  get pd(): BlankFormPdModel[] {
    return this.pds.filter(referral => referral.pdRole === EManagementCommissionPdRole.PDManagement && referral.additional === 1);
  }

  @Expose()
  @Default([])
  @Type(() => BlankFormReferralModel)
  referrals!: BlankFormReferralModel[];

  get referralsInternal(): BlankFormReferralModel[] {
    return this.referrals.filter(referral => referral.type === EBlankFormReferralSourceType.internal);
  }

  get referralsExternal(): BlankFormReferralModel[] {
    return this.referrals.filter(referral => referral.type === EBlankFormReferralSourceType.external);
  }

  @Expose()
  @Default([])
  @Type(() => BlankFormOtherFeeModel)
  otherFees!: BlankFormOtherFeeModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormBdModel)
  bds!: BlankFormBdModel[];
}
