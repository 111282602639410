import { EPaymentReversalStatus, InvoicePostingType } from '../enums';
import { PaymentReversalType } from '../types';
import { IChequeCompact } from './cheque.interface';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IDealValue } from './deal-value.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IProfitCentres } from './profit-centres.interface';

export interface IPaymentReversal {
  id: number;
  businessUnitId: number;
  code: string;
  status: EPaymentReversalStatus;

  contactId?: number;
  contact?: IContactCustomerAndSupplier;
  contactName?: string;
  contactCode?: string;

  reversalDate: Date;
  payerName?: string;
  remark?: string;
  total?: number;

  bankAccountId?: number;
  accountId?: number;
  account?: ILedgerAccount;

  chequeId?: number;
  chequeNumber?: string;
  cheque?: IChequeCompact;

  paymentCode?: string;
  receiptCode?: string;

  sourceId: number;
  sourceDate?: Date;
  type: PaymentReversalType;
  inClosedPeriod?: boolean;

  createdAt: Date;
  updatedAt: Date;
}

export interface IPaymentReversalPosting {
  id: number;
  reversalId: number;

  accountId: number;
  account: ILedgerAccount;

  profitCenterId?: number;
  profitCenter?: IProfitCentres;

  paymentId?: number;
  receiptId?: number;

  description: string;

  credit: number;
  debit: number;

  amount: number;
  sequence?: number;
  type?: `${InvoicePostingType}`;
}

export interface IPaymentReversalPostingDataSource {
  postings: IPaymentReversalPosting[];
  total: number;
}

export interface IPaymentReversalRecordMetadata {
  taxPercent: number;
  isEdit: boolean;
}

export interface IPaymentReversalRecord {
  account: ILedgerAccount;
  accountId: number;
  amount: number;
  businessUnitId?: number;

  credit: IDealValue;
  debit: IDealValue;

  description: string;
  gstCategory: string;
  gstType?: 'gstExclusive' | 'gstInclusive';

  id?: number;

  isTaxable: boolean;
  metadata: IPaymentReversalRecordMetadata;

  remarks?: string;
  sequence?: number;

  taxAccount?: ILedgerAccount;
  taxAccountId?: number;
  taxAmount?: number;

  taxDescription?: string;
  taxUnit?: 'percentage' | 'number';
  taxValue?: number;

  profitCenter?: IProfitCentres;
  profitCenterId?: number;

  sourceId: number;
  code?: string;
  type: string;
  // total: number;
}
