export enum EResaleAdjustmentStatus {
  draft = 'draft',
  pending = 'pending',
  submitted = 'submitted',
  rework = 'rework',
  confirmed = 'confirmed',
  rework2 = 'rework2',
  verified = 'verified',
  approved = 'approved',
  rejected = 'rejected',
  completed = 'completed',
}
