import { IQuerySearch } from '@shared/data-access/interfaces';
import { Injectable } from '@angular/core';
import { ApiService } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { TemplateType } from '@shared/data-access/enums';
import { TemplateModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TemplatesApiService {
  static ROOT_POINT = 'finance/templates';

  constructor(private apiService: ApiService) {}

  getTemplates(query: { types: TemplateType } | IQuerySearch): Observable<PaginationAdapter<TemplateModel>> {
    return this.apiService.get(TemplatesApiService.ROOT_POINT, query).pipe(map(res => new PaginationAdapter(TemplateModel, res)));
  }
}
