import { LookupTableDetailedFormFieldType } from '../types';

export interface ILookupTableColumn {
  displayName: string;
  fieldName: string;
  fieldType: LookupTableDetailedFormFieldType;
  fieldValidate: any;
  sequence?: number;
}

export interface ILookupTableDetailedFormFieldOption {
  value: any;
  label: string;
}

export interface ILookupTableCell {
  fieldName: string;
  value: any;

  // fmt: string;
  // type!: ValueTypeEnum;
}

export interface ILookupTable {
  id: number;
  createdAt: string;
  updatedAt: string;
  companyAccess: boolean;
  category: string;
  type: string;
  key: string;
  description: string;
  cols: ILookupTableColumn[];
}

export interface ILookupTableDetail {
  id: number;
  createdAt: string;
  updatedAt: string;
  lookupSetupId: number;
  cells: ILookupTableCell[];
}
