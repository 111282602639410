<cdk-virtual-scroll-viewport
  *ngIf="isVirtualSrollMode"
  redExceljsVirtualScrollController
  [itemSize]="defaultRowHeight"
>
  <table class="exceljs-table">
    <tr
      class="exceljs-row"
      *ngFor="let row of dataHeader; let idx = index"
      [style.height.px]="defaultRowHeight"
      [style.position]="'sticky'"
      [style.top.px]="scrollController?.topHeader ?? 0 + (idx * defaultRowHeight)"
      [style.zIndex]="1"
    >
      <th
        class="exceljs-cell"
        *ngFor="let cell of row.cells; let i = index"
        [style.width.px]="cell | colWidth: cols:defaultColWidth"
        [style.maxWidth.px]="cell | colWidth: cols:defaultColWidth"
        [style.minWidth.px]="cell | colWidth: cols:defaultColWidth"
        [style.textAlign]="cell.style.alignment?.horizontal || ''"
        [style.verticalAlign]="cell.style.alignment?.vertical || ''"
        [style.backgroundColor]="'#' + (cell.style.fill?.fgColor?.argb || defaultBackgroundColor)"
        [style.borderLeftWidth]="cell.style.border?.left?.style"
        [style.borderRightWidth]="cell.style.border?.right?.style"
        [style.borderTopWidth]="cell.style.border?.top?.style"
        [style.borderBottomWidth]="cell.style.border?.bottom?.style"
        [style.borderLeftColor]="'#' + cell.style.border?.left?.color?.argb"
        [style.borderRightColor]="'#' + cell.style.border?.right?.color?.argb"
        [style.borderTopColor]="'#' + cell.style.border?.top?.color?.argb"
        [style.borderBottomColor]="'#' + cell.style.border?.bottom?.color?.argb"
        [style.borderStyle]="cell.style.fill?.pattern"
        [class.wrap-text]="cell.style.alignment?.wrapText"
        [attr.colspan]="cell.attrs.colspan"
        [attr.rowspan]="cell.attrs.rowspan"
      >
        <ng-container [ngSwitch]="cell.type">
          <red-exceljs-cell-hyperlink *ngSwitchCase="exceljsValueType.HYPERLINK" [cell]="cell">
            <ng-container
              [ngTemplateOutlet]="rawTemplate"
              [ngTemplateOutletContext]="{
                cell: cell
              }"
            >
            </ng-container>
          </red-exceljs-cell-hyperlink>

          <ng-container
            *ngSwitchDefault
            [ngTemplateOutlet]="rawTemplate"
            [ngTemplateOutletContext]="{
              cell: cell
            }"
          >
          </ng-container>
        </ng-container>

        <ng-template #rawTemplate let-cell="cell">
          <span
            redClearEnter
            [style.backgroundColor]="'#' + (cell.style.fill?.fgColor?.argb || defaultBackgroundColor)"
            [style.fontWeight]="cell.style.font.bold ? 'bold' : ''"
            [style.fontSize.px]="cell.style.font.size"
            [style.color]="'#' + cell.style.font.color?.argb"
            >{{ cell.normalizedValue | cellValueFmt: cell.type:cell.style.numFmt }}</span
          >
        </ng-template>
      </th>
    </tr>
    <tr class="exceljs-row" *cdkVirtualFor="let row of dataSource; let idx = index" [style.height.px]="defaultRowHeight">
      <td
        class="exceljs-cell"
        *ngFor="let cell of row.cells; let i = index"
        [style.width.px]="cell | colWidth: cols:defaultColWidth"
        [style.maxWidth.px]="cell | colWidth: cols:defaultColWidth"
        [style.minWidth.px]="cell | colWidth: cols:defaultColWidth"
        [style.textAlign]="cell.style.alignment?.horizontal || ''"
        [style.verticalAlign]="cell.style.alignment?.vertical || ''"
        [style.backgroundColor]="'#' + (cell.style.fill?.fgColor?.argb || defaultBackgroundColor)"
        [style.borderLeftWidth]="cell.style.border?.left?.style"
        [style.borderRightWidth]="cell.style.border?.right?.style"
        [style.borderTopWidth]="cell.style.border?.top?.style"
        [style.borderBottomWidth]="cell.style.border?.bottom?.style"
        [style.borderLeftColor]="'#' + cell.style.border?.left?.color?.argb"
        [style.borderRightColor]="'#' + cell.style.border?.right?.color?.argb"
        [style.borderTopColor]="'#' + cell.style.border?.top?.color?.argb"
        [style.borderBottomColor]="'#' + cell.style.border?.bottom?.color?.argb"
        [style.borderStyle]="cell.style.fill?.pattern"
        [class.wrap-text]="cell.style.alignment?.wrapText"
        [attr.colspan]="cell.attrs.colspan"
        [attr.rowspan]="cell.attrs.rowspan"
      >
        <ng-container [ngSwitch]="cell.type">
          <red-exceljs-cell-hyperlink *ngSwitchCase="exceljsValueType.HYPERLINK" [cell]="cell">
            <ng-container
              [ngTemplateOutlet]="rawTemplate"
              [ngTemplateOutletContext]="{
                cell: cell
              }"
            >
            </ng-container>
          </red-exceljs-cell-hyperlink>

          <ng-container
            *ngSwitchDefault
            [ngTemplateOutlet]="rawTemplate"
            [ngTemplateOutletContext]="{
              cell: cell
            }"
          >
          </ng-container>
        </ng-container>

        <ng-template #rawTemplate let-cell="cell">
          <span
            redClearEnter
            [style.backgroundColor]="'#' + (cell.style.fill?.fgColor?.argb || defaultBackgroundColor)"
            [style.fontWeight]="cell.style.font.bold ? 'bold' : ''"
            [style.fontSize.px]="cell.style.font.size"
            [style.color]="'#' + cell.style.font.color?.argb"
            >{{ cell.normalizedValue | cellValueFmt: cell.type:cell.style.numFmt }}</span
          >
        </ng-template>
      </td>
    </tr>
  </table>
</cdk-virtual-scroll-viewport>
<table *ngIf="!isVirtualSrollMode" class="exceljs-table">
  <tr class="exceljs-row" *ngFor="let row of dataSource" [style.height.px]="defaultRowHeight">
    <td
      class="exceljs-cell"
      *ngFor="let cell of row.cells; let i = index"
      [style.width.px]="cell | colWidth: cols:defaultColWidth"
      [style.maxWidth.px]="cell | colWidth: cols:defaultColWidth"
      [style.minWidth.px]="cell | colWidth: cols:defaultColWidth"
      [style.textAlign]="cell.style.alignment?.horizontal || ''"
      [style.verticalAlign]="cell.style.alignment?.vertical || ''"
      [style.backgroundColor]="'#' + (cell.style.fill?.fgColor?.argb || defaultBackgroundColor)"
      [style.borderLeftWidth]="cell.style.border?.left?.style"
      [style.borderRightWidth]="cell.style.border?.right?.style"
      [style.borderTopWidth]="cell.style.border?.top?.style"
      [style.borderBottomWidth]="cell.style.border?.bottom?.style"
      [style.borderLeftColor]="'#' + cell.style.border?.left?.color?.argb"
      [style.borderRightColor]="'#' + cell.style.border?.right?.color?.argb"
      [style.borderTopColor]="'#' + cell.style.border?.top?.color?.argb"
      [style.borderBottomColor]="'#' + cell.style.border?.bottom?.color?.argb"
      [style.borderStyle]="cell.style.fill?.pattern"
      [class.wrap-text]="cell.style.alignment?.wrapText"
      [attr.colspan]="cell.attrs.colspan"
      [attr.rowspan]="cell.attrs.rowspan"
    >
      <ng-container [ngSwitch]="cell.type">
        <red-exceljs-cell-hyperlink *ngSwitchCase="exceljsValueType.HYPERLINK" [cell]="cell">
          <ng-container
            [ngTemplateOutlet]="rawTemplate"
            [ngTemplateOutletContext]="{
              cell: cell
            }"
          >
          </ng-container>
        </red-exceljs-cell-hyperlink>

        <ng-container
          *ngSwitchDefault
          [ngTemplateOutlet]="rawTemplate"
          [ngTemplateOutletContext]="{
            cell: cell
          }"
        >
        </ng-container>
      </ng-container>

      <ng-template #rawTemplate let-cell="cell">
        <span
          redClearEnter
          [style.backgroundColor]="'#' + (cell.style.fill?.fgColor?.argb || defaultBackgroundColor)"
          [style.fontWeight]="cell.style.font.bold ? 'bold' : ''"
          [style.fontSize.px]="cell.style.font.size"
          [style.color]="'#' + cell.style.font.color?.argb"
          >{{ cell.normalizedValue | cellValueFmt: cell.type:cell.style.numFmt }}</span
        >
      </ng-template>
    </td>
  </tr>
</table>
