export interface IImportAR {
  customerCode: string;
  customerName: string;
  type: string;
  invoiceNo: string;
  invoiceDate: string;
  itemGstPercent: string;
  itemGstAmount: string;
  itemNo: string;
  itemCode: string;
  itemDescription: string;
  uom: string;
  quantity: string;
  unitPrice: string;
  // discountPercent: string;
  creditAccount: string;
  debitAccount: string;
  profitCentre: string;
  itemRemarks: string;
  currencySign: string;
  currencyRate: string;
  billingAddress: string;
  contactPerson: string;
  reference: string;
  creditTerm: string;
  salesperson: string;
  paymentTerms: string;
  remarks: string;
  glEntry: string;
  offsetInvoice: string;
  gstInclusive: string;
}

export interface IImportARColumn {
  id: keyof IImportAR;
  label: string;
  index: number;
  required?: boolean;
  maxLength?: number;
}
