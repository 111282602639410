import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IUpdatedBy } from '../interfaces/updated-by.interface';

export class UpdatedByModel extends BaseModel implements IUpdatedBy {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  name?: string;
}
