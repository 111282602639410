import { EManagementCommissionActivePd, EManagementCommissionPdRole, EManagementCommissionStatus } from '../enums';
import { IManagementCommissionPD } from './management-commission-pd.interface';

export interface IManagementCommissionPdDescription {
  id: EManagementCommissionPdRole;
  activeKey: EManagementCommissionActivePd;
  relativeKey: 'pdManagement' | 'pdReferral' | 'pdOverride';
  label: string;
}

export interface IManagementCommission {
  id: number;
  code: string;
  name: string;
  status: EManagementCommissionStatus;
  isActive: boolean;
  pd: number;
  pdManagement: number;
  pdOverride: number;
  pdReferral: number;
  rejectReason: string;
  relatedPd: {
    pdManagement: IManagementCommissionPD[];
    pdOverride: IManagementCommissionPD[];
    pdReferral: IManagementCommissionPD[];
  };
  remarks: string;
  reviewedAt: string;
  reviewedBy: string;
  submittedAt: string;
  submittedBy: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  lastReason: string;
  activePdOverride: boolean;
  activePdReferal: boolean;
  activePdManagement: boolean;
}
