import { UPLOAD_FOLDER } from '@agent-portal/constants';
import { Injectable } from '@angular/core';
import { HuttonStorageApiService } from '@shared/features/media-storage';
import { assign } from 'lodash-es';
import { concatMap, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcddStorageApiService extends HuttonStorageApiService {
  static GET_PUBLIC_UPLOAD_URL = 'storage/public/upload/transaction';
  TYPE = 'transaction';

  getPublicUploadLink(data: { type: string; name: string }) {
    return this.apiService.get(EcddStorageApiService.GET_PUBLIC_UPLOAD_URL, {
      folder: data.type,
      filename: data.name,
    });
  }

  publicUpload(file: File) {
    const uploadLink: { type: string; name: string } = {
      type: UPLOAD_FOLDER.SIGNATURE,
      name: file.name,
    };

    return this.getPublicUploadLink(uploadLink).pipe(
      concatMap(data => {
        return this.uploadFile(assign(data, { file })).pipe(map(() => data));
      })
    );
  }

  upload(file: File) {
    const uploadLink: { type: string; name: string } = {
      type: UPLOAD_FOLDER.SIGNATURE,
      name: file.name,
    };

    return this.getUploadLink(uploadLink).pipe(
      concatMap(data => {
        return this.uploadFile(assign(data, { file })).pipe(map(() => data));
      })
    );
  }
}
