import { BaseModel, PhoneNumber } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { ContactType } from '../enums';
import { IDeveloper } from '../interfaces';
import { CategoryModel } from './category.model';
import { ContactAddressModel } from './contact-address.model';
import { LedgerAccountModel } from './ledger-account.model';

export class DeveloperModel extends BaseModel implements IDeveloper {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  groupName!: string;

  @Expose()
  email!: string;

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  categoryId!: number;

  @Expose()
  @Type(() => CategoryModel)
  category?: CategoryModel;

  @Expose()
  accountReceivableId!: number;

  @Expose()
  accountReceivable!: LedgerAccountModel;

  @Expose()
  accountPayableId!: number;

  @Expose()
  accountPayable!: LedgerAccountModel;

  @Expose()
  businessRegistration!: string;

  @Expose()
  creditTerm!: number;

  @Expose()
  creditLimit!: number;

  @Expose()
  gstRegistration!: string;

  @Expose()
  remarks!: string;

  @Expose()
  commenceDate!: string;

  @Expose()
  role!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  type?: `${ContactType}`;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  @Type(() => ContactAddressModel)
  addresses!: ContactAddressModel[];

  @Expose()
  get typeDisplay() {
    return this.type?.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  }

  //#region getters
  get defaultAddress() {
    return this.addresses?.find(address => address.default) || null;
    // return this.addresses?.length && this.addresses[0] ? this.addresses[0] : null;
  }

  get defaultAddressFull() {
    const defaultAddress = this.addresses?.find(address => address.default) || null;
    return [`${defaultAddress?.house ? defaultAddress?.house + ' ' : ''}` + `${defaultAddress?.street ?? ''}`,
    `${defaultAddress?.floor ? '#' + defaultAddress?.floor : ''}${defaultAddress?.unit ? '-' + defaultAddress?.unit : ''}${defaultAddress?.buildingName ? ' ' + defaultAddress?.buildingName : ''}`,
    `${defaultAddress?.country}` + ' ' + `${defaultAddress?.postcode}`]
      .filter(str => !!str)
      .join(', ') || ''
  }

  get developerName(): string {
    if (this.code) return `${this.name} (${this.code})`;
    return this.name;
  }
  //#endregion
}
