import { ProjectTransactionRelatedCobrokeModel } from './project-transaction-related-cobroke.model';
import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import {
  IProjectTransactionAgentInfoSummary,
  IProjectTransactionRelatedCobroke,
  IProjectTransactionRelatedAgent,
  IProjectTransactionBonus,
  IProjectTransactionOverriding,
  IProjectTransactionBds,
} from '../interfaces';
import { ProjectTransactionRelatedAgentModel } from './project-transaction-related-agent.model';
import { ProjectTransactionBonusModel } from './project-transaction-bonus.model';
import { ProjectTransactionRelatedInternalCobrokeModel } from './project-transaction-related-internal-cobroke.model';
import { ProjectTransactionBdsModel } from './project-transaction-bds.model';

export class ProjectTransactionAgentInfoSummaryModel extends BaseModel implements IProjectTransactionAgentInfoSummary {
  @Expose()
  agentTierProfit!: number;

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionRelatedAgent) => ProjectTransactionRelatedCobrokeModel.fromJson(item, { groups: ['summary'] }));
  })
  externals!: ProjectTransactionRelatedCobrokeModel[];

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionRelatedAgent) => ProjectTransactionRelatedInternalCobrokeModel.fromJson(item, { groups: ['summary'] }));
  })
  internals!: ProjectTransactionRelatedInternalCobrokeModel[];

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return ProjectTransactionRelatedAgentModel.fromJson(value, { groups: ['summary'] });
  })
  leaders!: ProjectTransactionRelatedAgentModel[];

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return ProjectTransactionRelatedAgentModel.fromJson(value, { groups: ['summary'] });
  })
  main!: ProjectTransactionRelatedAgentModel;

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionBonus) => ProjectTransactionBonusModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionBonuses!: ProjectTransactionBonusModel[];

  @Expose()
  @Default([])
  @Transform(({ value }) => {
    if (!value) {
      return [];
    }
    return value.map((item: IProjectTransactionBds) => ProjectTransactionBdsModel.fromJson(item, { groups: ['summary'] }));
  })
  transactionBds!: ProjectTransactionBdsModel[];

  @Expose()
  // @Type(()=>ProjectTransactionRelatedCobrokeModel)
  transactionPdOverrides!: any[];

  @Expose()
  // @Type(()=>ProjectTransactionRelatedCobrokeModel)
  hiProfitAmount!: number;

  @Expose()
  profitSharingAmount!: number;

  @Expose()
  @Default(0)
  headHiReceived!: number;

  @Expose()
  @Default(0)
  bdReceived!: number;

  @Expose()
  @Default(0)
  hiTierReceived!: number;
}
