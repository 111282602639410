import { Injectable } from '@angular/core';
import { IPersonalParticular } from '@shared/data-access/interfaces';
import { PersonalParticularModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';
import { PersonalParticularApiService } from './personal-particular-api.service';
import { IApiOption } from '@red/api';

@Injectable({
  providedIn: 'root',
})
export class TeamOrgChartApiService extends PersonalParticularApiService {
  static DESCENTDENTS_POINT = 'hrm/salespersons/:id/descendants';
  static EXPORT_EXCEL = 'hrm/hr/reports/team_org_chart/download'

  getDescendants(id: number): Observable<PersonalParticularModel[]> {
    return this.apiService
      .get(TeamOrgChartApiService.DESCENTDENTS_POINT, { id })
      .pipe(map((res: IPersonalParticular[]) => res.map(item => PersonalParticularModel.fromJson(item) as PersonalParticularModel)));
  }

  exportExcelTeamOrg(query = {}, option?: IApiOption): Observable<any> {
    return this.apiService.post(TeamOrgChartApiService.EXPORT_EXCEL, query, {
      ...option,
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }
}
