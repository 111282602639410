import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IJournalEntry, IJournalEntryPosting, IScheduleTypes } from '../interfaces';
import { UpdatedByModel } from './updated-by.model';
import { LedgerAccountModel } from './ledger-account.model';
import { ProfitCentresModel } from './profit-centres.model';

export class ScheduleTypesModel extends BaseModel implements IScheduleTypes {
  @Expose()
  value!: string[];
}

export class JournalEntryPostingModel extends BaseModel implements IJournalEntryPosting {
  @Expose()
  id!: number;

  @Expose()
  journalId!: number;

  @Expose()
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  description?: string;

  @Expose()
  debit!: number;

  @Expose()
  credit!: number;

  @Expose()
  order!: number;

  @Expose()
  rate!: number;

  @Expose()
  currency!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt?: string;

  @Expose()
  createdBy!: UpdatedByModel;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  deletedBy?: UpdatedByModel;
}

export class JournalEntryModel extends BaseModel implements IJournalEntry {
  @Expose()
  id!: number;

  @Expose()
  businessUnitId?: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  reversal!: boolean;

  @Expose()
  journalDate?: string;

  @Expose()
  scheduleEnable?: boolean;

  @Expose()
  scheduleName?: string;

  @Expose()
  scheduleType?: string;

  @Expose()
  scheduleStartDate?: string;

  @Expose()
  scheduleEndDate?: string;

  @Expose()
  totalDebit!: number;

  @Expose()
  totalCredit!: number;

  @Expose()
  formatCode?: string;

  @Expose()
  postings?: JournalEntryPostingModel[];

  @Expose()
  reversalJournalId!: number;

  @Expose()
  reversalJournal?: JournalEntryModel;

  @Expose()
  inClosedPeriod?: boolean;

  @Expose()
  createdAt?: string;

  @Expose()
  updatedAt?: string;

  @Expose()
  deletedAt?: string;

  @Expose()
  createdBy!: UpdatedByModel;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  deletedBy?: UpdatedByModel;

  @Expose()
  @Transform(({ obj }) => {
    return !obj.inClosedPeriod;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return !obj.inClosedPeriod;
  })
  canDelete!: boolean;
}
