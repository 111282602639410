import { BaseModel } from "@red/data-access";
import { Expose, Type } from "class-transformer";
import { IAppointmentType, ICarrerProgression, IProjectTransactionGrossComm } from "../interfaces";
import { AppointmentTypeModel } from "./appointment-type.model";

export class ProjectTransactionGrossCommModel extends BaseModel implements IProjectTransactionGrossComm{
  @Expose()
  value!: number;

  @Expose()
  withoutGst!: number;

  @Expose()
  gst!: number;

  @Expose()
  withGst!: number;
}
