import { Location } from '@angular/common';
import { cloneDeep } from 'lodash-es';
import { switchMap } from 'rxjs';
import { AfterViewInit } from '@angular/core';
import { Subject, takeUntil, filter } from 'rxjs';
import { iif, of, forkJoin } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IMetabaseCardParameter, MetabaseCard, MetabaseCardQueryModel } from '../models';
import { MetabaseHttpLoader, METABASE_HTTP_LOADER } from '../metabase-http-loader.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as saveAs from 'file-saver';
import { MetabaseCardQueryDto } from '../models/metabase.dto';
import { bindingDataParameter } from '../metabase.util';
import { MetabaseParameterService } from '../metabase-parameter/metabase-parameter.service';
import { generateColumns, generateDataSource, MetabaseColumnDef } from '../metabase-table.util';

@Component({
  selector: 'red-metabase-question',
  templateUrl: './metabase-question.component.html',
  styleUrls: ['./metabase-question.component.scss'],
  providers: [MetabaseParameterService],
})
export class MetabaseQuestionComponent implements OnInit, AfterViewInit, OnDestroy {
  card?: MetabaseCard;
  query?: MetabaseCardQueryModel;
  currentId?: number;
  currentQueryPayload?: MetabaseCardQueryDto;
  loading = false;

  parameters: IMetabaseCardParameter[] = [];
  columns: MetabaseColumnDef[] = [];
  dataSource: Array<Record<string, string | number | null>> = [];
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  constructor(
    @Inject(METABASE_HTTP_LOADER) private apiService: MetabaseHttpLoader,
    private _location: Location,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _parameterService: MetabaseParameterService
  ) {}
  ngAfterViewInit(): void {
    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._fetch();
      });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    this._fetch();
    this._parameterService
      .connect()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(val => {
        console.log('_parameterService --->', val);
        this._router.navigate([], {
          queryParams: convertMapToObject(val),
          relativeTo: this._activatedRoute,
        });
      });
  }

  private _fetch(): void {
    const idRaw = this._activatedRoute.snapshot.paramMap.get('id');
    const id = idRaw && parseFloat(idRaw);
    if (!id || typeof id !== 'number') {
      return;
    }
    if (id === this.currentId && this.card) {
      const queryParams = this._activatedRoute.snapshot.queryParams;
      const parameters = bindingDataParameter(this.card.parameters, queryParams);
      const payload = MetabaseCardQueryDto.fromJson({ parameters });
      this.currentQueryPayload = payload;
      this.parameters = cloneDeep(parameters);
      this.loading = true;
      this.apiService.query(id, payload).subscribe(query => {
        this.query = query;
        this._initializer();
        this.loading = false;
      });
      return;
    }
    const routeData = this._activatedRoute.snapshot.data;
    const cardInstance = cloneDeep(routeData['card']);

    this.currentId = id;
    this.loading = true;
    iif(() => cardInstance instanceof MetabaseCard && cardInstance.id === id, of(cardInstance), this.apiService.getCard(id))
      .pipe(
        switchMap((card: MetabaseCard) => {
          const queryParams = this._activatedRoute.snapshot.queryParams;
          const parameters = bindingDataParameter(card.parameters, queryParams);
          const payload = MetabaseCardQueryDto.fromJson({ parameters });
          this.currentQueryPayload = payload;
          this.parameters = cloneDeep(parameters);
          return forkJoin({
            card: of(card),
            query: iif(() => routeData['query'] instanceof MetabaseCardQueryModel, of(routeData['query']), this.apiService.query(id, payload)),
          });
        })
      )
      .subscribe(({ card, query }) => {
        this.card = card;
        this.query = query;
        this._initializer();
        this.loading = false;
      });
  }
  _initializer(): void {
    if (this.card) {
      this.columns = generateColumns(this.card);
    }
    if (this.query) {
      this.dataSource = generateDataSource(this.query);
    }
  }

  download(): void {
    if (!this.currentId) {
      return;
    }
    const payload = this.currentQueryPayload || {};
    this.apiService.export(this.currentId, payload).subscribe(res => {
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${this.card?.name}.xlsx`);
    });
  }

  back(): void {
    this._location.back();
  }
}
function convertMapToObject(metricArguments: Map<string, any>): Record<string, any> {
  const newObject: Record<string, any> = {};
  for (const [key, value] of metricArguments) {
    newObject[key] = value;
  }
  return newObject;
}
