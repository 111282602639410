import { IProjectTransaction } from './project-transaction.interface';
import { IBlankForm } from '@shared/data-access/interfaces/blank-form.interface';
export interface IBatchingTransactionSelection {
  id: number;
  transactionId: number;
  transaction: IProjectTransaction;
  blankFormId: number;
  blankForm: IBlankForm;
  isChosen: boolean;
  isPrinted: boolean;
  rate: number;
  status: string;
  remarks: string;
}
