import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IImportContact, IImportContactColumn } from '../interfaces';

export class ImportContactModel extends BaseModel implements IImportContact {
  @Expose()
  rowIndex!: number;

  @Expose()
  @Default(false)
  isHover!: boolean;

  @Expose()
  @Default(true)
  empty!: boolean;

  @Expose()
  @Type(() => String)
  @Default('')
  contactCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  contactName!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  type!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  accountReceivable!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  accountPayable!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  contactCategory!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  commenceDate!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  email!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  phonePrefix!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  phoneNumber!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  faxPrefix!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  faxNumber!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  businessReg!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  gstReg!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  creditTerm!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  creditLimit!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  remarks!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  address!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  country!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  postCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  personName!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  personDesignation!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  personEmail!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  personPhonePrefix!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  personPhoneNumber!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  bankCode!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  bankAccountNumber!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  bankAccountName!: string;

  // @Expose()
  // @Type(() => String)
  // @Default('')
  // personFaxPrefix!: string;

  // @Expose()
  // @Type(() => String)
  // @Default('')
  // personFaxNumber!: string;

  @Expose()
  @Type(() => String)
  @Default('')
  status!: string;

  get isEmpty(): boolean {
    const checkingProperties: (keyof IImportContact)[] = [
      'accountPayable',
      'accountReceivable',
      'address',
      'bankAccountName',
      'bankAccountNumber',
      'bankCode',
      'businessReg',
      'commenceDate',
      'contactCategory',
      'contactCode',
      'contactName',
      'country',
      'creditLimit',
      'creditTerm',
      'email',
      'faxNumber',
      'faxPrefix',
      'gstReg',
      'personDesignation',
      'personEmail',
      'personName',
      'personPhoneNumber',
      'personPhonePrefix',
      'phoneNumber',
      'phonePrefix',
      'postCode',
      'remarks',
      'status',
      'type',
      // 'personFaxNumber',
      // 'personFaxPrefix',
    ];

    return checkingProperties.every(c => {
      const value = this[c];
      return !value || value.trim() === '';
    });
  }
}

export class ImportContactColumnModel extends BaseModel implements IImportContactColumn {
  @Expose()
  id!: keyof IImportContact;

  @Expose()
  label!: string;

  @Expose()
  index!: number;

  @Expose()
  @Default(false)
  required!: boolean;

  @Expose()
  @Default(300)
  maxLength!: number;

  @Expose()
  @Transform(({ obj }) => {
    return `${obj.label} [${obj.maxLength ?? 'NA'}]${obj.required ? '*' : ''}`;
  })
  title!: string;
}
