export interface IImportSupplierInvoice {
  supplierCode: string;
  supplierName: string;
  invoiceNo: string;
  invoiceDate: string;
  creditTerm: string;
  gstPercent: string;
  gstAmount: string;
  itemNo: string;
  itemCode: string;
  itemDescription: string;
  itemAmount: string;
  creditAccount: string;
  debitAccount: string;
  profitCentre: string;
  currencySign: string;
  currencyRate: string;
  reference: string;
  remarks: string;
  glEntry: string;
  offsetInvoice: string;
  isCreditNote: string;
}

export interface IImportSupplierInvoiceColumn {
  id: keyof IImportSupplierInvoice;
  label: string;
  index: number;
  required?: boolean;
  maxLength?: number;
}
