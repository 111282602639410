export enum ESupplierInvoiceStatus {
  Confirmed = 'Confirmed',
  Withhold = 'Withhold',
  PaymentInProgress = 'PaymentInProgress', // Inside Payment Generator
  // View = 'View',
  Pending = 'Pending', // Waiting for PC Docs
  PartPaid = 'PartPaid', // Partially Paid Manually
  FullyPaid = 'FullyPaid',
}

export enum ESupplierInvoicePaidStatus {
  NOT_PAID = 'NOT_PAID',
  NOT_FULL_PAID = 'NOT_FULL_PAID',
  FULLY_PAID = 'FULLY_PAID',
  CREDITED = 'CREDITED',
  PENDING = 'Pending',
  PaymentInProgress = 'PaymentInProgress', // Inside Payment Generator
  // CANCELLED = 'Cancelled',
}
