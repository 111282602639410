/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface IApiOption {
  removeEmpty?: {
    enable?: boolean;
    excludeFields?: string[];
  };
  excludeFields?: string[];
  exposeHeaders?: {
    [key: string]: string | string[];
  };
  loader?: boolean;
  pretreatmentResponse?: boolean;
  requestOptions?: {
    headers?:
    | HttpHeaders
    | {
      [header: string]: string | string[];
    };
    mergeHeader?: {
      [header: string]: string;
    };
    observe?: string | any;
    params?:
    | HttpParams
    | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: string | any;
    withCredentials?: boolean;
  };
  timeout?: number;
}
