import { ETeam } from '../enums';
import { ProjectTeamMemberModel } from '../models';
import { IProjectConsultantMember } from './project-team-member.interface';
import { IProject } from './project.interface';

export interface IProjectConsultant {
  id: number;
  name: string;
  type: `${ETeam}`;
  project: IProject;
  projectId: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  endDate?: string;
  taggerStartDate?: string;
  taggerEndDate?: string;
  remarks?: string;
  members?: ProjectTeamMemberModel[];
}
export interface IProjectConsultantError {
  teamName: string;
  type: string;
  salespersonId: number;
  isLeader: boolean;
  appointmentTypeId: number;
  appTypeCode: string;
  memberName: string;
  ceaNo: string;
  appointmentTypeName: string;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  endDate?: string;
}
export interface IProjectConsultantImportErrorMetadata {
  data: IProjectConsultantError;
  errorCode?: string;
  rowNumber?: number;
}
export interface IProjectConsultantImportResponse {
  typeProgress: number;
  status: string;
  numberOfImport: number;
  errors: IProjectConsultantImportErrorMetadata[];
}
