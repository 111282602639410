import { EResaleAdjustmentStatus } from '../enums';
import { StatusRecord } from '../types/status.type';

export const RESALE_ADJUSTMENT_STATUSES: StatusRecord<EResaleAdjustmentStatus> = {
  [EResaleAdjustmentStatus.submitted]: {
    id: EResaleAdjustmentStatus.submitted,
    name: 'Pending Approval',
    class: 'status-box-warning',
    canDelete: true,
    canEdit: true,
  },
  [EResaleAdjustmentStatus.rework]: {
    id: EResaleAdjustmentStatus.rework,
    class: 'status-box-danger',
    name: 'Send Back',
    canSubmit: true,
    canEdit: true,
    canDelete: true,
  },
  [EResaleAdjustmentStatus.rework2]: {
    id: EResaleAdjustmentStatus.rework2,
    class: 'status-box-danger',
    name: 'Send Back',
    canSubmit: true,
    canEdit: true,
    canDelete: true,
  },
  [EResaleAdjustmentStatus.approved]: {
    id: EResaleAdjustmentStatus.approved,
    name: 'Approved',
    class: 'status-box-success',
  },
  [EResaleAdjustmentStatus.draft]: {
    id: EResaleAdjustmentStatus.draft,
    name: 'Draft',
    class: 'status-box-default',
    canSubmit: true,
    canEdit: true,
    canDelete: true,
  },
  [EResaleAdjustmentStatus.rejected]: {
    id: EResaleAdjustmentStatus.rejected,
    name: 'Rejected',
    class: 'status-box-danger',
  },
  [EResaleAdjustmentStatus.confirmed]: {
    id: EResaleAdjustmentStatus.confirmed,
    name: 'Pending Approval',
    class: 'status-box-warning',
  },
  [EResaleAdjustmentStatus.verified]: {
    id: EResaleAdjustmentStatus.verified,
    name: 'Pending Invoice',
    class: 'status-box-warning',
    canDelete: true,
  },
  [EResaleAdjustmentStatus.completed]: {
    id: EResaleAdjustmentStatus.completed,
    name: 'Completed',
    class: 'status-box-success',
  },
  [EResaleAdjustmentStatus.pending]: {
    id: EResaleAdjustmentStatus.pending,
    name: 'Pending Approval',
    class: 'status-box-warning',
  },
};
export const RESALE_ADJUSTMENT_STATUSES_FILTER_OPTIONS = [
  {
    name: 'Approved',
    value: EResaleAdjustmentStatus.approved,
  },
  {
    name: 'Pending Approval',
    value: EResaleAdjustmentStatus.submitted,
  },
  {
    name: 'Draft',
    value: EResaleAdjustmentStatus.draft,
  },
];