import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IEducationRecord } from '../interfaces';
import { QualificationModel } from './qualification.model';
import { DisciplineModel } from './discipline.model';
import { CountryModel } from './country.model';


export class EducationRecordModel extends BaseModel implements IEducationRecord {
  @Expose()
  id!: number;

  @Expose()
  qualificationId!: number;

  @Expose()
  qualification!: QualificationModel;

  @Expose()
  disciplineId!: number;

  @Expose()
  discipline!: DisciplineModel;

  @Expose()
  countryId!: number;

  @Expose()
  country!: CountryModel;

  @Expose()
  school!: string;

  @Expose()
  achievement!: string;

  @Expose()
  highestQualification!: boolean;

  @Expose()
  remarks!: string;

  @Expose()
  startDate!: string;

  @Expose()
  endDate!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;


}
