import { IBase } from './base-finance.interface';

// export interface IContactAddress extends IBase {
//   type: string;
//   postalCode?: string;
//   address1?: string;
//   address2?: string;
//   country?: string;
//   contactId: number;
//   index?: number;
//   default?: boolean;
// }

export interface IContactAddress extends IBase {
  address?: string;
  buildingName?: string;
  country: string;
  default?: boolean;
  district?: string;
  floor?: string;
  house?: string;
  postcode: string;
  street?: string;
  type: string;
  unit?: string;
  contactId: number;
  index?: number;
}
