import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { BonusDistributionCreateDto, BonusDistributionUpdateDto } from '@shared/data-access/dto';
import { IBonusDistribution } from '@shared/data-access/interfaces';
import { BonusDistributionModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BonusDistributionApiService {
  static ROOT_POINT = 'transaction/bonus-distributions';

  constructor(private apiService: ApiService) {}

  create(data: BonusDistributionCreateDto): Observable<BonusDistributionModel> {
    return this.apiService
      .post(`${BonusDistributionApiService.ROOT_POINT}`, data)
      .pipe(map((res: IBonusDistribution) => BonusDistributionModel.fromJson(res) as BonusDistributionModel));
  }
  get(itemId: number): Observable<BonusDistributionModel> {
    return this.apiService
      .get(BonusDistributionApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => BonusDistributionModel.fromJson(res) as BonusDistributionModel));
  }
  update(id: number, data: BonusDistributionUpdateDto): Observable<any> {
    return this.apiService.patch(BonusDistributionApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<BonusDistributionModel>> {
    return this.apiService.get(BonusDistributionApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(BonusDistributionModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(BonusDistributionApiService.ROOT_POINT, { ids }, option);
  }

  updateStatus(id: number, status: string): Observable<any> {
    return this.apiService.patch(BonusDistributionApiService.ROOT_POINT + '/' + id, { status });
  }
}
