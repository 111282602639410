import { take, tap } from 'rxjs';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { cloneDeep, get } from 'lodash-es';
import { Component, Inject, Input, OnInit, Type, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { MetabaseCard, MetabaseCardParameter } from '../models';
import { MetabaseParameterContainerDirective } from './metabase-parameter-container.directive';
import { normalizeValueByDefaultValueParameter } from '../metabase.util';
import { METABASE_PARAMETER_PLUGINS } from './metabase-parameter.module';
import { MetabaseParameterFieldBase } from './metabase-parameter-field-base.interface';
import { getTemplateTag } from '../metabase-parameter.util';
import { Subject, zip } from 'rxjs';
import { MetabaseParameterService } from './metabase-parameter.service';

@Component({
  selector: 'red-metabase-parameter',
  templateUrl: './metabase-parameter.component.html',
  styleUrls: ['./metabase-parameter.component.scss'],
})
export class MetabaseParameterComponent implements OnInit, OnDestroy {
  @Input() get card(): MetabaseCard | undefined {
    return this._card;
  }
  set card(val: MetabaseCard | undefined) {
    this._card = val;
    this.renderParameters();
  }
  @Input()
  get parameters(): MetabaseCardParameter[] {
    return this._parameters;
  }
  set parameters(val: MetabaseCardParameter[] | undefined) {
    this._parameters = val ? cloneDeep(val) : [];
    this.renderParameters();
  }

  @ViewChild(MetabaseParameterContainerDirective, { static: true }) container!: MetabaseParameterContainerDirective;
  private _parameters: MetabaseCardParameter[] = [];
  private _card?: MetabaseCard;
  protected _unsubscribeAll: Subject<void> = new Subject<void>();
  form!: FormGroup;
  constructor(
    private _fb: FormBuilder,
    @Inject(METABASE_PARAMETER_PLUGINS) private _plugins: Record<string, Type<MetabaseParameterFieldBase>>,
    private _parameterService: MetabaseParameterService
  ) {}

  ngOnInit() {
    this.form = this.createForm();
  }
  createForm(): FormGroup {
    return this._fb.group({
      parameters: this._fb.array([]),
    });
  }
  createFormItem(item: Partial<MetabaseCardParameter>): FormGroup {
    return this._fb.group({
      id: [item.id],
      name: [item.name],
      slug: [item.slug],
      target: [item.target],
      value: [normalizeValueByDefaultValueParameter(item.value, item)],
    });
  }
  get parametersFormArray(): FormArray | undefined {
    return this.form?.get('parameters') as FormArray;
  }
  isParamerersValid(card?: MetabaseCard, parameters?: MetabaseCardParameter[]): boolean {
    if (!card || !parameters) {
      return false;
    }
    if (parameters.some(parameter => !card.datasetQuery.native.templateTags[parameter.slug])) {
      return false;
    }
    return true;
  }
  renderParameters(): void {
    if (!this.isParamerersValid(this.card, this.parameters)) {
      return;
    }
    const viewContainerRef = this.container.viewContainerRef;
    viewContainerRef.clear();
    this._parameterService.clear();
    this.parameters.forEach(parameter => {
      const templateTag = getTemplateTag(parameter, this.card);
      const component = templateTag ? this._plugins[templateTag.type] : null;
      if (component) {
        const componentRef = viewContainerRef.createComponent<MetabaseParameterFieldBase>(component);
        componentRef.instance.dataUpcast.valueChanges
          .pipe(
            tap(value => {
              this._parameterService.select({ values: { key: parameter.slug, value } });
            }),
            take(1)
          )
          .subscribe();
        componentRef.instance.data = parameter;
        componentRef.instance.templateTag = templateTag;
        this._parameterService.register({ key: parameter.slug, formCtr: componentRef.instance.dataDowncast });
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
