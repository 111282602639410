import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EBatchType } from '../enums/batch.enum';
import { IBatchingApproval } from '../interfaces';
import { BatchingTransactionSelectionModel } from './batching-transaction-selection.model';
import { GrossCommissionEarnedModel } from './gross-commission-earned.model';
import { PersonalParticularModel } from './personal-particular.model';
import { ProjectModel } from './project.model';
import { UpdatedByModel } from './updated-by.model';
import { TaxInvoiceModel } from './tax-invoice-enhanced.model';

export class BatchhingAppovalProformaModel extends BaseModel {
  @Expose()
  businessUnitId!: number;

  @Expose()
  amount!: number;

  @Expose()
  currency!: string;

  @Expose()
  grossType!: string;

  @Expose()
  includeGst!: boolean;

  @Expose()
  subTotalAmount!: number;

  @Expose()
  taxAmount!: number;

  @Expose()
  taxType!: 'number' | 'percentage';

  @Expose()
  taxValue!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  code!: string;

  @Expose()
  invoiceAt!: string;
}

export class BatchingApprovalModel extends BaseModel implements IBatchingApproval {
  @Expose()
  id!: number;

  @Expose()
  projectId!: number;

  @Expose()
  @Type(() => ProjectModel)
  project!: ProjectModel;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  remarks!: string;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  grossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  devGrossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  hiGrossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  profomaGrossComm!: GrossCommissionEarnedModel;

  @Expose()
  proformaCode!: string;

  @Expose()
  @Type(() => BatchhingAppovalProformaModel)
  proforma!: BatchhingAppovalProformaModel;

  @Expose()
  @Type(() => BatchingTransactionSelectionModel)
  details!: BatchingTransactionSelectionModel[];

  @Expose()
  @Type(() => BatchingTransactionSelectionModel)
  detailBlankForms!: BatchingTransactionSelectionModel[];

  @Expose()
  batchedAt!: string;

  @Expose()
  @Type(() => PersonalParticularModel)
  batchedBy!: PersonalParticularModel;

  @Expose()
  invoiceAt!: string;

  @Expose()
  invoiceId?: number;

  @Expose()
  invoiceCode?: string;

  @Expose()
  @Type(() => TaxInvoiceModel)
  invoice?: TaxInvoiceModel;

  @Expose()
  hiInvoiceId?: number;

  @Expose()
  hiInvoiceCode?: string;

  @Expose()
  @Type(() => TaxInvoiceModel)
  hiInvoice?: TaxInvoiceModel;

  @Expose()
  runAt!: string;

  @Expose()
  @Type(() => UpdatedByModel)
  createdBy!: UpdatedByModel;

  @Expose()
  status!: string;

  @Expose()
  type!: EBatchType;

  @Expose()
  comments?: string;

  @Expose()
  isAuto!: boolean;

  @Expose()
  actualComm?: number;

  @Expose()
  @Transform(({ obj }: { obj: IBatchingApproval }) => {
    switch (obj.status) {
      case 'schedulingPending':
        return {
          className: 'status-box-purple',
          value: obj.status,
          displayName: 'scheduling pending',
        };
      case 'pending':
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: 'pending',
        };
      case 'schedulingApprove':
        return {
          className: 'status-box-purple',
          value: obj.status,
          displayName: 'scheduling approve',
        };
      case 'approved':
        return {
          className: 'status-box-success',
          value: obj.status,
          displayName: 'approved',
        };
      default:
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: obj.status,
        };
    }
  })
  statusObj!: { className: string; value: string; displayName: string };

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  @Transform(({ obj }: { obj: IBatchingApproval }) => {
    return ['draft', 'schedulingPending', 'pending', 'schedulingApprove'].some(val => val === obj.status);
  })
  canSubmit!: boolean;

  @Expose()
  @Transform(({ obj }: { obj: IBatchingApproval }) => {
    return ['draft', 'schedulingPending', 'pending'].some(val => val === obj.status);
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }: { obj: IBatchingApproval }) => {
    return ['draft', 'schedulingPending', 'pending'].some(val => val === obj.status) && !obj.isAuto && !obj.actualComm;
  })
  canDelete!: boolean;

  @Expose()
  @Transform(({ obj }: { obj: IBatchingApproval }) => {
    return ['schedulingPending', 'schedulingApprove'].some(val => val === obj.status);
  })
  isOnSchedule!: boolean;

  @Expose()
  jobId?: number;

  @Expose()
  isProcessing?: boolean;
}
