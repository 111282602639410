import { IContactAddress } from './contact-address.interface';

export interface IReferralExternal {
  id: number;
  category: string;
  code: string;
  email: string;
  name: string;
  phone: string;
  status: string;
  type: string;
  addresses: IContactAddress[];
}
