// core
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// share
import { PackageModel } from '@shared/data-access/models';
import { PaginationAdapter } from '@red/data-access';
import { ApiService, IApiOption } from '@red/api';

@Injectable({
  providedIn: 'root',
})
export class PackageApiService {
  static ROOT_POINT = 'hrm/packages/portal';

  constructor(private apiService: ApiService) {}

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PackageModel>> {
    return this.apiService.get(PackageApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(PackageModel, data)));
  }
}
