import { AssetCategoryAccountType } from '../types';
import { IAssetRegister } from './asset-register.interface';
import { IProfitCentres } from './profit-centres.interface';

export interface IAssetDisposalRecord {
  id: number;

  asset: Partial<IAssetRegister>;

  assetAccountId: number;
  assetAccount?: AssetCategoryAccountType | null;

  assetDepreciationAccountId: number;
  assetDepreciationAccount?: AssetCategoryAccountType | null;

  assetAccumulatedAccountId: number;
  assetAccumulatedAccount?: AssetCategoryAccountType | null;

  assetDisposalAccountId: number;
  assetDisposalAccount?: AssetCategoryAccountType | null;

  profitCenterId?: number;
  profitCenter?: Partial<IProfitCentres>;

  serialNumber?: string;
  purchaseCost?: number;
  initNBV: number;
  accDepTotal: number;
  accDepDisposal: number;
  disAmount: number;
  proceeds: number;
}

export interface IAssetDisposal {
  id: number;
  code: string;
  date: string;
  description: string;
  amount: number;

  initNBV: number;
  accDepTotal: number;
  accDepDisposal: number;
  disAmount: number;
  proceeds: number;
}
