import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { TRANSLOCO_ORIGIN_URL } from './transloco.token';

@Injectable({
  providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, @Optional() @Inject(TRANSLOCO_ORIGIN_URL) private originUrl: string) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get translation
   *
   * @param lang
   */
  getTranslation(lang: string): Observable<Translation> {
    const path = `${this.originUrl ? this.originUrl : '.'}/assets/i18n/${lang}.json`;
    return this._httpClient.get<Translation>(path);
  }
}
