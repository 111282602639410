import { assign } from 'lodash-es';
import { ClassConstructor, plainToInstance } from 'class-transformer';
import {
  IdTypeModel,
  BankModel,
  CompanyModel,
  CountryModel,
  DisciplineModel,
  QualificationModel,
  NationalityModel,
  MaritalModel,
  InformationTypeModel,
  DesignationModel,
  SchemeTypeModel,
  DistrictModel,
  CategoryModel,
  DepartmentModel,
  ContactCategoryModel,
  DialectModel,
  GstCategoryModel,
  GstRateModel,
  MessageOutboxModel,
  ScommMatrixTypeModel
} from '@shared/data-access/models';
import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { BaseModel, PaginationAdapter } from '@red/data-access';
import { map, Observable } from 'rxjs';
import { IQuerySearch } from '@shared/data-access/interfaces';

export abstract class CollectionApiService<T extends BaseModel> {
  ctor!: ClassConstructor<T>;
  abstract get endPoint(): string;

  constructor(protected apiService: ApiService) { }
  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<T>> {
    return this.apiService.get(this.endPoint, query, option).pipe(map(data => new PaginationAdapter(this.ctor, data)));
  }
  create(data: Partial<T>): Observable<T> {
    return this.apiService.post(this.endPoint, data).pipe(map(res => plainToInstance(this.ctor, res) as T));
  }
  update(id: number, data: Partial<T>): Observable<any> {
    return this.apiService.patch(this.endPoint + '/' + id, data);
  }
  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(this.endPoint, { ids }, option);
  }

  get(id: number): Observable<T> {
    return this.apiService.get(this.endPoint + '/' + id).pipe(map(res => plainToInstance(this.ctor, res) as T));
  }
}

@Injectable({
  providedIn: 'root',
})
export class IdTypeApiService extends CollectionApiService<IdTypeModel> {
  get endPoint(): string {
    return 'collection/id-types';
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ContactCategoriesApiService extends CollectionApiService<ContactCategoryModel> {
  get endPoint(): string {
    return 'collection/contact-categories';
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CountryApiService extends CollectionApiService<CountryModel> {
  get endPoint(): string {
    return 'collection/countries';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class NationalityApiService extends CollectionApiService<NationalityModel> {
  get endPoint(): string {
    return 'collection/nationalities';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService extends CollectionApiService<CompanyModel> {
  get endPoint(): string {
    return 'settings/companies';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class BankApiService extends CollectionApiService<BankModel> {
  get endPoint(): string {
    return 'finance/banks';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MarriageApiService extends CollectionApiService<MaritalModel> {
  get endPoint(): string {
    return 'collection/maritals';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class QualificationApiService extends CollectionApiService<QualificationModel> {
  get endPoint(): string {
    return 'collection/qualifications';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class DisciplineApiService extends CollectionApiService<DisciplineModel> {
  get endPoint(): string {
    return 'collection/disciplines';
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class InformationTypeApiService extends CollectionApiService<InformationTypeModel> {
  get endPoint(): string {
    return 'collection/information-types';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class DistrictApiService extends CollectionApiService<DistrictModel> {
  get endPoint(): string {
    return 'collection/districts';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class DialectApiService extends CollectionApiService<DialectModel> {
  get endPoint(): string {
    return 'collection/dialects';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class DesignationApiService extends CollectionApiService<DesignationModel> {
  get endPoint(): string {
    return 'hrm/designations';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CommSchemeTypeApiService extends CollectionApiService<SchemeTypeModel> {
  get endPoint(): string {
    return 'hrm/scheme-types';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CommCommMatrixTypeApiService extends CollectionApiService<ScommMatrixTypeModel> {
  get endPoint(): string {
    return 'collection/categories';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ContactCategoryApiService extends CollectionApiService<ContactCategoryModel> {
  get endPoint(): string {
    return 'collection/contact-categories';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

export abstract class CategoryApiService<T extends BaseModel> extends CollectionApiService<T> {
  get endPoint(): string {
    return 'collection/categories';
  }
  get queryDefault(): IQuerySearch {
    return {};
  }
  get paramDefault(): IQuerySearch {
    return {};
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
  override search(query = {}, option?: IApiOption): Observable<PaginationAdapter<T>> {
    return this.apiService.get(this.endPoint, assign(query, this.queryDefault), option).pipe(map(data => new PaginationAdapter(this.ctor, data)));
  }
  override create(data: Partial<T>): Observable<T> {
    return this.apiService.post(this.endPoint, assign(data, this.paramDefault)).pipe(map(res => plainToInstance(this.ctor, res) as T));
  }
}

@Injectable({
  providedIn: 'root',
})
export class DeveloperContactCategoryApiService extends CategoryApiService<CategoryModel> {
  override get queryDefault(): IQuerySearch {
    return {
      categoryRoles: 'developerContact',
    };
  }
  override get paramDefault(): IQuerySearch {
    return {
      categoryRole: 'developerContact',
    };
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TransactionCategoryApiService extends CategoryApiService<CategoryModel> {
  override get queryDefault(): IQuerySearch {
    return {
      categoryRoles: 'transactionType',
    };
  }
  override get paramDefault(): IQuerySearch {
    return {
      categoryRole: 'transactionType',
    };
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CommissionMatrixTypeApiService extends CategoryApiService<CategoryModel> {
  override get queryDefault(): IQuerySearch {
    return {
      categoryRoles: 'commissionMatrixType',
    };
  }
  override get paramDefault(): IQuerySearch {
    return {
      categoryRole: 'commissionMatrixType',
    };
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectTransactionBuyerCategoryApiService extends CategoryApiService<CategoryModel> {
  override get queryDefault(): IQuerySearch {
    return {
      categoryRoles: 'projectTransactionBuyer',
    };
  }
  override get paramDefault(): IQuerySearch {
    return {
      categoryRole: 'projectTransactionBuyer',
    };
  }
  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

/*@Injectable({
  providedIn: 'root'
})
export class ContactCategoryApiService extends CategoryApiService<ContactCategoryModel>{
  override get queryDefault():IQuerySearch{
    return {
      categoryRoles: 'contact'
    }
  }
  override get paramDefault():IQuerySearch{
    return {
      categoryRole: 'contact'
    }
  }
  constructor(protected override apiService: ApiService){
    super(apiService)
  }
}*/

@Injectable({
  providedIn: 'root',
})
export class DepartmentApiService extends CollectionApiService<DepartmentModel> {
  get endPoint(): string {
    return 'collection/departments';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class GstCategoryApiService extends CategoryApiService<GstCategoryModel> {
  override get queryDefault(): IQuerySearch {
    return { categoryRoles: 'gstCategory' };
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class GstRateApiService extends CollectionApiService<GstRateModel> {
  override ctor = GstRateModel;

  get endPoint(): string {
    return 'collection/gst-rates';
  }

  constructor(protected override apiService: ApiService) {
    super(apiService);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MessageOutboxApiService {
  get endPoint(): string {
    return 'collection/message-outbox';
  }

  get endPointSend(): string {
    return `${this.endPoint}/send`;
  }

  get resaleEndPoint(): string {
    return 'collection/message-outbox/resale-invoice';
  }

  get resaleEndPointSend(): string {
    return `${this.resaleEndPoint}/send`;
  }

  get resaleEndPointExport(): string {
    return `${this.resaleEndPoint}/export`;
  }

  constructor(protected apiService: ApiService) { }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<MessageOutboxModel>> {
    return this.apiService.get(this.endPoint, query, option).pipe(map(data => new PaginationAdapter(MessageOutboxModel, data)));
  }

  send(payload: { data: { code: string }[] }): Observable<any> {
    return this.apiService.post(this.endPointSend, payload);
  }

  resend(code: string): Observable<any> {
    return this.apiService.post(`${this.endPoint}/${code}/resend`, {});
  }

  get(code: string): Observable<MessageOutboxModel> {
    return this.apiService.get(this.endPoint + '/' + code).pipe(map(res => plainToInstance(MessageOutboxModel, res) as MessageOutboxModel));
  }

  searchResale(query = {}, option?: IApiOption): Observable<PaginationAdapter<MessageOutboxModel>> {
    return this.apiService.get(this.resaleEndPoint, query, option).pipe(map(data => new PaginationAdapter(MessageOutboxModel, data)));
  }

  sendResale(payload: { data: { code: string }[] }): Observable<any> {
    return this.apiService.post(this.resaleEndPointSend, payload);
  }

  getResale(code: string): Observable<MessageOutboxModel> {
    return this.apiService.get(this.resaleEndPoint + '/' + code).pipe(map(res => plainToInstance(MessageOutboxModel, res) as MessageOutboxModel));
  }

  exportResale(query = {}, option?: IApiOption): Observable<any> {
    return this.apiService.post(this.resaleEndPointExport, query, {
      ...option,
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  getResaleAttachment(data: { code: string; key: string }) {
    return this.apiService.post(`${this.resaleEndPoint}/:code/attachment/:key`, data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  getAttachment(data: { code: string; key: string }) {
    return this.apiService.post(`${this.endPoint}/:code/attachment/:key`, data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }
}
