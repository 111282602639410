import { Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { GrossCommissionEarnedModel, ResaleRelatedAgentModel, ResaleTransactionModel } from '.';
import { RESALE_ADJUSTMENT_STATUSES } from '../data';
import { EResaleAdjustmentStatus } from '../enums';
import { IResaleAdjustment } from '../interfaces';
import { BaseFixedModel } from './base.model';

export class ResaleAdjustmentModel extends BaseFixedModel implements IResaleAdjustment {
  @Expose()
  code!: string;

  @Expose()
  invoiceAt?: string;

  @Expose()
  @Default(EResaleAdjustmentStatus.draft)
  status!: EResaleAdjustmentStatus;

  @Expose()
  remarks?: string;

  @Expose()
  resaleId?: number;

  @Expose()
  @Type(() => ResaleTransactionModel)
  resale?: ResaleTransactionModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  grossComm?: GrossCommissionEarnedModel;

  @Expose()
  @Default(ResaleRelatedAgentModel.createEmpty())
  @Type(() => ResaleRelatedAgentModel)
  relatedAgent!: ResaleRelatedAgentModel;

  @Expose()
  rejectReason?: string;

  get statusDescription() {
    return RESALE_ADJUSTMENT_STATUSES[this.status];
  }

  get canSubmit(): boolean {
    return RESALE_ADJUSTMENT_STATUSES[this.status].canSubmit ?? false;
  }

  get canEdit(): boolean {
    return RESALE_ADJUSTMENT_STATUSES[this.status].canEdit ?? false;
  }

  get canDelete(): boolean {
    return RESALE_ADJUSTMENT_STATUSES[this.status].canDelete ?? false;
  }
}
