import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IRedocOauth2Config, RedocOAuthLogger, RedocOAuthStorage } from '@redocco/oauth2-redoc-connect';
import { RedocConnectService } from './redoc-connect.service';
import { REDOC_BASE_URL, REDOC_OAUTH2_CONFIG } from './redoc-connect.token';
import { MemoryStorage } from './memory-storage';

@NgModule({
  imports: [CommonModule],
})
export class RedocConnectModule {
  static forRoot(appBaseUrl: string, config: Partial<IRedocOauth2Config>): ModuleWithProviders<RedocConnectModule> {
    return {
      ngModule: RedocConnectModule,
      providers: [
        RedocConnectService,
        { provide: RedocOAuthLogger, useFactory: createDefaultLogger },
        { provide: RedocOAuthStorage, useFactory: createDefaultStorage },
        {
          provide: REDOC_OAUTH2_CONFIG,
          useValue: config,
        },
        {
          provide: REDOC_BASE_URL,
          useValue: appBaseUrl,
        },
      ],
    };
  }
}

export function createDefaultLogger() {
  return console;
}
export function createDefaultStorage() {
  console.log('sessionStorage', sessionStorage);
  return typeof sessionStorage !== 'undefined' ? sessionStorage : new MemoryStorage();
}
