import { OptionDescription } from '@shared/data-access/types';

export enum EPaymentMode {
  all = 'all',
  partial = 'partial',
  full = 'full',
}

export enum EReceiptPaymentMode {
  partial = 'partial',
  full = 'full',
  reversal = 'reversal',
}

export const BATCH_PAYMENT_MODE_OPTIONS: Record<EPaymentMode, OptionDescription<EPaymentMode>> = {
  [EPaymentMode.all]: {
    id: EPaymentMode.all,
    value: EPaymentMode.all,
    viewValue: 'All',
    display: '',
    class: '',
  },
  [EPaymentMode.partial]: {
    id: EPaymentMode.partial,
    value: EPaymentMode.partial,
    viewValue: 'Partial',
    display: 'Partial Payment',
    class: 'status-box-warning',
  },
  [EPaymentMode.full]: {
    id: EPaymentMode.full,
    value: EPaymentMode.full,
    viewValue: 'Full',
    display: 'Full Payment',
    class: 'status-box-success',
  },
};

export const BATCH_RECEIPT_PAYMENT_MODE_DATA: Record<EReceiptPaymentMode, OptionDescription<EReceiptPaymentMode>> = {
  [EReceiptPaymentMode.partial]: {
    id: EReceiptPaymentMode.partial,
    value: EReceiptPaymentMode.partial,
    viewValue: 'Partial',
    display: 'Partial Payment',
    class: 'status-box-warning',
  },
  [EReceiptPaymentMode.full]: {
    id: EReceiptPaymentMode.full,
    value: EReceiptPaymentMode.full,
    viewValue: 'Full',
    display: 'Full Payment',
    class: 'status-box-success',
  },
  [EReceiptPaymentMode.reversal]: {
    id: EReceiptPaymentMode.reversal,
    value: EReceiptPaymentMode.reversal,
    viewValue: 'Reversal',
    display: 'Reversal',
    class: 'status-box-danger',
  },
};
