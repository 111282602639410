import { BaseModel, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EAccountingPeriods } from '../enums/accounting-periods.enum';

export class AccountingPeriodsCreateDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  // @IsoString({ keepDateValue: true })
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  // @IsoString({ keepDateValue: true })
  endDate!: string;

  @Expose()
  type!: EAccountingPeriods;
}
