import { UpdatedByModel } from "../models";

export interface IBase {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: string;
  updatedBy?: UpdatedByModel;
  deletedBy: string;
}
