import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class ContactEmailBaseDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  email!: string;
}
