import { EPersonalVerifyKey } from '../enums';
import { PersonalVerificationFieldRecord } from '../types';

export const PERSONAL_VERIFY_FIELD_OPTIONS: PersonalVerificationFieldRecord = {
  [EPersonalVerifyKey.address]: {
    id: EPersonalVerifyKey.address,
    value: EPersonalVerifyKey.address,
    viewValue: 'Address',
  },
  [EPersonalVerifyKey.associatesCode]: {
    id: EPersonalVerifyKey.associatesCode,
    value: EPersonalVerifyKey.associatesCode,
    viewValue: 'Associates Code',
  },
  [EPersonalVerifyKey.bankAccountNo]: {
    id: EPersonalVerifyKey.bankAccountNo,
    value: EPersonalVerifyKey.bankAccountNo,
    viewValue: 'Bank Account No.',
  },
  [EPersonalVerifyKey.bankId]: {
    id: EPersonalVerifyKey.bankId,
    value: EPersonalVerifyKey.bankId,
    viewValue: 'Bank',
  },
  [EPersonalVerifyKey.email1]: {
    id: EPersonalVerifyKey.email1,
    value: EPersonalVerifyKey.email1,
    viewValue: 'Email - 1',
  },
  [EPersonalVerifyKey.email2]: {
    id: EPersonalVerifyKey.email2,
    value: EPersonalVerifyKey.email2,
    viewValue: 'Email - 2',
  },
  [EPersonalVerifyKey.fax]: {
    id: EPersonalVerifyKey.fax,
    value: EPersonalVerifyKey.fax,
    viewValue: 'Fax',
  },
  [EPersonalVerifyKey.phone]: {
    id: EPersonalVerifyKey.phone,
    value: EPersonalVerifyKey.phone,
    viewValue: 'Home Tel.',
  },
  [EPersonalVerifyKey.mobile1]: {
    id: EPersonalVerifyKey.mobile1,
    value: EPersonalVerifyKey.mobile1,
    viewValue: 'Mobile - 1',
  },
  [EPersonalVerifyKey.mobile2]: {
    id: EPersonalVerifyKey.mobile2,
    value: EPersonalVerifyKey.mobile2,
    viewValue: 'Mobile - 2',
  },
  [EPersonalVerifyKey.postalCode]: {
    id: EPersonalVerifyKey.postalCode,
    value: EPersonalVerifyKey.postalCode,
    viewValue: 'Postal Code',
  },
};
