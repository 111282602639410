import { BankModel, ContactCustomerAndSupplierModel } from '../models';
import { IBase } from './base-finance.interface';

export interface ISupplierBankAccount extends IBase {
  contact: ContactCustomerAndSupplierModel;
  contactId?: number;
  bankId: number;
  accountNumber: string;
  accountName: string;
  remarks?: string;
  bank?: BankModel;
}
