import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { AssetDisposalCreateDto, AssetDisposalUpdateDto } from '@shared/data-access/dto';
import { IAssetDisposal } from '@shared/data-access/interfaces';
import { AssetDisposalModel } from '@shared/data-access/models';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetDisposalApiService {
  static ROOT_POINT = 'asset-disposals';

  constructor(private apiService: ApiService) {}

  create(data: AssetDisposalCreateDto): Observable<AssetDisposalModel> {
    return this.apiService
      .post(`${AssetDisposalApiService.ROOT_POINT}`, data)
      .pipe(map((res: IAssetDisposal) => AssetDisposalModel.fromJson(res) as AssetDisposalModel));
  }

  get(id: number, query = {}): Observable<AssetDisposalModel> {
    return this.apiService.get(AssetDisposalApiService.ROOT_POINT + '/' + id, query).pipe(map(res => AssetDisposalModel.fromJson(res) as AssetDisposalModel));
  }

  update(id: number, data: AssetDisposalUpdateDto): Observable<any> {
    return this.apiService.patch(AssetDisposalApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<AssetDisposalModel>> {
    return of(new PaginationAdapter<AssetDisposalModel>(AssetDisposalModel, { meta: { limit: 10, page: 1, pageCount: 1, total: 1 }, items: [{ hello: 'world' }] }));
    // return this.apiService.get(AssetDisposalApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(AssetDisposalModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(AssetDisposalApiService.ROOT_POINT + '/' + id, data, option);
  }
}
