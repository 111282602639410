import { BaseModel } from '@red/data-access';
import { Expose, Transform } from 'class-transformer';

export class CommissionMatrixCreateDto extends BaseModel {
  @Expose()
  name!: string;
}

export class CommissionMatrixUpdateDto extends BaseModel {
  @Expose()
  name!: string;
}

export class CommissionMatrixTypeDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => value.trim())
  name!: string;

  @Expose()
  isActive!: boolean;
}
