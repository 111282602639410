import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaStorageService } from './media-storage.service';
import { RED_MEDIA_STORAGE } from './token';
import { IMediaStorageConfig } from './media-storage.constant';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [MediaStorageService],
})
export class MediaStorageModule {
  /**
   * Constructor
   */
  constructor(private _service: MediaStorageService) {}
  /**
   * forRoot method for setting user configuration
   *
   * @param config
   */
  static forRoot(config: IMediaStorageConfig): ModuleWithProviders<MediaStorageModule> {
    return {
      ngModule: MediaStorageModule,
      providers: [
        {
          provide: RED_MEDIA_STORAGE,
          useValue: config,
        },
      ],
    };
  }
}
