import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { Status } from '../enums';

export class SupplierBankAccountDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => Number)
  bankId!: number;

  @Expose()
  accountNumber!: string;

  @Expose()
  accountName!: string;

  @Expose()
  contactStatus?: Status;

  @Expose()
  @Type(() => Number)
  contactId!: number;
}

export class SupplierBankAccountValidateDto extends BaseModel {
  @Expose()
  bankId!: number;

  @Expose()
  accountNumber!: string;
}
