import { ExceljsCell, ExceljsCol, ExceljsNormalizedCell, ExceljsWorkSheet } from './../models/exceljs.model';
import { AfterViewInit, Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { ExceljsRow, ExceljsValueType } from '../models';
import { cloneDeep, defaults } from 'lodash-es';
import { CellValueFmtPipe } from '../exceljs-numFmt.pipe';
import { CollWidthPipe } from '../exceljs-col-width.pipe';
import { RouterModule } from '@angular/router';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { ExceljsCellComponent } from '../exceljs-cell/exceljs-cell.component';
import { ExceljsCellHyperlinkComponent } from '../exceljs-cell-hyperlink/exceljs-cell-hyperlink.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ExceljsVirtualScrollControllerDirective } from '../exceljs-virtual-scroll-controller.directive';
import { ClearEnterDirective } from './normalized-text.directive';

@Component({
  selector: 'red-exceljs-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    CellValueFmtPipe,
    CollWidthPipe,
    RouterModule,
    ScrollingModule,
    ExceljsVirtualScrollControllerDirective,
    ExceljsCellHyperlinkComponent,
    ClearEnterDirective,
  ],
  templateUrl: './exceljs-table.component.html',
  styleUrls: ['./exceljs-table.component.scss'],
})
export class ExceljsTableComponent {
  @Input('virtualSrollMode') get isVirtualSrollMode() {
    return this._isVirtualSrollMode;
  }
  set isVirtualSrollMode(val: any) {
    this._isVirtualSrollMode = coerceBooleanProperty(val);
  }
  _isVirtualSrollMode!: boolean;
  @Input() set worksheet(val: ExceljsWorkSheet | undefined) {
    this._worksheet = val;
    this.renderTable();
  }
  get worksheet() {
    return this._worksheet;
  }
  private _worksheet!: ExceljsWorkSheet | undefined;

  @HostBinding('class.virtual-scroll-mode-disabled') get getMode() {
    return !this.isVirtualSrollMode;
  }
  rows: ExceljsRow[] = [];
  cols: ExceljsCol[] = [];
  merges: string[] = [];
  dataSource: ExceljsRow[] = [];
  dataHeader: ExceljsRow[] = [];
  matries = [];
  defaultColWidth?: number;
  defaultRowHeight: number = 40;
  defaultBackgroundColor = 'ffffff';
  exceljsValueType = ExceljsValueType;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewPort!: CdkVirtualScrollViewport;
  rowHeaderFreeze: number = 0;
  topHeader = 0;

  @ViewChild(ExceljsVirtualScrollControllerDirective) public scrollController?: ExceljsVirtualScrollControllerDirective;
  renderTable() {
    this.rows = this.worksheet?.rows || [];
    this.cols = this.worksheet?.cols || [];
    this.dataSource = [];
    this.merges = this.worksheet?.merges || [];
    this.defaultColWidth = this.worksheet?.properties.defaultColWidth;
    // this.defaultColWidth = 140;
    // this.defaultRowHeight = this.worksheet?.properties.defaultRowHeight;
    this.defaultRowHeight = 40;
    this.rowHeaderFreeze = this.worksheet?.views?.find(_ => _?.state === 'frozen')?.ySplit ?? 0;
    this.renderHeaderRows()
    this.renderRows();

  }
  renderHeaderRows() {
    for (let i = 0; i < this.rowHeaderFreeze; i++) {
      const rowCollectionHeader: ExceljsRow = ExceljsRow.fromJson({
        cells: this.rows[i].cells.filter(cell => !cell.master).map(cell => this.renderCell(cell, this.rows[i], this.merges)),
      });
      this.dataHeader.push(rowCollectionHeader);
    }
  }
  renderRows(): void {
    console.log('rowlength -->', this.rows.length);
    for (const row of this.rows) {
      if (row.number > this.rowHeaderFreeze) {
        const selectedRow = cloneDeep(row);
        let selectedIndex = 1;
        while (selectedIndex < row.min) {
          const emptyCell = ExceljsNormalizedCell.createEmpty();
          selectedRow.cells.unshift(emptyCell);
          selectedIndex++;
        }
        this.renderRow(selectedRow);
      }
    }
  }
  renderRow(row: ExceljsRow): void {
    // for (const row of this.rows) {
    const rowCollection: ExceljsRow = ExceljsRow.fromJson({
      cells: row.cells.filter(cell => !cell.master).map(cell => this.renderCell(cell, row, this.merges)),
    });
    this.dataSource.push(rowCollection);
    // }
  }
  renderCell(cell: ExceljsCell, row: ExceljsRow, merges: string[] = []) {
    const attrs = this.getCellAttrs(cell, row, merges);
    return defaults(cell, { attrs });
  }
  getCellAttrs(cell: ExceljsCell, row: ExceljsRow, merges: string[]) {
    const mergeItem = merges.find(item => {
      const [start, end] = item.split(':');
      return start === cell.address;
    });
    if (!mergeItem) {
      return {
        colspan: 1,
        rowspan: 1,
      };
    }
    const [addressStart, addressEnd] = mergeItem.split(':');
    const addressStartName = (addressStart.match(/[A-Z]/g) as RegExpMatchArray).join('');
    const addressStartPosition = Number(addressStart.split(addressStartName)[1]);
    const rowStart = this.rows[addressStartPosition - 1];
    const cellStartIndex = rowStart.cells.findIndex(cellItem => cellItem.address === addressStart) + rowStart.min;

    const addressEndName = (addressEnd.match(/[A-Z]/g) as RegExpMatchArray).join('');
    const addressEndPosition = Number(addressEnd.split(addressEndName)[1]);
    const rowEnd = this.rows[addressEndPosition - 1];
    const cellEndIndex = rowEnd.cells.findIndex(cellItem => cellItem.address === addressEnd) + rowEnd.min;
    return {
      colspan: cellEndIndex - cellStartIndex + 1,
      rowspan: addressEndPosition - addressStartPosition + 1,
    };
  }

  // private nameCell(address: string): { rowIndex: number; collIndex: string } {
  //   const addressName = (address.match(/[A-Z]/g) as RegExpMatchArray).join('');
  //   const addressPosition = Number(address.split(addressName)[1]);
  //   return {
  //     rowIndex,
  //   };
  // }

}
