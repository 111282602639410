import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsCurrencyPipe } from './currency.pipe';

@NgModule({
  declarations: [CmsCurrencyPipe],
  providers: [CurrencyPipe, CmsCurrencyPipe],
  exports: [CmsCurrencyPipe],
})
export class CurrencyPipeModule {}
