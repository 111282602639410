import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IAccountingPeriods } from '../interfaces';
import { UpdatedByModel } from './updated-by.model';
import { EAccountingPeriods } from '../enums/accounting-periods.enum';

export class AccountingPeriodsModel extends BaseModel implements IAccountingPeriods {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => Number)
  businessUnitId!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  @Type(() => Number)
  createdBy!: number;

  @Expose()
  updatedAt!: string;

  @Expose()
  @Type(() => UpdatedByModel)
  updatedBy!: UpdatedByModel;

  @Expose()
  deletedAt!: string;

  @Expose()
  @Type(() => Number)
  deletedBy!: number;

  @Expose()
  startDate!: string;

  @Expose()
  endDate!: string;

  @Expose()
  type!: EAccountingPeriods;

  @Expose()
  isOpen!: boolean;

  @Expose()
  @Type(() => Number)
  version!: number;

  @Expose()
  @Type(() => Number)
  sn!: number;

  @Expose()
  // @Transform(({ obj }) => obj?.isOpen ? 'Open' : 'Closed')
  @Transform(({ obj }) => 'Closed')
  status!: string;
}
