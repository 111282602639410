import { EGstType } from '../enums';

export interface IGrossCommissionEarned {
  amount: number;
  currency: string;
  grossType: string;
  gstType?: `${EGstType}`;
  includeGst: boolean;
  rebateAmount?: number;
  subAmount?: number;
  subTotalAmount: number;
  taxAmount: number;
  taxType: 'percentage' | 'number';
  taxValue: number;
  totalAmount: number;
  isStupidFollow: boolean;
}
