import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ProjectCommissionMatrixCreateDto, ProjectCommissionMatrixUpdateDto, ProjectCommissionStatusUpdateDto } from '@shared/data-access/dto';
import { ICommissionMatrix } from '@shared/data-access/interfaces';
import { ProjectCommissionMatrixModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommissionMatrixApiService {
  static ROOT_POINT = 'transaction/project-commissions/portal';
  static CHANGE_STATUS_POINT = 'transaction/project-commissions/portal/submit';

  constructor(private apiService: ApiService) {}

  create(data: ProjectCommissionMatrixCreateDto): Observable<ProjectCommissionMatrixModel> {
    return this.apiService
      .post(`${CommissionMatrixApiService.ROOT_POINT}`, data)
      .pipe(map((res: ICommissionMatrix) => ProjectCommissionMatrixModel.fromJson(res) as ProjectCommissionMatrixModel));
  }

  get(itemId: number): Observable<ProjectCommissionMatrixModel> {
    return this.apiService
      .get(CommissionMatrixApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => ProjectCommissionMatrixModel.fromJson(res) as ProjectCommissionMatrixModel));
  }

  getAgentInfo(id: number): Observable<any> {
    return this.apiService.get(CommissionMatrixApiService.ROOT_POINT + '/' + id + '/agent');
  }

  update(id: number, data: ProjectCommissionMatrixUpdateDto): Observable<any> {
    return this.apiService.patch(CommissionMatrixApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectCommissionMatrixModel>> {
    return this.apiService
      .get(CommissionMatrixApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectCommissionMatrixModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(CommissionMatrixApiService.ROOT_POINT, { ids }, option);
  }

  submit(payload: ProjectCommissionStatusUpdateDto): Observable<any> {
    return this.apiService.post(CommissionMatrixApiService.CHANGE_STATUS_POINT, payload);
  }
}
