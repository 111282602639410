import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ReceiptCreateDto, ReceiptPostingReqDto, ReceiptUpdateDto } from '@shared/data-access/dto';
import { IReceipt } from '@shared/data-access/interfaces';
import { DealValueModel, ReceiptDataSource, ReceiptModel, ReceiptOffsetModel, ReceiptPostingDataSourceModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReceiptApiService {
  static ROOT_POINT = 'finance/receipts';
  static OFFSETS_POINT = 'finance/receipts/:id/offsets';
  static PREVIEW_POSTING = 'finance/receipts/postings/preview';
  static PREVIEW_VOUCHER = 'finance/receipts/:id/template/preview';
  static PREVIEW_TOTAL_AMOUNT = 'finance/receipts/total-amount';
  static RECORDS_AND_POSTING_DETAIL = 'finance/receipts/:id/details';

  constructor(private apiService: ApiService) {}

  create(data: ReceiptCreateDto): Observable<ReceiptModel> {
    return this.apiService.post(`${ReceiptApiService.ROOT_POINT}`, data).pipe(map((res: IReceipt) => ReceiptModel.fromJson(res) as ReceiptModel));
  }

  get(itemId: number): Observable<ReceiptModel> {
    return this.apiService.get(ReceiptApiService.ROOT_POINT + '/' + itemId).pipe(map(res => ReceiptModel.fromJson(res) as ReceiptModel));
  }

  getRecordsAndPostings(id: number): Observable<ReceiptDataSource> {
    return this.apiService.get(ReceiptApiService.RECORDS_AND_POSTING_DETAIL, { id }).pipe(map(res => ReceiptDataSource.fromJson(res) as ReceiptDataSource));
  }

  update(id: number, data: ReceiptUpdateDto): Observable<any> {
    return this.apiService.patch(ReceiptApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ReceiptModel>> {
    return this.apiService.get(ReceiptApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ReceiptModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(ReceiptApiService.ROOT_POINT + '/' + id, option);
  }

  previewTotalAmount(data: { details: { credit: DealValueModel; debit: DealValueModel }[] }): Observable<any> {
    return this.apiService.post(ReceiptApiService.PREVIEW_TOTAL_AMOUNT, data);
  }

  previewPosting(data: ReceiptPostingReqDto): Observable<ReceiptPostingDataSourceModel> {
    return this.apiService
      .post(ReceiptApiService.PREVIEW_POSTING, data)
      .pipe(map(res => ReceiptPostingDataSourceModel.fromJson(res) as ReceiptPostingDataSourceModel));
  }

  previewVoucher(id: number): Observable<any> {
    return this.apiService.post(
      ReceiptApiService.PREVIEW_VOUCHER,
      { id },
      {
        pretreatmentResponse: false,
        requestOptions: { responseType: 'arraybuffer' },
      }
    );
  }

  getOffsets(id: number): Observable<any> {
    return this.apiService.get(ReceiptApiService.OFFSETS_POINT, { id }).pipe(map(res => ReceiptOffsetModel.fromJson(res) as ReceiptOffsetModel));
  }
}
