import { EUserGroup } from '../enums';
import { IUpdatedBy } from './updated-by.interface';
import { IUserRole } from './user-role.interface';

export interface IUserGroupAccessible {
  id: number;
  module: EUserGroup;
  total: number;
  totalRead: number;
  totalCreate: number;
  totalUpdate: number;
  totalDelete: number;
}

export interface IUserGroup {
  id: number;
  code: string;
  name: string;
  description: string;
  roles: IUserRole[];
  status: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: IUpdatedBy;
  accessible: IUserGroupAccessible[];
}
