<div class="flex flex-col flex-auto min-w-0">
  <div class="flex flex-auto flex-col overflow-hidden">
    <div class="flex flex-col">
      <div class="flex items-start text-theme-gray">
        <a mat-button (click)="back()"><mat-icon>keyboard_backspace</mat-icon> Back</a>
      </div>
      <div class="flex flex-row justify-between items-center px-6 py-4">
        <div class="text-h5 text-bold">{{ card?.name }}</div>
        <div class="flex flex-row justify-end">
          <button mat-icon-button matTooltip="Export Excel" (click)="download()">
            <mat-icon class="text-theme-gray">file_download</mat-icon>
          </button>
        </div>
      </div>
      <div class="mx-4">
        <red-metabase-parameter [card]="card" [parameters]="parameters"></red-metabase-parameter>
      </div>
    </div>
    <div class="relative w-full flex flex-auto flex-col">
      <div class="absolute mx-4 border border-b-0 border-theme-gray-border border-solid inset-0 flex flex-col min-w-0 overflow-hidden">
        <div class="flex flex-col flex-auto overflow-auto">
          <red-metabase-table [dataSource]="dataSource" [columns]="columns"></red-metabase-table>
        </div>
        <ng-template [redLoadingBox]="loading"></ng-template>
      </div>
    </div>
  </div>
</div>
