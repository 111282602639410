import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import {
  ECBInvoiceDto,
  PreviewOriginalTemplateDto,
  PreviewProformaTemplateDto,
  PreviewTaxInvoiceTemplateDto,
  TaxInvoiceDto,
  TaxInvoicePostingReqDto,
  TaxInvoicePreviewInvoicePdfDto,
} from '@shared/data-access/dto';
import { ITaxInvoice, ITaxInvoicePayment, ITaxInvoicePosting } from '@shared/data-access/interfaces';
import {
  AssociatePayoutModel,
  ExternalPayoutModel,
  MemoPayableModel,
  PaymentMemoModel,
  ReceiptMemoModel,
  TaxInvoiceModel,
  TaxInvoicePostingModel,
  TaxInvoiceRecordModel,
} from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaxInvoiceEnhancedApiService {
  static CODE = 'finance/tax-invoices/code/next';
  static FINANCE = 'finance';
  static ROOT_POINT = 'finance/tax-invoices';
  static CANCEL_POINT = 'finance/tax-invoices/:id/cancel';
  static PAYMENTS_POINT = 'finance/tax-invoices/:id/payments';
  static VALIDATOR_CODE = 'finance/tax-invoices/validator/code';
  static PREVIEW_POSTING = 'finance/tax-invoices/postings/preview';
  static ECB_INVOICE_POINT = 'finance/tax-invoices/ecb';
  static ASSOCIATE_PAYOUT = 'finance/tax-invoices/:id/associate-payout';
  static EXTERNAL_PAYOUT = 'finance/tax-invoices/:id/external-payout';
  static FOR_PAYMENT_POINT = 'finance/supplier-invoices/details/for-supplier-payments';
  static FOR_RECEIPT_POINT = 'finance/tax-invoices/for-receipts';
  static FOR_CREDIT_NOTE_POINT = 'finance/tax-invoices/for-credit-notes';
  static FOR_SUPPLIER_CREDIT_NOTE_POINT = 'finance/tax-invoices/for-scn';
  //static SUPPLIER_INVOICE_RECORD_FOR_PAYMENT_POINT = 'finance/tax-invoices/for-payments';
  static SUPPLIER_INVOICE_RECORD_FOR_PAYMENT_POINT = 'finance/supplier-invoices/for-payments';

  constructor(private apiService: ApiService, private _router: Router) { }

  viewOriginal(data: PreviewOriginalTemplateDto): Observable<unknown> {
    return this.apiService.post(TaxInvoiceEnhancedApiService.FINANCE + '/template-v2/preview/original', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  // New
  get(id: number, query = {}): Observable<TaxInvoiceModel> {
    return this.apiService.get(TaxInvoiceEnhancedApiService.ROOT_POINT + '/' + id, query).pipe(map(res => TaxInvoiceModel.fromJson(res) as TaxInvoiceModel));
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<TaxInvoiceModel>> {
    return this.apiService.get(TaxInvoiceEnhancedApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(TaxInvoiceModel, data)));
  }

  getValidTaxInvoiceForCreditNote(query = {}, option?: IApiOption): Observable<PaginationAdapter<TaxInvoiceModel>> {
    return this.apiService.get(TaxInvoiceEnhancedApiService.FOR_CREDIT_NOTE_POINT, query, option).pipe(map(data => new PaginationAdapter(TaxInvoiceModel, data)));
  }

  getTaxInvoiceForSCN(query = {}, option: IApiOption): Observable<PaginationAdapter<TaxInvoiceModel>> {
    return this.apiService
      .get(TaxInvoiceEnhancedApiService.FOR_SUPPLIER_CREDIT_NOTE_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(TaxInvoiceModel, data)));
  }

  previewPosting(data: TaxInvoicePostingReqDto): Observable<TaxInvoicePostingModel[]> {
    return this.apiService
      .post(TaxInvoiceEnhancedApiService.PREVIEW_POSTING, data)
      .pipe(map(res => res.map((item: ITaxInvoicePosting) => TaxInvoicePostingModel.fromJson(item))));
  }

  // getTaxInvoice(id: number): Observable<TaxInvoiceModel> {
  //   return this.apiService.get(TaxInvoiceEnhancedApiService.ROOT_POINT + '/' + id).pipe(map(res => TaxInvoiceModel.fromJson(res) as TaxInvoiceModel));
  // }

  getEnhancedDetails(id: number, query = {}): Observable<PaginationAdapter<TaxInvoiceRecordModel>> {
    return this.apiService
      .get(TaxInvoiceEnhancedApiService.ROOT_POINT + '/' + id + '/details', query)
      .pipe(map(res => new PaginationAdapter(TaxInvoiceRecordModel, res)));
  }

  getEnhancedPostings(id: number, query = {}): Observable<PaginationAdapter<TaxInvoicePostingModel>> {
    return this.apiService
      .get(TaxInvoiceEnhancedApiService.ROOT_POINT + '/' + id + '/postings', query)
      .pipe(map(res => new PaginationAdapter(TaxInvoicePostingModel, res)));
  }

  getEnhancedPayments(id: number, query = {}): Observable<ITaxInvoicePayment[]> {
    return this.apiService.get(TaxInvoiceEnhancedApiService.PAYMENTS_POINT, { id, ...query });
  }

  create(data: TaxInvoiceDto): Observable<TaxInvoiceModel> {
    return this.apiService.post(`${TaxInvoiceEnhancedApiService.ROOT_POINT}`, data).pipe(map((res: ITaxInvoice) => TaxInvoiceModel.fromJson(res) as TaxInvoiceModel));
  }

  update(id: number, data: TaxInvoiceDto): Observable<TaxInvoiceModel> {
    return this.apiService.patch(TaxInvoiceEnhancedApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(TaxInvoiceEnhancedApiService.ROOT_POINT + '/' + id, data, option);
  }

  cancel(id: number, data = {}): Observable<any> {
    return this.apiService.patch(TaxInvoiceEnhancedApiService.CANCEL_POINT, { id, ...data });
  }

  viewPdf(data: TaxInvoicePreviewInvoicePdfDto): Observable<any> {
    return this.apiService.post(TaxInvoiceEnhancedApiService.ROOT_POINT + '/template/preview', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  viewOriginalPdf(data: PreviewTaxInvoiceTemplateDto): Observable<unknown> {
    return this.apiService.post(TaxInvoiceEnhancedApiService.ROOT_POINT + '/template/preview/original', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  searchSupplierInvoiceRecordsForPayment(query = {}, option?: IApiOption): Observable<PaginationAdapter<MemoPayableModel>> {
    return this.apiService
      .get(TaxInvoiceEnhancedApiService.SUPPLIER_INVOICE_RECORD_FOR_PAYMENT_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(MemoPayableModel, data)));
  }

  getInvoicesForReceipt(query = {}): Observable<PaginationAdapter<ReceiptMemoModel>> {
    return this.apiService.get(TaxInvoiceEnhancedApiService.FOR_RECEIPT_POINT, { ...query }).pipe(map(res => new PaginationAdapter(ReceiptMemoModel, res)));
  }

  getInvoicesForPayment(query = {}): Observable<PaginationAdapter<PaymentMemoModel>> {
    return this.apiService.get(TaxInvoiceEnhancedApiService.FOR_PAYMENT_POINT, query).pipe(map(res => new PaginationAdapter(PaymentMemoModel, res)));
  }

  viewProforma(data: PreviewProformaTemplateDto) {
    return this.apiService.post(TaxInvoiceEnhancedApiService.ROOT_POINT + '/template/preview/proforma', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  getAssociatePayout(id: number): Observable<AssociatePayoutModel[]> {
    return this.apiService.get(TaxInvoiceEnhancedApiService.ASSOCIATE_PAYOUT, { id }).pipe(map(res => res.map((v: any) => AssociatePayoutModel.fromJson(v))));
  }

  getExternalPayout(id: number, resaleId: number): Observable<ExternalPayoutModel[]> {
    return this.apiService.get(TaxInvoiceEnhancedApiService.EXTERNAL_PAYOUT, { id, resaleId }).pipe(map(res => res.map((v: any) => ExternalPayoutModel.fromJson(v))));
  }

  createECBInvoice(data: ECBInvoiceDto): Observable<any> {
    return this.apiService.post(TaxInvoiceEnhancedApiService.ECB_INVOICE_POINT, data);
  }
}
