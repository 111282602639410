import { Expose } from 'class-transformer';
import { IFileUploader } from '../interfaces';
import { BaseModel } from './base.model';

export class FileUploaderModel extends BaseModel implements IFileUploader {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  isPreview = false;

  @Expose()
  file: File | any;

  @Expose()
  uploading = false;

  @Expose()
  error = {
    status: false,
    message: '',
  };

  @Expose()
  key!: string;

  @Expose()
  fullKeyName?: string;

  @Expose()
  data!: string;

  @Expose()
  url?: string;

  @Expose()
  completed = false;

  @Expose()
  isExistFile = false;

  @Expose()
  size?: number;
}
