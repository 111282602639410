import { IProjectTransactionRelatedCobroke } from './project-transaction-related-cobroke.interface';
import { IProjectTransactionOverriding } from './project-transaction-overriding.interface';
import { IProjectTransactionBonus } from './project-transaction-bonus.interface';
import { IProjectTransactionRelatedAgent } from './project-transaction-related-agent.interface';
import { IProjectTransactionBds } from './project-transaction-bds.interface';

export interface IProjectTransactionAgentInfoSummary {
  agentTierProfit: number;
  errorMessages?: string[];
  externals: IProjectTransactionRelatedCobroke[];
  internals: IProjectTransactionRelatedAgent[];
  leaders: IProjectTransactionRelatedAgent[];
  main: IProjectTransactionRelatedAgent;
  profitSharingAmount?: number;
  transactionBds: IProjectTransactionBds[];
  transactionBonuses: IProjectTransactionBonus[];
  transactionPdOverrides: IProjectTransactionOverriding[];
  headHiReceived: number;
  bdReceived: number;
  hiTierReceived: number;
}
