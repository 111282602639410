import { UpdatedByModel } from './updated-by.model';
import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform } from 'class-transformer';
import { EManagementCommissionStatus } from '../enums';
import { IManagementCommission, IManagementCommissionPD, IStatusDescription } from '../interfaces';
import { MANAGEMENT_COMMISSION_STATUSES } from '@shared/data-access/data/management-commission.data';
import { TransactionStatusHistoryModel } from '@shared/data-access/models/transaction-status-history.model';

export class ManagementCommissionModel extends BaseModel implements IManagementCommission {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  status!: EManagementCommissionStatus;

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  name!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  remarks!: string;

  @Expose()
  startDate!: string;

  @Expose()
  endDate!: string;

  @Expose()
  relatedPd!: {
    pd: IManagementCommissionPD[];
    pdManagement: IManagementCommissionPD[];
    pdOverride: IManagementCommissionPD[];
    pdReferral: IManagementCommissionPD[];
  };

  @Expose()
  pd!: number;
  @Expose()
  pdManagement!: number;

  @Expose()
  pdOverride!: number;

  @Expose()
  pdReferral!: number;

  @Expose()
  rejectReason!: string;

  @Expose()
  reviewedAt!: string;

  @Expose()
  reviewedBy!: string;

  @Expose()
  submittedAt!: string;

  @Expose()
  submittedBy!: string;

  @Expose()
  approvedAt!: string;

  @Expose()
  approvedBy!: string;

  @Expose()
  get statusDisplay() {
    return MANAGEMENT_COMMISSION_STATUSES[this.status];
  }

  @Expose()
  @Default([])
  actionHistory!: TransactionStatusHistoryModel[];

  @Expose()
  lastReason!: string;

  @Expose()
  activePdOverride!: boolean;

  @Expose()
  activePdReferal!: boolean;

  @Expose()
  activePdManagement!: boolean;
}
