export const PROJECT_TRANSACTION_OWNERSHIP = [
  {
    id:1,
    name:'1st'
  },
  {
    id:2,
    name:'2nd'
  },
  {
    id:3,
    name:'3rd'
  },
  {
    id:4,
    name:'4th'
  },
  {
    id:5,
    name:'5th'
  },
  {
    id:6,
    name:'6th'
  },
  {
    id:7,
    name:'7th'
  },
  {
    id:8,
    name:'8th'
  },
  {
    id:9,
    name:'9th'
  },
  {
    id:10,
    name:'10th'
  },
] as const;
