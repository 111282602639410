import {BaseModel} from "@red/data-access";
import {Expose, Type} from "class-transformer";

export class UnitCreateDto extends BaseModel {
  @Expose()
  projectId!:number;

  @Expose()
  unitNo!: string;

  @Expose()
  blkNo!: string;

  @Expose()
  unitType!: string;

  @Expose()
  noOfRoom!: string;

  @Expose()
  @Type(()=>Number)
  areaSqft!: number;

  @Expose()
  @Type(()=>Number)
  areaSqm!: number;

  @Expose()
  @Type(()=>Number)
  sellingPrice!: number;

  @Expose()
  psf!: string;

  @Expose()
  @Type(()=>Number)
  bookingFee!: number;

  @Expose()
  postalCode!: string;
}

export class UnitUpdateDto extends BaseModel {

  @Expose()
  unitNo!: string;

  @Expose()
  blkNo!: string;

  @Expose()
  unitType!: string;

  @Expose()
  noOfRoom!: string;

  @Expose()
  @Type(()=>Number)
  areaSqft!: number;

  @Expose()
  @Type(()=>Number)
  areaSqm!: number;

  @Expose()
  @Type(()=>Number)
  sellingPrice!: number;

  @Expose()
  psf!: string;

  @Expose()
  @Type(()=>Number)
  bookingFee!: number;

  @Expose()
  postalCode!: string;
}

