import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSearchComponent } from './select-search.component';
import { SelectSearchClearDirective } from './select-search-clear.directive';
import { UppercaseModule } from '../../directives';

@NgModule({
  declarations: [SelectSearchComponent, SelectSearchClearDirective],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatCheckboxModule, MatProgressSpinnerModule, MatTooltipModule, ReactiveFormsModule, UppercaseModule],
  exports: [SelectSearchComponent, SelectSearchClearDirective],
})
export class SelectSearchModule {}
