import { ModuleWithProviders } from '@angular/core';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Translation, translocoConfig, TranslocoModule, TranslocoService, TRANSLOCO_CONFIG, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './transloco.http-loader';
import { firstValueFrom } from 'rxjs';

@NgModule({
  imports: [CommonModule, TranslocoModule],
})
export class TranslocoModuleCore {
  static forRoot(configs?: { prodMode: boolean }): ModuleWithProviders<TranslocoModuleCore> {
    return {
      ngModule: TranslocoModuleCore,
      providers: [
        {
          // Provide the default Transloco configuration
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            availableLangs: [
              {
                id: 'en',
                label: 'English',
              },
            ],
            defaultLang: 'en',
            fallbackLang: 'en',
            reRenderOnLangChange: true,
            prodMode: configs?.prodMode,
          }),
        },
        {
          // Provide the default Transloco loader
          provide: TRANSLOCO_LOADER,
          useClass: TranslocoHttpLoader,
        },
        {
          // Preload the default language before the app starts to prevent empty/jumping content
          provide: APP_INITIALIZER,
          deps: [TranslocoService],
          useFactory:
            (translocoService: TranslocoService): unknown =>
            (): Promise<Translation> => {
              const defaultLang = translocoService.getDefaultLang();
              translocoService.setActiveLang(defaultLang);
              return firstValueFrom(translocoService.load(defaultLang));
            },
          multi: true,
        },
      ],
    };
  }
}
