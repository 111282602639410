import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ExceljsWorkBook } from '@shared/components/exceljs-viewer';
import { PaymentGeneratorCreateDto, PaymentGeneratorMethodData, PaymentGeneratorUpdateDto } from '@shared/data-access/dto';
import { IPaymentGenerator } from '@shared/data-access/interfaces';
import {
  PayeePaymentGeneratorModel,
  PaymentGeneratorFilterRecordModel,
  PaymentGeneratorModel,
  PaymentGeneratorSummaryModel,
  SupplierCreditNotePaymentGeneratorModel,
  SupplierInvoicePaymentGeneratorErrorModel,
  SupplierInvoicePaymentGeneratorModel,
  TaxInvoicePaymentGeneratorModel,
  TransactionPaymentGeneratorModel,
} from '@shared/data-access/models';
import { PaymentGeneratorReportType } from '@shared/data-access/types';
import { Observable, delay, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentGeneratorApiService {
  static ROOT_POINT = 'finance/payments/generators';
  static PREVIEW_FILTER = 'finance/payments/generators/previews';
  static INCLUDE_TAX_INVOICE_IN_RANGE = 'finance/payments/generators/include/tax-invoices';
  static INCLUDE_TRANSACTION_IN_RANGE = 'finance/payments/generators/include/transactions';
  static INCLUDE_SUPPLIER_INVOICE_IN_RANGE = 'finance/payments/generators/include/supplier-invoices';
  static INCLUDE_SUPPLIER_CREDIT_NOTE_IN_RANGE = 'finance/payments/generators/include/supplier-credit-notes';
  static INCLUDE_PAYER_IN_RANGE = 'finance/payments/generators/include/payers';
  static EXCLUDE_TAX_INVOICE_IN_RANGE = 'finance/payments/generators/exclude/tax-invoices';
  static EXCLUDE_TRANSACTION_IN_RANGE = 'finance/payments/generators/exclude/transactions';
  static SUMMARY = 'finance/payments/generators/:id/report-summary';
  static REPORT_POINT = 'finance/payments/generators/:id/report/:type';
  static DOWNLOAD_REPORT_POINT = 'finance/payments/generators/:id/report/:type/download';
  static BANK_SUBMISSION_FILE_POINT = 'finance/payments/generators/:id/bank-file/download';
  static POSTED_POINT = 'finance/payments/generators/:id/post';
  static CONFIRM_EMAIL_SENT_POINT = 'finance/payments/generators/:id/sent';
  static TIMEOUT_POINT = 'finance/return-500';

  constructor(private apiService: ApiService) { }

  create(data: PaymentGeneratorCreateDto): Observable<PaymentGeneratorModel> {
    return this.apiService
      .post(`${PaymentGeneratorApiService.ROOT_POINT}`, data)
      .pipe(map((res: IPaymentGenerator) => PaymentGeneratorModel.fromJson(res) as PaymentGeneratorModel));
  }

  getErrorItem(itemId: number, query = {}): Observable<SupplierInvoicePaymentGeneratorErrorModel> {
    return this.apiService
      .get(PaymentGeneratorApiService.ROOT_POINT + '/' + itemId + '/items', query)
      .pipe(map(res => SupplierInvoicePaymentGeneratorErrorModel.fromJson(res) as SupplierInvoicePaymentGeneratorErrorModel));
  }

  get(itemId: number, query = {}): Observable<PaymentGeneratorModel> {
    return this.apiService
      .get(PaymentGeneratorApiService.ROOT_POINT + '/' + itemId, query)
      .pipe(map(res => PaymentGeneratorModel.fromJson(res) as PaymentGeneratorModel));
  }

  update(id: number, data: PaymentGeneratorUpdateDto): Observable<any> {
    return this.apiService.patch(PaymentGeneratorApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  updatePaymentMethod(id: number, data: PaymentGeneratorMethodData): Observable<any> {
    return this.apiService.patch(PaymentGeneratorApiService.ROOT_POINT + '/' + id + '/payments', data);
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PaymentGeneratorModel>> {
    return this.apiService.get(PaymentGeneratorApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(PaymentGeneratorModel, data)));
  }

  previewWithFilter(query = {}, option?: IApiOption): Observable<PaginationAdapter<PaymentGeneratorFilterRecordModel>> {
    return this.apiService
      .post(PaymentGeneratorApiService.PREVIEW_FILTER, query, option)
      // .get(PaymentGeneratorApiService.PREVIEW_FILTER, query)
      .pipe(map(data => new PaginationAdapter(PaymentGeneratorFilterRecordModel, data)));
  }

  testTimeout(query = {}, option?: IApiOption): Observable<PaginationAdapter<PaymentGeneratorFilterRecordModel>> {
    return this.apiService
      .get(PaymentGeneratorApiService.TIMEOUT_POINT + '/1', query, option)
      // .get(PaymentGeneratorApiService.PREVIEW_FILTER, query)
      .pipe(map(data => new PaginationAdapter(PaymentGeneratorFilterRecordModel, data)));
  }

  delete(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.delete(PaymentGeneratorApiService.ROOT_POINT + '/' + id, data, option);
  }

  compute(id: number, data = {}, option?: IApiOption): Observable<any> {
    return this.apiService.post(PaymentGeneratorApiService.ROOT_POINT + '/' + id + '/compute', data, option);
  }

  getSupllierInvoiceIncludingInRange(query = {}, option?: IApiOption): Observable<PaginationAdapter<SupplierInvoicePaymentGeneratorModel>> {
    return this.apiService
      .post(PaymentGeneratorApiService.INCLUDE_SUPPLIER_INVOICE_IN_RANGE, query, option)
      // .get(PaymentGeneratorApiService.INCLUDE_SUPPLIER_INVOICE_IN_RANGE, query, option)
      .pipe(map(data => new PaginationAdapter(SupplierInvoicePaymentGeneratorModel, data)));
  }

  getTaxInvoiceIncludingInRange(query = {}, option?: IApiOption): Observable<PaginationAdapter<TaxInvoicePaymentGeneratorModel>> {
    return this.apiService
      .post(PaymentGeneratorApiService.INCLUDE_TAX_INVOICE_IN_RANGE, query, option)
      // .get(PaymentGeneratorApiService.INCLUDE_TAX_INVOICE_IN_RANGE, query, option)
      .pipe(map(data => new PaginationAdapter(TaxInvoicePaymentGeneratorModel, data)));
  }

  getPayerIncludingInRange(query = {}, option?: IApiOption): Observable<PaginationAdapter<PayeePaymentGeneratorModel>> {
    return this.apiService
      .post(PaymentGeneratorApiService.INCLUDE_PAYER_IN_RANGE, query, option)
      // .get(PaymentGeneratorApiService.INCLUDE_PAYER_IN_RANGE, query, option)
      .pipe(map(data => new PaginationAdapter(PayeePaymentGeneratorModel, data)));
  }

  getSupllierCreditNoteIncludingInRange(query = {}, option?: IApiOption): Observable<PaginationAdapter<SupplierCreditNotePaymentGeneratorModel>> {
    return this.apiService
      .post(PaymentGeneratorApiService.INCLUDE_SUPPLIER_CREDIT_NOTE_IN_RANGE, query, option)
      // .get(PaymentGeneratorApiService.INCLUDE_SUPPLIER_CREDIT_NOTE_IN_RANGE, query, option)
      .pipe(map(data => new PaginationAdapter(SupplierCreditNotePaymentGeneratorModel, data)));
  }

  getTransactionIncludingInRange(query = {}, option?: IApiOption): Observable<PaginationAdapter<TransactionPaymentGeneratorModel>> {
    return this.apiService
      .post(PaymentGeneratorApiService.INCLUDE_TRANSACTION_IN_RANGE, query, option)
      // .get(PaymentGeneratorApiService.INCLUDE_TRANSACTION_IN_RANGE, query, option)
      .pipe(map(data => new PaginationAdapter(TransactionPaymentGeneratorModel, data)));
  }

  getTaxInvoiceExcludingInRange(query = {}, option?: IApiOption): Observable<PaginationAdapter<TaxInvoicePaymentGeneratorModel>> {
    return this.apiService
      .post(PaymentGeneratorApiService.EXCLUDE_TAX_INVOICE_IN_RANGE, query, option)
      // .get(PaymentGeneratorApiService.EXCLUDE_TAX_INVOICE_IN_RANGE, query, option)
      .pipe(map(data => new PaginationAdapter(TaxInvoicePaymentGeneratorModel, data)));
  }

  getTransactionExcludingInRange(query = {}, option?: IApiOption): Observable<PaginationAdapter<TransactionPaymentGeneratorModel>> {
    return this.apiService
      .post(PaymentGeneratorApiService.EXCLUDE_TRANSACTION_IN_RANGE, query, option)
      // .get(PaymentGeneratorApiService.EXCLUDE_TRANSACTION_IN_RANGE, query, option)
      .pipe(map(data => new PaginationAdapter(TransactionPaymentGeneratorModel, data)));
  }

  getSummary(id: number, query = {}): Observable<PaymentGeneratorSummaryModel> {
    return this.apiService.get(PaymentGeneratorApiService.SUMMARY, { id, ...query }).pipe(map(data => PaymentGeneratorSummaryModel.fromJson(data)));
  }

  getReport(id: number, type: PaymentGeneratorReportType, query = {}): Observable<ExceljsWorkBook> {
    return this.apiService.get(PaymentGeneratorApiService.REPORT_POINT, { id, type, ...query }).pipe(map(res => ExceljsWorkBook.fromJson(res) as ExceljsWorkBook));
  }

  downloadReport(id: number, type: PaymentGeneratorReportType, query = {}): Observable<any> {
    return this.apiService.post(
      PaymentGeneratorApiService.DOWNLOAD_REPORT_POINT,
      { id, type, ...query },
      {
        pretreatmentResponse: false,
        requestOptions: {
          responseType: 'arraybuffer',
        },
      }
    );
  }

  downloadBankSubmissionFile(id: number, query = {}): Observable<any> {
    return this.apiService
      .post(
        PaymentGeneratorApiService.BANK_SUBMISSION_FILE_POINT,
        { id, ...query },
        {
          pretreatmentResponse: false,
          requestOptions: {
            responseType: 'arraybuffer',
            observe: 'response',
          },
        }
      )
      .pipe(
        map(res => {
          const contentDisposition = res.headers.get('content-disposition');
          const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').trim();
          const body = res.body;
          return { fileName, body };
        })
      );
  }
  confirmEmailSent(id: number, query = {}): Observable<any> {
    return this.apiService.post(PaymentGeneratorApiService.CONFIRM_EMAIL_SENT_POINT, { id, ...query });
  }
  // posting(id: number): Observable<any> {
  //   return this.apiService.post(PaymentGeneratorApiService.POSTED_POINT, { id });
  // }

  postPayment(id: number, file?: File, query: Record<string, any> = {}): Observable<any> {
    const formData = new FormData();

    file && formData.append('bankFile', file);
    query['businessUnitId'] && formData.append('businessUnitId', query['businessUnitId']);

    return this.apiService.post(PaymentGeneratorApiService.ROOT_POINT + '/' + id + '/post', formData).pipe(
      map(res => {
        return res;
      })
    );
  }
}
