export const ADDRESS_TYPES = [
  {
    id:'billingAddress',
    name:'Billing Address'
  },
  {
    id:'shippingAddress',
    name:'Shipping Address'
  }
] as const;
