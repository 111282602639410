import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExceljsTableSearchDialogComponent } from '../../exceljs-table-search-dialog/exceljs-table-search-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ExceljsParameterFieldBase } from '../exceljs-parameter-field-base.directive';
import { IExceljsParameterControlType, ExceljsParameter, ExceljsCard, ExceljsParameterTemplateRefOnChangeObject, ExceljsParameterTemplateRef } from '../../models';
import { ExceljsParameterService } from '../exceljs-parameter.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { EXCELJS_CARD, EXCELJS_HTTP_LOADER } from '../../exceljs-http-loader.service';
import { get } from 'lodash-es';
import { takeUntil } from 'rxjs';
import { ExceljsParameterSelectionFromDialogTriggerComponent } from './exceljs-parameter-selection-from-dialog-trigger/exceljs-parameter-selection-from-dialog-trigger.component';
export const DYNAMICFIELD_REGEX = /{{([\w\s-_]*)}}/g;
@Component({
  selector: 'red-exceljs-parameter-selection-from-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ExceljsTableSearchDialogComponent,
    ExceljsParameterSelectionFromDialogTriggerComponent,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
  templateUrl: './exceljs-parameter-selection-from-dialog.component.html',
  styleUrls: ['./exceljs-parameter-selection-from-dialog.component.scss'],
})
export class ExceljsParameterSelectionFromDialogComponent extends ExceljsParameterFieldBase {
  private _dialog = inject(MatDialog);
  private apiService = inject(EXCELJS_HTTP_LOADER);
  tempateOption!: ExceljsParameterTemplateRefOnChangeObject;
  isMulti = false;
  formData = new FormGroup({
    value: new FormControl<any[]>([]),
    label: new FormControl<any[]>([]),
    data: new FormControl<any[]>([]),
  });
  constructor() {
    super();
    this.dataChange$.subscribe(() => {
      // If data not change, then we don't need to update this.options     
      this.isMulti = this.data.valueType?.toLocaleLowerCase().includes('array') ?? false;
    });


  }
  toDataDowncastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return val;
  }
  toDataUpcastValue(scheme: Partial<ExceljsParameter>, val: unknown): unknown {
    return val;
  }

  clear(): void {
    this.dataUpcast.setValue('');
  }
  get controlType(): IExceljsParameterControlType {
    return 'selection/dialog';
  }
  override init(): void {
    const dataDowncast = this.toDataUpcastValue(this.data, this.data.value);
    this.dataUpcast.setValue(dataDowncast);
    const slug = this.data.slug as string;
    const option = (this.tempateOption = getTempateOption(slug, this.card));
    console.log('card --> ', option);
    const template = this.card.parameterVisualization.templates[slug];
    if (!template) {
      throw Error(`can not find template for parameter ${this.data.slug}`);
    }
    const apiConfig: { method: 'get' | 'post'; endpoint: string } =
      typeof template.api === 'string'
        ? { method: 'get', endpoint: template.api }
        : ({ ...template.api, method: template.api.method.toLocaleLowerCase() } as { method: 'get' | 'post'; endpoint: string });
    this.triggerApi(apiConfig);
    this.dataChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // const dataDowncast = this.toDataUpcastValue(this.data, this.data.value);

      this.triggerApi(apiConfig);
    });

    // this.range.setValue(dataDowncast);
    // this.dataChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
    //   const dataDowncast = this.toDataUpcastValue(this.data, this.data.value);

    //   this.range.setValue(dataDowncast);
    // });
  }
  triggerApi(apiConfig: { method: 'get' | 'post'; endpoint: string }) {
    if (!this.tempateOption) {
      return;
    }
    const value = this.data.value ? (Array.isArray(this.data.value) ? this.data.value : [this.data.value]) : []
    this.apiService
      .get(apiConfig.method, apiConfig.endpoint, {
        [String(this.data.slug)]: value,
      })
      .subscribe({
        next: data => {
          const list = Array.isArray(data) ? data : [data]
          this.formData.setValue({
            value: list.map(item => getDynamicFieldKey(this.tempateOption.value, item)),
            label: list.map(item => getDynamicFieldKey(this.tempateOption.label, item)),
            data: list,
          });
          // console.log('data --> ', data, getTempateOption(slug, this.card), label);
        },
        error: () => {
          this.formData.reset();
        },
      });
  }

  onSelectDialog(): void {
    if (!this.tempateOption) {
      return;
    }
    const template = this.getParentTemplate();
    const data = this.formData.get('data')?.getRawValue();
    const selected = data ? (Array.isArray(data) ? data : [data]) : []
    const dialogRef = this._dialog.open(ExceljsTableSearchDialogComponent, {
      panelClass: ['dialog-no-padding', 'w-full', 'h-full'],
      maxHeight: '80%',
      maxWidth: '1200px',
      data: {
        selectionMode: this.isMulti ? 'multi' : 'single',
        // selectionMode: 'multi',
        selected,
        compareWithFn: this.compareWithFn.bind(this),
        template,
      },
    });
    dialogRef.afterClosed().subscribe((res?: { selected: any, currentFilters: Record<string, any> }) => {
      if (!res) {
        return;
      }
      const selectedList = res.selected ? (Array.isArray(res.selected) ? res.selected : [res.selected]) : [];

      console.log('res', selectedList)
      this.formData.setValue({
        value: selectedList.map(item => getDynamicFieldKey(this.tempateOption.value, item)),
        label: selectedList.map(item => getDynamicFieldKey(this.tempateOption.label, item)),
        data: selectedList,
      });
      // if (res.currentFilters && template && typeof template.api !== 'string' && template.api.query) {
      //   template.api.query.forEach(item => {
      //     item.default = res.currentFilters[item.slug]
      //   })
      //   this.card.parameterVisualization.templates[template.slug] = template;
      //   console.log('aaaaaa -----> ', this.card.parameterVisualization.templates[template.slug])
      // }

      // this.form.patchValue({ account: res });
      this.onValueChange(this.formData.get('value')?.getRawValue());
    });
  }
  getParentTemplate() {
    if (!this.data?.slug || !this.card) {
      return null;
    }
    return getParentTemplate(this.data.slug, this.card);
  }
  compareWithFn(o1: any, o2: any): boolean {
    // console.log('this.tempateOption', this.tempateOption)
    if (!this.tempateOption) {
      return o1 === o2;
    }
    const o1Value = o1 ? getDynamicFieldKey(this.tempateOption.value, o1) : null;
    const o2Value = o2 ? getDynamicFieldKey(this.tempateOption.value, o2) : null;
    return o1Value === o2Value;
  }
}
function getTempateOption(slug: string, card: ExceljsCard): ExceljsParameterTemplateRefOnChangeObject {
  const direction = new Set<string>();
  function getRecursive(slug: string, card: ExceljsCard): ExceljsParameterTemplateRefOnChangeObject {
    if (direction.has(slug)) {
      throw Error(`Circle dependency: ${Array.from(direction.values()).join(' --> ')}`);
    }
    const template = card.parameterVisualization.templates[slug];
    if (typeof template.on.change === 'string') {
      if (template.on.change.charAt(0) !== '#') {
        throw Error(`chart at index 0 must be #`);
      }
      const path = template.on.change;
      const templatePeerDependencyName = path.split('.')[0].replace('#', '');
      direction.add(templatePeerDependencyName);
      return getTempateOption(templatePeerDependencyName, card);
    }
    if (template.on.change.label && template.on.change.value) {
      return template.on.change;
    }
    throw Error(`template.on.change must be an object contains label and value`);
  }
  return getRecursive(slug, card);
}
function getParentTemplate(slug: string, card: ExceljsCard): ExceljsParameterTemplateRef | null {
  const template = card.parameterVisualization.templates[slug];
  if (typeof template.on.change === 'string') {
    if (template.on.change.charAt(0) !== '#') {
      throw Error(`chart at index 0 must be #`);
    }
    const path = template.on.change;
    const templatePeerDependencyName = path.split('.')[0].replace('#', '');
    return card.parameterVisualization.templates[templatePeerDependencyName];
  }
  return null;
}
function getTemplatePeerDependencyNameSlug(slug: string, card: ExceljsCard) {
  const template = card.parameterVisualization.templates[slug];
  if (typeof template.on.change === 'string') {
    if (template.on.change.charAt(0) !== '#') {
      throw Error(`chart at index 0 must be #`);
    }
    const path = template.on.change;
    return path.split('.')[0].replace('#', '');
  }
  return null;
}
export function getDynamicFieldKey(input: string, data: Record<string, any>) {
  if (!data) {
    return data
  }
  if (DYNAMICFIELD_REGEX.exec(input)) {
    return input.replace(DYNAMICFIELD_REGEX, (value: string, ...arg: string[]) => {
      return get(data, arg[0]);
    });
  }
  return input;
}
