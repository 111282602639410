import { IInvoicePayable } from './invoice-payable.interface';
import { IReceipt } from './receipt.interface';

export interface IMemoPayablePostingAccount {
  accountCode?: string;
  accountId?: number;
  accountName?: string;
}

export interface IMemoPayable {
  id: number;
  recordId?: number; // edit
  itemCode: string;
  description: string;
  type: string; // VI, CN
  total: number;
  paidAmount: number;
  balanceDue: number; // original amount,
  amount: number | string;
  invoiceId: number;
  invoice: IInvoicePayable | null;
  updatedAt: string;
  receiptId?: number;
  receipt?: IReceipt;
  posting: IMemoPayablePostingAccount
}
