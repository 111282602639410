import { BaseModel, Default } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IProjectTransactionRelatedParty } from '../interfaces';
import { ProjectTransactionRelatedAgentModel } from './project-transaction-related-agent.model';
import { ProjectTransactionRelatedCobrokeModel } from './project-transaction-related-cobroke.model';
import { ProjectTransactionRelatedInternalCobrokeModel } from './project-transaction-related-internal-cobroke.model';
// import { SplitPartEnhancedModel } from "./split-part.model";

export class ProjectTransactionRelatedPartyModel extends BaseModel implements IProjectTransactionRelatedParty {
  @Expose()
  @Type(() => ProjectTransactionRelatedAgentModel)
  mains!: ProjectTransactionRelatedAgentModel[];

  @Expose()
  @Type(() => ProjectTransactionRelatedAgentModel)
  main!: ProjectTransactionRelatedAgentModel;

  @Expose()
  @Type(() => ProjectTransactionRelatedAgentModel)
  leaders!: ProjectTransactionRelatedAgentModel[];

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionRelatedInternalCobrokeModel)
  internals!: ProjectTransactionRelatedInternalCobrokeModel[];

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionRelatedCobrokeModel)
  externals!: ProjectTransactionRelatedCobrokeModel[];
}
