import { BaseModel } from '@red/data-access';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
export const API_SERVICE_FOR_EDIT_RESOLVER= new InjectionToken('API_SERVICE_FOR_EDIT_RESOLVER');
export interface IServiceForEditResolver<T> {
  init(id:number):Observable<T>
}
@Injectable()
export abstract class ServiceForEditResolver<T> implements IServiceForEditResolver<T>{
  abstract init(id:number):Observable<T>
}
export function editResolverFactory<T extends BaseModel>(_apiService: ServiceForEditResolver<T>, _router:Router){
  return new EditResolver<T>(_apiService,_router);
}

@Injectable()
export class EditResolver<T extends BaseModel> implements Resolve<T>
{
    /**
     * Constructor
     */
    constructor(
        private  _service: ServiceForEditResolver<T>,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T>
    {
      let nextRoute: ActivatedRouteSnapshot | null = state.root;
        while (nextRoute && !nextRoute.paramMap.has('id') )
        {
            nextRoute = nextRoute.firstChild;
        }
        if(!nextRoute){
          return throwError(()=> new Error(`can not find id`))
        }
        const id:number = parseInt(nextRoute.paramMap.get('id') as string)
        if(isNaN(id)){
          return throwError(()=> new Error(`can not find id`))
        }
        console.log('type id -->',id,typeof id)
        return this._service.init(id)
                   .pipe(
                       // Error here means the requested contact is not available
                       catchError((error) => {

                           // Log the error
                           console.error(error);

                           // Get the parent url
                           const parentUrl = state.url.split('/').slice(0, -2).join('/');

                           // Navigate to there
                           this._router.navigateByUrl(parentUrl);

                           // Throw an error
                           return throwError(()=>error);
                       })
                   );
    }
}
