import { SelectionModel } from '@angular/cdk/collections';
import { getColumnSetting, normalizeTemplateRefName, normalizeValue } from './metabase.util';
import { MetabaseCard, MetabaseCardQueryModel, MetabaseColSetting, MetabaseCardDataCol } from './models/metabase.model';
export interface MetabaseColumnDef {
  columnDef: string;
  header: string;
  cell: (item: any, index?: number) => any;
  template: (item: any, index?: number) => any;
  colData: MetabaseCardDataCol;
  colSetting?: MetabaseColSetting;
  enabled: boolean;
}
export function getColumDef(name: string, selected: SelectionModel<string>, count = 0): string {
  if (selected.isSelected(name)) {
    count++;
    return getColumDef(name + count, selected, count);
  }
  selected.select(name);
  return name;
}
export function generateColumns(card: MetabaseCard): MetabaseColumnDef[] {
  const colNameSelected = new SelectionModel<string>(true, []);
  return card.visualizationSettings.tableColumns.map(col => {
    const selected = card.resultMetadata.find(item => item.fieldRefName === col.name);
    if (!selected) {
      throw new Error(`Can't find col ${col.name}`);
    }
    const columnDef = getColumDef(selected.fieldRefName, colNameSelected);
    const colSetting = getColumnSetting(card.visualizationSettings, selected.fieldRefName);
    return {
      columnDef,
      header: selected.displayName,
      cell: (item: any) => {
        return normalizeValue(item[selected.fieldRefName], selected, colSetting);
      },
      template: (item: any) => {
        const template = normalizeTemplateRefName(item, selected, colSetting);
        return template;
      },
      colData: selected,
      colSetting,
      enabled: col.enabled,
    };
  });
}

export function generateDataSource(query: MetabaseCardQueryModel): Array<Record<string, string | number | null>> {
  return (
    query?.data.rows.map(row => {
      return row.reduce((acc: Record<string | number | symbol, any>, val, index) => {
        acc[query.data.resultsMetadata.columns[index].fieldRefName] = val;
        return acc;
      }, {});
    }) || []
  );
}
