import { BaseModel, Default, PhoneFax, PhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { Observable } from 'rxjs';
import { Status } from '../enums';
import { ISupplierBankAccount } from '../interfaces';
import { IContactCustomerAndSupplier, IContactEmail } from '../interfaces/contact-customer-and-supplier.interface';
import { BaseFixedModel } from './base.model';
import { ContactAddressModel } from './contact-address.model';
import { ContactCategoryModel } from './contact-category.model';
import { LedgerAccountModel } from './ledger-account.model';

export class ContactEmailModel extends BaseModel implements IContactEmail {
  @Expose()
  id?: number;

  @Expose()
  email!: string;
}

export class ContactCustomerAndSupplierModel extends BaseFixedModel implements IContactCustomerAndSupplier {
  @Expose()
  type!: string;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  ceaRegNo?: string;

  @Expose()
  @Type(() => Number)
  categoryId?: number;

  @Expose()
  @Type(() => ContactCategoryModel)
  category?: ContactCategoryModel;

  @Expose()
  email?: string;

  @Expose()
  @Type(() => ContactEmailModel)
  @Default([])
  emails?: ContactEmailModel[];

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  @Type(() => PhoneFax)
  fax!: PhoneFax;

  @Expose()
  status!: Status;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  @Type(() => Number)
  creditLimit?: number;

  @Expose()
  @Type(() => Number)
  receiveAccountId?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  receiveAccount?: LedgerAccountModel;

  @Expose()
  @Type(() => Number)
  paidAccountId?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  paidAccount?: LedgerAccountModel;

  @Expose()
  receiveAccountAsync!: Observable<LedgerAccountModel>; /// ?????????

  @Expose()
  paidAccountAsync!: Observable<LedgerAccountModel>; /// ?????????

  @Expose()
  businessRegNo?: string;

  @Expose()
  gstRegNo?: string;

  @Expose()
  remark?: string;

  @Expose()
  groupName?: string;

  @Expose()
  commerceDate?: string;

  @Expose()
  @Default(false)
  gstRegistered!: boolean;

  @Expose()
  bankAccount?: ISupplierBankAccount;

  @Expose()
  @Type(() => Number)
  salePersonId?: number;

  @Expose()
  isWithHold?: boolean;

  @Expose()
  @Type(() => ContactAddressModel)
  addresses?: ContactAddressModel[];

  @Expose()
  @Transform(({ obj }: { obj: IContactCustomerAndSupplier }) => {
    // return `${obj.name}${obj.ceaRegNo ? ` (${obj.ceaRegNo})` : ''}`
    return obj.name;
  })
  normalizedName!: string;
}
