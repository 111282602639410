import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { PaymentReversalCreateDto, PaymentReversalPostingReqDto } from '@shared/data-access/dto';
import { IPaymentReversal } from '@shared/data-access/interfaces';
import { PaymentReversalDataSource, PaymentReversalModel, PaymentReversalPostingDataSourceModel } from '@shared/data-access/models';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentReversalApiService {
  static ROOT_POINT = 'finance/reversals';
  static CANCEL_POINT = 'finance/reversals/:id/cancel';
  static PREVIEW_POSTING = 'finance/reversals/postings/preview';
  static RECORDS_AND_POSTING_DETAIL = 'finance/reversals/:id/data';

  constructor(private apiService: ApiService) {}

  create(data: PaymentReversalCreateDto): Observable<PaymentReversalModel> {
    return this.apiService
      .post(PaymentReversalApiService.ROOT_POINT, data)
      .pipe(map((res: IPaymentReversal) => PaymentReversalModel.fromJson(res) as PaymentReversalModel));
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PaymentReversalModel>> {
    return this.apiService.get(PaymentReversalApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(PaymentReversalModel, data)));
  }

  get(itemId: number, query = {}): Observable<PaymentReversalModel> {
    return this.apiService
      .get(PaymentReversalApiService.ROOT_POINT + '/' + itemId, query)
      .pipe(map(res => PaymentReversalModel.fromJson(res) as PaymentReversalModel));
  }

  getRecordsAndPostings(id: number, query = {}): Observable<PaymentReversalDataSource> {
    return this.apiService
      .get(PaymentReversalApiService.RECORDS_AND_POSTING_DETAIL, { id, ...query })
      .pipe(map(res => PaymentReversalDataSource.fromJson(res) as PaymentReversalDataSource));
  }

  update(id: number, data: PaymentReversalCreateDto): Observable<any> {
    return this.apiService.patch(PaymentReversalApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  previewPosting(data: PaymentReversalPostingReqDto): Observable<PaymentReversalPostingDataSourceModel> {
    return this.apiService
      .post(PaymentReversalApiService.PREVIEW_POSTING, data)
      .pipe(map(res => PaymentReversalPostingDataSourceModel.fromJson(res) as PaymentReversalPostingDataSourceModel));
  }

  delete(id: number, data = {}): Observable<any> {
    return this.apiService.delete(PaymentReversalApiService.ROOT_POINT + '/' + id, data);
  }

  cancel(id: number, data = {}): Observable<any> {
    return this.apiService.patch(PaymentReversalApiService.CANCEL_POINT, { id, ...data });
  }
}
