import { EResaleType } from '../enums';
import { PayoutTransactionTypeOptionRecord } from '../types/commission-payout.type';

export const TRANSACTION_TYPE_OPTIONS: PayoutTransactionTypeOptionRecord = {
  sale: {
    id: 'sale',
    value: 'sale',
    viewValue: 'SALE',
  },
  [EResaleType.resale]: {
    id: EResaleType.resale,
    value: EResaleType.resale,
    viewValue: 'RESALE',
  },
  [EResaleType.enBloc]: {
    id: EResaleType.enBloc,
    value: EResaleType.enBloc,
    viewValue: 'EN BLOC',
  },
  [EResaleType.lease]: {
    id: EResaleType.lease,
    value: EResaleType.lease,
    viewValue: 'LEASE',
  },
  [EResaleType.leaseRental]: {
    id: EResaleType.leaseRental,
    value: EResaleType.leaseRental,
    viewValue: 'LEASE - ROOM RENTAL',
  },
  [EResaleType.developerSale]: {
    id: EResaleType.developerSale,
    value: EResaleType.developerSale,
    viewValue: 'DEVELOPER SALE',
  },
  [EResaleType.assignment]: {
    id: EResaleType.assignment,
    value: EResaleType.assignment,
    viewValue: 'ASSIGNMENT',
  },
};
