import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { UserAccountCreateDto, UserAccountUpdateDto } from '@shared/data-access/dto';
import { IUserAccount } from '@shared/data-access/interfaces';
import { UserAccountModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAccountApiService {
  static ROOT_POINT = 'settings/users';

  constructor(private apiService: ApiService) {}

  create(data: UserAccountCreateDto): Observable<UserAccountModel> {
    return this.apiService.post(`${UserAccountApiService.ROOT_POINT}`, data).pipe(map((res: IUserAccount) => UserAccountModel.fromJson(res) as UserAccountModel));
  }
  get(itemId: number): Observable<UserAccountModel> {
    return this.apiService.get(UserAccountApiService.ROOT_POINT + '/' + itemId).pipe(map(res => UserAccountModel.fromJson(res) as UserAccountModel));
  }
  update(id: number, data: UserAccountUpdateDto): Observable<any> {
    return this.apiService.patch(UserAccountApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<UserAccountModel>> {
    return this.apiService.get(UserAccountApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(UserAccountModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(UserAccountApiService.ROOT_POINT, { ids }, option);
  }

  addGroups(id: number, groupIds: number[]): Observable<unknown> {
    return this.apiService.patch(UserAccountApiService.ROOT_POINT + '/' + id + '/groups', { groupIds }, { excludeFields: [''] });
  }

  addRoles(id: number, roleIds: number[]): Observable<unknown> {
    return this.apiService.patch(UserAccountApiService.ROOT_POINT + '/' + id + '/roles', { roleIds }, { excludeFields: [''] });
  }
}
