import { BaseModel, Default, EndOfDate, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EBusinessDirector } from '../enums';

export class ProjectHICreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  projectId!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: 3;

  @Expose()
  type!: EBusinessDirector;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: 4;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  @Default(null)
  endDate!: string;

  @Expose()
  @Type(() => Boolean)
  isPersonInCharge?: boolean;

  @Expose()
  appTypes!: { appTypeId: number }[];
}

export class ProjectHeadOfHICreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  projectId!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: 3;

  @Expose()
  type!: EBusinessDirector;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: 4;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;

  @Expose()
  appTypes!: { appTypeId: number }[];
}
