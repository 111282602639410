import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IEmailTemplate } from '../interfaces';

export class EmailTemplateModel extends BaseModel implements IEmailTemplate {
  @Expose()
  id!: number;

  @Expose()
  css!: string;

  @Expose()
  html!: string;

  @Expose()
  params!: string[];

  @Expose()
  subject!: string;

  @Expose()
  text!: string;

  @Expose()
  type!: string;
}
