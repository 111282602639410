import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IInvoiceWriteoff, IInvoiceWriteoffData, IInvoiceWriteoffPosting, IInvoiceWriteoffRecord } from '../interfaces';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { LedgerAccountModel } from './ledger-account.model';
import { TaxInvoiceModel } from './tax-invoice-enhanced.model';

export class InvoiceWriteoffModel extends BaseModel implements IInvoiceWriteoff {
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  taxInvoiceId?: number;

  @Expose()
  debitAccountId?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  debitAccount?: LedgerAccountModel;

  @Expose()
  code?: string;

  @Expose()
  writeOffDate!: Date;

  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact?: ContactCustomerAndSupplierModel;

  @Expose()
  contactName?: string;

  @Expose()
  @Type(() => TaxInvoiceModel)
  invoice?: TaxInvoiceModel;

  @Expose()
  invoiceDate?: Date;

  @Expose()
  invoiceNumber?: string;

  @Expose()
  @Default('SGD')
  invoiceCurrency?: string;

  @Expose()
  total?: number;

  @Expose()
  inClosedPeriod?: boolean;

  @Expose()
  updatedBy!: {
    id: number;
    name: string;
  };

  @Expose()
  @Transform(({ obj }) => {
    return !obj.inClosedPeriod;
  })
  canEdit?: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return !obj.inClosedPeriod;
  })
  canDelete?: boolean;

  // Transform to Date object
  @Expose()
  // @Transform(({ value }) => {
  //   console.log('Before transform: ', value);
  //   const date = new Date(value);
  //   console.log('After transform: ', date);
  //   return date;
  // })
  updatedAt!: string;
}

export class InvoiceWriteoffRecordModel extends BaseModel implements IInvoiceWriteoffRecord {
  @Expose()
  id?: number;

  @Expose()
  sourceId?: number;

  @Expose()
  type!: string;

  @Expose()
  description!: string;

  @Expose()
  credit!: number;

  @Expose()
  debit!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId?: number;
}

export class InvoiceWriteoffPostingModel extends BaseModel implements IInvoiceWriteoffPosting {
  @Expose()
  id?: number;

  @Expose()
  @Transform(({ obj }) => obj.account.id)
  accountId?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  description!: string;

  @Expose()
  credit!: number;

  @Expose()
  debit!: number;

  @Expose()
  amount!: number;
}

export class InvoiceWriteoffDataModel extends BaseModel implements IInvoiceWriteoffData {
  @Expose()
  amount!: number;

  @Expose()
  @Type(() => InvoiceWriteoffRecordModel)
  details!: InvoiceWriteoffRecordModel[];

  @Expose()
  @Type(() => InvoiceWriteoffPostingModel)
  postings!: InvoiceWriteoffPostingModel[];
}

export class InvoiceWriteoffDataResolvedModel extends BaseModel {
  @Expose()
  @Type(() => InvoiceWriteoffDataModel)
  item!: InvoiceWriteoffDataModel;

  @Expose()
  @Type(() => TaxInvoiceModel)
  invoice!: TaxInvoiceModel;
}
