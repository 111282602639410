import { BaseModel, Default, IPhoneNumber } from "@red/data-access";
import { Expose, Type } from "class-transformer";
export class DeveloperContactPersonCreateDto extends BaseModel {

  @Expose()
  @Type(()=>Number)
  developerId!:number;

  @Expose()
  name!:string;

  @Expose()
  designation!:string;

  @Expose()
  phone!:IPhoneNumber;

  @Expose()
  mobile!:IPhoneNumber;

  @Expose()
  email!:string;

  @Expose()
  fax!:string;

  @Expose()
  @Default(false)
  @Type(()=>Boolean)
  isDefault!:boolean

}

export class DeveloperContactPersonUpdateDto extends BaseModel {

  @Expose()
  name!:string;

  @Expose()
  designation!:string;

  @Expose()
  phone!:IPhoneNumber;

  @Expose()
  mobile!:IPhoneNumber;

  @Expose()
  email!:string;

  @Expose()
  fax!:string;

  @Expose()
  @Default(false)
  @Type(()=>Boolean)
  isDefault!:boolean

}

