import { BaseModel } from '@red/data-access';
import {  Expose } from 'class-transformer';
import { IAddressType } from '../interfaces';


export class AddressTypeModel extends BaseModel implements IAddressType {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  status!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;
}
