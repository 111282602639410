import { ITimestamp } from './timestamp.interface';

export interface IGstRate extends ITimestamp {
  id: number;
  code: string;
  name: string;
  gstRate: number;
  gstCode: string;
  effectiveDate: string;
  isActive: boolean;
}
