import { isArray, isObject } from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_CONFIG } from './constants/token';
import { isMoment } from 'moment';
import { IApiConfig } from './interfaces/api-config.interface';
import { IApiOption } from './interfaces/api-option.interface';
import { map, Observable, timeout } from 'rxjs';
import { Helper } from '../utils/helper';

export interface ApiDTO {
  data: Record<string, unknown>;
}
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  headers: HttpHeaders = new HttpHeaders({
    // 'Content-Type': 'application/json',
    source: 'ApiService', // Entity Name
  });
  constructor(@Inject(API_CONFIG) private apiConfig: IApiConfig, private httpClient: HttpClient) { }
  private defaultOptions: IApiOption = {
    loader: true,
    pretreatmentResponse: true,
    excludeFields: [],
    requestOptions: {
      headers: this.headers,
    },
  };
  static pretreatmentDataRequest(data: Record<string, any>, options?: IApiOption) {
    if (data instanceof FormData) {
      return data;
    }
    data = ApiService.formatMomentObject(data);
    if (options?.removeEmpty?.enable) {
      data = Helper.removeEmpty(data, options?.removeEmpty?.excludeFields);
    }

    return data;
  }

  static formatMomentObject(data: any): any {
    if (isMoment(data)) {
      return data.format();
    }
    if (isArray(data)) {
      return data.map(item => ApiService.formatMomentObject(item));
    }
    if (isObject(data)) {
      const res: { [x in string]: any } = { ...data };
      const keys = Object.keys(res);
      keys.forEach(key => {
        res[key] = ApiService.formatMomentObject(res[key]);
      });
      return res;
    }
    return data;
  }

  static normalizeOption(options: IApiOption, optionsMatching?: IApiOption): IApiOption {
    if (!optionsMatching) {
      return options;
    }
    let results: IApiOption = { ...options };
    optionsMatching.requestOptions = { ...results.requestOptions, ...optionsMatching.requestOptions };
    results = { ...results, ...optionsMatching };
    if (optionsMatching.exposeHeaders) {
      Object.keys(optionsMatching.exposeHeaders).forEach(key => {
        const headers = results.requestOptions?.headers as HttpHeaders;
        if (optionsMatching.exposeHeaders && results.requestOptions) {
          results.requestOptions.headers = headers.set(key, optionsMatching.exposeHeaders[key]);
        }
      });
    }
    return results;
  }

  createAPIURL(url: string, params: Record<string, string>): string {
    if (params instanceof FormData) {
      return this.apiConfig.apiHost + this.apiConfig.urlPrefix + url;
    }
    const paths = url.split('/');
    paths.forEach((path, i) => {
      if (path.startsWith(':')) {
        const key = path.slice(1);

        paths[i] = params[key];
        delete params[key];
      }
    });

    return this.apiConfig.apiHost + this.apiConfig.urlPrefix + paths.join('/');
  }

  get(url: string, params?: HttpParams | any, options?: IApiOption): Observable<any> {
    params = ApiService.pretreatmentDataRequest(params, options);
    const defaultRequestOptions = {
      ...this.defaultOptions,
      ...{
        requestOptions: {
          params: params,
          headers: this.headers,
        },
      },
    };
    options = ApiService.normalizeOption(defaultRequestOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.get<ApiDTO>(fullUrl, options.requestOptions).pipe(
      timeout(options.timeout ?? this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response.data : response))
    );
  }

  post(url: string, params?: any, options?: IApiOption): Observable<any> {
    params = ApiService.pretreatmentDataRequest(params, options);
    options = ApiService.normalizeOption(this.defaultOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.post<ApiDTO>(fullUrl, params, options.requestOptions).pipe(
      timeout(options.timeout ?? this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response.data : response))
    );
  }

  put(url: string, params?: any, options?: IApiOption): Observable<any> {
    params = ApiService.pretreatmentDataRequest(params, options);
    options = ApiService.normalizeOption(this.defaultOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.put<ApiDTO>(fullUrl, params, options.requestOptions).pipe(
      timeout(options.timeout ?? this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response.data : response))
    );
  }

  patch(url: string, params?: any, options?: IApiOption): Observable<any> {
    params = ApiService.pretreatmentDataRequest(params, options);
    options = ApiService.normalizeOption(this.defaultOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.patch<ApiDTO>(fullUrl, params, options.requestOptions).pipe(
      timeout(options.timeout ?? this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response.data : response))
    );
  }

  delete(url: string, params?: any, options?: IApiOption): Observable<any> {
    params = ApiService.pretreatmentDataRequest(params, options);
    const defaultRequestOptions = {
      ...this.defaultOptions,
      ...{
        requestOptions: {
          body: params,
          headers: this.headers,
        },
      },
    };
    options = ApiService.normalizeOption(defaultRequestOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.request<ApiDTO>('delete', fullUrl, options.requestOptions).pipe(
      timeout(options.timeout ?? this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response['data'] : response))
    );
  }
  download(method: 'get' | 'post', url: string, params: Record<string, any> = {}, isStreaming = false, options?: IApiOption) {
    const headers = new HttpHeaders({
      'Accept': 'application/octet-stream',
      source: 'ApiService',
    });
    params = ApiService.pretreatmentDataRequest(params, options);
    const defaultRequestOptions = isStreaming ? {
      ...this.defaultOptions,
      ...{
        requestOptions: {
          body: params,
          headers,
          responseType: 'blob',
          reportProgress: true, // For progress monitoring
          observe: 'events'
        },
      },
    } : {
      ...this.defaultOptions,
      ...{
        requestOptions: {
          body: params,
          responseType: 'arraybuffer',
          headers: this.headers,
        },
      },
    };
    console.log('apiService --> defaultRequestOptions', defaultRequestOptions)
    options = ApiService.normalizeOption(defaultRequestOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.request<ApiDTO>(method, fullUrl, options.requestOptions).pipe(
      timeout(options.timeout ?? this.apiConfig.timeout),
    );
  }
}
