import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IGstCategory, IGstCategoryLookup } from '../interfaces';

export class GstCategoryModel extends BaseModel implements IGstCategory {
  @Expose()
  id!: number;

  @Expose()
  categoryRole!: string;

  @Expose()
  code!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  description!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  name!: string;

  @Expose()
  updatedAt!: string;
}

export class GstCategoryLookupModel extends BaseModel implements IGstCategoryLookup {
  @Expose()
  id?: number;

  @Expose()
  lookupSetupId?: number;

  @Expose()
  lookupId?: number;

  @Expose()
  abbreviation!: string;

  @Expose()
  @Type(() => Boolean)
  appear_ap!: boolean;

  @Expose()
  @Type(() => Boolean)
  appear_ar!: boolean;

  @Expose()
  @Type(() => Boolean)
  appear_transaction!: boolean;

  @Expose()
  gst_category!: string;

  @Expose()
  @Type(() => Number)
  gst_charged!: number;

  @Expose()
  @Transform(({ obj }) => obj.gst_charged > 0)
  allowInclusive!: boolean;

  @Expose()
  gst_code!: string;

  @Expose()
  @Type(() => Number)
  gst_rate!: number;

  @Expose()
  @Type(() => Boolean)
  active!: boolean;

  @Expose()
  createdAt?: string;

  @Expose()
  updatedAt?: string;
}
