import { ISplitMatrix } from './split-matrix.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IAppointmentType } from './appointment-type.interface';
import { ISplitPart } from './split-part.interface';
import { EAgentSession, EManagementCommissionPdRole } from '../enums';
import { IProjectTransaction } from './project-transaction.interface';

export interface IProjectTransactionPd extends ISplitMatrix {
  additional: number;
  appointmentType: IAppointmentType;
  appointmentTypeId: number;
  data: any;
  id: number;
  note: string;
  parties: ISplitPart[];
  pdRole: EManagementCommissionPdRole;
  receivedDirectPercent: number;
  receivedPercent: number;
  receivedValue: number;
  salesperson: IPersonalParticular;
  salespersonId: number;
  splitMatrixId: number;
  tiers: ISplitPart[];
  totalAmount: number;
  transaction: IProjectTransaction;
  transactionId: number;
  agentType: EAgentSession;
  grouping: string;
}
