import { PERMISSION_PREFIX } from './permission-provider';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef, Directive, EmbeddedViewRef, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { PermissionsService } from './permission.service';
import { coerceStringArray } from '@angular/cdk/coercion';
import { RedPermissionState } from './permission-state.type';
import { nomralizePermissionSlug } from './utls';
export class redPermissionContext<T = unknown> {
  public $implicit: T = null!;
}
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[redPermission]',
})
export class PermissionDirective<T> implements OnInit, OnDestroy {
  private _slugs: string[] = [];

  private _thenViewRef: EmbeddedViewRef<redPermissionContext<T>> | null = null;
  private _thenTemplateRef: TemplateRef<redPermissionContext<T>> | null = null;
  private _elseViewRef: EmbeddedViewRef<redPermissionContext<T>> | null = null;
  private _elseTemplateRef: TemplateRef<redPermissionContext<T>> | null = null;
  @Input('redPermission')
  set permissionSlugs(val: unknown) {
    const value = coerceStringArray(val);
    this._slugs = value.map(slug => nomralizePermissionSlug(slug, this.prefix));
    this.updateView();
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('redPermissionElse')
  set permissionElseTemplate(templateRef: TemplateRef<redPermissionContext<T>> | null) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this.updateView();
  }
  private readonly _unsubscribeAll = new Subject<void>();
  constructor(
    private _tpl: TemplateRef<any>,
    private _vcr: ViewContainerRef,
    private _cdr: ChangeDetectorRef,
    @Inject(PERMISSION_PREFIX) private prefix: string[],
    private _permissionService: PermissionsService
  ) {
    this._thenTemplateRef = this._tpl;
  }
  ngOnInit(): void {
    this._permissionService.state$.pipe(takeUntil(this._unsubscribeAll)).subscribe(state => {
      if (state === RedPermissionState.LOAD_SUCCESSFUL) {
        this.updateView();
      }
    });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private updateView() {
    if (this._permissionService.canAccess(this._slugs)) {
      if (!this._thenViewRef) {
        this._vcr.clear();
        if (this._thenTemplateRef) {
          this._thenViewRef = this._vcr.createEmbeddedView(this._thenTemplateRef);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._vcr.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._vcr.createEmbeddedView(this._elseTemplateRef);
        }
      }
    }
    this._cdr.markForCheck();
  }
}
