import { BaseModel, Default } from '@red/data-access';
import { IResaleRelatedAgent } from '../interfaces/resale-related-agent.interface';
import { Expose, Type } from 'class-transformer';
import { ResaleCoBrokeModel } from './resale-co-broke.model';
import { ResaleExternalCoBrokeModel } from './resale-external-co-broke.model';

export class ResaleRelatedAgentModel extends BaseModel implements IResaleRelatedAgent {
  @Expose()
  @Default([])
  @Type(() => ResaleExternalCoBrokeModel)
  externals!: ResaleExternalCoBrokeModel[];

  @Expose()
  @Default([])
  @Type(() => ResaleCoBrokeModel)
  internals!: ResaleCoBrokeModel[];

  @Expose()
  @Default([])
  @Type(() => ResaleCoBrokeModel)
  mains!: ResaleCoBrokeModel[];
}
