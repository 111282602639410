import {
  EBlankFormIcType,
  EBlankFormInternalAgentType,
  EBlankFormOtherFeeType,
  EBLankFormReferralFormula,
  EBlankFormReferralSourceType,
  EBlankFormReferralType,
  EBlankFormStatus,
} from '../enums';
import {
  BlankFormIcRecord,
  BlankFormInternalAgentTypeRecord,
  BlankFormOtherFeeTypeRecord,
  BlankFormReferralFormulaRecord,
  BlankFormReferralTypeRecord,
  BlankFormStatusRecord,
} from '../types';

export const BLANK_FORM_STATUSES: BlankFormStatusRecord = {
  [EBlankFormStatus.draft]: {
    id: EBlankFormStatus.draft,
    name: 'Draft',
    filterName: 'Draft',
    showOnFilter: true,
    class: 'status-box-default',
    canSubmit: true,
    canEdit: true,
    canAgentSubmit: true,
    canAgentEdit: true,
    canDelete: true,
  },
  [EBlankFormStatus.rework1]: {
    id: EBlankFormStatus.rework1,
    class: 'status-box-danger',
    name: 'Send Back',
    filterName: 'Send Back Verification',
    showOnFilter: true,
    canSubmit: true,
    canEdit: true,
    canAgentSubmit: true,
    canAgentEdit: true,
    canDelete: true,
  },
  [EBlankFormStatus.rework2]: {
    id: EBlankFormStatus.rework2,
    class: 'status-box-danger',
    name: 'Send Back',
    filterName: 'Send Back Approval',
    showOnFilter: true,
    canSubmit: true,
    canEdit: true,
    canDelete: true,
  },
  [EBlankFormStatus.submitted]: {
    id: EBlankFormStatus.submitted,
    name: 'Pending Verification',
    filterName: 'Pending Verification',
    showOnFilter: true,
    class: 'status-box-warning',
    canEdit: true,
    canAgentEdit: true,
    canDelete: true,
  },
  [EBlankFormStatus.confirmed]: {
    id: EBlankFormStatus.confirmed,
    name: 'Pending Approval',
    filterName: 'Pending Approval',
    showOnFilter: true,
    class: 'status-box-warning',
    canEdit: true,
    canDelete: true,
  },
  [EBlankFormStatus.verified]: {
    id: EBlankFormStatus.verified,
    name: 'Pending Invoice',
    filterName: 'Pending Invoice',
    showOnFilter: true,
    class: 'status-box-warning',
    canEdit: true,
    canDelete: true,
  },
  [EBlankFormStatus.approved]: {
    id: EBlankFormStatus.approved,
    name: 'Approved',
    filterName: 'Approved',
    showOnFilter: true,
    class: 'status-box-success',
  },
  [EBlankFormStatus.invoiced]: {
    id: EBlankFormStatus.invoiced,
    name: 'Invoiced',
    filterName: 'Invoiced',
    showOnFilter: true,
    class: 'status-box-turquoise-surf',
  },
  [EBlankFormStatus.rejected]: {
    id: EBlankFormStatus.rejected,
    name: 'Rejected',
    filterName: 'Rejected',
    showOnFilter: false,
    class: 'status-box-danger',
  },
  [EBlankFormStatus.completed]: {
    id: EBlankFormStatus.completed,
    name: 'Completed',
    filterName: 'Completed',
    showOnFilter: false,
    class: 'status-box-success',
  },
};

export const BLANK_FORM_COMM_SCHEME_DEFAULT_VALUES = [80, 85, 90, 100];

export const BLANK_FORM_IC_DESCRIPTION: BlankFormIcRecord = {
  [EBlankFormIcType.bonus]: {
    id: EBlankFormIcType.bonus,
    label: 'IC-Bonus',
    blankFormKey: 'bonuses',
  },
  [EBlankFormIcType.overriding]: {
    id: EBlankFormIcType.overriding,
    label: 'IC-Comm',
    blankFormKey: 'overridings',
  },
  [EBlankFormIcType.pool]: {
    id: EBlankFormIcType.pool,
    label: 'Pool',
    blankFormKey: 'pools',
  },
};

export const BLANK_FORM_REFERRAL_TYPE_OPTIONS: BlankFormReferralTypeRecord = {
  [EBlankFormReferralType.pdOverride]: {
    value: EBlankFormReferralType.pdOverride,
    viewValue: 'PD OVERRIDE',
    sourceTypes: [EBlankFormReferralSourceType.internal],
  },
  [EBlankFormReferralType.pdReferral]: {
    value: EBlankFormReferralType.pdReferral,
    viewValue: 'PD REFERRAL',
    sourceTypes: [EBlankFormReferralSourceType.internal],
  },
  [EBlankFormReferralType.pdManagement]: {
    value: EBlankFormReferralType.pdManagement,
    viewValue: 'PD MANAGEMENT',
    sourceTypes: [EBlankFormReferralSourceType.internal],
  },
  [EBlankFormReferralType.refFormulaA]: {
    value: EBlankFormReferralType.refFormulaA,
    viewValue: 'REF FORMULA - A',
    sourceTypes: [EBlankFormReferralSourceType.internal, EBlankFormReferralSourceType.external],
  },
  [EBlankFormReferralType.refFormulaB]: {
    value: EBlankFormReferralType.refFormulaB,
    viewValue: 'REF FORMULA - B',
    sourceTypes: [EBlankFormReferralSourceType.internal, EBlankFormReferralSourceType.external],
  },
  [EBlankFormReferralType.refFormulaC]: {
    value: EBlankFormReferralType.refFormulaC,
    viewValue: 'REF FORMULA - C',
    sourceTypes: [EBlankFormReferralSourceType.internal, EBlankFormReferralSourceType.external],
  },
  [EBlankFormReferralType.refFormulaD]: {
    value: EBlankFormReferralType.refFormulaD,
    viewValue: 'REF FORMULA - D',
    sourceTypes: [EBlankFormReferralSourceType.internal, EBlankFormReferralSourceType.external],
  },
  [EBlankFormReferralType.companyComm]: {
    value: EBlankFormReferralType.companyComm,
    viewValue: 'COMPANY COMM',
    sourceTypes: [EBlankFormReferralSourceType.internal],
  },
  [EBlankFormReferralType.otherFee]: {
    value: EBlankFormReferralType.otherFee,
    viewValue: 'OTHER MANAGEMENT FEE',
    sourceTypes: [EBlankFormReferralSourceType.internal],
  },
};

export const BLANK_FORM_INTERNAL_AGENT_TYPE_OPTIONS: BlankFormInternalAgentTypeRecord = {
  [EBlankFormInternalAgentType.internalCobroke]: {
    value: EBlankFormInternalAgentType.internalCobroke,
    viewValue: 'Internal Co-Broke (ICB)',
  },
  [EBlankFormInternalAgentType.internalCobrokeIncentive]: {
    value: EBlankFormInternalAgentType.internalCobrokeIncentive,
    viewValue: 'Internal Co-Broke (ICB) Incentive',
  },
  [EBlankFormInternalAgentType.taggerIncentive]: {
    value: EBlankFormInternalAgentType.taggerIncentive,
    viewValue: 'Tagger Incentive',
  },
  [EBlankFormInternalAgentType.tagger]: {
    value: EBlankFormInternalAgentType.tagger,
    viewValue: 'Tagger',
  },
  [EBlankFormInternalAgentType.icIncentive]: {
    value: EBlankFormInternalAgentType.icIncentive,
    viewValue: 'IC Incentive',
  },
};

export const BLANK_FORM_REFERRAL_FORMULA_OPTIONS: BlankFormReferralFormulaRecord = {
  [EBLankFormReferralFormula.refFormulaA]: {
    value: EBLankFormReferralFormula.refFormulaA,
    viewValue: 'REF FORMULA - A',
    sourceTypes: [EBlankFormReferralSourceType.internal, EBlankFormReferralSourceType.external],
  },
  [EBLankFormReferralFormula.refFormulaB]: {
    value: EBLankFormReferralFormula.refFormulaB,
    viewValue: 'REF FORMULA - B',
    sourceTypes: [EBlankFormReferralSourceType.internal, EBlankFormReferralSourceType.external],
  },
  [EBLankFormReferralFormula.refFormulaC]: {
    value: EBLankFormReferralFormula.refFormulaC,
    viewValue: 'REF FORMULA - C',
    sourceTypes: [EBlankFormReferralSourceType.internal, EBlankFormReferralSourceType.external],
  },
  [EBLankFormReferralFormula.refFormulaD]: {
    value: EBLankFormReferralFormula.refFormulaD,
    viewValue: 'REF FORMULA - D',
    sourceTypes: [EBlankFormReferralSourceType.internal, EBlankFormReferralSourceType.external],
  },
};

export const BLANK_FORM_OTHER_FEE_TYPE_OPTIONS: BlankFormOtherFeeTypeRecord = {
  [EBlankFormOtherFeeType.ultimateProfit]: {
    value: EBlankFormOtherFeeType.ultimateProfit,
    viewValue: 'COMPANY COMM',
  },
  [EBlankFormOtherFeeType.marketingFund]: {
    value: EBlankFormOtherFeeType.marketingFund,
    viewValue: 'MARKETING FUND',
  },
  // [EBlankFormOtherFeeType.up]: {
  //   value: EBlankFormOtherFeeType.up,
  //   viewValue: 'COMPANY COMM',
  // },
  [EBlankFormOtherFeeType.mf]: {
    value: EBlankFormOtherFeeType.mf,
    viewValue: 'MARKETING FUND',
  },
};
