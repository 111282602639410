import { coerceStringArray } from '@angular/cdk/coercion';
import { PermissionsService } from './permission.service';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { PERMISSION_PREFIX } from './permission-provider';
import { nomralizePermissionSlug } from './utls';

@Pipe({
  name: 'permission',
})
export class PermissionPipe implements PipeTransform {
  constructor(private permissionServive: PermissionsService, @Inject(PERMISSION_PREFIX) private prefix: string[]) {}

  transform(value: any): boolean {
    const arr = coerceStringArray(value);
    const slugs = arr.map(slug => nomralizePermissionSlug(slug, this.prefix));
    return this.permissionServive.canAccess(slugs);
  }
}
