import { Directive, Injectable } from '@angular/core';
import { MetabaseApiService, MetabaseCardCollection } from './metabase-report-api.service';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { ExceljsApiService, ExceljsCardCollection } from './exceljs-report-api.service';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ReportCard } from '@shared/components/report-manager';
import { map, Observable } from 'rxjs';
import { Mixin } from 'ts-mixer';
// @Injectable({
//   providedIn: 'root',
// })
// export class ResaleReportApiService extends MetabaseApiService {
//   collection: MetabaseCardCollection = 'resale_reports';
// }
@Directive()
abstract class ResaleReportMetabaseApiService extends MetabaseApiService {
  collection: MetabaseCardCollection = 'resale_reports';
}
@Directive()
abstract class ResaleReportExceljsApiService extends ExceljsApiService {
  collectionEndpoint = ExceljsCardCollection.ResaleReport;
}
@Directive()
abstract class ResaleReportCardApiService {
  static REPORT_CARD_POINT = 'transaction/resale-reports';
  constructor(protected apiService: ApiService) {}

  findReportCards(query: IQuerySearch = {}, option?: IApiOption): Observable<PaginationAdapter<ReportCard>> {
    return this.apiService.get(ResaleReportCardApiService.REPORT_CARD_POINT, query, option).pipe(map(data => new PaginationAdapter(ReportCard, data)));
  }
}

@Injectable({
  providedIn: 'root',
})
export class ResaleReportApiService extends Mixin(ResaleReportCardApiService, ResaleReportMetabaseApiService, ResaleReportExceljsApiService) {}
