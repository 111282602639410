import { BaseModel, Default, PhoneNumber } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IBlankFormExternalCoBroke } from '../interfaces/blank-form-external-co-broke.interface';
import { ReferralExternalModel } from './referral-external.model';

export class BlankFormExternalCoBrokeModel extends BaseModel implements IBlankFormExternalCoBroke {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => ReferralExternalModel)
  agent!: ReferralExternalModel;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  @Type(() => Number)
  defaultValue!: number;

  @Expose()
  attrs!: any;

  @Expose()
  note!: string;

  @Expose()
  @Default(false)
  isDefault!: boolean;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;

  @Expose()
  companyAmount!: number;
}
