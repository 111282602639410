export interface ICompanyContact {
  contactId: number;
  foreignCompanyId: number;
}

export interface ICompany {
  id: number;
  code: string;
  name: string;
  type: string;
  status: string;
  address: string;
  createdAt: string;
  updatedAt: string;
  licenceNo: string;
  setup: {
    contactId: number;
    licenceNo: string;
  };
  isDefault: boolean;
  contacts: ICompanyContact[];
}
