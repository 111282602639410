import { BaseModel, Default, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
export class KeyAppointmentCreateDto extends BaseModel {
  @Expose()
  projectId!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Boolean)
  isMainProjectIC!: boolean;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;

  // @Expose()
  // @Type(() => Boolean)
  // isPersonInCharge!: boolean;
  @Expose()
  appTypes!: { appTypeId: number }[];
}

export class KeyAppointmentUpdateDataDto extends BaseModel {
  @Expose({ groups: ['full'] })
  @Type(() => Number)
  projectId!: number;

  @Expose({ groups: ['full'] })
  @Type(() => Number)
  salespersonId!: number;

  @Expose({ groups: ['full'] })
  @Type(() => Boolean)
  isMainProjectIC!: boolean;

  @Expose({ groups: ['full'] })
  appointmentTypeId!: number;

  @Expose({ groups: ['full'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose({ groups: ['full'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Default(null)
  endDate!: string;

  @Expose({ groups: ['remark'] })
  remarks!: string;

  // @Expose()
  // @Type(() => Boolean)
  // isPersonInCharge!: boolean;
  @Expose()
  appTypes!: { appTypeId: number }[];
}

export class KeyAppointmentUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'general' | 'status' | 'full' | 'remark';

  @Expose()
  @Transform(params => {
    return KeyAppointmentUpdateDataDto.fromJson(params.value, { groups: [params.obj.group] });
  })
  data!: KeyAppointmentUpdateDataDto;
}
