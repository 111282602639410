import { IPersonalParticular } from './personal-particular.interface';
import { ICommissionScheme } from './commission-scheme.interface';
import { ITimestamp } from './timestamp.interface';
import { EVerifyStatus } from '../enums';

export interface IPromotionApproval extends ITimestamp {
  id: number;
  status: EVerifyStatus;
  salespersonId: number;
  currentCareerId: number;
  newSalespersonId: number;
  newLeadershipId: number;
  rejectReason: string;
  salesperson: IPersonalParticular;
  currentCareerProgression: ICommissionScheme;
  careerProgression: ICommissionScheme;
}

export interface IPromotionApprovalVerifyDto {
  status: EVerifyStatus;
  rejectReason: string;
  effectiveDate: string;
}
