import { ECreditNoteCreateMode, EDebitNoteStatus } from '../enums';
import { EBalanceDocumentPostingType } from '../enums/tax-invoice-enhanced.enum';
import { CurrencyRateModel } from '../models';
import { IContactAddress } from './contact-address.interface';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IContactPerson } from './contact-person.interface';
import { ICurrencyRate } from './currency-rate.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IProfitCentres } from './profit-centres.interface';
import { IStatusDescription } from './status-description.interface';
import { ITemplates } from './template.interface';
import { IUpdatedBy } from './updated-by.interface';

export interface IDebitNoteRecord {
  id?: number;
  itemCode?: string;

  description: string;

  amount?: number;
  quantity: number;
  unitPrice: number;
  discount: number;
  gstCategory: string;
  gstInclusive: boolean;
  gstValue?: number;
  // gstPercent?: number;

  uom?: string;
  remarks?: string;

  account: ILedgerAccount;
  accountId: number;

  profitCenter: IProfitCentres;
  profitCenterId?: number;

  // debitNote: IDebitNote;
  debitNoteId: number;
}

export interface IDebitNotePosting {
  account?: ILedgerAccount;
  accountId: number;

  profitCenter?: IProfitCentres;
  profitCenterId?: number;

  description?: string;

  debit: number;
  credit: number;
  amount: number;

  currency?: string;
  // debitNoteId: number;
  // debitNoteDetailId?: number;
  // debitNoteDetail?: IDebitNoteDetailEnhanced;

  type?: EBalanceDocumentPostingType;
}

export interface IDebitNote {
  // General
  contact: IContactCustomerAndSupplier;
  billingAddress: IContactAddress;
  contactId?: number;
  contactAddressId: number;

  debitNoteNumber?: string;
  debitNoteDate: string;

  contactPerson?: IContactPerson;
  contactPersonId?: number;

  // Summary
  amount: number;
  discount: number;
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gstEdited: boolean;
  gst?: number;

  // Other Details
  currencyId: number;
  currency: CurrencyRateModel;

  billingAddressCustom?: string;
  contactPersonCustom?: string;

  reference?: string;

  creditTerm?: number;
  paymentTerm?: string;

  salePersonId?: number;
  salesperson?: IPersonalParticular;

  remarks?: string;

  templateId?: number;
  template?: ITemplates;

  // General
  id: number;
  businessUnitId: number;
  status?: EDebitNoteStatus;
  createdMode?: ECreditNoteCreateMode;
  paidAmount?: number;
  paidStatus?: IStatusDescription;
  balanceDue?: number;
  inClosedPeriod?: boolean;

  createAt?: string;
  updateAt?: string;
  createdBy?: string;
  updatedBy?: IUpdatedBy;
}

export interface IDebitNoteSummary {
  amount: number;
  discount: number;
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gstEdited: boolean;
}

export interface IDebitNoteCalculateSummary {
  details: Partial<IDebitNoteRecord>[];
  summary: IDebitNoteSummary;
}

export interface IDebitNoteInitData {
  contact?: IContactCustomerAndSupplier;
  billingAddress?: IContactAddress;
  contactPerson?: IContactPerson;
  currency?: ICurrencyRate;
}

export interface IDebitNotePayment {
  amount: number;
  date: Date;
  id: number;
  referenceNo: string;
  type: string;
  // paymentContactType: ECustomerOrSupplier;
}
