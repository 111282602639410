import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EManagementCommissionPdRole } from '../enums';
import { IManagementCommissionPD } from '../interfaces';
import { AppointmentTypeModel } from './appointment-type.model';

export class ManagementCommissionPdModel extends BaseModel implements IManagementCommissionPD {
  @Expose()
  id!: number;

  @Expose()
  @Type(() => AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;

  @Expose()
  appTypeCode!: string;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  managementCommId!: number;

  @Expose()
  @Type(() => Number)
  percentage!: number;

  @Expose()
  @Type(() => Number)
  additional!: number;

  @Expose()
  pdRole?: EManagementCommissionPdRole;
}
