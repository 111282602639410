<div class="flex flex-row items-center">
  <div class="flex flex-row items-center justify-center">
    <mat-icon *ngIf="data.messages" class="icon-size-14" svgIcon="confirm_{{ data.type }}"></mat-icon>
    <mat-icon *ngIf="!data.messages" class="icon-size-12" svgIcon="confirm_{{ data.type }}"></mat-icon>
  </div>
  <div class="flex flex-col">
    <span class="text-h5 text-theme-dark font-bold">{{ data.title }}</span>
    <span class="text-b1 text-theme-gray whitespace-pre-line">{{ data.messages }}</span>
  </div>
</div>
