import { ITimestamp } from './timestamp.interface';

export interface ICurrencyRate extends ITimestamp {
  id: number;
  code: string;
  name: string;
  symbol: string;
  businessUnitId?: number;
  value: number;
  effectiveDate: string;
  uploadedAt?: string;
  uploadedBy?: string;
  isActive: boolean;
}

export interface ICurrencyRateHistory extends ITimestamp {
  id: number;
  currencyId: number;
  value: number;
  effectiveDate: string;
}
