import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { CurrencyRateModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyRateApiService {
  static ROOT_POINT = 'collection/currencies';

  constructor(private apiService: ApiService) {}

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<CurrencyRateModel>> {
    return this.apiService.get(CurrencyRateApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(CurrencyRateModel, data)));
  }
}
