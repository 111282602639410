import { Transform, TransformFnParams } from 'class-transformer';

type ValueNormal = string | number | unknown;
type ValueFn = (obj: any) => ValueNormal;

type ValueIfDecoratorType = ValueNormal | ValueFn;
type ConditionIf = [conditionFn: (obj: any) => boolean, valueIf: ValueIfDecoratorType];

export function If(conditions: ConditionIf | ConditionIf[], valueElse?: ValueIfDecoratorType) {
  const conditionCollection: ConditionIf[] = Array.isArray(conditions[0]) ? (conditions as ConditionIf[]) : ([conditions] as ConditionIf[]);
  return Transform((params: TransformFnParams) => {
    for (const condition of conditionCollection) {
      if (condition[0](params.obj)) {
        return getValue(condition[1], params.obj);
      }
    }
    return valueElse ? getValue(valueElse, params.obj) : undefined;
  });
}
function getValue(valueFn: ValueIfDecoratorType, data: any): ValueNormal {
  if (typeof valueFn === 'function') {
    return valueFn(data);
  }
  return valueFn;
}
