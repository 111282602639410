import { NgModule } from "@angular/core";
import { RedMediaWatcherService } from "./media-watcher.service";

@NgModule({
  providers: [
      RedMediaWatcherService
  ]
})
export class RedMediaWatcherModule
{
  /**
   * Constructor
   */
  constructor(private _mediaWatcherService: RedMediaWatcherService)
  {
  }
}
