import { BaseModel, IPhoneNumber, PhoneNumber } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IDistrict, INationality, IProjectTransactionBuyerInfo } from '../interfaces';
import { DistrictModel } from './district.model';
import { NationalityModel } from './nationality.model';
export class ProjectTransactionBuyerInfoModel extends BaseModel implements IProjectTransactionBuyerInfo{

  @Expose()
  remark!: string;

}
