import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IBonusDistribution } from '../interfaces';
import { BonusChartModel } from './bonus-chart.model';
import { UpdatedByModel } from './updated-by.model';

export class BonusDistributionModel extends BaseModel implements IBonusDistribution {
  @Expose()
  id!: number;

  @Expose()
  @Type(() => BonusChartModel)
  bonusCharts!: BonusChartModel[];

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  status!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;
}
