export interface IImportChartOfAccounts {
  accountType: string;
  accountCode: string;
  accountName: string;
  parentAccountCode: string;
}

export interface IImportChartOfAccountsColumn {
  id: keyof IImportChartOfAccounts;
  label: string;
  index: number;
  required?: boolean;
  maxLength?: number;
}
