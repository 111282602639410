import { EGstCategoryCustomer, EInvoicePaidStatus, ETaxInvoiceStatus } from '../enums/tax-invoice-enhanced.enum';
import { StatusRecord } from '../types';

export const TAX_INVOICE_GST_CATEGORY_MAX_TAX_PERCENT: Record<EGstCategoryCustomer, number> = {
  [EGstCategoryCustomer.ZR]: 0,
  [EGstCategoryCustomer.SR]: 8,
  [EGstCategoryCustomer.ES33]: 0,
  [EGstCategoryCustomer.DS]: 8,
  [EGstCategoryCustomer.ESN33]: 0,
  [EGstCategoryCustomer.OS]: 0,
};

export const TAX_INVOICE_STATUSES: StatusRecord<ETaxInvoiceStatus> = {
  [ETaxInvoiceStatus.View]: {
    id: ETaxInvoiceStatus.View,
    name: 'View',
    detailName: 'View',
    class: 'status-box-default',
    detailClass: 'status-box-default',
  },
  [ETaxInvoiceStatus.PartPaid]: {
    id: ETaxInvoiceStatus.PartPaid,
    name: 'Part Paid',
    detailName: 'Part Paid',
    class: 'status-box-warning',
    detailClass: 'status-box-warning',
  },
  [ETaxInvoiceStatus.FullyPaid]: {
    id: ETaxInvoiceStatus.FullyPaid,
    name: 'Fully Paid',
    detailName: 'Fully Paid',
    class: 'status-box-success',
    detailClass: 'status-box-success',
  },
  [ETaxInvoiceStatus.Cancelled]: {
    id: ETaxInvoiceStatus.Cancelled,
    name: 'Cancelled',
    detailName: 'Void',
    class: 'status-box-smokey-topaz',
    detailClass: 'status-box-smokey-topaz',
  },
  // [ETaxInvoiceStatus.Confirmed]: {
  //   id: ETaxInvoiceStatus.Confirmed,
  //   name: 'Confirmed',
  //   class: 'status-box-purple',
  // },
  // [ETaxInvoiceStatus.Withhold]: {
  //   id: ETaxInvoiceStatus.Withhold,
  //   name: 'Withhold',
  //   class: 'status-box-danger',
  // },
  // [ETaxInvoiceStatus.PaymentInProgress]: {
  //   id: ETaxInvoiceStatus.PaymentInProgress,
  //   name: 'Payment In Progress',
  //   class: 'status-box-success',
  // },
};

export const TAX_INVOICE_PAID_STATUSES: StatusRecord<EInvoicePaidStatus> = {
  [EInvoicePaidStatus.NOT_PAID]: {
    id: EInvoicePaidStatus.NOT_PAID,
    name: 'Not Paid',
    class: 'status-box-dark-electric-blue',
  },
  [EInvoicePaidStatus.NOT_FULL_PAID]: {
    id: EInvoicePaidStatus.NOT_FULL_PAID,
    name: 'Not Full Paid',
    class: 'status-box-dark-electric-blue',
  },
  [EInvoicePaidStatus.FULLY_PAID]: {
    id: EInvoicePaidStatus.FULLY_PAID,
    name: 'Fully Paid',
    class: 'status-box-dark-electric-blue',
  },
  [EInvoicePaidStatus.CREDITED]: {
    id: EInvoicePaidStatus.CREDITED,
    name: 'Credited',
    class: 'status-box-dark-electric-blue',
  },
  [EInvoicePaidStatus.CANCELLED]: {
    id: EInvoicePaidStatus.CANCELLED,
    name: 'Cancelled',
    class: 'status-box-dark-electric-blue',
  },
};
