import { BaseModel, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { JournalPostingModel } from '../models/journal-posting.model';

export class JournalCreateDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  reversal!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  journalDate?: string;

  @Expose()
  scheduleEnable?: boolean;

  @Expose()
  scheduleName?: string;

  @Expose()
  scheduleType?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  scheduleStartDate?: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  scheduleEndDate?: string;

  @Expose()
  @Type(() => JournalPostingModel)
  postings?: JournalPostingModel[];
}
