import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { IGstCategoryLookup, IRawGstCategoryData } from '@shared/data-access/interfaces';
import { GstCategoryLookupModel, LookupTableCellModel, LookupTableDetailModel } from '@shared/data-access/models';
import { GstCategoryAppearanceChoice } from '@shared/data-access/types';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GstCategoryLookupApiService {
  static LOOKUP_POINT = 'collection/ams/lookup';
  static ROOT_POINT = 'finance/gst-category';

  private _rootQuery = {
    lookupSetupIds: 3,
  };

  constructor(private apiService: ApiService) {}

  search_lookup(query = {}, option?: IApiOption): Observable<PaginationAdapter<GstCategoryLookupModel>> {
    return this.apiService.get(GstCategoryLookupApiService.LOOKUP_POINT, { ...this._rootQuery, ...query }, option).pipe(
      map((data: { items: LookupTableDetailModel[]; meta: Record<string, any> }) => {
        const { items, meta } = data;

        const newItems: Partial<IGstCategoryLookup>[] = items.map(item => {
          const cells = item.cells;
          const newItem: Partial<IGstCategoryLookup> = {
            id: item.id,
            lookupSetupId: item.lookupSetupId,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          };

          const newData = cells.reduce((acc: any, curr: LookupTableCellModel) => {
            acc[curr.fieldName] = curr.value;
            return acc;
          }, newItem);

          console.log('cells: ', newData);

          return newData;
        });

        return new PaginationAdapter(GstCategoryLookupModel, { items: newItems, meta });
      })
    );
  }

  search(query = {}, type?: GstCategoryAppearanceChoice, option?: IApiOption): Observable<GstCategoryLookupModel[]> {
    const finalQuery = type ? { type, ...query } : query;
    return this.apiService
      .get(GstCategoryLookupApiService.ROOT_POINT, finalQuery, option)
      .pipe(map((results: IRawGstCategoryData[]) => results.map(item => GstCategoryLookupModel.fromJson(item))));
  }
}
