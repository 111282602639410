import { BaseModel } from '@red/data-access';
import {  Expose } from 'class-transformer';
import { IDesignation } from '../interfaces';

export class DesignationModel extends BaseModel implements IDesignation{
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  status!: string;

  @Expose()
  designation?: string;
}
