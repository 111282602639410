import { BaseModel } from '@red/data-access';
import { IAssociatePayout } from '../interfaces';
import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class AssociatePayoutModel extends BaseModel implements IAssociatePayout {
  @Expose()
  agentType!: string;

  @Expose()
  payoutDate!: string;

  @Expose()
  chequeNumber!: string;

  @Expose()
  payoutValue!: number;

  @Expose()
  comPersonScheme!: number;

  @Expose()
  agentName!: string;

  @Expose()
  agentNric!: string;
}
