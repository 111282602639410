import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { CurrencyRateCreateDto, CurrencyRateUpdateDto, ScommMatrixTypeCreateDto, ScommMatrixTypeUpdateDto } from '@shared/data-access/dto';
import { ICurrencyRate, IScommMatrixType } from '@shared/data-access/interfaces';
import { CurrencyRateModel, ScommMatrixTypeModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommMatrixTypeApiService {
  static ROOT_POINT = 'collection/categories';

  constructor(private apiService: ApiService) { }

  create(data: ScommMatrixTypeCreateDto): Observable<ScommMatrixTypeModel> {
    return this.apiService.post(`${CommMatrixTypeApiService.ROOT_POINT}`, data).pipe(map((res: IScommMatrixType) => ScommMatrixTypeModel.fromJson(res) as ScommMatrixTypeModel));
  }

  get(itemId: number): Observable<ScommMatrixTypeModel> {
    return this.apiService.get(CommMatrixTypeApiService.ROOT_POINT + '/' + itemId).pipe(map(res => ScommMatrixTypeModel.fromJson(res) as ScommMatrixTypeModel));
  }

  update(id: number, data: ScommMatrixTypeUpdateDto): Observable<any> {
    return this.apiService.patch(CommMatrixTypeApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ScommMatrixTypeModel>> {
    return this.apiService.get(CommMatrixTypeApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ScommMatrixTypeModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(CommMatrixTypeApiService.ROOT_POINT, { ids }, option);
  }
}
