import { ESupplierInvoiceStatus } from '../enums';
import { EBalanceDocumentCode, EBalanceDocumentPostingType, ECustomerOrSupplier, EInvoiceCreateMode, ESupplierInvoice } from '../enums/tax-invoice-enhanced.enum';
import { CurrencyRateModel } from '../models';
import { IContactAddress } from './contact-address.interface';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IContactPerson } from './contact-person.interface';
import { ICurrencyRate } from './currency-rate.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IProfitCentres } from './profit-centres.interface';
import { ITaxInvoice } from './tax-invoice-enhanced.interface';
import { IUpdatedBy } from './updated-by.interface';

export interface ISupplierInvoiceRecord {
  id?: number;
  itemCode?: string;
  description: string;
  uom?: string;
  remarks?: string; // not have in uat

  amount?: number;
  quantity: number;
  unitPrice: number;
  discount: number;
  gstCategory: string;
  gstInclusive: boolean;
  gstValue?: number;
  // gstPercent?: number;
  total?: number; // unit price * quantity. Not include tax. For display only in supplier credit note

  account: ILedgerAccount;
  accountId: number;

  profitCenter: IProfitCentres;
  profitCenterId?: number;

  taxInvoice?: Partial<ITaxInvoice>;
  taxInvoiceId?: number;

  supplierInvoiceId?: number;
  supplierInvoice?: Partial<ISupplierInvoice>;

  // For SCN
  posting?: {
    accountName: string;
    accountId: number;
    accountCode: string;
  }


  // Base
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  createdBy?: string;
  updatedBy?: IUpdatedBy;
  deletedBy?: string;

  // agentId?: number;
  // agent?: IPersonalParticular;

  // transactionNo?: string;
  // propertyAddress?: string;
  // clientName?: string;
  // transactionType?: string;
  paidAmount?: number;
  // total?: number;
  balanceDue?: number;
  // clientType?: string;
  // propertyType?: string;
  // agentType?: string;
  // street?: string;
  // buildingName: string;
  // unitNo: string;
  // floor?: string;
  // country: string;
  // postalCode: string;
  // blockNo: string;
  // associate?: string;
  // commissionPercent?: number;
}

export interface ISupplierInvoicePosting {
  id?: number;
  account?: ILedgerAccount;
  accountId: number;

  profitCenter?: IProfitCentres;
  profitCenterId?: number;

  description?: string;

  debit: number;
  credit: number;
  amount: number;

  currency?: string;
  taxInvoiceId: number;
  taxInvoiceDetailId?: number;
  taxInvoiceDetail?: ITaxInvoice;

  type?: EBalanceDocumentPostingType;
}

export interface ISupplierInvoicePayment {
  id: number;
  code: string;
}

export interface ISupplierInvoice {
  // General
  contact: IContactCustomerAndSupplier;
  billingAddress: IContactAddress;
  contactId?: number;
  contactAddressId: number;

  invoiceNumber?: string;
  invoiceDate: string;
  invoiceDueDate?: string;
  ecbInvoiceDate?: string;

  contactPerson?: IContactPerson;
  contactPersonId?: number;
  contactPersonCustom?: string;
  customerName?: string;
  inClosedPeriod?: boolean;

  // Summary
  amount?: number;
  //   discount: number
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gstEdited: boolean;
  gst?: number;

  // Other Details
  currencyId: number;
  currencyCode?: string;
  currency: CurrencyRateModel;

  reference?: string;
  remarks?: string;

  creditTerm?: number;

  fileAttachedName?: string;
  fileAttachedViewName?: string;

  // General
  id: number;
  businessUnitId: number;
  status?: ESupplierInvoiceStatus;
  createdMode?: EInvoiceCreateMode;
  invoiceType?: ECustomerOrSupplier.SUPPLIER;
  balanceDocumentCodeType?: EBalanceDocumentCode;

  paidStatus?: string;
  balanceDue?: number;

  paymentGenerators: ISupplierInvoicePayment[];
  paymentDirects: ISupplierInvoicePayment[];

  // Base
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  createdBy?: string;
  updatedBy?: IUpdatedBy;
  deletedBy?: string;

  // For list / detail display
  adjustment?: boolean;
  projectId?: number;
  paidAmount?: number;
  parentInvoice?: ITaxInvoice;
  parentInvoiceId?: number;
  batchComment?: string;
  batchName?: string;
  batchCode?: string;
  attention?: string;
  supplierInvoiceType?: ESupplierInvoice;
}

export interface ISupplierInvoiceSummary {
  //   amount: number;
  //   discount: number;
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gstEdited: boolean;
}

export interface ISupplierInvoiceCalculateSummary {
  details: Partial<ISupplierInvoiceRecord>[];
  summary: ISupplierInvoiceSummary;
}

export interface ISupplierInvoiceInitData {
  contact?: IContactCustomerAndSupplier;
  billingAddress?: IContactAddress;
  contactPerson?: IContactPerson;
  currency?: ICurrencyRate;
  invoice?: ISupplierInvoice;
}

export interface ISupplierInvoicePayment {
  amount: number;
  id: number;
  referenceNo: string;
  date: Date;
  type: string;
}

export interface ISupplierInvoiceValidateCode {
  valid: boolean;
}
