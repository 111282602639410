export const APPOINTMENT_TYPE_KEY_CONFIG = {
  managementFee:{
    key:'managementFee',
    order:1
  },
  pd:{
    key:'pd',
    order:2
  }
}

export const APPOINTMENT_TYPE_KEYS = Object.keys(APPOINTMENT_TYPE_KEY_CONFIG)
