import { ProfitCentresModel } from '../models';
import { IBase } from './base-finance.interface';

export interface IProfitCentres extends IBase {
  id: number;
  code: string;
  name: string;
  status: string;
  description: string;
  refCode?: string;
  parentId?: string;
  profitCentres: ProfitCentresModel;
}
