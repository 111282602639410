import { BaseModel } from './base.model';
import { IPhoneNumber } from '../interfaces';
import { Expose } from 'class-transformer';

export class PhoneNumber extends BaseModel implements IPhoneNumber {
  @Expose()
  countryCode!: string;

  @Expose()
  phoneNumber!: string;

  get phoneNumberDisplay() {
    return this.phoneNumber ? `+${this.countryCode} ${this.phoneNumber}` : '';
  }
}
