export interface IGstCategory {
  id: number;
  categoryRole: string;
  code: string;
  createdAt: string;
  description: string;
  isActive: boolean;
  name: string;
  updatedAt: string;
}

export interface IRawGstCategoryData {
  abbreviation: string;
  appear_ap: string;
  appear_ar: string;
  appear_transaction: string;
  gst_category: string;
  gst_charged: string;
  gst_code: string;
  gst_rate: string;
  lookupId: number;
}

export interface IGstCategoryLookup {
  id?: number;
  lookupSetupId?: number;
  lookupId?: number;

  abbreviation: string;
  appear_ap: boolean;
  appear_ar: boolean;
  appear_transaction: boolean;
  gst_category: string;
  gst_charged: number;
  gst_code: string;
  gst_rate: number;
  active: boolean;

  createdAt?: string;
  updatedAt?: string;
}
