import { Injector, NgZone, StaticProvider } from '@angular/core';
import { Subscription } from 'rxjs';
import { DocumentVisibilityStateEnum } from './document-visibility.enum';
import { DocumentVisibilityService } from './document-visibility.service';
import { DocumentVisibilityModule } from './document-visibility.module';
// const providers: StaticProvider[] = [NgZone,{ provide: DocumentVisibilityService, deps: [NgZone] }];
// const injector = Injector.create({ providers: providers });

export function OnPageVisibilityChange(): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    let onPageHiddenSubscription: Subscription | undefined;
    if (!target.ngOnInit) {
      newMethod(target, 'ngOnInit');
    }
    const originalNgOnInit = target.ngOnInit;
    target.ngOnInit = function (...args: unknown[]) {
      const pageVisibilityService = DocumentVisibilityModule.injector?.get(DocumentVisibilityService);
      onPageHiddenSubscription = pageVisibilityService?.$onPageVisibilityChange.subscribe((visibilityState: DocumentVisibilityStateEnum) =>
        originalMethod.call(this, [visibilityState])
      );
      if (originalNgOnInit) {
        originalNgOnInit.call(this, args);
      }
    };
    if (!target.ngOnDestroy) {
      newMethod(target, 'ngOnDestroy');
    }
    const originalNgOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function (...args: unknown[]) {
      onPageHiddenSubscription?.unsubscribe();
      if (originalNgOnDestroy) {
        originalNgOnDestroy.call(this, args);
      }
    };
  };
}

export function OnPageHidden(): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    let onPageHiddenSubscription: Subscription | undefined;
    if (!target.ngOnInit) {
      newMethod(target, 'ngOnInit');
    }
    const originalNgOnInit = target.ngOnInit;
    target.ngOnInit = function (...args: unknown[]) {
      const pageVisibilityService = DocumentVisibilityModule.injector?.get(DocumentVisibilityService);
      onPageHiddenSubscription = pageVisibilityService?.$onPageHidden.subscribe(() => originalMethod.call(this));
      if (originalNgOnInit) {
        originalNgOnInit.call(this, args);
      }
    };
    if (!target.ngOnDestroy) {
      newMethod(target, 'ngOnDestroy');
    }
    const originalNgOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function (...args: unknown[]) {
      onPageHiddenSubscription?.unsubscribe();
      if (originalNgOnDestroy) {
        originalNgOnDestroy.call(this, args);
      }
    };
  };
}

export function OnPageVisible(): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    if (!target.ngOnInit) {
      newMethod(target, 'ngOnInit');
    }
    const originalNgOnInit = target.ngOnInit;
    let onPageVisibleSubscription: Subscription | undefined;
    target.ngOnInit = function (...args: unknown[]) {
      const pageVisibilityService = DocumentVisibilityModule.injector?.get(DocumentVisibilityService);
      onPageVisibleSubscription = pageVisibilityService?.$onPageVisible.subscribe(() => originalMethod.call(this));
      if (originalNgOnInit) {
        originalNgOnInit.call(this, args);
      }
    };
    if (!target.ngOnDestroy) {
      newMethod(target, 'ngOnDestroy');
    }
    const originalNgOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function (...args: unknown[]) {
      onPageVisibleSubscription?.unsubscribe();
      if (originalNgOnDestroy) {
        originalNgOnDestroy.call(this, args);
      }
    };
  };
}

function newMethod(target: any, name: string) {
  Object.defineProperty(target, name, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    value: function (...args: unknown[]) {},
    writable: true,
  });
}
