import { BaseModel } from '@red/data-access';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DynamicTitlePageFn, DYNAMIC_TITLE_PAGE_FN } from './detail-controller/title-page';
export const API_SERVICE_FOR_DETAIL_RESOLVER = new InjectionToken('API_SERVICE_FOR_DETAIL_RESOLVER');
export interface ApiServiceForDetailResolver {
  getById(id: number): Observable<unknown>;
}
export function detailResolverWithDrawerFactory(_apiService: ApiServiceForDetailResolver, _router: Router) {
  return new DetailResolverWithDrawer(_apiService, _router);
}

@Injectable()
export class DetailResolverWithDrawer<T extends BaseModel> implements Resolve<T> {
  /**
   * Constructor
   */
  constructor(
    @Inject(API_SERVICE_FOR_DETAIL_RESOLVER) private _apiService: ApiServiceForDetailResolver,
    private _router: Router,
    @Inject(DYNAMIC_TITLE_PAGE_FN) @Optional() private _titlePageFn?: DynamicTitlePageFn
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let nextRoute: ActivatedRouteSnapshot | null = state.root;
    while (nextRoute && !nextRoute.paramMap.has('id')) {
      nextRoute = nextRoute.firstChild;
    }
    if (!nextRoute) {
      return throwError(() => new Error(`can not find id`));
    }
    const id: number = parseInt(nextRoute.paramMap.get('id') as string);
    if (isNaN(id)) {
      return throwError(() => new Error(`can not find id`));
    }
    return this._apiService.getById(id).pipe(
      // Error here means the requested contact is not available
      catchError(error => {
        // Log the error
        console.error(error);

        // Get the parent url
        const parentUrl = state.url.split('/').slice(0, -2).join('/');

        console.log('Parent: ', parentUrl);

        // Navigate to there
        this._router.navigateByUrl(parentUrl);

        // Throw an error
        return throwError(() => error);
      })
    );
  }
}
