import { ESupplierCreditNoteDetailStatus } from '../enums';
import { StatusRecord } from '../types';

// export const CREDIT_NOTE_STATUSES = [
//   {
//     id: ECreditNoteStatus.CANCELLED,
//     name: 'Cancelled',
//   },
//   {
//     id: ECreditNoteStatus.ABORTED,
//     name: 'Aborted',
//   },
//   {
//     id: ECreditNoteStatus.PARTIAL,
//     // name: 'Partial Credit Note',
//     name: 'Cancelled Partial',
//   },
// ];

export const SUPPLIER_CREDIT_NOTE_PAID_STATUS: StatusRecord<ESupplierCreditNoteDetailStatus> = {
  [ESupplierCreditNoteDetailStatus.View]: {
    id: ESupplierCreditNoteDetailStatus.View,
    name: 'View',
    filterName: 'View',
    class: 'status-box-default',
  },
  // [ESupplierCreditNoteDetailStatus.UnPaid]: {
  //   id: ESupplierCreditNoteDetailStatus.UnPaid,
  //   name: 'Un-Paid',
  //   filterName: 'Un-Paid',
  //   class: 'status-box-default',
  // },
  [ESupplierCreditNoteDetailStatus.PartPaid]: {
    id: ESupplierCreditNoteDetailStatus.PartPaid,
    name: 'Part Paid',
    filterName: 'Part Paid',
    class: 'status-box-warning',
  },
  [ESupplierCreditNoteDetailStatus.Pending]: {
    id: ESupplierCreditNoteDetailStatus.Pending,
    name: 'Pending',
    filterName: 'Pending',
    class: 'status-box-warning',
  },
  [ESupplierCreditNoteDetailStatus.FullyPaid]: {
    id: ESupplierCreditNoteDetailStatus.FullyPaid,
    name: 'Fully Paid',
    filterName: 'Fully Paid',
    class: 'status-box-success',
  },
  // [ESupplierCreditNoteDetailStatus.Confirmed]: {
  //   id: ESupplierCreditNoteDetailStatus.Confirmed,
  //   name: 'Confirmed',
  //   filterName: 'Confirmed',
  //   class: 'status-box-success',
  // },
  [ESupplierCreditNoteDetailStatus.Withhold]: {
    id: ESupplierCreditNoteDetailStatus.Withhold,
    name: 'Withhold',
    filterName: 'Withhold',
    class: 'status-box-electric-orange',
  },
  [ESupplierCreditNoteDetailStatus.PaymentInProgress]: {
    id: ESupplierCreditNoteDetailStatus.PaymentInProgress,
    name: 'Payment In Progress',
    filterName: 'Payment In Progress',
    class: 'status-box-warning',
  },
};
