import { Permission } from '@shared/permission';
import { Expose, Transform } from 'class-transformer';
import { EUserGroup } from '../enums';

export class UserPermissionModel extends Permission {
  @Expose()
  group!: string;

  @Expose()
  @Transform(params => {
    return getModuleGroupPermssion(params.obj.module);
  })
  moduleGroup!: string;

  @Expose()
  @Transform(params => {
    return getScopeGroupPermssion(params.obj.scope);
  })
  scopeGroup!: string;
}

export function getModuleGroupPermssion(name: string): string {
  if ([EUserGroup.CONFIGURATION, EUserGroup.SETTINGS].some(item => item === name)) {
    return EUserGroup.CONFIGURATION;
  }
  if (EUserGroup.PROJECT === name) {
    return EUserGroup.TRANSACTION;
  }
  return name;
}

export function getScopeGroupPermssion(name: string) {
  if (['create', 'update', 'read', 'delete'].some(item => item === name)) {
    return name;
  }
  return 'others';
}
