import { BaseModel, PhoneNumber } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IManagementCommissionApprover } from '@shared/data-access/interfaces/management-commission-approver.interface';
import { CompanyModel } from '@shared/data-access/models/company.model';

export class ManagementCommissionApproverModel extends BaseModel implements IManagementCommissionApprover {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  address!: string;

  @Expose()
  email!: string;

  @Expose()
  @Type(() => PhoneNumber)
  phone!: PhoneNumber;

  @Expose()
  companyId!: number;

  @Expose()
  @Type(() => CompanyModel)
  company!: CompanyModel;

  @Expose()
  gstRegistered!: boolean;
}
