import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IBatchingTransactionSelection } from '../interfaces';
import { ProjectTransactionModel } from './project-transaction.model';
import { BlankFormModel } from './blank-form.model';

export class BatchingTransactionSelectionModel extends BaseModel implements IBatchingTransactionSelection {
  @Expose()
  id!: number;

  @Expose()
  transactionId!: number;

  @Expose()
  @Type(() => ProjectTransactionModel)
  transaction!: ProjectTransactionModel;

  @Expose()
  blankFormId!: number;

  @Expose()
  @Type(() => BlankFormModel)
  blankForm!: BlankFormModel;

  @Expose()
  isChosen!: boolean;

  @Expose()
  isPrinted!: boolean;

  @Expose()
  rate!: number;

  @Expose()
  status!: string;

  @Expose()
  remarks!: string;
}
