export enum EInvoiceCreateMode {
  MANUAL = 'MANUAL',
  BATCH = 'BATCH',
  RESALE = 'RESALE',
  RESALE_ECB = 'RESALE_ECB',
  TRANSACTION_ECB = 'TRANSACTION_ECB',
}

export enum ESupplierInvoice {
  NonPO = 'Non-PO',
}

export enum ECustomerOrSupplier {
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER',
}

export enum EBalanceDocumentCode {
  IV = 'IV',
  SCN = 'SCN',
}

export enum EBalanceDocumentPostingType {
  FROM_DETAIL = 'FROM_DETAIL',
  GST = 'GST',
  TRADE_RECEIVABLE = 'TRADE_RECEIVABLE',
  ITEM = 'ITEM',
}

export enum EGstCategoryDetailCustomer {
  ZR = 'ZR - Zero-Rated Supply',
  SR = 'SR - Standard-Rated Supply',
  ES33 = 'ES33 - Exempt Supply',
  DS = 'DS - Deemed Supply',
  ESN33 = 'ESN33 - Non-Exempt Supply',
  OS = 'OS - Out-Of-Scope Supply',
}

export enum EGstCategoryDetailSupplier {
  NR = 'NR - Purchase From Non-Registered Supplier',
  TX7 = 'TX7 - Standard-Rated-Purchase',
  TX8 = 'TX8 - Standard-Rated Purchase',
  EP = 'EP - Exempt Purchase',
  BL = 'BL - Non-Claimable Purchase',
  ZP = 'ZP - Zero-Rated Purchase',
  IM = 'IM - Import Of Goods Purchase',
  ME = 'ME - Import Under Special Scheme Purchase',
  TXE33 = 'TX-E33 - Exempt Supplies Purchase',
  OP = 'OP - Out-Of-Scope Purchase',
  TXN33 = 'TX-N33 - Non-Exempt Supplies Purchase',
  TXRE = 'TX-RE - Non-Taxable/Exempt Supplies Purchase',
}

export enum EGstCategoryCustomer {
  ZR = 'ZR',
  SR = 'SR',
  ES33 = 'ES33',
  DS = 'DS',
  ESN33 = 'ESN33',
  OS = 'OS',
}

export enum EGstCategorySupplier {
  NR = 'NR',
  TX7 = 'TX7',
  TX8 = 'TX8',
  EP = 'EP',
  BL = 'BL',
  ZP = 'ZP',
  IM = 'IM',
  ME = 'ME',
  TXE33 = 'TXE33',
  OP = 'OP',
  TXN33 = 'TXN33',
  TXRE = 'TXRE',
}

export enum ETaxInvoiceStatus {
  // Confirmed = 'Confirmed',
  // Withhold = 'Withhold',
  // PaymentInProgress = 'PaymentInProgress',

  View = 'View',
  PartPaid = 'PartPaid',
  FullyPaid = 'FullyPaid',
  Cancelled = 'Cancelled',
}

export enum EInvoicePaidStatus {
  NOT_PAID = 'NOT_PAID',
  NOT_FULL_PAID = 'NOT_FULL_PAID',
  FULLY_PAID = 'FULLY_PAID',
  CREDITED = 'CREDITED',
  CANCELLED = 'Cancelled',
}
