import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, inject } from '@angular/core';
import {
  AuthorizationParams,
  IRedocOauth2Config,
  RedocOAuthEvent,
  RedocOAuthInfoEvent,
  RedocOAuthLogger,
  RedocOAuthStorage,
  RedocOauth2ClientConnect,
  RedocOauth2ClientConnectOptionCallback,
} from '@redocco/oauth2-redoc-connect';
import { Observable, Subject, Subscription, delay, filter, of } from 'rxjs';
import { OAUTH2_CONFIG } from './token';
let index = 0;
@Injectable({
  providedIn: 'root',
})
export class OauthService {
  clientConnect!: RedocOauth2ClientConnect;
  options: Partial<IRedocOauth2Config> = inject(OAUTH2_CONFIG);
  logger: RedocOAuthLogger = inject(RedocOAuthLogger);
  storage: RedocOAuthStorage = inject(RedocOAuthStorage);
  ngZone: NgZone = inject(NgZone);
  http: HttpClient = inject(HttpClient);
  document = inject(DOCUMENT);
  tokenReceivedSubscription?: Subscription;
  accessTokenTimeoutSubscription?: Subscription;
  idTokenTimeoutSubscription?: Subscription;
  automaticRefreshSubscription?: Subscription;
  callbackOption: Partial<RedocOauth2ClientConnectOptionCallback> = {
    onEvent: e => {
      this.pushEvent(e);
    },
    onConfigChanged: () => {
      this.setupRefreshTimer();
    },
  };
  protected eventsSubject: Subject<RedocOAuthEvent> = new Subject<RedocOAuthEvent>();
  events: Observable<RedocOAuthEvent> = this.eventsSubject.asObservable();
  constructor() {
    this.clientConnect = new RedocOauth2ClientConnect(this.storage, this.document, this.logger, this.callbackOption, {
      prefix: this.options.tokenSetConfig?.prefix,
    });
    this.clientConnect.configure(this.options);
    // this.setupRefreshTimer();
    index++;
    console.log('index', index);
  }
  configure(config: Partial<IRedocOauth2Config>) {
    this.clientConnect.configure(config);
  }
  loadDiscoveryDocument() {
    return this.clientConnect.loadDiscoveryDocument();
  }
  loadDiscoveryDocumentAndTryLogin() {
    if (this.clientConnect.discoveryDocument) {
      return this.clientConnect.tryLogin();
    }
    return this.clientConnect.loadDiscoveryDocumentAndTryLogin();
  }
  getDiscoveryDocument() {
    return this.clientConnect.discoveryDocument;
  }
  tryLogin() {
    return this.clientConnect.tryLogin();
  }
  loginWithRedirect(additionalState?: string, params: Partial<AuthorizationParams> = {}): void {
    this.clientConnect.initLoginFlow(additionalState, params);
  }
  loginWithPopup(params: Partial<AuthorizationParams> = {}): void {
    this.clientConnect.loginWithPopup(params);
  }
  logOut(): void {
    this.clientConnect.logOut();
  }
  get state() {
    return this.clientConnect.state;
  }
  getRefreshToken() {
    return this.clientConnect.getRefreshToken();
  }
  getAccessToken() {
    return this.clientConnect.getAccessToken();
  }
  cleanUp() {
    this.clientConnect.cleanUpStorage();
  }
  hasValidAccessToken(): boolean {
    return this.clientConnect.hasValidAccessToken();
  }
  setupSessionCheck(): void {
    // this.events
    //   .pipe(filter((e) => e.type === 'token_received'))
    //   .subscribe((e) => {
    //     this.initSessionCheck();
    //   });
  }
  setupRefreshTimer(): void {
    if (typeof window === 'undefined') {
      this.clientConnect.debug('timer not supported on this plattform');
      return;
    }
    if (this.clientConnect.hasValidIdToken() || this.clientConnect.hasValidAccessToken()) {
      this.clearAccessTokenTimer();
      this.clearIdTokenTimer();
      this.setupExpirationTimers();
    }

    if (this.tokenReceivedSubscription) {
      this.tokenReceivedSubscription.unsubscribe();
    }

    this.tokenReceivedSubscription = this.events.pipe(filter(e => e.type === 'token_received')).subscribe(() => {
      this.clearAccessTokenTimer();
      this.clearIdTokenTimer();
      this.setupExpirationTimers();
    });
  }
  clearAccessTokenTimer(): void {
    if (this.accessTokenTimeoutSubscription) {
      this.accessTokenTimeoutSubscription.unsubscribe();
    }
  }
  clearIdTokenTimer(): void {
    if (this.idTokenTimeoutSubscription) {
      this.idTokenTimeoutSubscription.unsubscribe();
    }
  }
  clearAutomaticRefreshTimer(): void {
    if (this.automaticRefreshSubscription) {
      this.automaticRefreshSubscription.unsubscribe();
    }
  }
  setupExpirationTimers(): void {
    if (this.clientConnect.hasValidAccessToken()) {
      this.setupAccessTokenTimer();
    }

    if (!this.clientConnect.config.disableIdTokenTimer && this.clientConnect.hasValidIdToken()) {
      this.setupIdTokenTimer();
    }
  }
  setupAccessTokenTimer(): void {
    const timeout = this.clientConnect.calcTokenTimeout('access_token');

    this.ngZone.runOutsideAngular(() => {
      this.accessTokenTimeoutSubscription = of(new RedocOAuthInfoEvent('token_expires', 'access_token'))
        .pipe(delay(timeout))
        .subscribe(e => {
          this.ngZone.run(() => {
            this.eventsSubject.next(e);
          });
        });
    });
  }
  setupIdTokenTimer(): void {
    const timeout = this.clientConnect.calcTokenTimeout('id_token');

    this.ngZone.runOutsideAngular(() => {
      this.idTokenTimeoutSubscription = of(new RedocOAuthInfoEvent('token_expires', 'id_token'))
        .pipe(delay(timeout))
        .subscribe(e => {
          this.ngZone.run(() => {
            this.pushEvent(e);
          });
        });
    });
  }
  getIdentityClaims() {
    return this.clientConnect.getIdentityClaims() as {
      sub: number;
      iat: number;
      iss: string;
      aud: string;
    };
  }
  public stopAutomaticRefresh() {
    this.clearAccessTokenTimer();
    this.clearIdTokenTimer();
    this.clearAutomaticRefreshTimer();
  }

  pushEvent(event: RedocOAuthEvent): void {
    this.eventsSubject.next(event);
  }
}
