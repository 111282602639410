export enum EUnitNoOfRoom {
  'Studio' = 'Studio',
  '1B' = '1B',
  '1B+' = '1B+',
  '2B' = '2B',
  '2B+' = '2B+',
  '3B' = '3B',
  '3B+' = '3B+',
  '4B' = '4B',
  '4B+' = '4B+',
  '5B' = '5B',
  '>5B' = '>5B',
  'N.A' = 'N.A',
}
