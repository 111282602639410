import {BaseModel, Default} from "@red/data-access";
import {Expose, Type} from "class-transformer";

export class DisciplineCreateDto extends BaseModel {

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  @Default("")
  type!: string;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isActive!: boolean;
}
