import { IAttachment } from './../interfaces/attachment.interface';
import { BaseModel, STORAGE_TYPE } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
export class AttachmentModel extends BaseModel implements IAttachment {
  @Expose()
  id!: number;

  @Expose()
  fileName!: string;

  @Expose()
  fileSize!: number;

  @Expose()
  fileType!: string;

  @Expose()
  hostProvider!: STORAGE_TYPE;

  @Expose()
  @Type(() => Date)
  createdAt!: Date;

  @Expose()
  @Type(() => Date)
  updatedAt!: Date;
}
