import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule, UrlSegment, UrlTree } from '@angular/router';
import { ExceljsNormalizedCell } from '../models';
import { EXCELJS_ORIGIN, EXCELJS_PREFIX_URL } from '../exceljs-viewer.module';

@Component({
  selector: 'red-exceljs-cell-hyperlink',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './exceljs-cell-hyperlink.component.html',
  styleUrls: ['./exceljs-cell-hyperlink.component.scss'],
})
export class ExceljsCellHyperlinkComponent {
  @Input() get cell(): ExceljsNormalizedCell | undefined {
    return this._cell;
  }
  set cell(val: ExceljsNormalizedCell | undefined) {
    this._cell = val;
    this._renderUrlTree();
  }
  private _cell: ExceljsNormalizedCell | undefined;
  urlTree?: UrlTree;
  segments: UrlSegment[] = [];
  commands: string[] = [];
  constructor(private router: Router, @Optional() @Inject(EXCELJS_PREFIX_URL) private prefix: string, @Inject(EXCELJS_ORIGIN) private origin: string) {}

  _renderUrlTree(): void {
    if (this.cell?.hyperlink) {
      let hyperlink = this.cell.hyperlink;
      if (this.origin && hyperlink.includes(this.origin)) {
        hyperlink = hyperlink.replace(this.origin, '');
        this.urlTree = this.router.parseUrl(hyperlink);
        this.segments = this.urlTree.root.children['primary'].segments;
        this.commands = [`/`, ...this.segments.map(segment => segment.path)];
      } else {
        this.urlTree = this.router.parseUrl(hyperlink);
        this.segments = this.urlTree.root.children['primary'].segments;
        this.commands = [`/${this.prefix ?? ''}`, ...this.segments.map(segment => segment.path)];
      }
    }
  }
}
