import { IAppointmentType } from './appointment-type.interface';
import { ICommissionLeadershipScheme } from './commission-leadership-scheme.interface';
import { ICommissionScheme } from './commission-scheme.interface';
import { ISchemeType } from './scheme-type.interface';

export interface ICarrerProgression {
  id: number;
  status?: string;
  effectiveDate: string;
  endDate?: string;

  salespersonId: number;
  commissionSchemeId: number;
  commissionScheme: ICommissionScheme;

  leadershipId: number | null;
  leadership: ICommissionLeadershipScheme;

  appTypeIds: { id: number }[];
  appTypes: IAppointmentType[];

  schemeTypeId: number;
  schemeType: ISchemeType;
  // departmentId: number;
}

export interface ICareerProgressionCreateDto {
  effectiveDate: string;
  schemeTypeId: number;
  commissionSchemeId: number;
  leadershipId: number | null;
  appTypeIds: { id: number }[];
}
