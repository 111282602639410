import { IAttachment } from './attachment.interface';
import { IBank } from './bank.interface';
import { ILedgerAccount } from './ledger-account.interface';

export interface IBankAccount {
  id: number;
  accountId: number;
  account: ILedgerAccount;
  bankId: number;
  bank: IBank;
  name: string;
  bankAccountNumber: string;
  currency: string;
  requireBankReturnCollectionFile: boolean;
  requireBankReturnPaymentFile: boolean;
  directDebitCollectionFileId: number;
  directDebitCollectionFile: IAttachment;
  directDebitPaymentFileId: number;
  directDebitPaymentFile: IAttachment;
  remarks: string;
  // createdAt: Date;
  // updatedAt: Date;
  createdAt: string;
  updatedAt: string;
}
