<mat-form-field class="w-full" appearance="fill" [formGroup]="formData">
  <mat-label>{{ data.name }}</mat-label>
  <!-- <mat-icon *ngIf="dataUpcast?.value" matSuffix class="icon-size-5 text-theme-dark" (click)="clear()">clear</mat-icon> -->
  <div class="inline-flex items-center w-full" (click)="onSelectDialog()">
    <input #searchInput type="text" matInput formControlName="label" [class.hidden]="formData.get('label')?.value" readonly  />
    <span class="truncate">{{formData.get('label')?.value?.[0] || ''}}</span>
    <span *ngIf="(formData.get('label')?.value?.length || 0) > 1" class="example-additional-selection">
      (+{{ (formData.get('label')?.value?.length || 0) - 1 }} {{ formData.get('label')?.value?.length === 2 ? 'other' : 'others' }})
    </span>
  </div>
  <button matSuffix color="primary" mat-button type="button" (click)="onSelectDialog()">
    <mat-icon class="!text-primary">add_circle</mat-icon><span>Select</span>
  </button>
</mat-form-field>
