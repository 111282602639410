import { AssetCategoryAccountType } from '../types/fixed-asset.type';
import { ITimestamp } from './timestamp.interface';
export interface IAssetCategory {
  id: number;
  code: string;
  name: string;

  assetAccountId: number;
  assetAccount?: AssetCategoryAccountType | null;

  assetDepreciationAccountId: number;
  assetDepreciationAccount?: AssetCategoryAccountType | null;

  assetAccumulatedAccountId: number;
  assetAccumulatedAccount?: AssetCategoryAccountType | null;

  assetDisposalAccountId: number;
  assetDisposalAccount?: AssetCategoryAccountType | null;

  yearPeriod: number;
  monthPeriod: number;
}
