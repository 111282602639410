import { BaseModel, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IGceAmountDto } from '../interfaces';

export class GceAmountCreateDto extends BaseModel implements IGceAmountDto {
  @Expose()
  amount!: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate!: string;

  @Expose()
  salespersonId!: number;
}

export class GceAmountUpdateDto extends BaseModel implements Partial<IGceAmountDto> {
  @Expose()
  amount?: number;

  @Expose()
  description?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate?: string;

  @Expose()
  salespersonId?: number;
}
