import { formatNumber } from '@angular/common';
import { UrlTree } from '@angular/router';
import { get, assign, has } from 'lodash-es';
import * as moment from 'moment';
import { MetabaseConversionApi } from './data-converter/data-converter';
import { numberDataConverter } from './data-converter/number.data-converter';
import { IMetabaseCardParameter } from './models';
import { MetabaseCardDataCol, MetabaseVisualizationSetting, MetabaseColSetting } from './models/metabase.model';
import { MetabaseNumberSeparator, MetabaseTimeEnabled, MetabaseTimeStyle, MetabaseViewAs, MetadataColDataType } from './models/metabase.type';
export const DYNAMICFIELD_REGEX = /{{([\w\s-_]*)}}/g;
type DefinitionPath = { [key in MetadataColDataType]?: string[] };
const definitionPath: DefinitionPath = {
  [MetadataColDataType.DATETIME_WITH_ZONE_OFFSET]: ['timeEnabled', 'timeStyle'],
};
const definitionValueFormatter = {
  format: (value: string, colSetting: MetabaseColSetting) => value,
  [MetadataColDataType.DATETIME_WITH_ZONE_OFFSET]: {
    format: (value: string, colSetting: MetabaseColSetting) => moment(value).format('LL'),
    [MetabaseTimeEnabled.MINUTES]: {
      format: (value: string, colSetting: MetabaseColSetting) => moment(value).format('LL, hh:mm'),
      [MetabaseTimeStyle.HHmm]: {
        format: (value: string, colSetting: MetabaseColSetting) => moment(value).format('LL, HH:mm'),
      },
      [MetabaseTimeStyle.hmmA]: {
        format: (value: string, colSetting: MetabaseColSetting) => moment(value).format('LL, H:mm A'),
      },
    },
  },
  [MetadataColDataType.DATE_TIME]: {
    format: (value: string, colSetting: MetabaseColSetting) => moment(value).format('LL'),
  },
  [MetadataColDataType.FLOAT]: {
    format: (value: number, colSetting: MetabaseColSetting) => MetabaseConversionApi.transformData(value, colSetting, numberDataConverter),
  },
};
const definitionTemplateRef = {
  format: (rowData: Record<string, string | number | null>, col: MetabaseCardDataCol, colSetting?: MetabaseColSetting) => ({}),
  [MetabaseViewAs.LINK]: {
    format: (rowData: Record<string, string | number | null>, col: MetabaseCardDataCol, colSetting?: MetabaseColSetting) => ({
      templateRefName: MetabaseViewAs.LINK,
      attrs: {
        url: colSetting?.linkUrl ? bindingDataToUrl(colSetting.linkUrl, rowData) : '',
        // text:col.,
        target: '_blank',
      },
    }),
  },
};
function stringOrEmpty(value?: string): string {
  return value || '';
}
export function getColumnSetting(cardVisualizationSetting: MetabaseVisualizationSetting, fieldName: string): MetabaseColSetting | undefined {
  // const keys = Object.keys(cardVisualizationSetting.columnSettings);
  const obj = JSON.stringify(['name', fieldName]);
  return cardVisualizationSetting.columnSettings[obj];
}

export function normalizeValue(value: unknown, col: MetabaseCardDataCol, colSetting?: MetabaseColSetting): unknown {
  if (!value) {
    return value;
  }
  const paths: string[] = definitionPath[col.baseType] || [];
  const valuePaths = paths.map(path => stringOrEmpty(get(colSetting, path))).filter(val => val);
  const stringPath = [col.baseType, ...valuePaths].join('.');
  const def = get(definitionValueFormatter, stringPath);
  return def ? def.format(value, colSetting) : value;
}

export function normalizeTemplateRefName(
  rowData: Record<string, string | number | null>,
  col: MetabaseCardDataCol,
  colSetting?: MetabaseColSetting
): Record<string, any> | null {
  const def = get(definitionTemplateRef, colSetting?.viewAs || '');
  return def ? def.format(rowData, col, colSetting) : null;
}

export function bindingDataToUrl(rawUrl: string, rowData: Record<string, string | number | null>): string {
  const result = rawUrl.replace(DYNAMICFIELD_REGEX, function (match, key) {
    return rowData[key] as string;
  });

  return result;
}

export function getFieldNameFromParameter(parameter: IMetabaseCardParameter): string {
  return parameter.target[1][1];
}
export function normalizeValueByDefaultValueParameter(value: any, parameter: Partial<IMetabaseCardParameter>): any {
  if (typeof parameter.default === 'string') {
    return value.toString();
  }
  if (Array.isArray(parameter.default)) {
    return Array.isArray(value) ? value : [value];
  }
  return value;
}

export function bindingDataParameter(parameters: IMetabaseCardParameter[], data: Record<string, any>): IMetabaseCardParameter[] {
  return parameters.map(parameter => {
    const key = getFieldNameFromParameter(parameter);
    const value = typeof data[key] !== 'undefined' ? normalizeValueByDefaultValueParameter(data[key], parameter) : parameter.default;
    return assign(parameter, { value });
  });
}
