import { ICommissionMatrix } from './commission-matrix.interface';
import { IProject } from './project.interface';
import { ITimestamp } from './timestamp.interface';
import { IOverridingDistribution } from './overriding-distribution.interface';

export interface IProjectHICommMatrix extends ITimestamp, ICommissionMatrix {
  commissionMatrixId: number | null;
  endDate: string;
  endDateMarketing: string | null;
  isActive: boolean;
  project: Partial<IProject>;
  projectId: number;
  projectStage: string;
  remarks: string | null;
  reviewedAt: string | null;
  reviewedBy: string | null;
  startDate: string;
  startDateMarketing: string | null;
  submittedAt: string | null;
  submittedBy: string | null;
  unitId: number | null;
  headHi: {
    agent: boolean;
    hr: boolean;
    value: number;
    disOverriding: IOverridingDistribution;
    disOverridingId: number;
  };
  bd: {
    agent: boolean;
    hr: boolean;
    value: number;
    disOverriding: IOverridingDistribution;
    disOverridingId: number;
  };
  hiProfit: {
    agent: boolean;
    hr: boolean;
    value: number;
  };
}
