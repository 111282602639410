import { NavigationItem } from '@red/components/navigation';
import { ROUTE } from './route.constant';

export const defaultNavigation: NavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    // tooltip: 'Dashboard',
    type: 'basic',
    icon: 'dashboard',
    link: '/dashboard',
    meta: {
      permission: {
        disabled: true,
      },
    },
  },
  {
    id: 'personal_info',
    title: 'Personal Info',
    // tooltip: 'Personal Info',
    type: 'collapsable',
    icon: 'hr',
    isSvgIcon: true,
    children: [
      {
        id: 'personal_info.personal_profile',
        title: 'Personal Profile',
        type: 'basic',
        link: ROUTE.PERSONAL_INFO.PATH,
        meta: {
          permission: {
            disabled: true,
          },
        },
      },
      {
        id: 'personal_info.team_org_chart',
        title: 'Organization Chart',
        type: 'basic',
        link: ROUTE.TEAM_ORG_CHART.PATH,
        meta: {
          permission: {
            slug: 'hr:team_org_chart:read',
          },
        },
      },
      {
        id: 'personal_info.recruitment',
        title: 'Recruitment',
        type: 'basic',
        link: ROUTE.RECRUITMENT.PATH,
        meta: {
          permission: {
            slug: 'hr:my_recruitment:read',
          },
        },
      },
      {
        id: 'personal_info.report',
        title: 'Reports',
        type: 'basic',
        link: ROUTE.REPORT.PATH,
        meta: {
          permission: {
            slug: 'hr:report:read',
          },
        },
      },
    ],
  },
  {
    id: 'transaction',
    title: 'Transactions',
    // tooltip: 'Transaction',
    type: 'collapsable',
    icon: 'transaction',
    isSvgIcon: true,
    children: [
      {
        id: 'transaction.project',
        title: 'Project Admin',
        type: 'collapsable',
        children: [
          // {
          //   id: 'transaction.project.project_setup',
          //   title: 'Project Setup',
          //   type: 'basic',
          //   link: `/${ROUTE.PROJECT.PROJECT_SETUP}`,
          //   // meta: {
          //   //   permission: {
          //   //     slug: 'project:project_setup:read',
          //   //   },
          //   // }
          // },
          {
            id: 'transaction.project.key_appointment',
            title: 'Key Appoinment List',
            type: 'basic',
            link: `/${ROUTE.PROJECT.KEY_APPOINTMENT}`,
            meta: {
              permission: {
                slug: 'project:key_appointment_list:read',
              },
            },
          },
          {
            id: 'transaction.project.project_consultant',
            title: 'Project Consultant List',
            type: 'basic',
            link: `/${ROUTE.PROJECT.PROJECT_CONSULTANT}`,
            meta: {
              permission: {
                slug: 'project:project_consultant_list:read',
              },
            },
          },
          {
            id: 'transaction.project.comm_matrix',
            title: 'Comm Matrix List',
            type: 'basic',
            link: `/${ROUTE.PROJECT.COMM_MATRIX}`,
            meta: {
              permission: {
                slug: ['project:comm_matrix:read', 'project:comm_matrix:read'],
              },
            },
          },
          {
            id: 'transaction.project.project_transaction',
            title: 'Transaction List',
            type: 'basic',
            link: `/${ROUTE.PROJECT.PROJECT_TRANSACTION}`,
            meta: {
              permission: {
                slug: 'project:transaction:read',
              },
            },
          },
          {
            id: 'transaction.project.blank_form',
            title: 'Blank Form List',
            type: 'basic',
            link: `/${ROUTE.PROJECT.BLANK_FORM}`,
            meta: {
              permission: {
                slug: 'project:bf_transaction:read',
              },
            },
          },
        ],
      },
      {
        id: 'transaction.transaction',
        title: 'Transaction',
        type: 'collapsable',
        children: [
          {
            id: 'transaction.project.normal_transaction',
            title: 'Project Transaction List',
            type: 'basic',
            link: `/${ROUTE.PROJECT.AGENT_PROJECT_TRANSACTION}`,
            meta: {
              permission: {
                slug: 'project:normal_transaction:read',
              },
            },
          },
          {
            id: 'transaction.project.normal_blank_form',
            title: 'Project Blank Form List',
            type: 'basic',
            link: `/${ROUTE.PROJECT.AGENT_BLANK_FORM}`,
            meta: {
              permission: {
                slug: 'project:normal_bf_transaction:read',
              },
            },
          },
        ],
      },
      {
        id: 'transaction.resale_transaction',
        title: 'Resale Transaction',
        type: 'collapsable',
        children: [
          {
            id: 'transaction.resale_transaction.transaction_list',
            title: 'Transaction List',
            type: 'basic',
            link: `/${ROUTE.RESALE_TRANSACTION.LIST}`,
            meta: {
              permission: {
                slug: 'project:resale_transaction:read',
              },
            },
          },
        ],
      },
      {
        id: 'transaction.ecdd',
        title: 'ECDD',
        type: 'basic',
        link: `/${ROUTE.ECDD.ROOT}`,
        meta: {
          permission: {
            slug: 'hr:ecdd:read',
          },
        },
      },
      /*{
        id: 'transaction.commission_payout',
        title: 'Commission Payout',
        type: 'collapsable',
        children: [
          {
            id: 'transaction.commission_payout.list',
            title: 'Payout List',
            type: 'basic',
            link: `/${ROUTE.COMMISSION_PAYOUT.LIST}`,
          },
        ],
      },*/
    ],
  },
  {
    id: 'redoc',
    title: 'Digitalised Documents',
    subtitle: '(Powered by Redoc.co)',
    type: 'basic',
    icon: 'redoc_transparent',
    isSvgIcon: true,
    link: '/document',
    meta: {
      permission: {
        disabled: true,
      },
    },
  },
  /*{
    id: 'report',
    title: 'Report',
    // tooltip: 'Report',
    type: 'collapsable',
    icon: 'finance',
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },*/
];

export const compactNavigation: NavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    tooltip: 'Dashboard',
    type: 'aside',
    icon: 'dashboard',
    link: '/dashboard',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'personal_info',
    title: 'Personal Information',
    tooltip: 'Personal Information',
    type: 'aside',
    icon: 'hr',
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'transaction',
    title: 'Transaction',
    tooltip: 'Transaction',
    type: 'aside',
    icon: 'transaction',
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'redoc',
    title: 'Digitalised Documents',
    subtitle: '(Powered by Redoc.co)',
    type: 'aside',
    icon: 'redoc_transparent',
    link: '/document',
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  /*{
    id: 'report',
    title: 'Report',
    tooltip: 'Report',
    type: 'aside',
    icon: 'finance',
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },*/
];
