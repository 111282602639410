import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyPipe',
})
export class CmsCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) { }
  transform(value: number | string, currencyCode = ' ', digitsInfo?: string): string {
    return this.currencyPipe.transform(value || 0, currencyCode, 'code', digitsInfo) || '';
  }
}
