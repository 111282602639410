import { IPhoneNumber } from '@red/data-access';
import { IReferralExternal } from './referral-external.interface';

export interface IBlankFormExternalCoBroke {
  id: number;
  agent: IReferralExternal;
  agentId: number;
  receivedValue: number;
  defaultValue: number;
  attrs: any;
  note: string;
  isDefault: boolean;
  brokerName: string;
  brokerPhone: IPhoneNumber;
  companyAmount: number;
}
