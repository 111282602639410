import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ProjectCreateDto, ProjectHICreateDto, ProjectUpdateDto, ProjectUpdateRemarkDto } from '@shared/data-access/dto';
import { IBusinessDirector, IProject } from '@shared/data-access/interfaces';
import { BusinessDirectorModel, ProjectModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectApiService {
  static UNIT = 'transaction/units';
  static PROJECT = 'transaction/projects';
  static FOR_BATCH = 'transaction/projects/for-batch';
  static BUSINES_DIRECTOR = 'transaction/business-directors';
  static PROJECTS_COMMON = 'transaction/projects/projects-common';

  constructor(private apiService: ApiService) {}

  create(data: ProjectCreateDto): Observable<ProjectModel> {
    return this.apiService.post(`${ProjectApiService.PROJECT}`, data).pipe(map((res: IProject) => ProjectModel.fromJson(res) as ProjectModel));
  }

  get(itemId: number): Observable<ProjectModel> {
    return this.apiService.get(ProjectApiService.PROJECT + '/' + itemId).pipe(map(res => ProjectModel.fromJson(res) as ProjectModel));
  }

  getTotalUnits(itemId: number): Observable<{ projectId: number; total: number }> {
    return this.apiService.get(ProjectApiService.UNIT + '/total/' + itemId);
  }

  update(id: number, data: ProjectUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectApiService.PROJECT + '/' + id, data, { excludeFields: [''] });
  }

  updateRemarks(id: number, data: ProjectUpdateRemarkDto): Observable<any> {
    return this.apiService.patch(ProjectApiService.PROJECT + '/' + id + '/remarks', data);
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectModel>> {
    return this.apiService
      .get(ProjectApiService.PROJECT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectModel, data)));
  }

  searchAvailableForBatch(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectModel>> {
    return this.apiService.get(ProjectApiService.FOR_BATCH, query, option).pipe(map(data => new PaginationAdapter(ProjectModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectApiService.PROJECT + '/' + id, option);
  }

  updateStatus(id: number, status: string): Observable<any> {
    return this.apiService.patch(ProjectApiService.PROJECT + '/' + id, { status });
  }

  // Business Director
  createBusinessDirector(data: ProjectHICreateDto): Observable<BusinessDirectorModel> {
    return this.apiService
      .post(ProjectApiService.BUSINES_DIRECTOR, data)
      .pipe(map((res: IBusinessDirector) => BusinessDirectorModel.fromJson(res) as BusinessDirectorModel));
  }

  updateBusinessDirector(id: number, data: ProjectHICreateDto): Observable<BusinessDirectorModel> {
    return this.apiService
      .patch(ProjectApiService.BUSINES_DIRECTOR + '/' + id, data)
      .pipe(map((res: IBusinessDirector) => BusinessDirectorModel.fromJson(res) as BusinessDirectorModel));
  }

  getBusinessDirector(query = {}, option?: IApiOption): Observable<PaginationAdapter<BusinessDirectorModel>> {
    return this.apiService.get(ProjectApiService.BUSINES_DIRECTOR, query, option).pipe(map(data => new PaginationAdapter(BusinessDirectorModel, data)));
  }

  getBusinessDirectorById(id: number): Observable<BusinessDirectorModel> {
    return this.apiService
      .get(ProjectApiService.BUSINES_DIRECTOR + '/' + id)
      .pipe(map((res: IBusinessDirector) => BusinessDirectorModel.fromJson(res) as BusinessDirectorModel));
  }

  deleteBusinessDirector(ids: number[]) {
    return this.apiService.delete(ProjectApiService.BUSINES_DIRECTOR, { ids });
  }
  projectsCommon(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectModel>> {
    return this.apiService.get(ProjectApiService.PROJECTS_COMMON, query, option).pipe(map(data => new PaginationAdapter(ProjectModel, data)));
  }
}
