import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { ECommMatrixVerifyStatus } from '../enums';
import { IProjectCommMatrixStatusHistory } from '../interfaces';

export class ProjectCommMatrixtatusHistoryModel extends BaseModel implements IProjectCommMatrixStatusHistory {
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number | null;

  @Expose()
  action!: `${ECommMatrixVerifyStatus}`;

  @Expose()
  data!: { reason: string };

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: string | null;

  @Expose()
  deletedAt!: string;

  @Expose()
  deletedBy!: string;

  @Expose()
  sourceId!: number;

  @Expose()
  type!: string;
}
