import { assign } from 'lodash-es';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { Directive, Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ProjectReportCreateDto, ProjectReportUpdateDto } from '@shared/data-access/dto';
import { IProjectReport } from '@shared/data-access/interfaces';
import { map, Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { MetabaseCard, MetabaseCardQueryModel, MetabaseCollectionModel } from '@shared/components/metabase';

@Injectable({
  providedIn: 'root',
})
export class MetabaseReportApiService {
  static ROOT_POINT = 'metabase/collection';
  protected collection = '';

  constructor(protected apiService: ApiService) {}

  create(data: ProjectReportCreateDto): Observable<MetabaseCollectionModel> {
    return this.apiService
      .post(`${MetabaseReportApiService.ROOT_POINT}`, data)
      .pipe(map((res: IProjectReport) => MetabaseCollectionModel.fromJson(res) as MetabaseCollectionModel));
  }
  get(itemId: number): Observable<MetabaseCollectionModel> {
    return this.apiService.get(MetabaseReportApiService.ROOT_POINT + '/' + itemId).pipe(map(res => MetabaseCollectionModel.fromJson(res) as MetabaseCollectionModel));
  }
  update(id: number, data: ProjectReportUpdateDto): Observable<any> {
    return this.apiService.patch(MetabaseReportApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query: IQuerySearch = {}, option?: IApiOption): Observable<PaginationAdapter<MetabaseCollectionModel>> {
    const normalized = assign(query, { collection: this.collection });
    return this.apiService.get(MetabaseReportApiService.ROOT_POINT, normalized, option).pipe(map(data => new PaginationAdapter(MetabaseCollectionModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(MetabaseReportApiService.ROOT_POINT, { ids }, option);
  }
}

export type MetabaseCardCollection =
  | 'customer_reports'
  | 'supplier_reports'
  | 'project_reports'
  | 'resale_reports'
  | 'finance_reports'
  | 'agent_reports'
  | 'bank_reports';
@Directive()
export abstract class MetabaseApiService {
  static COLLECTION_POINT = 'metabase/collection';
  static CARD_POINT = 'metabase/card';
  abstract collection: MetabaseCardCollection;

  constructor(protected apiService: ApiService) {}

  // create(data: ProjectReportCreateDto): Observable<MetabaseCollectionModel> {
  //   return this.apiService
  //     .post(`${MetabaseApiService.ROOT_POINT}`, data)
  //     .pipe(map((res: IProjectReport) => MetabaseCollectionModel.fromJson(res) as MetabaseCollectionModel));
  // }

  get(itemId: number, query = {}): Observable<MetabaseCard> {
    return this.apiService.get(MetabaseApiService.CARD_POINT + '/' + itemId, query).pipe(map(res => MetabaseCard.fromJson(res) as MetabaseCard));
  }

  // update(id: number, data: ProjectReportUpdateDto): Observable<any> {
  //   return this.apiService.patch(MetabaseApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  // }

  search(query: IQuerySearch = {}, option?: IApiOption): Observable<PaginationAdapter<MetabaseCard>> {
    const normalized = assign(query, { collection: this.collection });
    return this.apiService.get(MetabaseApiService.CARD_POINT, normalized, option).pipe(map(data => new PaginationAdapter(MetabaseCard, data)));
  }

  // delete(ids: number[], option?: IApiOption): Observable<any> {
  //   return this.apiService.delete(MetabaseApiService.ROOT_POINT, { ids }, option);
  // }

  query(itemId: number, data: Record<string, any>): Observable<MetabaseCardQueryModel> {
    return this.apiService
      .post(MetabaseApiService.CARD_POINT + '/' + itemId + '/query', data)
      .pipe(map(res => MetabaseCardQueryModel.fromJson(res) as MetabaseCardQueryModel));
  }

  getCardXlsxFile(itemId: number, data: Record<string, any> = {}): Observable<any> {
    return this.apiService
      .post(MetabaseApiService.CARD_POINT + '/' + itemId + '/query/xlsx', data, {
        pretreatmentResponse: false,
        requestOptions: {
          responseType: 'arraybuffer',
        },
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}
