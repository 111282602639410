import { IPhoneNumber } from '@red/data-access';
import { EMessageOutboxStatus, EMessageOutboxType, EMessageType } from '../enums/message-outbox.enum';

export interface ICCInfo {
  email: string;
  name: string;
}

export interface IMessageOutbox {
  code: string;
  type: EMessageOutboxType;
  status: EMessageOutboxStatus;
  recipient: string;
  email: string;
  subject: string;
  emailTemplateId: number;
  phone: IPhoneNumber;
  sequence: number;
  params: {
    [x in string]: any;
  };
  filenames: {
    [x in string]: any;
  };
  createdAt: string;
  updatedAt: string;
  sentOn: string;
  messageType: EMessageType;
  from: string;
  cc: ICCInfo[];
  hardcopy: boolean;
}
