export interface IExternalPayout {
  agreedComm: number;
  value: number;
  gstAmount: number;
  gstType: string;
  invoiceNumber: string;
  ecbInvoiceDate: string;
  payoutDate: string;
  chequeNumber: string;
  contactName: string;
}
