import { BaseModel, Default, PhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { random } from 'lodash-es';
import { RESALE_AGENT_TYPE_OPTIONS, RESALE_EXTERNAL_COMM_TYPE_OPTIONS } from '../data';
import { ECommType, EGstType, EResaleAgentType, EResaleCommissionTaxType } from '../enums';
import { IResaleExternalCoBroke } from '../interfaces';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { GrossCommissionEarnedModel } from './gross-commission-earned.model';

export class ResaleExternalCoBrokeModel extends BaseModel implements IResaleExternalCoBroke {
  @Expose()
  attrs!: {
    sequence: number;
    commission?: {
      type: EResaleCommissionTaxType;
      value: number;
    };
    [key: string]: any;
  };

  @Expose()
  brokerIdNo!: string;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;

  @Expose()
  brokerEmail!: string;

  @Expose()
  commType!: ECommType;

  @Expose()
  contactId!: number;

  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  gstType!: EGstType;

  @Expose()
  id!: number;

  @Expose()
  @Type(() => Number)
  @Transform(({ value, obj }) => {
    if (obj.attrs?.commission?.type === ECommType.percentage) {
      return obj.attrs?.commission?.value
    }
    return value
  })
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  @Transform(({ value, obj }) => {
    if (obj.attrs?.commission?.type === ECommType.value) {
      return obj.attrs?.commission?.value
    }
    return value
  })
  receivedValue!: number;

  @Expose()
  remarks!: string;

  @Expose()
  @Default(GrossCommissionEarnedModel.createEmpty())
  @Type(() => GrossCommissionEarnedModel)
  grossComm!: GrossCommissionEarnedModel;

  @Expose()
  type!: EResaleAgentType;

  @Expose()
  isDefault?: boolean;

  @Expose()
  totalAmount?: number;

  @Expose()
  @Transform(({ value }) => (value ? value : random(1, 10000)))
  uniqueId?: string;

  get commTypeDescription() {
    return RESALE_EXTERNAL_COMM_TYPE_OPTIONS[this.commType];
  }

  get agentTypeDescription() {
    return RESALE_AGENT_TYPE_OPTIONS[this.type];
  }
}
