import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { ICurrencyRate, ICurrencyRateHistory } from '../interfaces';
import { UpdatedByModel } from './updated-by.model';

export class CurrencyRateModel extends BaseModel implements ICurrencyRate {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  symbol!: string;

  @Expose()
  businessUnitId?: number;

  @Expose()
  value!: number;

  @Expose()
  effectiveDate!: string;

  @Expose()
  uploadedAt?: string;

  @Expose()
  uploadedBy?: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  createdBy?: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  deletedAt?: string;

  @Expose()
  deletedBy?: string;

  @Expose()
  exchange!: number;
}

export class CurrencyRateHistoryModel extends BaseModel implements ICurrencyRateHistory {
  @Expose()
  id!: number;

  @Expose()
  currencyId!: number;

  @Expose()
  value!: number;

  @Expose()
  effectiveDate!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  createdBy?: string;

  @Expose()
  updatedBy?: string;

  @Expose()
  deletedAt?: string;

  @Expose()
  deletedBy?: string;
}
